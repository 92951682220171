import { ContentBox, PageMainContent } from '@yarmill/components';
import { useUsersStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { Page } from '../page/page';
import { Sidebar } from '../settings/sidebar';
import { UsersHeader } from './header';
import { VirtualizedUsersList } from './virtualized-users-list';

export const Users = observer(function Users(): JSX.Element {
  const usersStore = useUsersStore();
  const pageMainContentRef = useRef<HTMLDivElement>(null);
  const usersHeaderRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    void usersStore.loadDeactivatedUsers();
  }, [usersStore]);

  return (
    <Page>
      <Sidebar activeView="users" ref={sidebarRef} />
      <PageMainContent ref={pageMainContentRef}>
        <UsersHeader ref={usersHeaderRef} />
        <ContentBox>
          <VirtualizedUsersList
            pageMainContentRef={pageMainContentRef}
            usersHeaderRef={usersHeaderRef}
            sidebarRef={sidebarRef}
          />
        </ContentBox>
      </PageMainContent>
    </Page>
  );
});
