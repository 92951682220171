import { AttendanceModule } from '@yarmill/attendance';
import { Page } from '../page/page';

export function Attendance() {
  return (
    <Page>
      <AttendanceModule />
    </Page>
  );
}
