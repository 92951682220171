import { StackedBarChart } from '../../types';
import { groupStackedBarsByCategories } from '../../utils/group-stacked-bars-by-categories';
import { Group } from '@visx/group';
import { useMemo } from 'react';
import { getBarRadius } from '../../../reporting/utils/get-bar-radius';
import { StackedBarRect } from './stacked-bar-rect';
import { ArrayElement, DataItem } from '../../../reporting/types';

interface BarStackProps {
  readonly stack: ArrayElement<ReturnType<typeof groupStackedBarsByCategories>>;
  readonly config: StackedBarChart;
  readonly stackIndex: number;
  readonly item: DataItem;
}
export function BarStack({ stack, config, stackIndex, item }: BarStackProps) {
  const maskId = `${config.code}_cat_${stackIndex}`;

  const sortedRectsByY = useMemo(
    () => stack.slice().sort((a, b) => a.y - b.y),
    [stack]
  );
  const firstBar = sortedRectsByY[0];

  const height = useMemo(
    () =>
      stack.reduce((sum, bar) => {
        sum = bar.height + sum;
        return sum;
      }, 0),
    [stack]
  );

  return (
    <Group>
      <mask id={maskId}>
        <rect
          x={firstBar?.x}
          y={firstBar?.y}
          width={firstBar?.width}
          height={height}
          rx={getBarRadius(firstBar?.width)}
          fill="white"
        />
      </mask>
      <Group mask={`url(#${maskId})`}>
        {stack.map((bar, idx) => (
          <StackedBarRect bar={bar} key={idx} config={config} item={item} />
        ))}
      </Group>
    </Group>
  );
}
