import {
  AttachmentFileInput,
  Button,
  ButtonAppearance,
  FileAttribute,
  FormControlVariant,
  StyledLabel,
  Text,
  TextSize,
} from '@yarmill/components';
import { File, FileUploadStore } from '@yarmill/types';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import {
  AttachmentLabelWrapper,
  AttachmentsFormFieldWrapper,
} from './components/form';
import { useFilesOverviewFileUpload } from './hooks/use-files-overview-file-upload';

interface AttachmentsFormFieldProps {
  readonly attachments: File[];
  readonly addAttachment: (attachment: File) => void;
  readonly removeAttachment: (attachment: File) => void;
  readonly addPendingAttachment: (store: FileUploadStore) => void;
  readonly removePendingAttachment: (store: FileUploadStore) => void;
}

const save = async () => true;
export const AttachmentsFormField = observer(function Attachments({
  attachments,
  addAttachment,
  removeAttachment,
  addPendingAttachment,
  removePendingAttachment,
}: AttachmentsFormFieldProps) {
  const [inputRef, onChange] = useFilesOverviewFileUpload(
    addAttachment,
    addPendingAttachment,
    removePendingAttachment
  );
  const inputId = `attachments`;

  return (
    <>
      <AttachmentLabelWrapper>
        <StyledLabel variant={FormControlVariant.big} as="div">
          <FormattedMessage
            id={`filesOverview.addFilesForm.attachments.label`}
          />
        </StyledLabel>
        <Button
          as="label"
          htmlFor={inputId}
          appearance={ButtonAppearance.Primary}
          inverted
          noShadow
        >
          <Text size={TextSize.s14} inheritColor>
            <FormattedMessage id="trainingDay.attributes.attachment.addFile" />
          </Text>
        </Button>
      </AttachmentLabelWrapper>
      <AttachmentFileInput
        ref={inputRef}
        id={inputId}
        type="file"
        name="fileData"
        onChange={onChange}
        multiple
      />
      <AttachmentsFormFieldWrapper>
        <FileAttribute
          attachments={attachments}
          hideAdd
          variant="table-form"
          autoSave={false}
          isCurrentUserAllowedToWrite
          removeFile={removeAttachment}
          save={save}
        />
      </AttachmentsFormFieldWrapper>
    </>
  );
});
