import { ROUTE_DATE_FORMAT, WEEK_SEARCH_PARAM } from '@yarmill/const';
import {
  generateUrl,
  getMaxBookDate,
  getMinBookDate,
  getWeekEnd,
  getWeekStart,
  useCollapsible,
  useCurrentWeek,
  useHistory,
  useSeasonsStore,
} from '@yarmill/utils';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button, ButtonAppearance } from './button';
import { ContextShim } from './context-shim';
import { Datepicker } from './datepicker';
import { ExternalIcon } from './external-icon';
import { Icon, IconSize } from './icon';
import { Text, TextSize } from './text';

const StyledWeekSelector = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  color: #4a4a4a;
`;

const StyledDropdownButton = styled.div`
  margin: 0 8px;
  position: relative;
  display: none;

  @media (min-width: 576px) {
    display: block;
  }
`;

const StyledWeekSelectorButton = styled(Button)`
  display: inline-grid;
  border-radius: 4px;
  color: #4a4a4a;
  grid-template-columns: auto auto;
  column-gap: 5px;
  align-items: center;
`;

const StyledDatepickerWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  top: 44px;
  left: -70px;

  @media (max-width: 991px) {
    left: -100px;
  }
`;

export interface WeekSelectorProps {
  onChange?: (week: string) => void;
}

export function WeekSelector(props: WeekSelectorProps): JSX.Element {
  const { onChange } = props;
  const [opened, show, hide] = useCollapsible<HTMLDivElement>(false);
  const week = useCurrentWeek();
  const seasons = useSeasonsStore().seasons;
  const weekStart = getWeekStart(week);
  const weekEnd = getWeekEnd(week);
  const history = useHistory();

  const showDatepicker = useCallback((): void => {
    show();
  }, [show]);

  const hideDatepicker = useCallback((): void => {
    hide();
  }, [hide]);

  const setWeek = useCallback(
    (w: moment.Moment): void => {
      const formattedWeek = w.format(ROUTE_DATE_FORMAT);
      const link = generateUrl({
        [WEEK_SEARCH_PARAM]: formattedWeek,
      });
      history.push(link);
      onChange?.(formattedWeek);
      hideDatepicker();
    },
    [history, hideDatepicker, onChange]
  );

  const goToNextWeek = useCallback((): void => {
    const nextWeek = moment(week).add(1, 'weeks');
    setWeek(nextWeek);
  }, [setWeek, week]);

  const goToPrevWeek = useCallback((): void => {
    const prevWeek = moment(week).subtract(1, 'weeks');
    setWeek(prevWeek);
  }, [setWeek, week]);

  const onDateChange = useCallback(
    (newDate: string): void => {
      if (newDate) {
        setWeek(moment(newDate));
        hideDatepicker();
      }
    },
    [setWeek, hideDatepicker]
  );

  const minBookingDate = useMemo(() => getMinBookDate(seasons), [seasons]);
  const maxBookingDate = useMemo(() => getMaxBookDate(seasons), [seasons]);

  return (
    <StyledWeekSelector data-cy="week-selector">
      <Button
        square
        noShadow
        onClick={goToPrevWeek}
        appearance={ButtonAppearance.Navigation}
        data-cy="prev-week"
      >
        <Icon size={IconSize.s20}>
          <ExternalIcon name="ChevronLeft" />
        </Icon>
      </Button>
      <StyledDropdownButton>
        <StyledWeekSelectorButton
          noShadow
          onClick={opened ? hideDatepicker : showDatepicker}
          appearance={ButtonAppearance.Navigation}
          data-cy="toggle-calendar"
        >
          <Icon>
            <ExternalIcon name="Calendar" />
          </Icon>
          <Text size={TextSize.s14}>
            <FormattedDate
              value={weekStart.toDate()}
              day="2-digit"
              month="2-digit"
            />
            &nbsp;-&nbsp;
            <FormattedDate
              value={weekEnd.toDate()}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          </Text>
        </StyledWeekSelectorButton>
        {opened && (
          <StyledDatepickerWrapper>
            <ContextShim onClick={hideDatepicker} />
            <Datepicker
              value={week}
              onChange={onDateChange}
              selectRange="week"
              minBookingDate={minBookingDate}
              maxBookingDate={maxBookingDate}
              todayButtonLabel={
                <FormattedMessage id="navbar.calendar.todayButton" />
              }
            />
          </StyledDatepickerWrapper>
        )}
      </StyledDropdownButton>
      <Button
        noShadow
        square
        onClick={goToNextWeek}
        appearance={ButtonAppearance.Navigation}
        data-cy="next-week"
      >
        <Icon size={IconSize.s20}>
          <ExternalIcon name="ChevronRight" />
        </Icon>
      </Button>
    </StyledWeekSelector>
  );
}
