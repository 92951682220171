import { styled } from '@yarmill/components';
import { Text } from '@yarmill/components-2';
import { Link } from 'react-router-dom';

export const OverviewTableLayout = styled.div`
  display: grid;
  grid-template-columns: 100%;
  row-gap: ${({ theme }) => theme.size.x4};
  padding: 0 ${({ theme }) => theme.size.x4};
  @media (min-width: 768px) {
    grid-template-columns: max-content auto auto;
    align-items: start;
    column-gap: ${({ theme }) => theme.size.x4};
    row-gap: ${({ theme }) => theme.size.x2};
  }
`;

export const OverviewTableColumnLabel = styled(Text)`
  display: none;
  color: ${({ theme }) => theme.color.neutral};

  @media (min-width: 768px) {
    display: unset;
  }
`;

export const OverviewTableValueContainer = styled.div<{
  readonly withGap?: boolean;
  readonly fixedHeight?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ withGap, theme }) => (withGap ? theme.size.x1 : 0)};
  height: ${({ fixedHeight, theme }) => (fixedHeight ? theme.size.x4 : 'auto')};
`;

export const OverviewTableAthleteWrapper = styled(Text)`
  color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.size.x1};
  :hover {
    color: ${({ theme }) => theme.color.neutral_40};
  }
`;

export const AthleteLink = styled(Link)`
  text-decoration: none;
`;

export const OverviewTableRow = styled.div`
  display: grid;
  grid-template-columns: 100%;
  row-gap: ${({ theme }) => theme.size.x2};
  @media (min-width: 768px) {
    display: contents;
  }
`;
