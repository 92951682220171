import { css } from 'styled-components';

export const TippyContainer = css`
  font-family: Ubuntu;
  background-color: #333;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  position: relative;
  transition-property: transform, visibility, opacity;
  white-space: normal;

  .tippy-content {
    padding: 5px 9px;
    position: relative;
    z-index: 1;
  }

  .tippy-arrow {
    color: #333;
    height: 16px;
    width: 16px;

    :before {
      border-color: transparent;
      border-style: solid;
      content: '';
      position: absolute;
    }
  }

  &[data-animation='fade'][data-state='hidden'] {
    opacity: 0;
  }

  &[data-placement^='top'] > .tippy-arrow {
    bottom: 0;

    :before {
      border-top-color: initial;
      border-width: 8px 8px 0;
      bottom: -7px;
      left: 0;
      transform-origin: center top;
    }
  }

  &[data-placement^='bottom'] > .tippy-arrow {
    top: 0;

    &::before {
      top: -7px;
      left: 0;
      border-width: 0 8px 8px;
      border-bottom-color: initial;
      transform-origin: center bottom;
    }
  }

  &[data-placement^='left'] > .tippy-arrow {
    right: 0;

    &::before {
      border-width: 8px 0 8px 8px;
      border-left-color: initial;
      right: -7px;
      transform-origin: center left;
    }
  }

  &[data-placement^='right'] > .tippy-arrow {
    left: 0;

    &::before {
      left: -7px;
      border-width: 8px 8px 8px 0;
      border-right-color: initial;
      transform-origin: center right;
    }
  }
`;
