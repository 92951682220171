import styled, { css } from 'styled-components';
import { Text, TextSize, TextTag } from '../text';

interface IsActiveProp {
  isActive?: boolean;
}

const StyledDashboard = styled.li<IsActiveProp>`
  border: 1px solid rgba(151, 151, 151, 0.21);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  ${props =>
    props.isActive
      ? css`
          border-color: #4a90e2;
        `
      : css`
          cursor: pointer;
        `}
`;

const DashboardLabel = styled(Text)<IsActiveProp>`
  color: ${props => (props.isActive ? `#4a90e2` : '#4a4a4aff')};
`;

const DashboardDescription = styled(Text)<IsActiveProp>`
  color: #9b9b9b;
`;

export interface DashboardProps {
  id: string | number;
  label: string;
  description: string;
  isActive?: boolean;
  onClick(dashboardId: string | number): void;
}

export function Dashboard(props: DashboardProps): JSX.Element {
  const { id, isActive, label, onClick, description } = props;
  function handleClick() {
    onClick(id);
  }
  return (
    <StyledDashboard role="button" onClick={handleClick} isActive={isActive}>
      <DashboardLabel
        isActive={isActive}
        size={TextSize.s12}
        tag={TextTag.h3}
        medium
      >
        {label}
      </DashboardLabel>
      <DashboardDescription size={TextSize.s10} tag={TextTag.div}>
        {description}
      </DashboardDescription>
    </StyledDashboard>
  );
}
