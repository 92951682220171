import { UserGroupId, UserId } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';

export interface SubscribeToCalendarRequestParams {
  calendarName: string;
  userId: UserId | undefined;
  userGroupId: UserGroupId | undefined;
  start?: string;
  end?: string;
}
export function subscribeToCalendar(
  params: SubscribeToCalendarRequestParams,
  cancelToken: CancelTokenSource
): AxiosPromise<void> {
  return axios.post('/api/calendar/googlesubscribe/plan', params, {
    cancelToken: cancelToken.token,
  });
}
