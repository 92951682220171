import { formatNumber } from './format-number';

export function formatDecimalNumber(
  value: number | string | null | undefined,
  format: string | number | null | undefined
): string {
  if (value === null || value === undefined) {
    return '';
  }

  const fractionDigits = Number(format);

  if (!format || Number.isNaN(fractionDigits) || fractionDigits === 0) {
    return formatNumber(value);
  }

  const number = typeof value === 'string' ? Number(value) : value;

  return number.toLocaleString('cs', {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });
}
