import { ExportAttendanceToXlsxRequestParams } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';

export function exportAttendanceToXlsx(
  params: ExportAttendanceToXlsxRequestParams,
  cancelToken: CancelTokenSource
): AxiosPromise<BlobPart> {
  return axios.post(`api/attendance/export`, params, {
    responseType: 'blob',
    cancelToken: cancelToken.token,
  });
}
