import { GoalRowProps } from './goal-row';
import { GoalLabelWrapper, TextSize } from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { GoalDayLabel } from './goal-day-label';

function InternalGoalLabel(props: GoalRowProps): JSX.Element {
  const { attribute, week, cycle } = props;

  return (
    <GoalLabelWrapper size={TextSize.s12} isWeekDay={!cycle && !week}>
      {cycle && <FormattedMessage id="diary.goals.cycle" tagName="span" />}
      {week && <FormattedMessage id="diary.goals.week" tagName="span" />}
      {!cycle && !week && <GoalDayLabel attribute={attribute} />}
    </GoalLabelWrapper>
  );
}

export const GoalLabel = observer(InternalGoalLabel);
