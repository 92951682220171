import { Tippy } from '@yarmill/components';
import {
  DropdownButtonWrapper,
  DropdownProvider,
  Priority,
  getAppearanceForPriority,
} from '@yarmill/components-2';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { OkrPriorities, OkrPriority } from '../types';

interface PriorityDropdownProps {
  readonly selectedValue: OkrPriority | null;
  readonly handleSelect: (value: OkrPriority) => void;
  readonly disabled?: boolean;
}
export function PriorityDropdown({
  selectedValue,
  handleSelect,
  disabled,
}: PriorityDropdownProps): JSX.Element {
  const intl = useIntl();

  const priorities = useMemo(
    () =>
      OkrPriorities.map(priority => ({
        label: intl.formatMessage({ id: `okrs.priority.${priority}` }),
        icon: <Priority priority={priority} variant="compact" hideText />,
        value: priority,
      })),
    [intl]
  );

  return (
    <Tippy tooltipContent="okrs.priority" noWrapper touch={false}>
      <DropdownProvider
        keyboardShortcut="P"
        options={priorities}
        selectedValue={selectedValue ?? undefined}
        searchInputPlaceholder={intl.formatMessage({
          id: 'okrs.form.priority.label',
        })}
        handleSelect={handleSelect as any}
        disabled={disabled}
      >
        <DropdownButtonWrapper
          appearance={getAppearanceForPriority(selectedValue ?? 'low')}
          disabled={disabled}
        >
          <Priority priority={selectedValue ?? 'low'} hideText />
        </DropdownButtonWrapper>
      </DropdownProvider>
    </Tippy>
  );
}
