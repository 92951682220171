import { exportSeasonEvaluationToXlsx } from './export-season-evaluation-to-xlsx';
import { exportSeasonEvaluationToPdf } from './export-season-evaluation-to-pdf';
import { exportSeasonSummaryToXlsx } from './export-season-summary-to-xlsx';
import { exportReportingPageToPdf } from './export-reporting-page-to-pdf';
import { exportDiaryAttributesToPdf } from './export-diary-attributes-to-pdf';
import { exportDiaryActivitiesToPdf } from './export-diary-activities-to-pdf';
import { exportOkrsToPdf } from './export-okrs-to-pdf';
import { exportAttendanceToXlsx } from './export-attendance-to-xlsx';

export const api = {
  exportSeasonEvaluationToXlsx,
  exportSeasonEvaluationToPdf,
  exportSeasonSummaryToXlsx,
  exportReportingPageToPdf,
  exportDiaryAttributesToPdf,
  exportDiaryActivitiesToPdf,
  exportOkrsToPdf,
  exportAttendanceToXlsx,
};
