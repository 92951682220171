import { Link, SidebarLinkContent } from '@yarmill/components';
import { GroupStore } from '@yarmill/types';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { trackSecondarySidebarLinkClick } from '../google-analytics/utils';

export interface SidebarItemProps {
  readonly isActive: boolean;
  readonly group: GroupStore;
  readonly onClick: () => void;
}

export const SidebarItem = observer(function SidebarItem(
  props: SidebarItemProps
): JSX.Element {
  const { isActive, group, onClick } = props;
  const handleClick = () => {
    trackSecondarySidebarLinkClick(group.name);
    onClick();
  };

  return (
    <Link
      to={`/settings/groups/${group.id}`}
      $isActive={isActive}
      onClick={handleClick}
      data-cy="sidebar-list-item"
    >
      <SidebarLinkContent
        secondaryInfo={
          <FormattedMessage
            id="settings.sidebar.groups.activeAthletes"
            values={{ count: group.athletes.length }}
          />
        }
        isActive={isActive}
      >
        {group.name}
      </SidebarLinkContent>
    </Link>
  );
});
