import { DataItem } from '../../reporting';
import { getAxisPosition } from '../../reporting/utils/get-axis-position';
import { useScale } from '../hooks/use-scale';
import { BarChart } from '../types';
import { getBarSize } from './get-bar-size';

export function getBarDimensions(
  config: BarChart,
  item: DataItem,
  xScale: ReturnType<typeof useScale>,
  yScale: ReturnType<typeof useScale>
) {
  // Bar Chart works only for categorical scale
  if (!('bandwidth' in xScale) || 'bandwidth' in yScale) {
    return null;
  }
  const xValue = config.getXValue(item) as string;
  const yValue = config.getYValue(item);
  const domainY = yScale.domain();
  const axisPosition = getAxisPosition(domainY as number[]);
  const x =
    (xScale(xValue) || 0) +
    ((1 - config.getBarWidth(item)) * xScale.bandwidth()) / 2;
  const y =
    yValue === null ? 0 : yValue < 0 ? yScale(axisPosition) : yScale(yValue);
  const width = xScale.bandwidth() * config.getBarWidth(item);
  const height = getBarSize(yScale, yValue);

  return {
    x,
    y,
    width,
    height,
  };
}
