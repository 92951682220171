import { styled } from '@yarmill/components';
import { Text } from '@yarmill/components-2';

export const EmptyObjectivesLayout = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.size.x9} auto;
`;

export function EmptyObjectivesIllustration(): JSX.Element {
  return (
    <svg
      width="148"
      height="137"
      viewBox="0 0 148 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="path-1-outside-1_3068_11616"
        maskUnits="userSpaceOnUse"
        x="-0.872681"
        y="12.1273"
        width="98"
        height="98"
        fill="black"
      >
        <path d="M49.3009 65.4829C51.7015 64.8396 53.1262 62.3721 52.4829 59.9715C51.8397 57.5709 49.3722 56.1463 46.9716 56.7896C44.571 57.4328 43.1463 59.9003 43.7896 62.3009C44.4328 64.7015 46.9003 66.1261 49.3009 65.4829Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.9597 82.8696C65.9627 79.6534 73.0858 67.3158 69.8696 55.3128C66.6534 43.3098 54.3158 36.1867 42.3128 39.4029C30.3098 42.6191 23.1867 54.9567 26.4029 66.9597C29.6191 78.9626 41.9567 86.0857 53.9597 82.8696ZM51.048 72.0029C57.0495 70.3948 60.611 64.226 59.0029 58.2245C57.3948 52.223 51.226 48.6615 45.2245 50.2696C39.223 51.8777 35.6615 58.0464 37.2696 64.0479C38.8777 70.0494 45.0465 73.611 51.048 72.0029Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.4537 95.9096C76.6585 90.7636 88.0555 71.0235 82.9096 51.8187C77.7637 32.614 58.0235 21.217 38.8188 26.3629C19.614 31.5088 8.21702 51.2489 13.3629 70.4537C18.5088 89.6585 38.249 101.055 57.4537 95.9096ZM55.1244 87.2162C69.528 83.3568 78.0757 68.5517 74.2163 54.1481C70.3568 39.7445 55.5517 31.1968 41.1481 35.0562C26.7446 38.9157 18.1968 53.7208 22.0563 68.1243C25.9157 82.5279 40.7208 91.0757 55.1244 87.2162Z"
        />
      </mask>
      <path
        d="M49.3009 65.4829C51.7015 64.8396 53.1262 62.3721 52.4829 59.9715C51.8397 57.5709 49.3722 56.1463 46.9716 56.7896C44.571 57.4328 43.1463 59.9003 43.7896 62.3009C44.4328 64.7015 46.9003 66.1261 49.3009 65.4829Z"
        fill="#DDE3EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.9597 82.8696C65.9627 79.6534 73.0858 67.3158 69.8696 55.3128C66.6534 43.3098 54.3158 36.1867 42.3128 39.4029C30.3098 42.6191 23.1867 54.9567 26.4029 66.9597C29.6191 78.9626 41.9567 86.0857 53.9597 82.8696ZM51.048 72.0029C57.0495 70.3948 60.611 64.226 59.0029 58.2245C57.3948 52.223 51.226 48.6615 45.2245 50.2696C39.223 51.8777 35.6615 58.0464 37.2696 64.0479C38.8777 70.0494 45.0465 73.611 51.048 72.0029Z"
        fill="#DDE3EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.4537 95.9096C76.6585 90.7636 88.0555 71.0235 82.9096 51.8187C77.7637 32.614 58.0235 21.217 38.8188 26.3629C19.614 31.5088 8.21702 51.2489 13.3629 70.4537C18.5088 89.6585 38.249 101.055 57.4537 95.9096ZM55.1244 87.2162C69.528 83.3568 78.0757 68.5517 74.2163 54.1481C70.3568 39.7445 55.5517 31.1968 41.1481 35.0562C26.7446 38.9157 18.1968 53.7208 22.0563 68.1243C25.9157 82.5279 40.7208 91.0757 55.1244 87.2162Z"
        fill="#DDE3EB"
      />
      <path
        d="M49.3009 65.4829C51.7015 64.8396 53.1262 62.3721 52.4829 59.9715C51.8397 57.5709 49.3722 56.1463 46.9716 56.7896C44.571 57.4328 43.1463 59.9003 43.7896 62.3009C44.4328 64.7015 46.9003 66.1261 49.3009 65.4829Z"
        stroke="white"
        strokeWidth="24"
        mask="url(#path-1-outside-1_3068_11616)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.9597 82.8696C65.9627 79.6534 73.0858 67.3158 69.8696 55.3128C66.6534 43.3098 54.3158 36.1867 42.3128 39.4029C30.3098 42.6191 23.1867 54.9567 26.4029 66.9597C29.6191 78.9626 41.9567 86.0857 53.9597 82.8696ZM51.048 72.0029C57.0495 70.3948 60.611 64.226 59.0029 58.2245C57.3948 52.223 51.226 48.6615 45.2245 50.2696C39.223 51.8777 35.6615 58.0464 37.2696 64.0479C38.8777 70.0494 45.0465 73.611 51.048 72.0029Z"
        stroke="white"
        strokeWidth="24"
        mask="url(#path-1-outside-1_3068_11616)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.4537 95.9096C76.6585 90.7636 88.0555 71.0235 82.9096 51.8187C77.7637 32.614 58.0235 21.217 38.8188 26.3629C19.614 31.5088 8.21702 51.2489 13.3629 70.4537C18.5088 89.6585 38.249 101.055 57.4537 95.9096ZM55.1244 87.2162C69.528 83.3568 78.0757 68.5517 74.2163 54.1481C70.3568 39.7445 55.5517 31.1968 41.1481 35.0562C26.7446 38.9157 18.1968 53.7208 22.0563 68.1243C25.9157 82.5279 40.7208 91.0757 55.1244 87.2162Z"
        stroke="white"
        strokeWidth="24"
        mask="url(#path-1-outside-1_3068_11616)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.094 15.4754C114.236 14.5884 113.05 14.0947 111.816 14.1109L93.3526 14.3531C90.9821 14.3842 89.0463 16.2569 88.9367 18.6251L88.0826 37.0702C88.0255 38.3031 88.4797 39.5049 89.3378 40.392L110.514 62.2819C112.234 64.06 115.07 64.107 116.848 62.3869L136.165 43.6995C137.943 41.9794 137.99 39.1435 136.27 37.3654L115.094 15.4754ZM95.256 27.1818C96.9762 28.9599 99.812 29.0069 101.59 27.2867C103.368 25.5666 103.415 22.7307 101.695 20.9526C99.975 19.1745 97.1391 19.1275 95.361 20.8476C93.5829 22.5678 93.5359 25.4036 95.256 27.1818Z"
        fill="#DDE3EB"
      />
      <path
        d="M95.1126 116.156C94.3363 118.788 91.5201 120.277 88.8225 119.482C86.1249 118.686 84.5674 115.907 85.3437 113.275C86.12 110.642 88.9362 109.153 91.6338 109.948C94.3315 110.744 95.889 113.523 95.1126 116.156Z"
        fill="#DDE3EB"
      />
      <path
        d="M92.8021 107.758C90.2107 107.366 88.6894 105.391 89.0577 102.841C89.4087 100.412 91.0462 99.0132 93.6662 98.0088C97.3902 96.5571 98.3145 95.8122 98.5994 93.8399C98.8253 92.2765 97.66 90.9204 95.892 90.6529C94.3663 90.4219 93.2033 90.9341 92.2717 92.2432C91.2432 93.7096 89.4677 94.3503 87.6029 94.068C84.9872 93.6722 83.5247 91.8041 83.8999 89.3785C84.0493 88.3443 84.4582 87.3985 85.123 86.5652C87.2146 83.9078 91.731 82.4531 97.4709 83.3219C105.221 84.4948 109.651 89.1223 108.778 95.1593C108.275 98.6469 106.356 100.79 102.345 102.345C98.6765 103.756 98.0152 104.394 97.1701 105.79C96.1933 107.411 94.7639 108.055 92.8021 107.758Z"
        fill="#DDE3EB"
      />
    </svg>
  );
}
export const EmptyObjectivesHeadline = styled(Text)`
  color: ${({ theme }) => theme.color.neutral};
  margin-bottom: ${({ theme }) => theme.size.x1};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`;
export const EmptyObjectivesText = styled(Text)`
  color: ${({ theme }) => theme.color.neutral};
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  margin-bottom: ${({ theme }) => theme.size.x4};
`;

export const EmptyObjectivesButtonsWrapper = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: ${({ theme }) => theme.size.x05};
`;
