import {
  ContentBox,
  ContentBoxHead,
  ExternalIconName,
  Icon,
  IconSize,
  Text,
  TextSize,
  TextTag,
  YarmillIcon,
  styled,
} from '@yarmill/components';
import { EvidenceObject as iEvidenceObject } from '@yarmill/types';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { EvidenceItem } from './evidence-item';
import { useSetVisibleCategory } from './hooks/use-set-visible-category';

export interface EvidenceCategoryProps {
  definition: iEvidenceObject;
}

const StyledCategoryHeadText = styled(Text)`
  line-height: 20px;
`;

const CategoryHead = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 10px;
  align-items: center;
  color: #4a4a4a;
`;

const EvidenceContentBox = styled(ContentBox)`
  min-width: 0;
  box-shadow: none;
  border: none;

  @media print {
    padding: 12px;
    border: 1px solid #4a4a4a;
  }
`;

function getIcon(name?: ExternalIconName | null): JSX.Element | null {
  if (name) {
    return (
      <Icon size={IconSize.s20}>
        <YarmillIcon name={name} />
      </Icon>
    );
  }

  return null;
}

export function EvidenceCategory(props: EvidenceCategoryProps): JSX.Element {
  const { definition } = props;
  const categoryRef = useRef<HTMLDivElement>(null);
  useSetVisibleCategory(definition.ObjectKey, categoryRef);

  return (
    <EvidenceContentBox id={definition.ObjectKey} ref={categoryRef}>
      {!definition.Features?.HideHeader && (
        <ContentBoxHead>
          <CategoryHead>
            {getIcon(definition.Features?.IconName)}
            <StyledCategoryHeadText
              size={TextSize.s16}
              tag={TextTag.div}
              medium
            >
              <FormattedMessage
                id={`${definition.ModuleKey}.${definition.ObjectKey}`}
              />
            </StyledCategoryHeadText>
          </CategoryHead>
        </ContentBoxHead>
      )}
      {definition.Children.map(item => (
        <EvidenceItem
          key={`${item.ModuleKey}.${item.ObjectKey}`}
          definition={item}
        />
      ))}
    </EvidenceContentBox>
  );
}
