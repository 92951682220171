import {
  RootStore,
  AnnouncementStore as iAnnouncementStore,
} from '@yarmill/types';
import { action, makeObservable, observable } from 'mobx';
import { Announcement, AnnouncementType } from '../types';
import { incrementNumberOfViews, shouldShowAnnouncement } from '../utils';

export class AnnouncementStore implements iAnnouncementStore {
  readonly _definition: Announcement;
  private readonly _rootStore: RootStore;

  @observable
  private _isVisible: boolean;
  constructor(rootStore: RootStore, definition: Announcement) {
    this._definition = definition;
    this._rootStore = rootStore;
    this._isVisible = shouldShowAnnouncement(
      rootStore.currentUserStore,
      definition
    );
    makeObservable(this);
  }

  get code(): string {
    return this._definition.code;
  }

  get type(): AnnouncementType {
    return this._definition.type;
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  @action
  readonly close = (): void => {
    this._isVisible = false;
    incrementNumberOfViews(
      this.code,
      this._rootStore.currentUserStore,
      this._rootStore.settingsService
    );
  };
}
