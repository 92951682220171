import { ComponentType } from 'react';
import { ControlProps, components } from 'react-select';
import styled, { css } from 'styled-components';
import { ExternalIcon } from '../external-icon';
import { FormControlVariant } from '../helpers';
import { Icon, IconSize } from '../icon';
import { baseInputStyle } from '../text-input';

export const BaseMultiSelectStyles = css<{ readonly classNamePrefix: string }>`
  ${({ classNamePrefix }) => css`
    .${classNamePrefix}__control {
      min-height: 65px;
      height: auto;
    }

    .${classNamePrefix}__value-container {
      padding: 0 8px 0 60px;
    }

    .${classNamePrefix}__input {
      font-family: Ubuntu;
      font-size: 18px;
    }

    .${classNamePrefix}__placeholder {
      color: #9b9b9b;
      font-weight: 300;
    }
    .${classNamePrefix}__multi-value__label {
      font-size: 12px;
    }
    .${classNamePrefix}__multi-value__remove {
      cursor: pointer;
    }
  `}
`;

export const ControlWrapper = styled.div`
  position: relative;
`;

export const FormControlIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: #4a4a4a;
`;
export const StyledControl: ComponentType = styled(components.Control)<{
  variant: FormControlVariant;
}>`
  ${baseInputStyle};
  padding: 0;
  border-radius: 8px !important;
  border: 1px solid #979797 !important;
  :focus,
  :focus-within {
    box-shadow: #4a90e2 0 0 5px;
    border-color: #4a90e2 !important;
  }
`;

export const Control = (props: ControlProps) => (
  <ControlWrapper>
    <StyledControl {...(props as any)} variant={FormControlVariant.big}>
      {props.children}
    </StyledControl>
    <FormControlIcon size={IconSize.s20}>
      <ExternalIcon name="Search" />
    </FormControlIcon>
  </ControlWrapper>
);
