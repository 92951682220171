import { styled, Text, TextSize, WhiteSpace } from '@yarmill/components';
import { PropsWithChildren } from 'react';

const StyledTag = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #ffa900;
  font-size: 12px;
  padding: 2px 8px;
  vertical-align: middle;
  background-color: #fff9eb;
  box-sizing: border-box;
`;

export function Tag(props: PropsWithChildren): JSX.Element {
  const { children } = props;

  return (
    <StyledTag>
      <Text size={TextSize.s12} whiteSpace={WhiteSpace.noWrap} inheritColor>
        {children}
      </Text>
    </StyledTag>
  );
}
