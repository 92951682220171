import {
  StyledTd,
  Text,
  TextSize,
  WhiteSpace,
  styled,
} from '@yarmill/components';
import { Units } from '@yarmill/types';
import {
  formatDecimalNumber,
  formatNumber,
  formatValueByUnit,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { CsvTableAlignment, CsvTableDataItem, CsvTableUnit } from './types';

export interface CsvTableCellProps {
  data: CsvTableDataItem | undefined;
  unit?: CsvTableUnit | null;
  format?: string | null;
  alignment?: CsvTableAlignment | null;
  translateValue?: boolean;
}

const StyledCsvTableCell = styled(StyledTd)`
  padding: 4px 8px;
  font-family: "Roboto Mono", serif;
  text-align: right;

  .ql-editor {
    text-align: ${({ align }) => align};
  }

  @media print {
    page-break-inside: avoid;
  }
`;

function formatValue(
  value: string | number | undefined,
  unit?: CsvTableUnit | null,
  format?: string | null
): ReactNode {
  if (value === '0' || value === 0) {
    return '';
  }

  if (value !== undefined && unit && unit !== 'short-text') {
    switch (unit) {
      case 'number':
        return formatNumber(value);
      case 'decimal-number':
        return formatDecimalNumber(value, format);
      default:
        return formatValueByUnit(value, unit);
    }
  }

  return value;
}

function shouldWrap(unit?: CsvTableUnit | null): boolean {
  if (
    unit === Units.hod ||
    unit === Units.min ||
    unit === Units.longTime ||
    unit === 'number' ||
    unit === 'decimal-number'
  ) {
    return false;
  }
  return true;
}

export const CsvTableCell = observer(function CsvTableCell(
  props: CsvTableCellProps
): JSX.Element {
  const { data, unit, format, alignment, translateValue } = props;
  const intl = useIntl();
  const translatedValue =
    translateValue && data ? intl.formatMessage({ id: String(data) }) : data;
  const value = formatValue(translatedValue, unit, format);

  return (
    <StyledCsvTableCell align={alignment || 'right'}>
      <Text
        size={TextSize.s12}
        whiteSpace={shouldWrap(unit) ? undefined : WhiteSpace.noWrap}
        monoSpace
      >
        {value}
      </Text>
    </StyledCsvTableCell>
  );
});
