import { formatDistanceWithoutUnit, formatSeconds } from '@yarmill/utils';
import moment from 'moment';
export function formatSecondsWithWhitespaceHack(seconds: number): string {
  return formatSeconds(seconds);
}

export function formatSecondsWithUnit(seconds: number): string {
  return moment.duration(seconds, 'seconds').format('h:mm:ss', {
    trim: false,
  });
}

export function formatDistance(meters: number): string {
  return `${formatDistanceWithoutUnit(meters)}\u00A0\u00A0\u00A0`;
}

export function formatAscentWithUnit(meters: number): string {
  return `${meters.toLocaleString('cs', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })}\u00A0M`;
}

export function sortHeartRateZones<T extends { bottom: number }>(
  a: T,
  b: T
): number {
  return b.bottom - a.bottom;
}

export function formatZonesDuration(duration: number): string {
  return new Date(duration * 1000).toISOString().substr(11, 8);
}
