interface StyledPropsExtractorProps {
  children(props: any): JSX.Element;
}

export function StyledPropsExtractor({
  children,
  ...other
}: StyledPropsExtractorProps) {
  return children(other);
}
