import { Locale, UserGroupId, UserId } from '@yarmill/types';
import { EvidenceDataObject } from '@yarmill/types';
import { axios, devMode, instance } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export interface GetEvidenceRequestParams {
  moduleKey: string;
  userId: UserId | null;
  groupId: UserGroupId | null;
  language: Locale;
}

export function getEvidenceData(
  dataUrl: string,
  params: GetEvidenceRequestParams
): AxiosPromise<EvidenceDataObject[]> {
  return axios.get(dataUrl, {
    params: {
      groupId:
        params.userId === null ? (params.groupId ?? undefined) : undefined,
      userId: params.userId !== null ? params.userId : undefined,
      moduleKey: params.moduleKey,
      language: params.language,
      instanceCode: devMode ? instance : undefined,
    },
  });
}
