import { MutableRefObject, RefObject, useEffect, useState } from 'react';

export function useElementHeight(
  ref: RefObject<HTMLElement> | MutableRefObject<HTMLElement>
): number | undefined {
  const [elementHeight, setElementHeight] = useState<number>();

  useEffect(() => {
    const input = ref.current;
    function onResize() {
      if (input) {
        setElementHeight(input.clientHeight);
      }
    }

    const resizeObserver = new ResizeObserver(onResize);
    if (input) {
      resizeObserver.observe(input);
      onResize();
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  return elementHeight;
}
