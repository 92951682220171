import { createElement } from 'react';

export function Clean() {
  return createElement(
    'svg',
    {
      viewBox: '0 0 18 18',
      xmlns: 'http://www.w3.org/2000/svg',
      fill: 'currentColor',
      role: 'img',
    },
    createElement('path', {
      fill: 'none',
      stroke: 'currentColor',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.6',
      d: 'M5 3h8M6 12l3.35-9M11 11l4 4M15 11l-4 4',
    }),
    createElement('rect', {
      fill: 'currentColor',
      height: '1',
      rx: '.5',
      ry: '.5',
      width: '7',
      x: '2',
      y: '14',
    })
  );
}
