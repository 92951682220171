import { UserGroupId, UserId } from '@yarmill/types';
import { axios, devMode, instance } from '@yarmill/utils';
import { AxiosResponse, CancelTokenSource } from 'axios';

export interface SuggestTextCompletionRequestParams {
  readonly query: string;
  readonly userId: UserId | null;
  readonly userGroupId: UserGroupId | null;
  readonly cancelToken?: CancelTokenSource;
}

interface SuggestTextCompletionResponse {
  readonly completion: string;
}

export function suggestTextCompletion(
  url: string,
  {
    query,
    userId,
    userGroupId,
    cancelToken,
  }: SuggestTextCompletionRequestParams
): Promise<AxiosResponse<SuggestTextCompletionResponse>> {
  return axios.get(`${url}/api/yollanda-completion`, {
    params: {
      query,
      userId: userId || undefined,
      userGroupId: userId ? undefined : userGroupId || undefined,
      instanceCode: devMode ? instance : undefined,
    },
    cancelToken: cancelToken?.token,
  });
}
