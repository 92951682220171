import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';
import { ApiFilters } from '../../../types';
import { Trend } from '../types';

export function loadTrend(
  filters: ApiFilters,
  isForGroup?: boolean
): AxiosPromise<Trend[]> {
  return axios.post(
    `/api/Analytics/SPS-SLCR/DiaryFactsTrend${isForGroup ? 'Groups' : ''}`,
    filters
  );
}
