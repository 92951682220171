import * as React from 'react';
import { Command as ICommand } from 'react-command-palette';
import { ExternalIcon, Icon, IconSize } from '@yarmill/components';
import styled from 'styled-components';

const StyledCommand = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-grow: 0;
  font-family: Ubuntu;
  font-weight: 300;
  font-size: 14px;
`;

const StyledIconWrapper = styled.div`
  width: 24px;
  margin-right: 16px;
`;
const StyledText = styled.span`
  flex-grow: 1;
`;
const StyledKeys = styled.div`
  display: flex;
`;

const StyledKey = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(80, 80, 84);
  border-radius: 4px;
  text-transform: capitalize;
  font-size: 11px;
  color: rgb(215, 216, 219);
  padding: 4px;
  flex-grow: 0;
  min-width: 24px;
  & + & {
    margin-left: 4px;
  }
  &[data-key='cmd'] {
    font-size: 0;
    :after {
      font-size: 11px;
      content: '⌘';
    }
  }
  &[data-key='shift'] {
    font-size: 0;
    :after {
      font-size: 11px;
      content: '⇧';
    }
  }
  &[data-key='option'] {
    font-size: 0;
    :after {
      font-size: 11px;
      content: '⌥';
    }
  }
`;

interface CommandProps extends ICommand {
  highlight?: string[];
}

function splice(
  target: string,
  start: number,
  delCount: number,
  insert: string
) {
  return (
    target.slice(0, start) + insert + target.slice(start + Math.abs(delCount))
  );
}

function findIndexes(where: string, regex: RegExp): number[] {
  let result;
  const indices: number[] = [];

  while ((result = regex.exec(where))) {
    indices.push(result.index);
  }

  return indices;
}

function highlightText(text: string, defaultText: string): string {
  let highlightedText = defaultText;

  const indicesStart = findIndexes(text, /<b>/gi);
  const indicesEnd = findIndexes(text, /<\/b>/gi);

  indicesStart.forEach((index, idx) => {
    highlightedText = splice(highlightedText, index, 0, '<b>');
    highlightedText = splice(highlightedText, indicesEnd[idx], 0, '</b>');
  });

  return highlightedText;
}

export function Command(props: CommandProps): React.ReactNode {
  const { name, icon, highlight = [], keys = [] } = props;
  const [nameHighlight, plainTextHighlight] = highlight;
  let highlightedText = highlightText(plainTextHighlight || '', name);

  if (highlightedText === name) {
    highlightedText = highlightText(nameHighlight || '', name);
  }

  return (
    <StyledCommand>
      {icon && (
        <StyledIconWrapper>
          <Icon size={IconSize.s12}>
            <ExternalIcon name={icon} />
          </Icon>
        </StyledIconWrapper>
      )}
      <StyledText dangerouslySetInnerHTML={{ __html: highlightedText }} />
      <StyledKeys>
        {keys.map(key => (
          <StyledKey key={key} data-key={key}>
            {key}
          </StyledKey>
        ))}
      </StyledKeys>
    </StyledCommand>
  );
}
