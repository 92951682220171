import { Link } from 'react-router-dom';
import { css, Icon, styled } from '@yarmill/components';
import { getTextAppearanceStyles, Text } from './text';

export const SidebarNavigationContainer = styled.div`
  display: flex;
  flex: initial;
  flex-direction: column;
  scrollbar-gutter: auto;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 ${({ theme }) => theme.size.x2} ${({ theme }) => theme.size.x5};

  @media (min-width: 768px) {
    padding-right: 0;
  }
`;

export const SidebarNavigationSectionLabel = styled(Text)`
  ${getTextAppearanceStyles('text12strong')}
  color: ${({ theme }) => theme.color.neutral};
  margin-bottom: ${({ theme }) => theme.size.x1};
  margin-top: ${({ theme }) => theme.size.x3};
  text-transform: uppercase;
`;

export const NavigationItemWrapper = styled.div`
  border-radius: ${({ theme }) => theme.size.x05};
  margin: ${({ theme }) => theme.size.x0125} 0;
  position: relative;
`;
export const NavigationButton = styled(Link)<{ readonly $isActive?: boolean }>`
  display: flex;
  align-items: center;
  flex-grow: 1;
  ${({ $isActive }) =>
    getTextAppearanceStyles($isActive ? 'task13strong' : 'task13')};
  border-radius: ${({ theme }) => theme.borderRadius.x1};
  height: ${({ theme }) => theme.size.x5};
  white-space: nowrap;
  padding: ${({ theme }) => theme.size.x1};
  cursor: pointer;
  column-gap: ${({ theme }) => theme.size.x1};
  color: ${({ theme }) => theme.color.neutral};
  text-decoration: none;
  font-weight: ${({ $isActive }) => ($isActive ? 700 : 600)};
  transition-property: background, color, font-weight;
  transition-timing-function: ease;
  transition-duration: 250ms;

  :hover {
    background-color: ${({ theme }) => theme.color.neutralPlate};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.color.navy};
      background-color: ${({ theme }) => theme.color.navy_24};
      font-weight: 700;

      :hover {
        background-color: ${({ theme }) => theme.color.navy_40};
      }
    `};
`;

export const NavigationButtonText = styled(Text)`
  ${getTextAppearanceStyles('task13')};
  font-weight: inherit;
`;
export const GroupsToggle = styled(NavigationButton)`
  color: ${({ theme }) => theme.color.navy};
  line-height: 24px;
  background-color: ${({ theme }) => theme.color.navy}0a;
  margin-bottom: ${({ theme }) => theme.size.x05};
  flex-grow: 0;
  justify-content: space-between;
  width: 100%;
  height: auto;
  min-height: ${({ theme }) => theme.size.x5};
  ${getTextAppearanceStyles('task13strong')};

  :hover {
    background-color: ${({ theme }) => theme.color.navy_24};
    box-shadow: none;
  }
`;

export const GroupsToggleTextWrapper = styled.span`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.size.x1};
  white-space: normal;
`;

export const RoleAvatarIcon = styled(Icon)<{ readonly isCoach?: boolean }>`
  position: relative;
  width: ${({ size }) => size};
  ${({ isCoach }) =>
    isCoach &&
    css`
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.text};
      border: 2px solid ${({ theme }) => theme.color.sand};
    `}
`;
export const CoachSymbol = styled(Icon)`
  color: ${({ theme }) => theme.color.white};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.sand_40};
  background-color: ${({ theme }) => theme.color.sand};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(25%, 25%);
`;

export const NavigationDropdownToggleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: stretch;
  button {
    width: 100%;
  }
`;

export const NavigationTeamContainer = styled.div`
  background-color: ${({ theme }) => theme.color.navyDark}0a;
  padding: ${({ theme }) => theme.size.x1};
  border-radius: ${({ theme }) => theme.borderRadius.x1};
`;

export const NavigationModulesContainer = styled.div`
  padding-right: ${({ theme }) => theme.size.x1};
  display: grid;
  grid-template-columns: 100%;
  row-gap: ${({ theme }) => theme.size.x05};
`;
