import { styled } from '@yarmill/components';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
} from '@floating-ui/react';
import {
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react';
import { DropdownButton } from './dropdown-provider/dropdown-provider';

type PopoverProps = PropsWithChildren<{
  readonly content: JSX.Element | ((close: () => void) => JSX.Element);
}>;

const DropdownContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;

  outline: none;
  backdrop-filter: blur(12px) saturate(190%) contrast(50%) brightness(130%);
  background-color: ${({ theme }) => theme.color.white};
  border: 0.5px solid transparent;
  border-radius: ${({ theme }) => theme.borderRadius.x05};
  box-shadow: ${({ theme }) => theme.boxShadow.bs2};

  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
`;

export const Popover = forwardRef(function Popover(
  { children, content }: PopoverProps,
  forwardedRef: ForwardedRef<HTMLButtonElement>
): JSX.Element {
  const [isOpened, setIsOpened] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpened,
    onOpenChange: setIsOpened,
    placement: 'bottom-start',
    middleware: [flip()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context, {
    event: 'mousedown',
    toggle: true,
    ignoreMouse: false,
  });

  const dismiss = useDismiss(context, { bubbles: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const handleOpen = useCallback(() => setIsOpened(o => !o), []);

  return (
    <>
      <DropdownButton
        ref={useMergeRefs([refs.setReference, forwardedRef])}
        onClick={handleOpen}
        type="button"
        {...getReferenceProps()}
      >
        {children}
      </DropdownButton>
      {isOpened && (
        <FloatingPortal>
          <FloatingFocusManager context={context} visuallyHiddenDismiss>
            <DropdownContainer
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {typeof content === 'function'
                ? content(() => setIsOpened(false))
                : content}
            </DropdownContainer>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
});
