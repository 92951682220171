import {
  toastErrorFileUpload,
  toastShowFileUpload,
  toastSuccessFileUpload,
} from '@yarmill/components';
import { File, FileUploadStore } from '@yarmill/types';
import { useFileUpload } from '@yarmill/utils';
import { useCallback } from 'react';

export function useFilesOverviewFileUpload(
  addAttachment: (file: File) => void,
  addPendingAttachment: (store: FileUploadStore) => void,
  removePendingAttachment: (store: FileUploadStore) => void
) {
  const onSuccess = useCallback(
    async (fileUpload: FileUploadStore, toastId: string | number) => {
      const file = fileUpload.uploadedFile;
      if (file) {
        addAttachment(fileUpload.uploadedFile);

        toastSuccessFileUpload(toastId);
      } else {
        toastErrorFileUpload(toastId);
      }
      removePendingAttachment(fileUpload);
    },
    [addAttachment, removePendingAttachment]
  );

  const onStart = useCallback(
    (fileUpload: FileUploadStore) => {
      addPendingAttachment(fileUpload);
      return toastShowFileUpload(fileUpload);
    },
    [addPendingAttachment]
  );

  const onError = useCallback(
    (fileUpload: FileUploadStore, toastId: string | number) => {
      removePendingAttachment(fileUpload);
      toastErrorFileUpload(toastId);
    },
    [removePendingAttachment]
  );

  return useFileUpload(onSuccess, onError, onStart);
}
