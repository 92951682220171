import { FloatingBarChart } from '../types';
import { Bar } from './primitives/bar';
import { useXYChartContext } from '../xy-chart-context';
import { DataItem } from '../../reporting/types';
import { getFloatingBarDimensions } from '../utils/get-floating-bar-dimensions';
import { useState } from 'react';

interface BarsProps {
  readonly config: FloatingBarChart;
  readonly data: DataItem[];
}

export function FloatingBars({ config, data }: BarsProps) {
  const { xScale, yScale } = useXYChartContext();
  const highlightAttribute = config.highlightAttribute;
  const [highlightedValue, setHighlightedValue] = useState<
    number | string | null | undefined
  >(undefined);

  return (
    <>
      {data.map((item, idx) => {
        const dimensions = getFloatingBarDimensions(
          config,
          item,
          xScale,
          yScale
        );

        if (!dimensions) {
          return null;
        }

        const { x, y, width, height } = dimensions;

        if (!x || !y || !width || !height) {
          return null;
        }

        const highlightColor = config.getHighlightColor(item);
        const isHighlighted = Boolean(
          highlightAttribute && item[highlightAttribute] === highlightedValue
        );

        return (
          <Bar
            key={idx}
            x={x}
            y={y}
            width={width}
            height={height}
            color={config.getColor(item)}
            opacity={config.getOpacity(item) ?? 1}
            highlightColor={highlightColor}
            isHighlighted={isHighlighted}
            onMouseEnter={
              highlightAttribute
                ? () => setHighlightedValue(item[highlightAttribute])
                : undefined
            }
            onMouseLeave={
              highlightAttribute
                ? () => setHighlightedValue(undefined)
                : undefined
            }
          />
        );
      })}
    </>
  );
}
