import {
  Action,
  Location as HLocation,
  Path,
  TransitionPromptHook,
  UnregisterCallback,
} from 'history';
import { useMemo } from 'react';
import { useHistory as useRouterHistory } from 'react-router-dom';

interface History {
  push(path: Path, state?: any): void;
  replace(path: Path, state?: any): void;
  listen(
    callback: (location: HLocation<any>, action: Action) => void
  ): UnregisterCallback;
  block(
    prompt?: boolean | string | TransitionPromptHook<any>
  ): UnregisterCallback;
}

export function useHistory(): History {
  const history = useRouterHistory();
  const { push, listen, block, replace } = history;

  return useMemo(
    () => ({
      push,
      listen,
      block,
      replace,
    }),
    [push, listen, block, replace]
  );
}
