import { Icon, IconSize, Tippy } from '@yarmill/components';
import {
  Button,
  ButtonAppearance,
  DropdownOption,
  DropdownProvider,
  StyledButtonProps,
} from '@yarmill/components-2';
import { Download } from '@yarmill/icons-2';
import { useConfig, useRootStore } from '@yarmill/utils';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useOkrsStore } from '../okrs-store-context';
import { OkrExportTemplate } from '../types';

export function ExportButton() {
  const rootStore = useRootStore();
  const okrsStore = useOkrsStore();
  const intl = useIntl();
  const templates = useConfig('okrExportTemplates');

  const handleExport = useCallback(
    async (template: OkrExportTemplate | undefined) => {
      const seasonId = okrsStore.currentSeason?.id;
      const userId = okrsStore.athleteId;
      const fileName = `OKRs - ${okrsStore.athlete?.displayName}`;
      const language = rootStore.intlStore.locale;

      if (!seasonId || !userId) {
        return;
      }
      await rootStore.exportService.exportOkrsToPdf(
        {
          language,
          seasonId,
          userId,
          templateCode: template?.templateCode,
        },
        fileName
      );
    },
    [rootStore, okrsStore]
  );

  const options: DropdownOption<void>[] = useMemo(
    () =>
      templates.map(template => ({
        label: intl.formatMessage({
          id: `okrs.export.template.${template.templateCode}`,
        }),
        onClick: () => handleExport(template),
      })),

    [handleExport, intl, templates]
  );

  const buttonProps = {
    $appearance: ButtonAppearance.Secondary,
    'aria-label': intl.formatMessage({ id: 'okrs.export' }),
    $appearanceStyle: 'neutral',
    $iconOnly: true,
    disabled: okrsStore.objectives.length === 0,
  };

  return (
    <Tippy tooltipContent="okrs.export" noWrapper touch={false}>
      {templates.length > 1 && !buttonProps.disabled ? (
        <DropdownProvider options={options}>
          <Button {...(buttonProps as StyledButtonProps)} as="div">
            <Icon size={IconSize.s24}>
              <Download />
            </Icon>
          </Button>
        </DropdownProvider>
      ) : (
        <Button
          {...(buttonProps as StyledButtonProps)}
          type="button"
          onClick={
            buttonProps.disabled ? undefined : () => handleExport(templates[0])
          }
        >
          <Icon size={IconSize.s24}>
            <Download />
          </Icon>
        </Button>
      )}
    </Tippy>
  );
}
