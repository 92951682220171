import {
  CloseButton,
  FullScreenLayerCloseButtonWrapper,
  LayerPortal,
} from '@yarmill/components';
import { getCgmLink, useHistory, useLayer } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { ReactElement, useCallback, useEffect } from 'react';
import { CgmDetail } from './cgm-detail';
import { useCgmStore } from './hooks';

export const Cgm = observer(function Cgm(): ReactElement {
  const cgmStore = useCgmStore();
  const cgmId = cgmStore.cgmId;
  const layer = useLayer('full-screen');
  const history = useHistory();

  useEffect(() => {
    if (cgmId && !layer.isOpened) {
      layer.open();
    }
  }, [cgmId, layer]);

  const closeDetail = useCallback(() => {
    if (layer.isOpened) {
      layer.layer.close();
      cgmStore.clear();
      history.push(getCgmLink(null));
    }
  }, [layer, history, cgmStore]);

  return (
    <LayerPortal
      layerHandle={layer}
      getContent={() => (
        <>
          <CgmDetail />
          <FullScreenLayerCloseButtonWrapper>
            <CloseButton onClick={closeDetail} />
          </FullScreenLayerCloseButtonWrapper>
        </>
      )}
    />
  );
});
