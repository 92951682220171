import { RootStore } from '@yarmill/types';
import { getWeekEnd } from '@yarmill/utils';
import { makeObservable } from 'mobx';
import { RichtextAttributeStore } from '../../attributes/mobx/richtext-attribute-store';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { DiaryDataUniqueId } from '../../diary/types';
import { BASE_GOAL_ATTRIBUTE } from '../../goals/mobx/goals-store';
import { TrainingDayAttributeId } from '../types';
import { WeekSummaryStore } from './week-summary-store';

export class WeekGoalAttributeStore extends RichtextAttributeStore {
  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    weekSummaryStore: WeekSummaryStore,
    weekGoalAttributeId: TrainingDayAttributeId | undefined,
    dataId: DiaryDataUniqueId
  ) {
    super(
      rootStore,
      diaryStore,
      weekSummaryStore,
      {
        ...BASE_GOAL_ATTRIBUTE,
        AttributeItemId: weekGoalAttributeId || 0,
      },
      dataId
    );
    makeObservable(this);
  }

  protected get isInAllowedBackfillScope(): boolean {
    if (!this.diaryStore.applyBackfillScope) {
      return true;
    }

    return (
      // we want to disable week goal if all days in week are disabled,
      // which means that last day has to be disabled
      getWeekEnd(this.dataId.week).diff(this.diaryStore.lastWritableDay!) >= 0
    );
  }
}
