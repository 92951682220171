import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';
import { ConflictResolution } from '../types';

export interface CopyGoalsRequestParams {
  SourceId: number;
  SourceWeekStartDate: string;
  DestinationUsersIds: number[];
  DestinationGroupsIds: number[];
  DestinationWeekStartDate: string;
  TrainingDayState?: 1 | 2; // 1 = Plan, 2 = reality
  CopyType?: 1 | 2; // 1 = keep target, 2 = keep source
  ConflictResolution: ConflictResolution;
}

export function copyGoalsFromUser(
  params: Partial<CopyGoalsRequestParams>
): AxiosPromise<boolean> {
  const paramsWithDefaults: CopyGoalsRequestParams = {
    TrainingDayState: 1,
    CopyType: 2,
    DestinationUsersIds: [],
    DestinationGroupsIds: [],
    DestinationWeekStartDate: params.SourceWeekStartDate,
    ...params,
  } as CopyGoalsRequestParams;

  return axios.post('api/Goals/User/Copy', paramsWithDefaults);
}
