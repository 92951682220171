import { ISO_DATE_FORMAT } from '@yarmill/const';
import moment from 'moment';
import { MouseEventHandler, useCallback, useContext } from 'react';
import { PlannerContext } from './planner-context';

export interface CreateEventHandlers {
  readonly onMouseDown: MouseEventHandler;
}

export type OnEventUpdatedCallback = (date: string) => void;

function handleMouseEvent(e: MouseEvent): string | null {
  const { target } = e;
  if (
    target instanceof HTMLDivElement &&
    target.getAttribute('data-type') === 'day'
  ) {
    const date = target.getAttribute('data-date');
    if (!date) {
      return null;
    }

    return date;
  }

  return null;
}

export function useEditEventHandlers(
  onChangeHandler: OnEventUpdatedCallback | undefined,
  type: 'top' | 'bottom'
): CreateEventHandlers {
  const plannerRef = useContext(PlannerContext);

  const onMouseOver = useCallback(
    (e: MouseEvent) => {
      const locator = handleMouseEvent(e);
      if (locator) {
        if (type === 'top') {
          const dayBefore = moment(locator).format(ISO_DATE_FORMAT);
          onChangeHandler?.(dayBefore);
        } else {
          const dayAfter = moment(locator).format(ISO_DATE_FORMAT);
          onChangeHandler?.(dayAfter);
        }
      }
    },
    [onChangeHandler, type]
  );

  const onMouseLeave = useCallback(() => {
    plannerRef?.current?.removeEventListener('mouseover', onMouseOver);
    plannerRef?.current?.removeAttribute('data-isEdited');
  }, [plannerRef, onMouseOver]);

  const onMouseDown = useCallback(() => {
    const planner = plannerRef?.current;
    if (planner) {
      planner.addEventListener('mouseover', onMouseOver);
      planner.addEventListener('mouseleave', onMouseLeave);
      planner.setAttribute('data-isEdited', 'true');
      planner.style.cursor = 'row-resize';
    }

    function onMouseUp() {
      document.removeEventListener('mouseup', onMouseUp);
      const planner = plannerRef?.current;
      if (planner) {
        planner.removeEventListener('mouseover', onMouseOver);
        planner.removeEventListener('mouseleave', onMouseLeave);
        planner.removeAttribute('data-isEdited');
        planner.style.cursor = 'unset';
      }
    }
    document.addEventListener('mouseup', onMouseUp);
  }, [plannerRef, onMouseOver, onMouseLeave]);

  return {
    onMouseDown,
  };
}
