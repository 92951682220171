import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { PageMainContent, Text } from '@yarmill/components';
import { Page } from '../page/page';

export class NotFound extends PureComponent {
  public render(): JSX.Element {
    return (
      <Page>
        <PageMainContent>
          <Text>
            <FormattedMessage id="notFound.notFoundMessage" />
          </Text>
        </PageMainContent>
      </Page>
    );
  }
}
