import { TextInput, Tippy } from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { getDisabledReasonMessage } from '../../activities/utils';
import { InputArrayAttributeStore } from '../mobx/input-array-attribute-store';
import { InputStore } from '../mobx/input-store';

export interface InputProps {
  attribute: InputArrayAttributeStore;
  inputStore: InputStore;
}
function InternalInput(props: InputProps): JSX.Element {
  const { attribute, inputStore } = props;
  const intl = useIntl();
  const disabledReason = attribute.disabledReason;
  const inputRef = useRef<HTMLInputElement>(null);

  const onBlur = useCallback((): void => {
    attribute.onBlur();
  }, [attribute]);

  const onFocus = useCallback((): void => {
    attribute.onFocus();
  }, [attribute]);

  useEffect(() => {
    inputStore.setInputRef(inputRef);
  }, [inputStore]);

  return (
    <Tippy
      isEnabled={Boolean(disabledReason)}
      tooltipContent={getDisabledReasonMessage(disabledReason)}
    >
      <TextInput
        id={`${attribute.name}.${inputStore.index}`}
        ref={inputRef}
        disabled={attribute.isDisabled}
        placeholder={intl.formatMessage({
          id: `${attribute.name}.${inputStore.index}`,
        })}
        onChange={inputStore.onChange}
        value={inputStore.formattedValue}
        type="text"
        onFocus={onFocus}
        onBlur={onBlur}
        noLabel
        noError
        data-cy={`${attribute.name}_${inputStore.index}`}
      />
    </Tippy>
  );
}

export const Input = observer(InternalInput);
