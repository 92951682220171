import { axios, devMode, instance } from '@yarmill/utils';
import { AxiosPromise, CancelToken } from 'axios';

export function downloadFile(
  fileUrl: string,
  cancelToken: CancelToken
): AxiosPromise<BlobPart> {
  return axios.get(fileUrl, {
    responseType: 'blob',
    cancelToken,
    params: {
      instanceCode: devMode ? instance : undefined,
    },
  });
}
