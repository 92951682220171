import { Icon, IconSize, styled, ThemeDefinition } from '@yarmill/components';
import { Stop } from '@yarmill/icons-2';
import { getAppearanceColors, TextInputProps } from '../text-input';
import { Text } from '../text';

interface GenericDropdownItemProps {
  readonly color: keyof ThemeDefinition['color'];
  readonly title: string;
  readonly appearance: TextInputProps['appearance'];
}

const GenericDropdownItemLayout = styled.div<{
  readonly appearance: TextInputProps['appearance'];
}>`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.size.x1};
  color: ${({ theme, appearance }) =>
    getAppearanceColors(appearance, theme).text};
  line-height: normal;
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.borderRadius.x05};
  overflow: hidden;
`;

const StyledDropdownIcon = styled(Icon)<{
  readonly color: keyof ThemeDefinition['color'];
}>`
  color: ${({ theme, color }) => theme.color[color]};
`;
export function GenericDropdownItem({
  color,
  title,
  appearance,
}: GenericDropdownItemProps): JSX.Element {
  return (
    <GenericDropdownItemLayout appearance={appearance}>
      <GenericDropdownItemIcon color={color} />
      <Text
        appearance="button10"
        whiteSpace="noWrap"
        inheritColor
        bold
        ellipsis
      >
        {title}
      </Text>
    </GenericDropdownItemLayout>
  );
}

interface GenericDropdownItemIconProps {
  readonly color: keyof ThemeDefinition['color'];
  readonly size?: IconSize;
}
export function GenericDropdownItemIcon({
  color,
  size,
}: GenericDropdownItemIconProps): JSX.Element {
  return (
    <StyledDropdownIcon color={color} size={size ?? IconSize.s16}>
      <Stop />
    </StyledDropdownIcon>
  );
}
