import * as React from 'react';

export interface CapsLockDetectorProps {
  children(isCapsLockActive: boolean): React.ReactNode;
}

export interface CapsLockDetectorState {
  isCapsLockActive: boolean;
}

export class CapsLockDetector extends React.PureComponent<
  CapsLockDetectorProps,
  CapsLockDetectorState
> {
  public readonly state = {
    isCapsLockActive: false,
  };

  public componentDidMount(): void {
    document.addEventListener('keydown', this.wasCapsLockActivated);
    document.addEventListener('keyup', this.wasCapsLockDeactivated);
  }

  public componentWillUnmount(): void {
    document.removeEventListener('keydown', this.wasCapsLockActivated);
    document.removeEventListener('keyup', this.wasCapsLockDeactivated);
  }

  public render(): React.ReactNode {
    return this.props.children(this.state.isCapsLockActive);
  }

  private readonly wasCapsLockActivated = (event: KeyboardEvent) => {
    if (
      event.getModifierState &&
      event.getModifierState('CapsLock') &&
      !this.state.isCapsLockActive
    ) {
      this.setState({ isCapsLockActive: true });
    }
  };

  private readonly wasCapsLockDeactivated = (event: KeyboardEvent) => {
    if (
      event.getModifierState &&
      !event.getModifierState('CapsLock') &&
      this.state.isCapsLockActive
    ) {
      this.setState({ isCapsLockActive: false });
    }
  };
}
