import styled from 'styled-components';

export const PageLayout = styled.div<{ readonly doubleSidebar?: boolean }>`
  padding: 12px;
  overflow: auto;
  height: calc(100% - 55px);

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: ${({ doubleSidebar }) =>
      doubleSidebar ? '1fr 1fr 2fr' : '1fr 3fr'};
    align-items: flex-start;
    grid-template-rows: unset;
    padding: unset;
    height: calc(100% - 80px);
    overflow: hidden;
  }

  @media (min-width: 992px) {
    grid-template-columns: ${({ doubleSidebar }) =>
      doubleSidebar ? '1fr 1fr 4fr' : '1fr 5fr'};
    grid-template-rows: unset;
  }
`;

export const SimplePageLayout = styled.div`
  height: 100%;
  overflow: auto;
`;

export const PageMainContent = styled.div`
  position: relative;
  z-index: 0;
  margin-top: 20px;

  @media (min-width: 768px) {
    padding: 25px 20px;
    overflow: auto;
    height: 100%;
  }

  @media (min-width: 1024px) {
    margin-top: unset;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    height: 100%;
    overflow: hidden;

    :only-child {
      grid-column: 1 / 3;
    }
  }
`;

export const PageScrollContainer = styled.div`
  overflow: auto;
`;

export const PageMainContentContainer = styled.div`
  padding: 25px 20px;
  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 8px;

  @media (min-width: 1024px) {
    margin-bottom: unset;
  }
`;
