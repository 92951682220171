import * as React from 'react';
import styled, { css } from 'styled-components';

import { Fragment } from 'react';
import { FormControlVariant } from '../helpers';
import {
  StyledError,
  StyledHelp,
  StyledInput,
  StyledInputProps,
  StyledLabel,
  StyledOptionalMessage,
  TextInputProps,
} from '../text-input';
import { PatternInputConfig, usePatternInput } from './use-pattern-input';
import { replacePatternInputPattern } from './utils';

const StyledInputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const StyledValue = styled.div<StyledInputProps>`
  position: absolute;
  font-family: "Roboto Mono", serif;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 16px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  pointer-events: none;
  height: 36px;
  border: 1px solid transparent;
  border-radius: 4px;
  letter-spacing: normal;
  color: transparent;

  ${props =>
    props.variant === FormControlVariant.big &&
    css`
      height: 65px;
      font-size: 18px;
      padding: 15px;
      padding-top: 18px;
    `}
`;

const StyledPattern = styled(StyledValue)<{ variant?: FormControlVariant }>`
  color: #9a9a9a;
  border: 0;
`;

const StyledTextInput = styled(StyledInput)`
  font-family: "Roboto Mono", serif;
`;

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PatternInputProps = Omit<TextInputProps, 'onChange'> &
  PatternInputConfig;

export const PatternInput = React.forwardRef<
  HTMLInputElement,
  PatternInputProps
>((props, ref): JSX.Element => {
  const {
    defaultValue,
    pattern,
    validateValue,
    delimiter,
    variant,
    value,
    readOnly,
    noError,
    noLabel,
    tooltip,
    ...textInputProps
  } = props;
  const { onChange, onKeyDown, persistedValue } = usePatternInput(props);
  const content = value !== undefined ? value : persistedValue;
  const InputTooltipWrapper = tooltip ?? Fragment;

  return (
    <>
      {!props.noLabel && (
        <StyledLabel variant={variant} htmlFor={props.id}>
          {props.label}
          {props.optional && (
            <StyledOptionalMessage>
              {props.optionalMessage}
            </StyledOptionalMessage>
          )}
        </StyledLabel>
      )}
      {props.help && <StyledHelp>{props.help}</StyledHelp>}
      <StyledInputWrapper>
        <InputTooltipWrapper>
          <StyledTextInput
            {...textInputProps}
            variant={variant}
            value={value !== undefined ? value : persistedValue}
            readOnly={readOnly}
            autoComplete="off"
            onChange={onChange}
            onKeyDown={onKeyDown}
            ref={ref}
          />
        </InputTooltipWrapper>
        <StyledValue variant={variant}>
          {content}
          <StyledPattern variant={variant}>
            {replacePatternInputPattern(pattern, content)}
          </StyledPattern>
        </StyledValue>
      </StyledInputWrapper>
      {!props.noError && <StyledError>{props.error}&nbsp;</StyledError>}
    </>
  );
});
