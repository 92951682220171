import { Button } from '@yarmill/components-2';
import { useConfig } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import {
  ObjectiveValidationButtonWrapper,
  ObjectiveValidationWrapper,
} from '../components/detail/objective-validation';
import { ObjectiveStore } from '../mobx/objective-store';
import { ObjectiveValidationChecks } from './objective-validation-checks';
import { ObjectiveValidationRating } from './objective-validation-rating';

interface ObjectiveValidationProps {
  readonly objective: ObjectiveStore;
}

export const ObjectiveValidation = observer(function ObjectiveValidation({
  objective,
}: ObjectiveValidationProps) {
  const instructionsUrl = useConfig('okrInstructionsUrl');

  return (
    <ObjectiveValidationWrapper>
      <ObjectiveValidationRating objective={objective} />
      {instructionsUrl && (
        <ObjectiveValidationButtonWrapper>
          <Button
            as="a"
            href={instructionsUrl}
            target="_blank"
            rel="noopener noreferrer"
            stretch
          >
            <FormattedMessage id="okrs.detail.validation.tutorialLink" />
          </Button>
        </ObjectiveValidationButtonWrapper>
      )}
      <ObjectiveValidationChecks objective={objective} />
    </ObjectiveValidationWrapper>
  );
});
