import { HorizontalStackedBarChart } from '../../types';
import { groupStackedBarsByCategories } from '../../utils/group-stacked-bars-by-categories';
import { Group } from '@visx/group';
import { useMemo } from 'react';
import { getBarRadius } from '../../../reporting/utils/get-bar-radius';
import { HorizontalStackedBarRect } from './horizontal-stacked-bar-rect';
import { ArrayElement, DataItem } from '../../../reporting/types';

interface HorizontalBarStackProps {
  readonly stack: ArrayElement<ReturnType<typeof groupStackedBarsByCategories>>;
  readonly config: HorizontalStackedBarChart;
  readonly stackIndex: number;
  readonly item: DataItem;
}
export function HorizontalBarStack({
  stack,
  config,
  stackIndex,
  item,
}: HorizontalBarStackProps) {
  const maskId = `${config.code}_cat_${stackIndex}`;

  const sortedRectsByX = useMemo(
    () => stack.slice().sort((a, b) => a.x - b.x),
    [stack]
  );
  const firstBar = sortedRectsByX[0];

  const width = useMemo(
    () =>
      stack.reduce((sum, bar) => {
        sum = bar.width + sum;
        return sum;
      }, 0),
    [stack]
  );

  if (!width) {
    return null;
  }

  return (
    <Group>
      <mask id={maskId}>
        <rect
          x={firstBar?.x}
          y={firstBar?.y}
          height={firstBar?.height}
          width={width}
          rx={getBarRadius(firstBar?.height)}
          fill="white"
        />
      </mask>
      <Group mask={`url(#${maskId})`}>
        {stack.map((bar, idx) => (
          <HorizontalStackedBarRect
            bar={bar}
            key={idx}
            config={config}
            item={item}
          />
        ))}
      </Group>
    </Group>
  );
}
