import { MouseEvent, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { Button, ButtonAppearance } from './button';
import { ExternalIcon } from './external-icon';
import { Icon, IconSize } from './icon';

export interface CloseButtonProps {
  readonly autoFocus?: boolean;
  onClick?(e: MouseEvent): void;
  readonly hideText?: boolean;
  readonly 'data-cy'?: string;
}

export function CloseButton({
  autoFocus,
  onClick,
  hideText,
  'data-cy': dataCy,
}: CloseButtonProps): ReactElement {
  const intl = useIntl();
  const text = intl.formatMessage({ id: 'settings.modal.closeButton' });
  function handleClick(e: MouseEvent) {
    onClick?.(e);
  }

  return (
    <Button
      autoFocus={autoFocus}
      appearance={ButtonAppearance.Link}
      onClick={handleClick}
      aria-label={text}
      vertical
      data-cy={dataCy}
    >
      <Icon size={IconSize.s24}>
        <ExternalIcon name="X" />
      </Icon>
      {!hideText && (
        <>
          <br />
          {text}
        </>
      )}
    </Button>
  );
}
