import {
  FormattedHTMLMessage,
  LogoContainer,
  LogoImage,
  LogoImageWrapper,
  LogoTextWrapper,
} from '@yarmill/components';
import {
  apiTarget,
  devMode,
  impersonatedUser,
  useConfig,
  useCurrentUserStore,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import defaultLogo from './logo.png';

export const Logo = observer(function Logo(): JSX.Element {
  const logo = useConfig('logo');
  const currentUser = useCurrentUserStore();
  const logoUrl = logo ? `${apiTarget}/${logo}` : defaultLogo;

  return (
    <LogoContainer
      $devMode={devMode}
      $impersonatedUser={Boolean(impersonatedUser)}
      $email={currentUser.data?.Email}
    >
      <LogoImageWrapper>
        <LogoImage src={logoUrl} alt="Logo" />
      </LogoImageWrapper>
      <LogoTextWrapper>
        <FormattedHTMLMessage
          id="header.logo.subtitle"
          defaultMessage={`Powered by <strong>YARMILL</strong>`}
        />
      </LogoTextWrapper>
    </LogoContainer>
  );
});
