import {
  AthletesSidebar,
  PageMainContent,
  StyledWeekViewRow,
  TrainingDaysListScrollWrapper,
} from '@yarmill/components';
import {
  TrainingWeekLoadingPlaceholder,
  WeekViewLoadingPlaceholder,
} from '@yarmill/training-diary';
import { Page } from '../page/page';

export const BaseLoadingScreen = () => (
  <Page>
    <AthletesSidebar />
    <PageMainContent>
      <StyledWeekViewRow noNavbar>
        <TrainingDaysListScrollWrapper>
          <TrainingWeekLoadingPlaceholder />
        </TrainingDaysListScrollWrapper>
        <WeekViewLoadingPlaceholder />
      </StyledWeekViewRow>
    </PageMainContent>
  </Page>
);
