import { SeasonId } from '@yarmill/types';
import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { Objective } from '../types';

export interface GetObjectivesForUserRequestParams {
  userId: number | undefined;
  seasonId: SeasonId;
}

export type GetObjectivesForUserResponse = Objective[];

export function getObjectivesForUser(
  params: GetObjectivesForUserRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<GetObjectivesForUserResponse> {
  return axios.get(
    `${coreApiTarget}/api/okrs/userId/${params.userId}/seasonId/${params.seasonId}`,
    {
      cancelToken: cancelToken?.token,
      params: {
        instanceCode: devMode ? instance : undefined,
      },
    }
  );
}
