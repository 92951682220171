import { Location } from '../hooks';
import { appendDefaultSearchParams } from './append-default-search-params';

export function createModuleLink(
  baseUrl: string,
  defaultSearchParams: string,
  location: Location
) {
  const searchParams = appendDefaultSearchParams(
    location.search,
    defaultSearchParams
  );

  return `${baseUrl}?${searchParams}`;
}
