import {
  Text,
  TextAlignment,
  TextSize,
  TextTag,
  Tippy,
} from '@yarmill/components';
import { HeartRateZoneStore } from '@yarmill/types';
import styled from 'styled-components';

interface ZonesDiagramProps {
  zones: HeartRateZoneStore[];
}

const ZonesDiagramLayout = styled.div<ZonesDiagramProps>`
  display: grid;
  grid-template-columns: ${({ zones }) =>
    zones
      .map((z, idx) => `${z.max - (idx === 0 ? 0 : zones[idx - 1].max + 1)}fr`)
      .join(' ')};
  gap: 8px;
  min-width: 150px;
`;
const Zone = styled.div`
  background-color: #e6e8eb;
  color: #a9a9a9;
  font-size: 10px;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: 0 2px;
  min-width: 22px;
`;
export function ZonesDiagram({ zones }: ZonesDiagramProps): JSX.Element {
  return (
    <ZonesDiagramLayout zones={zones}>
      {zones.map((z, idx) => (
        <Tippy
          key={z.id}
          tooltipContent={
            <Text
              textAlign={TextAlignment.center}
              size={TextSize.s14}
              tag={TextTag.div}
              inheritColor
            >
              {z.name}
              <br />
              {idx === 0 ? 0 : zones[idx - 1].max + 1} - {z.max}
            </Text>
          }
          noWrapper
        >
          <Zone>{z.name}</Zone>
        </Tippy>
      ))}
    </ZonesDiagramLayout>
  );
}
