import { ActivityInput, Tippy } from '@yarmill/components';
import { ActivityItem, AsyncStatus } from '@yarmill/types';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef } from 'react';
import { useDiaryStore } from '../diary/diary-store-context';
import { TimeInput } from './components/time-input';
import { useSelectableActivityInputs } from './hooks';
import { ComputedActivityStore } from './mobx/computed-activity-store';
import { TimeActivityStore } from './mobx/time-activity-store';
import { getDisabledReasonMessage } from './utils';

export interface ActivityValueProps {
  activityItem: ActivityItem;
  columnIndex: number;
  tabIndex: number;
}

function InternalActivityValue(props: ActivityValueProps): JSX.Element {
  const { activityItem, columnIndex, tabIndex } = props;
  const diaryStore = useDiaryStore();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const activityListStore = diaryStore.activityListStore;
  const activityStore = activityListStore?.getActivityStore(
    activityItem.ActivityItemId,
    columnIndex
  );
  const status = diaryStore.status;
  const disabled = activityStore?.isDisabled ?? true;
  const disabledReason = activityStore?.disabledReason ?? null;
  const error = activityStore?.error;

  const onClick = useSelectableActivityInputs(activityStore, columnIndex);

  const onFocus = useCallback((): void => {
    activityStore?.onFocus();
  }, [activityStore]);

  const onBlur = useCallback((): void => {
    activityStore?.onBlur();
  }, [activityStore]);

  useEffect(() => {
    if (error && inputRef.current) {
      inputRef.current.focus();
    }
  }, [error]);

  if (status !== AsyncStatus.resolved) {
    return (
      <Tippy
        tooltipContent={getDisabledReasonMessage(
          status === AsyncStatus.rejected ? 'loading-error' : 'loading'
        )}
      >
        <ActivityInput disabled value="" />
      </Tippy>
    );
  }

  return (
    <Tippy
      isEnabled={Boolean(error) || disabled}
      isVisible={Boolean(error) || undefined}
      theme={error ? 'red' : 'dark'}
      manual={Boolean(error)}
      trigger={error ? 'manual' : undefined}
      tooltipContent={error || getDisabledReasonMessage(disabledReason) || ''}
      touch={disabled}
    >
      {activityStore instanceof TimeActivityStore ||
      (activityStore instanceof ComputedActivityStore &&
        activityStore.showTimeActivityStore) ? (
        <TimeInput
          ref={inputRef}
          data-cy={activityItem.Name}
          disabled={disabled}
          tabIndex={tabIndex}
          value={activityStore?.formattedValue as string}
          onChange={activityStore.onTimeInputChange}
          onBlur={onBlur}
          onFocus={onFocus}
          isSelected={activityStore?.isSelected}
          onMouseDown={onClick}
        />
      ) : (
        <ActivityInput
          ref={inputRef}
          data-cy={activityItem.Name}
          disabled={disabled}
          tabIndex={tabIndex}
          value={activityStore?.formattedValue}
          placeholder={activityStore?.placeholder}
          onChange={activityStore?.onChange}
          onMouseDown={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          isSelected={activityStore?.isSelected}
        />
      )}
    </Tippy>
  );
}

export const ActivityValue = observer(InternalActivityValue);
