import { useCallback } from 'react';
import { PlannerEventStore } from '../mobx/planner-event-store';
import { copyPlannerEventData } from '../utils';
import { useSubmitOpenedForm } from './use-submit-opened-form';
import { usePlannerStore } from '../planner-store-context';
import { runInAction } from 'mobx';

export function useEditEventHandler(event: PlannerEventStore) {
  const submitOpenedForm = useSubmitOpenedForm();
  const plannerStore = usePlannerStore();

  return useCallback(() => {
    submitOpenedForm();

    runInAction(() => {
      const calendarEvent = new PlannerEventStore();
      copyPlannerEventData(event, calendarEvent);

      plannerStore.showImporter = false;
      plannerStore.formEvent = event;
      plannerStore.calendarFormEvent = calendarEvent;
      plannerStore.lastEventType = event.eventTypeId;
      plannerStore.scrollToEvent = 'form-event';
    });
  }, [submitOpenedForm, event, plannerStore]);
}
