import { useConfig } from './use-config';

export const DEFAULT_AVATAR = 'avatar0';

export function useAvatar(avatar?: string | null): string {
  const avatarsMap = useConfig('avatars');
  const src = avatarsMap.get(avatar || '');

  if (!src) {
    return avatarsMap.get(DEFAULT_AVATAR) as string;
  }

  return src;
}
