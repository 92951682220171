import { ReactNode } from 'react';

export type OverviewTableData = OverviewTableDataRow[];

export interface OverviewTableDataRow {
  Key: string;
  Values: OverviewTableCellData[];
}

export interface OverviewTableCellData {
  ColumnKey: string;
  Value: string;
  Category: string;
  Flag: boolean;
  [key: string]: string | boolean | number;
}

export type OverviewTableDataFormatter<T = ReactNode> = (
  data: string,
  cellData?: OverviewTableCellData
) => T;

export const OVERVIEW_TABLE_LABEL_WIDTH = 150;
export const OVERVIEW_TABLE_CELL_SIZE = 35;
export const OVERVIEW_TABLE_MIN_COL_GAP = 5;
export const OVERVIEW_TABLE_STICKY_TOP = '-31px';

export function getOverviewTableMinimalWidth(
  hasRowLabels: boolean,
  columnsNumber: number
): number {
  const minWidthWithLabels =
    150 +
    columnsNumber * OVERVIEW_TABLE_CELL_SIZE +
    columnsNumber * OVERVIEW_TABLE_MIN_COL_GAP;
  const minWidthWithoutLabels =
    columnsNumber * OVERVIEW_TABLE_CELL_SIZE +
    (columnsNumber - 1) * OVERVIEW_TABLE_MIN_COL_GAP;

  if (hasRowLabels) {
    return minWidthWithLabels;
  } else {
    return minWidthWithoutLabels;
  }
}
