import { ActivityInput, validateByValidityTable } from '@yarmill/components';
import { FocusEvent, MouseEventHandler, forwardRef } from 'react';
import { PatternActivityInput } from './pattern-activity-input';

export interface TimeInputProps {
  disabled?: boolean;
  value?: string;
  tabIndex?: number;
  isSelected?: boolean;
  'data-cy'?: string;
  onFocus?(e: FocusEvent): void;
  onBlur?(e: FocusEvent): void;
  onChange?(newValue: string): void;
  onMouseDown?: MouseEventHandler;
}

const MASK = [/[0-9]/, /[0-9]/, /:/, /[0-5]/, /[0-9]/];
function validateValue(newValue: string): boolean {
  return validateByValidityTable(newValue, MASK, true);
}

export const TimeInput = forwardRef<HTMLInputElement, TimeInputProps>(
  (props, ref): JSX.Element => {
    const {
      value,
      tabIndex,
      disabled,
      isSelected,
      onBlur,
      onFocus,
      onChange,
      onMouseDown,
    } = props;

    if (disabled) {
      return (
        <ActivityInput
          disabled={disabled}
          value={value}
          data-cy={props['data-cy']}
        />
      );
    }

    return (
      <PatternActivityInput
        id=""
        data-cy={props['data-cy']}
        ref={ref}
        disabled={disabled}
        delimiter=":"
        validateValue={validateValue}
        pattern="hh:mm"
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        tabIndex={tabIndex}
        isSelected={isSelected}
        onMouseDown={onMouseDown}
      />
    );
  }
);
