import moment from 'moment';

export const MAX_PLANNER_ZOOM_LEVEL = 2;
export type PlannerZoomLevel = 0 | 1 | 2;
const ZOOM_LEVEL_WIDTHS = [84, 138, 308];
const ZOOM_LEVEL_HEIGHTS = [20, 24, 40];

function getZoomLevelWidth(zoomLevel: PlannerZoomLevel): number {
  return ZOOM_LEVEL_WIDTHS[zoomLevel];
}

export function getZoomLevelHeight(zoomLevel: PlannerZoomLevel): number {
  return ZOOM_LEVEL_HEIGHTS[zoomLevel];
}

function getColumnWidth(width: number, conflicts: number): number {
  if (conflicts <= 2) {
    return width;
  } else {
    // increase size of column by half of column width for columns having more than 2 conflicts
    return width + (width / 2) * (conflicts - 2);
  }
}

export function getColumnsDefinition(
  columnsCount: number,
  zoomLevel: PlannerZoomLevel,
  conflictsPerMonths: number[]
): string {
  const widths: string[] = new Array(columnsCount)
    .fill('')
    .map(
      (_, idx) =>
        `minmax(${getColumnWidth(
          getZoomLevelWidth(zoomLevel),
          conflictsPerMonths[idx] ?? 0
        )}px , 1fr)`
    );

  return widths.join(' ');
}

export function getNumberOfColumns(
  startDate: moment.Moment,
  endDate: moment.Moment
): number {
  const start = startDate.clone().startOf('month');
  const end = endDate.clone().endOf('month');
  return end.diff(start, 'months') + 1;
}
