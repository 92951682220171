import { toast } from '@yarmill/components';
import {
  AnnouncementStore,
  CurrentUserStore,
  SettingsKey,
  SettingsService,
} from '@yarmill/types';
import { instance } from '@yarmill/utils';
import { ToastPosition } from 'react-toastify';
import {
  ANNOUNCEMENT_PERSIST_PREFIX,
  Announcement,
  AnnouncementCode,
  AnnouncementType,
} from './types';

function createSettingsKey(announcement: AnnouncementCode): SettingsKey {
  return `${ANNOUNCEMENT_PERSIST_PREFIX}${announcement}`;
}

export function getNumberOfViews(
  announcement: AnnouncementCode,
  currentUser: CurrentUserStore
): number {
  const settingValue = currentUser.getSetting(createSettingsKey(announcement));
  const views =
    typeof settingValue === 'number'
      ? settingValue
      : typeof settingValue === 'string'
        ? parseInt(settingValue, 10)
        : 0;

  return isNaN(views) ? 0 : views;
}

export function incrementNumberOfViews(
  announcementCode: AnnouncementCode,
  currentUser: CurrentUserStore,
  settingsService: SettingsService
): void {
  const numberOfViews = getNumberOfViews(announcementCode, currentUser);
  void settingsService.saveSettings(
    createSettingsKey(announcementCode),
    numberOfViews + 1,
    { userId: currentUser.id }
  );
}

function getNotificationPosition(type: AnnouncementType): ToastPosition {
  switch (type) {
    case AnnouncementType['notification-top']:
      return 'top-center';
    case AnnouncementType['notification-top-left']:
      return 'top-left';
    case AnnouncementType['notification-top-right']:
      return 'top-right';
    case AnnouncementType['notification-bottom']:
      return 'bottom-center';
    case AnnouncementType['notification-bottom-left']:
      return 'bottom-left';
    default:
      return 'bottom-right';
  }
}

export function shouldShowAnnouncement(
  currentUser: CurrentUserStore,
  announcement: Announcement
): boolean {
  return (
    shouldShowAnnouncementForCurrentUser(currentUser, announcement) &&
    (announcement.displays === -1 ||
      getNumberOfViews(announcement.code, currentUser) < announcement.displays)
  );
}

function shouldShowAnnouncementForCurrentUser(
  currentUser: CurrentUserStore,
  announcement: Announcement
): boolean {
  if (!announcement.permissions) {
    return true;
  }
  const { users, roles } = announcement.permissions;

  if (users?.length && users.includes(currentUser.id)) {
    return true;
  }

  if (roles?.length && roles.includes(currentUser.role)) {
    return true;
  }

  return false;
}

export function showNotificationAnnouncement(
  announcement: AnnouncementStore
): void {
  const position = getNotificationPosition(announcement.type);
  toast(
    `announcement.${announcement.code}.text`,
    undefined,
    { instanceUrl: instance },
    {
      autoClose: false,
      position,
      onClose: () => announcement.close(),
    }
  );
}
