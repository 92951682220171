import styled from 'styled-components';

export const ChangePasswordForm = styled.form`
  max-width: 450px;
  margin: 36px auto 0;
`;

export const PasswordRequirementsList = styled.ul`
  color: #9a9a9a;
  margin: 0 0 36px;
  padding: 0;
  list-style-position: inside;
  font-size: 14px;
`;
