import styled from 'styled-components';

import { Icon, IconSize } from '@yarmill/components';

export interface ChartIconProps {
  children: JSX.Element;
}

const StyledChartIcon = styled.div`
  width: 36px;
  height: 36px;
  background-color: #edf0f5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export function ChartIcon(props: ChartIconProps): JSX.Element {
  return (
    <StyledChartIcon>
      <Icon size={IconSize.s14}>{props.children}</Icon>
    </StyledChartIcon>
  );
}
