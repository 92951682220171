import { UserGroupId, UserId } from '@yarmill/types';
import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { PlannerEvent } from '../types';

export interface GetEventsRequestParams {
  userId: UserId | undefined;
  userGroupId: UserGroupId | undefined;
  startDate: string;
  endDate: string;
}

export function getEvents(
  params: GetEventsRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<PlannerEvent[]> {
  return axios.get(`${coreApiTarget}/api/planner`, {
    params: {
      ...params,
      instanceCode: devMode ? instance : undefined,
    },
    cancelToken: cancelToken?.token,
  });
}
