import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from '@yarmill/const';
import { GroupStore } from '@yarmill/types';
import { generateUrl } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { ListItem } from './list-item';

interface GroupLinkProps {
  isActive: boolean;
  group: GroupStore;
  onClick(): void;
}

export const GroupLink = observer(function GroupLink(
  props: GroupLinkProps
): JSX.Element {
  const { isActive, group, onClick } = props;
  const link = useCallback(
    () =>
      generateUrl({
        [GROUP_SEARCH_PARAM]: group.id,
        [ATHLETE_SEARCH_PARAM]: null,
      }),
    [group]
  );

  const handleClick = useCallback(() => onClick(), [onClick]);

  return (
    <ListItem
      isActive={isActive}
      link={link}
      name={group.name}
      onClick={handleClick}
      secondaryLine={
        <FormattedMessage
          id="sidebar.groups.athletesCount"
          values={{ count: group.athletes.length }}
        />
      }
    />
  );
});
