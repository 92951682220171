import { CsvTableUnit, Units } from '@yarmill/types';
import moment from 'moment';

export function formatValueByUnit(
  value: string | number | undefined | null,
  unit: CsvTableUnit
): string {
  if (value === 0 || value === '0' || !value) {
    return '';
  }
  switch (unit) {
    case Units.hod:
      return moment
        .duration(Number(value), 'hours')
        .format('hh:mm', { trim: false });
    case Units.min:
    case Units.longTime:
      return moment
        .duration(Number(value), 'minutes')
        .format('hh:mm', { trim: false });
    default:
      return value.toLocaleString('cs', {
        maximumFractionDigits: 3,
        minimumFractionDigits: 0,
      });
  }
}
