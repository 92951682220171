import * as React from 'react';
import { styled, css } from '../theme-provider';

import { FormControlVariant } from '../helpers';

export enum ButtonAppearance {
  Primary,
  Secondary,
  Success,
  Navigation,
  Error,
  Link,
  ActiveLink,
  Dark,
  Active,
}

export type ButtonProps = React.PropsWithChildren<
  StyledButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>;

export interface StyledButtonProps {
  readonly appearance?: ButtonAppearance;
  readonly inverted?: boolean;
  readonly square?: boolean;
  readonly wide?: boolean;
  readonly small?: boolean;
  readonly noShadow?: boolean;
  readonly highlighted?: boolean;
  readonly variant?: FormControlVariant;
  readonly href?: string;
  readonly target?: string;
  readonly as?: 'a' | 'button' | 'div' | 'label';
  readonly vertical?: boolean;
  readonly iconOnly?: boolean;
  readonly htmlFor?: string;
}

const StyledButton = styled.button<StyledButtonProps>`
  display: inline-block;
  ${({ vertical }) => vertical && `flex-direction: column;`};
  min-height: 36px;
  font-size: ${({ iconOnly }) => (iconOnly ? '16px' : '13px')};
  background-color: transparent;
  color: #4a4a4a;
  border: 1px solid #4a4a4a;
  border-radius: 8px;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: ${({ theme }) => theme.text.font.default};
  text-decoration: none;
  line-height: ${({ iconOnly }) => (iconOnly ? '16px' : '22px')};
  text-align: center;
  user-select: none;

  :hover,
  :focus {
    box-shadow: ${props =>
      `0 0 30px rgba(74, 74, 74, ${props.noShadow ? 0 : 0.26})`};
    outline: none;
  }

  :disabled,
  :disabled:active {
    box-shadow: none;
    color: #9a9a9a;
    cursor: not-allowed;
  }

  ${props =>
    props.wide &&
    css`
      padding-left: 32px;
      padding-right: 32px;
    `}

  ${props =>
    props.square &&
    css`
      width: 36px;
      padding: 0 !important;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    `}

  ${props =>
    props.variant === FormControlVariant.big &&
    css`
      height: auto;
      font-size: 18px;
      padding: 20px 50px;
      border-radius: 8px;
    `}

  ${({ small, square }) =>
    small &&
    css`
      min-height: unset;
      height: auto;
      font-size: 12px;
      padding: 4px 8px;
      border-radius: 4px;
      line-height: normal;

      ${
        square &&
        css`
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: unset;
      `
      }
    `}

  ${props => getAppearanceStyle(props.appearance)}

  svg, span {
    pointer-events: none;
  }
`;

const StyledPrimaryButton = css<StyledButtonProps>`
  background-color: #f61c8e;
  color: #ffffff;
  border-color: #f61c8e;
  box-shadow: ${props =>
    `0 0 30px rgba(246, 28, 142, ${props.noShadow ? 0 : 0.5})`};

  :hover {
    background: #e3097b;
    border-color: #d60974;
    box-shadow: ${props =>
      `0 0 30px rgba(246, 28, 142, ${props.noShadow ? 0 : 0.5})`};
  }

  :disabled,
  :disabled:active {
    background: #d5d6da;
    color: #9a9a9a;
    box-shadow: none;
    border-color: #d5d6da;
  }

  :active {
    color: #ffffff;
    background-color: #d60974;
    border-color: #ca086e;
  }

  ${props =>
    props.inverted &&
    css`
      background: transparent;
      color: #f61c8e;
      :hover {
        color: #ffffff;
      }
      :disabled,
      :disabled:active {
        color: #9a9a9a;
        border-width: 1px;
        border-color: #9a9a9a;
        background: transparent;
      }
    `}
`;

const StyledLinkButton = css<StyledButtonProps>`
  background-color: transparent;
  color: #4a4a4a;
  border: 0;

  :focus {
    box-shadow: none;
    color: #4a90e2;
  }

  :hover {
    color: #4a90e2;
    box-shadow: none;
  }

  :disabled,
  :disabled:active {
    color: #9a9a9a;
    box-shadow: none;
  }

  :active {
    color: #4a4a4a;
  }

  ${props =>
    props.inverted &&
    css`
      background: transparent;
      color: #ffffff;

      :hover {
        background-color: rgba(255, 255, 255, 0.05);
        color: #ffffff;
      }
    `}
`;

const StyledActiveLinkButton = css<StyledButtonProps>`
  background-color: transparent;
  color: #4a90e2;
  border: 0;
  text-decoration: underline;

  :focus {
    box-shadow: none;
    color: #4a90e2;
  }

  :hover {
    color: #be0867;
    box-shadow: none;
  }

  :disabled,
  :disabled:active {
    color: #9a9a9a;
    box-shadow: none;
  }

  :active {
    color: #4a4a4a;
  }
`;

const StyledSuccessButton = css<StyledButtonProps>`
  background-color: #28a745;
  color: #ffffff;
  border-color: #28a745;

  :hover {
    background: #218838;
    border-color: #1e7e34;
    box-shadow: ${props =>
      `0 0 30px rgba(246, 28, 142, ${props.noShadow ? 0 : 0.5})`};
  }

  :active {
    color: #ffffff;
    background-color: #1e7e34;
    border-color: #1c7430;
  }
`;

const StyledNavigationButton = css<StyledButtonProps>`
  border: 1px solid #ccc;
  background-color: #fff;
  color: #4a4a4a;
  box-shadow: ${props => `0 0 5px rgba(0, 0, 0, ${props.noShadow ? 0 : 0.15})`};
  border-radius: 4px;

  :hover {
    box-shadow: 0 0 30px rgba(74, 74, 74, 0.26);
    border: 1px solid #c4c4c4;
  }

  :active {
    background-color: #f2f2f2;
  }
`;

const StyledErrorButton = css<StyledButtonProps>`
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;

  :hover {
    background: #c82333;
    border-color: #bd2130;
    box-shadow: ${props =>
      `0 0 30px rgba(246, 28, 142, ${props.noShadow ? 0 : 0.5})`};
  }

  :active {
    color: #ffffff;
    background-color: #bd2130;
    border-color: #b21f2d;
  }
`;

const StyledDarkButton = css<StyledButtonProps>`
  color: #838383;
  background-color: #d4d6d9;
  border-color: #d4d6d9;

  :hover {
    color: #d4d6d9;
    background: #838383;
    border-color: #838383;
  }

  :active {
    color: #ffffff;
    background-color: #4a4a4a;
    border-color: #4a4a4a;
  }
`;

const StyledActiveButton = css<StyledButtonProps>`
  border: 1px solid #4a90e2 !important;
  border-radius: 4px !important;
  color: #4a90e2;

  :hover {
    border: 1px solid #4a90e2 !important;
    border-radius: 4px !important;
    color: #4a90e2;
  }

  :active {
    border: 1px solid #4a90e2 !important;
    border-radius: 4px !important;
    color: #4a90e2;
  }
`;

function getAppearanceStyle(
  appearance?: ButtonAppearance
): typeof StyledPrimaryButton | null {
  switch (appearance) {
    case ButtonAppearance.Primary: {
      return StyledPrimaryButton;
    }
    case ButtonAppearance.Link: {
      return StyledLinkButton;
    }
    case ButtonAppearance.ActiveLink: {
      return StyledActiveLinkButton;
    }
    case ButtonAppearance.Success: {
      return StyledSuccessButton;
    }
    case ButtonAppearance.Navigation: {
      return StyledNavigationButton;
    }
    case ButtonAppearance.Dark: {
      return StyledDarkButton;
    }
    case ButtonAppearance.Error: {
      return StyledErrorButton;
    }
    case ButtonAppearance.Active: {
      return StyledActiveButton;
    }
    default:
      return null;
  }
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref): JSX.Element {
    const {
      children,
      appearance,
      inverted,
      wide,
      noShadow,
      square,
      as,
      ...buttonProps
    } = props;

    return (
      <StyledButton
        as={as ?? (props.href ? 'a' : 'button')}
        appearance={appearance}
        inverted={inverted}
        square={square}
        wide={wide}
        noShadow={noShadow}
        {...buttonProps}
        ref={ref}
      >
        {children}
      </StyledButton>
    );
  }
);
