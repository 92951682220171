import styled from 'styled-components';

export const CheckboxList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
export const CheckboxListRow = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;

  &:not(:last-child) {
    border-bottom: 1px solid #f2f4f8;
  }
`;

export const CheckboxListAvatarWrapper = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
`;

export const CheckboxListLabel = styled.label`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;
