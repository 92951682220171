import { loadFacts as spsSlcrLoadFacts } from './load-facts';
import { loadIndicators as spsSlcrLoadIndicators } from './load-indicators';
import { loadStressCompare as spsSlcrLoadStressCompare } from './load-stress-compare';
import { loadTrend as spsSlcrLoadTrend } from './load-trend';
import { loadVerticalDimensions as spsSlcrLoadVerticalDimensions } from './load-vertical-dimensions';

export const api = {
  loadFacts: spsSlcrLoadFacts,
  loadIndicators: spsSlcrLoadIndicators,
  loadStressCompare: spsSlcrLoadStressCompare,
  loadTrend: spsSlcrLoadTrend,
  loadVerticalDimensions: spsSlcrLoadVerticalDimensions,
};
