import { SeasonViewLoadingScreen } from '@yarmill/components';
import { AsyncStatus, CurrentUserStore, PermissionScope } from '@yarmill/types';
import { useCurrentUserStore, useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { ActivitiesList } from '../activities/activities-list';
import { useDiaryStore } from './diary-store-context';
import { DiaryStore } from './mobx/diary-store';
import { SeasonHeaderCell } from './season-header-cell';

function isAllowedView(
  currentUser: CurrentUserStore,
  diaryStore: DiaryStore
): boolean {
  const diaryType = diaryStore.diaryType;
  const permissionScope: PermissionScope = `${diaryType}.season.activities`;

  return Boolean(currentUser.isAllowedTo(permissionScope));
}

function InternalSeasonView(): JSX.Element | null {
  const rootStore = useRootStore();
  const diaryStore = useDiaryStore();
  const currentUser = useCurrentUserStore();

  if (!isAllowedView(currentUser, diaryStore)) {
    return null;
  }

  if (rootStore.status !== AsyncStatus.resolved) {
    return <SeasonViewLoadingScreen />;
  }

  const season = rootStore.seasonsStore.getSeasonByDate(diaryStore.week);

  return (
    <ActivitiesList
      columnsCount={season?.seasonSections.length || 12}
      HeaderCellContent={SeasonHeaderCell}
    />
  );
}

export const SeasonView = observer(InternalSeasonView);
