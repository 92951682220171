import {
  FormattedMessageWithDefaultValues,
  StyledTh,
  Text,
  TextSize,
  TextTag,
  Tippy,
  WhiteSpace,
  css,
  styled,
} from '@yarmill/components';
import * as React from 'react';
import {
  ReportingTableAlignment,
  ReportingTableColumnConfig,
  ReportingTableConfiguration,
} from './types';

export interface ReportingTableHeaderCellProps {
  config: ReportingTableConfiguration;
  column?: ReportingTableColumnConfig;
  children?: React.ReactNode;
  alignment?: ReportingTableAlignment | null;
  descriptiveData?: Record<string, string>;
}

const HeaderCell = styled(StyledTh)<{
  empty: boolean;
}>`
  position: sticky;
  padding-top: 26px;
  top: 0;
  background-color: #ffffff;
  font-family: "Ubuntu", "Apple Color Emoji", "Noto Color Emoji", sans-serif;

  @media (max-width: 800px) {
    top: 0;
    padding-top: 0;
  }

  ${props =>
    props.empty &&
    css`
      left: 0;
      z-index: 1;
    `}

  &:first-of-type {
    text-align: ${({ align }) => align};
  }

  @media print {
    page-break-inside: avoid;
    padding-top: 10px;
    position: relative;
  }
`;

export function ReportingTableHeaderCell(props: ReportingTableHeaderCellProps) {
  const { column, children, alignment, descriptiveData } = props;

  return (
    <HeaderCell
      empty={!column}
      align={alignment || 'right'}
      colSpan={column?.colSpan ?? undefined}
    >
      {column && (
        <Tippy
          tooltipContent={column?.tooltip}
          isEnabled={Boolean(column?.tooltip)}
          noWrapper
        >
          <Text
            size={TextSize.s12}
            bold
            whiteSpace={WhiteSpace.preWrap}
            tag={TextTag.span}
          >
            {column.label && (column.translateHeader ?? true) ? (
              <FormattedMessageWithDefaultValues
                id={column.label}
                values={descriptiveData}
              />
            ) : (
              column.label
            )}
          </Text>
        </Tippy>
      )}
      {children}
    </HeaderCell>
  );
}
