import {
  FormattedHTMLMessage,
  PageMainContent,
  Text,
} from '@yarmill/components';
import { AsyncStatus } from '@yarmill/types';
import { useConfig, useCurrentUserStore, useGroupsStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import { Page } from '../page/page';

export const NoGroup = observer(function NoGroup(): JSX.Element {
  const currentUser = useCurrentUserStore();
  const groups = useGroupsStore();
  const hasGroups =
    groups.sortedGroups.length > 0 &&
    groups.sortedGroups.some(group =>
      currentUser.hasPermissionToGroup(group.id)
    );
  const adminName = useConfig('adminName');
  const adminEmail = useConfig('adminEmail');

  if (currentUser.status === AsyncStatus.resolved && hasGroups) {
    return <Redirect to="/" />;
  }

  return (
    <Page>
      <PageMainContent>
        <Text>
          <FormattedHTMLMessage
            id="noGroup.noGroupMessage"
            values={{ adminName, adminEmail }}
          />
        </Text>
      </PageMainContent>
    </Page>
  );
});
