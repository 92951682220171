import { StyledProgress, StyledSvgWrapper } from './toast-progress';
import { StyledSvg } from './svg';

export function ToastSuccess(): JSX.Element {
  return (
    <StyledProgress>
      <StyledSvgWrapper>
        <StyledSvg viewBox="0 0 24 24">
          <g transform="scale(0.6) translate(8, 8)">
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
            />
          </g>
        </StyledSvg>
      </StyledSvgWrapper>
    </StyledProgress>
  );
}
