import { BreakpointWrapper, Breakpoints } from '@yarmill/components';
import { Header, HeaderPageTitle } from '@yarmill/components-2';
import { FormattedMessage } from 'react-intl';
import { BackToOkrsList } from './buttons/back-to-okrs-list';
import { Comments } from './buttons/comments';
import { useOkrsStore } from './okrs-store-context';

export function ObjectiveDetailHeader() {
  const okrsStore = useOkrsStore();

  return (
    <Header
      inverted
      left={<BackToOkrsList />}
      center={
        <HeaderPageTitle appearance="text15strong" absolutelyCentered>
          <BreakpointWrapper max={Breakpoints.tablet}>
            {okrsStore.athlete?.displayName ?? okrsStore.group?.name}
          </BreakpointWrapper>
          <BreakpointWrapper min={Breakpoints.tablet}>
            <FormattedMessage id="okrs.title" />
          </BreakpointWrapper>
        </HeaderPageTitle>
      }
      right={<Comments />}
    />
  );
}
