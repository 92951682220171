import { SettingsSidebarList, SidebarContainer } from '@yarmill/components';
import { useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { forwardRef } from 'react';
import { SidebarItem } from './sidebar-item';
import { SettingsPageView } from './types';

export interface SidebarProps {
  activeView: SettingsPageView;
}

export const Sidebar = observer(
  forwardRef<HTMLDivElement, SidebarProps>(
    function Sidebar(props, ref): JSX.Element {
      const { activeView } = props;
      const rootStore = useRootStore();
      const currentUser = rootStore.currentUserStore;
      const firstGroupId = rootStore.groupsStore.sortedGroups[0]?.id;

      return (
        <SidebarContainer data-cy="sidebar" ref={ref}>
          <SettingsSidebarList>
            {currentUser.isAllowedTo('settings.personal') && (
              <SidebarItem
                active={activeView === 'personal'}
                link="/settings/personal"
                title="personal"
              />
            )}
            {currentUser.isAllowedTo('settings.groups') && (
              <SidebarItem
                active={activeView === 'groups'}
                link={`/settings/groups/${firstGroupId || ''}`}
                title="groups"
              />
            )}
            {currentUser.isAllowedTo('settings.users') && (
              <SidebarItem
                active={activeView === 'users'}
                link="/settings/users"
                title="users"
              />
            )}
          </SettingsSidebarList>
        </SidebarContainer>
      );
    }
  )
);
