import { observer } from 'mobx-react-lite';
import { FormEvent } from 'react';
import { useIntl } from 'react-intl';
import { CommentInput } from '../components/comment-input';
import { useAddCommentHandler } from '../hooks/use-add-comment-handler';
import { ObjectiveStore } from '../mobx/objective-store';

interface CommentInputProps {
  readonly objective: ObjectiveStore;
}
export const AddComment = observer(function AddComment({
  objective,
}: CommentInputProps): JSX.Element {
  const intl = useIntl();
  const addObjective = useAddCommentHandler(objective);
  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (e.target instanceof HTMLFormElement) {
      const values = new FormData(e.target);
      const comment = values.get('comment');
      if (typeof comment === 'string' && comment.trim()) {
        void addObjective(comment.trim());
        e.target.reset();
      }
    }
  }

  return (
    <CommentInput
      submitButtonAriaLabel={intl.formatMessage({
        id: 'okrs.activityLog.comment.submit',
      })}
      onSubmit={handleSubmit}
    />
  );
});
