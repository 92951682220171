import styled from 'styled-components';
import { forwardRef } from 'react';

const StyledContextShim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

export interface ContextShimProps {
  onClick(): void;
}

export const ContextShim = forwardRef<HTMLDivElement, ContextShimProps>(
  function ContextShim(props: ContextShimProps, ref): JSX.Element {
    return <StyledContextShim onClick={props.onClick} ref={ref} />;
  }
);
