import {
  LayerOptions,
  LayerRenderer,
  LayerType,
  Layer as iLayer,
} from '@yarmill/types';
import { RefObject } from 'react';

export class Layer implements iLayer {
  public readonly type: LayerType;
  public readonly render: LayerRenderer;
  private readonly _options: LayerOptions;
  private readonly _removeFromState: (layer: Layer) => void;
  private _scrollContainerRef: RefObject<HTMLDivElement> | null = null;

  constructor(
    type: LayerType,
    render: LayerRenderer,
    removeFromState: (layer: Layer) => void,
    options: LayerOptions
  ) {
    this.type = type;
    this.render = render;
    this._removeFromState = removeFromState;
    this._options = options;
  }

  readonly close = (): void => {
    this._removeFromState(this);
    this._options.onClose?.();
  };

  get showShim(): boolean {
    return Boolean(this._options.showShim);
  }

  get options(): LayerOptions {
    return this._options;
  }

  setScrollContainerRef(ref: RefObject<HTMLDivElement>): void {
    this._scrollContainerRef = ref;
  }

  get scrollContainerRef(): RefObject<HTMLDivElement> | null {
    return this._scrollContainerRef;
  }
}
