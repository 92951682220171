import { UserId } from '@yarmill/types';

export type LogLevel = 'debug' | 'info' | 'warn' | 'error';
export const LOG_LEVELS: LogLevel[] = ['debug', 'info', 'warn', 'error'];

export interface LogEntry {
  agent?: string;
  appVersion: string;
  instanceCode: string;
  language: string;
  browserLanguage: string;
  level: LogLevel;
  message: string;
  scope: string;
  timezone: string;
  timestamp: number;
  url: string;
  user_id: UserId | undefined;
  user_name: string | undefined;
  user_role: string | undefined;
  windowHeight?: number;
  windowWidth?: number;
}
