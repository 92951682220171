import { DATE_FORMAT, ROUTE_DATE_FORMAT } from '@yarmill/const';
import moment from 'moment';

const dateFormats = [
  DATE_FORMAT,
  ROUTE_DATE_FORMAT,
  'DD-MM-YYYY',
  'DD.MM.YYYY',
  'DD. MM. YYYY',
];

export function sortByDate(
  a: string | moment.Moment | undefined,
  b: string | moment.Moment | undefined
): number {
  return moment(b, dateFormats).diff(moment(a, dateFormats));
}
