import {
  ATHLETE_SEARCH_PARAM,
  GROUP_SEARCH_PARAM,
  ROUTE_DATE_FORMAT,
  WEEK_SEARCH_PARAM,
} from '@yarmill/const';
import { RootStore } from '@yarmill/types';
import { getWeekStart } from '@yarmill/utils';
import { ObservableMap, action, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { ReportFilter } from '../types';

export class ReportingPageFilterStore {
  public static readonly MEMORY_STORE_KEY = 'reportingFilters';
  public static readonly ATHLETE_FILTER = 'Athlete';
  public static readonly GROUP_FILTER = 'UserGroup';
  public static readonly WEEK_FILTER = 'Week';

  private readonly _rootStore: RootStore;
  private readonly _definition: ReportFilter;
  private readonly _onChange?: () => void;
  @observable
  private _value: string | null;

  constructor(
    rootStore: RootStore,
    definition: ReportFilter,
    onChange?: () => void
  ) {
    this._rootStore = rootStore;
    this._definition = definition;
    this._value = definition.SelectedValue;
    this._onChange = onChange;
    makeObservable(this);
  }

  public get code() {
    return this._definition.Code;
  }

  public get position() {
    return this._definition.Position;
  }

  public get type() {
    return this._definition.Type;
  }

  public get title() {
    return this._definition.Title;
  }

  public get options() {
    return this._definition.Values;
  }

  public get translateLabels() {
    return this._definition.TranslateLabels;
  }

  public get value(): string | null {
    return this._value;
  }

  @action
  public setValue(value: string | null): void {
    if (value !== this._value) {
      this._value = value;
      this.persistValue(value);
      this._onChange?.();
    }
  }

  public loadPersistedValue(): void {
    const searchParams = this._rootStore.historyService.searchParams;

    if (this._definition.Code === ReportingPageFilterStore.GROUP_FILTER) {
      const groupId = searchParams.get(GROUP_SEARCH_PARAM);
      if (groupId) {
        this._value = groupId;
        return;
      }
    } else if (this._definition.Code === ReportingPageFilterStore.WEEK_FILTER) {
      const week = searchParams.get(WEEK_SEARCH_PARAM);
      if (week) {
        this._value = getWeekStart(week).format(ROUTE_DATE_FORMAT);
        return;
      } else {
        this._value = getWeekStart(moment()).format(ROUTE_DATE_FORMAT);
      }
    }

    const value = this._rootStore.memoryStore
      .getItem<ObservableMap<string, string>>(
        ReportingPageFilterStore.MEMORY_STORE_KEY
      )
      ?.get(this._definition.Code);

    if (value) {
      this._value = value;
    }
  }

  public loadUrlFilter(): void {
    const searchParams = this._rootStore.historyService.searchParams;

    if (this._definition.Code === ReportingPageFilterStore.ATHLETE_FILTER) {
      const athleteId = searchParams.get(ATHLETE_SEARCH_PARAM);
      if (athleteId) {
        this._value = athleteId;
        this.persistValue(athleteId);
      }
    }
  }

  public persistCurrentValue(): void {
    this.persistValue(this.value);
  }

  private persistValue(value: string | null): void {
    const memoryStore = this._rootStore.memoryStore;
    let persistedFilters = memoryStore.getItem<ObservableMap<string, string>>(
      ReportingPageFilterStore.MEMORY_STORE_KEY
    );
    if (!persistedFilters) {
      persistedFilters = new ObservableMap<string, string>();
      memoryStore.setItem(
        ReportingPageFilterStore.MEMORY_STORE_KEY,
        persistedFilters
      );
    }

    if (value) {
      if (this.code === ReportingPageFilterStore.GROUP_FILTER) {
        this._rootStore.historyService.updateSearchParams([
          { key: GROUP_SEARCH_PARAM, value },
          { key: ATHLETE_SEARCH_PARAM, value: null },
        ]);
      } else {
        persistedFilters.set(this._definition.Code, value);
      }
    } else {
      persistedFilters.delete(this._definition.Code);
    }
  }
}
