import styled from 'styled-components';
import { AthletesSidebarMobileArrow } from './arrow';

export * from './dropdown-toggle';

const HeaderLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 50%;
  column-gap: 12px;
  align-items: center;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 100%;
  }
`;
const GroupsToggleWrapper = styled.div`
  overflow: hidden;
`;
const AthletesToggleWrapper = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const AthletesListWrapper = styled.div<{ readonly isVisible: boolean }>`
  ${({ isVisible }) => !isVisible && `display: none;`};
  text-align: right;
  @media (min-width: 768px) {
    display: block;
    text-align: left;
  }
`;

export const HistoricalAthletesListLayout = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    padding-top: 36px;
    row-gap: 18px;
  }
`;

interface AthletesSidebarHeaderProps {
  readonly groupsToggle?: JSX.Element;
  readonly athletesToggle: JSX.Element;
  readonly hideAthletesInGroup?: boolean;
}

export function AthletesSidebarHeader({
  groupsToggle,
  athletesToggle,
  hideAthletesInGroup,
}: AthletesSidebarHeaderProps): JSX.Element {
  return (
    <HeaderLayout>
      <GroupsToggleWrapper>{groupsToggle}</GroupsToggleWrapper>
      {!hideAthletesInGroup && (
        <>
          <AthletesSidebarMobileArrow />
          <AthletesToggleWrapper>{athletesToggle}</AthletesToggleWrapper>
        </>
      )}
    </HeaderLayout>
  );
}
