import { RootStore } from '@yarmill/types';
import {
  ReportFilter,
  ReportItem,
  ReportItemStore,
  ReportPage,
  ReportingSection,
} from '../types';
import { createReportItemStore } from '../utils/create-report-item-store';

export class ReportingPageStore {
  private readonly _rootStore: RootStore;
  private readonly _page: ReportPage;
  private readonly _sections: ReportingSection[];
  private readonly _items: ReportItemStore[];
  private readonly _filters: ReportFilter[];

  constructor(rootStore: RootStore, page: ReportPage) {
    this._rootStore = rootStore;
    this._page = page;
    this._sections = page.Sections;
    this._filters = page.Filters;
    this._items = this.createReportItemStores(page.ReportItems).filter(
      Boolean
    ) as ReportItemStore[];
  }

  get code(): string {
    return this._page.ReportPageCode;
  }

  get title(): string {
    return this._page.Title;
  }

  get description(): string {
    return this._page.Description;
  }

  get sections(): ReportingSection[] {
    return this._sections;
  }

  get filters(): ReportFilter[] {
    return this._filters;
  }

  get reportItems(): ReportItemStore[] {
    return this._items;
  }

  get enableSubscription(): boolean {
    return this._page.EnableSubscription;
  }

  get page(): ReportPage {
    return this._page;
  }

  private createReportItemStores(
    items: ReportItem[] | null
  ): (ReportItemStore | null)[] {
    return (
      items?.map(item =>
        createReportItemStore(item, this.code, this._rootStore)
      ) ?? []
    );
  }
}
