import { getBarRadius } from '../../../reporting/utils/get-bar-radius';
import { Bar as VisxBar } from '@visx/shape';
import { css, styled } from '../../../theme-provider';
import { MouseEventHandler } from 'react';

interface BarProps {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
  readonly opacity: number;
  readonly color: string;
  readonly highlightColor?: string | null;
  readonly isHighlighted?: boolean;
  readonly isHorizontal?: boolean;
  readonly onMouseEnter?: MouseEventHandler;
  readonly onMouseLeave?: MouseEventHandler;
}

interface StyledBarProps {
  readonly $highlightColor?: string | null;
}

const StyledBar = styled(VisxBar)<StyledBarProps>`
  ${({ $highlightColor }) =>
    $highlightColor &&
    css`
      &:hover {
        fill: ${$highlightColor};
      }
    `}
`;

export function Bar({
  x,
  y,
  width,
  height,
  color,
  opacity,
  highlightColor,
  isHighlighted,
  isHorizontal,
  onMouseEnter,
  onMouseLeave,
}: BarProps) {
  if (Number.isNaN(height) || height <= 0) {
    return null;
  }
  return (
    <StyledBar
      x={x}
      y={y}
      width={width}
      height={height}
      fill={highlightColor && isHighlighted ? highlightColor : color}
      opacity={opacity ?? 1}
      rx={getBarRadius(isHorizontal ? height : width)}
      $highlightColor={highlightColor}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
}
