import styled from 'styled-components';
import { getProgressPath } from './utils';

export const StyledProgressPath = styled.path`
  fill: none;
  stroke: ${({ stroke }) => stroke ?? '#ffffff'};
`;

interface ProgressProp {
  readonly progress?: number;
  readonly strokeWidth?: number;
  readonly svgSize?: number;
  readonly progressColor?: string;
  readonly radius?: number;
}

export function ProgressPath(props: ProgressProp): JSX.Element {
  const {
    progress,
    strokeWidth = 2,
    svgSize = 16,
    progressColor,
    radius,
  } = props;

  return (
    <StyledProgressPath
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeOpacity="1"
      stroke={progressColor}
      d={getProgressPath(progress ?? 0.5, svgSize, strokeWidth, radius)}
    />
  );
}
