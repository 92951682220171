import { styled } from '../theme-provider';

export * from './link-content';
export const SidebarContainer = styled.nav<{
  readonly $stickyButton?: boolean;
}>`
  @media (min-width: 768px) {
    row-gap: 36px;
    height: 100%;
    max-height: calc(100vh - 80px - var(--vh-offset, 0px));
    overflow: auto;
    border-right: 1px solid hsla(0, 0%, 59%, 0.21);
    padding: 25px;

    ${({ $stickyButton }) => $stickyButton && `padding-bottom: 0;`};
  }
`;

export const SidebarList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) =>
    theme.name === 'main' ? theme.size.x05 : theme.size.x2};

  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const SettingsSidebarList = styled(SidebarList)`
  list-style-type: none;
  margin: 0;
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 16px;
  row-gap: 16px;

  @media (min-width: 768px) {
    padding: 0;
    justify-content: unset;
    flex-direction: column;
  }
`;

export const SecondarySidebarDropdownToggle = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const SecondarySidebarListWrapper = styled.div<{
  readonly isOpened: boolean;
}>`
  display: ${({ isOpened }) => (isOpened ? 'block' : 'none')};

  @media (min-width: 768px) {
    display: block;
  }
`;
