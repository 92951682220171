import { isBrowser } from '@yarmill/utils';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ContentRow } from './content-row';
import { HeaderRow } from './header-row';
import { RowLines } from './row-lines';
import {
  OVERVIEW_TABLE_CELL_SIZE,
  OVERVIEW_TABLE_LABEL_WIDTH,
  OVERVIEW_TABLE_MIN_COL_GAP,
  OverviewTableData,
  OverviewTableDataFormatter,
  getOverviewTableMinimalWidth,
} from './utils';

export { getOverviewTableMinimalWidth };

interface OverviewTableReportProps {
  hasRowLabel?: boolean;
  width: number;
  columnsCount: number;
  isInitialRender: boolean;
  firstColumnWidth: number;
}

function getCorrectedFirstColumnWidth(
  tableWidth: number,
  columnsCount: number,
  firstColumnWidth: number
): number {
  const maxWidth =
    tableWidth -
    columnsCount * OVERVIEW_TABLE_CELL_SIZE -
    (columnsCount - 1) * OVERVIEW_TABLE_MIN_COL_GAP;

  return Math.max(
    Math.min(maxWidth, firstColumnWidth + 5), // + 5 buffer for unnecessary ellipsis
    OVERVIEW_TABLE_LABEL_WIDTH
  );
}

function getGridColumns(props: OverviewTableReportProps): string {
  const {
    isInitialRender,
    hasRowLabel,
    columnsCount,
    width,
    firstColumnWidth,
  } = props;

  if (isInitialRender) {
    return `max-content repeat(${columnsCount}, ${OVERVIEW_TABLE_CELL_SIZE}px)`;
  } else {
    if (hasRowLabel) {
      const correctedFirstColumnWidth = getCorrectedFirstColumnWidth(
        width,
        columnsCount,
        firstColumnWidth
      );
      return `${correctedFirstColumnWidth}px repeat(${columnsCount}, ${OVERVIEW_TABLE_CELL_SIZE}px)`;
    } else {
      return `repeat(${columnsCount}, ${OVERVIEW_TABLE_CELL_SIZE}px)`;
    }
  }
}

function getColumnGap(props: OverviewTableReportProps): string | number {
  const {
    isInitialRender,
    hasRowLabel,
    columnsCount,
    width,
    firstColumnWidth,
  } = props;

  if (isInitialRender) {
    return 0;
  } else {
    if (hasRowLabel) {
      const correctedFirstColumnWidth = getCorrectedFirstColumnWidth(
        width,
        columnsCount,
        firstColumnWidth
      );

      return `${Math.floor(
        (width -
          correctedFirstColumnWidth -
          columnsCount * OVERVIEW_TABLE_CELL_SIZE) /
          columnsCount
      )}px`;
    } else {
      return `${Math.floor(
        (width - columnsCount * OVERVIEW_TABLE_CELL_SIZE) / (columnsCount - 1)
      )}px`;
    }
  }
}

function measureFirstColumn(
  tableRef: RefObject<HTMLDivElement>,
  columnsCount: number
): number {
  const table = tableRef.current;

  if (!table) {
    return 0;
  }

  return table.clientWidth - columnsCount * OVERVIEW_TABLE_CELL_SIZE;
}

const OverviewTableReport = styled.div<OverviewTableReportProps>`
  display: ${({ isInitialRender }) =>
    isInitialRender ? 'inline-grid' : 'grid'};
  grid-template-columns: ${getGridColumns};
  column-gap: ${getColumnGap};
  row-gap: 10px;
  visibility: ${({ isInitialRender }) =>
    isInitialRender ? 'hidden' : 'visible'};
`;

export interface OverviewTableProps {
  columns: string[];
  data: OverviewTableData;
  rowLabelFormatter?: OverviewTableDataFormatter;
  valueFormatter?: OverviewTableDataFormatter;
  categoryFormatter?: OverviewTableDataFormatter<string>;
  hasHeaderRow?: boolean;
  hasRowLabel?: boolean;
  headerFormatter?: OverviewTableDataFormatter;
  width: number;
}

export function OverviewTable(props: OverviewTableProps): JSX.Element {
  const {
    data,
    rowLabelFormatter,
    columns,
    valueFormatter,
    categoryFormatter,
    hasHeaderRow,
    hasRowLabel = true,
    headerFormatter,
    width,
  } = props;
  const [isInitialRender, setIsInitialRender] = useState(isBrowser);
  const [firstColumnWidth, setFirstColumnWidth] = useState(isBrowser ? 0 : 200);
  const tableRef = useRef<HTMLDivElement>(null);
  const columnsIndexMap = useMemo(
    () => Object.fromEntries(columns.map((key, idx) => [key, idx])),
    [columns]
  );
  const minWidth = getOverviewTableMinimalWidth(hasRowLabel, columns.length);

  useEffect(() => {
    if (data.length) {
      setIsInitialRender(true);
    }
  }, [data]);

  useEffect(() => {
    if (isInitialRender) {
      const width = measureFirstColumn(tableRef, columns.length);
      setFirstColumnWidth(width);
      setIsInitialRender(false);
    }
  }, [columns, isInitialRender]);

  return (
    <OverviewTableReport
      hasRowLabel={hasRowLabel}
      columnsCount={columns.length}
      width={width}
      isInitialRender={isInitialRender}
      ref={tableRef}
      firstColumnWidth={firstColumnWidth}
    >
      <RowLines
        count={data.length}
        numberOfColumns={hasRowLabel ? columns.length + 1 : columns.length}
        hasHeader={Boolean(hasHeaderRow)}
      />
      {hasHeaderRow && data.length !== 0 && (
        <HeaderRow
          key="header"
          columns={columns}
          headerFormatter={headerFormatter}
          hasRowLabel={hasRowLabel}
          sticky={width >= minWidth}
        />
      )}
      {data.map((item, rowNumber) => (
        <ContentRow
          key={item.Key}
          columns={columns}
          row={hasHeaderRow ? rowNumber + 1 : rowNumber}
          data={item}
          categoryFormatter={categoryFormatter}
          rowLabelFormatter={rowLabelFormatter}
          valueFormatter={valueFormatter}
          columnsIndexMap={columnsIndexMap}
          hasRowLabel={hasRowLabel}
        />
      ))}
    </OverviewTableReport>
  );
}
