import { Video } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise, CancelToken } from 'axios';

export interface SearchVideoRequestParams {
  searchText: string;
  skip: number;
  limit: number;
}

export function searchVideo(
  params: SearchVideoRequestParams,
  cancelToken: CancelToken
): AxiosPromise<Video[]> {
  return axios.post('api/Video', params, { cancelToken });
}
