import { createElement } from 'react';

export function Bold() {
  return createElement(
    'svg',
    {
      viewBox: '0 0 18 18',
      xmlns: 'http://www.w3.org/2000/svg',
      fill: 'currentColor',
      role: 'img',
    },
    createElement('path', {
      d: 'M5 4h4.5A2.5 2.5 0 0 1 12 6.5v0A2.5 2.5 0 0 1 9.5 9H5h0V4h0ZM5 9h5.5a2.5 2.5 0 0 1 2.5 2.5v0a2.5 2.5 0 0 1-2.5 2.5H5h0V9h0Z',
      fill: 'none',
      stroke: 'currentColor',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.6',
    })
  );
}
