import { Fragment, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Icon, IconSize } from '../icon';
import { Text, TextAlignment, TextSize, TextTag } from '../text';

type HealthDataOverviewProps = PropsWithChildren;

const HealthDataOverviewLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #f5f7fa;
  border-radius: 4px;
  padding: 14px 28px;

  @media (min-width: 991px) and (max-width: 1240px) {
    padding: 7px 14px;
  }
`;
export function HealthDataOverview(
  props: HealthDataOverviewProps
): JSX.Element {
  return <HealthDataOverviewLayout>{props.children}</HealthDataOverviewLayout>;
}

interface HealthDataOverviewRowProps {
  readonly icon: JSX.Element;
  readonly label: ReactNode;
  readonly primaryValue: ReactNode;
  readonly secondaryValues?: ReactNode[];
}

const HealthDataOverviewRowLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  align-items: center;
  column-gap: 8px;
  color: #4a4a4a;

  & + & {
    border-top: 1px solid #e0e0e0;
    padding-top: 10px;
  }
`;

const SecondaryRowLayout = styled(Text)`
  grid-column: 1/4;
  grid-row: 2/3;
  color: #9b9b9b;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const SecondaryValueWrapper = styled(Text)`
  line-height: 21px;
  font-family: "Roboto Mono", "Ubuntu", serif;
`;

const StyledSeparator = styled(Text)`
  color: rgba(155, 155, 155, 0.5);
  line-height: 21px;
`;

export function HealthDataOverviewRow(
  props: HealthDataOverviewRowProps
): JSX.Element {
  return (
    <HealthDataOverviewRowLayout>
      <Icon size={IconSize.s20}>{props.icon}</Icon>
      <Text textAlign={TextAlignment.left} size={TextSize.s14}>
        {props.label}
      </Text>
      <Text textAlign={TextAlignment.right} size={TextSize.s14} monoSpace>
        {props.primaryValue}
      </Text>
      <SecondaryRowLayout size={TextSize.s10} tag={TextTag.div}>
        {props.secondaryValues?.map((value, index) => (
          <Fragment key={index}>
            {index !== 0 && (
              <StyledSeparator size={TextSize.s14} inheritColor>
                |
              </StyledSeparator>
            )}
            <SecondaryValueWrapper size={TextSize.s10} inheritColor monoSpace>
              {value}
            </SecondaryValueWrapper>
          </Fragment>
        ))}
      </SecondaryRowLayout>
    </HealthDataOverviewRowLayout>
  );
}
