export function getFileExtension(fileName: string): string {
  // Split the file name by periods (.)
  const parts = fileName.split('.');

  // If there are no periods in the file name or the file name ends with a period,
  // return an empty string (no extension)
  if (parts.length === 1 || fileName.endsWith('.')) {
    return '';
  }

  // The extension is the last part of the split
  const extension = parts[parts.length - 1];

  return extension;
}
