import { ThemeDefinition } from './theme-definition';
import { main } from './main';

export const legacy: ThemeDefinition = {
  ...main,
  name: 'legacy',
  text: {
    font: {
      default: 'Ubuntu, Apple Color Emoji, Noto Color Emoji, serif',
      mono: 'Roboto Mono, Apple Color Emoji, Noto Color Emoji, serif',
      emoji: 'Apple Color Emoji, Noto Color Emoji, Android Emoji',
    },
    appearance: {
      ...main.text.appearance,
      h32: {
        fontSize: '32px',
        fontWeight: '700',
        lineHeight: '40px',
      },
      h28: {
        fontSize: '28px',
        fontWeight: '700',
        lineHeight: '36px',
      },
      text16: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
      },
      h16: {
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '24px',
      },
      nav16semi: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
      },
      nav16strong: {
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '24px',
      },
      text15strong: {
        fontSize: '15px',
        fontWeight: '700',
        lineHeight: '24px',
      },
      text15: {
        fontSize: '15px',
        fontWeight: '400',
        lineHeight: '24px',
      },
      task13strong: {
        fontSize: '13px',
        fontWeight: '700',
        lineHeight: '16px',
      },
      task13: {
        fontSize: '13px',
        fontWeight: '400',
        lineHeight: '19px',
      },
      button10: {
        fontSize: '10px',
        fontWeight: '700',
        lineHeight: '12px',
      },
      label10strong: {
        fontSize: '10px',
        fontWeight: '700',
        lineHeight: '12px',
      },
      label10: {
        fontSize: '10px',
        fontWeight: '400',
        lineHeight: '12px',
      },
    },
  },
  color: {
    ...main.color,
    text: '#4a4a4A',
  },
};
