import { Locale, PdfOrientation, UserGroupId, UserId } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';

export interface ExportDiaryActivitiesToPdfRequestParams {
  language: Locale;
  state: 'P' | 'R';
  startDate: string;
  endDate: string;
  userId: UserId | undefined;
  userGroupId: UserGroupId | undefined;
  orientation: PdfOrientation;
}

export function exportDiaryActivitiesToPdf(
  params: ExportDiaryActivitiesToPdfRequestParams,
  cancelToken: CancelTokenSource
): AxiosPromise<BlobPart> {
  return axios.post(`api/print/diaryActivities`, params, {
    responseType: 'blob',
    cancelToken: cancelToken.token,
  });
}
