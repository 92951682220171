import {
  EvidenceDataObject,
  EvidenceDataObjectAttribute,
  EvidenceObjectTags,
} from '@yarmill/types';
import { axios, devMode, instance } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export function addEvidenceObject(
  dataUrl: string,
  moduleKey: string,
  objectCategoryKey: string,
  attributes: EvidenceDataObjectAttribute[],
  userId: number | null,
  groupId: number | null,
  tags: EvidenceObjectTags | undefined
): AxiosPromise<EvidenceDataObject> {
  return axios.post(
    dataUrl,
    {
      ObjectCategoryKey: objectCategoryKey,
      GroupId: userId === null ? (groupId ?? undefined) : undefined,
      UserId: userId !== null ? userId : undefined,
      Attributes: attributes,
      Tags: tags,
    },

    {
      params: {
        groupId: userId === null ? (groupId ?? undefined) : undefined,
        userId: userId !== null ? userId : undefined,
        moduleKey,
        instanceCode: devMode ? instance : undefined,
      },
    }
  );
}
