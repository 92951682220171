import { action, computed, observable, makeObservable } from 'mobx';
import { ChangeEvent, RefObject } from 'react';
import { InputArrayValueData } from '../../training-day/types';

export class InputStore {
  public readonly index: number;
  @observable
  private _userValue: string | null = null;

  private _inputRef: RefObject<HTMLInputElement> | null = null;

  constructor(index: number) {
    makeObservable(this);
    this.index = index;
  }

  public readonly onChange = (e: ChangeEvent): void => {
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      const { value } = target;
      this.setValue(value);
    }
  };

  @action
  public setApiValue(apiValue: InputArrayValueData): void {
    this._userValue = apiValue?.Value || null;
  }

  @action
  public setValue(userValue: string): void {
    this._userValue = userValue;
  }

  @action
  public clear(): void {
    this._userValue = null;
  }

  public get userValue(): string | null {
    return this._userValue;
  }

  @computed
  public get currentValue(): string {
    return this._userValue ?? '';
  }

  @computed
  public get formattedValue(): string {
    return this._userValue ?? '';
  }

  public setInputRef(ref: RefObject<HTMLInputElement>): void {
    this._inputRef = ref;
  }

  public getInputRef(): RefObject<HTMLInputElement> | null {
    return this._inputRef;
  }
}
