import { ServerError } from '@yarmill/types';

// Type guard function
export function isServerError(obj: unknown): obj is ServerError {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  const serverError = obj as ServerError;

  return (
    typeof serverError.Id === 'string' &&
    (serverError.Field === undefined ||
      typeof serverError.Field === 'string') &&
    (serverError.Values === undefined || typeof serverError.Values === 'object')
  );
}
