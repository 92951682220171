import { NavigationLink } from '@yarmill/types';
import {
  useConfig,
  useLocation,
  useRootStore,
  useWindowSize,
} from '@yarmill/utils';
import {
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForceUpdate } from '../layer-manager/hooks';
import { NavigationStore } from './mobx/navigation-store';
import { NavigationContext } from './navigation-context';

export function useNavigationStore(): NavigationStore {
  return useContext(NavigationContext);
}

export function useMainNavButtons(): NavigationLink[] {
  const rootStore = useRootStore();
  const modulesRegistry = rootStore.modulesRegistryService;
  const navigationConfig = useConfig('headerNavigationConfig');

  if (!modulesRegistry.isReady) {
    return [];
  }

  let items: NavigationLink[] = modulesRegistry.enabledModules.flatMap(
    module => module.navigationLinks
  );

  if (navigationConfig) {
    const itemsMap = new Map(items.map(item => [item.moduleCode, item]));
    items = navigationConfig
      .map(item => itemsMap.get(item.module))
      .filter((item): item is NavigationLink => Boolean(item));
  }

  return items;
}

export function useActiveHeaderLink(links: NavigationLink[]): NavigationLink {
  const { pathname } = useLocation();

  return useMemo(() => {
    const splitRoute = pathname.split('/');
    const activeItem = splitRoute[1];
    const moduleId = splitRoute[2];
    const reportingLinks = links.find(link => link.moduleCode === 'reporting');
    const settings: NavigationLink = {
      moduleCode: 'settings',
      icon: 'Adjustments',
      label: 'header.navigation.settings',
      createClickHandler: () => () => '/settings/personal',
    };

    const activeModuleDefinition: NavigationLink | undefined =
      activeItem === 'evidence'
        ? (links.find(l => l.moduleCode === `${activeItem}.${moduleId}`) ??
          links.find(l => l.moduleCode === 'evidence'))
        : activeItem === 'reporting' || activeItem === 'analytics'
          ? reportingLinks?.children?.find(
              link => link.moduleCode === `${activeItem}.${moduleId}`
            )
          : (links.find(link => link.moduleCode === activeItem) ?? settings);

    return activeModuleDefinition || settings;
  }, [links, pathname]);
}

const visibleElementsCache: Record<number, number> = {};
export function useMaxVisibleItems(
  total: number,
  listRef: RefObject<HTMLUListElement>,
  wrapperRef: RefObject<HTMLDivElement>
): [number, boolean] {
  const navigationConfig = useConfig('headerNavigationConfig');
  const totalAttempts = useRef(0);
  const showNavigation = useRef(false);
  const { width: windowWidth } = useWindowSize();
  let maxElements = navigationConfig
    ? navigationConfig.findIndex(i => i.isHidden)
    : total;

  if (maxElements === -1) {
    maxElements = total;
  }

  const [visibleElements, setVisibleElements] = useState(
    windowWidth
      ? (visibleElementsCache[windowWidth] ?? maxElements)
      : maxElements
  );
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (windowWidth) {
      totalAttempts.current = 0;
    }
  }, [windowWidth]);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const list = listRef.current;

    if (wrapper && list && windowWidth) {
      if (windowWidth < 768) {
        return;
      }

      if (wrapper.clientWidth < list.scrollWidth) {
        totalAttempts.current++;
        const newVisibleElements = visibleElements - 1;
        visibleElementsCache[windowWidth] = newVisibleElements;
        setVisibleElements(newVisibleElements);
      } else if (
        wrapper.clientWidth > list.scrollWidth + 150 &&
        visibleElements < maxElements &&
        totalAttempts.current < maxElements
      ) {
        totalAttempts.current++;
        const newVisibleElements = Math.min(maxElements, visibleElements + 1);
        visibleElementsCache[windowWidth] = newVisibleElements;
        setVisibleElements(newVisibleElements);
      } else {
        showNavigation.current = true;
        forceUpdate();
      }
    }
  }, [
    visibleElements,
    windowWidth,
    forceUpdate,
    maxElements,
    wrapperRef,
    listRef,
  ]);

  return [visibleElements, showNavigation.current];
}
