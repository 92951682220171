import { File, ServerErrorResponse } from '@yarmill/types';
import {
  getFileExtension,
  getFileNameWithoutExtension,
  isValidFileName,
} from '@yarmill/utils';
import { useFormik } from 'formik';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AlertLayerCloseButtonWrapper, AlertLayerContent } from './alert-layer';
import { Button, ButtonAppearance } from './button';
import { CloseButton } from './close-button';
import { ErrorBox } from './error';
import {
  RenameFileAlertLayerContainer,
  RenameFileInputWrappers,
} from './files-overview';
import { FormattedHTMLMessage } from './formatted-html-message';
import { Text, TextSize, TextTag } from './text';
import { TextInput } from './text-input';

interface RenameFileDialogProps {
  readonly close: () => void;
  readonly file: File;
  readonly rename: (name: string) => Promise<true | ServerErrorResponse>;
}
export function RenameFileDialog({
  close,
  file,
  rename,
}: RenameFileDialogProps): JSX.Element {
  const intl = useIntl();
  const [apiError, setApiError] = useState<ServerErrorResponse | null>(null);
  const formik = useFormik({
    initialValues: {
      fileName: getFileNameWithoutExtension(file.FileName),
      extension: getFileExtension(file.FileName),
    },

    validateOnBlur: true,
    validateOnChange: true,
    validate: ({ fileName, extension }) => {
      if (!isValidFileName(`${fileName}.${extension}`)) {
        return {
          fileName: intl.formatMessage({
            id: 'trainingDay.attributes.attachment.rename.invalidFileName',
          }),
        };
      }
    },
    onSubmit: async (values, formikHelpers) => {
      setApiError(null);
      const errors = await formikHelpers.validateForm(values);

      if (Object.keys(errors).length === 0) {
        const result = await rename(
          `${values.fileName.trim()}.${values.extension}`
        );

        if (result === true) {
          close();
          formik.resetForm();
        } else {
          setApiError(result);
        }
      }
    },
  });

  return (
    <RenameFileAlertLayerContainer>
      <AlertLayerContent.StickyWrapper>
        <AlertLayerCloseButtonWrapper>
          <CloseButton onClick={close} hideText />
        </AlertLayerCloseButtonWrapper>
      </AlertLayerContent.StickyWrapper>
      <AlertLayerContent.ContentWrapper onSubmit={formik.handleSubmit}>
        <AlertLayerContent.HeadlineWrapper>
          <Text tag={TextTag.h2} size={TextSize.s16} medium>
            <FormattedMessage id="trainingDay.attributes.attachment.rename" />
          </Text>
        </AlertLayerContent.HeadlineWrapper>
        {apiError && (
          <ErrorBox>
            <FormattedHTMLMessage id={apiError.id} values={apiError.values} />
          </ErrorBox>
        )}
        <RenameFileInputWrappers
          extensionLength={formik.values.extension?.length ?? 3}
        >
          <div>
            <TextInput
              autoFocus
              id="fileName"
              name="fileName"
              noLabel
              noError={!formik.errors.fileName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fileName}
              error={formik.errors.fileName}
            />
          </div>
          <div>
            <TextInput
              noLabel
              noError={!formik.errors.fileName}
              id="extension"
              name="extension"
              disabled
              readOnly
              value={formik.values.extension}
            />
          </div>
        </RenameFileInputWrappers>
        <AlertLayerContent.ButtonsWrapper>
          <Button
            type="button"
            appearance={ButtonAppearance.Link}
            noShadow
            data-cy="cancel"
            onClick={close}
          >
            <FormattedMessage id="diary.exportAttributes.buttons.cancel" />
          </Button>
          <Button
            type="submit"
            appearance={ButtonAppearance.Primary}
            noShadow
            disabled={Object.keys(formik.errors).length !== 0}
            data-cy="save"
          >
            <FormattedMessage id="trainingDay.attributes.attachment.rename.submit" />
          </Button>
        </AlertLayerContent.ButtonsWrapper>
      </AlertLayerContent.ContentWrapper>
    </RenameFileAlertLayerContainer>
  );
}
