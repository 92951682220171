import { useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { ContentBox, IconSize, Spinner } from '@yarmill/components';

export interface StyledImageProps {
  readonly loaded: boolean;
}
export const StyledImage = styled.img<StyledImageProps>`
  opacity: ${props => (props.loaded ? 1 : 0)};
  position: ${props => (props.loaded ? 'relative' : 'absolute')};
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  max-width: 90vw;
  max-height: calc(100vh - 150px - var(--vh-offset, 0px));
`;

const ImageWrapper = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  max-height: calc(100vh - 150px - var(--vh-offset, 0px));
`;

export interface ImageProps {
  src: string | Promise<string>;
  isImageLoaded: boolean;
  isValidSrc: boolean;
  setIsImageLoaded: (val: boolean) => void;
  setIsValidSrc: (val: boolean) => void;
}

export function Image(props: ImageProps): JSX.Element {
  const { src, setIsImageLoaded, setIsValidSrc, isImageLoaded, isValidSrc } =
    props;

  useEffect(() => {
    setIsValidSrc(!!src);
    setIsImageLoaded(false);
  }, [src, setIsValidSrc, setIsImageLoaded]);

  useEffect(() => {
    if (typeof src === 'string' && src.startsWith('blob:')) {
      setIsImageLoaded(true);
    }
  }, [src, setIsImageLoaded]);

  return (
    <>
      {isValidSrc && typeof src === 'string' && (
        <ImageWrapper>
          <StyledImage
            loaded={isImageLoaded}
            src={src}
            alt={src}
            onLoad={() => setIsImageLoaded(true)}
            onError={() => setIsValidSrc(false)}
            data-cy="image-preview"
          />
        </ImageWrapper>
      )}
      {!isValidSrc && (
        <ContentBox>
          <FormattedMessage id="imagePreview.invalidImage" />
        </ContentBox>
      )}
      {isValidSrc && !isImageLoaded && <Spinner size={IconSize.s48} />}
    </>
  );
}
