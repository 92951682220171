import {
  ATHLETE_SEARCH_PARAM,
  GROUP_SEARCH_PARAM,
  WEEK_SEARCH_PARAM,
} from '@yarmill/const';
import { DiaryRouteParams } from '@yarmill/types';

export function getDiaryRouteLink(
  routeParams: DiaryRouteParams,
  overrides: Partial<DiaryRouteParams> = {}
): string {
  const finalParams = { ...routeParams, ...overrides };

  const searchParams = new URLSearchParams();
  searchParams.set(WEEK_SEARCH_PARAM, finalParams.week);
  searchParams.set(GROUP_SEARCH_PARAM, String(finalParams.groupId));

  if (finalParams.athleteId) {
    searchParams.set(ATHLETE_SEARCH_PARAM, String(finalParams.athleteId));
  }

  return `/${finalParams.diaryType}/${
    finalParams.viewType
  }?${searchParams.toString()}`;
}
