import { UserId } from '@yarmill/types';

export interface Objective {
  readonly id: number | null;
  readonly title: string | null;
  readonly description: string | null;
  readonly category: OkrCategory | null;
  readonly supervisors: UserId[] | null;
  readonly status: OkrStatus | null;
  readonly priority: OkrPriority | null;
  readonly keyResults: KeyResult[];
  readonly activities?: ObjectiveActivity[] | null;
  readonly lastUpdated: string | null;
  readonly evaluations?: ObjectiveEvaluation[];
}

export interface ObjectivePreview {
  readonly id: number;
  readonly status: OkrStatus | null;
  readonly keyResults: { id: number; status: OkrStatus | null }[];
}

export interface ObjectiveActivity {
  activityId: number | null;
  readonly createdBy: UserId;
  readonly eventDate: string | null;
  readonly icon: string | null;
  readonly label: string | null;
  readonly activityType: string | null;
  readonly labelParams: Record<string, string> | null;
}

export type ObjectiveRightPanelType = 'validation' | 'activity-log' | null;

export const OkrPriorities = ['low', 'medium', 'high'] as const;
export const OkrRootCategories = [
  'performance',
  'fitness',
  'personal',
  'technical',
  'health',
  'conditions',
] as const;
export type OkrPriority = (typeof OkrPriorities)[number];
export type OkrRootCategory = (typeof OkrRootCategories)[number];

export type OkrCategory = OkrRootCategory | `${OkrRootCategory}.${string}`;
export const OkrStatuses = [
  'not-started',
  'on-track',
  'off-track',
  'completed',
  'canceled',
  'failed',
] as const;
export type OkrStatus = (typeof OkrStatuses)[number];

export interface ObjectiveEvaluation {
  evaluationText: string;
  readonly createdBy: UserId;
  readonly dateCreated: string;
}

export type KeyResultId = number;
export type KeyResultUid = string;
export interface KeyResult {
  readonly id: KeyResultId | null;
  readonly uid: KeyResultUid;
  readonly status: OkrStatus | null;
  readonly targetDate: string | null;
  readonly title: string | null;
  readonly metric: string | null;
  readonly startValue: string | null;
  readonly currentValue: string | null;
  readonly targetValue: string | null;
  readonly progressValue: number | null;
  readonly sortCode: number | null;
  readonly lastUpdated: string | null;
}

export const mockOkrCategoryList: OkrCategory[] = [
  ...OkrRootCategories,
  'performance.result',
  'fitness.speed',
  'fitness.strength',
  'fitness.endurance',
  'personal.education',
  'personal.psyche',
  'personal.communication',
  'technical.coordination',
  'technical.technique',
  'technical.tactics',
  'health.bodyComposition',
  'health.nutrition',
  'health.recovery',
  'health.health',
  'conditions.background',
  'conditions.equipment',
];

export interface ObjectiveFormValues {
  id: number | null;
  title: string;
  description: string;
  priority: OkrPriority;
  category: OkrCategory | null;
  supervisors: UserId[];
  status: OkrStatus;
}

export type ObjectiveValidationRule =
  | 'missingTitle'
  | 'missingCategory'
  | 'missingSupervisor'
  | 'missingDescription'
  | 'missingKeyResults'
  | 'keyResultsCount';

export type ObjectiveActivityLogTab = 'activities' | 'comments';

export interface OkrExportTemplate {
  readonly templateCode: string;
  readonly filters?: string[];
}
