import { Placement } from '@floating-ui/react';
import { Icon, IconSize, styled } from '@yarmill/components';
import {
  Button,
  ButtonAppearance,
  Text,
  TextInputAppearance,
  getAppearanceColors,
} from '@yarmill/components-2';
import { Cal, Edit, Location, Tag, Team } from '@yarmill/icons-2';

const FLOATING_DETAIL_WIDTH = 232;

const TrainingEventFloatingDetailLayout = styled.div<{
  readonly appearance: TextInputAppearance;
}>`
  border-radius: ${({ theme }) => theme.borderRadius.x1};
  background-color: ${({ theme, appearance }) =>
    getAppearanceColors(appearance, theme).dark};
  box-shadow: ${({ theme }) => theme.boxShadow.bs4};
  width: ${FLOATING_DETAIL_WIDTH}px;
  position: relative;
`;

const Header = styled.div<{ readonly appearance: TextInputAppearance }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.size.x5};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.x1};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.x1};
  color: ${({ theme, appearance }) =>
    getAppearanceColors(appearance, theme).background};
  background-color: ${({ theme, appearance }) =>
    getAppearanceColors(appearance, theme).base};
  padding: 0 ${({ theme }) => theme.size.x5};
`;

const EditButtonWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.size.x1};
  top: ${({ theme }) => theme.size.x1};
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.size.x2} ${({ theme }) => theme.size.x1};
  color: ${({ theme }) => theme.color.white};
  display: grid;
  grid-template-columns: ${({ theme }) => theme.size.x2} 1fr;
  gap: ${({ theme }) => theme.size.x05};
  align-content: center;
`;

const Arrow = styled.svg<{
  readonly appearance: TextInputAppearance;
  readonly placement: Placement;
}>`
  position: absolute;
  top: ${({ placement, theme }) =>
    placement.endsWith('end')
      ? 'unset'
      : `calc((${theme.size.x5} - ${theme.size.x15}) / 2)`};
  bottom: ${({ placement, theme }) =>
    placement.endsWith('end')
      ? `calc((${theme.size.x5} - ${theme.size.x15}) / 2)`
      : 'unset'};
  left: ${({ placement }) => (placement.startsWith('right') ? `0` : 'unset')};
  right: ${({ placement }) => (placement.startsWith('right') ? 'unset' : `0`)};
  transform: translateX(${({ placement }) =>
    placement.startsWith('right') ? '-100%' : '100%'}) rotate(${({
    placement,
  }) => (placement.startsWith('right') ? '0' : '180deg')});
  transform-origin: center;
  path {
    fill: ${({ theme, appearance, placement }) => {
      const appearanceColors = getAppearanceColors(appearance, theme);
      return placement.endsWith('end')
        ? appearanceColors.dark
        : appearanceColors.base;
    }}
`;

interface TrainingEventFloatingDetailProps {
  readonly appearance: TextInputAppearance;
  readonly name: string;
  readonly onEditClick: () => void;
  readonly date: string;
  readonly location?: string;
  readonly attendees?: string;
  readonly attributes?: string;
  readonly placement: Placement;
}
export function TrainingEventFloatingDetail({
  name,
  onEditClick,
  appearance,
  date,
  location,
  attendees,
  attributes,
  placement,
}: TrainingEventFloatingDetailProps) {
  return (
    <TrainingEventFloatingDetailLayout appearance={appearance}>
      <Arrow
        appearance={appearance}
        placement={placement}
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="12"
        viewBox="0 0 9 12"
        fill="none"
      >
        <path d="M9 12L9 0L1.24808 5.16795C0.654345 5.56377 0.654345 6.43623 1.24808 6.83205L9 12Z" />
      </Arrow>
      <Header appearance={appearance}>
        <Text
          appearance="task13strong"
          align="center"
          as="div"
          inheritColor
          ellipsis
          whiteSpace="noWrap"
        >
          {name}
        </Text>
        <EditButtonWrapper>
          <Button
            $appearance={ButtonAppearance.Primary}
            small
            $square
            onClick={onEditClick}
            type="button"
            $appearanceStyle={appearance}
          >
            <Icon size={IconSize.s24}>
              <Edit />
            </Icon>
          </Button>
        </EditButtonWrapper>
      </Header>
      <Content>
        <Icon size={IconSize.s16}>
          <Cal />
        </Icon>
        <Text appearance="text12" inheritColor capitalize>
          {date}
        </Text>
        {location && (
          <>
            <Icon size={IconSize.s16}>
              <Location />
            </Icon>
            <Text appearance="text12" inheritColor>
              {location}
            </Text>
          </>
        )}
        {attendees && (
          <>
            <Icon size={IconSize.s16}>
              <Team />
            </Icon>
            <Text appearance="text12" inheritColor>
              {attendees}
            </Text>
          </>
        )}
        {attributes && (
          <>
            <Icon size={IconSize.s16}>
              <Tag />
            </Icon>
            <Text appearance="text12" inheritColor>
              {attributes}
            </Text>
          </>
        )}
      </Content>
    </TrainingEventFloatingDetailLayout>
  );
}
