import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';
import {
  PlannerAttributeDefinition,
  PlannerEventSet,
  PlannerEventType,
} from '../types';

interface PlannerDefinitionResponse {
  attributes: PlannerAttributeDefinition[];
  eventTypes: PlannerEventType[];
  eventSets: PlannerEventSet[];
}
export function getDefinition(
  cancelToken?: CancelTokenSource
): AxiosPromise<PlannerDefinitionResponse> {
  return axios.get(`${coreApiTarget}/api/planner/def`, {
    cancelToken: cancelToken?.token,
    params: {
      instanceCode: devMode ? instance : undefined,
    },
  });
}
