import { ROUTE_DATE_FORMAT, WEEK_SEARCH_PARAM } from '@yarmill/const';
import { SeasonStore } from '@yarmill/types';
import {
  generateUrl,
  sortSeasonStores,
  useCurrentSeasonByDay,
  useCurrentWeek,
  useHistory,
  useSeasonsStore,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useCallback } from 'react';
import styled from 'styled-components';
import { SelectValueProps } from './helpers';
import { SelectBox } from './select-box';

const StyleSeasonSelector = styled.div`
  border-radius: 3px;
  width: 120px;
  cursor: pointer;
`;

function getSeasonLink(season: SeasonStore, currentWeek: string): string {
  let week = `${season.startYear}${currentWeek.slice(4)}`;
  const newWeek = moment(week);
  const seasonStart = moment(season.startDate);
  const seasonEnd = moment(season.endDate);

  // if we are out of season, use start date of season
  if (newWeek.diff(seasonStart) <= 0 || newWeek.diff(seasonEnd) >= 0) {
    week = seasonStart.format(ROUTE_DATE_FORMAT);
  }

  return week;
}

export const SeasonSelector = observer(function SeasonSelector(): JSX.Element {
  const currentWeek = useCurrentWeek();
  const seasonsStore = useSeasonsStore();
  const seasons = seasonsStore.seasons;
  const currentSeason = useCurrentSeasonByDay(currentWeek);
  const history = useHistory();
  const currentSeasonId = currentSeason?.id;

  const setSeason = useCallback(
    (selectedValue: SelectValueProps): void => {
      const season = seasonsStore.getSeasonById(Number(selectedValue.value));
      if (!season) {
        return;
      }
      const newWeek = getSeasonLink(season, currentWeek);
      const link = generateUrl({ [WEEK_SEARCH_PARAM]: newWeek });
      history.push(link);
    },
    [seasonsStore, currentWeek, history]
  );

  const items = seasons.sort(sortSeasonStores).map(season => ({
    label: season.label,
    value: season.id,
  }));

  const currentValue = items.find(i => i.value === currentSeasonId);

  return (
    <StyleSeasonSelector data-cy="season-selector">
      <SelectBox
        id="season-selector"
        options={items}
        value={currentValue}
        onChange={setSeason}
        noLabel
        noError
        noSeparator
        noShadow
      />
    </StyleSeasonSelector>
  );
});
