import { UserStore } from '@yarmill/types';
import { getNormalizedString } from './get-normalized-string';

export function filterUserByName(
  user: UserStore,
  filter: string,
  searchByEmail = true
): boolean {
  if (
    !getNormalizedString(user.displayName.toLocaleLowerCase('cs')).includes(
      getNormalizedString((filter as string).toLocaleLowerCase('cs'))
    ) &&
    (!searchByEmail ||
      !getNormalizedString(user.internalUser.Email.toLowerCase()).includes(
        getNormalizedString((filter as string).toLocaleLowerCase())
      ))
  ) {
    return false;
  }
  return true;
}
