import {
  ChartReportDataItem,
  DataDefinitionV2,
  ReportItem,
  VerticalAlignment,
} from '../types';

import { computed, makeObservable } from 'mobx';
import { BaseReportStore } from './base-report-store';
import { formatValueByBusinessFormat } from '../utils';
import { TextAlignment, TextSize } from '@yarmill/components';

export class RichtextReportStore extends BaseReportStore {
  constructor(item: ReportItem) {
    super(item);
    makeObservable(this);
  }

  @computed
  private get allColumnsByName(): Record<string, DataDefinitionV2> {
    return Object.fromEntries(
      this._item.DataDefinition.map(def => [def.Name, def])
    );
  }

  private get translateStringKey(): string | undefined {
    return this._item.DataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsTranslateStringKey
    )?.Name;
  }

  private get colorKey(): string | undefined {
    return this._item.DataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsColor
    )?.Name;
  }

  private get backgroundColorKey(): string | undefined {
    return this._item.DataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsBackgroundColor
    )?.Name;
  }

  private get textSizeKey(): string | undefined {
    return this._item.DataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsTextSize
    )?.Name;
  }

  public getTranslateStringId(data?: ChartReportDataItem): string {
    const dataKey = this.translateStringKey;

    const value =
      dataKey && data?.[dataKey] ? String(data[dataKey]) : undefined;
    return value ?? (this._item.Params?.Value || 'Missing Value Param');
  }

  public getBackgroundColor(data?: ChartReportDataItem): string | undefined {
    const dataKey = this.backgroundColorKey;

    const value =
      dataKey && data?.[dataKey] ? String(data[dataKey]) : undefined;
    return value ?? this._item.Params?.BackgroundColor;
  }

  public getTextSize(data?: ChartReportDataItem): TextSize | undefined {
    const dataKey = this.textSizeKey;

    const value =
      dataKey && data?.[dataKey] ? (data[dataKey] as TextSize) : undefined;
    return value ?? this._item.Params?.TextSize;
  }

  public getColor(data?: ChartReportDataItem): string | undefined {
    const dataKey = this.colorKey;

    const value =
      dataKey && data?.[dataKey] ? String(data[dataKey]) : undefined;
    return value ?? this._item.Params?.Color;
  }

  @computed
  public get textAlignment(): TextAlignment | undefined {
    return this._item.Params?.TextAlignment ?? undefined;
  }

  @computed
  public get verticalAlignment(): VerticalAlignment | undefined {
    return this._item.Params?.VerticalAlignment;
  }

  public formatItemByDataTypes(
    item: ChartReportDataItem
  ): Record<string, string> {
    const entries = Object.entries(item).map(([key, value]) => {
      const def = this.allColumnsByName[key];

      return [
        key,
        def
          ? formatValueByBusinessFormat(value, def.BusinessFormat, def.Format, {
              forceString: true,
            })
          : value,
      ];
    });

    return Object.fromEntries(entries);
  }

  @computed
  public get emptyValues(): Record<string, string> {
    return Object.fromEntries(
      this._item.DataDefinition.map(def => [def.Name, ''])
    );
  }

  @computed
  public get isHighlighted(): boolean {
    return this._item.Params?.IsHighlighted ?? true;
  }
}
