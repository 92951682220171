import {
  ActivityItem,
  AggregationFunction,
  DisabledReason,
  RootStore,
} from '@yarmill/types';
import { formatValueByUnit } from '@yarmill/utils';
import { computed, makeObservable } from 'mobx';
import { FormEvent } from 'react';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { computeAggregationFunction } from '../utils';
import { AbstractActivityStore } from './abstract-activity-store';
import { ActivityListStore } from './activity-list-store';
import { TimeActivityStore } from './time-activity-store';

export class ComputedActivityStore extends AbstractActivityStore {
  private readonly activityListStore: ActivityListStore;
  private readonly aggregationFunction: AggregationFunction | null;
  public readonly childrenActivities: AbstractActivityStore[] = [];
  private readonly editableActivityStore: AbstractActivityStore;

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    index: number,
    activityItem: ActivityItem,
    aggregationFunction: AggregationFunction | null,
    activityListStore: ActivityListStore,
    editableActivityStore: AbstractActivityStore
  ) {
    super(rootStore, diaryStore, index, activityItem);
    makeObservable(this);
    this.activityListStore = activityListStore;
    this.aggregationFunction = aggregationFunction;
    this.editableActivityStore = editableActivityStore;
  }

  public get disabledReason(): DisabledReason | null {
    if (!this.isEditable) {
      return 'computed-value';
    }

    return this.editableActivityStore.disabledReason;
  }

  public get summaryValue(): number {
    if (this.isEditable) {
      return this.editableActivityStore.summaryValue;
    }

    if (this.apiValue) {
      // If value comes from API we do not want to compute it manually
      return this.apiValue;
    }

    const value = this.childrenActivities?.map(
      activity => activity.summaryValue
    );
    return computeAggregationFunction(this.aggregationFunction, value);
  }

  public get formattedValue(): string | number {
    if (this.isEditable) {
      return this.editableActivityStore.formattedValue;
    }

    return formatValueByUnit(this.summaryValue, this.activityItem.Unit);
  }

  protected get isEditable(): boolean {
    const isEditable = this.isEditableInternal;

    if (isEditable) {
      return !this.childrenActivities.some(activity => activity.hasValue);
    }

    return false;
  }

  @computed
  public get showTimeActivityStore(): boolean {
    return (
      this.editableActivityStore instanceof TimeActivityStore && this.isEditable
    );
  }

  public get hasValue(): boolean {
    return Boolean(
      this.apiValue ||
        this.childrenActivities.some(activity => activity.hasValue) ||
        (this.isEditable && this.editableActivityStore.hasValue)
    );
  }

  public setApiValue(newValue: number) {
    this.apiValue = newValue;
    super.setApiValue(newValue);
    this.editableActivityStore.setApiValue(newValue);
    this.childrenActivities.forEach(activity => activity.disableByParent());
  }

  public disableByParent() {
    super.disableByParent();
    this.editableActivityStore.disableByParent();
    this.disableChildActivities();
  }

  public enableByParent() {
    super.enableByParent();
    this.editableActivityStore.enableByParent();
    this.enableChildActivities();
  }

  public clear(): void {
    super.clear();
    this.apiValue = null;
    this.editableActivityStore.clear();
    this.enableChildActivities();
  }

  public readonly onChange = (e: FormEvent<HTMLInputElement>): void => {
    if (this.isEditable) {
      this.editableActivityStore.onChange(e);

      if (this.formattedValue) {
        this.disableChildActivities();
      } else {
        this.enableChildActivities();
      }
    }
  };

  public readonly onTimeInputChange = (value: string): void => {
    if (
      this.isEditable &&
      this.editableActivityStore instanceof TimeActivityStore
    ) {
      this.editableActivityStore.onTimeInputChange(value);
      if (this.formattedValue) {
        this.disableChildActivities();
      } else {
        this.enableChildActivities();
      }
    }
  };

  public onFocus() {
    if (this.isEditable) {
      this.editableActivityStore.onFocus();
    } else {
      super.onFocus();
    }
  }

  public onBlur() {
    if (this.isEditable) {
      this.editableActivityStore.onBlur();
      if (this.formattedValue) {
        this.disableChildActivities();
      } else {
        this.clear();
      }
    } else {
      super.onBlur();
    }
  }

  public initChildrenActivities(): void {
    this.activityItem.ChildrenActivityItemIds.forEach(activityItemId => {
      const store = this.activityListStore.getActivityStore(
        activityItemId,
        this.index
      );
      if (store) {
        this.childrenActivities.push(store);
      }
    });
  }

  private disableChildActivities(): void {
    this.childrenActivities.forEach(activity => activity.disableByParent());
  }

  private enableChildActivities(): void {
    this.childrenActivities.forEach(activity => activity.enableByParent());
  }
}
