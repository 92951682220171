import { useRef, useContext } from 'react';
import styled from 'styled-components';

import { useDay } from '@datepicker-react/hooks';
import { Text } from '../text';
import { DatepickerContext, SelectRange } from './index';
import { css, useTheme } from '@yarmill/components';

interface DayProps {
  readonly dayLabel: string;
  readonly date: Date;
  readonly selectMode: SelectRange;
  readonly rangeStart?: Date;
  readonly rangeEnd?: Date;
}

interface ColorProp {
  selectedStartEndColor: string;
  selectedColor: string;
  withinHoverRange: string;
  disableColor: string;
  normalColor: string;
}

interface StyledDayComponentProps {
  color?: string;
  backgroundColor?: string;
  selectMode?: SelectRange;
  isSelectedStart: boolean;
  isSelectedEnd: boolean;
}

const StyledDayComponent = styled.div<StyledDayComponentProps>`
  outline: none;
  padding: ${({ theme }) => theme.size.x1};
  width: 100%;
  height: 100%;
  color: ${props => props.color};
  background: ${({ backgroundColor, selectMode }) =>
    selectMode === 'day' ? 'transparent' : backgroundColor};
  border-color: ${({ backgroundColor, selectMode }) =>
    selectMode === 'day' ? backgroundColor : 'none'};
  border-width: ${({ selectMode, theme }) =>
    selectMode === 'day' ? theme.size.x025 : '0'};

  border-style: solid;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ selectMode, isSelectedStart, isSelectedEnd, theme }) =>
    selectMode === 'day'
      ? css`
          border-radius: ${theme.borderRadius.x05};
        `
      : css`
          ${
            isSelectedStart &&
            css`
            border-top-left-radius: ${theme.borderRadius.x05};
            border-bottom-left-radius: ${theme.borderRadius.x05};
          `
          }
          ${
            isSelectedEnd &&
            css`
            border-top-right-radius: ${theme.borderRadius.x05};
            border-bottom-right-radius: ${theme.borderRadius.x05};
          `
          }
        `}

  &.disabled {
    border-width: 0;
    cursor: not-allowed;
  }
`;

export function Day(props: DayProps): JSX.Element {
  const { dayLabel, date, selectMode, rangeStart, rangeEnd } = props;
  const dayRef = useRef(null);
  const theme = useTheme();
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DatepickerContext);

  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,

    dayRef,
  });

  if (!dayLabel) {
    return <div />;
  }

  const getColor = (color: ColorProp) => {
    if (isSelectedStartOrEnd) {
      return color.selectedStartEndColor;
    }
    if (isSelected) {
      return color.selectedColor;
    }
    if (isWithinHoverRange) {
      return color.withinHoverRange;
    }
    if (disabledDate) {
      return color.disableColor;
    }

    return color.normalColor;
  };

  const classNames = [
    isSelectedStartOrEnd && 'selected',
    disabledDate && 'disabled',
  ].filter(Boolean);
  const isSelectedStart =
    selectMode === 'range' && rangeStart?.toISOString() === date.toISOString();
  const isSelectedEnd =
    selectMode === 'range' && rangeEnd?.toISOString() === date.toISOString();

  return (
    <StyledDayComponent
      data-cy="datepicker-day"
      onClick={onClick}
      tabIndex={tabIndex}
      ref={dayRef}
      selectMode={selectMode}
      className={classNames.length ? classNames.join(' ') : undefined}
      isSelectedStart={isSelectedStart}
      isSelectedEnd={isSelectedEnd}
      color={getColor({
        selectedStartEndColor: '#fff',
        selectedColor: '#fff',
        withinHoverRange: '#fff',
        disableColor: '#cacccd',
        normalColor: '#fff',
      })}
      backgroundColor={getColor({
        selectedStartEndColor: theme.color.tangerineDark,
        selectedColor: theme.color.tangerineDark,
        withinHoverRange: theme.color.tangerine_24,
        disableColor: '#eee',
        normalColor: 'transparent',
      })}
    >
      <Text appearance="task13" inheritColor bold>
        {parseInt(dayLabel, 10)}
      </Text>
    </StyledDayComponent>
  );
}
