import { Group } from '@visx/group';
import { ChartConfig, DataLabelDefinition } from './types';
import { useXYChartContext } from './xy-chart-context';
import { getLineLabel } from './utils/get-line-label';
import { getBarLabel } from './utils/get-bar-label';
import { LABEL_FONT_SIZE } from '../reporting/const';
import { DataItem } from '../reporting/types';
import { getRotatedHeight } from '../reporting/utils/get-rotated-height';
import { getSSRStringWidth } from '../reporting/utils/get-ssr-string-width';
import { getRotatedWidth } from '../reporting/utils/get-rotated-width';
import { getBarGroupLabel } from './utils/get-bar-group-label';
import { useMemo } from 'react';
import { isValidRange } from './utils/is-valid-range';
import { getFloatingBarLabel } from './utils/get-floating-bar-label';
import { getHorizontalBarGroupLabel } from './utils/get-horizontal-bar-group-label';
import { DataLabel } from './elements/data-label';

interface DataLabelsProps {
  readonly data: DataItem[];
  readonly configs: ChartConfig[];
}

export function DataLabels({ data, configs }: DataLabelsProps) {
  const { xScale, yScale } = useXYChartContext();

  const xRange = xScale.range();
  const yRange = yScale.range();
  const isHorizontal = 'bandwidth' in yScale;

  const labels = useMemo(
    () =>
      isValidRange(xRange) && isValidRange(yRange)
        ? data.flatMap(d =>
            configs
              .flatMap((chartConfig, idx) => {
                switch (chartConfig.type) {
                  case 'MultiLine':
                  case 'Line':
                    return getLineLabel(d, xScale, yScale, chartConfig, idx);
                  case 'Bar':
                    return getBarLabel(d, xScale, yScale, chartConfig, idx);
                  case 'FloatingBar':
                    return getFloatingBarLabel(
                      d,
                      xScale,
                      yScale,
                      chartConfig,
                      idx
                    );
                  case 'BarGroup':
                    return getBarGroupLabel(
                      d,
                      xScale,
                      yScale,
                      chartConfig,
                      idx
                    );
                  case 'HorizontalBarGroup':
                    return getHorizontalBarGroupLabel(
                      d,
                      xScale,
                      yScale,
                      chartConfig,
                      idx
                    );
                  default:
                    return undefined;
                }
              })
              .filter<DataLabelDefinition>(
                (label): label is DataLabelDefinition =>
                  Boolean(
                    label &&
                      label.originalValue &&
                      (!label.maxHeight || label.maxHeight >= 8) &&
                      (isHorizontal
                        ? label.originalValue >= xScale.domain()[0] &&
                          label.originalValue <= xScale.domain()[1]
                        : label.originalValue >= yScale.domain()[0] &&
                          label.originalValue <= yScale.domain()[1])
                  )
              )
              .map(label => {
                const textWidth = getSSRStringWidth(String(label?.text));
                const width = getRotatedWidth(
                  textWidth,
                  LABEL_FONT_SIZE,
                  label?.angle ?? 0
                );

                const height = getRotatedHeight(
                  textWidth,
                  LABEL_FONT_SIZE,
                  label?.angle ?? 0
                );

                const top = Number(label?.x) - height / 2;
                const bottom = Number(label?.x) + height / 2;
                const left = Number(label?.y) - width / 2;
                const right = Number(label?.y) + width / 2;

                return {
                  ...label,
                  textWidth,
                  width,
                  height,
                  top,
                  bottom,
                  left,
                  right,
                };
              })
          )
        : [],
    [xRange, yRange, data, configs, xScale, yScale, isHorizontal]
  );

  return (
    <Group>
      {labels.map((label, idx) => (
        <DataLabel label={label} key={idx} />
      ))}
    </Group>
  );
}
