import styled from 'styled-components';

const Svg = styled.svg`
  align-self: flex-end;
  margin-bottom: 8px;
  @media (min-width: 768px) {
    display: none;
  }
`;
export function AthletesSidebarMobileArrow(): JSX.Element {
  return (
    <Svg width="25px" height="10px" viewBox="0 0 25 10">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-101.000000, -101.000000)"
          fill="#D4D6D9"
          fillRule="nonzero"
        >
          <g transform="translate(35.000000, 97.000000)">
            <g transform="translate(66.000000, 8.000000)">
              <path
                id="Line-12"
                d="M25,1.25 L16,-3.25 L16,5.75 L25,1.25 Z M0.5,1.75 L16.5,1.75 L17,1.75 L17,0.75 L16.5,0.75 L0.5,0.75 L1.11022302e-16,0.75 L1.11022302e-16,1.75 L0.5,1.75 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  );
}
