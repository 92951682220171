import { styled } from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { GoalEditor } from './goal-editor';
import { GoalAttributeStore } from './mobx/goal-attribute-store';
import { SeasonGoalLabel } from './season-goal-label';

export interface SeasonGoalRowProps {
  attribute: GoalAttributeStore;
  cycleIndex: number;
  richtextBounds: string;
  week?: boolean;
  cycle?: boolean;
  highlighted?: boolean;
}

const StyledGoalRow = styled.div`
  display: grid;
  grid-template-columns: 46px auto;
  column-gap: 10px;
  position: relative;

  &:focus-within {
    z-index: 1;
  }
`;

export function getGoalRowId(
  cycleIndex: number,
  week?: boolean,
  cycle?: boolean
) {
  const type = cycle ? 'cycle' : week ? 'week' : 'day';

  return `${type}-${cycleIndex}`;
}

export const SeasonGoalRow = observer(function SeasonGoalRow(
  props: SeasonGoalRowProps
): JSX.Element {
  const { attribute, week, cycle, cycleIndex, highlighted } = props;

  return (
    <StyledGoalRow id={getGoalRowId(cycleIndex, week, cycle)}>
      <SeasonGoalLabel
        attribute={attribute}
        week={week}
        cycle={cycle}
        cycleNumber={cycleIndex + 1}
        highlighted={highlighted}
      />
      <GoalEditor attribute={attribute} />
    </StyledGoalRow>
  );
});
