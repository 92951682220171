import { ActivityInput, Tippy } from '@yarmill/components';
import { ActivityItem, AsyncStatus } from '@yarmill/types';
import { observer } from 'mobx-react-lite';
import { useDiaryStore } from '../diary/diary-store-context';
import { useFormattedValue } from './hooks';
import { getDisabledReasonMessage } from './utils';

export interface ActivitySummaryProps {
  activityItem: ActivityItem;
}

function InternalActivitySummary(props: ActivitySummaryProps): JSX.Element {
  const { activityItem } = props;
  const diaryStore = useDiaryStore();
  const summaryStore =
    diaryStore.activityListStore?.getActivityItemSummaryStore(
      activityItem.ActivityItemId
    );

  const formattedValue = useFormattedValue(
    summaryStore?.currentValue || 0,
    activityItem.Unit
  );

  const renderedValue =
    diaryStore.status === AsyncStatus.resolved ? formattedValue : '';

  return (
    <Tippy tooltipContent={getDisabledReasonMessage('computed-value')}>
      <ActivityInput
        value={renderedValue}
        disabled
        data-cy={activityItem.Name}
      />
    </Tippy>
  );
}

export const ActivitySummary = observer(InternalActivitySummary);
