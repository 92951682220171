import { Icon, IconSize, styled } from '@yarmill/components';
import { TextInputAppearance } from './text-input';
import { Text } from './text';
import { Button, ButtonAppearance } from './button';
import { Cancel } from '@yarmill/icons-2';

export const StyledTag = styled.span<{
  readonly appearance: TextInputAppearance;
  readonly disabled?: boolean;
  readonly small?: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: ${({ theme }) => theme.borderRadius.x05};
  color: ${({ theme }) => theme.color.orangine};
  background: ${({ theme }) => theme.color.sand_40};
  white-space: nowrap;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.x1};
  padding: ${({ theme, small }) => (small ? theme.size.x05 : theme.size.x075)}
    ${({ theme, small }) => (small ? theme.size.x05 : theme.size.x15)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

interface TagProps<V extends string | number> {
  readonly icon?: JSX.Element;
  readonly appearance: TextInputAppearance;
  readonly value: V;
  readonly label: string | number;
  readonly disabled?: boolean;
  readonly onRemoveClick?: (value: V) => void;
  readonly small?: boolean;
}
export function Tag<V extends string | number>({
  icon,
  appearance,
  disabled,
  label,
  value,
  small,
  onRemoveClick,
}: TagProps<V>) {
  return (
    <StyledTag appearance={appearance} disabled={disabled} small={small}>
      {icon && <Icon size={IconSize.s16}>{icon}</Icon>}
      <Text inheritColor upperCase appearance="label10strong" ellipsis>
        {label}
      </Text>
      {onRemoveClick && (
        <Button
          $appearance={ButtonAppearance.None}
          $appearanceStyle="orangine"
          type="button"
          small
          $square
          $iconOnly
          onClick={disabled ? undefined : () => onRemoveClick(value)}
        >
          <Icon>
            <Cancel />
          </Icon>
        </Button>
      )}
    </StyledTag>
  );
}
