import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

import { PatternInputTime, TIME_PATTERN } from '@yarmill/components';
import { useErrorMessage } from '../hooks/use-error-message';
import { useInvalidFieldFocusHandler } from '../hooks/use-invalid-field-focus-handler';
import { getFormFieldAttributes } from '../utils';
import { FormFieldProps } from './form-field';

export const Time = observer(function Time(props: FormFieldProps): JSX.Element {
  const { attributeStore, autoFocus } = props;
  const intl = useIntl();
  const elementRef =
    useInvalidFieldFocusHandler<HTMLInputElement>(attributeStore);
  const commonAttributes = getFormFieldAttributes(attributeStore, intl);
  const error = useErrorMessage(attributeStore);
  const pattern = attributeStore.definition.Format || TIME_PATTERN;

  const isCondensedObject =
    attributeStore.objectDataStore.objectStore.definition.Features?.Condensed;

  return (
    <PatternInputTime
      {...commonAttributes}
      autoFocus={autoFocus}
      id={String(attributeStore.id)}
      onChange={attributeStore.onChange}
      onBlur={attributeStore.onBlur}
      onFocus={attributeStore.onFocus}
      error={error}
      value={attributeStore.formValue}
      pattern={pattern}
      ref={elementRef}
      noLabel={isCondensedObject && !commonAttributes.label}
      noError={isCondensedObject && !error}
    />
  );
});
