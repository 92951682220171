import { RefObject, useCallback, useState } from 'react';
import * as React from 'react';
import { lock, unlock } from 'tua-body-scroll-lock';

export function useForceUpdate(): () => void {
  const [, setValue] = useState(0);
  return useCallback(() => setValue(value => value + 1), []);
}

export function useBodyScrollLock(target: RefObject<HTMLDivElement>): void {
  React.useEffect(() => {
    const ref = target.current;

    if (ref) {
      lock(ref);
    }
    return () => {
      if (ref) {
        unlock(ref);
      }
    };
  }, [target]);
}
