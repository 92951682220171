import { Export } from '@yarmill/components';
import {
  ExportItem,
  ExportReportingPageToPdfRequestParams,
} from '@yarmill/types';
import { useLocale, useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useReportingStore } from './hooks/use-reporting-store';
import { formatFileName } from './utils/format-file-name';

export const ExportReportingPageButton = observer(
  function ExportReportingPage(): JSX.Element | null {
    const exportService = useRootStore().exportService;
    const reportingStore = useReportingStore();
    const language = useLocale();
    const intl = useIntl();
    const reportingPage = reportingStore.currentPage;
    const customFileName = reportingPage?.page.PrintConfig?.FileName;

    const fileName = reportingPage?.title
      ? `${intl.formatMessage({
          id: reportingPage?.title,
        })}`
      : 'report';

    const exportToPdf = useCallback(async () => {
      const reportPageCode = reportingStore.reportingPageCode;
      if (!reportPageCode) return;
      const params: ExportReportingPageToPdfRequestParams = {
        language,
        reportPageCode,
        filters: reportingStore.dataStore?.apiFilters || {},
      };

      const pdfFileName = customFileName
        ? formatFileName(customFileName, reportingStore, intl)
        : fileName;

      return exportService.exportReportingPageToPdf(params, pdfFileName);
    }, [
      reportingStore,
      language,
      customFileName,
      intl,
      fileName,
      exportService,
    ]);

    const exportItems: ExportItem[] = useMemo(
      () => [{ format: 'pdf', doExport: exportToPdf }],
      [exportToPdf]
    );

    return reportingPage ? <Export exportItems={exportItems} /> : null;
  }
);
