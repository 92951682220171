import { useCallback, useEffect } from 'react';

export function useBrowserWindowHeight() {
  const isClient = typeof window === 'object';

  const getWindowHeight = useCallback(() => {
    return isClient ? window.innerHeight : undefined;
  }, [isClient]);

  useEffect(() => {
    if (!isClient) {
      return undefined;
    }

    function handleResize() {
      const prevHeight = getComputedStyle(
        document.documentElement
      )?.getPropertyValue('--100vh');
      const newHeight = `${getWindowHeight()}px`;

      if (prevHeight !== newHeight) {
        document.documentElement.style.setProperty('--100vh', newHeight);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getWindowHeight, isClient]);
}
