import styled from 'styled-components';
import { OVERVIEW_TABLE_CELL_SIZE, OverviewTableDataFormatter } from './utils';

export interface CategoryProps {
  row: number;
  columnStart: number;
  columnEnd: number;
  category: string;
  formatter?: OverviewTableDataFormatter<string>;
  hasRowLabel?: boolean;
}

const StyledCategory = styled.div<CategoryProps>`
  border-radius: ${Math.floor(OVERVIEW_TABLE_CELL_SIZE / 2)}px;
  background-color: ${({ formatter, category }) =>
    formatter ? formatter(category) : category};
  grid-row: ${({ row }) => `${row + 1} / ${row + 2}`};
  grid-column: ${({ columnStart, columnEnd, hasRowLabel }) =>
    `${columnStart + (hasRowLabel ? 1 : 0)} / ${
      columnEnd + (hasRowLabel ? 2 : 1)
    }`};
  page-break-inside: avoid;
`;

export function Category(props: CategoryProps): JSX.Element {
  const { columnStart, columnEnd, row, formatter, category, hasRowLabel } =
    props;

  return (
    <StyledCategory
      columnEnd={columnEnd}
      columnStart={columnStart}
      row={row}
      formatter={formatter}
      category={category}
      hasRowLabel={hasRowLabel}
    />
  );
}
