import { PageMainContent, PageScrollContainer } from '@yarmill/components';
import { RootStore } from '@yarmill/types';
import { useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { ComponentType, Fragment, useRef } from 'react';
import { Page } from '../page/page';
import { AnalyticsRouteParamsContext } from './analytics-route-params-context';
import { GeneralAnalyticsDashboard } from './dashboards/general/dashboard';
import { SlcrAnalyticsDashboards } from './dashboards/slcr';
import { SpsSlcrAnalyticsDashboards } from './dashboards/sps-slcr';
import { useAnalyticsRouteParamsParser } from './hooks';
import { LegacyAnalyticsStoreContext } from './legacy-analytics-store-context';
import { LegacyAnalyticsStore } from './mobx/legacy-analytics-store';
import { Sidebar } from './sidebar';
import { DashboardType } from './types';

function getDashboardComponent(activeDashboard: DashboardType): ComponentType {
  switch (activeDashboard) {
    case 'general.defaultDashboard':
      return GeneralAnalyticsDashboard;
    case 'slcr.perActivityDashboard':
    case 'slcr.perGroupDashboard':
      return SlcrAnalyticsDashboards;
    case 'sps-slcr.perActivityDashboard':
    case 'sps-slcr.perGroupDashboard':
      return SpsSlcrAnalyticsDashboards;
  }
}

function createLegacyAnalyticsStore(
  rootStore: RootStore
): LegacyAnalyticsStore {
  const modulesRegistry = rootStore.modulesRegistryService;
  const legacyAnalyticsModuleDefinitionStore =
    modulesRegistry.enabledModules.find(
      module => module.moduleCode === 'reporting'
    );
  const legacyAnalyticsStore =
    legacyAnalyticsModuleDefinitionStore &&
    'legacyAnalyticsStore' in legacyAnalyticsModuleDefinitionStore
      ? (legacyAnalyticsModuleDefinitionStore.legacyAnalyticsStore as LegacyAnalyticsStore)
      : undefined;

  return legacyAnalyticsStore ?? new LegacyAnalyticsStore(rootStore);
}

export const Analytics = observer(function Analytics(): JSX.Element {
  const rootStore = useRootStore();
  const store = useRef(createLegacyAnalyticsStore(rootStore));
  const routeParams = useAnalyticsRouteParamsParser();
  const DashboardComponent = getDashboardComponent(routeParams.activeDashboard);

  return (
    <LegacyAnalyticsStoreContext.Provider value={store.current}>
      <Page>
        <AnalyticsRouteParamsContext.Provider value={routeParams}>
          <Sidebar dashboard={routeParams.activeDashboard} />
          <PageMainContent>
            <PageScrollContainer>
              {DashboardComponent ? <DashboardComponent /> : <Fragment />}
            </PageScrollContainer>
          </PageMainContent>
        </AnalyticsRouteParamsContext.Provider>
      </Page>
    </LegacyAnalyticsStoreContext.Provider>
  );
});
