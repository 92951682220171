import { FormState } from '@yarmill/types';
import * as React from 'react';
import { Fragment, PropsWithChildren, ReactElement, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonAppearance } from './button';
import { FormControlVariant } from './helpers';
import { Spinner } from './spinner';

export type AsyncButtonProps = PropsWithChildren<{
  disabled?: boolean;
  loading?: boolean;
  error?: boolean;
  success?: boolean;
  message?: string;
  tabIndex?: number;
  dataTest?: string;
  type?: 'button' | 'submit';
  innerRef?: React.RefObject<HTMLButtonElement>;
  variant?: FormControlVariant;
  onClick?(): void;
}>;

function getSubmitButtonIcon(state: FormState): ReactElement | null {
  switch (state) {
    case 'submitting':
      return (
        <Fragment>
          <Spinner />
          &nbsp;
        </Fragment>
      );

    case 'error':
      return (
        <Fragment>
          <span>×</span>
          &nbsp;
        </Fragment>
      );

    case 'success':
      return (
        <Fragment>
          <span>✓</span>
          &nbsp;
        </Fragment>
      );

    default:
      return null;
  }
}

function getSubmitButtonAppearance(state: FormState): ButtonAppearance {
  switch (state) {
    case 'error':
      return ButtonAppearance.Error;

    case 'success':
      return ButtonAppearance.Success;

    default:
      return ButtonAppearance.Primary;
  }
}

export const AsyncButton = forwardRef<HTMLButtonElement, AsyncButtonProps>(
  function AsyncButton(props, ref): ReactElement {
    const {
      loading,
      error,
      success,
      disabled,
      type = 'button',
      tabIndex,
      dataTest,
      innerRef,
      variant,
    } = props;

    const state: FormState = loading
      ? 'submitting'
      : success
        ? 'success'
        : error
          ? 'error'
          : null;

    return (
      <Button
        ref={ref || innerRef}
        type={type}
        data-cy={dataTest}
        appearance={getSubmitButtonAppearance(state)}
        wide
        disabled={disabled || loading}
        onClick={props.onClick}
        tabIndex={tabIndex}
        variant={variant}
      >
        {getSubmitButtonIcon(state)}
        {props.children || <FormattedMessage id="plan.copy.doCopy" />}
      </Button>
    );
  }
);
