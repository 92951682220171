import { Icon, IconSize } from '@yarmill/components';
import { Button, ButtonAppearance } from '@yarmill/components-2';
import { Plus } from '@yarmill/icons-2';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  EmptyObjectivesButtonsWrapper,
  EmptyObjectivesHeadline,
  EmptyObjectivesIllustration,
  EmptyObjectivesLayout,
  EmptyObjectivesText,
} from './components/empty-state';
import { useOkrsStore } from './okrs-store-context';

export const NoObjectives = observer(function NoObjectives(): JSX.Element {
  const { url } = useRouteMatch();
  const okrsStore = useOkrsStore();
  const isAllowedToAdd = okrsStore.isCurrentUserAllowedToWrite();

  return (
    <EmptyObjectivesLayout>
      <EmptyObjectivesIllustration />

      <EmptyObjectivesHeadline as="div">
        <FormattedMessage id="okrs.emptyState.headline" />
      </EmptyObjectivesHeadline>
      {isAllowedToAdd && (
        <>
          <EmptyObjectivesText as="div">
            <FormattedMessage id="okrs.emptyState.text" />
          </EmptyObjectivesText>

          <EmptyObjectivesButtonsWrapper>
            <Button
              as={Link}
              to={l => ({ ...l, pathname: `${url}/add` })}
              $appearanceStyle="green"
              $appearance={ButtonAppearance.Secondary}
            >
              <Icon size={IconSize.s24}>
                <Plus />
              </Icon>
              <FormattedMessage id={'okrs.addObjective'} />
            </Button>
          </EmptyObjectivesButtonsWrapper>
        </>
      )}
    </EmptyObjectivesLayout>
  );
});
