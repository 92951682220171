import { Permission, PermissionType } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export interface UpdateUserGroupUserPermissionRequestParams {
  userGroupId: number;
  userId: number;
  permission: PermissionType;
}

export function updateUserGroupUserPermission(
  params: UpdateUserGroupUserPermissionRequestParams
): AxiosPromise<Permission[]> {
  return axios.put('api/UserGroupUserPermission', params);
}
