import {
  AsyncStatus,
  RequestStore,
  File as YtdFile,
  FileUploadStore as iFileUploadStore,
} from '@yarmill/types';
import { RootStore } from '@yarmill/types';
import { AxiosProgressEvent, CancelTokenSource } from 'axios';
import { action, computed, makeObservable, observable } from 'mobx';
import { uploadFile } from '../api/upload-file';

export class FileUploadStore implements iFileUploadStore {
  private readonly file: File;

  @observable
  private _uploadedFile: YtdFile | null = null;

  @observable
  private readonly request: RequestStore<YtdFile>;
  @observable
  private uploadProgress: number = 0;

  private _toastId: number | string | null = null;

  @observable
  private _isCanceled: boolean = false;

  constructor(rootStore: RootStore, file: File) {
    makeObservable(this);
    this.file = file;
    const fileUploadUrl = rootStore.configStore.fileUploadUrl;

    const formData = new FormData();
    formData.append('file', file);

    this.request = rootStore.requestsStore.createRequest(
      (cancelToken: CancelTokenSource) =>
        uploadFile(fileUploadUrl, formData, cancelToken, this.setUploadProgress)
    );
  }

  get progress(): number {
    return this.uploadProgress;
  }

  @computed
  get name(): string {
    return this.uploadedFile?.FileName || this.file.name;
  }

  @computed
  get size(): number {
    return this.file.size;
  }

  @computed
  get status(): AsyncStatus {
    return this.request.status;
  }

  get uploadedFile(): YtdFile | null {
    return this._uploadedFile;
  }

  cancelUpload(): void {
    this.request.cancel();
    this._isCanceled = true;
  }

  get isCanceled(): boolean {
    return this._isCanceled;
  }

  get toastId(): number | string | null {
    return this._toastId;
  }

  setToastId(toastId: number | string | null): void {
    this._toastId = toastId;
  }

  @action
  async uploadFile(): Promise<void> {
    const response = await this.request.getResponse();

    if (response) {
      this._uploadedFile = response;
    }
  }

  @action
  private readonly setUploadProgress = (e: AxiosProgressEvent) => {
    this.uploadProgress = e && e.total ? e.loaded / e.total : 0;
  };
}
