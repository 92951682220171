import { LoggerContext } from '@yarmill/contextlib';
import { Logger } from '@yarmill/types';
import { useContext } from 'react';

export function useLogger(): Logger {
  const logger = useContext(LoggerContext);

  if (!logger) {
    throw new Error('logger not available');
  }

  return logger;
}
