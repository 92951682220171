import { useAvatar } from '@yarmill/utils';
import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { ExternalIcon } from './external-icon';
import { Icon, IconSize } from './icon';

export interface AvatarProps {
  readonly active?: boolean;
  readonly id: string | null;
  readonly alt?: string;
  readonly disableFlex?: boolean;
  onClick?(id: string): void;
}

const Img = styled.img`
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
`;

const AvatarWrapper = styled.span<{
  readonly isActive?: boolean;
  readonly disableFlex?: boolean;
}>`
  ${({ disableFlex }) =>
    !disableFlex &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      background: rgb(255, 255, 255);
      border: 1px solid rgb(74, 144, 226) !important;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 18px 6px;
      border-radius: 8px;
      position: relative;
    `}
`;

const ActiveIcon = styled(Icon)`
  position: absolute;
  color: rgb(74, 144, 226);
  bottom: -5px;
  right: -5px;
`;

export function Avatar(props: AvatarProps): JSX.Element {
  const { active, id, onClick, alt, disableFlex } = props;

  const src = useAvatar(id);

  const handleClick = useCallback(() => {
    if (onClick && id) {
      onClick(id);
    }
  }, [id, onClick]);

  return (
    <AvatarWrapper
      isActive={active}
      onClick={handleClick}
      disableFlex={disableFlex}
    >
      <Img src={src} alt={alt || `Avatar ${id}`} />
      {active && (
        <ActiveIcon size={IconSize.s22}>
          <ExternalIcon name="CircleCheckFilled" />
        </ActiveIcon>
      )}
    </AvatarWrapper>
  );
}
