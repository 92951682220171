import { FormattedDate } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { ActivityHeadCellWrapper, Text, TextSize } from '@yarmill/components';

import { useDiaryStore } from './diary-store-context';
import { getDateForDayIndex } from './utils';

export interface HeaderCellProps {
  columnIndex: number;
}

function InternalWeekHeaderCell(props: HeaderCellProps): JSX.Element {
  const { columnIndex } = props;
  const diaryStore = useDiaryStore();
  const date = getDateForDayIndex(diaryStore.week, columnIndex);

  return (
    <ActivityHeadCellWrapper className="activity-column-wrapper">
      <Text bold size={TextSize.s12} className="activity-column-label-text">
        <FormattedDate value={date.toDate()} weekday="short" />
      </Text>
      <br />
      <FormattedDate value={date.toDate()} day="numeric" month="numeric">
        {(msg: string) => (
          <Text size={TextSize.s12} className="activity-column-label-text">
            {msg}
          </Text>
        )}
      </FormattedDate>
    </ActivityHeadCellWrapper>
  );
}

export const WeekHeaderCell = observer(InternalWeekHeaderCell);
