import { ROUTE_DATE_FORMAT, WEEK_SEARCH_PARAM } from '@yarmill/const';
import { generateUrl, useCurrentWeek, useHistory } from '@yarmill/utils';
import moment from 'moment';
import { useCallback } from 'react';
import { DaySelector } from './day-selector';

export function WeekDaySelector(): JSX.Element {
  const week = useCurrentWeek();
  const history = useHistory();

  const setWeek = useCallback(
    (day: moment.Moment): void => {
      const date = day.format(ROUTE_DATE_FORMAT);
      const link = generateUrl({ [WEEK_SEARCH_PARAM]: date });
      history.push(link);
    },
    [history]
  );

  const onPrevClick = useCallback(() => {
    const prevDay = moment(week).subtract(1, 'days');
    setWeek(prevDay);
  }, [week, setWeek]);

  const onNextClick = useCallback(() => {
    const prevDay = moment(week).add(1, 'days');
    setWeek(prevDay);
  }, [week, setWeek]);

  return (
    <DaySelector
      onPrevClick={onPrevClick}
      onNextClick={onNextClick}
      date={week}
    />
  );
}
