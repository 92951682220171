import { User } from '@yarmill/types';
import { getUserName } from '@yarmill/utils';
import { computed } from 'mobx';

export class UserDataSource {
  private readonly _user: User;
  constructor(user: User) {
    this._user = user;
  }

  get avatar(): string | null {
    return this._user.AvatarFileName;
  }

  @computed
  get displayName(): string {
    return getUserName(this._user);
  }
}
