import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from '@yarmill/const';
import {
  AsyncStatus,
  MaskCode,
  RootStore,
  SeasonEvaluationViewType,
  UserGroupId,
  UserId,
} from '@yarmill/types';
import {
  IReactionDisposer,
  action,
  makeObservable,
  observable,
  reaction,
  runInAction,
  when,
} from 'mobx';
import { SeasonOverSeasonEvaluationStore } from './season-over-season-evaluation-store';
import { SingleSeasonEvaluationStore } from './single-season-evaluation-store';

export class SeasonEvaluationStore {
  private readonly rootStore: RootStore;
  @observable
  private _singleSeasonEvaluationStore: SingleSeasonEvaluationStore | null =
    null;
  @observable
  private _seasonOverSeasonEvaluationStore: SeasonOverSeasonEvaluationStore | null =
    null;
  private readonly VIEW_TYPE_PERSIST_KEY = 'yarmill-seasonEvaluation-viewType';

  @observable
  private _viewType: SeasonEvaluationViewType = 'single-season';

  @observable
  private _athleteId: UserId | null = null;

  @observable
  private _groupId: UserGroupId | null = null;

  @observable
  private _maskCode?: MaskCode = undefined;

  private reactions: IReactionDisposer[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.rootStore.activityItemsStore.loadActivityItems();
    this.loadPersistedViewType();
    const currentUser = rootStore.currentUserStore;
    this.registerReactions();

    when(
      () =>
        rootStore.status === AsyncStatus.resolved &&
        this.rootStore.activityItemsStore.status === AsyncStatus.resolved,
      () => {
        this.updateMaskCode();
        this._singleSeasonEvaluationStore =
          this.createSingleSeasonEvaluationStore();
        this._seasonOverSeasonEvaluationStore =
          this.createSeasonOverSeasonEvaluationStore();

        runInAction(() => {
          if (
            !currentUser.isAllowedTo(
              'seasonEvaluation.singleSeasonEvaluation'
            ) &&
            currentUser.isAllowedTo(
              'seasonEvaluation.seasonOverSeasonEvaluation'
            )
          ) {
            this._viewType = 'season-over-season';
          }
        });
      }
    );
  }

  public disposeReactions(): void {
    this.reactions.forEach(dispose => dispose());
    this.singleSeasonEvaluationStore?.disposeReactions();
    this.seasonOverSeasonEvaluationStore?.disposeReactions();
  }

  public get athleteId(): UserId | null {
    return this._athleteId;
  }

  public get groupId(): UserGroupId | null {
    return this._groupId;
  }

  public get viewType(): SeasonEvaluationViewType {
    return this._viewType;
  }

  @action
  public readonly setSingleSeasonViewType = (): void => {
    this._viewType = 'single-season';
  };

  @action
  public readonly setSeasonOverSeasonViewType = (): void => {
    this._viewType = 'season-over-season';
  };

  public get singleSeasonEvaluationStore(): SingleSeasonEvaluationStore | null {
    return this._singleSeasonEvaluationStore;
  }

  public get seasonOverSeasonEvaluationStore(): SeasonOverSeasonEvaluationStore | null {
    return this._seasonOverSeasonEvaluationStore;
  }

  @action
  public readonly setMaskCode = (maskCode: MaskCode): void => {
    this._maskCode = maskCode;
  };

  public get currentMaskCode(): MaskCode | undefined {
    return this._maskCode;
  }

  private readonly updateMaskCode = (): void => {
    const athleteId = this.athleteId;
    const groupId = this.groupId;

    const athlete = athleteId
      ? this.rootStore.usersStore.getUserById(athleteId)
      : null;
    const group = groupId
      ? this.rootStore.groupsStore.getGroupById(groupId)
      : null;

    this._maskCode = athlete?.activityMask || group?.activityMask;
  };

  private registerReactions(): void {
    const observeGroupId = reaction(
      () => this.rootStore.historyService.searchParams.get(GROUP_SEARCH_PARAM),
      id => {
        this._groupId = id !== undefined ? Number(id) : null;
      },
      {
        fireImmediately: true,
      }
    );

    const observerAthleteId = reaction(
      () =>
        this.rootStore.historyService.searchParams.get(ATHLETE_SEARCH_PARAM),
      id => {
        this._athleteId = id !== undefined ? Number(id) : null;
      },
      {
        fireImmediately: true,
      }
    );

    const observeViewType = reaction(
      () => this.viewType,
      () => this.persistViewType()
    );

    const maskCode = reaction(
      () => ({
        athleteId: this.athleteId,
        groupId: this.groupId,
        viewType: this.viewType,
      }),
      () => this.updateMaskCode()
    );

    const observeMaskCode = reaction(
      () => this.currentMaskCode,
      () => {
        this._singleSeasonEvaluationStore =
          this.createSingleSeasonEvaluationStore();
      }
    );

    this.reactions.push(
      observerAthleteId,
      observeGroupId,
      observeViewType,
      maskCode,
      observeMaskCode
    );
  }

  private persistViewType(): void {
    window.localStorage?.setItem(this.VIEW_TYPE_PERSIST_KEY, this.viewType);
  }

  @action
  private loadPersistedViewType(): void {
    const value = window.localStorage?.getItem(this.VIEW_TYPE_PERSIST_KEY);
    if (value && SeasonEvaluationStore.isValidViewType(value)) {
      this._viewType = value;
    }
  }

  private static isValidViewType(x: string): x is SeasonEvaluationViewType {
    return x === 'single-season' || x === 'season-over-season';
  }

  private createSingleSeasonEvaluationStore(): SingleSeasonEvaluationStore {
    if (this.singleSeasonEvaluationStore) {
      this.singleSeasonEvaluationStore.disposeReactions();
    }
    return new SingleSeasonEvaluationStore(
      this.rootStore,
      this,
      this.currentMaskCode
    );
  }

  private createSeasonOverSeasonEvaluationStore(): SeasonOverSeasonEvaluationStore {
    if (this.seasonOverSeasonEvaluationStore) {
      this.seasonOverSeasonEvaluationStore.disposeReactions();
    }
    return new SeasonOverSeasonEvaluationStore(this.rootStore, this);
  }
}
