import styled from 'styled-components';
import { Breakpoints } from '../helpers';
import { Button } from '../button';

export const HRZonesFormElementsWrapper = styled.fieldset`
  border: 0;
  max-width: 500px;
  margin: 0 auto;
`;

export const HRZonesFormContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 3fr 3fr;
  width: calc(100% - 30px);
  gap: 8px;
  align-items: center;

  @media (min-width: ${Breakpoints.tablet}px) {
    width: 100%;
    gap: 20px;
  }
`;

export const HRZonesFormButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: stretch;

  @media (min-width: ${Breakpoints.tablet}px) {
    justify-content: flex-end;
    flex-wrap: nowrap;
  }
`;

export const AddHRZoneButton = styled(Button)`
  display: flex;
  align-items: center;
  padding-left: 0;
  gap: 5px;
  margin-top: 8px;
  color: #4a90e2;

  :hover {
    color: #396fad;
    text-decoration: none;
  }

  @media (min-width: ${Breakpoints.tablet}px) {
    margin-top: 16px;
  }
`;
