import { useScale } from '../hooks/use-scale';
import { getAxisPosition } from '../../reporting/utils/get-axis-position';

export function getBarSize(
  scale: ReturnType<typeof useScale>,
  value: number | null
) {
  if ('bandwidth' in scale) {
    throw new Error('Bars are available only for categorical scale');
  }

  if (value === null) {
    return 0;
  }

  const domain = scale.domain();
  const axisPosition = getAxisPosition(domain as number[]);

  return Math.abs(scale(value) - scale(axisPosition));
}
