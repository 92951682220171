import { HorizontalStackedBarChart } from '../../types';
import { HorizontalStackedBarRectLabel } from './horizontal-stacked-bar-rect-label';
import { ArrayElement, DataItem } from '../../../reporting/types';
import { getBarRadius } from '../../../reporting/utils/get-bar-radius';
import { groupStackedBarsByCategories } from '../../utils/group-stacked-bars-by-categories';

interface HorizontalStackedBarRectProps {
  readonly bar: ArrayElement<
    ArrayElement<ReturnType<typeof groupStackedBarsByCategories>>
  >;
  readonly config: HorizontalStackedBarChart;
  readonly item: DataItem;
}
export function HorizontalStackedBarRect({
  bar,
  config,
  item,
}: HorizontalStackedBarRectProps) {
  const showCategoryGaps = config.showCategoryGaps;
  const barWidth = showCategoryGaps ? Math.max(bar.width - 2, 1) : bar.width;
  const barX = showCategoryGaps ? bar.x + 1 : bar.x;

  if (Number.isNaN(barWidth) || barWidth <= 0) {
    return null;
  }

  return (
    <>
      <rect
        x={barX}
        y={bar.y}
        height={bar.height}
        width={barWidth}
        fill={config.getColor(item, bar.key)}
        opacity={config.getOpacity(item, bar.key)}
        pointerEvents="none"
        rx={showCategoryGaps ? getBarRadius(bar.height) : undefined}
      />
      <HorizontalStackedBarRectLabel
        bar={bar}
        barWidth={barWidth}
        config={config}
        item={item}
        barX={barX}
      />
    </>
  );
}
