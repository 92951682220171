import {
  ExternalIcon,
  HealthDataOverviewRow,
  Tippy,
  styled,
} from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import { BodyBatteryData } from '../../training-day/types';

export interface BodyBatteryProps {
  value: BodyBatteryData;
}

const CurrentValue = styled.span`
  opacity: 0.4;
`;

export function BodyBattery(props: BodyBatteryProps): JSX.Element {
  const { value } = props;
  const validatedValue = {
    Charge: '-',
    Discharge: '-',
    Label: '-',
    MorningMax: '-',
    ...value,
  };

  return (
    <HealthDataOverviewRow
      icon={<ExternalIcon name="BatteryCharging" />}
      label={
        value.Label ? <FormattedMessage id={value.Label} /> : 'Missing Label'
      }
      primaryValue={
        <FormattedMessage
          id="healthData.bodyBattery.value"
          values={{
            ...validatedValue,
            CurrentValue: text => <CurrentValue>{text}</CurrentValue>,
          }}
        />
      }
      secondaryValues={[
        value.Charge && (
          <Tippy tooltipContent="healthData.bodyBattery.plus.tooltip">
            <FormattedMessage
              id="healthData.bodyBattery.plus"
              values={validatedValue}
            />
          </Tippy>
        ),
        value.Discharge && (
          <Tippy tooltipContent="healthData.bodyBattery.minus.tooltip">
            <FormattedMessage
              id="healthData.bodyBattery.minus"
              values={validatedValue}
            />
          </Tippy>
        ),
      ].filter(Boolean)}
    />
  );
}
