import { Icon, IconSize, Tippy } from '@yarmill/components';
import { ButtonAppearance } from '@yarmill/components-2';
import { Back } from '@yarmill/icons-2';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { BackButton } from '../components/detail/objective-detail';

export function BackToOkrsList() {
  const intl = useIntl();

  return (
    <Tippy tooltipContent="okrs.detail.back" noWrapper touch={false}>
      <BackButton
        $appearance={ButtonAppearance.Secondary}
        as={Link}
        to={l => ({ ...l, pathname: `/okr` })}
        aria-label={intl.formatMessage({ id: 'okrs.detail.back' })}
        $appearanceStyle="neutral"
        $iconOnly
      >
        <Icon size={IconSize.s24}>
          <Back />
        </Icon>
      </BackButton>
    </Tippy>
  );
}
