import { ContentBox, Text, TextSize } from '@yarmill/components';
import { useNavbarStore } from '@yarmill/utils';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

export function InvalidUser({
  moduleKey,
}: {
  moduleKey: string | null;
}): JSX.Element {
  const navbarStore = useNavbarStore();

  useEffect(() => {
    navbarStore.clear();
  }, [navbarStore]);

  return (
    <ContentBox>
      <Text size={TextSize.s14}>
        <FormattedMessage id={`evidence.${moduleKey}.invalidUser`} />
      </Text>
    </ContentBox>
  );
}
