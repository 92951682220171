import { ATHLETE_SEARCH_PARAM } from '@yarmill/const';
import { GroupStore } from '@yarmill/types';
import {
  generateUrl,
  persistSelectedAthlete,
  useCommonUrlParams,
  useCurrentUserStore,
  useUsersStore,
} from '@yarmill/utils';
import { Location } from 'history';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ExternalIcon } from '../external-icon';
import { Icon, IconSize } from '../icon';
import { SidebarList } from '../sidebar';
import { AthleteLink } from './athlete-link';
import { AthletesListWrapper } from './components';
import { HistoricalAthletesList } from './historical-athletes-list';
import { ListItem } from './list-item';

export interface AthletesListProps {
  readonly disableWholeGroup?: boolean;
  readonly disableWholeGroupTooltip?: string;
  readonly group: GroupStore;
  readonly isVisible: boolean;
  readonly toggleGroups: () => void;
  readonly includeCoaches?: boolean;
  readonly hideCurrentUser?: boolean;
}

export const ALL_ATHLETES_ICON = () => {
  return (
    <Icon size={IconSize.s16}>
      <ExternalIcon name="Layout2" />
    </Icon>
  );
};

function generateRootPath(location: Location): string | undefined {
  if (location.pathname.includes('okr')) {
    return `${window.location.origin}/okr`;
  }
  return undefined;
}

export const AthletesList = observer(function AthletesList(
  props: AthletesListProps
): JSX.Element | null {
  const {
    group,
    isVisible,
    toggleGroups,
    disableWholeGroup,
    disableWholeGroupTooltip,
    includeCoaches,
    hideCurrentUser,
  } = props;
  const intl = useIntl();
  const usersStore = useUsersStore();
  const currentUser = useCurrentUserStore();
  const athletesInGroup = group.athletes;
  const allUsersInGroup = group.allUsers;
  const { athlete } = useCommonUrlParams();
  const selectedAthlete = usersStore.getUserById(athlete);
  const groupLink = useCallback((location: Location) => {
    const searchParams = new URLSearchParams(location.search);
    const search = Object.fromEntries(searchParams.entries());
    const path = generateRootPath(location);
    return generateUrl(
      {
        ...search,
        [ATHLETE_SEARCH_PARAM]: null,
      },
      path
    );
  }, []);

  const usersToShow = includeCoaches ? allUsersInGroup : athletesInGroup;

  useEffect(() => {
    persistSelectedAthlete(selectedAthlete);
  }, [selectedAthlete]);

  const onListItemClick = useCallback(
    (_name: string): void => {
      toggleGroups();
      if (window.innerWidth < 768) {
        window.scrollTo({ top: 0 });
      }
    },
    [toggleGroups]
  );

  return (
    <AthletesListWrapper isVisible={isVisible}>
      {usersToShow.length > 0 && (
        <SidebarList>
          <ListItem
            disabled={disableWholeGroup}
            disableWholeGroupTooltip={disableWholeGroupTooltip}
            onClick={onListItemClick}
            isActive={!selectedAthlete}
            link={groupLink}
            name={intl.formatMessage({ id: 'sidebar.athletes.allAthletes' })}
            icon={<ALL_ATHLETES_ICON />}
            data-cy={'sidebar-list-item-whole-group'}
          />
          {usersToShow.map(athlete =>
            hideCurrentUser && currentUser.id === athlete.id ? null : (
              <AthleteLink
                key={athlete.id}
                onClick={onListItemClick}
                athlete={athlete}
                isActive={athlete === selectedAthlete}
              />
            )
          )}
        </SidebarList>
      )}
      {(group.historicalAthletes.length > 0 ||
        group.group.HistoricalAthletesCount > 0) && (
        <HistoricalAthletesList
          group={group}
          selectedAthlete={selectedAthlete}
          toggleDropdown={toggleGroups}
        />
      )}
    </AthletesListWrapper>
  );
});
