import { useMemo } from 'react';
import { LABEL_OFFSET } from '../../reporting/const';
import { AxisValue, DataItem, XAxisConfig } from '../../reporting/types';
import { getSSRStringWidth } from '../../reporting/utils/get-ssr-string-width';
import { ChartConfig } from '../types';
import { useScale } from './use-scale';

export function useCalculateRightPadding(
  xScale: ReturnType<typeof useScale>,
  yScale: ReturnType<typeof useScale>,
  xAxisConfig: XAxisConfig<AxisValue> | undefined,
  markerPadding: number,
  chartConfigs: ChartConfig[],
  data: DataItem[]
) {
  return useMemo(() => {
    const isContinuous = 'ticks' in xScale;
    const isHorizontal = 'bandwidth' in yScale;
    let xTickWidth = 0;
    if (xAxisConfig?.showTickLabels && isContinuous) {
      const xTicks = xScale.ticks();
      const lastTick = xTicks[xTicks.length - 1];
      const value = xAxisConfig.formatTick(lastTick);
      xTickWidth = getSSRStringWidth(value) / 2;
    }

    const labelWidth =
      isContinuous && !isHorizontal
        ? Math.max(
            ...chartConfigs.map(config => {
              if (!('getYValue' in config)) {
                return 0;
              }
              const lastItem = data.slice().reverse().find(Boolean);
              if (!lastItem) {
                return 0;
              }
              if (!config.getShowLabels(lastItem)) {
                return 0;
              }
              const value = config.getYValue(lastItem);
              const label =
                value !== null ? config.formatLabelValue(value as number) : '';

              return getSSRStringWidth(label) / 2;
            })
          )
        : isHorizontal
          ? Math.max(
              ...chartConfigs.map(config =>
                Math.max(
                  ...data.map(item => {
                    if (config.type === 'HorizontalBarStack') {
                      return 0;
                    }
                    if (config.type !== 'HorizontalBarGroup') {
                      return 0;
                    }

                    return Math.max(
                      ...config.keys.map(categoryKey => {
                        const xValue = item[categoryKey];

                        if (!xValue || config.labelPosition !== 'outside') {
                          return 0;
                        }

                        const label = config.formatLabelValue(
                          xValue as number,
                          categoryKey
                        );
                        return getSSRStringWidth(label) + LABEL_OFFSET;
                      })
                    );
                  })
                )
              )
            )
          : 0;

    const marker =
      isContinuous || markerPadding > xScale.bandwidth() ? markerPadding : 0;

    return Math.max(xTickWidth, marker, labelWidth);
  }, [chartConfigs, data, markerPadding, xAxisConfig, xScale, yScale]);
}
