import { Logger as iLogger } from '@yarmill/types';
import { RootStore } from '@yarmill/types';
import { isBrowser } from '../utils';
import { HttpLogger } from './http-logger';
import { isLogLevelEnabled } from './is-log-level-enabled';
import { LOG_LEVELS, LogLevel } from './types';

declare global {
  interface Window {
    ytd: {
      log: Record<string, Logger | HttpLogger | undefined>;
      [key: string]: any;
    };
  }
}

export class Logger implements iLogger {
  private readonly localStoragePersistKey = 'yarmill-log-level';
  private readonly localStorageHttpPersistKey = 'yarmill-http-logger';
  private readonly httpLogger?: HttpLogger;
  private httpAllowed: boolean;
  private level: LogLevel = 'warn';
  private readonly scope;

  public get currentLevel(): LogLevel {
    return this.level;
  }

  public set currentLevel(level: LogLevel) {
    this.level = level;
    this.initAndPersist();
  }

  disableHttpLogger(): void {
    this.httpAllowed = false;
  }

  enableHttpLogger(): void {
    this.httpAllowed = true;
  }

  createScopedLogger(scope: string): Logger {
    return new Logger(scope, this.httpLogger);
  }

  public constructor(scope: string = 'global', httpLogger?: HttpLogger) {
    this.httpLogger = httpLogger ?? new HttpLogger();
    this.httpAllowed = true;
    this.scope = scope;
    this.loadAndInitConfigs();
    this.addToGlobalNamespace();
  }

  setRootStore(rootStore: RootStore): void {
    this.httpLogger?.setRootStore(rootStore);
  }

  log(_message?: any, ..._optionalParams: any[]): void {
    console.log(_message, ..._optionalParams);
  }
  debug(message?: any, ...optionalParams: any[]): void {
    if (isLogLevelEnabled('debug', this.level)) {
      console.debug(message, ...optionalParams);
    }
    this.logHttp(message, 'debug', ...optionalParams);
  }
  info(message?: any, ...optionalParams: any[]): void {
    if (isLogLevelEnabled('info', this.level)) {
      console.info(message, ...optionalParams);
    }
    this.logHttp(message, 'info', ...optionalParams);
  }
  warn(message?: any, ...optionalParams: any[]): void {
    if (isLogLevelEnabled('warn', this.level)) {
      console.warn(message, ...optionalParams);
    }
    this.logHttp(message, 'warn', ...optionalParams);
  }
  error(message?: any, ...optionalParams: any[]): void {
    if (isLogLevelEnabled('error', this.level)) {
      console.error(message, ...optionalParams);
    }
    this.logHttp(message, 'error', ...optionalParams);
  }
  trace(message?: any, ...optionalParams: any[]): void {
    if (isLogLevelEnabled('debug', this.level)) {
      console.trace(message, ...optionalParams);
    }
  }

  levelError(): void {
    this.currentLevel = 'error';
  }
  levelWarning(): void {
    this.currentLevel = 'warn';
  }
  levelInfo(): void {
    this.currentLevel = 'info';
  }
  levelDebug(): void {
    this.currentLevel = 'debug';
  }

  loadAndInitConfigs = (): void => {
    if (!isBrowser) {
      return;
    }
    const storageLevel = window?.localStorage?.getItem(this.persistKey);
    if (storageLevel && LOG_LEVELS.indexOf(storageLevel as LogLevel) >= 0) {
      this.level = storageLevel as LogLevel;
    }
    const httpAllowed = window?.localStorage.getItem(
      this.localStorageHttpPersistKey
    );
    if (httpAllowed !== null) {
      this.httpAllowed = httpAllowed !== 'false';
    }
  };

  private get persistKey(): string {
    return `${this.localStoragePersistKey}-${this.scope}`;
  }

  initAndPersist(): void {
    if (isBrowser) {
      window?.localStorage.setItem(this.persistKey, this.level);
    }
  }

  addToGlobalNamespace(): void {
    if (isBrowser) {
      window['ytd']['log'][this.scope] = this;
      window['ytd']['log']['http'] = this.httpLogger;
    }
  }

  private logHttp(
    message: string,
    level: LogLevel,
    ...optionalParams: any[]
  ): void {
    if (this.httpAllowed) {
      this.httpLogger?.sendHttp(message, level, ...optionalParams);
    }
  }
}
