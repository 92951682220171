import styled from 'styled-components';
import { Icon, IconSize } from '../icon';

const StyledSvg = styled.svg`
  width: 18px;
  height: 18px;
`;

const SpinningPath = styled.path`
  transform-origin: center;
  animation: 1s linear 0s infinite normal none spin;

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export interface SpinnerProps {
  size?: IconSize;
}

export const Spinner = (props: SpinnerProps) => (
  <Icon size={props.size || IconSize.s14}>
    <StyledSvg viewBox="0 0 1588 1588">
      <SpinningPath d="M794 359q32 0 54-23t23-54V77q0-33-23-55T793 0t-54 22-22 55v205q1 32 23 54t54 23zm358 153q32 0 55-22l153-154q23-22 23-54t-23-54q-22-22-54-23-32 0-54 23l-154 153q-22 23-22 55t22 54 54 22zm-717 0q32 0 55-22 22-22 22-54t-23-55L336 228q-22-23-54-23t-54 24q-22 25-23 55 0 30 23 52l152 154q23 22 55 22zm871 359h205q33 0 55-23 22-22 22-54t-23-54-54-23h-205q-32 0-54 23t-23 55q0 32 22 54t55 22zM77 871h205q32 0 54-23 22-22 23-54 0-32-23-54t-54-23H77q-33 0-55 23T0 795t22 54 55 22zm1228 512q32 1 55-23t23-54-24-54l-152-154q-23-22-55-22t-55 24q-21 24-21 54t22 53l153 153q22 23 54 23zm-1023 0q32 0 54-23l154-153q22-23 22-55t-22-54-54-22-55 22l-153 154q-23 22-23 54 1 32 23 54t54 23zm512 205q32 0 54-23t23-54v-205q0-32-23-54t-55-23q-32 0-54 22t-22 55v205q1 33 23 55t54 22z" />
    </StyledSvg>
  </Icon>
);
