import styled from 'styled-components';
import { BaseWorkoutData } from '@yarmill/components';

const StyledTooltipRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledValue = styled.span`
  display: inline-block;
  margin: 0 8px;
`;

export function CgmTooltip(item: BaseWorkoutData): JSX.Element {
  return (
    <StyledTooltipRow>
      <StyledValue>
        {Number(item.egv) < 100 && <>&nbsp;</>}
        {Number(item.egv) < 10 && <>&nbsp;</>}
        {item.egv}
      </StyledValue>
      mg/dL
    </StyledTooltipRow>
  );
}
