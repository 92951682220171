import { GlobalIntl } from '@yarmill/components';
import { RootStore } from '@yarmill/types';
import {
  ObservableMap,
  action,
  computed,
  makeObservable,
  observable,
} from 'mobx';
import {
  ReportingTable,
  ReportingTableColumnConfig,
  ReportingTableUnit,
} from '../components/reporting-table/types';
import {
  BaseReportItem,
  ConfigOverride,
  DataDefinitionV2,
  ReportItem,
} from '../types';
import { fillStringTemplate, formatValueByBusinessFormat } from '../utils';
import { formatRowDataByBusinessFormat } from '../utils/format-row-data-by-business-format';
import { BaseReportStore } from './base-report-store';
import { ReportingPageFilterStore } from './reporting-page-filter-store';

export class TableChartStore extends BaseReportStore {
  private readonly _rootStore: RootStore;

  @observable
  private configOverride: ConfigOverride | undefined;

  constructor(item: ReportItem, rootStore: RootStore) {
    super(item);
    this._rootStore = rootStore;
    makeObservable(this);
  }

  public get table(): ReportingTable {
    return {
      configuration: {
        title: this.params?.Title || '',
        hasHeader: this.params?.HasHeader ?? true,
      },
      columnsDefinition: this.columnsDefinition,
    };
  }

  @action
  public setConfigOverride(config: ConfigOverride | undefined) {
    this.configOverride = config;
  }

  @computed
  private get dataDefinition(): DataDefinitionV2[] {
    return this.configOverride?.DataDefinition ?? this._item.DataDefinition;
  }

  @computed
  private get params(): BaseReportItem['Params'] {
    return this.configOverride?.Params ?? this._item.Params;
  }

  @computed
  private get labelMetadataColumn(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(def => def.Purpose === 'ValueForRow');
  }

  @computed
  private get dataColumns(): DataDefinitionV2[] {
    return this.dataDefinition.filter(def => def.Purpose === 'DataValue');
  }

  @computed
  private get columnsDefinition(): ReportingTableColumnConfig[] {
    const labelColumn = this.labelMetadataColumn
      ? [this.mapColumnParams(this.labelMetadataColumn)]
      : [];

    return [
      ...labelColumn,
      ...this.dataColumns.map(def => this.mapColumnParams(def)),
    ].sort((a, b) => a.index - b.index);
  }

  private mapColumnParams(
    definition: DataDefinitionV2
  ): ReportingTableColumnConfig {
    return {
      businessFormat: definition.BusinessFormat ?? null,
      borderLeft: Boolean(definition.BorderLeft),
      borderRight: Boolean(definition.BorderRight),
      color: definition.Color,
      columnName: definition.Name ?? '',
      colSpan: definition.ColSpan,
      dataType: (definition.DataType as ReportingTableUnit) ?? 'String',
      format: definition.Format ?? null,
      groupSameValues: Boolean(definition.GroupSameValues),
      headerAlignment: definition.HeaderAlignment ?? 'left',
      valueAlignment: definition.ValueAlignment ?? 'left',
      valueVerticalAlignment: definition.ValueVerticalAlignment,
      width: definition.Width || '',
      label: definition.Label || null,
      tooltip: definition.TooltipLabel || '',
      translateValue: Boolean(definition.TranslateValue),
      translateHeader: Boolean(definition.TranslateHeader ?? true),
      index: definition.Index,
      noWrap: definition.NoWrap,
      link: definition.Link
        ? item => {
            const link = fillStringTemplate(
              definition.Link || '',
              item as Record<string, string | number | boolean>
            );
            const url = new URL(link, window.location.origin);
            const currentSearchParams = new URLSearchParams(
              window.location.search
            );
            currentSearchParams.forEach((value, key) => {
              if (!url.searchParams.has(key)) {
                url.searchParams.set(key, value);
              }
            });

            return `${url.pathname}?${url.searchParams}`;
          }
        : undefined,
      handleLinkClick:
        definition.Link && definition.LinkFilters
          ? item => {
              const memoryStore = this._rootStore.memoryStore;
              let persistedFilters = memoryStore.getItem<
                ObservableMap<string, string>
              >(ReportingPageFilterStore.MEMORY_STORE_KEY);

              if (!persistedFilters) {
                persistedFilters = new ObservableMap<string, string>();
                memoryStore.setItem(
                  ReportingPageFilterStore.MEMORY_STORE_KEY,
                  persistedFilters
                );
              }

              Object.entries(definition.LinkFilters || {}).forEach(
                ([filter, columnKey]) => {
                  persistedFilters!.set(filter, String(item[columnKey]));
                }
              );
            }
          : undefined,
      getTooltipContent: definition.TooltipValue
        ? item => {
            const isReferencedColumn =
              definition.TooltipValue !== definition.Name;
            const def = isReferencedColumn
              ? this.dataDefinition.find(
                  d => d.Name === definition.TooltipValue
                )
              : definition;

            if (!def) {
              return undefined;
            }

            const value = item[def.Name];

            if (typeof value === 'object') {
              return;
            }

            const translatedValue = def.TranslateValue
              ? GlobalIntl.formatMessage(
                  { id: String(value) },
                  formatRowDataByBusinessFormat(
                    item,
                    this.columnsDefinition,
                    this._rootStore.logger
                  )
                )
              : value;

            return formatValueByBusinessFormat(
              translatedValue,
              def.BusinessFormat,
              def.Format
            );
          }
        : undefined,
      getColor:
        definition.Color || definition.ColorValue
          ? item => {
              if (definition.Color) {
                return definition.Color;
              }
              const def = this.dataDefinition.find(
                d => d.Name === definition.ColorValue
              );

              if (!def) {
                return undefined;
              }

              return String(item[def.Name]);
            }
          : undefined,
    };
  }
}
