import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export interface UpdateDayActivityItemValueRequestParams {
  activityItemId: number;
  day: string;
  userGroupId: number | null;
  userId: number | null;
  value: number;
  state: string;
}

export function updateDayActivityItemValue(
  params: UpdateDayActivityItemValueRequestParams
): AxiosPromise<boolean> {
  return axios.post('api/TrainingDayActivity', params);
}
