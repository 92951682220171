import { createContext } from 'react';
import { PlannerEventFormStep } from '../types';

interface PlannerFormContextValue {
  readonly currentStep: PlannerEventFormStep;
  readonly setCurrentStep: (step: PlannerEventFormStep) => void;
  readonly closeForm: () => void;
}
export const PlannerFormContext = createContext<PlannerFormContextValue>({
  currentStep: 'details',
  closeForm: () => {},
  setCurrentStep: () => {},
});
