import { IntegratorRoot, ThemeProvider } from '@yarmill/components';
import { useBrowserWindowHeight } from '@yarmill/utils';
import { History } from 'history';
import { useEffect } from 'react';
import { Router as ReactRouter } from 'react-router-dom';
import vhCheck from 'vh-check';
import { RedirectActionHandler } from '../page/redirect-action-handler';
import { Router } from '../routes/router';
import { Meta } from './meta';

interface AppProps {
  history: History;
}

export function App({ history }: AppProps): JSX.Element {
  useBrowserWindowHeight();
  useEffect(() => {
    vhCheck();
  }, []);

  return (
    <ThemeProvider theme="legacy">
      <IntegratorRoot>
        <ReactRouter history={history}>
          <RedirectActionHandler />
          <Meta />
          <Router />
        </ReactRouter>
      </IntegratorRoot>
    </ThemeProvider>
  );
}
