import { useCallback } from 'react';
import { usePlannerStore } from '../planner-store-context';
import { useSubmitOpenedForm } from './use-submit-opened-form';
import { useCurrentUserIsAllowedToWrite } from './use-current-user-is-allowed-to-write';

export function useOnCreateStartHandler() {
  const plannerStore = usePlannerStore();
  const submitOpenedForm = useSubmitOpenedForm();
  const currentUserIsAllowedToWrite = useCurrentUserIsAllowedToWrite();

  return useCallback(() => {
    if (plannerStore.formEvent && currentUserIsAllowedToWrite) {
      if (plannerStore.calendarFormEvent?.eventTypeId) {
        plannerStore.lastEventType =
          plannerStore.calendarFormEvent?.eventTypeId;
      }
      submitOpenedForm();
      plannerStore.formEvent = null;
      plannerStore.calendarFormEvent = null;
    }
    plannerStore.showImporter = false;
  }, [currentUserIsAllowedToWrite, submitOpenedForm, plannerStore]);
}
