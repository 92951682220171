import styled from 'styled-components';
import { STROKE_WIDTH, StyledSvg, SVG_SIZE } from './svg';

import { ProgressPath } from '../progress';

export const StyledProgress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
`;

export const StyledSvgWrapper = styled.div`
  width: ${SVG_SIZE + 6}px;
  height: ${SVG_SIZE + 6}px;
  background-color: rgba(31, 37, 49, 0.85);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;

export interface ToastProgressProps {
  progress?: number;
}

export function ToastProgress(props: ToastProgressProps): JSX.Element {
  const { progress } = props;

  return (
    <StyledProgress className="toast-progress">
      <StyledSvgWrapper>
        <StyledSvg spin={progress === undefined}>
          <ProgressPath
            progress={progress}
            svgSize={SVG_SIZE}
            strokeWidth={STROKE_WIDTH}
          />
        </StyledSvg>
      </StyledSvgWrapper>
    </StyledProgress>
  );
}
