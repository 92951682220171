import { CheckboxChecked, CheckboxMinus } from '@yarmill/icons-2';
import { css, Icon, IconSize, styled } from '@yarmill/components';
import { MouseEventHandler } from 'react';

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.size.x4};
  height: ${({ theme }) => theme.size.x4};

  margin: -${({ theme }) => theme.size.x1};
  padding: ${({ theme }) => theme.size.x1};
`;

const IconWrapper = styled.div<{
  readonly $isChecked?: boolean;
  readonly $isActive?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.size.x2};
  height: ${({ theme }) => theme.size.x2};
  background-color: ${({ theme }) => theme.color.blackHover};
  color: ${({ theme }) => theme.color.navy};
  border-radius: ${({ theme }) => theme.borderRadius.x05};
  border: 1px solid transparent;

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      background-color: ${({ theme }) => theme.color.navy};
    `}}

${({ $isActive }) =>
  $isActive &&
  css`
    border: 1px solid ${({ theme }) => theme.color.navy};
  `}}
`;

interface MultiSelectCheckboxProps {
  readonly isChecked?: boolean;
  readonly isAdditional?: boolean;
  readonly isActive?: boolean;
  readonly onClick?: MouseEventHandler<HTMLDivElement>;
}
export function MultiSelectCheckbox({
  isChecked,
  isAdditional,
  isActive,
  onClick,
}: MultiSelectCheckboxProps): JSX.Element {
  return (
    <CheckboxWrapper onClick={onClick}>
      <IconWrapper $isChecked={isChecked} $isActive={isActive}>
        {isAdditional && isChecked && (
          <Icon size={IconSize.s16}>
            <CheckboxMinus />
          </Icon>
        )}
        {isChecked && !isAdditional && (
          <Icon size={IconSize.s16}>
            <CheckboxChecked />
          </Icon>
        )}
      </IconWrapper>
    </CheckboxWrapper>
  );
}
