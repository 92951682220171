import { IntlShape } from 'react-intl';
import { ReportingStore } from '../mobx/reporting-store';
import { getFilterValues } from './get-filter-values';

export function formatFileName(
  fileNameId: string,
  reportingStore: ReportingStore,
  intl: IntlShape
): string {
  const filters = getFilterValues(
    reportingStore.dataStore?.filters ?? [],
    intl
  );

  const values = {
    ...filters,
    instanceName: intl.formatMessage({ id: 'fe.instance.name' }),
  };

  return intl.formatMessage({ id: fileNameId }, values);
}
