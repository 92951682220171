import { FormattedMessage } from 'react-intl';
import { ContentBox } from './content-box';
import { Text, TextSize } from './text';

export interface NoAthleteProps {
  context?: 'reality' | 'analytics' | 'evidence';
}

export function NoAthlete(props: NoAthleteProps): JSX.Element {
  const { context = 'reality' } = props;

  return (
    <ContentBox>
      <Text size={TextSize.s14}>
        <FormattedMessage id={`${context}.noAthlete`} />
      </Text>
    </ContentBox>
  );
}
