import { styled } from '@yarmill/components';
import { DropdownButtonWrapper } from '@yarmill/components-2';

export const SupervisorsDropdownButtonWrapper = styled(DropdownButtonWrapper)<{
  readonly isEmpty: boolean;
}>`
  padding: ${({ theme, isEmpty }) => (isEmpty ? theme.size.x1 : theme.size.x05)}
    ${({ theme }) => theme.size.x1};
`;

export const StatusDropdownButtonWrapper = styled(DropdownButtonWrapper)<{
  readonly variant?: 'default' | 'large';
}>`
  padding: ${({ theme, variant = 'default' }) =>
    variant === 'default' ? theme.size.x1 : 0};
`;
