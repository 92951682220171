import { WrappedComponentProps } from 'react-intl';
import { Days } from './kpi/days';
import { Hours } from './kpi/hours';
import { Sickness } from './kpi/sickness';
import { KPI as KPIType } from './types';
import { observer } from 'mobx-react-lite';
import { AnalyticsContentBox, ReportContainer } from '@yarmill/components';

export interface KPIProps extends WrappedComponentProps {
  data: KPIType[];
}

export const Kpi = observer(function KPI(props: KPIProps): JSX.Element {
  const { data, intl } = props;

  return (
    <>
      <ReportContainer sectionArea="days">
        <AnalyticsContentBox>
          <Days data={data} intl={intl} />
        </AnalyticsContentBox>
      </ReportContainer>
      <ReportContainer sectionArea="hours">
        <AnalyticsContentBox>
          <Hours data={data} intl={intl} />
        </AnalyticsContentBox>
      </ReportContainer>
      <ReportContainer sectionArea="sickness">
        <AnalyticsContentBox>
          <Sickness data={data} intl={intl} />
        </AnalyticsContentBox>
      </ReportContainer>
    </>
  );
});
