import {
  Mark,
  markInputRule,
  markPasteRule,
  mergeAttributes,
} from '@tiptap/core';
import { inputRegex, pasteRegex } from './highlight';
import { Plugin, PluginKey } from '@tiptap/pm/state';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    link: {
      /**
       * Set a highlight mark
       */
      setLink: (attributes: { href: string }) => ReturnType;
      /**
       * Toggle a highlight mark
       */
      toggleLink: (attributes: { href: string }) => ReturnType;
      /**
       * Unset a highlight mark
       */
      unsetLink: () => ReturnType;
    };
  }
}

interface LinkOptions {
  readonly HTMLAttributes: Record<string, any>;
}

export const Link = Mark.create<LinkOptions>({
  name: 'link',
  keepOnSplit: false,
  priority: 1000000,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      href: {
        default: null,
        parseHTML: (element: HTMLElement) =>
          element.className.split(' ')?.find(c => !c.includes('ytd-video')) &&
          element.getAttribute('href'),
        renderHTML: attributes => {
          if (!attributes.href) {
            return {};
          }

          return {
            href: attributes.href,
          };
        },
      },
      target: {
        default: '__blank',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'a',
        getAttrs: element =>
          element instanceof HTMLElement
            ? {
                class: !element.className.includes('ytd-video'),
                href: element.getAttribute('href'),
              }
            : null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'a',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setLink:
        attributes =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes);
        },
      toggleLink:
        attributes =>
        ({ commands }) => {
          return commands.toggleMark(this.name, attributes);
        },
      unsetLink:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },

  addInputRules() {
    return [
      markInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: pasteRegex,
        type: this.type,
      }),
    ];
  },

  addProseMirrorPlugins() {
    return [clickHandler()];
  },
});

function clickHandler(): Plugin {
  return new Plugin({
    key: new PluginKey('handleClickLink'),
    props: {
      handleClick: (_view, _pos, event) => {
        if (event.button !== 0) {
          return false;
        }

        const link = (event.target as HTMLElement).closest('a');

        const className = link?.className;

        if (
          link instanceof HTMLAnchorElement &&
          link.href &&
          !className?.includes('ytd-video')
        ) {
          const element = document.createElement('a');
          element.href = link.href;
          element.target = '_blank';
          element.rel = 'noopener noreferrer';

          element.click();
          return true;
        }

        return false;
      },
    },
  });
}
