import { formatDecimalNumber } from './format-decimal-number';

export function formatSignedDecimalNumber(
  value: number | string | null | undefined,
  format: string | number | null | undefined
): string {
  if (value === null || value === undefined) {
    return '';
  }

  const formattedValue = formatDecimalNumber(value, format);
  const number = typeof value === 'string' ? Number(value) : value;
  const prefix = number > 0 ? '+' : '';

  return `${prefix}${formattedValue}`;
}
