import { StyledTr, Table, styled } from '@yarmill/components';
import { times, useLogger } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import {
  MutableRefObject,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { formatRowDataByBusinessFormat } from '../../utils/format-row-data-by-business-format';
import { NoDataCell } from './no-data-cell';
import { ReportingTableCell } from './reporting-table-cell';
import { ReportingTableHeaderCell } from './reporting-table-header-cell';
import {
  ReportingTable as IReportingTable,
  ReportingTableColumnConfig,
  ReportingTableRowData,
} from './types';
import { calculateRowSpan } from './utils/calculate-row-span';

export interface ReportingTableProps {
  table: IReportingTable;
  data: ReportingTableRowData[] | undefined;
  descriptiveData?: Record<string, string>;
}

const ReportingTableTr = styled(StyledTr)`
  height: calc(24px + 2 * 4px);

  @media print {
    height: auto;
  }
`;

function getColWidth(
  colIdx: number,
  columns: ReportingTableColumnConfig[]
): string | number {
  const predefinedWidth = columns[colIdx]?.width;

  if (predefinedWidth !== undefined && predefinedWidth !== null) {
    return predefinedWidth;
  }

  const baseWidth = 100;

  return `${baseWidth / columns.length}%`;
}

function getSkippedColumns(
  columnConfigs: ReportingTableColumnConfig[]
): number[] {
  const skipped: number[] = [];
  columnConfigs.forEach((column, idx) => {
    if (column.colSpan) {
      times(column.colSpan - 1).forEach((_, colNum) =>
        skipped.push(idx + (colNum + 1))
      );
    }
  });

  return skipped;
}

function shouldRenderCell(
  columnKey: string,
  skippedRowsMap: MutableRefObject<Record<string, number[]>>,
  rowIdx: number
): boolean {
  if (!columnKey) {
    return false;
  }

  return !skippedRowsMap.current[columnKey]?.includes(rowIdx);
}

export const ReportingTable = observer<ReportingTableProps, HTMLTableElement>(
  forwardRef<HTMLTableElement, ReportingTableProps>(
    function ReportingTable(props, ref): JSX.Element {
      const { table, data, descriptiveData } = props;
      const logger = useLogger();
      const config = table.configuration;
      const rowsCount = data?.length || 0;
      const skippedHeaderColumns = useRef<number[]>(
        getSkippedColumns(table.columnsDefinition)
      );
      const skippedRowsMap = useRef<Record<string, number[]>>({});

      const shouldFormatRowData = useMemo(
        () => table.columnsDefinition?.some(def => def.translateValue),
        [table]
      );

      useEffect(() => {
        skippedHeaderColumns.current = getSkippedColumns(
          table.columnsDefinition
        );
      }, [table.columnsDefinition]);

      useEffect(() => {
        if (data) {
          skippedRowsMap.current = {};
        }
      }, [data]);

      return (
        <Table
          ref={ref}
          colgroup={
            <>
              {table.columnsDefinition.map((def, idx) =>
                skippedHeaderColumns.current.includes(idx) ? null : (
                  <col
                    key={idx}
                    span={def.colSpan ?? undefined}
                    width={
                      getColWidth(idx, table.columnsDefinition) ?? undefined
                    }
                  />
                )
              )}
            </>
          }
          head={
            config.hasHeader ? (
              <>
                {table.columnsDefinition.map((column, idx) =>
                  skippedHeaderColumns.current.includes(idx) ? null : (
                    <ReportingTableHeaderCell
                      key={idx}
                      config={config}
                      column={column}
                      alignment={column.headerAlignment}
                      descriptiveData={descriptiveData}
                    />
                  )
                )}
              </>
            ) : undefined
          }
        >
          {times(rowsCount).map((_, rowIdx) => {
            const rowData = data?.[rowIdx];
            const formattedRowData = shouldFormatRowData
              ? formatRowDataByBusinessFormat(
                  rowData ?? {},
                  table.columnsDefinition,
                  logger
                )
              : rowData;

            return (
              <ReportingTableTr key={rowIdx}>
                {table.columnsDefinition.map(
                  (columnDef, columnIdx) =>
                    shouldRenderCell(
                      columnDef.columnName,
                      skippedRowsMap,
                      rowIdx
                    ) && (
                      <ReportingTableCell
                        key={columnIdx}
                        columnConfig={columnDef}
                        data={rowData?.[columnDef.columnName || '']}
                        formattedRowData={formattedRowData}
                        rowData={rowData}
                        rowIdx={rowIdx}
                        rowSpan={
                          columnDef?.groupSameValues
                            ? calculateRowSpan(
                                data,
                                columnDef.columnName || '',
                                rowIdx,
                                skippedRowsMap
                              )
                            : undefined
                        }
                      />
                    )
                )}
              </ReportingTableTr>
            );
          })}
          {data && data.length === 0 && (
            <ReportingTableTr>
              <NoDataCell numberOfColumns={table.columnsDefinition.length} />
            </ReportingTableTr>
          )}
        </Table>
      );
    }
  )
);
