import { Header } from '@yarmill/components-2';
import { observer } from 'mobx-react-lite';
import { RefObject } from 'react';
import { DecreaseZoomLevel } from './buttons/decrease-zoom-level';
import { IncreaseZoomLevel } from './buttons/increase-zoom-level';
import { ScrollLeft } from './buttons/scroll-left';
import { ScrollRight } from './buttons/scroll-right';
import { ButtonGroupContainer } from './components/button-group';
import { useIsZoomOutAvailable } from './hooks/use-is-zoom-out-available';
import { SavingStatus } from './saving-status';

interface PlannerFooterProps {
  readonly containerRef: RefObject<HTMLDivElement>;
  readonly plannerRef: RefObject<HTMLDivElement>;
  readonly leftScrollIndicatorRef: RefObject<HTMLDivElement>;
  readonly rightScrollIndicatorRef: RefObject<HTMLDivElement>;
}
export const PlannerFooter = observer(function PlannerFooter({
  containerRef,
  plannerRef,
  leftScrollIndicatorRef,
  rightScrollIndicatorRef,
}: PlannerFooterProps): JSX.Element {
  const isZoomOutAvailable = useIsZoomOutAvailable(plannerRef, containerRef);

  return (
    <Header
      left={
        <ButtonGroupContainer>
          <DecreaseZoomLevel isZoomOutAvailable={isZoomOutAvailable} />
          <IncreaseZoomLevel />
        </ButtonGroupContainer>
      }
      center={<SavingStatus />}
      right={
        <ButtonGroupContainer>
          <ScrollLeft
            plannerContainerRef={containerRef}
            leftScrollIndicatorRef={leftScrollIndicatorRef}
          />
          <ScrollRight
            plannerContainerRef={containerRef}
            rightScrollIndicatorRef={rightScrollIndicatorRef}
          />
        </ButtonGroupContainer>
      }
      inverted
      position="footer"
    />
  );
});
