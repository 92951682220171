import { ModulesStore } from '@yarmill/types';
import { useRootStore } from '@yarmill/utils';

export function useModulesStore(): ModulesStore {
  return useRootStore().modulesStore;
}

type ModuleKey = keyof ModulesStore;
export function useModule<P extends ModuleKey>(moduleKey: P): ModulesStore[P] {
  return useModulesStore()[moduleKey];
}
