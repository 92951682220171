import { Permission } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export function getUserGroupUserPermission(
  userGroupId: number
): AxiosPromise<Permission[]> {
  return axios.get('api/UserGroupUserPermission', {
    params: {
      userGroupId,
    },
  });
}
