import { Icon, IconSize, Tippy } from '@yarmill/components';
import { Button, ButtonAppearance } from '@yarmill/components-2';
import { ZoomIn } from '@yarmill/icons-2';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { MAX_PLANNER_ZOOM_LEVEL } from '../components/utils';
import { usePlannerStore } from '../planner-store-context';

export function IncreaseZoomLevel(): ReactElement {
  const plannerStore = usePlannerStore();
  const intl = useIntl();
  const isEnabled = plannerStore.zoomLevel < MAX_PLANNER_ZOOM_LEVEL;

  return (
    <Tippy tooltipContent="planner.zoomIn" noWrapper touch={false}>
      <Button
        $appearance={ButtonAppearance.Secondary}
        onClick={isEnabled ? () => plannerStore.increaseZoomLevel() : undefined}
        $appearanceStyle="neutral"
        $iconOnly
        disabled={!isEnabled}
        type="button"
        aria-label={intl.formatMessage({ id: 'planner.zoomIn' })}
      >
        <Icon size={IconSize.s24}>
          <ZoomIn />
        </Icon>
      </Button>
    </Tippy>
  );
}
