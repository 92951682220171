import { toast } from '@yarmill/components';
import { GroupStore, UserStore } from '@yarmill/types';

export function toastAddUsersToGroup(
  users: UserStore[],
  group: GroupStore,
  type: 'success' | 'error'
): void {
  if (users.length === 1) {
    toast(`toast.${type}.addUserToGroup`, type, {
      user: users[0].displayName,
      group: group.name,
    });
  } else {
    toast(`toast.${type}.addUsersToGroup`, type, {
      count: String(users.length),
      group: group.name,
    });
  }
}
