import styled from 'styled-components';
import { Text, TextSize, TextTag } from '../text';
import { ReactNode } from 'react';

export interface ReportingFiltersProps {
  headline: string;
  filters: ReactNode;
}

const ReportingFiltersWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  row-gap: 12px;
`;

const FiltersContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  row-gap: 16px;
`;

export const ReportingPageActions = styled.div`
  display: flex;
  gap: 8px;
`;

export function ReportingFilters(props: ReportingFiltersProps): JSX.Element {
  const { headline, filters } = props;

  return (
    <ReportingFiltersWrapper>
      <Text tag={TextTag.h2} size={TextSize.s16} medium>
        {headline}
      </Text>
      <FiltersContainer>{filters}</FiltersContainer>
    </ReportingFiltersWrapper>
  );
}
