import { UserGroupId, UserId } from '@yarmill/types';
import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';

export interface PublishRequestParams {
  userId: UserId | undefined;
  userGroupId: UserGroupId | undefined;
  startDate: string;
  endDate: string;
}

export function publish(
  params: PublishRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<boolean> {
  return axios.post(`${coreApiTarget}/api/planner/publish`, params, {
    cancelToken: cancelToken?.token,
    params: {
      ...params,
      instanceCode: devMode ? instance : undefined,
    },
  });
}
