import { DataDefinitionV2, ReportItem } from '../types';

import { computed, makeObservable } from 'mobx';
import { BaseReportStore } from './base-report-store';
import { ExternalIconName } from '@yarmill/components';

export class KpiStore extends BaseReportStore {
  constructor(item: ReportItem) {
    super(item);
    makeObservable(this);
  }

  @computed
  public get valueDefinition(): DataDefinitionV2 | undefined {
    return this._item.DataDefinition.find(def => def.Purpose === 'Value');
  }

  public get kpiValue(): string {
    return this._item.Params?.Value || 'Missing Value Param';
  }

  @computed
  public get icon(): ExternalIconName | undefined {
    return this._item.Params?.Icon;
  }

  @computed
  public get textIcon(): string | undefined {
    return this._item.Params?.TextIcon;
  }

  public get kpiDescription(): string {
    return this._item.Params?.Description || 'Missing Description Param';
  }
}
