import { LabelPosition } from '../../reporting/types';
import { LABEL_FONT_SIZE, LABEL_OFFSET } from '../../reporting/const';

export function shouldShowHorizontalBarStackLabel(
  showLabels: boolean | undefined,
  position: LabelPosition | undefined,
  barWidth: number,
  barHeight: number,
  labelWidth: number
): boolean {
  if (!showLabels || !barWidth) {
    return false;
  }

  if (position === 'inside-center' || position === 'inside-top') {
    if (
      barWidth < labelWidth + LABEL_OFFSET / 2 ||
      barHeight < LABEL_FONT_SIZE
    ) {
      return false;
    }
  }

  return true;
}
