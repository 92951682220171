import { observer } from 'mobx-react-lite';

import { useSidebarStore, useWindowSize } from '@yarmill/utils';
import { PrimarySidebarContainer } from '../page-layout';
import { PrimarySidebarLayout } from '../primary-sidebar';
import { Header } from './header';
import { Navigation } from './navigation';

export const PrimarySidebar = observer(function PrimarySidebar(): JSX.Element {
  const sidebarStore = useSidebarStore();
  const { width } = useWindowSize();

  return (
    <PrimarySidebarContainer
      isVisible={sidebarStore.isVisible}
      windowWidth={width ?? 0}
    >
      <PrimarySidebarLayout>
        <Header />
        <Navigation />
      </PrimarySidebarLayout>
    </PrimarySidebarContainer>
  );
});
