import { uniqId } from '@yarmill/utils';
import { FormUserInvitation } from '../types';

export function createEmptyInvitation(): FormUserInvitation {
  return {
    Email: '',
    LastName: '',
    FirstName: '',
    Role: 'athlete',
    uid: uniqId(),
  };
}
