import { RefObject, useEffect, useRef } from 'react';
import { EvidenceAttributeStore } from '../mobx/evidence-attribute-store';
import { isFirstInvalidField } from '../utils';

export function useInvalidFieldFocusHandler<T extends HTMLElement>(
  attributeStore: EvidenceAttributeStore,
  customRef?: { current: { focus(): void } }
): RefObject<T> {
  const elementRef = useRef<T>(null);
  const submitButtonClicked =
    attributeStore.objectDataStore.submitButtonClicked;

  useEffect(() => {
    if (
      submitButtonClicked &&
      !attributeStore.isValid &&
      isFirstInvalidField(attributeStore)
    ) {
      customRef?.current?.focus() || elementRef.current?.focus();
    }
  }, [attributeStore, submitButtonClicked, customRef]);

  return elementRef;
}
