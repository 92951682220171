import {
  DailyGoalDesktopDate,
  DailyGoalMobileDate,
  DailyGoalTabletDate,
  Link,
  TextSize,
} from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FormattedDate } from 'react-intl';
import { useGoalLabelLinkAttributes } from './hooks';
import { GoalAttributeStore } from './mobx/goal-attribute-store';

export interface GoalDayLabelProps {
  attribute: GoalAttributeStore;
}

export const GoalDayLabel = observer(function GoalDayLabel(
  props: GoalDayLabelProps
): JSX.Element {
  const { attribute } = props;
  const { to, onClick } = useGoalLabelLinkAttributes(attribute);
  const date = moment(attribute.trainingDayStore.currentDate).toDate();

  return (
    <Link to={to} onClick={onClick}>
      <DailyGoalDesktopDate size={TextSize.s12} inheritColor>
        <FormattedDate value={date} day="numeric" />
      </DailyGoalDesktopDate>
      <DailyGoalTabletDate size={TextSize.s12} inheritColor>
        <FormattedDate value={date} day="numeric" month="numeric" />
      </DailyGoalTabletDate>
      <DailyGoalMobileDate size={TextSize.s12} inheritColor>
        <strong>
          <FormattedDate value={date} weekday="long" />
        </strong>
        <FormattedDate
          value={date}
          day="numeric"
          month="numeric"
          year="numeric"
        />
      </DailyGoalMobileDate>
    </Link>
  );
});
