import { StyledProgress, StyledSvgWrapper } from './toast-progress';
import { CrossIcon, StyledSvg } from './svg';

export function ToastError(): JSX.Element {
  return (
    <StyledProgress>
      <StyledSvgWrapper>
        <StyledSvg viewBox="0 0 26 26">
          <CrossIcon />
        </StyledSvg>
      </StyledSvgWrapper>
    </StyledProgress>
  );
}
