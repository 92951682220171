import { AsyncStatus, FileUploadStore } from '@yarmill/types';
import * as React from 'react';
import { useFileUploadService } from './use-file-upload-service';
import { useLogger } from './use-logger';

type FileUploadCallback = (
  store: FileUploadStore,
  toastId: string | number
) => void;

type FileUploadStartCallback = (store: FileUploadStore) => string | number;

export function useFileUpload(
  onSuccess: FileUploadCallback,
  onError: FileUploadCallback,
  onStart: FileUploadStartCallback
): [React.RefObject<HTMLInputElement>, React.ChangeEventHandler] {
  const fileUploadService = useFileUploadService();
  const logger = useLogger();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onChange = React.useCallback(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      if (input.files) {
        for (const file of input.files) {
          const fileUpload = fileUploadService.uploadFile(file);
          const toastId = onStart(fileUpload);
          fileUpload.setToastId(toastId);

          fileUpload
            .uploadFile()
            .then(() => {
              if (
                fileUpload.status === AsyncStatus.resolved &&
                fileUpload.uploadedFile
              ) {
                onSuccess?.(fileUpload, toastId);
              } else {
                onError?.(fileUpload, toastId);
              }
            })
            .catch(e => {
              logger.error('Unable to upload file.', e);
              if (onError) {
                onError?.(fileUpload, toastId);
              }
            });
        }
        input.value = '';
      }
    }
  }, [fileUploadService, onStart, onSuccess, onError, logger]);

  return [inputRef, onChange];
}
