import { UserId } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export function updateEmail(
  userId: UserId,
  email: string
): AxiosPromise<boolean> {
  return axios.put('api/Account/UpdateEmail', { userId, email });
}
