import { DropdownItem, Icon } from '@yarmill/components';
import { MouseEvent, PropsWithChildren } from 'react';
import { trackUserMenuClick } from '../google-analytics/utils';

export type UserSectionItemProps = PropsWithChildren<{
  isHeader?: boolean;
  dataTest?: string;
  icon?: JSX.Element;
  linkHref?: string;
  linkTarget?: string;
  danger?: boolean;
  onClick?(): void;
}>;

export function UserSectionItem(props: UserSectionItemProps): JSX.Element {
  const {
    icon,
    linkHref,
    linkTarget,
    children,
    dataTest,
    onClick,
    isHeader,
    danger,
  } = props;
  const wrapperProps = linkHref
    ? { href: linkHref, target: linkTarget || undefined }
    : { type: 'button' };

  function handleClick(e: MouseEvent): void {
    onClick?.();
    const { target } = e;
    if (target instanceof HTMLElement) {
      trackUserMenuClick(target.innerText);
    }
  }

  return (
    <li>
      <DropdownItem
        {...wrapperProps}
        danger={danger}
        isHeader={isHeader}
        as={linkHref ? 'a' : 'button'}
        data-cy={dataTest}
        onClick={handleClick}
      >
        {icon && <Icon>{icon}</Icon>}
        {children}
      </DropdownItem>
    </li>
  );
}
