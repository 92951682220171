import { AsyncStatus, RequestStore, RootStore } from '@yarmill/types';
import {
  IObservableArray,
  IReactionDisposer,
  action,
  makeObservable,
  observable,
  reaction,
  runInAction,
  when,
} from 'mobx';
import {
  GetSeasonOverSeasonResponse,
  getSeasonOverSeasonData,
} from '../api/get-season-over-season-data';
import { SeasonEvaluationStore } from './season-evaluation-store';

import { getSeasonOverSeasonDefinition } from '../api/get-season-over-season-definition';
import { ExpandableTree } from '../components/csv-table/mobx/expandable-tree';
import {
  CsvTable,
  CsvTableFirstColumnItem,
  CsvTableHeader,
  CsvTableRowData,
} from '../components/csv-table/types';
export class SeasonOverSeasonEvaluationStore {
  private readonly rootStore: RootStore;
  private readonly seasonEvaluationStore: SeasonEvaluationStore;

  @observable
  private dataRequest: RequestStore<GetSeasonOverSeasonResponse> | null = null;

  @observable
  private _definition: CsvTable[] = [];

  @observable
  private _expandables: ExpandableTree<CsvTableFirstColumnItem>[] = [];

  @observable
  private _data: CsvTableRowData[][] = []; // table / rows

  private reactions: IReactionDisposer[] = [];

  constructor(
    rootStore: RootStore,
    seasonEvaluationStore: SeasonEvaluationStore
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.seasonEvaluationStore = seasonEvaluationStore;

    when(
      () => rootStore.status === AsyncStatus.resolved,
      () => {
        void this.loadDefinition();
        this.registerReactions();
      }
    );
  }

  public disposeReactions(): void {
    this.reactions.forEach(dispose => dispose());
  }

  public get status(): AsyncStatus {
    if (!this.dataRequest) {
      return AsyncStatus.idle;
    }

    return this.dataRequest.status;
  }

  public get definition(): CsvTable[] {
    return this._definition;
  }

  public getColumnHeader(
    tableIndex: number,
    columnIndex: number
  ): CsvTableHeader {
    return this._definition[tableIndex]?.Header[columnIndex];
  }

  public getFirstColumnConfig(
    tableIndex: number,
    rowIndex: number
  ): CsvTableFirstColumnItem {
    return this._definition[tableIndex]?.FirstColumn[rowIndex];
  }

  public getData(tableIndex: number): CsvTableRowData[] | undefined {
    return this._data.length !== 0 &&
      tableIndex < this._data.length &&
      tableIndex >= 0
      ? this._data[tableIndex]
      : undefined;
  }

  public getExpandable(
    tableIndex: number
  ): ExpandableTree<CsvTableFirstColumnItem> | undefined {
    return this._expandables[tableIndex];
  }

  @action
  private async loadDefinition(): Promise<boolean> {
    const userId = this.seasonEvaluationStore.athleteId;

    if (!userId) {
      return true;
    }

    const language = this.rootStore.intlStore.locale;
    const request = this.rootStore.requestsStore.createRequest(cancelToken =>
      getSeasonOverSeasonDefinition({
        language,
        userId,
      })
    );

    const response = await request.getResponse();
    if (response) {
      runInAction(() => {
        this._definition = response;

        (this._expandables as IObservableArray).clear();
        response.forEach(table => {
          if (table.FirstColumn.length) {
            const expandable = this.createExpandableTree(table.FirstColumn);
            this._expandables.push(expandable);
          }
        });
      });

      return true;
    } else {
      return request.status !== AsyncStatus.rejected;
    }
  }

  @action
  private async loadData(): Promise<boolean> {
    if (this.dataRequest) {
      this.dataRequest.cancel();
      this.rootStore.requestsStore.removeRequest(this.dataRequest);
    }

    const athleteId = this.seasonEvaluationStore.athleteId;

    if (!athleteId) {
      return true;
    }

    const params = {
      userId: athleteId,
    };

    const request = (this.dataRequest =
      this.rootStore.requestsStore.createRequest(cancelToken =>
        getSeasonOverSeasonData(params, cancelToken)
      ));

    const response = await request.getResponse();

    if (response) {
      runInAction(() => {
        response.forEach(tableData => this._data.push(tableData.Data));
      });
      return true;
    } else {
      return request.status !== AsyncStatus.rejected;
    }
  }

  private registerReactions(): void {
    const dataLoader = reaction(
      () => ({
        athleteId: this.seasonEvaluationStore.athleteId,
        groupId: this.seasonEvaluationStore.groupId,
        viewType: this.seasonEvaluationStore.viewType,
        maskCode: this.seasonEvaluationStore.currentMaskCode,
      }),
      async () => {
        this.clearData();
        if (this.seasonEvaluationStore.viewType === 'season-over-season') {
          const transaction = this.rootStore.navbarStore.createTransaction(
            'loadingData',
            'season-evaluation'
          );

          const results = await Promise.all([
            this.loadDefinition(),
            this.loadData(),
          ]);

          if (results.every(Boolean)) {
            transaction.finished();
          } else {
            transaction.error();
          }
        }
      },
      {
        fireImmediately: true,
      }
    );

    this.reactions.push(dataLoader);
  }

  private clearData(): void {
    (this._data as IObservableArray).clear();
  }

  private createExpandableTree(
    elements: CsvTableFirstColumnItem[]
  ): ExpandableTree<CsvTableFirstColumnItem> {
    return new ExpandableTree(
      elements,
      (_, index) => index,
      e => e.Level || 0,
      `sos-evaluation-${this.seasonEvaluationStore.currentMaskCode}`
    );
  }
}
