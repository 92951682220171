import * as React from 'react';

import { NumberLike } from '@visx/scale';
import { useMemo } from 'react';
import { BaseWorkoutChart, BaseWorkoutData } from '../base-workout-chart';
import { SyncTooltipHandlers } from '../base-workout-chart/tooltip';

export interface HeartRateZone {
  top: number;
  bottom: number;
  name: string | null;
  id: number;
}

export interface HeartRateProps {
  data: BaseWorkoutData[];
  domain?: [number, number];
  height: number;
  total: number;
  showTooltip?: boolean;
  showZones?: boolean;
  syncId?: string;
  width: number;
  zones?: HeartRateZone[];
  id?: string;
  xAxisKey?: 'time' | 'distance';
  formatXAxisTicks?(duration: NumberLike): string;
  formatTooltipCursor?(duration: number): string;
  formatTooltip?(item: BaseWorkoutData): React.ReactNode;
  syncTooltipHandlers?: SyncTooltipHandlers;
  setSyncTooltipHandlers?: (handlers: SyncTooltipHandlers) => () => void;
}

export const HR_RANGE: [number, number] = [60, 220];
export const HR_ZONES: HeartRateZone[] = [
  { bottom: 100, top: 119, id: 1, name: 'zone 1' },
  { bottom: 120, top: 139, id: 2, name: 'zone 2' },
  { bottom: 140, top: 159, id: 3, name: 'zone 3' },
  { bottom: 160, top: 179, id: 4, name: 'zone 4' },
  { bottom: 180, top: 200, id: 5, name: 'zone 5' },
];

const CHART_MARGIN = {
  left: 60,
  right: 35,
  bottom: 20,
  top: 5,
};

const SAMPLE_MARGIN = {
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
};
export function HeartRate(props: HeartRateProps): JSX.Element {
  const { showZones, domain = HR_RANGE, zones } = props;

  const sortedZones = useMemo(
    () => (zones?.length ? zones.sort((a, b) => a.bottom - b.bottom) : []),
    [zones]
  );

  const ticks = useMemo(
    () =>
      domain && sortedZones.length
        ? sortedZones
            .map(zone => zone.bottom)
            .filter(zone => zone > domain[0] && zone < domain[1])
        : undefined,
    [domain, sortedZones]
  );

  return (
    <BaseWorkoutChart
      {...props}
      yAxisKey="hr"
      id="hr"
      domain={props.domain || HR_RANGE}
      showLeftAxis={showZones}
      showBottomAxis={showZones}
      showGridRows={showZones}
      showArea={!showZones}
      zones={sortedZones || HR_ZONES}
      yAxisTicks={ticks}
      marginConfig={showZones ? CHART_MARGIN : SAMPLE_MARGIN}
    />
  );
}
