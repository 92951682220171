import { scaleBand, scaleLinear, scaleTime } from '@visx/scale';
import { ScaleTypeToD3Scale } from '@visx/scale/lib/types/Scale';
import { useMemo } from 'react';
import { Domain } from '../../reporting/types';

export function useScale(
  domain: Domain,
  type: 'categorical' | 'continuous'
):
  | ScaleTypeToD3Scale<number>['linear']
  | ScaleTypeToD3Scale<string, string>['band']
  | ScaleTypeToD3Scale<number>['time'] {
  return useMemo(() => {
    const scaleConfig = {
      round: true,
    };

    if (
      type === 'continuous' &&
      domain.length === 2 &&
      typeof domain[0] === 'number' &&
      typeof domain[1] === 'number'
    ) {
      const scale = scaleLinear<number>(scaleConfig);
      scale.domain(domain as number[]);
      return scale;
    }

    if (
      type === 'continuous' &&
      domain.length === 2 &&
      domain[0] instanceof Date &&
      domain[1] instanceof Date
    ) {
      const scale = scaleTime<number>(scaleConfig);
      scale.domain(domain as Date[]);
      return scale;
    }

    const scale = scaleBand<string>(scaleConfig);
    scale.domain(domain as string[]);
    scale.padding(0.2);

    return scale;
  }, [domain, type]);
}
