import { axios } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { UserSummaryAttendance } from '../types';

export interface GetSeasonAttendanceItemsRequestParams {
  UserGroupId: number;
  SeasonId: number;
  ActivityItemId: number | null;
}

export function getSeasonAttendanceItems(
  params: GetSeasonAttendanceItemsRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<UserSummaryAttendance[]> {
  return axios.post('api/attendance/season/group', params, {
    cancelToken: cancelToken?.token,
  });
}
