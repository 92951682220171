import { times } from '@yarmill/utils';
import { MutableRefObject } from 'react';
import { ReportingTableRowData } from '../types';

export function calculateRowSpan(
  data: ReportingTableRowData[] | undefined,
  columnKey: string,
  rowIdx: number,
  skippedRowsMap: MutableRefObject<Record<string, number[]>>
): number | undefined {
  if (!data) {
    return;
  }

  const value = data[rowIdx][columnKey];
  let nextRowIdx = rowIdx + 1;
  let rowSpan = 1;

  while (nextRowIdx < data.length && data[nextRowIdx][columnKey] === value) {
    rowSpan++;
    nextRowIdx++;
  }

  if (rowSpan > 1) {
    skippedRowsMap.current[columnKey] = times(rowSpan - 1).map(
      (_, idx) => idx + rowIdx + 1
    );
  }

  return rowSpan;
}
