import { EvidenceDataObject, UserGroupId, UserId } from '@yarmill/types';
import { axios, devMode, instance } from '@yarmill/utils';
import { AxiosPromise } from 'axios';
import { ApiSortConfig, EvidenceTableFilters } from '../table/types';

export interface GetEvidenceObjectRequestParams {
  moduleKey: string;
  objectKey: string;
  userId: UserId | null;
  groupId: UserGroupId | null;
  sortConfig: ApiSortConfig | null;
  filters: EvidenceTableFilters | null;
}

export function getEvidenceObjectData(
  dataUrl: string,
  params: GetEvidenceObjectRequestParams
): AxiosPromise<EvidenceDataObject[]> {
  return axios.get(dataUrl, {
    params: {
      ...params,
      groupId: params.userId === null ? params.groupId : undefined,
      userId: params.userId !== null ? params.userId : undefined,
      sortConfig: params.sortConfig
        ? JSON.stringify(params.sortConfig)
        : undefined,
      filters: params.filters
        ? JSON.stringify(Object.fromEntries(params.filters.entries()))
        : undefined,
      instanceCode: devMode ? instance : undefined,
    },
  });
}
