import { StyledTd, css, styled } from '@yarmill/components';
import { UserStore } from '@yarmill/types';
import { useCurrentUserStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { AttendanceCurrentValue } from './attendance-current-value';
import { useAttendanceStore } from './attendance-store-context';
import { AttendanceValueButtons } from './attendance-value-buttons';
import { ENABLE_TABLE_ANIMATION } from './utils';

export interface AttendanceItemProps {
  user: UserStore;
  date: string;
  readonly: boolean;
  dayIndex: number;
}

export const ButtonsWrapper = styled.div<{ readonly isVisible: boolean }>`
  position: absolute;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  column-gap: 8px;
  transform: scale(0);
  opacity: 0;
  transition: transform 250ms ease;
  justify-content: center;
  align-items: center;
  display: flex;
  transform-origin: center;
  ${({ isVisible }) =>
    isVisible &&
    css`
      transform: scale(1);
      opacity: 1;
    `};
`;

export const Td = styled(StyledTd)`
  position: relative;

  ${
    ENABLE_TABLE_ANIMATION &&
    css`
    transition: all 250ms ease;
    transition-duration: 250ms;
    will-change: contents;
  `
  }
`;

export const AttendanceItemValue = observer(function AttendanceItemValue(
  props: AttendanceItemProps
): JSX.Element {
  const { readonly, date, user, dayIndex } = props;
  const currentUser = useCurrentUserStore();
  const attendanceStore = useAttendanceStore();

  const currentPhase = attendanceStore.currentPhase;
  const item = attendanceStore.weekData.get(user.id)?.get(date);
  const isCurrentlyEdited = attendanceStore.editedColumn === dayIndex;

  const value = item?.value;
  const hasReadPermissionToGroup =
    currentUser.getPermissionToGroup(attendanceStore.group?.id || -1) ===
    'read';
  const disabled =
    readonly ||
    !currentUser.isAllowedTo('attendance.write') ||
    hasReadPermissionToGroup ||
    !currentPhase?.isEditable;

  return (
    <Td disabled={disabled}>
      <ButtonsWrapper isVisible={isCurrentlyEdited && !disabled}>
        <AttendanceValueButtons
          date={date}
          item={item}
          user={user}
          disabled={!isCurrentlyEdited}
        />
      </ButtonsWrapper>
      <ButtonsWrapper isVisible={!isCurrentlyEdited || disabled}>
        <AttendanceCurrentValue
          value={value}
          dayIndex={dayIndex}
          disabled={disabled}
        />
      </ButtonsWrapper>
    </Td>
  );
});
