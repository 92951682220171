import { CurrentUserStore, GroupsStore } from '@yarmill/types';

export function getCurrentUserAllowedGroups(
  groupsStore: GroupsStore,
  currentUser: CurrentUserStore
) {
  return groupsStore.sortedGroups.filter(group =>
    currentUser.hasPermissionToGroup(group.id)
  );
}
