import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useMergeRefs } from '@floating-ui/react';
import { Icon, IconSize } from '@yarmill/components';
import { Grid } from '@yarmill/icons-2';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import {
  KeyResultDragHandle,
  KeyResultsTableTd,
} from './components/detail/key-result-detail';
import { KeyResultUid, OkrStatus } from './types';

type DraggableKeyResultProps = PropsWithChildren<{
  readonly id: KeyResultUid;
  readonly status?: OkrStatus | null;
  readonly disabled?: boolean;
}>;

export function DraggableKeyResult({
  children,
  disabled,
  id,
  status,
}: DraggableKeyResultProps): JSX.Element {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id,
  });

  const rowRef = useRef<HTMLTableRowElement>(null);
  const refs = useMergeRefs([rowRef, setNodeRef]);
  const [height, setHeight] = useState<number>(0);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    height: isDragging ? height : undefined,
  };

  useEffect(() => {
    function onResize() {
      if (rowRef.current && !isDragging) {
        setHeight(rowRef.current.getBoundingClientRect().height);
      }
    }
    const resizeObserver = new ResizeObserver(onResize);

    if (rowRef.current) {
      resizeObserver.observe(rowRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [isDragging]);

  return (
    <tr ref={refs} style={style}>
      {isDragging ? (
        <KeyResultsTableTd colSpan={9}>&nbsp;</KeyResultsTableTd>
      ) : (
        <>
          {disabled ? (
            <KeyResultsTableTd />
          ) : (
            <KeyResultsTableTd>
              <KeyResultDragHandle {...attributes} {...listeners} tabIndex={-1}>
                <Icon size={IconSize.s16}>
                  <Grid />
                </Icon>
              </KeyResultDragHandle>
            </KeyResultsTableTd>
          )}
          {children}
        </>
      )}
    </tr>
  );
}
