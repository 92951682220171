export function appendDefaultSearchParams(
  searchParams: string | undefined,
  defaultParams: string
): string {
  const urlSearchParams = new URLSearchParams(searchParams);
  const defaults = new URLSearchParams(defaultParams);

  for (const defaultParam of defaults.entries()) {
    const [key, value] = defaultParam;
    if (!urlSearchParams.has(key)) {
      urlSearchParams.set(key, value);
    }
  }

  return urlSearchParams.toString();
}
