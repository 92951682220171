import { TableFilterType, TableFilterValue } from '@yarmill/types';

export function formatFilterValue(
  value: TableFilterValue,
  type: TableFilterType
): TableFilterValue {
  if (type === 'date' || type === 'time') {
    if (
      typeof value === 'object' &&
      'type' in value &&
      value.type === 'range'
    ) {
      if (!value.from && !value.to) {
        return '';
      } else if (!value.from) {
        return { type: 'lessorequal', values: [value.to] };
      } else if (!value.to) {
        return { type: 'greaterorequal', values: [value.from] };
      }
    } else if (
      typeof value === 'object' &&
      'values' in value &&
      !(value.values as string[])[0]
    ) {
      return '';
    }
  }

  return value;
}
