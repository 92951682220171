import styled from 'styled-components';

import { ExternalIcon, Icon, IconSize } from '@yarmill/components';
import { BaseWorkoutData } from '@yarmill/components';

const StyledTooltipRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledValue = styled.span`
  display: inline-block;
  margin: 0 8px;
`;

export function AltitudeAndDistanceTooltip(item: BaseWorkoutData): JSX.Element {
  const isNumber = typeof item.altitude === 'number';
  const altitude = isNumber ? (item.altitude as number) : 0;

  return (
    <StyledTooltipRow>
      <Icon size={IconSize.s14}>
        <ExternalIcon name="Mountain" />
      </Icon>
      <StyledValue>
        {altitude < 1000 && <>&nbsp;</>}
        {altitude < 100 && <>&nbsp;</>}
        {altitude < 10 && <>&nbsp;</>}
        {isNumber ? altitude.toFixed(1) : '---'}
      </StyledValue>
      M
    </StyledTooltipRow>
  );
}

export function HeartRateTooltip(item: BaseWorkoutData): JSX.Element {
  return (
    <StyledTooltipRow>
      <Icon size={IconSize.s14}>
        <ExternalIcon name="Heart" />
      </Icon>
      <StyledValue>
        {Number(item.hr) < 100 && <>&nbsp;</>}
        {Number(item.hr) < 10 && <>&nbsp;</>}
        {item.hr ?? '---'}
      </StyledValue>
      BPM
    </StyledTooltipRow>
  );
}
