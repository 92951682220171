import { Text, TextSize } from '@yarmill/components';
import { isSumColumnType, SumColumnType } from './types';
import { formatNumberValue } from './utils';
import { useAttendanceSumRowItemValue, useEnabledDaysForGroup } from './hooks';
import { observer } from 'mobx-react-lite';

export type SumItemType = 'present' | 'missed' | 'percentage';

export interface PassedSumRowItemProps {
  column: string | SumItemType;
}

type SumItemProps = PassedSumRowItemProps;

const formatValueByType = (
  value: number,
  type: SumColumnType,
  enabledDays: number
): number | string => {
  switch (type) {
    case 'missed':
      return enabledDays - value;
    case 'percentage':
      return `${formatNumberValue(((value || 0) / (enabledDays || 1)) * 100)}%`;
    default:
      return value;
  }
};

export const SumRowItem = observer(function SumRowItem(
  props: SumItemProps
): JSX.Element {
  const { column } = props;
  const value = useAttendanceSumRowItemValue(column);
  const enabledDays = useEnabledDaysForGroup(column);
  const formattedValue = isSumColumnType(column)
    ? formatValueByType(value, column, enabledDays)
    : value;

  return (
    <Text size={TextSize.s14}>{enabledDays !== 0 ? formattedValue : '-'}</Text>
  );
});
