import { getTicks } from '../helpers';
import { BaseWorkoutChart, BaseWorkoutData } from '../base-workout-chart';
import { ReactNode } from 'react';
import { SyncTooltipHandlers } from '../base-workout-chart/tooltip';

export interface AltitudeAndDistanceProps {
  data: BaseWorkoutData[];
  height: number;
  showTooltip?: boolean;
  syncId?: string;
  width: number;
  total: number;
  xAxisKey?: 'time' | 'distance';
  formatXAxisTicks?(value: number): string;
  formatTooltip?(item: BaseWorkoutData): ReactNode;
  formatTooltipCursor?(value: number): string;
  syncTooltipHandlers?: SyncTooltipHandlers;
  setSyncTooltipHandlers?: (handlers: SyncTooltipHandlers) => () => void;
}

function getDomain(data: BaseWorkoutData[]): [number, number] {
  const altitudeData = data
    .map(item => item.altitude)
    .filter(item => item !== null && item !== undefined) as number[];

  const min = Math.min(...altitudeData);
  const max = Math.max(...altitudeData);

  const domain: [number, number] = [min - min * 0.1, max + max * 0.1];

  return domain;
}

const CHART_MARGIN = {
  left: 60,
  right: 35,
  bottom: 20,
  top: 5,
};

export function AltitudeAndDistance(
  props: AltitudeAndDistanceProps
): JSX.Element {
  const { xAxisKey = 'time', total, data } = props;
  const domain = getDomain(data);

  return (
    <BaseWorkoutChart
      {...props}
      id="altitude"
      domain={domain}
      yAxisKey="altitude"
      showLeftAxis
      showBottomAxis
      showGridRows
      marginConfig={CHART_MARGIN}
      xAxisTicks={getTicks(xAxisKey, total)}
    />
  );
}
