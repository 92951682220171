export function getFileNameWithoutExtension(fileName: string): string {
  // Split the file name by periods (.)
  const parts = fileName.split('.');

  if (fileName === '.') {
    return '';
  }

  // If there are no periods in the file name or the file name ends with a period,
  // return the original file name
  if (parts.length === 1 || fileName.endsWith('.')) {
    return fileName;
  }

  // The file name without extension is all parts except the last one joined by periods
  const nameWithoutExtension = parts.slice(0, -1).join('.');

  return nameWithoutExtension;
}
