import { Group } from '@visx/group';
import { useXYChartContext } from '../xy-chart-context';
import { Bar } from '@visx/shape';
import { useTooltipContext } from '../hooks/use-tooltip';

export function HorizontalCategoricalTooltip() {
  const { yScale, chartRect } = useXYChartContext();
  const tooltipContext = useTooltipContext();

  if (!('bandwidth' in yScale)) {
    throw new Error('Categorical tooltip requires categorical scale');
  }

  const bandwidth = yScale.bandwidth();
  const step = yScale.step();
  const categories = yScale.domain();
  const activeCategory = tooltipContext.tooltipData?.key;

  return (
    <Group>
      {categories.map(category => {
        const barY = (yScale(category) || 0) - (step - bandwidth) / 2;

        return (
          <Bar
            fill={
              String(activeCategory) === String(category)
                ? 'rgba(0, 0, 0, 0.05)'
                : 'transparent'
            }
            key={category}
            height={step}
            x={chartRect.left}
            y={barY}
            width={chartRect.right - chartRect.left}
          />
        );
      })}
    </Group>
  );
}
