import { styled } from '@yarmill/components';

export const StyledButtonsWrapper = styled.div`
  margin-top: 30px;

  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledSubmitWrapper = styled.div`
  margin-top: 20px;

  button {
    width: 100%;
  }

  @media (min-width: 769px) {
    margin-top: 0;
    margin-left: 20px;

    button {
      width: auto;
    }
  }
`;

export const AttachmentLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const AttachmentsFormFieldWrapper = styled.div`
  padding-bottom: 24px;
`;
