import { HorizontalStackedBarChart } from '../types';
import { BarStackHorizontal as VisxBarStackHorizontal } from '@visx/shape';
import { useXYChartContext } from '../xy-chart-context';
import { DataItem } from '../../reporting/types';
import { HorizontalBarsStacks } from './primitives/horizontal-bars-stacks';

interface StackedBarsProps {
  readonly config: HorizontalStackedBarChart;
  readonly data: DataItem[];
}

const noop = () => '';

export function HorizontalStackedBars({ config, data }: StackedBarsProps) {
  const { xScale, yScale } = useXYChartContext();

  return (
    <VisxBarStackHorizontal<DataItem, string>
      data={data}
      keys={config.keys}
      y={config.getYValue}
      xScale={xScale}
      yScale={yScale}
      color={noop}
    >
      {stacks => (
        <HorizontalBarsStacks config={config} stacks={stacks} data={data} />
      )}
    </VisxBarStackHorizontal>
  );
}
