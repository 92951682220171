import { GlobalIntl } from '@yarmill/components';
import {
  Command,
  ModuleCode,
  ModuleDefinitionStore,
  NavigationLink,
  RootStore,
} from '@yarmill/types';
import { appendDefaultSearchParams } from '@yarmill/utils';
import { computed, makeObservable } from 'mobx';
import { createDashboardsByPermissionFilter } from '../utils/create-dashboards-by-permission-filter';
import { findActiveReportingModulePage } from '../utils/find-active-reporting-module-page';
import { ReportingStore } from './reporting-store';

export class ReportingModuleDefinitionStore implements ModuleDefinitionStore {
  readonly moduleCode: ModuleCode = 'reporting';
  private readonly _rootStore: RootStore;
  private readonly _reportingStore: ReportingStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    this._reportingStore = new ReportingStore(this._rootStore);
    makeObservable(this);
  }

  get reportingStore() {
    return this._reportingStore;
  }

  @computed
  get navigationLinks(): NavigationLink[] {
    const reportingConfig = this._rootStore.modulesStore.reporting;
    const currentUser = this._rootStore.currentUserStore;

    const reportingPages = reportingConfig
      // Find first dashboard on page that user is allowed to see
      .map(module => ({
        title: module.Title,
        isLegacyAnalytics: module.LegacyAnalytics,
        dashboard: module.Dashboards.find(
          createDashboardsByPermissionFilter(
            currentUser,
            module.LegacyAnalytics
          )
        ),
      }))
      // Get rid of pages without dashboards
      .filter(item => item.dashboard)
      .map<NavigationLink>(item => ({
        label: item.title,
        icon: 'ChartLine',
        moduleCode: `reporting.${item.dashboard?.ReportPageCode}`,
        createClickHandler: defaultSearchParams => location => {
          const searchParams = appendDefaultSearchParams(
            location.search,
            defaultSearchParams
          );
          const prefix = item.isLegacyAnalytics ? 'analytics' : 'reporting';
          return `/${prefix}/${item.dashboard?.ReportPageCode}?${searchParams}`;
        },
        isActive: pathname => {
          if (item.isLegacyAnalytics && !pathname.includes('analytics')) {
            return false;
          }
          if (!item.isLegacyAnalytics && !pathname.includes('reporting')) {
            return false;
          }

          const [, , pageCode] = pathname.split('/');

          const activeModulePage = findActiveReportingModulePage(
            reportingConfig,
            pageCode
          );

          return activeModulePage?.Title === item.title;
        },
      }));

    return [
      {
        label: 'header.navigation.analytics', // todo: replace with reporting
        icon: 'ChartLine',
        moduleCode: 'reporting',
        children: reportingPages,
        isActive: pathname =>
          pathname.startsWith('/reporting') ||
          pathname.startsWith('/analytics'),
      },
    ];
  }

  @computed
  get commandPaletteCommands(): Command[] {
    const historyService = this._rootStore.historyService;
    const currentUser = this._rootStore.currentUserStore;
    const reportingConfig = this._rootStore.modulesStore.reporting;
    const definitionStore = this._reportingStore.definitionStore;

    const reportingDashboards = reportingConfig
      .flatMap(page =>
        page.Dashboards.map(dashboard => ({
          ...dashboard,
          isLegacyAnalytics: page.LegacyAnalytics,
        }))
      )
      .filter(dashboard =>
        createDashboardsByPermissionFilter(
          currentUser,
          dashboard.isLegacyAnalytics
        )
      );

    return reportingDashboards
      .map<Command | null>(dashboard => {
        const reportingPage = definitionStore?.pages.get(
          dashboard.ReportPageCode
        );
        if (!reportingPage) {
          return null;
        }

        const command: Command = {
          priority: 1,
          icon: 'ChartLine',
          name: GlobalIntl.formatMessage(
            {
              id: `commandPalette.goToReport`,
            },
            {
              report: GlobalIntl.formatMessage({ id: reportingPage.title }),
            }
          ),
          createCommand: (defaultSearchParams: string) => () => {
            historyService.history.push(
              `/reporting/${reportingPage.code}?${defaultSearchParams}`
            );
          },
        };

        return command;
      })
      .filter((c): c is Command => Boolean(c));
  }
}
