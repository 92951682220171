import { useScale } from '../hooks/use-scale';

export function invertScale(scale: ReturnType<typeof useScale>, value: number) {
  if ('invert' in scale) {
    return scale.invert(value);
  } else {
    const domain = scale.domain();
    const paddingOuter = scale(domain[0]) ?? 0;
    const eachBand = scale.step();
    const index = Math.abs(Math.floor((value - paddingOuter) / eachBand));
    return domain[Math.max(0, Math.min(index, domain.length - 1))];
  }
}
