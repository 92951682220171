import { Editor } from '@tiptap/react';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Tippy } from '../tippy';
import { BaseButton, Button } from './menu-buttons/button';
import { HighlightButton } from './menu-buttons/highlight-button';
import { LinkButton } from './menu-buttons/link-button';
import { OtherButton } from './menu-buttons/other-button';
import { VideoLinkButton } from './menu-buttons/video-link-button';

import { useRootStore } from '@yarmill/utils';
import { FormControlVariant } from '../helpers';
import { RichtextMenuBar, RichtextStickyControls } from './components';
import { Bold } from './icons/bold';
import { Clean } from './icons/clean';
import { Italic } from './icons/italic';
import { LetterCase } from './icons/letter-case';
import { ListBullet } from './icons/list-bullet';
import { ListOrdered } from './icons/list-ordered';
import { Strikethrough } from './icons/strikethrough';
import { Underline } from './icons/underline';

interface RichtextMenuBarProps {
  readonly editor: Editor | null;
  readonly variant?: FormControlVariant;
  readonly showToolbarByDefault?: boolean;
  readonly enableVideos?: boolean;
  readonly showControls: boolean;
  readonly containerWidth: number;
}

const MEMORY_STORE_PERSIST_KEY = 'richtext-menu-bar';
export function MenuBar(props: RichtextMenuBarProps): JSX.Element | null {
  const {
    editor,
    variant,
    showToolbarByDefault,
    enableVideos,
    showControls,
    containerWidth,
  } = props;
  const intl = useIntl();
  const memoryStore = useRootStore().memoryStore;
  const isVisible =
    memoryStore.getItem<boolean>(MEMORY_STORE_PERSIST_KEY) ??
    Boolean(showToolbarByDefault);
  const [keepControlsVisible, setKeepControlsVisible] = useState(0);

  if (!editor) {
    return null;
  }

  const label = `richtext.toolbar.${isVisible ? 'hide' : 'show'}Toolbar`;

  return (
    <>
      <RichtextMenuBar
        isVisible={isVisible && (showControls || Boolean(keepControlsVisible))}
        variant={variant}
      >
        <EditorControls
          editor={editor}
          enableVideos={enableVideos}
          setKeepControlsVisible={setKeepControlsVisible}
          containerWidth={containerWidth}
          isVisible={isVisible && showControls}
        />
      </RichtextMenuBar>
      <RichtextStickyControls isMenuVisible={isVisible}>
        <Tippy tooltipContent={label} noWrapper touch={false}>
          <Button
            type="button"
            onMouseDown={e => {
              e.preventDefault();
              e.stopPropagation();
              editor?.chain().focus().run();
              memoryStore.setItem(MEMORY_STORE_PERSIST_KEY, !isVisible);
            }}
            aria-label={intl.formatMessage({ id: label })}
            tabIndex={-1}
            key="controls-toggle"
            isHidden={!showControls && !keepControlsVisible}
            noHoverBg
          >
            <LetterCase />
          </Button>
        </Tippy>
      </RichtextStickyControls>
    </>
  );
}

const BUTTON_WIDTH = 30;
const SHOW_CONTROLS_BUTTON_WIDTH = 28;
function EditorControls({
  editor,
  enableVideos,
  setKeepControlsVisible,
  containerWidth,
}: {
  readonly editor: Editor | null;
  readonly enableVideos?: boolean;
  readonly setKeepControlsVisible: (visible: (prev: number) => number) => void;
  readonly containerWidth: number;
  readonly isVisible: boolean;
}): JSX.Element | null {
  const clearAll = useCallback(
    (focus: boolean) => {
      const command = editor?.chain();

      if (focus) {
        command?.focus();
      }

      return command?.unsetAllMarks();
    },
    [editor]
  );

  if (!editor) {
    return null;
  }
  const maxButtons = Math.floor(
    (containerWidth - SHOW_CONTROLS_BUTTON_WIDTH) / BUTTON_WIDTH
  );
  const allButtons = [
    <BaseButton
      tabIndex={-1}
      key="bold"
      name="bold"
      onMouseDown={() => editor.chain().focus().toggleBold().run()}
      isActive={editor.isActive('bold')}
    >
      <Bold />
    </BaseButton>,
    <BaseButton
      tabIndex={-1}
      key="italic"
      name="italic"
      onMouseDown={() => editor.chain().focus().toggleItalic().run()}
      isActive={editor.isActive('italic')}
    >
      <Italic />
    </BaseButton>,
    <BaseButton
      tabIndex={-1}
      key="underline"
      name="underline"
      onMouseDown={() => editor.chain().focus().toggleUnderline().run()}
      isActive={editor.isActive('underline')}
    >
      <Underline />
    </BaseButton>,
    <BaseButton
      tabIndex={-1}
      key="strike"
      name="strike"
      onMouseDown={() => editor.chain().focus().toggleStrike().run()}
      isActive={editor.isActive('strike')}
    >
      <Strikethrough />
    </BaseButton>,
    <HighlightButton
      tabIndex={-1}
      key="highlight"
      editor={editor}
      setKeepControlsVisible={setKeepControlsVisible}
    />,
    <BaseButton
      tabIndex={-1}
      key="orderedList"
      name="orderedList"
      onMouseDown={() => editor.chain().focus().toggleOrderedList().run()}
      isActive={editor.isActive('orderedList')}
    >
      <ListOrdered />
    </BaseButton>,
    <BaseButton
      tabIndex={-1}
      key="bulletList"
      name="bulletList"
      onMouseDown={() => editor.chain().focus().toggleBulletList().run()}
      isActive={editor.isActive('bulletList')}
    >
      <ListBullet />
    </BaseButton>,
    <LinkButton
      key="link"
      tabIndex={-1}
      editor={editor}
      setKeepControlsVisible={setKeepControlsVisible}
    />,
    enableVideos && (
      <VideoLinkButton
        key="videos"
        tabIndex={-1}
        editor={editor}
        setKeepControlsVisible={setKeepControlsVisible}
      />
    ),
    <BaseButton
      tabIndex={-1}
      key="clean"
      name="clean"
      onMouseDown={() => clearAll(true)?.run()}
    >
      <Clean />
    </BaseButton>,
  ].filter(Boolean) as JSX.Element[];

  const visibleButtons =
    maxButtons < allButtons.length
      ? allButtons.slice(0, maxButtons - 1)
      : allButtons;
  const otherButtons = allButtons.slice(maxButtons - 1);

  return (
    <>
      {visibleButtons.map(btn => btn)}
      {maxButtons < allButtons.length && (
        <OtherButton
          buttons={otherButtons}
          editor={editor}
          setKeepControlsVisible={setKeepControlsVisible}
          isWide={otherButtons.length > visibleButtons.length + 1}
        />
      )}
    </>
  );
}
