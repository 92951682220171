import styled, { css } from 'styled-components';

export const SVG_SIZE = 20;
export const STROKE_WIDTH = 2;

export const StyledSvg = styled.svg<{ spin?: boolean }>`
  width: ${SVG_SIZE}px;
  height: ${SVG_SIZE}px;
  ${props =>
    props.spin &&
    css`
      animation: spin 1s linear infinite;

      @keyframes spin {
        0% {
          transform: rotateZ(0deg);
        }

        100% {
          transform: rotateZ(360deg);
        }
      }
    `}
`;

export function CrossIcon(): JSX.Element {
  return (
    <g transform="scale(1.3) translate(-3, -3)">
      <path
        d="M11.586 13l-2.293 2.293a1 1 0 0 0 1.414 1.414L13 14.414l2.293 2.293a1 1 0 0 0 1.414-1.414L14.414 13l2.293-2.293a1 1 0 0 0-1.414-1.414L13 11.586l-2.293-2.293a1 1 0 0 0-1.414 1.414L11.586 13z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  );
}
