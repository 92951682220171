import { RootStore } from '@yarmill/types';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { DiaryDataUniqueId } from '../../diary/types';
import { TrainingDayAttribute } from '../../training-day/types';
import { GoalStore } from './goal-store';

export class SeasonGoalStore extends GoalStore {
  private readonly _currentDate: string;
  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    index: number,
    attributes: TrainingDayAttribute[],
    date: string,
    dataId: DiaryDataUniqueId
  ) {
    super(rootStore, diaryStore, index, attributes, dataId);
    this._currentDate = date;
  }

  get currentDate(): string {
    return this._currentDate;
  }
}
