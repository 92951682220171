import { axios } from '@yarmill/utils';
import type { AxiosPromise, CancelTokenSource } from 'axios';
import { GetReportPageDataResponse } from '../types';

export function getReportPageData(
  dataUrl: string,
  reportPageCode: string,
  filters: Record<string, string>,
  cancelToken: CancelTokenSource
): AxiosPromise<GetReportPageDataResponse> {
  return axios.get(dataUrl, {
    params: {
      reportPageCode,
      filters: JSON.stringify(filters),
    },
    cancelToken: cancelToken.token,
  });
}
