import { styled } from '@yarmill/components';

export const VerticalFlexSpacer = styled.div`
  display: flex;
  flex-shrink: initial;
  flex-basis: initial;
  flex-direction: row;
  -webkit-box-flex: 1;
  flex-grow: 1;
`;

export const FlexCenterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
