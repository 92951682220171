import { AsyncStatus, RequestStore, RootStore } from '@yarmill/types';
import { makeObservable, observable, runInAction } from 'mobx';
import { getReportsDefinition } from '../api/get-reports-definition';
import { ReportPage } from '../types';
import { ReportingPageStore } from './reporting-page-store';

export class ReportsDefinitionStore {
  private readonly rootStore: RootStore;
  private readonly definitionUrl: string;

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;
  @observable
  private _request: RequestStore<ReportPage[]> | null = null;
  @observable
  private _pages: Map<string, ReportingPageStore> = new Map();

  constructor(rootStore: RootStore, definitionUrl: string) {
    this.rootStore = rootStore;
    this.definitionUrl = definitionUrl;
    makeObservable(this);
  }

  public async loadDefinition(): Promise<void> {
    this._status = AsyncStatus.pending;
    const transaction = this.rootStore.navbarStore.createTransaction(
      'loadingData',
      'reporting'
    );
    this._request = this.rootStore.requestsStore.createRequest(() =>
      getReportsDefinition(this.definitionUrl)
    );

    const response = await this._request.getResponse();
    if (response) {
      runInAction(() => {
        response.forEach(page => {
          this._pages.set(
            page.ReportPageCode,
            new ReportingPageStore(this.rootStore, page)
          );
        });
        this._status = AsyncStatus.resolved;
        transaction.finished();
      });
    } else {
      this._status = AsyncStatus.resolved;
    }
  }

  public get pages(): Map<string, ReportingPageStore> {
    return this._pages;
  }

  public get status(): AsyncStatus {
    return this._status;
  }
}
