import { KeyResultsTableTh } from './components/detail/key-results-list';

export function KeyResultsTableFooter(): JSX.Element {
  return (
    <>
      <tfoot>
        <tr>
          <KeyResultsTableTh />
          <KeyResultsTableTh />
          <KeyResultsTableTh />
          <KeyResultsTableTh />
          <KeyResultsTableTh />
          <KeyResultsTableTh />
          <KeyResultsTableTh />
          <KeyResultsTableTh />
          <KeyResultsTableTh />
        </tr>
      </tfoot>
    </>
  );
}
