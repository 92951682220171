import { Group } from '@visx/group';
import { useXYChartContext } from '../xy-chart-context';
import { Bar } from '@visx/shape';
import { useTooltipContext } from '../hooks/use-tooltip';

export function CategoricalTooltip() {
  const { xScale, chartRect } = useXYChartContext();
  const tooltipContext = useTooltipContext();

  if (!('bandwidth' in xScale)) {
    throw new Error('Categorical tooltip requires categorical scale');
  }

  const bandwidth = xScale.bandwidth();
  const step = xScale.step();
  const categories = xScale.domain();
  const activeCategory = tooltipContext.tooltipData?.key;

  return (
    <Group>
      {categories.map(category => {
        const barX = (xScale(category) || 0) - (step - bandwidth) / 2;

        return (
          <Bar
            fill={
              String(activeCategory) === String(category)
                ? 'rgba(0, 0, 0, 0.05)'
                : 'transparent'
            }
            key={category}
            width={step}
            y={chartRect.top}
            x={barX}
            height={chartRect.bottom - chartRect.top}
          />
        );
      })}
    </Group>
  );
}
