import { toast } from '@yarmill/components';
import { useLogger, useYollandaService } from '@yarmill/utils';
import { MouseEvent, useCallback, useState } from 'react';
import { YollandaInteractionId } from '../types';

export function useSendFeedbackHandler(
  interactionId: YollandaInteractionId
): [(e: MouseEvent<HTMLButtonElement>) => void, number | null] {
  const yollandaService = useYollandaService();
  const logger = useLogger();
  const [sentFeedback, setSentFeedback] = useState<number | null>(null);
  return [
    useCallback(
      async (e: MouseEvent<HTMLButtonElement>) => {
        if (e.target instanceof HTMLButtonElement) {
          const qualityResponse = e.target.value;
          setSentFeedback(
            feedback => (feedback ?? 0) + Number(qualityResponse)
          );

          toast('toast.success.yollandaFeedback', 'success', {
            qualityResponse,
          });

          try {
            const response = await yollandaService.sendFeedback(
              interactionId,
              Number(qualityResponse)
            );
            if (!(response.status >= 200 && response.status < 300)) {
              toast('toast.error.yollandaFeedback', 'error', {
                qualityResponse,
              });
            }
          } catch (e) {
            logger.error('Sending yollanda feedback failed', e);
            toast('toast.error.yollandaFeedback', 'error', {
              qualityResponse,
            });
          }
        }
      },
      [interactionId, logger, yollandaService]
    ),
    sentFeedback,
  ];
}
