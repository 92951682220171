import { ReadinessScoreData } from '../../training-day/types';
import { ExternalIcon, HealthDataOverviewRow } from '@yarmill/components';
import { FormattedMessage, useIntl } from 'react-intl';

export interface ReadinessScoreProps {
  value: ReadinessScoreData;
}
export function ReadinessScore(props: ReadinessScoreProps): JSX.Element {
  const intl = useIntl();
  const { value } = props;
  const validatedValue = {
    Score: '-',
    Label: '-',
    ...value,
    ScoreValue: value.ScoreValue
      ? intl.formatMessage({
          id: `healthData.readinessScore.scoreValue.${value.ScoreValue}`,
        })
      : '-',
  };

  return (
    <HealthDataOverviewRow
      icon={<ExternalIcon name="Seeding" />}
      label={
        value.Label ? <FormattedMessage id={value.Label} /> : 'Missing Label'
      }
      primaryValue={
        <FormattedMessage
          id="healthData.readinessScore.value"
          values={validatedValue}
        />
      }
    />
  );
}
