import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from '@yarmill/const';
import { CommonUrlParams } from '@yarmill/types';
import { useMemo } from 'react';
import { useLocation } from './use-location';

export function useCommonUrlParams(): CommonUrlParams {
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const group = params.get(GROUP_SEARCH_PARAM);
  const athlete = params.get(ATHLETE_SEARCH_PARAM);
  const groupId = group ? parseInt(group, 10) : null;
  const athleteId = athlete ? parseInt(athlete, 10) : null;

  return useMemo(() => {
    return {
      group: groupId,
      athlete: athleteId,
    };
  }, [groupId, athleteId]);
}
