import {
  BreakpointWrapper,
  Checkbox,
  CheckboxProps,
  MaskSelector,
  SeasonSelector,
  Status,
  styled,
} from '@yarmill/components';
import { SeasonEvaluationDiaryType } from '@yarmill/types';
import { useCurrentUserStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { ExportSingleSeasonEvaluationButton } from './export-single-season-evaluation-button';
import { useSeasonEvaluationStore } from './season-evaluation-store-context';
import { ViewSelector } from './view-selector';

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 25px;
`;

const LeftControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 25px;
`;

const RightConstrolsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 15px;
  column-gap: 25px;
`;

const CheckboxWrapper = styled.div`
  white-space: nowrap;
`;

const DiaryTypesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    width: unset;
    column-gap: 40px;
  }
`;

export const Controls = observer(function Controls(): JSX.Element {
  const intl = useIntl();
  const seasonEvaluationStore = useSeasonEvaluationStore();
  const currentUser = useCurrentUserStore();
  const singleSeasonStore = seasonEvaluationStore.singleSeasonEvaluationStore;
  const enabledDiaryTypes = singleSeasonStore?.enabledDiaryTypes;
  const checkboxes = singleSeasonStore?.availableDiaryTypes;
  const handleCheckbox = React.useCallback(
    (_: React.SyntheticEvent<HTMLElement>, checkboxProps: CheckboxProps) => {
      const diaryType = checkboxProps.value as SeasonEvaluationDiaryType;
      singleSeasonStore?.toggleDiaryType(diaryType);
    },
    [singleSeasonStore]
  );

  return (
    <ControlsContainer>
      <LeftControlsContainer>
        <ViewSelector />
        {seasonEvaluationStore.viewType === 'single-season' && (
          <>
            <SeasonSelector />
            <DiaryTypesContainer>
              {checkboxes?.map(type => (
                <CheckboxWrapper key={type}>
                  <Checkbox
                    value={type}
                    label={intl.formatMessage({
                      id: `seasonEvaluation.type.${type}`,
                    })}
                    onChange={handleCheckbox}
                    checked={enabledDiaryTypes?.includes(type)}
                    narrow
                  />
                </CheckboxWrapper>
              ))}
            </DiaryTypesContainer>
          </>
        )}
      </LeftControlsContainer>
      <BreakpointWrapper max={991}>
        <MaskSelector
          currentMaskCode={seasonEvaluationStore.currentMaskCode}
          groupId={seasonEvaluationStore.groupId}
          userId={seasonEvaluationStore.athleteId}
          onMaskChange={seasonEvaluationStore.setMaskCode}
        />
        {seasonEvaluationStore.viewType === 'single-season' &&
          currentUser.isAllowedTo(
            'seasonEvaluation.singleSeasonEvaluation.export'
          ) && <ExportSingleSeasonEvaluationButton />}
      </BreakpointWrapper>
      <BreakpointWrapper min={991}>
        <RightConstrolsWrapper>
          <Status />
          <MaskSelector
            currentMaskCode={seasonEvaluationStore.currentMaskCode}
            groupId={seasonEvaluationStore.groupId}
            userId={seasonEvaluationStore.athleteId}
            onMaskChange={seasonEvaluationStore.setMaskCode}
          />
          {seasonEvaluationStore.viewType === 'single-season' &&
            currentUser.isAllowedTo(
              'seasonEvaluation.singleSeasonEvaluation.export'
            ) && <ExportSingleSeasonEvaluationButton />}
        </RightConstrolsWrapper>
      </BreakpointWrapper>
    </ControlsContainer>
  );
});
