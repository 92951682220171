import { Text } from '@visx/text';
import { LABEL_FONT_SIZE } from '../../reporting/const';
import { DataLabelDefinition, DataLabelPositionParamenters } from '../types';

interface DataLabelProps {
  readonly label: DataLabelDefinition & DataLabelPositionParamenters;
}
export function DataLabel({ label }: DataLabelProps) {
  return (
    <Text
      x={label.x}
      y={label.y}
      width={Math.abs(label.angle ?? 0) === 90 ? undefined : label.maxWidth}
      verticalAnchor="middle"
      textAnchor="middle"
      angle={label.angle}
      fontSize={Math.min(LABEL_FONT_SIZE, label.maxHeight ?? LABEL_FONT_SIZE)}
      fill={label.color || '#4a4a4a'}
      pointerEvents="none"
      scaleToFit="shrink-only"
    >
      {label.text}
    </Text>
  );
}
