import { AggregationFunction, DisabledReason, Units } from '@yarmill/types';
import moment from 'moment';

export function parseValueByUnit(value: string, unit: Units): number {
  if (!value) {
    return 0;
  }
  const split = value.split(':');
  const val = { hours: Number(split[0]) || 0, minutes: Number(split[1]) || 0 };

  switch (unit) {
    case Units.hod:
      return moment.duration(val).asHours();
    case Units.min:
      return moment.duration(val).asMinutes();
    default:
      return 0;
  }
}

export function computeAggregationFunction(
  aggFunction: AggregationFunction | null,
  values: number[]
): number {
  switch (aggFunction) {
    case AggregationFunction.SGN:
      return sign(values);
    case AggregationFunction.AVG: {
      const filledValues = values.filter(value => value);

      return round(avg(filledValues));
    }
    case AggregationFunction.PROD:
      return prod(values);
    default:
      return sum(values);
  }
}

export function sum(values: number[]): number {
  return values.reduce((total, item) => total + item, 0);
}

export function avg(values: number[]): number {
  return sum(values) / values.length;
}

export function sign(values: number[]): number {
  return Math.sign(sum(values));
}

export function prod(values: number[]): number {
  return values.reduce((total, item) => total * item, 1);
}

export function round(value: number): number {
  // Round value to 2 decimals
  return Math.floor(value * 100) / 100;
}

export function getTabIndex(rowIndex: number, columnIndex: number): number {
  return Number(`${columnIndex + 10}${rowIndex + 10}`);
}

export function getDisabledReasonMessage(
  reason?: DisabledReason | null
): string {
  if (!reason) return '';

  return `activityItem.disabled.${reason}`;
}
