import {
  AttributesLayout,
  FontSize,
  Locale,
  PdfOrientation,
  TrainingDayAttributeId,
  UserGroupId,
  UserId,
} from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';

export interface ExportDiaryAttributesToPdfRequestParams {
  language: Locale;
  state: 'P' | 'R';
  startDate: string;
  endDate: string;
  userId: UserId | undefined;
  userGroupId: UserGroupId | undefined;
  orientation: PdfOrientation;
  attributeIds: TrainingDayAttributeId[];
  fontSize: FontSize;
  layout: AttributesLayout;
  showAttributeLabels: boolean;
}

export function exportDiaryAttributesToPdf(
  params: ExportDiaryAttributesToPdfRequestParams,
  cancelToken: CancelTokenSource
): AxiosPromise<BlobPart> {
  return axios.post(`api/print/diaryAttributes`, params, {
    responseType: 'blob',
    cancelToken: cancelToken.token,
  });
}
