import { times } from '@yarmill/utils';
import styled from 'styled-components';

export interface RowLinesProps {
  count: number;
  numberOfColumns: number;
  hasHeader: boolean;
}

interface RowLineProps {
  row: number;
  numberOfColumns: number;
}

const RowLineWrapper = styled.svg<RowLineProps>`
  grid-row: ${props => props.row};
  grid-column: ${props => `1 / ${props.numberOfColumns + 1}`};
  width: 100%;
  height: 1px;
  align-self: center;
`;

const RowLine = styled.line`
  stroke-dasharray: 2;
  stroke-dashoffset: 4;
  stroke-width: 1;
  stroke: #e2e4e7;
`;

export function RowLines(props: RowLinesProps): JSX.Element {
  const { count, numberOfColumns, hasHeader } = props;

  return (
    <>
      {times(count).map((_, idx) => (
        <RowLineWrapper
          key={idx}
          row={hasHeader ? idx + 2 : idx + 1}
          numberOfColumns={numberOfColumns}
          aria-hidden="true"
          focusable="false"
        >
          <RowLine x1={0} x2="100%" y1={0} y2={0} />
        </RowLineWrapper>
      ))}
    </>
  );
}
