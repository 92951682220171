import { FileLink, styled } from '@yarmill/components';

export const EvidenceTableAttachmentsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: fit-content;
  margin-top: -2px;
  margin-bottom: -2px;
`;

export const AttachmentTag = styled(FileLink)<{ readonly showName?: boolean }>`
  height: 24px;
  min-height: 20px;
  max-width: 100%;
  display: grid;
  grid-template-columns: ${({ showName }) => (showName ? '24px auto' : '24px')};
  align-items: center;
  gap: 4px;
  background-color: rgb(230, 240, 251);
  border-radius: 4px;
  padding-right: ${({ showName }) => (showName ? '4px' : 0)};
`;
