import { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledChartWidthProvider = styled.div<{
  readonly useStaticPosition?: boolean;
}>`
  position: ${({ useStaticPosition }) =>
    useStaticPosition ? 'static' : 'absolute'};
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  height: auto;
`;

export interface ChartWidthProviderProps {
  readonly useStaticPosition?: boolean;
  children?(width: number): ReactNode;
  setWidth?(width: number): void;
}

export function ChartWidthProvider(
  props: ChartWidthProviderProps
): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | null>(null);
  const passedSetWidth = props.setWidth;

  useEffect(() => {
    function reset(): void {
      setWidth(null);
    }

    if (typeof ResizeObserver === 'undefined') {
      window.addEventListener('resize', reset);
      window.addEventListener('orientationchange', reset);
    }

    return () => {
      window.removeEventListener('resize', reset);
      window.removeEventListener('orientationchange', reset);
    };
  }, []);

  useEffect(() => {
    function measure(): void {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        setWidth(rect.width);
        passedSetWidth?.(rect.width);
      }
    }

    if (width === null) {
      measure();
    }

    const observer = new ResizeObserver(measure);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [width, passedSetWidth]);

  return (
    <>
      <StyledChartWidthProvider
        ref={ref}
        useStaticPosition={props.useStaticPosition}
      />
      {width !== null && props.children?.(width)}
    </>
  );
}
