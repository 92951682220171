import { Logger } from '../logger';
import { HttpLogger } from '../logger/http-logger';

declare global {
  interface Window {
    ytd: {
      log: Record<string, Logger | HttpLogger | undefined>;
      [key: string]: any;
    };
  }
}

export function initGlobalYtdNamespace(): void {
  window['ytd'] = { log: {} };
}
