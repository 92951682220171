import { AxiosPromise } from 'axios';
import { axios } from '../axios';

export interface RemoveAttachmentRequest {
  attachmentId: number | string;
}

export function removeAttachment(
  params: RemoveAttachmentRequest
): AxiosPromise<boolean> {
  return axios.delete(`api/TrainingDayAttributeAttachment`, {
    params,
  });
}
