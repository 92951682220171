import { UserGroupId, UserId } from '@yarmill/types';
import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { PlannerEvent } from '../types';

export interface CreateEventRequestParams {
  userId: UserId | undefined;
  userGroupId: UserGroupId | undefined;
  event: PlannerEvent;
}

export function createEvent(
  params: CreateEventRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<PlannerEvent> {
  return axios.post(`${coreApiTarget}/api/planner`, params, {
    cancelToken: cancelToken?.token,
    params: {
      ...params,
      instanceCode: devMode ? instance : undefined,
    },
  });
}
