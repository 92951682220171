import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from '@yarmill/const';
import { useHistory, useLocation } from '@yarmill/utils';
import { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { trackChangeFilterClick } from '../google-analytics/utils';
import { LegacyAnalyticsStoreContext } from './legacy-analytics-store-context';
import { LegacyAnalyticsStore } from './mobx/legacy-analytics-store';
import {
  AnalyticsRouteParams,
  DashboardType,
  GROUP_URL_FILTER,
  USER_URL_FILTER,
} from './types';
import { setFilterValue } from './utils';

export function useAnalyticsRouteParamsParser(): AnalyticsRouteParams {
  const { dashboardType } = useParams<{
    dashboardType: DashboardType;
  }>();

  return useMemo(() => {
    return {
      activeDashboard: dashboardType as DashboardType,
    };
  }, [dashboardType]);
}

export function useUrlFilters(): Record<string, string> {
  const { search } = useLocation();

  return useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const params: Record<string, string> = {};

    searchParams.forEach((value, key) => {
      if (key === ATHLETE_SEARCH_PARAM) {
        params[USER_URL_FILTER] = value;
      } else if (key === GROUP_SEARCH_PARAM) {
        params[GROUP_URL_FILTER] = value;
      } else {
        params[key] = value;
      }
    });

    return params;
  }, [search]);
}

type FilterChangeHandler = (id: string, value: string) => void;
export function useFilterChangeHandler(): FilterChangeHandler {
  const history = useHistory();
  const { search, pathname } = useLocation();

  return useCallback(
    (id: string, value: string): void => {
      trackChangeFilterClick(id);
      const searchParams = new URLSearchParams(search);
      setFilterValue(searchParams, id, value);
      history.push(`${pathname}?${searchParams}`);
    },
    [history, pathname, search]
  );
}

export function useLegacyAnalyticsStore(): LegacyAnalyticsStore {
  const store = useContext(LegacyAnalyticsStoreContext);

  if (!store) {
    throw new Error('Legacy analytics store not provided');
  }

  return store;
}
