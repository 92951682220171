import {
  AthletesListWrapper,
  SidebarList,
  useTheme,
} from '@yarmill/components';
import { ATHLETE_SEARCH_PARAM } from '@yarmill/const';
import { Team } from '@yarmill/icons-2';
import { GroupStore } from '@yarmill/types';
import {
  generateUrl,
  persistSelectedAthlete,
  useCommonUrlParams,
  useCurrentUserStore,
  useSidebarStore,
  useUsersStore,
} from '@yarmill/utils';
import { Location } from 'history';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { AthleteLink } from './athlete-link';
import { NavigationItem } from './navigation-item';

export interface AthletesListProps {
  readonly group: GroupStore;
  readonly isVisible: boolean;
  readonly toggleGroups: () => void;
  readonly includeCoaches?: boolean;
  readonly hideCurrentUser?: boolean;
  readonly showWholeGroupItem?: boolean;
}

function generateRootPath(location: Location): string | undefined {
  if (location.pathname.includes('okr')) {
    return `${window.location.origin}/okr`;
  }
  return undefined;
}

export const AthletesList = observer(function AthletesList(
  props: AthletesListProps
): JSX.Element | null {
  const {
    group,
    isVisible,
    toggleGroups,
    includeCoaches,
    hideCurrentUser,
    showWholeGroupItem,
  } = props;
  const intl = useIntl();
  const usersStore = useUsersStore();
  const currentUser = useCurrentUserStore();
  const athletesInGroup = group.athletes;
  const allUsersInGroup = group.allUsers;
  const { athlete } = useCommonUrlParams();
  const selectedAthlete = usersStore.getUserById(athlete);
  const theme = useTheme();
  const groupLink = useCallback((location: Location) => {
    const searchParams = new URLSearchParams(location.search);
    const search = Object.fromEntries(searchParams.entries());
    const path = generateRootPath(location);
    return generateUrl(
      {
        ...search,
        [ATHLETE_SEARCH_PARAM]: null,
      },
      path
    );
  }, []);
  const sidebarStore = useSidebarStore();

  const usersToShow = includeCoaches ? allUsersInGroup : athletesInGroup;

  useEffect(() => {
    persistSelectedAthlete(selectedAthlete);
  }, [selectedAthlete]);

  const onListItemClick = useCallback(
    (_name: string): void => {
      toggleGroups();
      if (window.innerWidth < 768) {
        window.scrollTo({ top: 0 });
        if (theme.name === 'main') {
          sidebarStore.hide();
        }
      }
    },
    [toggleGroups, theme, sidebarStore]
  );

  return (
    <AthletesListWrapper isVisible={isVisible}>
      {usersToShow.length > 0 && (
        <SidebarList>
          {!showWholeGroupItem ? null : (
            <NavigationItem
              icon={<Team />}
              text={intl.formatMessage({ id: 'sidebar.athletes.allAthletes' })}
              to={groupLink}
              isAthlete
              isActive={!selectedAthlete}
            />
          )}
          {usersToShow.map(athlete =>
            hideCurrentUser && currentUser.id === athlete.id ? null : (
              <AthleteLink
                key={athlete.id}
                onClick={onListItemClick}
                athlete={athlete}
                isActive={athlete === selectedAthlete}
              />
            )
          )}
        </SidebarList>
      )}
    </AthletesListWrapper>
  );
});
