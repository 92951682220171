import styled, { css } from 'styled-components';
import { richtextStyles } from './styles';

export const RawValue = styled.div<{
  asInput?: boolean;
  asLegacyInput?: boolean;
}>`
  padding: 0;
  line-height: 1.5;
  outline: none;
  :after {
    content: attr(data-placeholder);
    pointer-events: none;
    display: block;
    color: #000000;
    opacity: 0.4;
    font-style: normal;
    left: 12px;
  }

  ${richtextStyles}

  p {
    margin: 0;
  }

  ${({ asInput }) =>
    asInput &&
    css`
      p {
        min-height: calc(1em * 1.5);
      }
    `}

  ${({ asLegacyInput }) =>
    asLegacyInput &&
    css`
      min-height: 31.5px;
      padding: 0.375rem 0.75rem;
      max-height: calc(30 * 1em);
      overflow: auto;
      word-break: break-word;
      z-index: 0;
    `}
`;
