import {
  CloseButton,
  FullScreenLayerCloseButtonWrapper,
  LayerPortal,
} from '@yarmill/components';
import { WORKOUT_URL_PARAM } from '@yarmill/const';
import { useHistory, useLayer, useLocation } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useWorkoutDetailStore } from './hooks';
import { WorkoutDetail } from './workout-detail';

export const Workout = observer(function Workout(): JSX.Element | null {
  const workoutDetailStore = useWorkoutDetailStore();
  const history = useHistory();
  const workoutId = workoutDetailStore.id;
  const workoutDetailLayer = useLayer('full-screen');
  const location = useLocation();

  const onClose = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(WORKOUT_URL_PARAM);
    const closeTarget = `${location.pathname}?${searchParams.toString()}`;

    if (workoutDetailLayer.isOpened) {
      workoutDetailLayer.layer.close();
    }

    history.push(closeTarget);
    workoutDetailStore.clear();
  }, [workoutDetailLayer, history, workoutDetailStore, location]);

  useEffect(() => {
    if (workoutId && !workoutDetailLayer.isOpened) {
      workoutDetailLayer.open({ onClose });
    }
  }, [workoutId, workoutDetailLayer, onClose]);

  return (
    <LayerPortal
      layerHandle={workoutDetailLayer}
      getContent={layer => (
        <>
          <WorkoutDetail />
          <FullScreenLayerCloseButtonWrapper>
            <CloseButton onClick={layer.close} />
          </FullScreenLayerCloseButtonWrapper>
        </>
      )}
    />
  );
});
