import { FormattedMessage } from 'react-intl';
import { StyledReportingTableCell } from './styled-components';

interface NoDataCellProps {
  readonly numberOfColumns: number;
}
export function NoDataCell({ numberOfColumns }: NoDataCellProps) {
  return (
    <StyledReportingTableCell colSpan={numberOfColumns}>
      <FormattedMessage id="evidence.table.noData" />
    </StyledReportingTableCell>
  );
}
