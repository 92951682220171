import { Layer, LayerHandle } from '@yarmill/types';
import { PropsWithChildren, ReactElement } from 'react';
import { createPortal } from 'react-dom';

export interface LayerPortalProps {
  layerHandle: LayerHandle;
  getContent: (layer: Layer) => ReactElement;
}

/**
 * mount - should contain mount property, where the component will render its children <br>
 * children - will be rendered inside mount point
 */
export function LayerPortal(
  props: PropsWithChildren<LayerPortalProps>
): JSX.Element | null {
  const { layerHandle, getContent } = props;

  return layerHandle.isOpened
    ? createPortal(getContent(layerHandle.layer), layerHandle.mountPoint)
    : null;
}
