import { MultiSelect } from './multi-select';

interface GroupsSelectOption {
  label: string;
  value: number;
}

interface GroupsSelectProps {
  autoFocus?: boolean;
  options: GroupsSelectOption[];
  placeholder: string;
  onChange(groupIds: GroupsSelectOption[]): void;
  handleSubmit?(): void;
  noOptionsMessage?: string;
}
export function GroupsSelect(props: GroupsSelectProps): JSX.Element {
  return <MultiSelect {...props} classNamePrefix="groups" />;
}
