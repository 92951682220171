import { DashboardSelector } from './dashboard-selector';
import { Filters } from './filters';
import { DashboardType } from './types';
import { SidebarContainer } from '@yarmill/components';

export interface SidebarProps {
  dashboard: DashboardType | null;
}

export function Sidebar(props: SidebarProps): JSX.Element {
  const { dashboard } = props;

  return (
    <SidebarContainer>
      <DashboardSelector activeDashboard={dashboard} />
      {dashboard && <Filters />}
    </SidebarContainer>
  );
}
