import { GlobalLogger } from '@yarmill/utils';
import { action, computed, makeObservable, observable } from 'mobx';

type NodeId = string | number;

interface ExpandableNode {
  id: NodeId;
  parent: NodeId | null;
  children: NodeId[];
  isExpanded: boolean;
}

type GetId<T> = (element: T, index: number) => NodeId;
type GetLevel<T> = (element: T, index: number) => number;

export class ExpandableTree<T> {
  private static readonly PERSIST_KEY = 'yarmill-expandable-';
  private readonly treeId: string;
  @observable
  private _nodes: Map<NodeId, ExpandableNode> = new Map();

  private readonly getElementId: GetId<T>;
  private readonly getElementLevel: GetLevel<T>;

  constructor(
    elements: T[],
    getElementId: GetId<T>,
    getElementLevel: GetLevel<T>,
    treeId: string
  ) {
    makeObservable(this);
    this.getElementId = getElementId;
    this.getElementLevel = getElementLevel;
    this.treeId = treeId;
    this.buildTree(elements);
    this.restorePersistedState();
  }

  @action
  public expandNode(id: NodeId): void {
    const node = this._nodes.get(id);
    if (node && this.isNodeExpandable(id)) {
      node.isExpanded = true;
      this.persistExpandedElements();
    }
  }

  @action
  public collapseNode(id: NodeId): void {
    const node = this._nodes.get(id);
    if (node && this.isNodeExpandable(id)) {
      node.isExpanded = false;
      this.persistExpandedElements();
    }
  }

  @computed
  public get expandedNodes(): NodeId[] {
    return [...this._nodes.values()]
      .filter(node => node.isExpanded)
      .map(node => node.id);
  }

  public isNodeVisible(id: NodeId): boolean {
    const node = this._nodes.get(id);
    if (!node) {
      return false;
    }

    if (node.parent === null) {
      return true;
    }

    const parent = this._nodes.get(node.parent);

    if (!parent) {
      return false;
    }

    return this.isNodeVisible(parent.id) && parent.isExpanded;
  }

  public isNodeExpandable(id: NodeId): boolean {
    const node = this._nodes.get(id);
    if (!node) {
      return false;
    }

    return node.children.length > 0;
  }

  public isNodeExpanded(id: NodeId): boolean {
    const node = this._nodes.get(id);
    if (!node) {
      return false;
    }

    return node.isExpanded;
  }

  private buildTree(elements: T[]): void {
    elements.forEach((element, index) => {
      const level = this.getElementLevel(element, index);

      const node: ExpandableNode = {
        id: this.getElementId(element, index),
        parent: level !== 0 ? this.getParent(elements, element, index) : null,
        children: [],
        isExpanded: false,
      };

      this._nodes.set(node.id, node);

      if (node.parent !== null && this._nodes.has(node.parent)) {
        this._nodes.get(node.parent)?.children.push(node.id);
      }
    });
  }

  private getParent(elements: T[], element: T, index: number): NodeId | null {
    const level = this.getElementLevel(element, index);

    for (let i = index - 1; i >= 0; i--) {
      const possibleParent = elements[i];
      if (possibleParent && this.getElementLevel(possibleParent, i) < level) {
        return this.getElementId(possibleParent, i);
      }
    }

    return null;
  }

  @computed
  private get persistKey(): string {
    return `${ExpandableTree.PERSIST_KEY}-${this.treeId}`;
  }

  private persistExpandedElements(): void {
    const expandedNodes = this.expandedNodes;

    window?.localStorage?.setItem(
      this.persistKey,
      JSON.stringify(expandedNodes)
    );
  }

  @action
  private restorePersistedState(): void {
    const savedData = window?.localStorage.getItem(this.persistKey);

    if (savedData) {
      try {
        const json = JSON.parse(savedData);

        if (Array.isArray(json)) {
          json.forEach(nodeId => {
            const node = this._nodes.get(nodeId);
            if (node && this.isNodeExpandable(nodeId)) {
              node.isExpanded = true;
            }
          });
        }
      } catch (e: unknown) {
        GlobalLogger.warn(
          `Unable to restore expanded nodes for ${this.persistKey}`
        );
      }
    }
  }
}
