import { styled } from '@yarmill/components';

export const AthletesInGroupDropdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.x1};
  padding: ${({ theme }) => theme.size.x1};
  :hover {
    background-color: ${({ theme }) => theme.color.neutral_8};
  }
`;
