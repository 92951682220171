import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  FileLink,
  Icon,
  IconSize,
  Popover,
  PopoverItem,
  SingleLinePopoverItem,
  Tippy,
  toast,
} from '@yarmill/components';
import { AttachmentStore } from '@yarmill/types';
import { useFileLink } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DesktopButtonsWrapper,
  MobileButtonsWrapper,
  StyledDownloadLink,
} from './components/edit-buttons-wrapper';
import { useFilesOverviewStore } from './files-overview-store-context';
import { useRenameFileHandler } from './hooks/use-rename-file-handler';
import { FileOverview } from './types';

interface EditButtonsProps {
  readonly file: AttachmentStore;
}
export const EditButtons = observer(function EditButtons({
  file,
}: EditButtonsProps) {
  const filesOverviewStore = useFilesOverviewStore();
  const intl = useIntl();
  const attachment = file.attachment as FileOverview;

  const isAllowedToModify =
    filesOverviewStore.isAllowedToDeleteFile(attachment);
  const [onRenameClick, renameFileLayer] = useRenameFileHandler(file);
  const onDownloadClick = useFileLink(attachment, true);

  const removeRow = useCallback(async () => {
    const fileName = file.attachment.FileName;
    const message = intl.formatMessage(
      {
        id: 'trainingDay.attributes.attachment.removeConfirm',
      },
      { file: fileName }
    );

    if (window.confirm(message)) {
      const result = await file.remove();
      if (result) {
        toast('toast.success.removeAttachment', 'success', { file: fileName });
      } else {
        toast('toast.error.removeAttachment', 'error', { file: fileName });
      }
    }
  }, [file, intl]);

  return (
    <>
      <DesktopButtonsWrapper>
        <Tippy
          tooltipContent="trainingDay.attributes.attachment.download"
          touch={false}
        >
          <StyledDownloadLink>
            <FileLink file={file.attachment} forceDownload>
              <Icon>
                <ExternalIcon name="CloudDownload" />
              </Icon>
            </FileLink>
          </StyledDownloadLink>
        </Tippy>
        <Tippy
          tooltipContent="trainingDay.attributes.attachment.rename"
          touch={false}
        >
          <Button
            onClick={isAllowedToModify ? onRenameClick : undefined}
            disabled={!isAllowedToModify}
            appearance={ButtonAppearance.Link}
          >
            <Icon>
              <ExternalIcon name="Forms" />
            </Icon>
          </Button>
        </Tippy>
        <Tippy
          tooltipContent="trainingDay.attributes.attachment.remove"
          touch={false}
        >
          <Button
            onClick={isAllowedToModify ? removeRow : undefined}
            disabled={!isAllowedToModify}
            appearance={ButtonAppearance.Link}
          >
            <Icon>
              <ExternalIcon name="Trash" />
            </Icon>
          </Button>
        </Tippy>
      </DesktopButtonsWrapper>
      <MobileButtonsWrapper>
        <Popover
          autoWidth
          content={tippy => (
            <>
              <PopoverItem
                dataTest="download-button"
                onClick={
                  isAllowedToModify
                    ? (e: MouseEvent) => {
                        void onDownloadClick(e);
                        tippy.hide();
                      }
                    : undefined
                }
                appearance={isAllowedToModify ? 'default' : 'disabled'}
              >
                <SingleLinePopoverItem>
                  <FormattedMessage id="trainingDay.attributes.attachment.download" />
                </SingleLinePopoverItem>
              </PopoverItem>
              <PopoverItem
                dataTest="rename-button"
                onClick={
                  isAllowedToModify
                    ? () => {
                        void onRenameClick();
                        tippy.hide();
                      }
                    : undefined
                }
                appearance={isAllowedToModify ? 'default' : 'disabled'}
              >
                <SingleLinePopoverItem>
                  <FormattedMessage id="trainingDay.attributes.attachment.rename" />
                </SingleLinePopoverItem>
              </PopoverItem>
              <PopoverItem
                dataTest="remove-button"
                onClick={
                  isAllowedToModify
                    ? () => {
                        void removeRow();
                        tippy.hide();
                      }
                    : undefined
                }
                appearance={isAllowedToModify ? 'danger' : 'disabled'}
              >
                <SingleLinePopoverItem>
                  <FormattedMessage id="trainingDay.attributes.attachment.remove" />
                </SingleLinePopoverItem>
              </PopoverItem>
            </>
          )}
        >
          <Button
            as="button"
            appearance={ButtonAppearance.Link}
            square
            data-cy="edit-buttons-menu"
          >
            <Icon size={IconSize.s24}>
              <ExternalIcon name="DotsCircleHorizontal" />
            </Icon>
          </Button>
        </Popover>
      </MobileButtonsWrapper>
      {renameFileLayer}
    </>
  );
});
