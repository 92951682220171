import { useCallback, useEffect } from 'react';

export * from './chart-width-provider';
export * from './darken-color';

export enum Breakpoints {
  default = 'default',
  tablet = 768,
  desktop = 1440,
  max = 1920,
}

export interface TransitionGroupProps {
  /**
   * Indicates whether the layer instance is the currently active layer
   */
  readonly active: boolean;

  /**
   * This is provided by the react-transition-group. No need to use it when instantiating the component.
   * Note: If you write a wrapper for this component, take care to pass these props through to ensure that the
   * animations still work
   */
  readonly in?: boolean;
  /**
   * This is provided by the react-transition-group. No need to use it when instantiating the component.
   * Note: If you write a wrapper for this component, take care to pass these props through to ensure that the
   * animations still work
   */
  onExited?(): void;
}

export enum FormControlVariant {
  default,
  big,
}

export interface SelectValueProps {
  label: string;
  value: string | number;
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toLocaleUpperCase() + str.slice(1);
}

export function lowerCaseFirstLetter(str: string): string {
  return str.charAt(0).toLocaleLowerCase() + str.slice(1);
}

export const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void
) => {
  const handleClick = useCallback(
    (ev: Event): void => {
      const target = ev.target as HTMLElement;

      if (
        target &&
        target.id.indexOf('react-select') === -1 &&
        target.className &&
        typeof target.className !== 'object' &&
        target.className.indexOf('react-select') === -1 &&
        ref.current &&
        !ref.current.contains(target)
      ) {
        callback();
      }
    },
    [callback, ref]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);
};

export function roundDistance(value: number): number {
  const km = value / 1000;
  const twice = km * 10;
  const round = Math.floor(twice);
  const result = round / 10;

  return result * 1000;
}

export function getTicks(xAxisKey: string, total: number): number[] {
  return xAxisKey === 'time'
    ? [0, total / 4, total / 2, total * (3 / 4), total * 0.95]
    : [
        0,
        roundDistance(total / 4),
        roundDistance(total / 2),
        roundDistance(total * (3 / 4)),
        roundDistance(total * 0.95),
      ];
}

export function chunkArray<T>(arr: T[], chunkSize: number): T[][] {
  if (chunkSize <= 0) throw new Error('Invalid chunk size');
  const R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize)
    R.push(arr.slice(i, i + chunkSize));
  return R;
}
