import {
  Icon,
  IconSize,
  PatternInputConfig,
  replacePatternInputPattern,
  usePatternInput,
  styled,
} from '@yarmill/components';
import { forwardRef, useRef } from 'react';
import { useMergeRefs } from '@floating-ui/react';
import {
  getAppearanceColors,
  IconWrapper,
  StyledTextInput,
  TextInputLabel,
  TextInputLayout,
  TextInputProps,
  TextInputWrapper,
} from './text-input';

export type PatternInputProps = Omit<TextInputProps, 'onChange'> &
  PatternInputConfig;

const StyledPatternInput = styled(StyledTextInput)`
  font-family: ${({ theme }) => theme.text.font.mono};
  color: ${({ theme }) => theme.color.tangerine};
  :focus {
    color: ${({ theme }) => theme.color.tangerine};
  }
`;

const StyledPattern = styled(StyledPatternInput)`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  color: ${({ theme, appearance }) =>
    getAppearanceColors(appearance, theme).additional};
  background: transparent;
`;

export const PatternInput = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  PatternInputProps
>(function PatternInput(props, passedRef): JSX.Element {
  const {
    label,
    align,
    icon,
    iconPosition,
    value,
    pattern,
    ...textInputProps
  } = props;
  const { onChange, onKeyDown, persistedValue } = usePatternInput(props);
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const ref = useMergeRefs([passedRef, inputRef]);
  const content = value !== undefined ? value : persistedValue;

  return (
    <TextInputLayout>
      {label && (
        <TextInputLabel
          as="label"
          appearance="button10"
          htmlFor={textInputProps.id}
          whiteSpace="noWrap"
          upperCase
          appearanceStyle={textInputProps.appearance}
        >
          {label}
        </TextInputLabel>
      )}
      <TextInputWrapper icon={icon} iconPosition={iconPosition}>
        <StyledPatternInput
          {...textInputProps}
          value={content}
          onChange={onChange}
          onKeyDown={onKeyDown}
          ref={ref}
          icon={icon}
          iconPosition={iconPosition}
          autoComplete="off"
        />
        <StyledPattern
          appearance={textInputProps.appearance}
          readOnly
          name={`${textInputProps.name}-pattern`}
          tabIndex={-1}
          value={replacePatternInputPattern(pattern, content)
            .map(r => (typeof r === 'object' ? ' ' : r))
            .join('')}
        />
        {icon && (
          <IconWrapper
            position={iconPosition ?? 'left'}
            appearance={textInputProps.appearance}
          >
            <Icon size={IconSize.s16}>{icon}</Icon>
          </IconWrapper>
        )}
      </TextInputWrapper>
    </TextInputLayout>
  );
});
