import {
  ReportingModuleConfig,
  ReportingPageNavigationConfig,
} from '@yarmill/types';

export function findActiveReportingModulePage(
  reportingModules: ReportingModuleConfig,
  pageCode: string | undefined
): ReportingPageNavigationConfig | undefined {
  return reportingModules.find(page =>
    page.Dashboards.find(dashboard => dashboard.ReportPageCode === pageCode)
  );
}
