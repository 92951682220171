import { useCallback } from 'react';
import { useSubmitOpenedForm } from './use-submit-opened-form';
import { useCloseEventFormPanelHandler } from './use-close-event-form-panel-handler';
import { usePlannerStore } from '../planner-store-context';

export function useShowImporterHandler() {
  const submitOpenedForm = useSubmitOpenedForm();
  const closeEventFormPanel = useCloseEventFormPanelHandler();
  const plannerStore = usePlannerStore();

  return useCallback(() => {
    submitOpenedForm();
    closeEventFormPanel();
    plannerStore.showImporter = true;
  }, [submitOpenedForm, closeEventFormPanel, plannerStore]);
}
