import * as React from 'react';
import styled from 'styled-components';

const Slider = styled.span`
  position: absolute;
  content: '';
  height: 23px;
  width: 23px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 13px;
`;

const StyledSwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 30px;
  background: #dad9d9;
  border-radius: 25px;
  position: relative;
  transition: background-color 0.2s;

  &:active ${Slider} {
    width: 28px;
  }
`;

const StyledInputSwitch = styled.input`
  display: none;
  width: 0;
  height: 0;
  
  &:checked + ${StyledSwitchLabel} {
    background-color: #4a90e2;
  }

  &:checked + ${StyledSwitchLabel} ${Slider} {
    left: calc(100% - 4px);
    transform: translateX(-100%);
  }
  
  &:disabled + ${StyledSwitchLabel} {
    cursor: not-allowed;s
  }
  
  &:disabled + ${StyledSwitchLabel} ${Slider} {
    background-color: #9a9a9a;
  }
`;

interface SwitchProps {
  isChecked: boolean;
  disabled?: boolean;
  id: string;
  onChange?(e: React.SyntheticEvent, poops: SwitchProps): void;
  onClick(e: React.SyntheticEvent, poops: SwitchProps): void;
}

export function Switch(props: SwitchProps): JSX.Element {
  const { isChecked, onChange, id, onClick, disabled = false } = props;

  const handleChange = (e: React.SyntheticEvent<HTMLElement>) => {
    if (!disabled && onChange) {
      onChange(e, props);
    }
  };

  const handleClick = (e: React.SyntheticEvent<HTMLElement>) => {
    if (!disabled) {
      onClick(e, props);
    }
  };

  return (
    <>
      <StyledInputSwitch
        id={id}
        type="checkbox"
        checked={isChecked}
        disabled={disabled}
        value={String(isChecked)}
        onChange={handleChange}
        onClick={handleClick}
      />
      <StyledSwitchLabel htmlFor={id}>
        <Slider />
      </StyledSwitchLabel>
    </>
  );
}
