import { LayerPortal } from '@yarmill/components';
import { ATHLETE_SEARCH_PARAM } from '@yarmill/const';
import { useLayer, useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { YollandaLayerContent } from './layer-content';

export const Yollanda = observer(function Yollanda(): JSX.Element {
  const rootStore = useRootStore();
  const yollandaService = rootStore.yollandaService;

  const layer = useLayer('alert', {
    closeOnShimClick: true,
    showShim: true,
    center: false,
    onClose: () => yollandaService.hide(),
  });

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (
        e.key?.toLowerCase() === 'y' &&
        e.metaKey &&
        e.shiftKey &&
        (rootStore.historyService.pathname.includes('plan') ||
          rootStore.historyService.pathname.includes('reality')) &&
        rootStore.historyService.searchParams.get(ATHLETE_SEARCH_PARAM) &&
        rootStore.currentUserStore.isAllowedTo('yollanda')
      ) {
        if (!yollandaService.isVisible) {
          yollandaService.show();
        } else {
          yollandaService.hide();
        }
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, [yollandaService, rootStore]);

  useEffect(() => {
    if (yollandaService.isVisible && !layer.isOpened) {
      layer.open();
    } else if (!yollandaService.isVisible && layer.isOpened) {
      layer.layer.close();
    }
  }, [yollandaService.isVisible, layer]);

  return (
    <LayerPortal
      layerHandle={layer}
      getContent={() => <YollandaLayerContent />}
    />
  );
});
