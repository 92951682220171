import { User } from '@yarmill/types';
import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export function getUsers(): AxiosPromise<User[]> {
  return axios.get(`${coreApiTarget}/api/users`, {
    params: {
      instanceCode: devMode ? instance : undefined,
    },
  });
}
