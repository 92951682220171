import { RootStore } from '@yarmill/types';
import { getWeekEndString } from '@yarmill/utils';
import { makeObservable, observable } from 'mobx';
import moment from 'moment';
import { RichtextAttributeStore } from '../../attributes/mobx/richtext-attribute-store';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { DiaryDataUniqueId } from '../../diary/types';
import { TrainingDayStore } from '../../training-day/mobx/training-day-store';
import { TrainingDayAttribute } from '../../training-day/types';
import { isCycleGoal, isWeekGoal } from '../utils';

export class GoalAttributeStore extends RichtextAttributeStore {
  @observable
  private _hasInitialFocus = false;

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    trainingDayStore: TrainingDayStore,
    attribute: TrainingDayAttribute,
    dataId: DiaryDataUniqueId
  ) {
    super(rootStore, diaryStore, trainingDayStore, attribute, dataId);
    makeObservable(this);
  }

  protected get isInAllowedBackfillScope(): boolean {
    const index = this.trainingDayStore.index;
    const currentDate = this.trainingDayStore.currentDate;

    if (!this.diaryStore.applyBackfillScope) {
      return true;
    }

    if (isCycleGoal(index)) {
      return true;
    }

    const date = isWeekGoal(index)
      ? getWeekEndString(currentDate)
      : currentDate;

    return moment(date).diff(this.diaryStore.lastWritableDay!) >= 0;
  }

  public get hasInitialFocus(): boolean {
    return this._hasInitialFocus;
  }

  public setInitialFocus(initialFocus: boolean): void {
    this._hasInitialFocus = initialFocus;
  }
}
