import styled, { css } from 'styled-components';

export const LogoContainer = styled.div<{
  $email?: string;
  $devMode?: boolean;
  $impersonatedUser?: boolean;
}>`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  row-gap: 7px;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  width: ${(100 / 12) * 4}vw;
  min-width: ${(100 / 12) * 4}vw;
  padding: 10px 6px;
  height: 55px;

  @media (min-width: 768px) {
    padding: 15px 6px;
    width: ${(100 / 12) * 2}vw;
    min-width: ${(100 / 12) * 2}vw;
    height: 80px;
  }
  ${({ $impersonatedUser, $devMode }) =>
    ($impersonatedUser || $devMode) &&
    css`
      outline: 2px solid red;
      outline-offset: -2px;
      position: relative;
      &:after,
      &:before {
        position: absolute;
        font-size: 10px;
        color: red;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
      }
    `};

  ${({ $devMode }) =>
    $devMode &&
    css`
      &:before {
        content: 'Bacha, use instance je aktivní!';
        top: 2px;
      }
    `}

  ${({ $impersonatedUser, $email }) =>
    $impersonatedUser &&
    css`
      &:after {
        bottom: 2px;
        content: '${$email}';
      }
    `}
`;

export const LogoImageWrapper = styled.div`
  height: 100%;
  flex-grow: 1;
  position: relative;
`;

export const LogoImage = styled.img`
  position: absolute;
  width: auto;
  left: 0;
  top: 0;
  height: 100%;
  transform: translateX(-50%);
`;

export const LogoTextWrapper = styled.div`
  font-size: 7px;
  font-family: 'Ubuntu';
`;
