import {
  ExternalIcon,
  Icon,
  IconSize,
  Text,
  TextSize,
  WhiteSpace,
  YollandaBetaBadge,
  YollandaContext,
  YollandaForm,
  YollandaFormHeader,
  YollandaInput,
  YollandaInputIcon,
  YollandaInputWrapper,
  YollandaLayerLayout,
  YollandaMessageContainer,
  YollandaSubmitButton,
} from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { Fragment, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CrystalBallAnimating } from './crystal-ball-animating-icon';
import { FeedbackButtons } from './feedback-buttons';
import { useContextValue } from './hooks/use-context-value';
import { useSubmitHandler } from './hooks/use-submit-handler';
import { LoadingText } from './loading-text';

export const YollandaLayerContent = observer(
  function YollandaLayerContent(): JSX.Element {
    const contextValue = useContextValue();
    const layoutRef = useRef<HTMLDivElement>(null);
    const intl = useIntl();
    const [showLoadingMessageFallback, setShowLoadingMessageFallback] =
      useState(false);
    const [streamedMessage, interactionId, isPending, handleSubmit] =
      useSubmitHandler(layoutRef, setShowLoadingMessageFallback);

    return (
      <YollandaLayerLayout ref={layoutRef}>
        <YollandaForm
          onSubmit={handleSubmit}
          $showDivider={Boolean(
            isPending || showLoadingMessageFallback || streamedMessage
          )}
        >
          <YollandaFormHeader>
            <YollandaContext>
              {contextValue.map((item, idx) => (
                <Fragment key={idx}>
                  {idx !== 0 && (
                    <Text inheritColor size={TextSize.s12}>
                      {'→'}
                    </Text>
                  )}
                  <Text inheritColor size={TextSize.s12}>
                    {item}
                  </Text>
                </Fragment>
              ))}
            </YollandaContext>
            <YollandaBetaBadge>
              <Icon size={IconSize.s12}>
                <ExternalIcon name="Wand" />
              </Icon>
              <Text size={TextSize.s12} medium inheritColor>
                <FormattedMessage id="betaFeature.label" />
              </Text>
            </YollandaBetaBadge>
          </YollandaFormHeader>
          <YollandaInputWrapper>
            <YollandaInputIcon size={IconSize.s24}>
              {isPending ? (
                <CrystalBallAnimating />
              ) : (
                <ExternalIcon name="CrystalBall" />
              )}
            </YollandaInputIcon>
            <YollandaInput
              id="term"
              name="term"
              placeholder={intl.formatMessage({
                id: 'yollanda.searchInput.placeholder',
              })}
              autoComplete="off"
            />
            <YollandaSubmitButton type="submit" disabled={isPending}>
              <Icon size={IconSize.s16}>
                <ExternalIcon name="ArrowBack" />
              </Icon>
            </YollandaSubmitButton>
          </YollandaInputWrapper>
        </YollandaForm>
        {(isPending || showLoadingMessageFallback) && (
          <YollandaMessageContainer>
            <LoadingText forceLastMessage={showLoadingMessageFallback} />
          </YollandaMessageContainer>
        )}
        {streamedMessage && !showLoadingMessageFallback && (
          <>
            <YollandaMessageContainer>
              <Text size={TextSize.s14} whiteSpace={WhiteSpace.preWrap}>
                {streamedMessage}
              </Text>
            </YollandaMessageContainer>
            <FeedbackButtons interactionId={interactionId} />
          </>
        )}
      </YollandaLayerLayout>
    );
  }
);
