import { useRootStore } from '@yarmill/utils';
import { PropsWithChildren, useRef } from 'react';
import { EvidenceStoreContext } from './context/evidence-store-context';
import { EvidenceStore } from './mobx/evidence-store';

export function EvidenceStoreContextProvider({ children }: PropsWithChildren) {
  const rootStore = useRootStore();
  const store = useRef(new EvidenceStore(rootStore));

  return (
    <EvidenceStoreContext.Provider value={store.current}>
      {children}
    </EvidenceStoreContext.Provider>
  );
}
