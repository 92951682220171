import {
  AthletesSidebar,
  MaskSelector,
  Navbar,
  PageMainContent,
  SeasonSelector,
  ViewSelector,
  WeekSelector,
} from '@yarmill/components';
import { useCurrentUserStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { Fragment, ReactElement, memo } from 'react';
import { Redirect } from 'react-router-dom';
import { CopyButton } from './copy/copy-button';
import { Diary } from './diary/diary';
import {
  DiaryStoreContextProvider,
  useDiaryStore,
} from './diary/diary-store-context';
import { ExportDiaryButton } from './diary/export-diary-button';
import { ExportSeasonButton } from './diary/export-season-button';
import { currentUserHasPermissionToSelectMask } from './diary/utils';
import { SeasonStartSettingsButton } from './goals/season-start-settings-button';

function InternalDiaryRouteRoot(): ReactElement {
  const diaryStore = useDiaryStore();
  const diaryType = diaryStore.diaryType;
  const viewType = diaryStore.viewType;
  const isAllowedToCopy = diaryStore.isCurrentUserAllowedToCopy;
  const isAllowedToExport = diaryStore.isCurrentUserAllowedToExport;
  const currentUser = useCurrentUserStore();
  const enabledViewTypes = diaryStore.enabledViewTypes;
  const maskCode = diaryStore.currentMaskCode;

  if (!diaryStore.isValidRoute) {
    return <Redirect to="/" />;
  }

  const showCopyButton = isAllowedToCopy;
  const showExportButton =
    isAllowedToExport &&
    diaryType === 'reality' &&
    viewType === 'season' &&
    diaryStore.isValidAthleteId;

  const showSeasonSelector =
    viewType === 'season' || viewType === 'seasonGoals';
  const showMaskSelector =
    (viewType === 'week' || viewType === 'season') &&
    enabledViewTypes.length !== 0 &&
    diaryStore.isValidAthleteId &&
    currentUserHasPermissionToSelectMask(currentUser, diaryType, viewType);
  const showSeasonStartSettingsButton =
    viewType === 'seasonGoals' &&
    currentUser.isAllowedTo(`${diaryType}.seasonGoals.setSeasonStart`);
  const showExportDiaryButton =
    viewType === 'week' && diaryStore.isValidAthleteId;

  return (
    <>
      {currentUser?.role !== 'athlete' ? (
        <AthletesSidebar
          disableWholeGroup={diaryType === 'reality'}
          disableWholeGroupTooltip="sidebar.athletes.disableAllAthletesInReality"
        />
      ) : undefined}
      <PageMainContent>
        <Navbar
          leftItems={
            <>
              {enabledViewTypes.length !== 0 && (
                <ViewSelector
                  moduleKey={diaryType}
                  activeViewType={viewType}
                  allowedViewTypes={enabledViewTypes}
                />
              )}
              {showSeasonSelector && enabledViewTypes.includes('season') ? (
                <SeasonSelector />
              ) : !showSeasonSelector &&
                (enabledViewTypes.includes('week') ||
                  enabledViewTypes.includes('goals')) ? (
                <WeekSelector />
              ) : (
                <Fragment />
              )}
            </>
          }
          rightItems={
            <>
              {showMaskSelector && (
                <MaskSelector
                  currentMaskCode={maskCode}
                  userId={diaryStore.athleteId}
                  groupId={diaryStore.groupId}
                  onMaskChange={diaryStore.setMaskCode}
                />
              )}
              {showExportDiaryButton && <ExportDiaryButton />}
              {showSeasonStartSettingsButton && <SeasonStartSettingsButton />}
              {showCopyButton && <CopyButton />}
              {showExportButton && <ExportSeasonButton />}
            </>
          }
        />
        <Diary />
      </PageMainContent>
    </>
  );
}

const ObserverDiaryRouteRoot = observer(InternalDiaryRouteRoot);

export const TrainingDiaryModule = memo(() => (
  <DiaryStoreContextProvider>
    <ObserverDiaryRouteRoot />
  </DiaryStoreContextProvider>
));
