import { NumberFilter, TimeFilter } from '@yarmill/types';
import { ComponentType, useState } from 'react';
import styled from 'styled-components';
import { NumberInputProps } from '../number-input';
import { RadioButton } from '../radio-button';

const NumberFiltersWrapper = styled.div`
  display: grid;
  padding-bottom: 10px;
  row-gap: 8px;
`;
const CustomNumberWrapper = styled.div`
  display: grid;
  row-gap: 5px;
  padding: 5px 0 5px 36px;
`;

const noop = () => null;

export interface NumberFiltersProps {
  PatternInput: ComponentType<NumberInputProps>;
  timeFormat?: string;
  value: NumberFilter | undefined;
  setCurrentValue(value: NumberFilter): void;
  translate?(key: string): string;
}

function isValidNumber(value: string): boolean {
  return !Number.isNaN(Number(value.replace(',', '.')));
}

function formatNumericValue(value: string): string {
  return String(Number(value.replace(',', '.')));
}

export function NumberFilters(props: NumberFiltersProps): JSX.Element {
  const { value, setCurrentValue, PatternInput, translate } = props;

  const [inputValues, setInputValue] = useState({
    from:
      value?.type === 'range' && value.from
        ? value.from
        : value?.type === 'greaterorequal' && value?.values?.[0]
          ? value.values[0]
          : '',
    to:
      value?.type === 'range' && value.to
        ? value.to
        : value?.type === 'lessorequal' && value?.values?.[0]
          ? value.values[0]
          : '',
    match: value?.type === 'match' && value?.values?.[0] ? value.values[0] : '',
  });

  function onInputChange(input: string, inputValue: string): void {
    if (input === 'from') {
      setInputValue(v => ({ ...v, from: inputValue }));
    } else if (input === 'to') {
      setInputValue(v => ({ ...v, to: inputValue }));
    } else if (input === 'match') {
      setInputValue(v => ({ ...v, match: inputValue }));
    }
  }

  function onRangeBlur(): void {
    const from = isValidNumber(inputValues.from)
      ? formatNumericValue(inputValues.from)
      : '';
    const to = isValidNumber(inputValues.to)
      ? formatNumericValue(inputValues.to)
      : '';

    setCurrentValue({
      type: 'range',
      from,
      to,
    });
  }

  function onFromBlur(): void {
    if (!isValidNumber(inputValues.from)) {
      return;
    }
    setCurrentValue({
      type: 'greaterorequal',
      values: [formatNumericValue(inputValues.from)],
    });
  }

  function onMatchBlur(): void {
    if (!isValidNumber(inputValues.match)) {
      return;
    }
    setCurrentValue({
      type: 'match',
      values: [formatNumericValue(inputValues.match)],
    });
  }

  function onToBlur(): void {
    if (!isValidNumber(inputValues.to)) {
      return;
    }
    setCurrentValue({
      type: 'lessorequal',
      values: [formatNumericValue(inputValues.to)],
    });
  }

  function selectFilterType(value: TimeFilter): void {
    setCurrentValue(value);
    setInputValue({ from: '', to: '', match: '' });
  }

  return (
    <NumberFiltersWrapper>
      <RadioButton
        value="greaterorequal"
        label={translate?.('table.filters.greaterOrEqualThan')}
        checked={value?.type === 'greaterorequal'}
        onClick={() =>
          selectFilterType({ type: 'greaterorequal', values: [''] })
        }
        onChange={noop}
      />
      {value?.type === 'greaterorequal' && (
        <CustomNumberWrapper>
          <PatternInput
            autoFocus
            id="from"
            noError
            noLabel
            value={inputValues.from}
            onChange={val => onInputChange('from', val)}
            onBlur={onFromBlur}
          />
        </CustomNumberWrapper>
      )}
      <RadioButton
        value="lessorequal"
        label={translate?.('table.filters.lowerOrEqualThan')}
        checked={value?.type === 'lessorequal'}
        onClick={() => selectFilterType({ type: 'lessorequal', values: [''] })}
        onChange={noop}
      />
      {value?.type === 'lessorequal' && (
        <CustomNumberWrapper>
          <PatternInput
            autoFocus
            id="to"
            noLabel
            noError
            value={inputValues.to}
            onChange={val => onInputChange('to', val)}
            onBlur={onToBlur}
          />
        </CustomNumberWrapper>
      )}
      <RadioButton
        value="match"
        label={translate?.('table.filters.matchNumber')}
        checked={value?.type === 'match'}
        onChange={noop}
        onClick={() => selectFilterType({ type: 'match', values: [''] })}
      />
      {value?.type === 'match' && (
        <CustomNumberWrapper>
          <PatternInput
            autoFocus
            id="match"
            noError
            noLabel
            value={inputValues.match}
            onChange={val => onInputChange('match', val)}
            onBlur={onMatchBlur}
          />
        </CustomNumberWrapper>
      )}
      <RadioButton
        value="range"
        label={translate?.('table.filters.range')}
        checked={value?.type === 'range'}
        onChange={noop}
        onClick={() => selectFilterType({ type: 'range', from: '', to: '' })}
      />
      {value?.type === 'range' && (
        <CustomNumberWrapper>
          <PatternInput
            autoFocus
            id="from"
            noError
            label={translate?.('table.filters.range.from')}
            value={inputValues.from}
            onChange={val => onInputChange('from', val)}
            onBlur={onRangeBlur}
          />
          <PatternInput
            id="to"
            noError
            label={translate?.('table.filters.range.to')}
            value={inputValues.to}
            onChange={val => onInputChange('to', val)}
            onBlur={onRangeBlur}
          />
        </CustomNumberWrapper>
      )}
    </NumberFiltersWrapper>
  );
}
