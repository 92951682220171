import { userCookies } from '@yarmill/const';

export function loadPersistedCurrentUser(): number | null {
  const value =
    typeof window !== 'undefined'
      ? window.localStorage.getItem(userCookies.USER)
      : null;

  if (value) {
    try {
      return parseInt(value, 10);
    } catch (e: unknown) {
      return null;
    }
  }

  return null;
}
