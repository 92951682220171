import styled from 'styled-components';
import { Text, TextSize } from '../text';
import { Icon, IconSize } from '../icon';
import { ReactNode } from 'react';

const SingleValueReportWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 5px;
  color: #4a4a4a;
`;

const DescriptionWrapper = styled.div`
  margin-top: 5px;
  padding-top: 10px;
  border-top: 1px solid #e2e4e7;
  color: #949494;
`;

export interface SingleValueReportProps {
  icon: JSX.Element;
  textIcon?: string;
  value: ReactNode;
  description: ReactNode;
}

export function SingleValueReport(props: SingleValueReportProps): JSX.Element {
  const { icon, value, description, textIcon } = props;
  return (
    <SingleValueReportWrapper>
      {textIcon ? (
        <Text size={TextSize.s18}>{textIcon}</Text>
      ) : (
        <Icon size={IconSize.s24}>{icon}</Icon>
      )}
      <Text size={TextSize.s28}>{value}</Text>
      <DescriptionWrapper>
        <Text size={TextSize.s12} inheritColor>
          {description}
        </Text>
      </DescriptionWrapper>
    </SingleValueReportWrapper>
  );
}
