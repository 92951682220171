import { styled } from '@yarmill/components';
import { Button, getAppearanceColors } from '@yarmill/components-2';

export const ActivityLogLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
`;

export const ActivityLogLayoutScrollContainer = styled.div`
  height: calc(100% - ${({ theme }) => theme.size.x6});
  overflow: auto;
  padding-bottom: ${({ theme }) => theme.size.x1};
`;

export const ActivityLogTabs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: stretch;
  width: 100%;
  column-gap: ${({ theme }) => theme.size.x1};
  padding: ${({ theme }) => theme.size.x1};
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.color.neutralPlate};
  z-index: 1;
`;

export const TabsButton = styled(Button)`
  color: ${({ $appearanceStyle, theme }) =>
    getAppearanceColors($appearanceStyle, theme).base};
`;
