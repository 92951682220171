import { styled } from '@yarmill/components';

export const AttachmentLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const AttachmentsFormFieldWrapper = styled.div`
  padding-bottom: 24px;
`;
