import { BarGroupHorizontal as VisxHorizontalBarGroup } from '@visx/shape/lib/types/barGroup';
import { HorizontalBarGroupChart } from '../../types';
import { Group } from '@visx/group';
import { Bar } from './bar';
import { DataItem } from '../../../reporting/types';
import { useXYChartContext } from '../../xy-chart-context';
import { getAxisPosition } from '../../../reporting/utils/get-axis-position';

interface HorizontalBarGroupProps {
  readonly group: VisxHorizontalBarGroup<string>;
  readonly config: HorizontalBarGroupChart;
  readonly item: DataItem;
}

export function HorizontalBarGroup({
  group,
  config,
  item,
}: HorizontalBarGroupProps) {
  const { xScale } = useXYChartContext();
  const domainX = xScale.domain();
  // Bar Chart works only for categorical scale
  if ('bandwidth' in xScale) {
    return null;
  }

  return (
    <Group top={group.y0}>
      {group.bars.map(bar => {
        const xValue = bar.value;
        const axisPosition = getAxisPosition(domainX as number[]);
        const x =
          xValue < 0 ? xScale(axisPosition) : xScale(domainX[0] as number);
        const width = Math.abs(xScale(xValue) - xScale(axisPosition));

        return (
          <Bar
            key={bar.key}
            x={x}
            y={
              bar.y + ((1 - config.getBarWidth(item, bar.key)) * bar.height) / 2
            }
            width={width}
            height={bar.height * config.getBarWidth(item, bar.key)}
            opacity={config.getOpacity(item, bar.key) ?? 1}
            color={config.getColor(item, bar.key)}
            isHorizontal
          />
        );
      })}
    </Group>
  );
}
