import { ButtonAppearance, Spinner, toast } from '@yarmill/components';
import { userCookies } from '@yarmill/const';
import { AsyncStatus, User } from '@yarmill/types';
import { getUserName } from '@yarmill/utils';
import { Fragment } from 'react';
import { UserInvitation } from './types';

export function isEmptyInvitation(invitation: UserInvitation): boolean {
  return !invitation.FirstName && !invitation.LastName && !invitation.Email;
}

export function toastInviteUsers(invitations: UserInvitation[]): void {
  if (invitations.length === 1) {
    toast('toast.success.inviteUser', 'success', {
      user: getUserName({
        LastName: invitations[0].LastName,
        FirstName: invitations[0].FirstName,
      }),
    });
  } else {
    toast('toast.success.inviteUsers', 'success', {
      count: String(invitations.length),
    });
  }
}

export function getSubmitButtonIcon(state: AsyncStatus): JSX.Element | null {
  let icon = null;

  if (state === AsyncStatus.pending) {
    icon = (
      <Fragment>
        <Spinner />
        &nbsp;
      </Fragment>
    );
  }
  if (state === AsyncStatus.rejected) {
    icon = (
      <Fragment>
        <span>×</span>
        &nbsp;
      </Fragment>
    );
  }

  if (state === AsyncStatus.resolved) {
    icon = (
      <Fragment>
        <span>✓</span>
        &nbsp;
      </Fragment>
    );
  }

  return icon;
}

export function getButtonAppearance(state: AsyncStatus): ButtonAppearance {
  if (state === AsyncStatus.pending) {
    return ButtonAppearance.Primary;
  }

  if (state === AsyncStatus.rejected) {
    return ButtonAppearance.Error;
  }

  if (state === AsyncStatus.resolved) {
    return ButtonAppearance.Success;
  }

  return ButtonAppearance.Primary;
}

export function persistCurrentUser(user: User): void {
  window.localStorage.setItem(userCookies.USER, String(user.UserId));
}

export const isAdmin = (currentUser: User | null): boolean =>
  Boolean(currentUser && currentUser.Role === 'admin');
export const isAthlete = (currentUser: User | null): boolean =>
  Boolean(currentUser && currentUser.Role === 'athlete');
export const isCoach = (currentUser: User | null): boolean =>
  Boolean(currentUser && currentUser.Role === 'coach');
