import { Icon, IconSize, Tippy } from '@yarmill/components';
import { Button, ButtonAppearance } from '@yarmill/components-2';
import { Trash } from '@yarmill/icons-2';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ObjectiveStore } from '../mobx/objective-store';
import { useOkrsStore } from '../okrs-store-context';

interface RemoveObjectiveProps {
  readonly objective?: ObjectiveStore;
}
export const RemoveObjective = observer(function RemoveObjective({
  objective,
}: RemoveObjectiveProps): JSX.Element {
  const intl = useIntl();
  const history = useHistory();
  const okrsStore = useOkrsStore();
  async function handleClick() {
    if (
      window.confirm(
        intl.formatMessage({
          id: 'okrs.removeObjective.confirm',
        })
      ) &&
      objective
    ) {
      const { location } = history;
      if (window.innerWidth < 768) {
        history.replace({
          pathname: `/okr`,
          search: location.search,
        });
      } else {
        const idx = okrsStore.objectives.indexOf(objective);
        const nextIndex = idx === 0 ? idx + 1 : idx - 1;

        history.replace({
          pathname: `/okr/${okrsStore.objectives[nextIndex]?.id ?? 'add'}`,
          search: location.search,
        });
      }
      await okrsStore.removeObjective(objective);
    }
  }

  return (
    <Tippy tooltipContent="okrs.removeObjective" noWrapper touch={false}>
      <Button
        $appearance={ButtonAppearance.Secondary}
        $appearanceStyle="neutral"
        onClick={
          objective && okrsStore.isCurrentUserAllowedToWrite(objective)
            ? handleClick
            : undefined
        }
        $iconOnly
        disabled={
          !objective?.id || !okrsStore.isCurrentUserAllowedToWrite(objective)
        }
        aria-label={intl.formatMessage({ id: 'okrs.removeObjective' })}
      >
        <Icon size={IconSize.s24}>
          <Trash />
        </Icon>
      </Button>
    </Tippy>
  );
});
