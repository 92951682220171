import { ROUTE_DATE_FORMAT } from '@yarmill/const';
import { UserStore } from '@yarmill/types';
import {
  useConfig,
  useCurrentSeasonByDay,
  useIsMobile,
  useWeekDays,
} from '@yarmill/utils';
import moment from 'moment';
import { useMemo } from 'react';
import { useAttendanceStore } from './attendance-store-context';
import { SumItemType } from './sum-row-item';
import {
  getEnabledDaysForGroup,
  getEnabledDaysForUser,
  getSumColumnItemValue,
  getSumRowItemValue,
  monthsMock,
} from './utils';

export function useAttendanceWeekViewColumns(): string[] {
  const week = useAttendanceStore().week;
  const isMobile = useIsMobile();
  const columns = useWeekDays(week || '');

  return useMemo(() => {
    if (!isMobile) {
      return columns;
    }

    // In mobile view we show only first column with names and one day
    return columns.filter(
      column =>
        moment(column).format(ROUTE_DATE_FORMAT) === week ||
        column === 'user-names'
    );
  }, [columns, isMobile, week]);
}

export function useAttendanceSeasonViewColumns(): string[] {
  const week = useAttendanceStore().week;
  const currentSeason = useCurrentSeasonByDay(week || '');
  const seasonViewType = useConfig('seasonView');

  return useMemo(() => {
    if (!currentSeason) {
      return monthsMock;
    }

    return seasonViewType === 'month'
      ? currentSeason.seasonMonths
      : currentSeason.seasonCycles;
  }, [currentSeason, seasonViewType]);
}

export function useAttendanceSumRowItemValue(
  column: string | SumItemType
): number {
  const attendanceStore = useAttendanceStore();
  return getSumRowItemValue(attendanceStore, column);
}

export function useAttendanceSumColumnItemValue(user: UserStore): number {
  const attendanceStore = useAttendanceStore();
  return getSumColumnItemValue(attendanceStore, user);
}

export function useEnabledDaysForGroup(column: string | SumItemType): number {
  const attendanceStore = useAttendanceStore();

  return getEnabledDaysForGroup(attendanceStore, column);
}

export function useEnabledDaysForUser(user: UserStore): number {
  const attendanceStore = useAttendanceStore();

  return getEnabledDaysForUser(attendanceStore, user);
}
