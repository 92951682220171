import {
  AsyncStatus,
  DebouncedFunc,
  DisabledReason,
  RequestStore,
  AbstractTrainingDayAttributeStore as iAbstractTrainingDayAttributeStore,
} from '@yarmill/types';
import { RootStore } from '@yarmill/types';
import debounce from 'lodash.debounce';
import {
  IReactionDisposer,
  action,
  computed,
  makeObservable,
  observable,
  reaction,
} from 'mobx';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { DiaryDataUniqueId } from '../../diary/types';
import { TrainingDayStore } from '../../training-day/mobx/training-day-store';
import {
  TextAreaBasicData,
  TrainingDayAttribute,
  TrainingDayAttributeState,
  TrainingDayData,
} from '../../training-day/types';
import {
  UpdateAttributeResponse,
  updateTrainingDayAttribute,
} from '../api/update-attribute';

export abstract class AbstractTrainingDayAttributeStore
  implements iAbstractTrainingDayAttributeStore
{
  protected readonly rootStore: RootStore;
  public readonly diaryStore: DiaryStore;
  public readonly trainingDayStore: TrainingDayStore;
  public readonly dataId: DiaryDataUniqueId;

  protected readonly attribute: TrainingDayAttribute;
  protected syncValueToApi = false;

  @observable
  protected _placeholderText: string | null = null;

  @observable
  protected abstract userValue: TrainingDayData['Value'] | null;

  protected reactions: IReactionDisposer[] = [];

  @observable
  private request: RequestStore<UpdateAttributeResponse> | null = null;

  @observable
  protected hasFocus: boolean = false;

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    trainingDayStore: TrainingDayStore,
    attribute: TrainingDayAttribute,
    dataId: DiaryDataUniqueId
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.diaryStore = diaryStore;
    this.trainingDayStore = trainingDayStore;
    this.attribute = attribute;
    this.dataId = dataId;
  }

  @computed
  get isDisabled(): boolean {
    return (
      !this.attribute.IsEditable ||
      !this.hasPermissionToWrite ||
      !this.isInAllowedBackfillScope ||
      this.diaryStore.status !== AsyncStatus.resolved
    );
  }

  get isFocused(): boolean {
    return this.hasFocus;
  }

  @computed
  get disabledReason(): DisabledReason | null {
    if (!this.attribute.IsEditable) {
      return 'not-editable';
    }
    if (!this.isInAllowedBackfillScope && !this.hasPermissionToWrite) {
      const isAthlete = this.rootStore.currentUserStore.role === 'athlete';
      const diaryType = this.dataId.diaryType;

      if (isAthlete && diaryType === 'plan') {
        return 'no-permissions';
      } else if (!isAthlete) {
        return 'no-permissions';
      }
      return 'out-of-backfill-scope';
    }

    if (!this.isInAllowedBackfillScope) {
      return 'out-of-backfill-scope';
    }
    if (!this.hasPermissionToWrite) {
      return 'no-permissions';
    }

    const status = this.diaryStore.status;
    if (status !== AsyncStatus.resolved) {
      if (status === AsyncStatus.pending) {
        return 'loading';
      }
      if (status === AsyncStatus.rejected) {
        return 'loading-error';
      }
    }

    return null;
  }

  @computed
  get isHidden(): boolean {
    return (
      this.attribute.IsExtra &&
      !this.trainingDayStore.visibleExtraAttributes.has(
        this.attribute.AttributeItemId
      )
    );
  }

  @action
  show(): void {
    this.trainingDayStore.showExtraAttribute(this.attribute.AttributeItemId);
  }

  get sortCode(): number {
    return this.attribute.SortCode;
  }

  get isExtra(): boolean {
    return this.attribute.IsExtra;
  }

  get id(): number {
    return this.attribute.AttributeItemId;
  }

  get name(): string {
    return this.attribute.Name;
  }

  get tooltip(): string {
    return this.attribute.ToolTip;
  }

  @computed
  get htmlId(): string {
    return `${this.trainingDayStore.index}_${this.attribute.AttributeItemId}_${this.dataId.groupId}_${this.dataId.athleteId}`;
  }

  @action
  clear(): void {
    this.userValue = null;
    this._placeholderText = null;
  }

  @action
  setApiValue(newValue: TrainingDayData): void {
    this.userValue = newValue.Value;
  }

  @action
  enableApiSynchronization(): void {
    this.syncValueToApi = true;
    this.registerReactions();
  }

  @action
  onFocus(): void {
    this.hasFocus = true;
    if (!this.syncValueToApi) {
      this.enableApiSynchronization();
    }
  }

  @action
  focusWithoutSync(): void {
    this.hasFocus = true;
  }

  @action
  onBlur(): void {
    this.hasFocus = false;
    this.updateAttributeValueDebounced.flush();
  }

  disposeReactions(): void {
    this.updateAttributeValueDebounced.flush();
    this.reactions.forEach(dispose => dispose());
  }

  @computed
  get hasValue(): boolean {
    return Boolean(this.userValue);
  }

  @action
  setPlaceholderText(data: TrainingDayData): void {
    this._placeholderText = (data as TextAreaBasicData).Value;
  }

  get placeholder(): string {
    return this._placeholderText ?? '';
  }

  protected abstract get currentValue(): string | string[];

  @computed
  protected get hasPermissionToWrite(): boolean {
    return this.trainingDayStore.hasPermissionToWrite;
  }

  @computed
  protected get isInAllowedBackfillScope(): boolean {
    return this.trainingDayStore.isInAllowedBackfillScope;
  }

  @computed
  protected get isNullValue(): boolean {
    return this.userValue === null;
  }

  protected async updateAttributeValue(): Promise<void> {
    const value = this.currentValue;
    const date = this.trainingDayStore.currentDate;
    const userId = this.dataId.athleteId;
    const userGroupId = this.dataId.athleteId ? null : this.dataId.groupId;
    if (this.isNullValue || !this.syncValueToApi) {
      return;
    }

    if (this.request?.status === AsyncStatus.rejected) {
      this.rootStore.requestsStore.removeRequest(this.request);
    }
    const transaction = this.rootStore.navbarStore.createTransaction(
      'loading',
      'diary'
    );

    this.request = this.rootStore.requestsStore.createRequest(() =>
      updateTrainingDayAttribute({
        attributeId: this.attribute.AttributeItemId,
        state: this.getState(),
        value,
        date,
        userGroupId,
        userId,
      })
    );

    const response = await this.request.getResponse();
    if (response) {
      transaction.success();
    } else {
      transaction.error();
    }
  }

  protected getState(): TrainingDayAttributeState {
    if (this.dataId.diaryType === 'plan') {
      return 'P';
    } else {
      return 'R';
    }
  }

  protected readonly updateAttributeValueDebounced: DebouncedFunc<
    typeof this.updateAttributeValue
  > = debounce(() => this.updateAttributeValue(), 500);

  protected registerReactions() {
    const updateDisposer = reaction(
      () => this.currentValue,
      this.updateAttributeValueDebounced
    );
    this.reactions.push(updateDisposer);
  }
}
