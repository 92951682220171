export function times<R, T extends (idx: number) => R>(
  size: number,
  content?: T
): R[];
export function times<T>(size: number, content?: T): T[] {
  const emptyArray = Array.from(new Array(size));

  if (content && typeof content !== 'function') {
    emptyArray.fill(content);
  } else if (content && typeof content === 'function') {
    return emptyArray.map((_, idx) => content(idx));
  }

  return emptyArray;
}
