import { UserStore, UsersStore } from '@yarmill/types';
import { PlannerEventStore } from '../mobx/planner-event-store';
import { PlannerEvent } from '../types';

export function createPlannerStore(
  event: PlannerEvent,
  usersStore: UsersStore
): PlannerEventStore {
  const store = new PlannerEventStore();
  store
    .setId(event.id)
    .setTitle(event.title)
    .setStartDate(event.startDate)
    .setEndDate(event.endDate)
    .setEventTypeId(event.eventTypeId)
    .setNotes(event.notes)
    .setLocation(event.location)
    .setLocationType(event.locationType)
    .setOtherSubscribersCount(event.otherSubscribersCount)
    .setAttributes(event.attributes)
    .addUsers(
      event.users
        .map(userId => usersStore.getUserById(userId))
        .filter<UserStore>((u): u is UserStore => Boolean(u))
    )
    .setIsEditable(event.isEditable)
    .setIsRemovable(event.isRemovable)
    .setIsLocked(event.isLocked)
    .setIsAttendeesEditable(event.isAttendeesEditable);

  return store;
}
