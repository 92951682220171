import { TrainingDaysList } from '@yarmill/components';
import { times, useConfig } from '@yarmill/utils';
import { TrainingDayLoadingPlaceholder } from './training-day-loading-placeholder';

export function TrainingWeekLoadingPlaceholder(): JSX.Element {
  const isGoalsViewEnabled = useConfig('goalsViewEnabled');

  return (
    <TrainingDaysList>
      {isGoalsViewEnabled && <TrainingDayLoadingPlaceholder weekPlaceholder />}
      {times(7).map((_, idx) => (
        <TrainingDayLoadingPlaceholder key={idx} />
      ))}
    </TrainingDaysList>
  );
}
