import {
  toastErrorFileUpload,
  toastShowFileUpload,
  toastSuccessFileUpload,
} from '@yarmill/components';
import { FileUploadStore } from '@yarmill/types';
import { useFileUpload } from '@yarmill/utils';
import { ChangeEventHandler, RefObject, useCallback } from 'react';
import { AttachmentAttributeStore } from '../attributes/mobx/attachment-attribute-store';

export function useTrainingDayAttributeFileUpload(
  attribute: AttachmentAttributeStore,
  position: string = 'default'
): [string, RefObject<HTMLInputElement>, ChangeEventHandler] {
  const onSuccess = useCallback(
    (fileUpload: FileUploadStore, toastId: string | number) => {
      const uploadDate = attribute.trainingDayStore.currentDate;
      const athleteId = attribute.diaryStore.athleteId;
      const userGroupId = attribute.diaryStore.groupId;
      attribute
        .assignAttachment(fileUpload, userGroupId, athleteId, uploadDate)
        .then(success => {
          if (!success) {
            toastErrorFileUpload(toastId);
          } else {
            toastSuccessFileUpload(toastId);
          }
        });
    },
    [attribute]
  );

  const onStart = useCallback((fileUpload: FileUploadStore) => {
    return toastShowFileUpload(fileUpload);
  }, []);

  const onError = useCallback(
    (_fileUpload: FileUploadStore, toastId: number | string) => {
      toastErrorFileUpload(toastId);
    },
    []
  );

  const [inputRef, onChange] = useFileUpload(onSuccess, onError, onStart);
  const inputId = `${attribute.trainingDayStore.index}-${attribute.id}-${position}`;

  return [inputId, inputRef, onChange];
}
