import { ApiExportType, Locale, UserGroupId, UserId } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';

export interface ExportSeasonEvaluationToPdfRequestParams {
  seasonId: number;
  userId: UserId | undefined;
  groupId: UserGroupId | undefined;
  exportTypes: ApiExportType[];
  language: Locale;
}

export function exportSeasonEvaluationToPdf(
  params: ExportSeasonEvaluationToPdfRequestParams,
  cancelToken: CancelTokenSource
): AxiosPromise<BlobPart> {
  return axios.post(`api/print/rtc`, params, {
    responseType: 'blob',
    cancelToken: cancelToken.token,
  });
}
