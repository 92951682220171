import ContentLoader from 'react-content-loader';

export function AvatarLoader(): JSX.Element {
  return (
    <ContentLoader
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      viewBox="0 0 66 66"
      width="100%"
      height="100%"
    >
      <circle cx="33" cy="33" r="32" />
    </ContentLoader>
  );
}
