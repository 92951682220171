import { Breakpoints, styled } from '@yarmill/components';

export const StyledDownloadLink = styled.div`
  display: inline-block;
  width: 39px;
  height: 36px;
`;

const ButtonsWrapper = styled.div`
  margin: -8px 0 -8px;
  display: flex;
  align-items: center;
  background-color: #fff;

  tr:hover & {
    background-color: #f7f9fb;
  }

  &:after {
    content: ' ';
    position: absolute;
    height: calc(100% + 2px);
    width: 6px;
    top: 0;
    right: 0;
    background-color: #ffffff;
    transform: translateX(100%) translateY(-1px);
  }
`;

export const DesktopButtonsWrapper = styled(ButtonsWrapper)`
  display: none;
  @media (min-width: ${Breakpoints.tablet}px) {
    display: block;
  }
`;

export const MobileButtonsWrapper = styled(ButtonsWrapper)`
  display: block;
  @media (min-width: ${Breakpoints.tablet}px) {
    display: none;
  }
`;
