import { ExternalIcon, Icon, IconSize, TextInput } from '@yarmill/components';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useVideoSelectorStore } from './video-selector-context';
import { ChangeEvent, useEffect, useRef } from 'react';

const iconSize = IconSize.s16;

const StyledSearch = styled.div`
  margin: 16px 0;
  position: relative;
`;

const StyledInput = styled(TextInput)`
  padding-left: calc(${iconSize} + 8px + 8px) !important;
`;

const StyledIconWrapper = styled.div`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: ${iconSize};
  color: #c2c2c2;
  flex-grow: 0;
  display: flex;
`;

export const Search = observer(function Search(): JSX.Element {
  const intl = useIntl();
  const videoSelectorStore = useVideoSelectorStore();
  const inputRef = useRef<HTMLInputElement>(null);

  function handleChange(e: ChangeEvent<HTMLInputElement>): void {
    const {
      target: { value },
    } = e;
    videoSelectorStore.setSearchTerm(value);
  }

  useEffect(() => {
    // we need to wait until layer animation is finished (300ms)
    setTimeout(() => inputRef.current?.focus(), 300);
  }, []);

  return (
    <StyledSearch>
      <StyledInput
        id="video-selector-search"
        ref={inputRef}
        className="form-control"
        onChange={handleChange}
        placeholder={intl.formatMessage({
          id: 'videoSelector.search.placeholder',
        })}
        noError
        noLabel
      />
      <StyledIconWrapper>
        <Icon size={iconSize}>
          <ExternalIcon name="Search" />
        </Icon>
      </StyledIconWrapper>
    </StyledSearch>
  );
});
