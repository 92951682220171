import {
  StyledTh,
  Text,
  TextSize,
  WhiteSpace,
  css,
  styled,
} from '@yarmill/components';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  CsvTableAlignment,
  CsvTableConfiguration,
  CsvTableHeader,
} from './types';

export interface CsvTableHeaderCellProps {
  config: CsvTableConfiguration;
  column?: CsvTableHeader;
  children?: React.ReactNode;
  alignment?: CsvTableAlignment | null;
  hasExpandableControls: boolean;
}

const HeaderCell = styled(StyledTh)<{
  empty: boolean;
  hasExpandableControls: boolean;
}>`
  position: sticky;
  padding-top: 26px;
  top: -31px;
  background-color: #ffffff;

  @media (max-width: 800px) {
    top: 0;
    padding-top: 0;
  }

  ${props =>
    props.empty &&
    css`
      left: 0;
      z-index: 3;
    `}
  &:first-of-type {
    text-align: ${({ align }) => align};
  }

  ${({ hasExpandableControls }) =>
    hasExpandableControls &&
    css`
      // Hide expandable controls in sticky position
      &:first-of-type:before {
        content: '';
        background-color: #ffffff;
        width: 26px;
        height: 55px;
        position: absolute;
        left: -26px;
        top: 0;
    `}
}

@media print {
  page-break-inside: avoid;
  padding-top: 10px;
  position: relative;
}
`;

export function CsvTableHeaderCell(props: CsvTableHeaderCellProps) {
  const { config, column, children, alignment, hasExpandableControls } = props;
  return (
    <HeaderCell
      empty={!column}
      align={alignment || 'right'}
      hasExpandableControls={hasExpandableControls}
    >
      {column && (
        <Text size={TextSize.s12} bold whiteSpace={WhiteSpace.preWrap}>
          {config.HeaderLabels && column.Label ? (
            <FormattedMessage id={column.Label} />
          ) : (
            column.Label
          )}
        </Text>
      )}
      {children}
    </HeaderCell>
  );
}
