import {
  AlertLayerCloseButtonWrapper,
  AlertLayerContent,
  Button,
  ButtonAppearance,
  Checkbox,
  CloseButton,
  Text,
  TextSize,
  TextTag,
  styled,
  toast,
} from '@yarmill/components';
import { Layer, SettingsKey } from '@yarmill/types';
import { useGroup, useLogger, useSettingsService } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReportingDataStore } from './hooks/use-reporting-data-store';
import { useReportingStore } from './hooks/use-reporting-store';
import { ReportingPageFilterStore } from './mobx/reporting-page-filter-store';

interface SubscribeReportPageDialogProps {
  layer: Layer;
}

const ButtonWrapper = styled(AlertLayerContent.ButtonsWrapper)`
  grid-template-columns: auto;
`;

export const SubscribeReportPageDialog = observer(
  function SubscribeReportPageDialog(
    props: SubscribeReportPageDialogProps
  ): JSX.Element {
    const { layer } = props;
    const settingsService = useSettingsService();
    const reportingStore = useReportingStore();
    const dataStore = useReportingDataStore();
    const page = reportingStore.currentPage;
    const logger = useLogger();
    const groupId = dataStore?.filters.find(
      filter => filter.code === ReportingPageFilterStore.GROUP_FILTER
    )?.value;
    const group = useGroup(Number(groupId));

    const intl = useIntl();
    const settingKey: SettingsKey = `reporting.${page?.code}.subscribe`;
    const currentValue =
      group?.getSetting(settingKey) === 'true' ||
      group?.getSetting(settingKey) === true;

    const [isPageSubscribed, setIsPageSubscribed] =
      useState<boolean>(currentValue);

    async function handleSubscribe(e: FormEvent) {
      e.preventDefault();
      layer.close();

      if (groupId) {
        try {
          await settingsService.saveSettings(
            settingKey,
            String(isPageSubscribed),
            {
              groupId: Number(groupId),
            }
          );
          toast('toast.success.reporting.subscribe', 'success');
        } catch (e: any) {
          logger.error(e);
          toast('toast.error.reporting.subscribe', 'error');
        }
      }
    }

    return (
      <AlertLayerContent.AlertLayerContainer>
        <AlertLayerContent.StickyWrapper>
          <AlertLayerCloseButtonWrapper>
            <CloseButton onClick={layer.close} hideText />
          </AlertLayerCloseButtonWrapper>
        </AlertLayerContent.StickyWrapper>
        <AlertLayerContent.ContentWrapper onSubmit={handleSubscribe}>
          <AlertLayerContent.HeadlineWrapper>
            <Text tag={TextTag.h2} size={TextSize.s16} medium>
              <FormattedMessage id="reporting.subscribe.headline" />
            </Text>
          </AlertLayerContent.HeadlineWrapper>

          <Text tag={TextTag.p} size={TextSize.s14}>
            <Checkbox
              id="subscribe"
              label={intl.formatMessage({
                id: 'reporting.subscribe.checkbox',
              })}
              checked={isPageSubscribed}
              onChange={() => setIsPageSubscribed(r => !r)}
              value="subscribe"
              name="subscribe"
            />
          </Text>

          <ButtonWrapper>
            <Button
              type="submit"
              appearance={ButtonAppearance.Primary}
              noShadow
              data-cy="save"
              disabled={currentValue === isPageSubscribed}
            >
              <FormattedMessage id="reporting.subscribe.buttons.save" />
            </Button>
          </ButtonWrapper>
        </AlertLayerContent.ContentWrapper>
      </AlertLayerContent.AlertLayerContainer>
    );
  }
);
