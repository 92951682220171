import { styled, ThemeDefinition, useTheme } from '@yarmill/components';

interface LoaderProps {
  readonly appearance: 'red' | 'green' | 'neutral';
}

const AnimatedEllipse = styled.ellipse`
  transform-origin: center;
  animation: 1s linear 0s infinite normal none spin;

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

function getColors(
  theme: ThemeDefinition,
  appearance: LoaderProps['appearance']
): [string, string] {
  switch (appearance) {
    case 'red':
      return [theme.color.red, theme.color.redDark];
    case 'green':
      return [theme.color.green, theme.color.greenDark];
    case 'neutral':
      return [theme.color.neutral, theme.color.neutralDark];
  }
}
export function Loader({ appearance }: LoaderProps): JSX.Element {
  const theme = useTheme();
  const [baseColor, darkColor] = getColors(theme, appearance);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00008 13.3333C10.9456 13.3333 13.3334 10.9455 13.3334 7.99996C13.3334 5.05444 10.9456 2.66663 8.00008 2.66663C5.05456 2.66663 2.66675 5.05444 2.66675 7.99996C2.66675 10.9455 5.05456 13.3333 8.00008 13.3333ZM8.00008 10.6666C9.47284 10.6666 10.6667 9.47272 10.6667 7.99996C10.6667 6.5272 9.47284 5.33329 8.00008 5.33329C6.52732 5.33329 5.33341 6.5272 5.33341 7.99996C5.33341 9.47272 6.52732 10.6666 8.00008 10.6666Z"
        fill="url(#loaderGradient)"
      />
      <AnimatedEllipse
        cx="8.00008"
        cy="12"
        rx="1.33333"
        ry="1.33333"
        fill={baseColor}
      />
      <defs>
        <radialGradient
          id="loaderGradient"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.00008 7.99996) rotate(90) scale(5.33333)"
        >
          <stop stopColor={baseColor} />
          <stop offset="1" stopColor={darkColor} />
        </radialGradient>
      </defs>
    </svg>
  );
}
