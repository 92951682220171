import { ResponsiveTableWrapper, styled } from '@yarmill/components';

export const KeyResultsListLayout = styled.div`
  padding-top: ${({ theme }) => theme.size.x2};
  position: relative;
`;

export const KeyResultsListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.size.x2};
  color: ${({ theme }) => theme.color.neutral};
  padding: 0 ${({ theme }) => theme.size.x4};
`;

export const KeyResultsTableResponsiveWrapper = styled(ResponsiveTableWrapper)`
  width: 100%;
`;
export const KeyResultsTable = styled.table`
  border-collapse: collapse;
  background-color: ${({ theme }) => theme.color.neutralPlate};
  padding-bottom: ${({ theme }) => theme.size.x4};
  table-layout: fixed;
  min-width: 100%;

  @media (min-width: 768px) {
    width: 100%;
    max-width: 100%;
  }

  tr:first-child {
    td:nth-child(2) {
      border-top-left-radius: ${({ theme }) => theme.borderRadius.x1};
    }
    td:nth-last-child(2) {
      border-top-right-radius: ${({ theme }) => theme.borderRadius.x1};
    }
  }

  tr:last-child {
    td:nth-child(2) {
      border-bottom-left-radius: ${({ theme }) => theme.borderRadius.x1};
    }
    td:nth-last-child(2) {
      border-bottom-right-radius: ${({ theme }) => theme.borderRadius.x1};
    }
  }

  tr {
    th:nth-child(1),
    td:nth-child(1) {
      width: 32px;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 36px;
      position: relative;
    }
    th:nth-child(3),
    td:nth-child(3) {
      width: 28px;
    }
    th:nth-child(4),
    td:nth-child(4) {
      min-width: 200px;

      @media (min-width: 768px) {
        width: 40%;
        min-width: 250px;
      }
    }
    th:nth-child(5),
    td:nth-child(5),
    th:nth-child(6),
    td:nth-child(6),
    th:nth-child(7),
    td:nth-child(7) {
      min-width: 100px;

      @media (min-width: 768px) {
        width: 20%;
        min-width: 100px;
      }
    }

    th:nth-child(8),
    td:nth-child(8) {
      width: 120px;
    }
    th:nth-child(9),
    td:nth-child(9) {
      width: 32px;
    }
  }
`;

export const KeyResultsTableTh = styled.th<{
  readonly textAlign?: 'left' | 'center' | 'right';
}>`
  color: ${({ theme }) => theme.color.neutral};
  vertical-align: middle;
  height: ${({ theme }) => theme.size.x4};
  text-align: ${({ textAlign = 'center' }) => textAlign};
  padding: ${({ theme }) => theme.size.x025};
`;

export const KeyResultsTableSelectAll = styled.label<{
  readonly disabled: boolean;
}>`
  position: absolute;
  top: 0;
  left: ${({ theme }) => theme.size.x1};
  padding: ${({ theme }) => theme.size.x025};
  color: ${({ theme }) => theme.color.neutral_24};
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;
