import { Icon, IconSize, Tippy } from '@yarmill/components';
import {
  Button,
  ButtonAppearance,
  ContentWrapper,
  FakeButton,
  Header,
  MainPageContentContainer,
} from '@yarmill/components-2';
import { Chat } from '@yarmill/icons-2';
import { useIntl } from 'react-intl';
import { ObjectiveDetailFooter } from './objective-detail-footer';

export function EmptyPage(): JSX.Element {
  const intl = useIntl();

  return (
    <MainPageContentContainer>
      <Header
        left={<FakeButton />}
        inverted
        right={
          <Tippy
            tooltipContent="okrs.activityLog.showActivityLog"
            noWrapper
            touch={false}
          >
            <Button
              as="button"
              disabled
              $appearance={ButtonAppearance.Secondary}
              $appearanceStyle="neutral"
              $iconOnly
              aria-label={intl.formatMessage({
                id: 'okrs.activityLog.showActivityLog',
              })}
            >
              <Icon size={IconSize.s24}>
                <Chat />
              </Icon>
            </Button>
          </Tippy>
        }
      />
      <ContentWrapper />
      <ObjectiveDetailFooter />
    </MainPageContentContainer>
  );
}
