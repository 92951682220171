import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';
import { DashboardType } from '../../../types';
import { VerticalDimension } from '../types';

export function loadVerticalDimensions(
  dashboardIdent: DashboardType
): AxiosPromise<VerticalDimension[]> {
  return axios.get('api/Analytics/SPS-SLCR/VerticalDimension', {
    params: {
      dashboardIdent,
    },
  });
}
