import { ConflictResolution, CopyConflictResponse } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export interface CopyUserPlanToReality {
  UserId: number;
  SourceDay: string;
  ConflictResolution: ConflictResolution;
}

export function copyUserPlanToReality(
  params: CopyUserPlanToReality
): AxiosPromise<boolean | CopyConflictResponse> {
  return axios.post('api/Plan/Week/Copy/UserPlanToReality', params);
}
