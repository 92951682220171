import { GroupsSelect } from '@yarmill/components';
import { useGroupsStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

export interface GroupSelectProps {
  autoFocus?: boolean;
  onChange(groupIds: { label: string; value: number }[]): void;
}

export const GroupSelect = observer(function GroupSelect(
  props: GroupSelectProps
): JSX.Element {
  const groupsStore = useGroupsStore();
  const intl = useIntl();

  return (
    <GroupsSelect
      autoFocus={props.autoFocus}
      onChange={props.onChange}
      options={groupsStore.sortedGroups.map(group => {
        return {
          value: group.id,
          label: group.name,
        };
      })}
      placeholder={intl.formatMessage({
        id: 'sidebar.search.placeholder',
      })}
    />
  );
});
