import { SeasonId, UserId } from '@yarmill/types';
import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { ObjectivePreview } from '../types';

export interface GetObjectivesRequestParams {
  userGroupId: number | undefined;
  seasonId: SeasonId;
}

export type GetObjectivesForGroupResponse = {
  UserId: UserId;
  Objectives: ObjectivePreview[];
}[];

export function getObjectivesForGroup(
  params: GetObjectivesRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<GetObjectivesForGroupResponse> {
  return axios.get(
    `${coreApiTarget}/api/okrs/userGroupId/${params.userGroupId}/seasonId/${params.seasonId}`,
    {
      cancelToken: cancelToken?.token,
      params: {
        instanceCode: devMode ? instance : undefined,
      },
    }
  );
}
