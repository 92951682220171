import { DATE_FORMAT } from '@yarmill/const';
import { CurrentUserStore } from '@yarmill/types';
import moment from 'moment';
import { DiaryType, ViewType } from './types';

export function isAthleteRequired(diaryType: DiaryType): boolean {
  return diaryType === 'reality';
}

export function getTrainingDayElementId(dayIndex: number): string {
  return `training-day-container-${dayIndex}`;
}

export function getDateForDayIndex(week: string, day: number): moment.Moment {
  return moment(week).isoWeekday(day + 1);
}

export function getDateForDayIndexString(week: string, day: number): string {
  return getDateForDayIndex(week, day).format(DATE_FORMAT);
}

export function currentUserHasPermissionToSelectMask(
  currentUser: CurrentUserStore,
  diaryType: DiaryType,
  viewType: ViewType
): boolean {
  if (viewType === 'season') {
    return currentUser.isAllowedTo(`${diaryType}.season`);
  } else {
    return currentUser.isAllowedTo(`${diaryType}.week.activities`);
  }
}
