import {
  FocusedInput,
  OnDatesChangeProps,
  useDatepicker,
  weekdayLabelFormat as weekdayLabelFormatFn,
} from '@datepicker-react/hooks';
import { Icon, IconSize, styled } from '@yarmill/components';
import { Left, Right } from '@yarmill/icons-2';
import moment from 'moment';
import { createContext, useEffect, useState } from 'react';
import { FormattedDate } from 'react-intl';
import { Button, ButtonAppearance } from '../button';
import { Text } from '../text';
import { Month } from './month';

interface DatepickerContextData {
  focusedDate: Date | null;
  isDateFocused(date: Date): boolean;
  isDateSelected(date: Date): boolean;
  isDateHovered(date: Date): boolean;
  onDateFocus(date: Date): void;
  onDateHover(date: Date | null): void;
  onDateSelect(date: Date): void;
  isDateBlocked(date: Date): boolean;
  isFirstOrLastSelectedDate(date: Date): boolean;
}

export const DatepickerContext = createContext<DatepickerContextData>({
  focusedDate: null,
  isDateFocused: () => false,
  isDateSelected: () => false,
  isDateHovered: () => false,
  isDateBlocked: () => false,
  isFirstOrLastSelectedDate: () => false,
  onDateFocus: () => undefined,
  onDateHover: () => undefined,
  onDateSelect: () => undefined,
});

export type SelectRange = 'day' | 'week' | 'range';

export interface DatepickerProps {
  value?: string;
  format?: string;
  selectRange?: SelectRange;
  minBookingDate?: Date;
  maxBookingDate?: Date;
  rangeStart?: string;
  rangeEnd?: string;
  onChange?(
    startDate: string,
    endDate?: string,
    focusedInput?: FocusedInput
  ): void;
  handleChangeMonth?(data: Date, reset: () => void): void;
}

const StyledNavigationWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: ${({ theme }) => theme.size.x1};
  width: 100%;
  color: ${({ theme }) => theme.color.white};
  text-transform: capitalize;
`;

const DatepickerWrapper = styled.div`
  display: grid;
  justify-items: center;
`;

export function Datepicker(props: DatepickerProps): JSX.Element {
  const {
    value,
    format = 'YYYY-MM-DD',
    onChange,
    minBookingDate = moment('1900-01-01').toDate(),
    maxBookingDate = moment().add(50, 'years').toDate(),
    selectRange = 'day',
    rangeStart,
    rangeEnd,
  } = props;

  const [focusedInput, setFocusedInput] = useState<FocusedInput>('startDate');
  const maybeValue = value ? moment(value, format) : moment();
  const validValue = maybeValue.isValid() ? maybeValue : moment();
  const currentDate = validValue.toDate();
  let rangeStartValue = rangeStart ? moment(rangeStart, format) : undefined;
  let rangeEndValue = rangeEnd ? moment(rangeEnd, format) : undefined;

  if (rangeStartValue && rangeEndValue && rangeStartValue > rangeEndValue) {
    rangeStartValue = undefined;
    rangeEndValue = undefined;
  } else if (!rangeStartValue && rangeEndValue) {
    rangeStartValue = undefined;
    rangeEndValue = undefined;
  }

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
    goToDate,
  } = useDatepicker({
    startDate: rangeStartValue?.toDate() ?? currentDate,
    endDate: rangeEndValue?.toDate() ?? null,
    numberOfMonths: 1,
    minBookingDate,
    focusedInput,
    maxBookingDate,
    onDatesChange: handleDataChange,
    changeActiveMonthOnSelect: !rangeEndValue,
  });

  useEffect(() => {
    const momentValue = moment(value);
    if (value && momentValue.isValid()) {
      goToDate(momentValue.toDate());
    }
  }, [value, goToDate]);

  function handleDataChange(data: OnDatesChangeProps): void {
    const { startDate, endDate } = data;
    const newDate = startDate ? moment(startDate).format(format) : '';
    if (selectRange === 'range') {
      const end = endDate ? moment(endDate).format(format) : '';
      const newFocusedInput =
        focusedInput === 'startDate' || !end ? 'endDate' : 'startDate';
      setFocusedInput(newFocusedInput);

      onChange?.(newDate, end, newFocusedInput);
    } else {
      onChange?.(newDate);
    }
  }

  function isButtonDisabled(button: 'prev' | 'next'): boolean {
    const activeMonth = activeMonths[0];

    if (button === 'prev') {
      return (
        activeMonth.year === minBookingDate.getFullYear() &&
        activeMonth.month === minBookingDate.getMonth()
      );
    } else {
      return (
        activeMonth.year === maxBookingDate.getFullYear() &&
        activeMonth.month === maxBookingDate.getMonth()
      );
    }
  }

  function weekdayLabelFormat(date: Date): string {
    return moment(date).toDate().toLocaleString(moment.locale(), {
      weekday: 'short',
    });
  }

  const activeMonth = activeMonths[0];

  return (
    <DatepickerWrapper>
      <DatepickerContext.Provider
        value={{
          focusedDate,
          isDateFocused,
          isDateSelected,
          isDateHovered,
          isDateBlocked,
          isFirstOrLastSelectedDate,
          onDateSelect,
          onDateFocus,
          onDateHover,
        }}
      >
        <StyledNavigationWrapper data-cy="datepicker-navigation">
          <Button
            $square
            type="button"
            onClick={goToPreviousMonths}
            $appearance={ButtonAppearance.Tertiary}
            $appearanceStyle="neutral"
            disabled={isButtonDisabled('prev')}
            data-cy="previous-month"
          >
            <Icon size={IconSize.s24}>
              <Left />
            </Icon>
          </Button>
          <Text align="center" appearance="task13strong" inheritColor>
            <FormattedDate
              value={activeMonth.date}
              month="long"
              year="numeric"
            />
          </Text>
          <Button
            type="button"
            $square
            onClick={goToNextMonths}
            $appearance={ButtonAppearance.Tertiary}
            disabled={isButtonDisabled('next')}
            data-cy="next-month"
            $appearanceStyle="neutral"
          >
            <Icon size={IconSize.s24}>
              <Right />
            </Icon>
          </Button>
        </StyledNavigationWrapper>
        {activeMonths.map(month => (
          <Month
            key={`${month.year}-${month.month}`}
            year={month.year}
            month={month.month}
            selectMode={selectRange}
            selectedDay={currentDate}
            firstDayOfWeek={firstDayOfWeek}
            weekdayLabelFormat={weekdayLabelFormat || weekdayLabelFormatFn}
            rangeStart={rangeStartValue?.toDate()}
            rangeEnd={rangeEndValue?.toDate()}
          />
        ))}
      </DatepickerContext.Provider>
    </DatepickerWrapper>
  );
}
