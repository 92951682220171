import { ReportPermissionsStore } from './report-permissions-store';
import {
  Clarificator,
  ReportItem,
  ReportTitlePosition,
  VerticalAlignment,
} from '../types';
import { TextAlignment } from '@yarmill/components';

export class BaseReportStore {
  protected readonly _item: ReportItem;
  public readonly permissions: ReportPermissionsStore;

  constructor(item: ReportItem) {
    this._item = item;
    this.permissions = new ReportPermissionsStore(item);
  }

  public get code(): string {
    return this._item.Code;
  }

  public get clarificators(): Clarificator[] | null {
    return this._item.Clarificators;
  }

  public get title(): string {
    return this._item.Title;
  }

  public get description(): string {
    return this._item.Description;
  }

  public get kind(): ReportItem['Kind'] {
    return this._item.Kind;
  }

  public get titleAlignment(): TextAlignment | null | undefined {
    return this._item.Params?.TitleAlignment;
  }

  public get sectionVerticalAlignment(): VerticalAlignment | undefined {
    return this._item.Params?.SectionVerticalAlignment;
  }

  public get titlePosition(): ReportTitlePosition {
    return this._item.Params?.TitlePosition ?? 'outside';
  }

  public get noDataMessage(): string | null | undefined {
    return this._item.Params?.NoDataMessage;
  }

  public get hideTooltip(): boolean {
    return this._item.Params?.HideTooltip ?? false;
  }

  public get item(): Readonly<ReportItem> {
    return this._item;
  }
}
