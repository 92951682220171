import { AsyncStatus, UserGroupId, UserId } from '@yarmill/types';
import {
  sortBySortCode,
  useGroup,
  useRootStore,
  useUsersStore,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { SelectValueProps } from './helpers';
import { SelectBox } from './select-box';

const StyledMaskSelector = styled.div<{ readonly width: number }>`
  width: ${({ width }) => width}px;
  @media (max-width: 1024px) {
    margin-right: 10px;
  }
  @media (max-width: 1015px) {
    margin-right: 5px;
  }

  @media (max-width: 990px) {
    margin-right: 0;
  }
`;

interface MaskSelectorProps {
  readonly currentMaskCode: string | undefined;
  readonly onMaskChange?: (mask: string) => void;
  readonly userId: UserId | null;
  readonly groupId: UserGroupId | null;
}
export const MaskSelector = observer(function MaskSelector({
  currentMaskCode,
  userId,
  groupId,
  onMaskChange,
}: MaskSelectorProps): ReactElement | null {
  const rootStore = useRootStore();
  const settingsService = rootStore.settingsService;
  const activityItemsStore = rootStore.activityItemsStore;
  const intl = useIntl();
  const usersStore = useUsersStore();
  const user = usersStore.getUserById(userId);
  const group = useGroup(groupId);
  const [width, setWidth] = useState(0);

  const availableMasks = useMemo(() => {
    return activityItemsStore.masks
      .filter(({ MaskCode }) =>
        user
          ? user.availableMasks.includes(MaskCode)
          : group
            ? group.availableMasks.includes(MaskCode)
            : false
      )
      .sort(sortBySortCode);
  }, [user, group, activityItemsStore.masks]);

  const options = useMemo(
    () =>
      availableMasks.map(mask => ({
        label: intl.formatMessage({ id: mask.Title }),
        value: mask.MaskCode,
      })),

    [availableMasks, intl]
  );

  useEffect(() => {
    const canvas = document.createElement('canvas');
    const ctx = canvas?.getContext('2d');
    const width = Math.max(
      ...options.map(({ label }) => ctx?.measureText(label).width ?? 0)
    );
    setWidth(width + 50);
  }, [options]);

  const handleMaskChange = useCallback(
    async (selected: SelectValueProps) => {
      const maskCode = selected.value as string;
      await settingsService.saveSettings('mask.selectedMask', maskCode, {
        userId: userId || undefined,
        groupId: groupId || undefined,
      });
      onMaskChange?.(maskCode);
    },
    [settingsService, userId, groupId, onMaskChange]
  );

  const selectedValue = useMemo(
    () => options.find(o => o.value === currentMaskCode),
    [options, currentMaskCode]
  );

  if (
    activityItemsStore.masks.length === 1 ||
    availableMasks.length === 0 ||
    rootStore.status !== AsyncStatus.resolved
  ) {
    return null;
  }

  return (
    <StyledMaskSelector width={width}>
      <SelectBox
        id="mask-selector"
        options={options}
        onChange={handleMaskChange}
        value={selectedValue}
        noSeparator
        noLabel
        noError
        noShadow
      />
    </StyledMaskSelector>
  );
});
