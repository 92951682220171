/* eslint-disable */
//! moment.js locale configuration
//! locale : Czech [cs]
//! author : petrbela : https://github.com/petrbela

import moment from 'moment';

var months = {
    standalone:
      'leden_únor_březen_duben_květen_červen_červenec_srpen_září_říjen_listopad_prosinec'.split(
        '_'
      ),
    format:
      'ledna_února_března_dubna_května_června_července_srpna_září_října_listopadu_prosince'.split(
        '_'
      )
  },
  monthsShort = 'led_úno_bře_dub_kvě_čvn_čvc_srp_zář_říj_lis_pro'.split('_'),
  monthsParse = [
    /^led/i,
    /^úno/i,
    /^bře/i,
    /^dub/i,
    /^kvě/i,
    /^(čvn|červen$|června)/i,
    /^(čvc|červenec|července)/i,
    /^srp/i,
    /^zář/i,
    /^říj/i,
    /^lis/i,
    /^pro/i
  ],
  // NOTE: 'červen' is substring of 'červenec'; therefore 'červenec' must precede 'červen' in the regex to be fully matched.
  // Otherwise parser matches '1. červenec' as '1. červen' + 'ec'.
  monthsRegex =
    /^(leden|únor|březen|duben|květen|červenec|července|červen|června|srpen|září|říjen|listopad|prosinec|led|úno|bře|dub|kvě|čvn|čvc|srp|zář|říj|lis|pro)/i;

function plural(n) {
  return n > 1 && n < 5 && ~~(n / 10) !== 1;
}
function translate(number, withoutSuffix, key, isFuture) {
  var result = number + ' ';
  switch (key) {
    case 's': // a few seconds / in a few seconds / a few seconds ago
      return withoutSuffix || isFuture ? 'pár sekund' : 'pár sekundami';
    case 'ss': // 9 seconds / in 9 seconds / 9 seconds ago
      if (withoutSuffix || isFuture) {
        return result + (plural(number) ? 'sekundy' : 'sekund');
      } else {
        return result + 'sekundami';
      }
    case 'm': // a minute / in a minute / a minute ago
      return withoutSuffix ? 'minuta' : isFuture ? 'minutu' : 'minutou';
    case 'mm': // 9 minutes / in 9 minutes / 9 minutes ago
      if (withoutSuffix || isFuture) {
        return result + (plural(number) ? 'minuty' : 'minut');
      } else {
        return result + 'minutami';
      }
    case 'h': // an hour / in an hour / an hour ago
      return withoutSuffix ? 'hodina' : isFuture ? 'hodinu' : 'hodinou';
    case 'hh': // 9 hours / in 9 hours / 9 hours ago
      if (withoutSuffix || isFuture) {
        return result + (plural(number) ? 'hodiny' : 'hodin');
      } else {
        return result + 'hodinami';
      }
    case 'd': // a day / in a day / a day ago
      return withoutSuffix || isFuture ? 'den' : 'dnem';
    case 'dd': // 9 days / in 9 days / 9 days ago
      if (withoutSuffix || isFuture) {
        return result + (plural(number) ? 'dny' : 'dní');
      } else {
        return result + 'dny';
      }
    case 'M': // a month / in a month / a month ago
      return withoutSuffix || isFuture ? 'měsíc' : 'měsícem';
    case 'MM': // 9 months / in 9 months / 9 months ago
      if (withoutSuffix || isFuture) {
        return result + (plural(number) ? 'měsíce' : 'měsíců');
      } else {
        return result + 'měsíci';
      }
    case 'y': // a year / in a year / a year ago
      return withoutSuffix || isFuture ? 'rok' : 'rokem';
    case 'yy': // 9 years / in 9 years / 9 years ago
      if (withoutSuffix || isFuture) {
        return result + (plural(number) ? 'roky' : 'let');
      } else {
        return result + 'lety';
      }
  }
}

moment.defineLocale('cs', {
  months: months,
  monthsShort: monthsShort,
  monthsRegex: monthsRegex,
  monthsShortRegex: monthsRegex,
  // NOTE: 'červen' is substring of 'červenec'; therefore 'červenec' must precede 'červen' in the regex to be fully matched.
  // Otherwise parser matches '1. červenec' as '1. červen' + 'ec'.
  monthsStrictRegex:
    /^(leden|ledna|února|únor|březen|března|duben|dubna|květen|května|červenec|července|červen|června|srpen|srpna|září|říjen|října|listopadu|listopad|prosinec|prosince)/i,
  monthsShortStrictRegex: /^(led|úno|bře|dub|kvě|čvn|čvc|srp|zář|říj|lis|pro)/i,
  monthsParse: monthsParse,
  longMonthsParse: monthsParse,
  shortMonthsParse: monthsParse,
  weekdays: 'neděle_pondělí_úterý_středa_čtvrtek_pátek_sobota'.split('_'),
  weekdaysShort: 'ne_po_út_st_čt_pá_so'.split('_'),
  weekdaysMin: 'ne_po_út_st_čt_pá_so'.split('_'),
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'H:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D. MMMM YYYY',
    LLL: 'D. MMMM YYYY H:mm',
    LLLL: 'dddd D. MMMM YYYY H:mm',
    l: 'D. M. YYYY'
  },
  calendar: {
    sameDay: '[dnes v] LT',
    nextDay: '[zítra v] LT',
    nextWeek: function () {
      switch (this.day()) {
        case 0:
          return '[v neděli v] LT';
        case 1:
        case 2:
          return '[v] dddd [v] LT';
        case 3:
          return '[ve středu v] LT';
        case 4:
          return '[ve čtvrtek v] LT';
        case 5:
          return '[v pátek v] LT';
        case 6:
          return '[v sobotu v] LT';
      }
    },
    lastDay: '[včera v] LT',
    lastWeek: function () {
      switch (this.day()) {
        case 0:
          return '[minulou neděli v] LT';
        case 1:
        case 2:
          return '[minulé] dddd [v] LT';
        case 3:
          return '[minulou středu v] LT';
        case 4:
        case 5:
          return '[minulý] dddd [v] LT';
        case 6:
          return '[minulou sobotu v] LT';
      }
    },
    sameElse: 'L'
  },
  relativeTime: {
    future: 'za %s',
    past: 'před %s',
    s: translate,
    ss: translate,
    m: translate,
    mm: translate,
    h: translate,
    hh: translate,
    d: translate,
    dd: translate,
    M: translate,
    MM: translate,
    y: translate,
    yy: translate
  },
  dayOfMonthOrdinalParse: /\d{1,2}\./,
  ordinal: '%d.',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  }
});
