import { FilesOverviewModule } from '@yarmill/files-overview';
import { Page } from '../page/page';

export function FilesOverview() {
  return (
    <Page>
      <FilesOverviewModule />
    </Page>
  );
}
