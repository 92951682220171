import { ExternalIconName } from '@yarmill/components';
import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from '@yarmill/const';
import { AsyncStatus, PermissionScope } from '@yarmill/types';
import {
  normalizeString,
  useConfig,
  useCurrentUserAllowedGroups,
  useCurrentUserStore,
  useDefaultCommonSearchParams,
  useGroupsStore,
  useLocale,
  useRootStore,
  useUsersStore,
  useYollandaService,
} from '@yarmill/utils';
import { Location } from 'history';
import { useMemo } from 'react';
import { Command } from 'react-command-palette';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { DashboardStore } from '../analytics/types';
import { useAuthStore } from '../auth/hooks';
import { useAnalyticsLink } from '../header/links/analytics-link';
import { useProfileLink } from '../header/links/settings-link';
import { useChangeLanguageHandler } from '../intl/hooks/use-change-language-handler';
import { intlCookies } from '../intl/types';
import { useModulesStore } from '../modules/hooks';

function getIconForDiaryType(type: string): ExternalIconName | undefined {
  switch (type) {
    case 'analytics':
      return 'ChartLine';
    case 'settings':
      return 'Adjustments';
    case 'yollanda':
      return 'CrystalBall';
    default:
      return;
  }
}

function useHeaderLinks(): Command[] {
  const intl = useIntl();
  const history = useHistory();
  const analyticsLink = useAnalyticsLink();
  const profileLink = useProfileLink();

  const searchParams = useDefaultCommonSearchParams();
  const modules = useModulesStore();
  const rootStore = useRootStore();
  const currentUser = useCurrentUserStore();
  const memoryStore = rootStore.memoryStore;
  const legacyDashboards = memoryStore.getItem<DashboardStore[]>(
    'legacy-analytics-store'
  );

  return useMemo(() => {
    const commands: Command[] = [];
    if (rootStore.status !== AsyncStatus.resolved) {
      return commands;
    }

    if (modules.analytics && currentUser.isAllowedTo('analytics')) {
      commands.push({
        priority: 1,
        icon: getIconForDiaryType('analytics'),
        name: intl.formatMessage({ id: 'commandPalette.goToAnalytics' }),
        command: () => {
          history.push(analyticsLink(window.location as unknown as Location));
        },
      });

      legacyDashboards?.forEach(dashboard => {
        if (currentUser.isAllowedTo(`analytics.${dashboard.id}`)) {
          commands.push({
            icon: getIconForDiaryType('analytics'),
            name: intl.formatMessage(
              { id: 'commandPalette.goToLegacyAnalytics' },
              { dashboard: dashboard.id }
            ),
            command: () => {
              history.push(`/analytics/${dashboard.id}?${searchParams}`);
            },
          });
        }
      });
    }

    commands.push({
      priority: 2,
      icon: getIconForDiaryType('settings'),
      name: intl.formatMessage({ id: 'commandPalette.goToProfile' }),
      command: () => {
        history.push(profileLink(window.location as unknown as Location));
      },
    });

    return commands;
  }, [
    rootStore.status,
    legacyDashboards,
    currentUser,
    intl,
    history,
    analyticsLink,
    searchParams,
    profileLink,
    modules.analytics,
  ]);
}

export function useUserSectionLinks(): Command[] {
  const currentUser = useCurrentUserStore();
  const intl = useIntl();
  const locale = useLocale();
  const rootStore = useRootStore();

  return useMemo(() => {
    const commands: Command[] = [];

    currentUser?.data?.Instances.forEach(instance => {
      commands.push({
        icon: 'CircleArrowRight',
        name: intl.formatMessage(
          { id: 'header.navigation.signInTo' },
          { instance: instance.Name }
        ),
        command: () => {
          const link = document.createElement('a');
          link.href = instance.FeUrl;
          link.target = '_blank';
          link.click();
        },
      });
    });

    commands.push({
      icon: 'CirclePlus',
      name: intl.formatMessage({ id: 'header.navigation.signInToOtherTeam' }),
      command: () => {
        const link = document.createElement('a');
        link.href = `https://yarmill.com/${
          locale === 'en' ? 'en/sign-in' : 'cs/prihlasit'
        }`;
        link.target = '_blank';
        link.click();
      },
    });

    commands.push({
      icon: 'Help',
      name: intl.formatMessage({ id: 'header.navigation.help' }),
      command: () => {
        const link = document.createElement('a');
        link.href = 'https://www.yarmill.com/cs-navod/';
        link.target = '_blank';
        link.click();
      },
    });

    commands.push({
      icon: 'Logout',
      name: intl.formatMessage({ id: 'header.navigation.logout' }),
      command: () => {
        rootStore.authStore
          .logOut()
          .finally(() => (window.location.href = '/'));
      },
    });

    return commands;
  }, [currentUser, intl, locale, rootStore]);
}

function useAdminLinks(): Command[] {
  const currentUser = useCurrentUserStore();
  const intl = useIntl();
  const history = useHistory();
  const groupsStore = useGroupsStore();

  return useMemo(() => {
    const commands: Command[] = [];

    if (currentUser.isAdmin || currentUser.isCoach) {
      commands.push({
        icon: 'Adjustments',
        name: intl.formatMessage({
          id: 'commandPalette.goToGroupsSettings',
        }),
        command: () => {
          const searchParams = new URLSearchParams(window.location.search);
          const groupId = searchParams.get(GROUP_SEARCH_PARAM);
          history.push(`/settings/groups/${groupId || ''}`);
        },
      });
    }

    if (currentUser.isAdmin) {
      commands.push({
        icon: 'Adjustments',
        name: intl.formatMessage({
          id: 'commandPalette.goToUsersSettings',
        }),
        command: () => {
          history.push(`/settings/users`);
        },
      });
      commands.push({
        icon: 'CirclePlus',
        name: intl.formatMessage({
          id: 'settings.groups.sidebar.addGroupButton',
        }),
        command: () => {
          history.push(`/settings/groups/createNew`);
        },
      });
      commands.push({
        icon: 'CirclePlus',
        name: intl.formatMessage({
          id: 'settings.users.inviteNewButton',
        }),
        command: () => {
          history.push(`/settings/users`, { openInviteUsersForm: true });
        },
      });
      groupsStore.sortedGroups.forEach(group => {
        commands.push({
          icon: 'CirclePlus',
          name: intl.formatMessage(
            {
              id: 'commandPalette.addUsersToGroup',
            },
            { group: group.name }
          ),
          command: () => {
            history.push(`/settings/groups/${group.id}`, {
              openInviteUsersForm: true,
            });
          },
        });
      });
    }

    return commands;
  }, [currentUser, intl, history, groupsStore.sortedGroups]);
}

function useProfileLinks(): Command[] {
  const users = useUsersStore().activeUsers;
  const history = useHistory();
  const currentUser = useCurrentUserStore();
  const intl = useIntl();

  return useMemo(() => {
    const commands: Command[] = [];
    if (currentUser.isAdmin) {
      users.forEach(user => {
        commands.push({
          icon: 'UserCircle',
          name: intl.formatMessage(
            { id: `commandPalette.goToUserProfile` },
            { user: user.displayName }
          ),
          command: () => {
            const url = `/settings/users/${user.id}`;
            history.push(url);
          },
        });
      });
    }
    return commands;
  }, [users, intl, history, currentUser]);
}

function useDebugLinks(): Command[] {
  const intl = useIntl();
  const authStore = useAuthStore();
  return useMemo(
    () =>
      localStorage.getItem('yarmill-use-instance')
        ? [
            {
              icon: 'Logout',
              name: intl.formatMessage({
                id: `commandPalette.debug.logoutFromInstance`,
              }),
              command: async () => {
                localStorage.removeItem('yarmill-use-instance');
                window.localStorage.removeItem(intlCookies.LOCALE);
                window.localStorage.removeItem(intlCookies.MESSAGES);
                await authStore.logOut();
                window.location.href = '/';
              },
            },
          ]
        : [],
    [intl, authStore]
  );
}

function useLanguages(): Command[] {
  const intl = useIntl();
  const handleChangeLanguage = useChangeLanguageHandler();
  const availableLanguages = useConfig('availableLanguages');

  return useMemo(
    () =>
      availableLanguages
        .filter(lang => lang !== intl.locale)
        .map(lang => ({
          icon: 'Language',
          name: intl.formatMessage(
            {
              id: `commandPalette.changeLanguage`,
            },
            {
              language: lang,
            }
          ),
          command: async () => {
            handleChangeLanguage(lang);
          },
        })),
    [availableLanguages, handleChangeLanguage, intl]
  );
}

function useYollandaLinks(): Command[] {
  const groups = useCurrentUserAllowedGroups();
  const currentUser = useCurrentUserStore();
  const intl = useIntl();
  const yollandaService = useYollandaService();
  const history = useHistory();

  return useMemo(
    () =>
      [
        ['plan', 'week'],
        ['reality', 'week'],
      ]
        .filter(
          ([diaryType]) =>
            currentUser.isAllowedTo(diaryType as PermissionScope) &&
            currentUser.isAllowedTo('yollanda')
        )
        .flatMap(([diaryType, viewType]) =>
          (currentUser.isAthlete ? [groups[0]] : groups).flatMap(group =>
            group.athletes.map(athlete => ({
              icon: getIconForDiaryType('yollanda'),
              name: intl.formatMessage(
                {
                  id: currentUser.isAthlete
                    ? 'commandPalette.yollanda'
                    : 'commandPalette.goToUser.yollanda',
                },
                {
                  user: athlete.displayName,
                  group: group.name,
                  type: diaryType,
                }
              ) as string,
              command: () => {
                const searchParams = new URLSearchParams(
                  window.location.search
                );
                searchParams.set(GROUP_SEARCH_PARAM, String(group.id));
                searchParams.set(ATHLETE_SEARCH_PARAM, String(athlete.id));

                history.push(
                  `/${diaryType}/${viewType}?${searchParams.toString()}`
                );
                yollandaService.show();
              },
            }))
          )
        ),
    [currentUser, groups, history, intl, yollandaService]
  );
}

function useModuleRegistryCommands(): Command[] {
  const rootStore = useRootStore();
  const moduleRegistryService = rootStore.modulesRegistryService;
  const defaultSearchParams = useDefaultCommonSearchParams();

  return useMemo(
    () =>
      moduleRegistryService.enabledModules.flatMap(module =>
        module.commandPaletteCommands.map(cmd => ({
          ...cmd,
          command: cmd.createCommand(defaultSearchParams),
        }))
      ),
    [moduleRegistryService, defaultSearchParams]
  );
}

export function useCommands(): Command[] {
  const headerLinks = useHeaderLinks();
  const userLinks = useUserSectionLinks();
  const adminLinks = useAdminLinks();
  const profileLinks = useProfileLinks();
  const debugLinks = useDebugLinks();
  const languages = useLanguages();
  const yollandaLinks = useYollandaLinks();
  const registryCommands = useModuleRegistryCommands();

  return useMemo(
    () =>
      [
        ...headerLinks,
        ...userLinks,
        ...adminLinks,
        ...profileLinks,
        ...debugLinks,
        ...languages,
        ...yollandaLinks,
        ...registryCommands,
      ]
        .map(command => ({
          ...command,
          plainText: normalizeString(command.name),
        }))
        .sort((a, b) => (a.priority ?? 100) - (b.priority ?? 100)),
    [
      headerLinks,
      userLinks,
      adminLinks,
      profileLinks,
      debugLinks,
      languages,
      yollandaLinks,
      registryCommands,
    ]
  );
}
