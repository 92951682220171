import styled from 'styled-components';

import { DropdownList } from './dropdown';

export const CurrentUserNavigation = styled.div`
  height: 100%;
  width: 50px;

  @media (min-width: 768px) {
    width: 80px;
  }
`;
export const CurrentUserDropdownButton = styled.button`
  height: 100%;
  background: transparent;
  border: 0;
  padding: 12px 8px;
  cursor: pointer;

  @media (min-width: 768px) {
    padding: 20px;
  }
`;

export const UserSectionDropdown = styled(DropdownList)`
  right: 0;
  max-height: calc(100vh - 80px - var(--vh-offset, 0px));
  max-width: 95vw;
  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 768px) {
    margin-top: -16px;
  }
`;
