import { ActivityItem } from '@yarmill/types';
import { useOptionalTranslation } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ActivityTitleCell } from './activities-table/activities-table';
import { FormattedHTMLMessage } from './formatted-html-message';
import { Text, TextSize } from './text';
import { Tippy } from './tippy';

interface ActivityTitleProps {
  activityItem: ActivityItem;
  onClick?: (e: MouseEvent, activityItem: ActivityItem) => void;
}

export const ActivityTitle = observer(function ActivityTitle(
  props: ActivityTitleProps
) {
  const { activityItem, onClick } = props;
  const intl = useIntl();

  const level = useMemo(
    () => (activityItem.Level ? '\u00A0'.repeat(activityItem.Level * 3) : ''),
    [activityItem.Level]
  );
  const name = useOptionalTranslation(activityItem?.Name);
  const tooltipContent =
    activityItem?.ToolTip && intl.messages[activityItem?.ToolTip] ? (
      <FormattedHTMLMessage id={activityItem.ToolTip} />
    ) : (
      activityItem?.ToolTip
    );

  const handleClick = useCallback(
    (e: MouseEvent) => {
      onClick?.(e, activityItem);
    },
    [onClick, activityItem]
  );

  return (
    <ActivityTitleCell onClick={handleClick} className="activity-title-cell">
      <div
        style={{
          color: activityItem.Color || undefined,
        }}
        className="activity-title-wrapper"
      >
        {level}
        <Tippy
          tooltipContent={tooltipContent}
          isEnabled={Boolean(tooltipContent)}
          translateValue={false}
        >
          <Text
            size={TextSize.s12}
            className="activity-title-cell"
            inheritColor
            bold={activityItem.Type === 'S'}
            light={activityItem.Type === 'I'}
          >
            {name}
          </Text>
        </Tippy>
      </div>
    </ActivityTitleCell>
  );
});
