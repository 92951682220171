import { Icon, IconSize } from '@yarmill/components';
import { Button, ButtonAppearance } from '@yarmill/components-2';
import { Back } from '@yarmill/icons-2';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

interface BackToEventTypePanelProps {
  readonly onClick: () => void;
}
export function BackToEventTypePanel({
  onClick,
}: BackToEventTypePanelProps): ReactElement {
  const intl = useIntl();
  return (
    <Button
      $appearance={ButtonAppearance.Secondary}
      $appearanceStyle="white"
      $iconOnly
      onClick={onClick}
      aria-label={intl.formatMessage({
        id: 'sidebar.back',
      })}
      type="button"
    >
      <Icon size={IconSize.s24}>
        <Back />
      </Icon>
    </Button>
  );
}
