import { useCurrentUserStore, useRootStore } from '@yarmill/utils';
import { when } from 'mobx';
import moment from 'moment';
import { useCallback } from 'react';
import { addObjectiveComment } from '../api/add-objective-comment';
import { ObjectiveStore } from '../mobx/objective-store';
import { useOkrsStore } from '../okrs-store-context';

export function useAddCommentHandler(
  objective: ObjectiveStore
): (comment: string) => Promise<void> {
  const okrsStore = useOkrsStore();
  const rootStore = useRootStore();
  const currentUser = useCurrentUserStore();

  return useCallback(
    async (comment: string) => {
      const activitiesCount = objective.activities.push({
        eventDate: moment.utc().format(),
        activityType: 'comment',
        createdBy: currentUser.id,
        label: 'okrs.activity.comment.label',
        labelParams: {
          comment,
          commenter: String(currentUser.id),
        },
        icon: null,
        activityId: null as number | null,
      });
      const activity = objective.activities[activitiesCount - 1];

      let saveAttempts = 0;
      async function saveComment() {
        const request = rootStore.requestsStore.createRequest(() =>
          addObjectiveComment({ objectiveId: objective.id!, comment })
        );
        saveAttempts++;

        const response = await request.getResponse();

        if (request.statusCode === 200 && response) {
          activity.activityId = response as number;
        } else {
          if (saveAttempts < 5) {
            setTimeout(() => {
              saveComment();
            }, 2000 * saveAttempts);
          }
        }
      }

      if (!objective.id) {
        await okrsStore.saveObjective(objective);
        when(
          () => objective.id !== null,
          () => saveComment()
        );
      } else {
        await saveComment();
      }
    },
    [objective, currentUser, okrsStore, rootStore]
  );
}
