import { Logger, UserId } from '@yarmill/types';
import { instance } from '@yarmill/utils';

export function createInstanceUrl(
  url: string,
  userId: UserId,
  token: string,
  logger: Logger
): string {
  const instanceUrl = url.replace(`{instanceIdent}`, instance);
  try {
    const u = new URL(instanceUrl);
    u.searchParams.set('userId', String(userId));
    u.searchParams.set('token', token);

    return u.toString();
  } catch (e) {
    logger.error('Unable to create external service url', url);
    return instanceUrl;
  }
}
