import { GlobalLogger } from '@yarmill/utils';
import { RootStore } from '../app/mobx/root-store';
import { handleServicesRedirectActions } from '../external-services/utils/handle-services-redirect-actions';

export function handleRedirectAction(
  rootStore: RootStore,
  maybeAction?: string | null
): void {
  const action = parseActionType(maybeAction);
  if (!action) {
    return;
  }

  handleServicesRedirectActions(rootStore, action);
}

function parseActionType(maybeAction?: string | null): string | null {
  try {
    return maybeAction ? atob(maybeAction) : null;
  } catch (e: unknown) {
    GlobalLogger.warn('Invalid redirect action provided', maybeAction);
    return null;
  }
}
