import { TextInput, TextInputProps } from './text-input';
import { styled } from '@yarmill/components';
import { Text } from './text';

export interface SuggestedInputProps extends TextInputProps {
  readonly suggestions?: string[];
  readonly onSuggestionClick?: (suggestion: string) => void;
}

const SuggestedInputWrapper = styled.div`
  position: relative;
`;

const SuggestionsLayout = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 0;
  left: 0;
  border-radius: ${({ theme }) => theme.borderRadius.x15};
  padding: ${({ theme }) => theme.size.x1} 0;
  box-shadow: ${({ theme }) => theme.boxShadow.bs2};
  background-color: ${({ theme }) => theme.color.text};
`;

const Suggestion = styled.button`
  width: 100%;
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.size.x1};
  text-align: left;

  :hover {
    background-color: ${({ theme }) => theme.color.blackHover};
  }
`;
export function SuggestedInput({
  suggestions,
  onSuggestionClick,
  ...inputProps
}: SuggestedInputProps): JSX.Element {
  return (
    <SuggestedInputWrapper>
      <TextInput {...inputProps} />
      {suggestions?.length && (
        <SuggestionsLayout>
          {suggestions.map(suggestion => (
            <Suggestion
              key={suggestion}
              onClick={() => onSuggestionClick?.(suggestion)}
            >
              <Text appearance="task13" inheritColor bold>
                {suggestion}
              </Text>
            </Suggestion>
          ))}
        </SuggestionsLayout>
      )}
    </SuggestedInputWrapper>
  );
}
