import { Icon, IconSize, NavigationLinkContent } from '@yarmill/components';

interface NavLinkContentProps {
  linkIcon?: JSX.Element;
  linkText: string | JSX.Element;
}

export function NavLinkContent(props: NavLinkContentProps): JSX.Element {
  const { linkIcon, linkText } = props;

  return (
    <NavigationLinkContent>
      {linkIcon && <Icon size={IconSize.s18}>{linkIcon}</Icon>}
      {linkText}
    </NavigationLinkContent>
  );
}
