export class InitialConfigError extends Error {
  private _originalError?: Error;
  constructor(message: string, originalError?: Error) {
    super(message);
    this.name = 'InitialConfigError';
    this._originalError = originalError;
  }

  public get originalError(): Error | undefined {
    return this._originalError;
  }
}
