import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { OnEventCreatedCallback } from '../components/use-create-event-handlers';
import { PlannerEventStore } from '../mobx/planner-event-store';
import { usePlannerStore } from '../planner-store-context';
import { useSubmitOpenedForm } from './use-submit-opened-form';

export function useCreateEventHandler(): OnEventCreatedCallback {
  const submitOpenedForm = useSubmitOpenedForm();
  const plannerStore = usePlannerStore();
  const intl = useIntl();

  return useCallback(
    createdEvent => {
      submitOpenedForm();
      const store = new PlannerEventStore();
      store
        .setStartDate(createdEvent.start)
        .setEndDate(createdEvent.end)
        .setEventTypeId(
          plannerStore.calendarFormEvent?.eventTypeId ??
            plannerStore.lastEventType ??
            plannerStore.eventTypes[0]?.eventTypeId
        );
      store.titlePlaceholder = intl.formatMessage(
        { id: 'planner.newEvent' },
        { eventType: plannerStore.lastEventType }
      );
      if (!plannerStore.athlete && plannerStore.group) {
        store.addUsers([...plannerStore.group.athletes]);
      }

      plannerStore.formEvent = store;
      plannerStore.calendarFormEvent = store;
    },
    [submitOpenedForm, plannerStore, intl]
  );
}
