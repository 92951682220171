import { Units } from '@yarmill/types';
import { formatValueByUnit } from '@yarmill/utils';
import moment from 'moment';
import {
  KPI,
  KPIType,
  TrendState,
  TrendType,
  isCycleTrend,
  isSeasonTrend,
  isWeekTrend,
} from './types';

export function getKPIValue(kpis: KPI[], code: KPIType): number {
  const kpi = kpis.find(k => k.MeasureCode === code);

  return kpi ? kpi.Value : 0;
}

export function getTrendValue(trends: TrendType[], dayNum: number): number {
  const weekTrend = trends.find(trend => {
    if (isWeekTrend(trend)) {
      return trend.DayNum === dayNum;
    }

    if (isSeasonTrend(trend)) {
      return trend.CycleNum === dayNum;
    }

    if (isCycleTrend(trend)) {
      return trend.WeekNumCycle === dayNum;
    }

    return false;
  });

  return weekTrend ? weekTrend.Value : 0;
}

export function getTrendValues(
  labels: string[],
  data: TrendType[],
  state: TrendState
): number[] {
  const stateData = data.filter(trend => trend.StateId === state);

  return labels.map((_day, idx) => getTrendValue(stateData, idx + 1));
}

export function prepareChartData(
  labels: string[],
  data: TrendType[],
  tooltipLabels: string[]
): {
  label: string;
  tooltipLabel: string;
  plan: number;
  reality: number;
}[] {
  const plan = getTrendValues(labels, data, TrendState.plan);
  const reality = getTrendValues(labels, data, TrendState.reality);

  return labels.map((label, idx) => ({
    label,
    tooltipLabel: tooltipLabels[idx],
    plan: plan[idx],
    reality: reality[idx],
  }));
}

export function getKPIProgressColor(value: number, index: number): string {
  const inActiveColor = '#B7BECC';
  const activeColor = '#F2C80F';
  const totalItems = 19;
  const percentage = index / (totalItems / 100);

  return value > percentage ? activeColor : inActiveColor;
}

export function formatValueByUnitForAnalytics(
  value: number,
  unit: Units
): string {
  const result = formatValueByUnit(value, unit);

  return result ? String(result) : '00:00';
}

export function formatHZHours(value: number): string {
  const result = moment
    .duration(Number(value), 'minutes')
    .format('h:mm', { trim: false });

  return result ? String(result) : '0:00';
}
