import styled, { css } from 'styled-components';
import { DropdownList } from './dropdown';
import { Icon } from '../icon';

export const NavigationList = styled.ul<{ isOpened: boolean }>`
  display: ${({ isOpened }) => (isOpened ? 'block' : 'none')};
  width: 100vw;
  position: absolute;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 6px;
  background: #ffffff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 1;
  max-height: calc(75vh - 55px);
  overflow: auto;

  @media (min-width: 768px) {
    width: auto;
    padding: 0;
    display: inline-flex;
    box-shadow: unset;
    position: relative;
    background: none;
    height: 80px;
    align-items: center;
    overflow: unset;
  }
`;
export const DropdownNavigationList = styled(DropdownList)`
  margin: 0;
  @media (max-width: 767px) {
    position: relative;
    border: 0;
    max-height: unset;
  }
`;

export const SelectedNavigationButton = styled.button`
  border: 0;
  background: transparent;
  height: 100%;
  width: 100%;
  text-transform: uppercase;
  font-family: 'Ubuntu';
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  color: #4a4a4a;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const NavigationLink = css<{ readonly $isActiveLink?: boolean }>`
  display: block;
  padding: 8px 30px;
  text-decoration: none;
  color: ${({ $isActiveLink }) => ($isActiveLink ? '#4a90e2' : '#4a4a4a')};
  line-height: 24px;
  font-family: 'Ubuntu';
  text-transform: uppercase;

  :hover {
    color: #4a90e2;
  }

  ${({ $isActiveLink }) =>
    $isActiveLink &&
    css`
      @media (min-width: 768px) {
        position: relative;
        :before {
          background-color: #4a90e2;
          border-radius: 100%;
          content: '';
          display: block;
          height: 7px;
          left: 50%;
          margin-left: -4px;
          padding: 0;
          position: absolute;
          top: 39px;
          -webkit-transform: translateX(50%);
          transform: translateX(50%);
          width: 7px;
          z-index: -1;
        }
      }
    `}}


     @media (min-width: 768px) {
       font-weight: 500;
       padding: 8px 15px;
     }

     @media (min-width: 1240px) {
       padding: 8px 23px;
     }

     @media (min-width: 1350px) {
       padding: 8px 30px;
     }

    ${DropdownNavigationList} & {
      font-weight: normal;
      :before {
        content: unset;
      }
      @media (min-width: 768px) {
        padding: 10px;
      }

      @media (min-width: 1240px) {
        padding: 10px;
      }
   }

`;
export const MainNavigationDropdownButton = styled.button<{
  readonly $isActiveLink: boolean;
}>`
  ${NavigationLink};
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;

  @media (min-width: 768px) {
    display: inline-flex;
    align-items: center;
    color: ${({ $isActiveLink }) => ($isActiveLink ? '#4a90e2' : '#4a4a4a')};
  }
`;

export const DropdownIcon = styled(Icon)`
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);

  @media (min-width: 1240px) {
    transform: translate(-25%, -50%);
  }

  @media (min-width: 1350px) {
    transform: translate(-50%, -50%);
  }
`;

export const NavigationLinkContent = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  font-family: 'Ubuntu';
  font-size: 16px;
  overflow: auto;
  grid-template-columns: auto auto;
  column-gap: 5px;
  padding-left: 1px; // Safari cuts the icon on left

  @media (min-width: 1240px) {
    column-gap: 5px;
  }

  svg {
    vertical-align: baseline;

    @media (min-width: 1240px) {
      vertical-align: middle;
    }
  }

  @media (min-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    font-size: 10px;
    white-space: nowrap;

    ${DropdownNavigationList} & {
      font-size: 16px;
      text-transform: none;
      font-weight: normal;
    }

    div:first-child {
      margin-bottom: 2px;
    }
  }

  @media (min-width: 1240px) {
    flex-direction: row;
    font-size: 13px;

    ${DropdownNavigationList} & {
      font-size: 16px;
    }
  }
`;
