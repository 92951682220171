import { File } from '@yarmill/types';
import { axios, devMode, instance } from '@yarmill/utils';
import { AxiosProgressEvent, AxiosPromise, CancelTokenSource } from 'axios';

export function uploadFile(
  fileUploadUrl: string,
  formData: FormData,
  cancelToken: CancelTokenSource,
  onUploadProgress: (e: AxiosProgressEvent) => void
): AxiosPromise<File> {
  return axios.post(fileUploadUrl, formData, {
    onUploadProgress,
    cancelToken: cancelToken.token,
    params: {
      instanceCode: devMode ? instance : undefined,
    },
  });
}
