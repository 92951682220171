import {
  ContentBox,
  ContentBoxHead,
  Datepicker,
  SelectRange,
  Text,
  TextSize,
  styled,
} from '@yarmill/components';
import {
  getMaxBookDate,
  getMinBookDate,
  getWeekStartString,
  useSeasonsStore,
} from '@yarmill/utils';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export interface SelectTargetDaysProps {
  week: string;
  selectMode: SelectRange;
  onChange(week: string): void;
}

const StyledDatepickerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

function InternalSelectTargetDays(props: SelectTargetDaysProps): JSX.Element {
  const { week, selectMode, onChange } = props;
  const seasons = useSeasonsStore().seasons;

  const onDateChange = useCallback(
    (date: string): void => {
      const newWeek = getWeekStartString(date);

      if (newWeek) {
        onChange(newWeek);
      }
    },
    [onChange]
  );

  const minBookingDate = useMemo(() => getMinBookDate(seasons), [seasons]);
  const maxBookingDate = useMemo(() => getMaxBookDate(seasons), [seasons]);

  return (
    <ContentBox data-cy="target-week">
      <ContentBoxHead>
        <Text medium size={TextSize.s16}>
          <FormattedMessage id="plan.copy.selectTargetDays" />
        </Text>
      </ContentBoxHead>
      <StyledDatepickerWrapper>
        <Datepicker
          value={week}
          onChange={onDateChange}
          selectRange={selectMode}
          minBookingDate={minBookingDate}
          maxBookingDate={maxBookingDate}
        />
      </StyledDatepickerWrapper>
    </ContentBox>
  );
}

const MemosSelectTargetDays = memo(InternalSelectTargetDays);

export { MemosSelectTargetDays as SelectTargetDays };
