import { Icon, IconSize, Tippy } from '@yarmill/components';
import {
  Button,
  ButtonAppearance,
  DropdownOption,
  DropdownProvider,
  GenericDropdownItemIcon,
} from '@yarmill/components-2';
import { Order } from '@yarmill/icons-2';
import { observer } from 'mobx-react-lite';
import { ReactElement, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { usePlannerStore } from '../planner-store-context';

export const FilterEvents = observer(function FilterEvents(): ReactElement {
  const intl = useIntl();
  const plannerStore = usePlannerStore();

  const options: DropdownOption<string>[] = useMemo(
    () =>
      plannerStore.eventTypes.map(et => ({
        label: intl.formatMessage({ id: et.name }),
        icon: <GenericDropdownItemIcon color={et.color} />,
        value: et.eventTypeId,
      })),
    [intl, plannerStore.eventTypes]
  );

  const isAllSelected = options.length === plannerStore.eventTypeFilter.length;

  const handleSelect = useCallback(
    (eventTypeId: string | null) => {
      if (eventTypeId === null) {
        plannerStore.setEventTypeFilter(
          plannerStore.eventTypeFilter.length === plannerStore.eventTypes.length
            ? []
            : plannerStore.eventTypes.map(et => et.eventTypeId)
        );

        return;
      }

      const prevValue = plannerStore.eventTypeFilter;
      const eventTypeIdx = prevValue.indexOf(eventTypeId);
      let updatedValue;

      if (eventTypeIdx !== -1) {
        prevValue.splice(eventTypeIdx, 1);
        updatedValue = [...prevValue];
      } else {
        updatedValue = [...prevValue, eventTypeId];
      }
      plannerStore.setEventTypeFilter(updatedValue);
    },
    [plannerStore]
  );

  const additionalOption: DropdownOption<null> = useMemo(
    () => ({
      label: intl.formatMessage({
        id: isAllSelected
          ? 'planner.eventTypeFilter.unSelectAll'
          : 'planner.eventTypeFilter.selectAll',
      }),
      value: null,
      isSelected: isAllSelected,
    }),
    [intl, isAllSelected]
  );

  return (
    <Tippy tooltipContent="planner.filterEventTypes" noWrapper touch={false}>
      <DropdownProvider
        options={options}
        multiSelect
        selectedValue={plannerStore.eventTypeFilter}
        handleSelect={handleSelect as any}
        label={intl.formatMessage({ id: 'planner.eventTypeFilter' })}
        additionalOption={additionalOption}
      >
        <Button
          $appearance={ButtonAppearance.Secondary}
          $appearanceStyle="neutral"
          $iconOnly
          aria-label={intl.formatMessage({
            id: 'planner.filterEventTypes',
          })}
          as="div"
        >
          <Icon size={IconSize.s24}>
            <Order />
          </Icon>
        </Button>
      </DropdownProvider>
    </Tippy>
  );
});
