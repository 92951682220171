import { useCallback } from 'react';
import { usePlannerStore } from '../planner-store-context';
import { useCurrentUserIsAllowedToWrite } from './use-current-user-is-allowed-to-write';

export function useSubmitOpenedForm(): () => void {
  const plannerStore = usePlannerStore();
  const currentUserIsAllowedToWrite = useCurrentUserIsAllowedToWrite();

  return useCallback(async () => {
    if (currentUserIsAllowedToWrite) {
      plannerStore.closeFormAfterSave = false;
      await plannerStore.formik?.submitForm();
      plannerStore.closeFormAfterSave = true;
    }
  }, [currentUserIsAllowedToWrite, plannerStore]);
}
