import { Layer } from './stores';

export type LayerType = 'full-screen' | 'alert' | 'context' | 'interaction';
export type LayerRenderer = (mountPoint: HTMLElement, layer: Layer) => void;

export enum InteractionLayerSize {
  A = 'A',
  B = 'B',
  C = 'C',
}

export interface ContextLayerPositionProps {
  readonly top: number | string;
  readonly left: number | string;
  readonly verticalAlignment?: 'top' | 'bottom';
  readonly horizontalAlignment?: 'left' | 'right';
}

export interface LayerOptions {
  readonly onClose?: () => void;
  readonly closeOnShimClick?: boolean;
  readonly showShim?: boolean;
  readonly returnFocus?: boolean;
  readonly disableFocusScroll?: boolean;
  readonly center?: boolean;
}

export interface ContextLayerOptions extends LayerOptions {
  readonly position: ContextLayerPositionProps;
}

export interface InteractionLayerOptions extends LayerOptions {
  size?: InteractionLayerSize;
}

export interface AlertLayerOptions extends LayerOptions {
  readonly transparent?: boolean;
  readonly autoSize?: boolean;
  readonly wide?: boolean;
}

interface OpenedLayerHandle {
  readonly mountPoint: HTMLElement;
  readonly layer: Layer;
  readonly isOpened: true;
}

interface ClosedLayerHandle {
  readonly open: (options?: Partial<LayerOptions>) => void;
  readonly isOpened: false;
}

export type LayerHandle = OpenedLayerHandle | ClosedLayerHandle;
