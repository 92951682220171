import { File } from '@yarmill/types';
import { useLayer } from '@yarmill/utils';
import { ReactElement, useCallback, useMemo } from 'react';
import { LayerPortal } from '../layer-portal';
import { RenameFileDialog } from '../rename-file-dialog';
import { toast } from '../toast-message';

export function useRenameFileHandler(
  file: File,
  save: () => Promise<boolean>,
  autoSave: boolean = true
): [() => void, ReactElement] {
  const renameFileLayer = useLayer('alert', {
    showShim: true,
    closeOnShimClick: true,
  });

  const rename = useCallback(
    async (name: string): Promise<true> => {
      file.FileName = name;
      if (autoSave) {
        const result = await save();

        if (result) {
          toast('toast.success.renameFile', 'success');
        } else {
          toast('toast.error.renameFile', 'error');
        }
      }
      return true;
    },
    [file, save, autoSave]
  );

  return useMemo(
    () => [
      () => {
        if (!renameFileLayer.isOpened) renameFileLayer.open();
      },
      <LayerPortal
        key="rename-file-layer"
        layerHandle={renameFileLayer}
        getContent={layer => (
          <RenameFileDialog close={layer.close} file={file} rename={rename} />
        )}
      />,
    ],
    [renameFileLayer, file, rename]
  );
}
