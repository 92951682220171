import { RightPanelTransition } from '@yarmill/components-2';
import { UserStore } from '@yarmill/types';
import { usePrevious, useUsersStore } from '@yarmill/utils';
import { FormikProvider, useFormik } from 'formik';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { PlannerFormElement } from '../components/form';
import { useCurrentUserIsAllowedToWrite } from '../hooks/use-current-user-is-allowed-to-write';
import { PlannerEventStore } from '../mobx/planner-event-store';
import { usePlannerStore } from '../planner-store-context';
import { PlannerEventFormStep } from '../types';
import { AttendeesStep } from './attendees-step';
import { AttributesStep } from './attributes-step';
import { DateStep } from './date-step';
import { EventDetailsStep } from './event-details-step';
import { EventTypeStep } from './event-type-step';
import { LocationStep } from './location-step';
import { PlannerFormContext } from './planner-form-context';

interface PlannerEventFormProps {
  readonly event: PlannerEventStore;
  readonly calendarEvent: PlannerEventStore;
  readonly close: () => void;
}

export const PlannerEventForm = observer(function PlannerEventForm({
  event,
  calendarEvent,
  close,
}: PlannerEventFormProps): JSX.Element {
  const plannerStore = usePlannerStore();
  const [currentStep, setCurrentStep] =
    useState<PlannerEventFormStep>('details');
  const previousStep = usePrevious(currentStep);
  const usersStore = useUsersStore();
  const currentUserIsAllowedToWrite = useCurrentUserIsAllowedToWrite();
  const groupId = plannerStore.groupId;
  const athleteId = plannerStore.athleteId;

  const isEditable = currentUserIsAllowedToWrite && event.isEditable;
  const formik = useFormik({
    initialValues: { ...event.toJS(), groupId, athleteId },
    onSubmit: async values => {
      const shouldCloseForm = plannerStore.closeFormAfterSave;
      plannerStore.closeFormAfterSave = true;

      if (!isEditable && !event.isAttendeesEditable) {
        return;
      }

      await runInAction(async () => {
        event
          .setTitle(values.title || event.titlePlaceholder)
          .setEventTypeId(values.eventTypeId)
          .setStartDate(values.startDate)
          .setEndDate(values.endDate)
          .setLocation(values.location)
          .setLocationType(values.locationType)
          .setAttributes(values.attributes)
          .setNotes(values.notes)
          .replaceUsers(
            values.users
              .map(userId => usersStore.getUserById(userId))
              .filter<UserStore>((u): u is UserStore => Boolean(u))
          );

        const isNewEvent = !event.id;

        if (isNewEvent || formik.dirty) {
          plannerStore.saveEvent(event, values.groupId, values.athleteId);
        }

        if (isNewEvent) {
          plannerStore.addEvent(event);
        } else {
          plannerStore.calculateLayout();
        }
      });

      plannerStore.lastEventType = values.eventTypeId;

      if (shouldCloseForm) {
        close();
      }
    },
  });
  const { startDate, endDate, eventTypeId, title } = formik.values;

  useEffect(() => {
    plannerStore.formik = formik;
  }, [plannerStore, formik]);

  useEffect(() => {
    runInAction(() => {
      calendarEvent.setEventTypeId(eventTypeId);
      calendarEvent.setStartDate(startDate);
      calendarEvent.setEndDate(endDate);
      calendarEvent.setTitle(title);
    });
  }, [calendarEvent, startDate, endDate, eventTypeId, title]);

  useEffect(() => {
    formik.setFieldValue('startDate', calendarEvent.startDate);
    formik.setFieldValue('endDate', calendarEvent.endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarEvent.startDate, calendarEvent.endDate, formik.setFieldValue]);

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        if (currentStep !== 'details') {
          setCurrentStep('details');
        } else {
          close();
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document?.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentStep, close]);

  const formContextValue = useMemo(
    () => ({ currentStep, setCurrentStep, closeForm: close }),
    [currentStep, close]
  );

  const transitionDirection = previousStep === 'details' ? 'right' : 'left';

  return (
    <PlannerFormContext.Provider value={formContextValue}>
      <FormikProvider value={formik}>
        <PlannerFormElement onSubmit={formik.handleSubmit}>
          <TransitionGroup component={null}>
            <RightPanelTransition
              stepName="details"
              transition={transitionDirection}
              active={currentStep === 'details'}
            >
              <EventDetailsStep />
            </RightPanelTransition>
            <RightPanelTransition
              stepName="eventType"
              transition={transitionDirection}
              active={currentStep === 'eventType'}
            >
              <EventTypeStep />
            </RightPanelTransition>
            <RightPanelTransition
              stepName="date"
              transition={transitionDirection}
              active={currentStep === 'date'}
            >
              <DateStep />
            </RightPanelTransition>
            <RightPanelTransition
              stepName="location"
              transition={transitionDirection}
              active={currentStep === 'location'}
            >
              <LocationStep />
            </RightPanelTransition>
            <RightPanelTransition
              stepName="attributes"
              transition={transitionDirection}
              active={currentStep === 'attributes'}
            >
              <AttributesStep setCurrentStep={setCurrentStep} />
            </RightPanelTransition>
            <RightPanelTransition
              stepName="attendees"
              transition={transitionDirection}
              active={currentStep === 'attendees'}
            >
              <AttendeesStep />
            </RightPanelTransition>
          </TransitionGroup>
        </PlannerFormElement>
      </FormikProvider>
    </PlannerFormContext.Provider>
  );
});
