import {
  AthletesSidebar,
  ContentBox,
  Navbar,
  PageMainContent,
  SeasonSelector,
  Text,
  TextSize,
  ViewSelector,
  WeekDaySelector,
  WeekSelector,
  styled,
} from '@yarmill/components';
import { AttendanceViewType } from '@yarmill/types';
import { useCurrentUserStore, useIsMobile, useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { Attendance } from './attendance';
import {
  AttendanceStoreContextProvider,
  useAttendanceStore,
} from './attendance-store-context';
import { CopyAttendanceButton } from './copy-attendance-button';
import { ExportAttendanceButton } from './export-attendance-button';
import { PhaseSelector } from './phase-selector';

const VIEW_TYPES: AttendanceViewType[] = ['week', 'season'];

const LeftItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 8px;

  @media (min-width: 768px) {
    margin-top: -10px;
    margin-left: -15px;

    & > * {
      margin-top: 10px;
      margin-left: 15px;
    }
  }

  @media (min-width: 991px) {
    margin-left: -25px;
    & > * {
      margin-left: 25px;
    }
    & > :first-child {
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const ObserverAttendancePageRoot = observer(
  function AttendancePageRoot(): JSX.Element {
    const attendanceStore = useAttendanceStore();
    const rootStore = useRootStore();

    const viewType = attendanceStore.viewType;
    const group = attendanceStore.group;
    const isMobile = useIsMobile();
    const currentUser = useCurrentUserStore();
    const phases = attendanceStore.phases;
    const showExportButton =
      currentUser.isAllowedTo('attendance.export') &&
      (!attendanceStore.currentPhase?.activityItemId || phases.length < 2) &&
      viewType === 'week';

    if (!currentUser.isAthlete && !attendanceStore.group && rootStore.isReady) {
      return <Redirect to="/noGroup" />;
    }

    return (
      <>
        {!currentUser.isAthlete ? (
          <AthletesSidebar hideAthletesInGroup />
        ) : undefined}
        <PageMainContent>
          <Navbar
            leftItems={
              <LeftItemsWrapper>
                <ViewSelector
                  moduleKey="attendance"
                  activeViewType={viewType}
                  allowedViewTypes={VIEW_TYPES}
                />
                {viewType === 'week' &&
                  (isMobile ? <WeekDaySelector /> : <WeekSelector />)}
                {viewType === 'season' && <SeasonSelector />}
                {phases.length > 2 && <PhaseSelector />}
              </LeftItemsWrapper>
            }
            rightItems={
              <>
                {showExportButton && <ExportAttendanceButton />}
                <CopyAttendanceButton />
              </>
            }
          />
          {!group && rootStore.isReady ? (
            <ContentBox>
              <Text size={TextSize.s14}>
                <FormattedMessage id="attendance.noAttendance" />
              </Text>
            </ContentBox>
          ) : (
            <Attendance />
          )}
        </PageMainContent>
      </>
    );
  }
);

export const AttendanceModule = memo(() => (
  <AttendanceStoreContextProvider>
    <ObserverAttendancePageRoot />
  </AttendanceStoreContextProvider>
));
