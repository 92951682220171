import { axios } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';

export interface ConnectAppRequestParams {
  readonly origin: string;
  readonly originUserId: string;
  readonly appCode: string;
  readonly isAccepted: boolean;
}

export function connectApp(
  params: ConnectAppRequestParams,
  cancelToken: CancelTokenSource
): AxiosPromise<string> {
  return axios.post(`/api/account/connectapp/process`, params, {
    cancelToken: cancelToken.token,
  });
}
