import { Icon, IconSize, Tippy } from '@yarmill/components';
import { ROUTE_DATE_FORMAT, WEEK_SEARCH_PARAM } from '@yarmill/const';
import { Cal } from '@yarmill/icons-2';
import { SeasonId, SeasonStore } from '@yarmill/types';
import {
  generateUrl,
  sortSeasonStores,
  useCurrentSeasonByDay,
  useCurrentWeek,
  useHistory,
  useSeasonsStore,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  DropdownButtonWrapper,
  DropdownOption,
  DropdownProvider,
} from './dropdown-provider';
import { Text } from './text';

function getSeasonLink(season: SeasonStore, currentWeek: string): string {
  let week = `${season.startYear}${currentWeek.slice(4)}`;
  const newWeek = moment(week);
  const seasonStart = moment(season.startDate);
  const seasonEnd = moment(season.endDate);

  // if we are out of season, use start date of season
  if (newWeek.diff(seasonStart) <= 0 || newWeek.diff(seasonEnd) >= 0) {
    week = seasonStart.format(ROUTE_DATE_FORMAT);
  }

  return week;
}

export const SeasonSelector = observer(function SeasonSelector(): JSX.Element {
  const currentWeek = useCurrentWeek();
  const seasonsStore = useSeasonsStore();
  const seasons = seasonsStore.seasons;
  const currentSeason = useCurrentSeasonByDay(currentWeek);
  const history = useHistory();
  const currentSeasonId = currentSeason?.id;
  const intl = useIntl();

  const setSeason = useCallback(
    (seasonId: SeasonId): void => {
      const season = seasonsStore.getSeasonById(seasonId);
      if (!season) {
        return;
      }
      const newWeek = getSeasonLink(season, currentWeek);
      const link = generateUrl({ [WEEK_SEARCH_PARAM]: newWeek });
      history.push(link);
    },
    [seasonsStore, currentWeek, history]
  );

  const items: DropdownOption<SeasonId>[] = seasons
    .sort(sortSeasonStores)
    .map(season => ({
      label: season.label,
      value: season.id,
    }));

  const currentValue = items.find(i => i.value === currentSeasonId);

  return (
    <Tippy tooltipContent="seasonSelector.label" noWrapper touch={false}>
      <DropdownProvider
        options={items}
        handleSelect={setSeason as any}
        selectedValue={currentValue?.value}
        label={intl.formatMessage({ id: 'navbar.viewSelector.season' })}
      >
        <DropdownButtonWrapper appearance="tangerine">
          <Icon size={IconSize.s16}>
            <Cal />
          </Icon>
          <Text inheritColor bold appearance="button10" monoSpace>
            {currentValue?.label}
          </Text>
        </DropdownButtonWrapper>
      </DropdownProvider>
    </Tippy>
  );
});
