import { usePrevious } from '@yarmill/utils';
import { useEffect, useRef } from 'react';
import { FormUserInvitation } from './types';
import { createEmptyInvitation } from './utils/create-empty-invitation';
import { isValidInvitation } from './utils/is-valid-invitation';

interface AutoAddEmptyRowProps {
  readonly push: (invitation: FormUserInvitation) => void;
  readonly invitations: FormUserInvitation[];
}
export function AutoAddEmptyRow({
  push,
  invitations,
}: AutoAddEmptyRowProps): null {
  const shouldAddEmptyInvitation = useRef(true);
  const currentInvitationsLength = invitations.length;
  const prevInvitationsLength = usePrevious(invitations.length) ?? 0;
  const lastInvitation = invitations[invitations.length - 1];

  useEffect(() => {
    if (prevInvitationsLength > currentInvitationsLength) {
      shouldAddEmptyInvitation.current = false;
    }
  }, [prevInvitationsLength, currentInvitationsLength]);

  useEffect(() => {
    const validInvitations = invitations.filter(isValidInvitation).length;
    if (
      validInvitations === invitations.length &&
      shouldAddEmptyInvitation.current
    ) {
      push(createEmptyInvitation());
      shouldAddEmptyInvitation.current = false;
    }
  }, [invitations, push]);

  useEffect(() => {
    if (!shouldAddEmptyInvitation.current && lastInvitation?.Email) {
      shouldAddEmptyInvitation.current = true;
    }
  }, [lastInvitation?.Email]);

  return null;
}
