import { EvidenceContainer } from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { EvidenceDataStoreContext } from './evidence-data-store-context';
import { EvidenceItem } from './evidence-item';
import { useCategoryScroller } from './hooks/use-category-scroller';
import { useEvidenceStore } from './hooks/use-evidence-store';

function InternalEvidence(): JSX.Element {
  const evidenceStore = useEvidenceStore();
  const size = evidenceStore.moduleConfiguration?.size || 'default';
  const definition = evidenceStore.definition;
  const containerRef = useRef<HTMLDivElement>(null);
  useCategoryScroller(containerRef);

  return (
    <EvidenceDataStoreContext.Provider value={evidenceStore.dataStore}>
      <EvidenceContainer ref={containerRef} size={size}>
        {definition?.map(category => (
          <EvidenceItem definition={category} key={category.ObjectKey} />
        ))}
      </EvidenceContainer>
    </EvidenceDataStoreContext.Provider>
  );
}

export const Evidence = observer(InternalEvidence);
