import {
  PermissionScope,
  TrainingDayStore as iTrainingDayStore,
} from '@yarmill/types';
import { RootStore } from '@yarmill/types';
import {
  IObservableArray,
  action,
  computed,
  makeObservable,
  observable,
} from 'mobx';
import moment from 'moment';
import { AbstractTrainingDayAttributeStore } from '../../attributes/mobx/abstract-training-day-attribute-store';
import { AttachmentAttributeStore } from '../../attributes/mobx/attachment-attribute-store';
import { CgmAttributeStore } from '../../attributes/mobx/cgm-attribute-store';
import { HealthDataAttributeStore } from '../../attributes/mobx/health-data-attribute-store';
import { InputArrayAttributeStore } from '../../attributes/mobx/input-array-attribute-store';
import { RichtextAttributeStore } from '../../attributes/mobx/richtext-attribute-store';
import { WorkoutAttributeStore } from '../../attributes/mobx/workout-attribute-store';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { DiaryDataUniqueId, DiaryEventData } from '../../diary/types';
import { getDateForDayIndexString } from '../../diary/utils';
import {
  TrainingDayAttribute,
  TrainingDayAttributeId,
  TrainingDayData,
  TrainingDayExtraActionType,
} from '../types';

export class TrainingDayStore implements iTrainingDayStore {
  protected readonly rootStore: RootStore;
  public readonly diaryStore: DiaryStore;
  protected readonly _attributes: Map<
    TrainingDayAttributeId,
    AbstractTrainingDayAttributeStore
  > = new Map();
  private readonly _extraAttributes: AbstractTrainingDayAttributeStore[] = [];
  public readonly index: number;
  public readonly dataId: DiaryDataUniqueId;

  @observable
  private _events: IObservableArray<DiaryEventData> = observable.array([]);

  @observable
  readonly visibleExtraAttributes: Map<TrainingDayAttributeId, boolean> =
    new Map();

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    index: number,
    attributes: TrainingDayAttribute[],
    dataId: DiaryDataUniqueId
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.diaryStore = diaryStore;
    this.index = index;
    this.dataId = dataId;
    this.initAttributes(attributes);
  }

  @computed
  get currentDate(): string {
    return getDateForDayIndexString(this.dataId.week, this.index);
  }

  @computed
  get isInAllowedBackfillScope(): boolean {
    if (!this.diaryStore.applyBackfillScope) {
      return true;
    }

    return moment(this.currentDate).diff(this.diaryStore.lastWritableDay!) >= 0;
  }

  @computed
  get attributes(): AbstractTrainingDayAttributeStore[] {
    return Array.from(this._attributes.values()).sort(
      (a, b) => a.sortCode - b.sortCode
    );
  }

  @action
  clear() {
    this.attributes.forEach(attribute => attribute.clear());
    this._events.clear();
    this.visibleExtraAttributes.clear();
  }

  @action
  showExtraAttribute(attributeId: TrainingDayAttributeId): void {
    if (!this.visibleExtraAttributes.has(attributeId)) {
      this.visibleExtraAttributes.set(attributeId, true);
    }
  }

  @computed
  get hasFilledAttributes(): boolean {
    let isFilled = false;

    for (const [, attribute] of this._attributes) {
      if (
        !(
          attribute instanceof WorkoutAttributeStore ||
          attribute instanceof CgmAttributeStore
        ) &&
        attribute.hasValue
      ) {
        isFilled = true;
        break;
      }
    }

    return isFilled;
  }

  isExtraAttributeVisible(attributeId: TrainingDayAttributeId): boolean {
    return this.visibleExtraAttributes.has(attributeId);
  }

  get extraAttributesCount(): number {
    return this._extraAttributes.length;
  }

  @computed
  get extraAttributes(): AbstractTrainingDayAttributeStore[] {
    return this._extraAttributes.filter(
      attribute =>
        !this.visibleExtraAttributes.has(attribute.id) ||
        attribute instanceof AttachmentAttributeStore
    );
  }

  @action
  setAttributeApiValue(
    attributeId: TrainingDayAttributeId,
    value: TrainingDayData
  ): void {
    const attribute = this._attributes.get(attributeId);
    attribute?.setApiValue(value);

    if (attribute?.isExtra) {
      this.visibleExtraAttributes.set(attribute.id, true);
    }
  }

  @action
  setEvents(events: DiaryEventData[]): void {
    if (events.length) {
      const ga = this.rootStore.configStore.goalsAttributes;
      const goalAttributeIds = [ga?.plan.day, ga?.reality.day].filter(
        Boolean
      ) as number[];
      const extraAttribute = this.extraAttributes.find(a =>
        goalAttributeIds.includes(a.id)
      );
      if (extraAttribute) {
        this.showExtraAttribute(extraAttribute?.id);
      }
    }

    this._events.replace(events);
  }

  @action
  setAttributePlaceholder(
    attributeId: TrainingDayAttributeId,
    value: TrainingDayData
  ): void {
    const attribute = this._attributes.get(attributeId);
    attribute?.setPlaceholderText(value);
  }

  @computed
  get showExtraDropdown(): boolean {
    return (
      this.hasPermissionToWrite &&
      this.isInAllowedBackfillScope &&
      (this.extraAttributesCount !== 0 || this.extraActions.length !== 0)
    );
  }

  @computed
  get extraActions(): TrainingDayExtraActionType[] {
    const actions: TrainingDayExtraActionType[] = [];

    if (
      this.rootStore.configStore.copyPlanToReality &&
      this.dataId.diaryType === 'reality'
    ) {
      actions.push('copy-plan-to-reality');
    }

    return actions;
  }

  @computed
  get hasPermissionToWrite(): boolean {
    const currentUser = this.rootStore.currentUserStore;
    const diaryType = this.dataId.diaryType as 'plan' | 'reality';
    const viewType = this.dataId.viewType as 'week' | 'goals' | 'seasonGoals';
    const athleteId = this.dataId.athleteId;
    const groupId = this.dataId.groupId;
    const permissionScope: PermissionScope =
      viewType === 'goals' || viewType === 'seasonGoals'
        ? `${diaryType}.${viewType}.write`
        : `${diaryType}.${viewType}.attributes.write`;
    const isAllowedToWrite = currentUser.isAllowedTo(permissionScope);

    if (athleteId) {
      return (
        isAllowedToWrite &&
        currentUser.getPermissionToUser(athleteId) === 'write'
      );
    } else if (groupId) {
      return (
        isAllowedToWrite &&
        currentUser.getPermissionToGroup(groupId) === 'write'
      );
    } else {
      return false;
    }
  }

  disposeReactions(): void {
    this._attributes.forEach(attribute => attribute.disposeReactions());
  }

  enableApiSynchronization(): void {
    this.attributes.forEach(attribute => attribute.enableApiSynchronization());
  }

  get events(): Readonly<DiaryEventData[]> {
    return this._events;
  }

  protected initAttributes(attributes: TrainingDayAttribute[]): void {
    attributes.forEach(item => {
      let Store;
      switch (item.DataTypeIdent) {
        case 'attachment': {
          Store = AttachmentAttributeStore;
          break;
        }
        case 'input-array': {
          Store = InputArrayAttributeStore;
          break;
        }
        case 'textarea-basic': {
          Store = RichtextAttributeStore;
          break;
        }
        case 'workout': {
          Store = WorkoutAttributeStore;
          break;
        }
        case 'cgm': {
          Store = CgmAttributeStore;
          break;
        }
        case 'health': {
          Store = HealthDataAttributeStore;
          break;
        }
      }

      if (!Store) {
        return;
      }

      const store = new Store(
        this.rootStore,
        this.diaryStore,
        this,
        item,
        this.dataId
      );
      this._attributes.set(item.AttributeItemId, store);

      if (item.IsExtra) {
        this._extraAttributes.push(store);
      }
    });
  }
}
