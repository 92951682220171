import { EvidenceModule } from '@yarmill/evidence';
import { Page } from '../page/page';

export function Evidence() {
  return (
    <Page>
      <EvidenceModule />
    </Page>
  );
}
