import moment from 'moment';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { groupBy } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { ChartTooltip } from '../../chart-tooltip';
import { Trend } from './types';
import { formatNumber } from './utils';

export interface TrendsProps {
  trends: Trend[];
  seasonMonths: string[];
}

const getTooltipLabel = (props: TooltipProps<number, string>): string => {
  const label =
    props.payload && props.payload[0] && props.payload[0].payload
      ? props.payload[0].payload.tooltipLabel
      : props.label;

  return label;
};

export const Trends = observer(function Trends(
  props: TrendsProps
): JSX.Element {
  const { trends, seasonMonths } = props;
  const groupedData = useMemo(
    () => Object.fromEntries(groupBy(trends, 'IndicatorName').entries()),
    [trends]
  );

  const data = useMemo(
    () =>
      seasonMonths.map(month => ({
        label: moment(month).format('MMM'),
        tooltipLabel: moment(month).format('MMMM'),
        ...Object.fromEntries(
          Object.entries(groupedData).map(([key, dataset]) => {
            const item = dataset.find(d => d.Month === month);

            return [key, item ? item.Value : 0];
          })
        ),
      })),
    [groupedData, seasonMonths]
  );

  return (
    <AutoSizer disableHeight style={{ height: 400, width: '100%' }}>
      {({ width }) => (
        <ComposedChart
          width={width}
          height={400}
          data={data}
          margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="label" tickLine={false} axisLine={false} />
          <YAxis
            tickLine={false}
            axisLine={false}
            tick={{ fill: '#D4D6D9' }}
            width={40}
          />
          <Tooltip
            cursor={{ fill: 'rgba(206, 206, 206, 0.2)' }}
            content={(props: TooltipProps<number, string>) => (
              <ChartTooltip
                label={getTooltipLabel(props)}
                items={
                  props.payload
                    ? props.payload.map(item => ({
                        color: item.color as string,
                        id: String(item.name),
                        label: item.name,
                        value: formatNumber(item.value as number),
                      }))
                    : []
                }
              />
            )}
          />
          <Legend verticalAlign="top" height={36} />
          <Bar barSize={15} dataKey="Plán" fill="#F4D35E" />
          <Line
            type="monotone"
            dataKey="Roční plán"
            stroke="#F93943"
            strokeWidth={2}
            isAnimationActive={false}
          />
          <Line
            type="monotone"
            dataKey="Průměr kategorie (plán)"
            stroke="#0D3B66"
            strokeWidth={2}
            isAnimationActive={false}
          />
        </ComposedChart>
      )}
    </AutoSizer>
  );
});
