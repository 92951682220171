import {
  ChartLoader,
  StyledTd,
  StyledTr,
  Table,
  Text,
  TextSize,
} from '@yarmill/components';
import { DATE_FORMAT } from '@yarmill/const';
import moment from 'moment';
import { useMemo } from 'react';
import { FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Cgm } from './types';

const StyledAdditionalInfo = styled(Text)`
  color: #d4d6d9;
`;

export interface SummaryProps {
  detail: Cgm | null;
}

export function Summary(props: SummaryProps): JSX.Element {
  const { detail } = props;
  const intl = useIntl();
  const startTime = useMemo(
    () => moment(detail?.StartTime, DATE_FORMAT).toDate(),
    [detail]
  );

  const endTime = useMemo(
    () => moment(detail?.EndTime, DATE_FORMAT).toDate(),
    [detail]
  );

  const lastUpdated = useMemo(
    () => moment(detail?.LastUpdated, DATE_FORMAT).toDate(),
    [detail]
  );

  return (
    <Table>
      <StyledTr>
        <StyledTd align="left" noBorder>
          <Text size={TextSize.s14}>
            <FormattedMessage id="cgm.summary.timeRange" />
          </Text>
          <br />
          <StyledAdditionalInfo size={TextSize.s14}>
            <FormattedMessage id="cgm.summary.lastUpdated" />
          </StyledAdditionalInfo>
        </StyledTd>
        <StyledTd align="right" noBorder minWidth={detail ? undefined : '60px'}>
          {detail ? (
            <>
              <Text size={TextSize.s14} monoSpace>
                <FormattedTime value={startTime} />
                &nbsp;-&nbsp;
                <FormattedTime value={endTime} />
              </Text>
              <br />
              <StyledAdditionalInfo monoSpace size={TextSize.s14}>
                <FormattedTime value={lastUpdated} />
              </StyledAdditionalInfo>
            </>
          ) : (
            <ChartLoader width={60} height={18} />
          )}
        </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd align="left">
          <Text size={TextSize.s14}>
            <FormattedMessage id="cgm.summary.average" />
          </Text>
          <br />
          <StyledAdditionalInfo size={TextSize.s14}>
            <FormattedMessage id="cgm.summary.range" />
          </StyledAdditionalInfo>
        </StyledTd>
        <StyledTd align="right" minWidth={detail ? undefined : '50px'}>
          {detail ? (
            <>
              <Text size={TextSize.s14} monoSpace>
                <FormattedMessage
                  id="cgm.sample.average"
                  values={{
                    egv: detail.EgvAvg.toLocaleString(intl.locale, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }),
                  }}
                />
              </Text>
              <br />
              <StyledAdditionalInfo monoSpace size={TextSize.s14}>
                <FormattedMessage
                  id="cgm.summary.minMax"
                  values={{ min: detail.EgvMin, max: detail.EgvMax }}
                />
              </StyledAdditionalInfo>
            </>
          ) : (
            <ChartLoader width={50} height={18} />
          )}
        </StyledTd>
      </StyledTr>
    </Table>
  );
}
