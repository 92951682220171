import { ExportItem } from '@yarmill/types';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button, ButtonAppearance } from '../button';
import { ExternalIcon } from '../external-icon';
import { Icon, IconSize } from '../icon';
import { Popover } from '../popover';
import { ExportDropdown } from './export-dropdown';

const ExportButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface ExportSeasonProps {
  exportItems: ExportItem[];
}

export function Export(props: ExportSeasonProps): JSX.Element {
  const { exportItems } = props;

  const intl = useIntl();

  return (
    <Popover
      content={tippy => (
        <ExportDropdown closeMenu={tippy.hide} exportItems={exportItems} />
      )}
    >
      <ExportButton
        type="button"
        appearance={ButtonAppearance.Primary}
        inverted
        noShadow
        aria-label={intl.formatMessage({ id: 'export.season.button' })}
        title={intl.formatMessage({ id: 'export.season.button' })}
        data-cy="export-dropdown"
      >
        <Icon size={IconSize.s14}>
          <ExternalIcon name="Download" />
        </Icon>
        &nbsp;
        <Icon size={IconSize.s12}>
          <ExternalIcon name="ChevronDown" />
        </Icon>
      </ExportButton>
    </Popover>
  );
}
