import { EvidenceObject } from '@yarmill/types';
import { axios, devMode, instance } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

function createDefinitionUrl(definitionUrl: string, moduleKey: string): string {
  return definitionUrl.replace('{moduleKey}', moduleKey);
}

export function getEvidenceDefinition(
  definitionUrl: string,
  moduleKey: string
): AxiosPromise<EvidenceObject[]> {
  return axios.get(createDefinitionUrl(definitionUrl, moduleKey), {
    params: {
      instanceCode: devMode ? instance : undefined,
    },
  });
}
