import { Bold } from '@tiptap/extension-bold';

export const YtdBold = Bold.extend({
  addAttributes() {
    return {
      class: {
        default: null,
        renderHTML: attributes => {
          // … and return an object with HTML attributes.
          return {
            class: `${attributes.class}`,
          };
        },
      },
    };
  },
});
