import { ContentBox } from '../content-box';
import styled, { css } from 'styled-components';
import { ReportsContainer } from '../reporting';
import { StyledTd, StyledTh } from '../table';

export const AnalyticsReportsContainer = styled(ReportsContainer)`
  max-width: unset;
  font-size: 14px;
`;
export const AnalyticsContentBox = styled(ContentBox)`
  padding: 0;
`;

export const AnalyticsReportTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const AnalyticsChartHeader = styled.div`
  margin-bottom: 40px;
  max-width: 466px;
`;

export const ReBarChartStyles = css`
  .scale-label tspan {
    fill: #b7becc;
    font-size: 12px;
  }
`;

export const SlcrTh = styled(StyledTh)`
  vertical-align: middle;
  padding: 4px !important;
`;
export const SlcrTd = styled(StyledTd)<{
  readonly italic?: boolean;
  readonly sum?: boolean;
  readonly isIIIPlus?: boolean;
}>`
  ${({ italic }) => italic && `font-style: italic;`};
  color: #4a4a4a;
  padding: 8px !important;
  ${({ sum }) => sum && 'padding-right: 1.25rem !important;'};
  ${({ isIIIPlus }) => isIIIPlus && 'padding-left: 1.15rem !important;'};

  &.analytics-blue {
    color: #68a920;
  }

  &.analytics-orange {
    color: #d0021b;
  }
`;
