import { GlobalIntl } from '@yarmill/components';
import {
  Command,
  ExternalIconName,
  ModuleCode,
  ModuleDefinitionStore,
  NavigationLink,
  RootStore,
} from '@yarmill/types';
import {
  createModuleLink,
  generateCommandPaletteModuleUserCommands,
  getCurrentUserAllowedGroups,
} from '@yarmill/utils';

export class EvidenceModuleDefinitionStore implements ModuleDefinitionStore {
  private readonly _rootStore: RootStore;
  private readonly _icon: ExternalIconName = 'Table';
  private readonly _baseUrl: string = '/evidence';
  readonly moduleCode: ModuleCode = 'evidence';

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
  }

  get navigationLinks(): NavigationLink[] {
    const currentUser = this._rootStore.currentUserStore;
    const definitions =
      this._rootStore.configStore.evidenceModuleConfigurations;

    return definitions
      .filter(
        definition =>
          currentUser.isAllowedTo('evidence') &&
          currentUser.isAllowedTo(`evidence.${definition.moduleKey}`)
      )
      .map(definition => ({
        icon: definition.icon || this._icon,
        moduleCode: `${this.moduleCode}.${definition.moduleKey}`,
        label: `header.navigation.evidence/${definition.moduleKey}`,
        createClickHandler: defaultSearchParams => location =>
          createModuleLink(
            `${this._baseUrl}/${definition.moduleKey}`,
            defaultSearchParams,
            location
          ),
      }));
  }

  get commandPaletteCommands(): Command[] {
    const history = this._rootStore.historyService.history;
    const currentUser = this._rootStore.currentUserStore;

    const groups = getCurrentUserAllowedGroups(
      this._rootStore.groupsStore,
      currentUser
    );
    const definitions =
      this._rootStore.configStore.evidenceModuleConfigurations;

    if (currentUser.isAthlete) {
      return definitions.map(definition => ({
        priority: 1,
        icon: this._icon,
        name: GlobalIntl.formatMessage({
          id: `commandPalette.goToEvidence.${definition.moduleKey}`,
        }),
        createCommand: (defaultSearchParams: string) => () => {
          history.push(
            createModuleLink(
              `${this._baseUrl}/${definition.moduleKey}`,
              defaultSearchParams,
              history.location
            )
          );
        },
      }));
    }

    return definitions.flatMap(definition =>
      generateCommandPaletteModuleUserCommands(
        groups,
        definition.icon || this._icon,
        `${this.moduleCode}.${definition.moduleKey}`,
        `${this._baseUrl}/${definition.moduleKey}`,
        history,
        GlobalIntl
      )
    );
  }
}
