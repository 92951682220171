import { ReportItemStore } from '../types';
import { useIntl } from 'react-intl';

export function useSectionSubgrid(reports: ReportItemStore[]): boolean {
  const intl = useIntl();

  return reports.some(report => {
    const hasTitle = Boolean(report.title && intl.messages[report.title]);
    const hasDescription = Boolean(
      report.description && intl.messages[report.description]
    );
    const position = report.titlePosition;

    return (hasTitle || hasDescription) && position === 'outside';
  });
}
