import { useCallback } from 'react';
import { usePlannerStore } from '../planner-store-context';

export function useCloseEventFormPanelHandler(): () => void {
  const plannerStore = usePlannerStore();
  return useCallback(() => {
    plannerStore.formEvent = null;
    plannerStore.calendarFormEvent = null;
    plannerStore.formik = null;
  }, [plannerStore]);
}
