import { styled } from '@yarmill/components';
import { useAvatar } from '@yarmill/utils';
import { forwardRef } from 'react';

interface AvatarProps {
  avatar?: string | null;
}

const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const Avatar = forwardRef<HTMLImageElement, AvatarProps>(function Avatar(
  { avatar },
  ref
): JSX.Element {
  const src = useAvatar(avatar);
  return <AvatarImg src={src} alt="avatar" ref={ref} />;
});
