import { Text, TextSize, styled } from '@yarmill/components';
import { times } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FormattedDate } from 'react-intl';
import { GoalRow } from './goal-row';
import { useGoalsStore } from './hooks';

export interface WeekContainerProps {
  index: number;
}

const WeekHeader = styled.div<{ index: number }>`
  justify-self: center;
  @media (max-width: ${1024 - 1}px) {
    margin-top: 40px;
    margin-bottom: 10px;
    ${props => props.index === 0 && `margin-top: 0;`}
  }
`;

function getWeekHeader({
  start,
  end,
}: {
  start: moment.Moment;
  end: moment.Moment;
}): JSX.Element {
  return (
    <Text size={TextSize.s14}>
      <FormattedDate value={start.toDate()} day="numeric" month="numeric" />
      &nbsp;-&nbsp;
      <FormattedDate
        value={end.toDate()}
        day="numeric"
        month="numeric"
        year="numeric"
      />
    </Text>
  );
}

function InternalWeekContainer(props: WeekContainerProps): JSX.Element | null {
  const { index } = props;
  const goalsStore = useGoalsStore();
  const weekOffset = index * 9;

  return (
    <>
      {goalsStore && goalsStore?.attributes.length !== 0 && (
        <>
          <WeekHeader index={index}>
            {getWeekHeader(goalsStore.getWeekRange(index))}
          </WeekHeader>
          <GoalRow
            attribute={goalsStore.attributes[weekOffset].attribute}
            cycle
          />
          <GoalRow
            attribute={goalsStore.attributes[1 + weekOffset].attribute}
            week
          />
          {times(7).map((_, idx) => (
            <GoalRow
              key={idx}
              attribute={goalsStore.attributes[idx + 2 + weekOffset].attribute}
            />
          ))}
        </>
      )}
    </>
  );
}

export const WeekContainer = observer(InternalWeekContainer);
