import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';
import { ApiFilters, DashboardType, Filter } from '../types';

export function getFilters(
  dashboard: DashboardType,
  currentFilters: ApiFilters
): AxiosPromise<Filter[]> {
  return axios.post('api/Analytics/Filter', currentFilters, {
    params: {
      dashboardIdent: dashboard,
    },
  });
}
