import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  Icon,
  IconSize,
  LayerPortal,
} from '@yarmill/components';
import { useLayer } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useReportingStore } from './hooks/use-reporting-store';
import { SubscribeReportPageDialog } from './subscribe-report-page-dialog';
export const SubscribeButton = observer(
  function SubscribeButton(): JSX.Element | null {
    const intl = useIntl();
    const reportingStore = useReportingStore();
    const reportingPage = reportingStore.currentPage;
    const layerHandle = useLayer('alert', {
      closeOnShimClick: true,
      showShim: true,
    });

    const onClick = (): void => {
      if (!layerHandle.isOpened) {
        layerHandle.open();
      }
    };

    if (!reportingPage?.enableSubscription) {
      return null;
    }

    return (
      <>
        <Button
          type="button"
          appearance={ButtonAppearance.Primary}
          inverted
          square
          noShadow
          aria-label={intl.formatMessage({ id: 'reporting.subscribe.button' })}
          title={intl.formatMessage({ id: 'reporting.subscribe.button' })}
          onClick={onClick}
        >
          <Icon size={IconSize.s16}>
            <ExternalIcon name="Mail" />
          </Icon>
        </Button>
        <LayerPortal
          layerHandle={layerHandle}
          getContent={layer => <SubscribeReportPageDialog layer={layer} />}
        />
      </>
    );
  }
);
