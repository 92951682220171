import { useContext } from 'react';
import { EvidenceStoreContext } from '../context/evidence-store-context';
import { EvidenceStore } from '../mobx/evidence-store';

export function useEvidenceStore(): EvidenceStore {
  const store = useContext(EvidenceStoreContext);

  if (!store) {
    throw new Error('EvidenceStore not provided!');
  }

  return store;
}
