import { ChartConfig } from '../types';
import { useMemo } from 'react';
import { DataItem } from '../../reporting/types';
import { DEFAULT_MARKER_SIZE } from '../../reporting/const';

export function useMarkerPadding(configs: ChartConfig[], data: DataItem[]) {
  return useMemo(() => {
    const hasLine = configs.some(
      c => c.type === 'Line' || c.type === 'MultiLine'
    );
    if (!hasLine) {
      return 0;
    }

    return Math.max(
      ...data.flatMap(item =>
        configs.map(config =>
          'getMarkerSize' in config
            ? (config.getMarkerSize(item) ?? DEFAULT_MARKER_SIZE)
            : 0
        )
      )
    );
  }, [configs, data]);
}
