import { Placement, useMergeRefs } from '@floating-ui/react';
import {
  Color,
  ThemeDefinition,
  css,
  styled,
  useTheme,
} from '@yarmill/components';
import { Text } from '@yarmill/components-2';
import { ISO_DATE_FORMAT } from '@yarmill/const';
import moment from 'moment';
import { ReactNode, useContext, useEffect, useMemo, useRef } from 'react';
import { FormattedDate } from 'react-intl';
import { useMonthIndexes } from './month-index-context';
import { useEditEventHandlers } from './use-edit-event-handlers';
import { useEventPreview } from './use-event-preview';
import { PlannerZoomLevel, getZoomLevelHeight } from './utils';
import { ZoomLevelContext } from './zoom-level-context';
// import { RIGHT_PANEL_WIDTH } from '../../components-2/right-panel';

export interface TrainingCycleEvent {
  readonly start: string;
  readonly end: string;
  readonly name: ReactNode;
  readonly color?: Color;
}

export interface TrainingEventProps extends TrainingCycleEvent {
  readonly conflictingEvents?: number;
  readonly conflictOffset?: number;
  readonly showEditHandles?: boolean;
  readonly showEditMode?: boolean;
  readonly onStartUpdate?: (date: string) => void;
  readonly onEndUpdate?: (date: string) => void;
  readonly onClick?: () => void;
  readonly scrollIntoView?: boolean;
  readonly getPreviewContent?: (placement: Placement) => JSX.Element;
}

interface StyledEventProps {
  readonly column: string | number;
  readonly row: string | number;
  readonly conflicts: number;
  readonly offset: number;
  readonly color?: Color;
  readonly showTopEditHandle?: boolean;
  readonly showBottomEditHandle?: boolean;
  readonly showEditHandles?: boolean;
  readonly zoomLevel: PlannerZoomLevel;
}

function getDayLabelMarginLeft(
  zoomLevel: PlannerZoomLevel,
  theme: ThemeDefinition
): string {
  switch (zoomLevel) {
    // label width + margin left + margin right + left border
    case 2:
      return theme.size.x1;
    case 1:
      return theme.size.x05;
    case 0:
      return theme.size.x025;
  }
}
function getDayLabelWidth(
  zoomLevel: PlannerZoomLevel,
  theme: ThemeDefinition
): string {
  switch (zoomLevel) {
    // label width + margin left + margin right + left border
    case 2:
      return `(${theme.size.x4} + ${getDayLabelMarginLeft(
        zoomLevel,
        theme
      )} + ${theme.size.x05} + ${theme.size.x0125})`;
    case 1:
      return `(${theme.size.x3} + ${getDayLabelMarginLeft(
        zoomLevel,
        theme
      )} + ${theme.size.x025} + ${theme.size.x0125})`;
    case 0:
      return `(${theme.size.x25} + ${getDayLabelMarginLeft(
        zoomLevel,
        theme
      )} + ${theme.size.x025} + ${theme.size.x0125})`;
  }
}

function getRightPadding(
  zoomLevel: PlannerZoomLevel,
  theme: ThemeDefinition
): string {
  switch (zoomLevel) {
    case 2:
      return theme.size.x1;
    case 1:
      return theme.size.x075;
    case 0:
      return theme.size.x05;
  }
}

function getContainerWidth(
  zoomLevel: PlannerZoomLevel,
  theme: ThemeDefinition
): string {
  return `(100% - ${getDayLabelWidth(zoomLevel, theme)} - ${getRightPadding(
    zoomLevel,
    theme
  )})`;
}

function getEventGap(
  zoomLevel: PlannerZoomLevel,
  theme: ThemeDefinition
): string {
  switch (zoomLevel) {
    case 2:
      return theme.size.x1;
    case 1:
      return theme.size.x05;
    case 0:
      return theme.size.x025;
  }
}

function getEventWidth(
  conflicts: number,
  theme: ThemeDefinition,
  zoomLevel: PlannerZoomLevel
): string {
  if (conflicts === 0) {
    return `(${getContainerWidth(zoomLevel, theme)})`;
  }

  return `(((100% - ${getDayLabelMarginLeft(
    zoomLevel,
    theme
  )} - ${getRightPadding(zoomLevel, theme)}) / ${conflicts}) - ((${getEventGap(
    zoomLevel,
    theme
  )} * ${conflicts - 1}) / ${conflicts}))`;
}

function getEventMarginLeft(
  conflicts: number,
  offset: number,
  theme: ThemeDefinition,
  zoomLevel: PlannerZoomLevel
): string {
  return `(${getEventWidth(conflicts, theme, zoomLevel)} * ${offset}) + ${
    conflicts === 0
      ? getDayLabelWidth(zoomLevel, theme)
      : getDayLabelMarginLeft(zoomLevel, theme)
  } + (${offset} * ${getEventGap(zoomLevel, theme)})`;
}
export const StyledEvent = styled.div.attrs<StyledEventProps>(
  ({ conflicts, offset, column, row, theme, zoomLevel }) => ({
    style: {
      gridColumn: column,
      gridRow: row,
      width: `calc(${getEventWidth(conflicts, theme, zoomLevel)})`,
      marginLeft: `calc(${getEventMarginLeft(
        conflicts,
        offset,
        theme,
        zoomLevel
      )})`,
    },
  })
)<StyledEventProps>`
  background: ${({ theme, color, showEditHandles }) =>
    theme.color[
      showEditHandles
        ? color
          ? (`${color}Dark` as Color)
          : 'navyDark'
        : (color ?? 'navy')
    ]};
  color: ${({ theme, color }) =>
    theme.color[color ? (`${color}_8` as Color) : 'navy_8']};
  border-radius: ${({ theme }) => theme.borderRadius.x05};
  padding: ${({ theme }) => theme.size.x05};
  margin: ${({ theme }) => theme.size.x025} ${({ theme }) => theme.size.x0125};
  word-break: break-word;
  hyphens: auto;
  position: relative;
  opacity: ${({ showEditHandles }) => (showEditHandles ? 0.9 : 1)};
  cursor: ${({ showEditHandles }) => (showEditHandles ? 'default' : 'pointer')};
  user-select: none;
  transition: margin 300ms ease;
  :hover {
    background: ${({ theme, color }) =>
      theme.color[color ? (`${color}Dark` as Color) : 'navyDark']};
  }
`;

const EditHandle = styled.button<{
  readonly position: 'top' | 'bottom';
  readonly isGrabbing?: boolean;
}>`
  position: absolute;
  cursor: row-resize;
  border: 0;
  padding: 0;
  background: transparent;
  width: 100%;
  height: 5px;
  left: 0;
  user-select: none;

  ${({ position }) =>
    position === 'top'
      ? css`
          top: 0;
        `
      : css`
          bottom: 0;
        `}
`;

const LABEL_ROWS = 1;

function getEventRow(start: moment.Moment, end: moment.Moment): string {
  return `${start.date() + LABEL_ROWS} / ${end.date() + LABEL_ROWS + 1}`; // css grid indexes rows from 1
}
function getEventColumn(start: moment.Moment, monthIndexes: string[]): string {
  const col = monthIndexes.indexOf(
    start.clone().startOf('month').format(ISO_DATE_FORMAT)
  );

  return `${col} / ${col + 1}`; // css grid indexes column from 1
}

interface EventDuration {
  readonly start: moment.Moment;
  readonly end: moment.Moment;
}

interface InternalTrainingEventProps extends EventDuration {
  readonly name: ReactNode;
  readonly conflictingEvents: number;
  readonly conflictOffset: number;
  readonly color?: Color;
  readonly showTopEditHandle?: boolean;
  readonly showBottomEditHandle?: boolean;
  readonly showEditHandles?: boolean;
  readonly onStartUpdate?: (date: string) => void;
  readonly onEndUpdate?: (date: string) => void;
  readonly onClick?: () => void;
  readonly showEditMode?: boolean;
  readonly realStart: moment.Moment;
  readonly realEnd: moment.Moment;
  readonly scrollIntoView?: boolean;
  readonly getPreviewContent?: (placement: Placement) => JSX.Element;
}

interface StyledEventTextContainerProps {
  readonly alignVertically: boolean;
}
const StyledEventTextContainer = styled.div<StyledEventTextContainerProps>`
  position: absolute;
  top: ${({ theme }) => theme.size.x05};
  max-height: calc(100% - ${({ theme }) => theme.size.x1});
  width: calc(100% - ${({ theme }) => theme.size.x1});
  overflow: hidden;
  //pointer-events: none;

  ${({ alignVertically }) =>
    alignVertically &&
    css`
      top: 50%;
      transform: translateY(-50%);
    `};
`;

const StyledEventDate = styled(Text)`
  color: ${({ color }) => color};
`;

function getLineClamp(
  eventDuration: number,
  height: number,
  theme: ThemeDefinition
) {
  const EVENT_TEXT_CONTAINER_PADDING = parseInt(theme.size.x05) * 2;
  const LINE_HEIGHT = parseInt(theme.text.appearance.label10.lineHeight);
  return Math.floor(
    (eventDuration * height - EVENT_TEXT_CONTAINER_PADDING) / LINE_HEIGHT
  );
}

function InternalTrainingEvent(props: InternalTrainingEventProps): JSX.Element {
  const {
    start,
    end,
    name,
    conflictingEvents,
    conflictOffset,
    color,
    showTopEditHandle,
    showBottomEditHandle,
    showEditHandles,
    onStartUpdate,
    onEndUpdate,
    onClick,
    showEditMode,
    realStart,
    realEnd,
    getPreviewContent,
    scrollIntoView,
  } = props;
  const localRef = useRef<HTMLDivElement>(null);
  const [refs, getReferenceProps, preview] = useEventPreview(getPreviewContent);
  const eventRef = useMergeRefs([localRef, refs.setReference]);
  const monthIndexes = useMonthIndexes();
  const editStartHandlers = useEditEventHandlers(onStartUpdate, 'top');
  const editEndHandlers = useEditEventHandlers(onEndUpdate, 'bottom');
  const column = useMemo(
    () => getEventColumn(start, monthIndexes),
    [start, monthIndexes]
  );
  const row = useMemo(() => getEventRow(start, end), [start, end]);
  const showEventDate = start.date() !== end.date();
  const eventDuration = Math.abs(end.diff(start, 'day')) + 1;
  const zoomLevel = useContext(ZoomLevelContext);
  const height = getZoomLevelHeight(zoomLevel);
  const theme = useTheme();

  useEffect(() => {
    if (scrollIntoView) {
      localRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [scrollIntoView]);

  return (
    <>
      <StyledEvent
        ref={eventRef}
        className="planner-event"
        column={column}
        row={row}
        conflicts={conflictingEvents}
        offset={conflictOffset}
        color={color}
        showTopEditHandle={showTopEditHandle}
        showBottomEditHandle={showBottomEditHandle}
        showEditHandles={showEditHandles || showEditMode}
        onClick={onClick}
        zoomLevel={zoomLevel}
        {...getReferenceProps()}
      >
        {showTopEditHandle && (
          <EditHandle position="top" {...editStartHandlers} />
        )}
        <StyledEventTextContainer alignVertically={!showEventDate}>
          <Text
            appearance={zoomLevel === 2 ? 'task13' : 'label10'}
            inheritColor
            as={eventDuration === 1 ? 'div' : undefined}
            ellipsis={eventDuration === 1}
            whiteSpace={eventDuration === 1 ? 'noWrap' : undefined}
            lineClamp={
              eventDuration > 1
                ? getLineClamp(eventDuration, height, theme)
                : undefined
            }
          >
            {showEventDate && (
              <StyledEventDate
                color={
                  theme.color[color ? (`${color}_24` as Color) : 'navy_24']
                }
                as="div"
                upperCase
                appearance={zoomLevel === 2 ? 'text12strong' : 'text8strong'}
              >
                <FormattedDate
                  value={realStart.toDate()}
                  day="numeric"
                  month={
                    realStart.isSame(realEnd, 'month') ? undefined : 'short'
                  }
                  weekday={
                    realStart.isSame(realEnd, 'month') ? 'short' : undefined
                  }
                />{' '}
                -{' '}
                <FormattedDate
                  value={realEnd.toDate()}
                  day="numeric"
                  month={
                    realStart.isSame(realEnd, 'month') ? undefined : 'short'
                  }
                  weekday={
                    realStart.isSame(realEnd, 'month') ? 'short' : undefined
                  }
                />
              </StyledEventDate>
            )}
            {name}
          </Text>
        </StyledEventTextContainer>
        {showBottomEditHandle && (
          <EditHandle position="bottom" {...editEndHandlers} />
        )}
      </StyledEvent>
      {preview}
    </>
  );
}

export function TrainingEvent(props: TrainingEventProps): JSX.Element {
  const {
    start,
    end,
    name,
    conflictingEvents = 0,
    conflictOffset = 0,
    color,
    showEditHandles,
    onStartUpdate,
    onEndUpdate,
    onClick,
    showEditMode,
    scrollIntoView,
    getPreviewContent,
  } = props;
  const startDate = useMemo(() => moment(start, ISO_DATE_FORMAT), [start]);
  const endDate = useMemo(
    () => moment(end, ISO_DATE_FORMAT).endOf('day'),
    [end]
  );
  const monthIndexes = useMonthIndexes();
  const firstMonth = monthIndexes[1];
  const lastMonth = monthIndexes[monthIndexes.length - 1];

  const continuousEvents: EventDuration[] = useMemo(() => {
    if (startDate.isSame(endDate, 'month')) {
      return [
        {
          start: startDate,
          end: endDate,
        },
      ];
    }

    const current = startDate.clone();
    const events: EventDuration[] = [];
    const seasonStart = moment(firstMonth).startOf('month');
    const seasonEnd = moment(lastMonth).endOf('month');

    while (
      current.isSameOrBefore(endDate) &&
      current.isSameOrBefore(seasonEnd)
    ) {
      if (
        current.isSame(startDate, 'month') &&
        current.isSameOrAfter(seasonStart)
      ) {
        events.push({
          start: startDate.clone(),
          end: startDate.clone().endOf('month'),
        });
      } else if (
        current.isSame(endDate, 'month') &&
        current.isSameOrBefore(seasonEnd)
      ) {
        events.push({
          start: endDate.clone().startOf('month'),
          end: endDate.clone(),
        });
      } else if (
        current.isSameOrAfter(seasonStart) &&
        current.isSameOrBefore(seasonEnd)
      ) {
        events.push({
          start: current.clone().startOf('month'),
          end: current.clone().endOf('month'),
        });
      }
      current.add(1, 'month').startOf('month');
    }

    return events;
  }, [startDate, endDate, lastMonth, firstMonth]);

  return (
    <>
      {continuousEvents.map((evt, idx) => (
        <InternalTrainingEvent
          key={evt.start.format()}
          start={evt.start}
          end={evt.end}
          name={name}
          conflictingEvents={conflictingEvents}
          conflictOffset={conflictOffset}
          color={color}
          showTopEditHandle={
            showEditHandles && evt.start.isSame(startDate, 'day')
          }
          showBottomEditHandle={
            showEditHandles && evt.end.isSame(endDate, 'day')
          }
          showEditHandles={showEditHandles}
          onStartUpdate={onStartUpdate}
          onEndUpdate={onEndUpdate}
          onClick={onClick}
          showEditMode={showEditMode}
          realStart={startDate}
          realEnd={endDate}
          scrollIntoView={scrollIntoView && idx === continuousEvents.length - 1}
          getPreviewContent={getPreviewContent}
        />
      ))}
    </>
  );
}
