import {
  EvidenceTableFilters,
  SortConfig,
  TableFiltersStore as iTableFiltersStore,
} from '@yarmill/types';
import {
  ObservableMap,
  action,
  computed,
  makeObservable,
  observable,
  toJS,
} from 'mobx';

export class TableFiltersStore implements iTableFiltersStore {
  @observable
  private _sortConfig: SortConfig | null = null;

  @observable
  private _filters: EvidenceTableFilters = new Map();

  constructor(defaultFilters?: EvidenceTableFilters) {
    makeObservable(this);
    (this.filters as ObservableMap).merge(defaultFilters);
  }

  @computed.struct
  get sortConfig(): SortConfig | null {
    return this._sortConfig;
  }

  get filters(): EvidenceTableFilters {
    return this._filters;
  }

  getFilter(attributeId: string | number): string | string[] | undefined {
    return this._filters.get(attributeId);
  }

  @computed.struct
  get jsFilters(): EvidenceTableFilters {
    return toJS(this.filters as ObservableMap);
  }

  @computed
  get apiFilters(): Record<string, string> {
    return Object.fromEntries(
      Array.from(this._filters.entries())
        .map(([key, value]) => [key, value])
        .filter(([_, value]) => Boolean(value))
    );
  }

  @action
  readonly handleFilterChange = (
    attributeId: string | number,
    value: string | string[],
    sort: SortConfig['order'] | null
  ): void => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      this._filters.delete(attributeId);
    } else {
      this._filters.set(attributeId, value);
    }

    if (sort) {
      this._sortConfig = { order: sort, sort: attributeId };
    } else if (!sort && this._sortConfig?.sort === attributeId) {
      this._sortConfig = null;
    }
  };

  clear(): void {
    this._filters.clear();
    this._sortConfig = null;
  }
}
