import {
  FULL_TIME_PATTERN_WITH_MS,
  TIME_PATTERN_HHMMSS,
  TIME_PATTERN_SS,
  TIME_PATTERN_SSS,
  TIME_PATTERN_WITH_MS,
  validateDateValue,
  validateFullTimeValueWithMs,
  validateHhMmSsTimeValue,
  validateTimeOfDayValue,
  validateTimeSsValue,
  validateTimeSssValue,
  validateTimeValue,
  validateTimeValueWithMs,
} from '@yarmill/components';
import { EvidenceAttributeType } from '@yarmill/types';
import { IntlShape } from 'react-intl';

export function validateDecimalNumber(value: string): boolean {
  return Boolean(value.match(/^[+-]?([0-9]*[.,])?[0-9]+$/));
}

export function validateInteger(value: string): boolean {
  return Boolean(value.match(/^-?\d+$/));
}

export function validateEvidenceAttribute(
  type: EvidenceAttributeType,
  value: string,
  format?: string | null
): boolean {
  switch (type) {
    case 'decimal-number':
      return validateDecimalNumber(value);
    case 'number':
      return validateInteger(value);
    case 'time-of-day': {
      return validateTimeOfDayValue(value, false);
    }
    case 'time': {
      if (format === FULL_TIME_PATTERN_WITH_MS) {
        return validateFullTimeValueWithMs(value, false);
      } else if (format === TIME_PATTERN_WITH_MS) {
        return validateTimeValueWithMs(value, false);
      } else if (format === TIME_PATTERN_SS) {
        return validateTimeSsValue(value, false);
      } else if (format === TIME_PATTERN_SSS) {
        return validateTimeSssValue(value, false);
      } else if (format === TIME_PATTERN_HHMMSS) {
        return validateHhMmSsTimeValue(value, false);
      } else {
        return validateTimeValue(value, false);
      }
    }
    case 'date':
      return validateDateValue(value);
    default:
      return true;
  }
}

export function getErrorMessage(
  type: EvidenceAttributeType,
  hasValue: boolean,
  isRequired: boolean,
  isValid: boolean,
  intl: IntlShape,
  format: string | null
): string | undefined {
  if (isValid) {
    return undefined;
  }

  if (!hasValue && !isRequired) {
    return undefined;
  }

  if (!hasValue && isRequired) {
    return intl.formatMessage({ id: 'evidence.attribute.required' });
  }

  switch (type) {
    case 'decimal-number':
      return intl.formatMessage({
        id: 'evidence.attribute.invalidDecimalNumber',
      });
    case 'number':
      return intl.formatMessage({ id: 'evidence.attribute.invalidNumber' });
    case 'time':
      return intl.formatMessage(
        {
          id: 'evidence.attribute.invalidTime',
        },
        { format }
      );
    case 'date':
      return intl.formatMessage({ id: 'evidence.attribute.invalidDate' });
  }

  return undefined;
}
