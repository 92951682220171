import { CloseButton, Text, TextSize, TextTag } from '@yarmill/components';
import { Layer } from '@yarmill/types';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Search } from './search';
import { VideoList } from './video-list';
import { VideoSelectorStoreContextProvider } from './video-selector-context';

const StyledVideoSelector = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 96px 32px 32px;
`;

const StyledCloseButton = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
`;

interface VideoSelectorProps {
  layer: Layer;
}
export const VideoSelectorLayerContent = observer(
  function InternalVideoSelector({ layer }: VideoSelectorProps): JSX.Element {
    const containerRef = useRef<HTMLDivElement>(null);

    function closeVideoSelector(e: MouseEvent): void {
      e.preventDefault();
      layer.close();
    }

    return (
      <VideoSelectorStoreContextProvider done={layer.close}>
        <StyledVideoSelector ref={containerRef}>
          <StyledCloseButton>
            <CloseButton onClick={closeVideoSelector} />
          </StyledCloseButton>
          <Text tag={TextTag.div} size={TextSize.s20} bold>
            <FormattedMessage id="videoSelector.header" />
          </Text>
          <Search />
          <VideoList containerRef={containerRef} />
        </StyledVideoSelector>
      </VideoSelectorStoreContextProvider>
    );
  }
);
