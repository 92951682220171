import { action, observable, makeObservable } from 'mobx';

export class RegistrationStore {
  @observable
  private _showTermsAndConditions: boolean = true;
  private _showRegisterForm: boolean = false;

  constructor() {
    makeObservable(this);
  }

  public get isTermsAndConditions(): boolean {
    return this._showTermsAndConditions;
  }

  public get isRegistrationForm(): boolean {
    return this._showRegisterForm;
  }

  @action
  public toTermsAndConditions = (): void => {
    this._showTermsAndConditions = true;
    this._showRegisterForm = false;
  };

  @action
  public toRegistrationForm = (): void => {
    this._showRegisterForm = true;
    this._showTermsAndConditions = false;
  };
}
