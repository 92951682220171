import { Color, Icon, IconSize, styled } from '@yarmill/components';
import {
  GenericDropdownItem,
  TextInputAppearance,
  getAppearanceColors,
} from '@yarmill/components-2';
import { Right } from '@yarmill/icons-2';
import { IntlShape } from 'react-intl';

interface PlannerEventType {
  readonly color: TextInputAppearance;
  readonly name: string;
  readonly eventTypeId: string;
}
interface InlinePlannerEventTypeSelectorProps {
  readonly eventTypes: PlannerEventType[];
  readonly selectedEventType: PlannerEventType;
  readonly onClick: (eventType: PlannerEventType) => void;
  readonly onDetailsClick: () => void;
  readonly disabled?: boolean;
  readonly intl: IntlShape;
}

const PlannerEventTypeSelectorLayout = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.blackHover};
  border-radius: ${({ theme }) => theme.borderRadius.x075};
  padding: ${({ theme }) => theme.size.x05};
`;

const EventTypeShortcut = styled.button<{
  readonly color: TextInputAppearance;
  readonly selected?: boolean;
}>`
  border: 0;
  outline: ${({ theme }) => theme.size.x025} solid
    ${({ selected, theme }) => (selected ? theme.color.white : 'transparent')};

  background-color: ${({ theme, color }) =>
    getAppearanceColors(color, theme).base};
  cursor: pointer;
  width: ${({ theme }) => theme.size.x3};
  height: ${({ theme }) => theme.size.x3};
  border-radius: ${({ theme }) => theme.size.x05};

  :hover {
    background-color: ${({ theme, color }) =>
      getAppearanceColors(color, theme).dark};
  }
`;

const ShortcutsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.size.x1} ${({ theme }) => theme.size.x025};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.size.x05};
  background-color: ${({ theme }) => theme.color.blackHover};
  color: ${({ theme }) => theme.color.neutral_8};
  border-radius: ${({ theme }) => theme.borderRadius.x05};

  :hover {
    background-color: ${({ theme }) => theme.color.text};
  }
`;

const SelectedItemWrapper = styled.button`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr ${({ theme }) => theme.size.x3};
  column-gap: ${({ theme }) => theme.size.x05};
  background-color: ${({ theme }) => theme.color.text};
  border: 0;
  border-radius: ${({ theme }) => theme.borderRadius.x075};
  padding: ${({ theme }) => theme.size.x05};
  width: 100%;
  cursor: pointer;

  :hover:disabled {
    cursor: default;
  }
  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.color.blackHover};

    ${IconWrapper} {
      background-color: ${({ theme }) => theme.color.text};
    }
  }
`;

function getAppearanceFromColor(color: Color): TextInputAppearance {
  if (color.includes('_')) {
    return color.substring(0, color.indexOf('_')) as TextInputAppearance;
  }

  return color
    .replace('PlateDark', '')
    .replace('Plate', '')
    .replace('Dark', '')
    .replace('Hover', '') as TextInputAppearance;
}
export function InlinePlannerEventTypeSelector({
  eventTypes,
  selectedEventType,
  onClick,
  onDetailsClick,
  disabled,
  intl,
}: InlinePlannerEventTypeSelectorProps): JSX.Element {
  return (
    <PlannerEventTypeSelectorLayout>
      <SelectedItemWrapper
        type="button"
        onClick={!disabled ? onDetailsClick : undefined}
        disabled={disabled}
      >
        <GenericDropdownItem
          color={selectedEventType.color}
          title={intl.formatMessage({ id: selectedEventType.name })}
          appearance={getAppearanceFromColor(selectedEventType.color)}
        />
        {!disabled && (
          <IconWrapper>
            <Icon size={IconSize.s16}>
              <Right />
            </Icon>
          </IconWrapper>
        )}
      </SelectedItemWrapper>
      {!disabled && (
        <ShortcutsWrapper>
          {eventTypes.map(eventType => (
            <EventTypeShortcut
              type="button"
              key={eventType.name}
              onClick={() => onClick(eventType)}
              color={eventType.color}
              aria-label={eventType.name}
              selected={selectedEventType.eventTypeId === eventType.eventTypeId}
            />
          ))}
        </ShortcutsWrapper>
      )}
    </PlannerEventTypeSelectorLayout>
  );
}
