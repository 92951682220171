import { AsyncStatus, RootStore } from '@yarmill/types';
import { computed, makeObservable, observable, reaction, when } from 'mobx';
import { ReportFilter, ReportItemStore, ReportingSection } from '../types';
import { getReportingPagePermissionScope } from '../utils';
import { ReportingPageStore } from './reporting-page-store';
import { ReportsDataStore } from './reports-data-store';
import { ReportsDefinitionStore } from './reports-definition-store';

export class ReportingStore {
  private readonly rootStore: RootStore;
  private readonly _definitionStore: ReportsDefinitionStore;
  @observable
  private _reportingPageCode: string | null = null;

  @observable
  private _dataStore: ReportsDataStore | null = null;
  private _useUrlFilters = true;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    const definitionUrl = this.rootStore.configStore.reportingDefUrl;
    this._definitionStore = new ReportsDefinitionStore(
      this.rootStore,
      definitionUrl
    );
    this._definitionStore.loadDefinition();
    when(
      () => this._definitionStore.status === AsyncStatus.resolved,
      () => this.registerReactions()
    );
  }

  public get definitionStore(): ReportsDefinitionStore {
    return this._definitionStore;
  }

  public get dataStore(): ReportsDataStore | null {
    return this._dataStore;
  }

  public get reportingPageCode(): string | null {
    return this._reportingPageCode;
  }

  @computed
  public get currentPageDashboards(): ReportingPageStore[] {
    const moduleConfig = this.rootStore.modulesStore.reporting;
    const currentUser = this.rootStore.currentUserStore;

    const currentPage = moduleConfig?.find(page =>
      page.Dashboards.find(
        dashboard => dashboard.ReportPageCode === this.reportingPageCode
      )
    );

    const pageDashboards = currentPage?.Dashboards.map(dashboard =>
      this.definitionStore?.pages.get(dashboard.ReportPageCode)
    )
      .filter(Boolean)
      .filter(dashboard =>
        currentUser.isAllowedTo(
          getReportingPagePermissionScope(dashboard!.code)
        )
      ) as ReportingPageStore[];

    return pageDashboards || [];
  }

  @computed
  public get currentPageFilters(): ReportFilter[] {
    const currentPage = this.definitionStore?.pages.get(
      this.reportingPageCode || ''
    );

    return currentPage?.filters || [];
  }

  @computed
  public get pageReports(): ReportItemStore[] {
    return (
      this.currentPage?.reportItems?.filter(
        report =>
          report.permissions.isAllowed(this.rootStore.currentUserStore) &&
          ((!report.clarificators && !this._dataStore?.clarificator) ||
            this.dataStore?.clarificator?.every(clarificator =>
              report.clarificators?.includes(clarificator)
            ))
      ) || []
    );
  }

  @computed
  public get pageSections(): ReportingSection[] | undefined {
    return this.currentPage?.sections.filter(
      section =>
        (!section.Clarificators && !this._dataStore?.clarificator) ||
        this.dataStore?.clarificator?.every(clarificator =>
          section.Clarificators?.includes(clarificator)
        )
    );
  }

  @computed
  public get currentPage(): ReportingPageStore | undefined {
    return this.definitionStore?.pages.get(this.reportingPageCode || '');
  }

  private registerReactions(): void {
    reaction(
      () => this.rootStore.historyService.pathname,
      pathname => {
        if (!pathname.includes('reporting')) {
          this._reportingPageCode = null;
          this._useUrlFilters = true;
        }
        const match = pathname.match(/\/reporting\/(.+)$/);
        this._reportingPageCode = match?.[1] || null;
      },
      {
        fireImmediately: true,
      }
    );

    reaction(
      () => this._reportingPageCode,
      pageCode => {
        if (pageCode) {
          const dataUrl = this.rootStore.configStore.reportingDataUrl;

          this._dataStore?.dispose();
          this._dataStore = new ReportsDataStore(
            this.rootStore,
            dataUrl,
            pageCode,
            this.currentPage?.filters || []
          );
          if (this._useUrlFilters) {
            this._dataStore.loadUrlFilters();
            this._useUrlFilters = false;
          }
          this._dataStore.loadPersistedFilters();
          void this._dataStore.loadData();
        }
      },
      {
        fireImmediately: true,
      }
    );
  }
}
