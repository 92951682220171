import { UserGroupId, UserId } from '@yarmill/types';
import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';

export interface ImportEventsRequestParams {
  userId: UserId | undefined;
  userGroupId: UserGroupId | undefined;
  eventIds: number[];
}

export function importEvents(
  params: ImportEventsRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<boolean> {
  return axios.post(`${coreApiTarget}/api/planner/import`, params, {
    cancelToken: cancelToken?.token,
    params: {
      instanceCode: devMode ? instance : undefined,
    },
  });
}
