import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const Link = styled(RouterLink)<{ readonly $isActive?: boolean }>`
  text-decoration: none;
  color: ${({ $isActive }) => ($isActive ? '#4a90e2' : '#4a4a4a')};
  :hover {
    color: #4a90e2;
  }
`;
