import { PageMainContent, PageScrollContainer } from '@yarmill/components';
import { ReactElement, memo } from 'react';
import { ReportingStoreContextProvider } from './reporting-store-context-provider';
import { Reports } from './reports';
import { Sidebar } from './sidebar';

function ReportingModule(): ReactElement {
  return (
    <ReportingStoreContextProvider>
      <Sidebar />
      <PageMainContent>
        <PageScrollContainer>
          <Reports />
        </PageScrollContainer>
      </PageMainContent>
    </ReportingStoreContextProvider>
  );
}

export const Reporting = memo(ReportingModule);
