import { GROUP_SEARCH_PARAM, WEEK_SEARCH_PARAM } from '@yarmill/const';
import {
  AsyncStatus,
  AttendanceViewType,
  GroupStore,
  RequestStore,
  RootStore,
  SeasonStore,
  UserId,
  UserStore,
} from '@yarmill/types';
import { getWeekEndString, getWeekStartString } from '@yarmill/utils';
import {
  IReactionDisposer,
  ObservableMap,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
  toJS,
} from 'mobx';
import { api } from '../api';
import { GetSeasonAttendanceItemsRequestParams } from '../api/get-season-attendace-items';
import { GetWeekAttendanceItemsRequestParams } from '../api/get-week-attendace-items';
import { UpdateAttendanceItemForGroupRequestParams } from '../api/update-attendace-item-for-group';
import {
  AttendancePhase,
  AttendanceValue,
  PHASE_SEARCH_PARAM,
  UserSummaryAttendance,
  WeekAttendanceItem,
} from '../types';
import { WeekAttendanceItemStore } from './week-attendance-item-store';

export class AttendanceStore {
  private readonly _rootStore: RootStore;

  @observable
  private _viewType: AttendanceViewType = 'week';

  @observable
  private _groupId: number | null = null;

  @observable
  private _week: string | null = null;

  @observable
  private _phaseId: number | null = null;

  @observable
  private _editedColumn: number | null = null;

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  @observable
  private _weekData: Map<UserId, Map<string, WeekAttendanceItemStore>> =
    new Map();

  @observable
  private _summaryData: Map<UserId | null, UserSummaryAttendance> = new Map();

  private request: RequestStore<
    WeekAttendanceItem[] | UserSummaryAttendance[]
  > | null = null;

  private reactions: IReactionDisposer[] = [];

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeObservable(this);
    this.registerReactions();
  }

  public disposeReactions(): void {
    this.reactions.forEach(dispose => dispose());
  }

  public async loadAttendanceData(): Promise<void> {
    this.request?.cancel();
    this._status = AsyncStatus.pending;

    if (this._viewType === 'week') {
      await this.loadWeekAttendanceData();
    } else {
      await this.loadSeasonAttendanceData();
    }
  }

  public get editedColumn(): number | null {
    return this._editedColumn;
  }

  public setEditedColumn(index: number | null): void {
    this._editedColumn = index;
  }

  public async selectAll(date: string, type: AttendanceValue): Promise<void> {
    const currentUser = this._rootStore.currentUserStore;

    const userIds = Array.from(this._weekData.keys()).filter(
      userId =>
        currentUser.hasPermissionToUser(userId) &&
        currentUser.getPermissionToUser(userId) === 'write'
    );

    if (userIds.length === 0 || !this._groupId) {
      return;
    }

    const transaction = this._rootStore.navbarStore.createTransaction(
      'loading',
      'attendance'
    );

    const phaseId = this.currentPhase?.activityItemId || null;
    const params: UpdateAttendanceItemForGroupRequestParams = {
      UserGroupId: this._groupId,
      Date: date,
      Value: type,
      ActivityItemId: phaseId,
    };
    const request = this._rootStore.requestsStore.createRequest(() =>
      api.updateAttendanceItemForGroup(params)
    );

    const prevState = toJS(this._weekData);
    runInAction(() => {
      userIds.forEach(userId => {
        if (!this._weekData.has(userId)) {
          this._weekData.set(userId, new ObservableMap());
        }
        const userData = this._weekData.get(userId);
        userData!.set(
          date,
          new WeekAttendanceItemStore(
            this._rootStore,
            {
              UserId: userId,
              Date: date,
              Value: type,
            },
            phaseId
          )
        );
      });
    });

    await request.getResponse();

    if (!request.error) {
      runInAction(() => {
        transaction.success();
      });
    } else {
      (this._weekData as ObservableMap).replace(prevState);
      transaction.error();
    }
  }

  @computed
  public get group(): GroupStore | undefined {
    return this._rootStore.groupsStore.getGroupById(this._groupId);
  }

  public get viewType(): AttendanceViewType {
    return this._viewType;
  }

  public get status(): AsyncStatus {
    return this._status;
  }

  public get week(): string | null {
    return this._week;
  }

  public get weekData() {
    return this._weekData;
  }

  public get summaryData() {
    return this._summaryData;
  }

  public createItem(
    item: WeekAttendanceItem,
    phaseId: number | null
  ): WeekAttendanceItemStore {
    if (!this.weekData.has(item.UserId)) {
      this.weekData.set(item.UserId, new ObservableMap());
    }
    const userData = this.weekData.get(item.UserId);
    const store = new WeekAttendanceItemStore(this._rootStore, item, phaseId);
    userData!.set(item.Date, store);

    return store;
  }

  @computed
  public get athletes(): Readonly<UserStore[]> {
    return this.group?.athletes ?? [];
  }

  private async loadWeekAttendanceData(): Promise<void> {
    if (!this._groupId || !this._week) {
      return;
    }
    const phase = this.currentPhase;
    const phaseId = phase?.activityItemId || null;
    const isPhaseEditable = phase?.isEditable;
    const params: GetWeekAttendanceItemsRequestParams = {
      UserGroupId: this._groupId,
      StartDate: getWeekStartString(this._week),
      EndDate: getWeekEndString(this._week),
      ActivityItemId: phaseId,
    };
    const request = this._rootStore.requestsStore.createRequest(cancelToken =>
      api.getWeekAttendanceItems(params, cancelToken)
    );
    this.request = request;

    const response = await request.getResponse();

    if (request.error) {
      if (!request.wasCanceled) {
        this._status = AsyncStatus.rejected;
      }
      return;
    }

    runInAction(() => {
      if (isPhaseEditable) {
        (response as WeekAttendanceItem[]).forEach(item => {
          if (!this._weekData.has(item.UserId)) {
            this._weekData.set(item.UserId, new ObservableMap());
          }

          this._weekData
            .get(item.UserId)!
            .set(
              item.Date,
              new WeekAttendanceItemStore(this._rootStore, item, phaseId)
            );
        });
      } else {
        (response as UserSummaryAttendance[]).forEach(item => {
          this._summaryData.set(item.UserId, item);
        });
      }
      this._status = AsyncStatus.resolved;
    });
  }

  private async loadSeasonAttendanceData(): Promise<void> {
    if (!this._groupId || !this.currentSeason) {
      return;
    }

    const params: GetSeasonAttendanceItemsRequestParams = {
      UserGroupId: this._groupId,
      SeasonId: this.currentSeason.id,
      ActivityItemId: this.currentPhase?.activityItemId || null,
    };
    const request = this._rootStore.requestsStore.createRequest(cancelToken =>
      api.getSeasonAttendanceItems(params, cancelToken)
    );
    this.request = request;

    const response = await request.getResponse();

    if (response) {
      runInAction(() => {
        response.forEach(item => {
          this._summaryData.set(item.UserId, item);
        });
        this._status = AsyncStatus.resolved;
      });
    } else {
      this._status = AsyncStatus.rejected;
    }
  }

  @computed
  public get phases(): AttendancePhase[] {
    return this._rootStore.configStore.attendancePhases;
  }

  @computed
  public get currentPhase(): AttendancePhase | undefined {
    return (
      this.phases.find(phase => phase.activityItemId === this._phaseId) ||
      this.phases[0]
    );
  }

  @computed
  public get currentSeason(): SeasonStore | undefined {
    return this._week
      ? this._rootStore.seasonsStore.getSeasonByDate(this._week)
      : undefined;
  }

  private registerReactions(): void {
    const observeGroupId = reaction(
      () => this._rootStore.historyService.searchParams.get(GROUP_SEARCH_PARAM),
      id => {
        this._groupId = id !== undefined ? Number(id) : null;
      },
      {
        fireImmediately: true,
      }
    );
    const observeWeek = reaction(
      () => this._rootStore.historyService.searchParams.get(WEEK_SEARCH_PARAM),
      week => {
        this._week = week || '';
      },
      {
        fireImmediately: true,
      }
    );
    const observePhase = reaction(
      () => this._rootStore.historyService.searchParams.get(PHASE_SEARCH_PARAM),
      phase => {
        this._phaseId = phase && phase !== 'null' ? Number(phase) : null;
      },
      {
        fireImmediately: true,
      }
    );
    const observePathname = reaction(
      () => this._rootStore.historyService.pathname,
      pathname => {
        this._viewType = pathname.includes('season') ? 'season' : 'week';
      },
      {
        fireImmediately: true,
      }
    );

    const dataLoader = reaction(
      () => ({
        groupId: this._groupId,
        week: this._week,
        viewType: this._viewType,
        phaseId: this._phaseId,
      }),
      () => this.loadAttendanceData(),
      { fireImmediately: true }
    );

    this.reactions.push(
      observeGroupId,
      observeWeek,
      observePathname,
      observePhase,
      dataLoader
    );
  }
}
