import { File, UserGroupId, UserId } from '@yarmill/types';
import {
  EvidenceAttribute,
  EvidenceAttributeId,
  EvidenceAttributeType,
  EvidenceDataObjectAttribute,
} from '@yarmill/types';
import {
  IObservableArray,
  action,
  computed,
  makeObservable,
  observable,
} from 'mobx';
import { EvidenceObjectDataStore } from './evidence-object-data-store';

export class EvidenceAttachmentStore {
  private readonly _objectDataStore: EvidenceObjectDataStore;
  private readonly _definition: EvidenceAttribute;
  private readonly _objectKey: string;
  private readonly _userId: UserId | null;
  private readonly _groupId: UserGroupId | null;

  @observable
  private _attachments: IObservableArray<File> = observable.array([]);

  private _apiValue: File[] = [];

  constructor(
    objectDataStore: EvidenceObjectDataStore,
    objectKey: string,
    definition: EvidenceAttribute,
    userId: UserId | null,
    groupId: UserGroupId | null
  ) {
    makeObservable(this);
    this._objectDataStore = objectDataStore;
    this._objectKey = objectKey;
    this._definition = definition;
    this._userId = userId;
    this._groupId = groupId;
  }

  get definition(): EvidenceAttribute {
    return this._definition;
  }

  get objectKey(): string {
    return this._objectKey;
  }

  @action
  setApiValue(value: File[]): void {
    this._attachments.replace(value);
    this._apiValue = value;
  }

  @computed
  get attachments(): File[] {
    return this._attachments;
  }

  @computed
  get hasValue(): boolean {
    return this.attachments.length > 0;
  }

  @computed
  get isRequired(): boolean {
    return !this._definition.IsOptional && !this._definition.IsCalculated;
  }

  @computed
  get attributeValueObject(): EvidenceDataObjectAttribute {
    return {
      AttributeId: this.definition.AttributeId,
      AttributeValue: this.attachments,
    };
  }

  get id(): EvidenceAttributeId {
    return this.definition.AttributeId;
  }

  get attributeType(): EvidenceAttributeType {
    return this.definition.AttributeTypeKey;
  }

  get objectDataStore(): EvidenceObjectDataStore {
    return this._objectDataStore;
  }

  get userId(): UserId | null {
    return this._userId;
  }

  get groupId(): UserGroupId | null {
    return this._groupId;
  }

  @action
  clear(): void {
    this._attachments.clear();
    this._apiValue = [];
  }

  @action
  reset(): void {
    this._attachments.replace(this._apiValue);
  }

  @action
  readonly prefill = (value: File[]): void => {
    this._attachments.replace(value);
  };

  @action
  readonly removeAttachment = (value: File): void => {
    this._attachments.remove(value);
  };

  @action
  readonly addAttachment = (value: File): void => {
    this._attachments.push(value);
  };
}
