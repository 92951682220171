import { createContext, useContext } from 'react';
import { AnalyticsRouteParams } from './types';

export const AnalyticsRouteParamsContext =
  createContext<AnalyticsRouteParams | null>(null);

export function useAnalyticsRouteParams(): AnalyticsRouteParams {
  const params = useContext(AnalyticsRouteParamsContext);
  if (params === null) {
    throw new Error('Analytics Route Params not available');
  }

  return params;
}
