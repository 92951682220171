import styled from 'styled-components';
import { Text, TextSize, TextTag } from '../text';
import { ReactNode } from 'react';

const HeaderWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
const HeaderLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px;
  column-gap: 25px;
`;

const AvatarWrapper = styled.div`
  width: 40px;
`;
const UserDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const DescriptiveText = styled(Text)`
  color: #9a9a9a;
`;

interface ProfileHeaderProps {
  readonly avatar: JSX.Element;
  readonly name: string;
  readonly email: string;
  readonly role: JSX.Element;
  readonly editButton?: JSX.Element;
}
export function ProfileHeader({
  avatar,
  name,
  email,
  role,
  editButton,
}: ProfileHeaderProps): JSX.Element {
  return (
    <HeaderWrapper>
      <HeaderLayout>
        <AvatarWrapper>{avatar}</AvatarWrapper>
        <UserDataContainer>
          <UserDataContainer>
            <Text size={TextSize.s24} tag={TextTag.h1}>
              {name}
            </Text>
            <Text size={TextSize.s14} ellipsis>
              {email}
            </Text>
          </UserDataContainer>
        </UserDataContainer>
        <div>{role}</div>
      </HeaderLayout>
      {editButton}
    </HeaderWrapper>
  );
}

const ProfileInfoItemLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileInfoLayout = styled.div`
  display: grid;
  row-gap: 36px;
  grid-template-columns: 100%;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 36px;
  }
`;
interface ProfileInfoItemProps {
  readonly label: ReactNode;
  readonly value: ReactNode;
}
export function ProfileInfoItem({
  label,
  value,
}: ProfileInfoItemProps): JSX.Element {
  return (
    <ProfileInfoItemLayout>
      <DescriptiveText size={TextSize.s14} medium>
        {label}
      </DescriptiveText>
      <Text>{value}</Text>
    </ProfileInfoItemLayout>
  );
}
