import {
  ExternalIcon,
  Icon,
  IconSize,
  PageMainContent,
  PageMainContentContainer,
  SimplePageLayout,
  Text,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { getQueryParam, useConfig, useCurrentUserStore } from '@yarmill/utils';
import { remove as removeCookie } from 'es-cookie';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { useToken } from '../auth/hooks';
import { authCookies } from '../auth/types';
import { UpdateProfileForm } from '../profile/update-profile-form';
import { RegistrationStore } from './mobx/registration-store';
import { TermsAndConditionsStore } from './mobx/terms-and-conditions-store';
import { TermsAndConditions } from './terms-and-conditions';

const StyledHeadline = styled(Text)`
  width: 100%;
  margin: 0 auto 36px;

  @media (min-width: 556px) {
    width: 80%;
  }

  @media (min-width: 767px) {
    margin: 50px auto 36px;
    width: 50%;
  }
`;
StyledHeadline.displayName = 'StyledHeadline';

const StyledIconWrapper = styled.span`
  padding-left: 10px;
`;
StyledIconWrapper.displayName = 'StyledIconWrapper';

export interface RegisterProps {
  registration: RegistrationStore;
  termsAndConditions: TermsAndConditionsStore;
}

function InnerRegistration(
  props: RegisterProps & RouteComponentProps
): JSX.Element {
  const { registration, termsAndConditions } = props;
  const tokenInUrl = getQueryParam('token');
  const user = useCurrentUserStore();
  const appName = useConfig('appName');
  const avatars = useConfig('avatars');
  const hasToken = useToken();

  useEffect(() => {
    removeCookie(authCookies.TOKEN);
  }, []);

  if (tokenInUrl) {
    return <Redirect to="/register" />;
  }

  if (!hasToken) {
    return <Redirect to="/" />;
  }

  if (!user) {
    return <PageMainContent />;
  }

  return (
    <SimplePageLayout>
      <PageMainContent>
        <PageMainContentContainer>
          {registration.isRegistrationForm && (
            <StyledHeadline size={TextSize.s24} tag={TextTag.h1}>
              <FormattedMessage
                id="registration.header"
                values={{ instance: appName }}
              />
              <StyledIconWrapper>
                <Icon size={IconSize.s24}>
                  <ExternalIcon name="HandLoveYou" />
                </Icon>
              </StyledIconWrapper>
            </StyledHeadline>
          )}
          {registration.isTermsAndConditions && (
            <TermsAndConditions
              checkBox={termsAndConditions}
              handleNextStep={registration.toRegistrationForm}
            />
          )}
          {registration.isRegistrationForm && (
            <UpdateProfileForm
              user={user}
              isRegistration
              commercialConsent={termsAndConditions.isNewsAccepted}
              backToTermsState={registration.toTermsAndConditions}
              avatars={avatars}
            />
          )}
        </PageMainContentContainer>
      </PageMainContent>
    </SimplePageLayout>
  );
}

export const Registration = observer(InnerRegistration);
