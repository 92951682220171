import { SidebarStore as iSidebarStore } from '@yarmill/types';
import { action, makeObservable, observable } from 'mobx';

export class SidebarStore implements iSidebarStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  private _isVisible: boolean = true;

  get isVisible(): Readonly<typeof this._isVisible> {
    return this._isVisible;
  }

  @action
  readonly hide = (): void => {
    this._isVisible = false;
  };

  @action
  readonly show = () => {
    this._isVisible = true;
  };

  @action
  readonly toggle = () => {
    this._isVisible = !this._isVisible;
  };
}
