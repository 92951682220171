import { GroupStore } from '@yarmill/types';
import { useMemo } from 'react';
import { getCurrentUserAllowedGroups } from '../utils/get-current-user-allowed-groups';
import { useCurrentUserStore } from './use-current-user-store';
import { useGroupsStore } from './use-group-store';

export function useCurrentUserAllowedGroups(): GroupStore[] {
  const groupsStore = useGroupsStore();
  const currentUser = useCurrentUserStore();

  return useMemo(
    () => getCurrentUserAllowedGroups(groupsStore, currentUser),
    [currentUser, groupsStore]
  );
}
