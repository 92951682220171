import {
  Breakpoints,
  ResponsiveTableWrapper,
  StyledTh,
  Table,
  TableFilters,
  Text,
  TextSize,
  css,
  styled,
} from '@yarmill/components';
import {
  AsyncStatus,
  EvidenceAttribute,
  TableFiltersStore,
} from '@yarmill/types';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { FormattedMessage, useIntl } from 'react-intl';
import { EvidenceObjectDataStore } from '../mobx/evidence-object-data-store';
import { EvidenceObjectStore } from '../mobx/evidence-object-store';
import { EvidenceTableItemRow } from './evidence-table-item-row';
import { ShowMoreRow } from './show-more-row';

import { getAlignmentForAttributeType } from '../utils/get-alignment-for-attribute-type';

export interface EvidenceTableProps {
  table: EvidenceObjectStore | null;
  showControls: boolean;
  maxHeight?: string;
  editItem(e: React.MouseEvent, item: EvidenceObjectDataStore): void;
}

export const StyledThWithBackground = styled(StyledTh)`
  background: #fff;
  top: 0;
  z-index: 2;
`;

const StyledHeadCell = styled(StyledTh)`
  position: sticky;
  top: 0;
  background: #fff;
  vertical-align: middle;
  z-index: 2;

  ${({ sticky }) =>
    sticky &&
    css`
      left: unset;
      @media (min-width: ${Breakpoints.tablet}px) {
        left: ${sticky.left};
      }

      z-index: 3;
      padding-right: 16px;

      + th {
        padding-left: 0;
      }
    `};
`;

const StyledCellContent = styled.div`
  display: inline-flex;
  align-items: center;
`;

interface HeadCellProps {
  attribute: EvidenceAttribute;
  filtersStore: TableFiltersStore;
  sticky?: boolean;
}

const EvidenceTableWrapper = styled(ResponsiveTableWrapper)<{
  maxHeight?: string;
}>`
  max-height: ${props =>
    props.maxHeight ||
    /* window-height - header-height - diary-padding - navbar height - navbar margin - padding */
    `calc(
    100vh - 80px - 30px - 36px - 30px - 15px - 26px - 26px -
      var(--vh-offset, 0px)
  )`};
  position: relative;
`;

const NoDataWrapper = styled.div`
  padding-top: 20px;
`;

const HeadCell = observer(function HeadCell(props: HeadCellProps) {
  const { attribute, filtersStore, sticky } = props;
  const sortConfig = filtersStore.sortConfig;
  const intl = useIntl();

  const options = React.useMemo(() => {
    if (attribute.AttributeTypeKey === 'dropdown') {
      return attribute.SourceData.map(item => ({
        label: intl.formatMessage({ id: item.Value }),
        value: item.Key,
      }));
    }
    return undefined;
  }, [attribute, intl]);

  return (
    <StyledHeadCell
      align={getAlignmentForAttributeType(attribute.AttributeTypeKey)}
      sticky={sticky ? { left: 0 } : undefined}
    >
      <StyledCellContent>
        <Text size={TextSize.s12} bold>
          <FormattedMessage id={attribute.AttributeName} />
        </Text>
        {
          <TableFilters
            hidden={attribute.IsCalculated}
            attributeId={attribute.AttributeId}
            onChange={filtersStore.handleFilterChange}
            filter={filtersStore.getFilter(attribute.AttributeId)}
            sort={
              sortConfig?.sort === attribute.AttributeId
                ? sortConfig.order
                : null
            }
            options={options}
            filterType={
              attribute.AttributeTypeKey === 'date'
                ? 'date'
                : attribute.AttributeTypeKey === 'time'
                  ? 'time'
                  : attribute.AttributeTypeKey === 'time-of-day'
                    ? 'time-of-day'
                    : attribute.AttributeTypeKey === 'number'
                      ? 'number'
                      : attribute.AttributeTypeKey === 'decimal-number'
                        ? 'decimal-number'
                        : options
                          ? 'options'
                          : 'text'
            }
            timeFormat={attribute.Format || undefined}
          />
        }
      </StyledCellContent>
    </StyledHeadCell>
  );
});

export const EvidenceTable = observer(function EvidenceTable(
  props: EvidenceTableProps
): JSX.Element {
  const { table, editItem, showControls } = props;

  if (!table) {
    return (
      <ContentLoader
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        viewBox="0 0 744 136"
      >
        <rect x="0" y="0" rx="2" ry="2" width="729" height="109" />
      </ContentLoader>
    );
  }

  if (table.status === AsyncStatus.rejected && !table.definition.Attributes) {
    return (
      // TODO: check translate strings
      <FormattedMessage
        id={`evidence.${table.definition.ObjectKey}.definition.loadingError`}
      />
    );
  }
  const definition = table.definition;

  return (
    <>
      <EvidenceTableWrapper>
        <Table
          head={
            <>
              {definition.Attributes?.map((attribute, idx) => (
                <HeadCell
                  key={attribute.AttributeId}
                  attribute={attribute}
                  filtersStore={table?.filters}
                  sticky={idx === 0 && definition.Features?.FirstColumnSticky}
                />
              ))}
              {showControls && <StyledThWithBackground sticky={{ right: 0 }} />}
            </>
          }
        >
          {table.status === AsyncStatus.resolved &&
            table.data.map(row => (
              <EvidenceTableItemRow
                item={row}
                edit={editItem}
                showControls={showControls}
                key={row.arrayIndex || -1}
                firstColumnSticky={definition.Features?.FirstColumnSticky}
              />
            ))}
        </Table>
        <ShowMoreRow table={table} />
      </EvidenceTableWrapper>
      {table.status === AsyncStatus.resolved && table.data.length === 0 && (
        <NoDataWrapper>
          <Text size={TextSize.s14}>
            <FormattedMessage id="evidence.table.noData" />
          </Text>
        </NoDataWrapper>
      )}
      {table.status === AsyncStatus.rejected && (
        <NoDataWrapper>
          <Text size={TextSize.s14}>
            <FormattedMessage
              id={`evidence.${table.definition.ObjectKey}.definition.loadingError`}
            />
          </Text>
        </NoDataWrapper>
      )}
    </>
  );
});
