import { useRootStore } from '@yarmill/utils';
import {
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useRef,
} from 'react';
import { AttendanceStore } from './mobx/attendance-store';

export const AttendanceStoreContext = createContext<
  AttendanceStore | undefined
>(undefined);

export function useAttendanceStore(): AttendanceStore {
  const diaryStore = useContext(AttendanceStoreContext);
  if (!diaryStore) {
    throw new Error('Attendance Store not available');
  }

  return diaryStore;
}

export function InternalAttendanceStoreContextProvider(
  props: PropsWithChildren<any>
): JSX.Element {
  const rootStore = useRootStore();
  const diaryStore = useRef(new AttendanceStore(rootStore)).current;

  return (
    <AttendanceStoreContext.Provider value={diaryStore}>
      {props.children}
    </AttendanceStoreContext.Provider>
  );
}

export const AttendanceStoreContextProvider = memo(
  InternalAttendanceStoreContextProvider
);
