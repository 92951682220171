import { ApiExportType, Locale } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';

export interface ExportSeasonSummary {
  seasonId: number | null;
  users: number[];
  groups: number[];
  exportType: Exclude<ApiExportType, 'opponency'>;
  language: Locale;
}

export function exportSeasonSummaryToXlsx(
  params: ExportSeasonSummary,
  cancelToken: CancelTokenSource
): AxiosPromise<BlobPart> {
  return axios.post('api/seasonExport', params, {
    responseType: 'blob',
    cancelToken: cancelToken.token,
  });
}
