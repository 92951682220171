import { User } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';
import { UserInGroupValidity } from '../types';

export interface GetHistoricalAthletesRequestParams {
  userGroupId: number;
}

export interface GetHistoricalAthletesResponse {
  Users: User[];
  ValidityDates: UserInGroupValidity[];
}

export function getHistoricalAthletesForGroup(
  params: GetHistoricalAthletesRequestParams
): AxiosPromise<GetHistoricalAthletesResponse> {
  return axios.get('api/UserGroupUsers/Historical', { params });
}
