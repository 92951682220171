import { TrainingDayContentBox } from '@yarmill/components';
import ContentLoader from 'react-content-loader';

interface TrainingDayLoadingPlaceholderProps {
  weekPlaceholder?: boolean;
}

export function TrainingDayLoadingPlaceholder(
  props: TrainingDayLoadingPlaceholderProps
): JSX.Element {
  return (
    <TrainingDayContentBox>
      {props.weekPlaceholder ? (
        <ContentLoader
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          viewBox="0 0 484 75"
          width="100%"
        >
          <rect x="0" y="0" rx="8" ry="8" width="149" height="22" />
          <rect x="0" y="35" rx="8" ry="8" width="484" height="45" />
        </ContentLoader>
      ) : (
        <ContentLoader
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          viewBox="0 0 484 323"
          width="100%"
        >
          <rect x="0" y="0" rx="8" ry="8" width="149" height="22" />
          <rect x="0" y="44" rx="8" ry="8" width="116" height="19" />
          <rect x="0" y="75" rx="8" ry="8" width="484" height="48" />
          <rect x="0" y="144" rx="8" ry="8" width="116" height="19" />
          <rect x="0" y="175" rx="8" ry="8" width="484" height="48" />
          <rect x="0" y="244" rx="8" ry="8" width="116" height="19" />
          <rect x="0" y="275" rx="8" ry="8" width="484" height="48" />
        </ContentLoader>
      )}
    </TrainingDayContentBox>
  );
}
