import { TrainingDayExtraActionType } from './types';
import { CopyPlanToReality } from './extra-actions/copy-plan-to-reality';
import { PinToTop } from './extra-actions/pin-to-top';
import { TrainingDayStore } from './mobx/training-day-store';
import { WeekSummaryStore } from './mobx/week-summary-store';

export interface TrainingDayExtraActionProps {
  action: TrainingDayExtraActionType;
  day: TrainingDayStore;
  hideTooltip(): void;
}

export function TrainingDayExtraAction(
  props: TrainingDayExtraActionProps
): JSX.Element | null {
  const { action, hideTooltip, day } = props;

  switch (action) {
    case 'copy-plan-to-reality':
      return <CopyPlanToReality day={day} hideTooltip={hideTooltip} />;
    case 'pin-to-top':
      return (
        <PinToTop day={day as WeekSummaryStore} hideTooltip={hideTooltip} />
      );
    default:
      return null;
  }
}
