export function registerVersionWorker(
  currentVersion: string,
  callback: () => void
): void {
  if (
    process.env.NODE_ENV === 'production' &&
    window.Worker &&
    process.env.PUBLIC_URL
  ) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(
      process.env.PUBLIC_URL,
      window.location.toString()
    );
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const wwUrl = `${process.env.PUBLIC_URL}/version-worker.js`;
      const versionWorker = new Worker(wwUrl);
      versionWorker.postMessage([currentVersion, window.location.origin]);
      versionWorker.onmessage = callback;
    });
  }
}
