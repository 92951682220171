import {
  CloseButton,
  FullScreenLayerCloseButtonWrapper,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { AddFilesForm } from './add-files-form';
import {
  LayerContentContainer,
  LayerHeadline,
} from './components/add-files-layer';

interface AddFilesLayerProps {
  readonly close: () => void;
}
export const AddFilesLayer = observer(function AddFilesLayer({
  close,
}: AddFilesLayerProps) {
  return (
    <>
      <LayerContentContainer>
        <LayerHeadline size={TextSize.s24} tag={TextTag.h1}>
          <FormattedMessage id={`filesOverview.addFilesForm.headline`} />
        </LayerHeadline>
        <AddFilesForm close={close} />
      </LayerContentContainer>
      <FullScreenLayerCloseButtonWrapper>
        <CloseButton onClick={close} />
      </FullScreenLayerCloseButtonWrapper>
    </>
  );
});
