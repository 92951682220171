import { ScaleTypeToD3Scale, scaleBand } from '@visx/scale';

export function createKeysScale(
  keys: string[],
  parentScaleBandwidth: number
): ScaleTypeToD3Scale<string, string>['band'] {
  return scaleBand<string>({
    domain: keys,
    padding: 0.1,
  }).rangeRound([0, parentScaleBandwidth]);
}
