export function isActive(pathname: string, moduleKey: string): boolean {
  const searchParamsIdx = moduleKey.indexOf('?');
  return (
    pathname ===
    moduleKey.substring(
      0,
      searchParamsIdx !== -1 ? searchParamsIdx : moduleKey.length - 1
    )
  );
}
