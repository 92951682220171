import { action, makeObservable, observable } from 'mobx';
import { KeyResultUid } from '../types';

export class KeyResultsSelectionStore {
  @observable
  private readonly _selectedKeyResults = observable.set<KeyResultUid>();

  constructor() {
    makeObservable(this);
  }

  @action
  public toggleKeyResultSelection(id: KeyResultUid): void {
    if (this._selectedKeyResults.has(id)) {
      this._selectedKeyResults.delete(id);
    } else {
      this._selectedKeyResults.add(id);
    }
  }

  @action
  public selectKeyResults(ids: KeyResultUid[]) {
    ids.forEach(id => this._selectedKeyResults.add(id));
  }

  @action
  public unSelectKeyResults(ids: KeyResultUid[]) {
    ids.forEach(id => this._selectedKeyResults.delete(id));
  }

  @action
  public clear(): void {
    this._selectedKeyResults.clear();
  }

  public get selectedKeyResults(): Readonly<Set<KeyResultUid>> {
    return this._selectedKeyResults;
  }
}
