import { AsyncStatus } from './async-status';
import { File } from './file-upload';

export interface TrainingDayAttributesStore {
  readonly status: AsyncStatus;
  readonly planAttributes: TrainingDayAttribute[];
  readonly realityAttributes: TrainingDayAttribute[];

  loadTrainingDayAttributes(): Promise<void>;

  setTrainingDayAttributes(trainingDayAttributes: TrainingDayAttribute[]): void;
}

export type TrainingDayAttributeType =
  | 'textarea-basic'
  | 'attachment'
  | 'input-array'
  | 'workout'
  | 'cgm'
  | 'health';

export type TrainingDayAttributeId = number;
export type TrainingDayAttributeState = 'P' | 'R'; // plan | reality

export interface BaseTrainingDayAttribute {
  AttributeItemId: TrainingDayAttributeId;
  DataTypeIdent: TrainingDayAttributeType;
  IsEditable: boolean;
  IsExtra: boolean;
  IsShown: boolean;
  Name: string;
  SortCode: number;
  ToolTip: string;
  Size: number;
  Type: TrainingDayAttributeState;
}

export interface ValueTimestamp {
  UpdatedBy: string;
  UpdatedDate: string;
}

export interface TextAreaBasicAttribute extends BaseTrainingDayAttribute {
  DataTypeIdent: 'textarea-basic';
}

export interface AttachmentAttribute extends BaseTrainingDayAttribute {
  DataTypeIdent: 'attachment';
}

export interface InputArrayAttribute extends BaseTrainingDayAttribute {
  DataTypeIdent: 'input-array';
}

export interface WorkoutAttribute extends BaseTrainingDayAttribute {
  DataTypeIdent: 'workout';
}

export interface CgmAttribute extends BaseTrainingDayAttribute {
  DataTypeIdent: 'cgm';
}

export interface HealthDataAttribute extends BaseTrainingDayAttribute {
  DataTypeIdent: 'health';
}

export type TrainingDayAttribute =
  | TextAreaBasicAttribute
  | AttachmentAttribute
  | InputArrayAttribute
  | WorkoutAttribute
  | CgmAttribute
  | HealthDataAttribute;

export interface BaseTrainingDayData {
  Date: string;
  LastUpdated: string;
  AttributeItemId: number;
  ValueType: TrainingDayValueType;
  Value: BaseTrainingDayValue;
}

export type TrainingDayValueType =
  | 'string'
  | 'attachment'
  | 'input-array'
  | 'workout'
  | 'cgm'
  | 'health';

export type BaseTrainingDayValue =
  | string
  | AttachmentValue[]
  | InputArrayValue
  | WorkoutValue[]
  | CgmValue[]
  | HealthDataValue[];

export interface TextAreaBasicData extends BaseTrainingDayData {
  ValueType: 'string';
  Value: string;
}

export interface AttachmentData extends BaseTrainingDayData {
  ValueType: 'attachment';
  Value: AttachmentValue[];
}

export interface InputArrayData extends BaseTrainingDayData {
  ValueType: 'input-array';
  Value: InputArrayValue;
}

export interface WorkoutData extends BaseTrainingDayData {
  ValueType: 'workout';
  Value: WorkoutValue[];
}

export interface CgmData extends BaseTrainingDayData {
  ValueType: 'cgm';
  Value: CgmValue[];
}

export interface HealthData extends BaseTrainingDayData {
  ValueType: 'health';
  Value: HealthDataValue[];
}

export type TrainingDayData =
  | TextAreaBasicData
  | AttachmentData
  | InputArrayData
  | WorkoutData
  | CgmData
  | HealthData;

export type AttachmentId = number | string;

export interface AttachmentValue extends File {
  TrainingDayAttributeId: AttachmentId;
}

export interface WorkoutValue {
  WorkoutId: number;
  Label: string;
  HeartRateAvg: number;
  HeartRateMax: number | null;
  HeartRateMin: number | null;
  StartDateTime: string;
  TotalTime: number;
  TotalDistance: number;
  ChartData: number[];
}

export interface CgmValue {
  MeasurementDate: string;
  Label: string;
  LastRecording: string;
  EgvsMax: number | null;
  EgvsMin: number | null;
  ChartData: number[];
}

export type HealthDataValue =
  | SleepData
  | RestingHeartRateData
  | BodyBatteryData
  | ReadinessScoreData
  | HeartRateVariabilityData;

export interface SleepData {
  readonly End?: string | null;
  readonly Label?: string | null;
  readonly Score?: number | null;
  readonly Duration?: string | null;
  readonly Start?: string | null;
  readonly Type: 'sleep';
  readonly Efficiency?: string | null;
  [key: string]: string | number | null | undefined;
}

export interface HeartRateVariabilityData {
  readonly Label?: string | null;
  readonly AvgHRV?: string | null;
  readonly MaxHRV?: number | null;
  readonly Type: 'hrv';
  [key: string]: string | number | null | undefined;
}

export interface ReadinessScoreData {
  readonly Label?: string | null;
  readonly Score?: number | null;
  readonly ScoreValue?: string | null;
  readonly Type: 'readiness-score';
  [key: string]: string | number | null | undefined;
}

export interface RestingHeartRateData {
  readonly AvgHR?: number | null;
  readonly MaxHR?: number | null;
  readonly Label?: string | null;
  readonly MinHR?: number | null;
  readonly RestingHR?: number | null;
  Type: 'resting-heart-rate';
  [key: string]: string | number | null | undefined;
}

export interface BodyBatteryData {
  readonly Label?: string | null;
  readonly Discharge?: number | null;
  readonly Charge?: number | null;
  readonly MorningMax?: number | null;
  readonly Type: 'body-battery';
  [key: string]: string | number | null | undefined;
}

export type InputArrayValueData =
  | ({
      Value: string;
    } & ValueTimestamp)
  | null;

export type InputArrayValue = InputArrayValueData[];

export type TrainingDayExtraActionType = 'copy-plan-to-reality' | 'pin-to-top';

export enum weekSummary {
  PINNED = 'pinned-week-summary',
}

export interface AttachmentValue extends File {
  TrainingDayAttributeId: AttachmentId;
}
