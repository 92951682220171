import { isSafariBrowser } from '@yarmill/utils';
import { RefObject, useEffect } from 'react';
import { useEvidenceStore } from './use-evidence-store';

export function useCategoryScroller(
  containerRef: RefObject<HTMLDivElement>
): void {
  const evidenceStore = useEvidenceStore();

  useEffect(() => {
    if (typeof ResizeObserver === 'undefined' || !isSafariBrowser()) {
      return;
    }
    let scrolledUser = evidenceStore.userId;

    function onResize(): void {
      const sectionId = evidenceStore.scrolledCategory;
      if (!sectionId) {
        return;
      }
      const section: HTMLDivElement | null = document.querySelector(
        `#${sectionId}`
      );

      if (scrolledUser !== evidenceStore.userId) {
        scrolledUser = evidenceStore.userId;
        section?.scrollIntoView({ behavior: 'auto', block: 'start' });
      }
    }
    const observer = new ResizeObserver(onResize);
    const container = containerRef.current;

    if (container) {
      observer.observe(container);
    }

    return () => observer.disconnect();
  }, [containerRef, evidenceStore]);
}
