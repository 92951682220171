import { DashboardSelector as DashboardSelectorLayout } from '@yarmill/components';
import { useHistory, useLocation } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { trackChangeDashboardClick } from '../google-analytics/utils';
import { useLegacyAnalyticsStore } from './hooks';
import { DashboardType } from './types';

export interface DashboardSelectorProps {
  activeDashboard: DashboardType | null;
}

export const DashboardSelector = observer(function DashboardSelector(
  props: DashboardSelectorProps
): JSX.Element {
  const { activeDashboard } = props;
  const dashboards = useLegacyAnalyticsStore().dashboards;
  const history = useHistory();
  const intl = useIntl();
  const { search } = useLocation();

  const setActiveDashboard = useCallback(
    (dashboard: DashboardType) => {
      if (dashboard !== activeDashboard) {
        trackChangeDashboardClick(dashboard);
        history.push(`/analytics/${dashboard}${search}`);
      }
    },
    [activeDashboard, history, search]
  );

  const filteredDashboards = useMemo(
    () =>
      dashboards
        .filter(
          d =>
            (activeDashboard === 'general.defaultDashboard' &&
              d.id === 'general.defaultDashboard') ||
            (activeDashboard !== 'general.defaultDashboard' &&
              d.id !== 'general.defaultDashboard')
        )
        .map(d => ({
          id: d.id,
          label: intl.formatMessage({ id: d.label }),
          description: intl.formatMessage({ id: d.description }),
        })),
    [activeDashboard, dashboards, intl]
  );

  return (
    <DashboardSelectorLayout
      activeDashboard={activeDashboard}
      dashboards={filteredDashboards}
      onDashboardClick={setActiveDashboard}
    />
  );
});
