import { useRootStore } from '@yarmill/utils';
import {
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { SeasonEvaluationStore } from './mobx/season-evaluation-store';

export const SeasonEvaluationStoreContext = createContext<
  SeasonEvaluationStore | undefined
>(undefined);

export function useSeasonEvaluationStore(): SeasonEvaluationStore {
  const store = useContext(SeasonEvaluationStoreContext);
  if (!store) {
    throw new Error('Season Evaluation Store not available');
  }

  return store;
}

export function InternalSeasonEvaluationStoreContextProvider(
  props: PropsWithChildren
): JSX.Element {
  const rootStore = useRootStore();
  const store = useRef(new SeasonEvaluationStore(rootStore)).current;

  useEffect(() => {
    return () => {
      store.disposeReactions();
    };
  }, [store]);

  return (
    <SeasonEvaluationStoreContext.Provider value={store}>
      {props.children}
    </SeasonEvaluationStoreContext.Provider>
  );
}

export const SeasonEvaluationStoreContextProvider = memo(
  InternalSeasonEvaluationStoreContextProvider
);
