import { observer } from 'mobx-react-lite';

import { TrainingDayStore } from './mobx/training-day-store';
import { TrainingDayExtraAttribute } from './training-day-extra-attribute';
import { TrainingDayExtraAction } from './training-day-extra-action';

export interface ExtraItemsMenuProps {
  day: TrainingDayStore;
  closeMenu(): void;
}
function InternalExtraItemsMenu(props: ExtraItemsMenuProps) {
  const { day, closeMenu } = props;

  return (
    <>
      {day.extraAttributes.map(attribute => (
        <TrainingDayExtraAttribute
          key={attribute.id}
          attribute={attribute}
          hideTooltip={closeMenu}
        />
      ))}
      {day.extraActions.map(action => (
        <TrainingDayExtraAction
          key={action}
          action={action}
          day={day}
          hideTooltip={closeMenu}
        />
      ))}
    </>
  );
}

export const ExtraItemsMenu = observer(InternalExtraItemsMenu);
