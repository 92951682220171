import { useCollapsible } from '@yarmill/utils';
import { MouseEvent, useCallback, useState } from 'react';
import { EvidenceObjectDataStore } from '../mobx/evidence-object-data-store';
import { EvidenceObjectStore } from '../mobx/evidence-object-store';

export interface EvidenceTableFormHandler {
  addRow: (e: MouseEvent) => void;
  closeForm: (e?: MouseEvent<HTMLButtonElement>) => void;
  editedItem: EvidenceObjectDataStore | null;
  editRow: (e: MouseEvent, item: EvidenceObjectDataStore) => void;
  showForm: boolean;
}

export function useEvidenceTableFormHandler(
  dataStore: EvidenceObjectStore | null
): EvidenceTableFormHandler {
  const [showForm, showEvidenceTableItemForm, closeEvidenceTableItemForm] =
    useCollapsible();
  const [editedItem, setEditedItem] = useState<EvidenceObjectDataStore | null>(
    null
  );

  const closeForm = useCallback(
    (e?: MouseEvent<HTMLButtonElement>): void => {
      setEditedItem(null);
      closeEvidenceTableItemForm(e);
    },
    [closeEvidenceTableItemForm]
  );

  const editRow = useCallback(
    (e: MouseEvent, item: EvidenceObjectDataStore): void => {
      setEditedItem(item);
      showEvidenceTableItemForm(e);
    },
    [showEvidenceTableItemForm]
  );

  const addRow = useCallback(
    (e: MouseEvent) => {
      if (dataStore) {
        const objectDataStore = dataStore.createNewEvidenceObjectDataStore();
        setEditedItem(objectDataStore);
        objectDataStore.setDefaultValues();
        showEvidenceTableItemForm(e);
      }
    },
    [dataStore, showEvidenceTableItemForm]
  );

  return {
    addRow,
    closeForm,
    editedItem,
    editRow,
    showForm,
  };
}
