import {
  NavbarContext,
  NavbarStatus,
  NavbarTransaction as iNavbarTransaction,
} from '@yarmill/types';
import { action, makeObservable, observable, runInAction } from 'mobx';

export class NavbarTransaction implements iNavbarTransaction {
  @observable
  private _status: NavbarStatus | null = null;
  private timeout: ReturnType<typeof setTimeout> | null = null;
  private loadingTimestamp: Date | null = null;
  @observable
  private readonly _context: string | undefined;
  private readonly _stack: string | undefined;

  constructor(status: NavbarStatus, context?: NavbarContext) {
    makeObservable(this);
    this._context = context;
    if (status === 'loading' || status === 'loadingData') {
      this._status = null;
      this.timeout = setTimeout(
        () =>
          runInAction(() => {
            this._status = status;
            this.loadingTimestamp = new Date();
          }),
        100
      );
    } else {
      this._status = status;
    }
    this._stack = this.getStack();
  }

  get context() {
    return this._context;
  }

  get status(): NavbarStatus | null {
    return this._status;
  }

  get stack() {
    return this._stack;
  }
  @action
  success() {
    this.clearTimeout('success');
  }

  @action
  error() {
    this.clearTimeout('error');
  }

  @action
  finished() {
    this.clearTimeout(null);
    this._status = null;
  }

  @action
  private clearTimeout(status: NavbarStatus | null) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    const diff = this.getTimestampDiff();
    if (this.loadingTimestamp && diff < 500) {
      setTimeout(() => runInAction(() => (this._status = status)), 1000 - diff);
    } else {
      this._status = status;
    }
  }

  private getTimestampDiff(): number {
    const now = new Date().getMilliseconds();
    const start = this.loadingTimestamp?.getMilliseconds() ?? now;

    return now - start;
  }

  private getStack() {
    const e = new Error();
    return e.stack;
  }
}
