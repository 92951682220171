import { EvidenceAttributeType } from '@yarmill/types';

export function getAlignmentForAttributeType(
  attributeType: EvidenceAttributeType
): 'left' | 'center' | 'right' | 'justify' | 'char' {
  switch (attributeType) {
    case 'date':
    case 'time':
    case 'number':
    case 'decimal-number':
      return 'right';
    default:
      return 'left';
  }
}
