import {
  PersonalizedSettingsConfiguration,
  SettingsKey,
  UserGroupId,
  UserId,
  SettingsService as iSettingsService,
} from '@yarmill/types';
import { RootStore } from '@yarmill/types';
import { updateSetting } from '../api/update-setting';

export interface EntityIdentifier {
  userId?: UserId;
  groupId?: UserGroupId;
}

export class SettingsService implements iSettingsService {
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  async saveSettings<K extends SettingsKey>(
    key: K,
    value: PersonalizedSettingsConfiguration[K],
    entityIdentifier: EntityIdentifier,
    isGlobal?: boolean
  ): Promise<boolean | undefined> {
    if (entityIdentifier.userId) {
      const user = this.rootStore.usersStore.getUserById(
        entityIdentifier.userId
      );
      user?.updateSetting(key, value);
      if (entityIdentifier.userId === this.rootStore.currentUserStore.id) {
        this.rootStore.currentUserStore.updateSetting(key, value);
      }
    } else if (entityIdentifier.groupId) {
      const group = this.rootStore.groupsStore.getGroupById(
        entityIdentifier.groupId
      );
      group?.updateSetting(key, value);
    }

    const request = this.rootStore.requestsStore.createRequest(() =>
      updateSetting({
        SettingsKey: key,
        Value: value,
        UserId: entityIdentifier.userId,
        UserGroupId: entityIdentifier.userId
          ? undefined
          : entityIdentifier.groupId,

        IsGlobal: isGlobal,
      })
    );

    return await request.getResponse();
  }
}
