import { CurrentUserStore } from '@yarmill/types';
import { ReportItem } from '../types';

export class ReportPermissionsStore {
  private readonly _item: ReportItem;

  constructor(item: ReportItem) {
    this._item = item;
  }

  public isAllowed(currentUser: CurrentUserStore): boolean {
    return (
      this._item.Permissions.Roles.includes(currentUser.role) ||
      this._item.Permissions.Users.includes(currentUser.id)
    );
  }
}
