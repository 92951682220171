import { File, UserId } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { FileOverview } from '../types';

export interface AssignMultipleFilesRequestParams {
  Date: string;
  Files: File[];
  Users: UserId[];
  Module: 'plan' | 'reality';
  Tags: string[];
}

export function assignMultipleFiles(
  params: AssignMultipleFilesRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<FileOverview[]> {
  return axios.post(
    `api/TrainingDayAttributeAttachment/AssignMultiple`,
    {
      ...params,
      Module: params.Module === 'plan' ? 80 : 90,
    },
    {
      cancelToken: cancelToken?.token,
    }
  );
}
