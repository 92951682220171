import { useMergeRefs } from '@floating-ui/react';
import { Icon, IconSize, css, styled } from '@yarmill/components';
import { CheckboxEmpty, CheckboxMinus } from '@yarmill/icons-2';
import { useKeyboardListNavigationItem } from '@yarmill/utils';
import { KeyboardEvent, SyntheticEvent, useEffect, useRef } from 'react';
import { Checkbox } from './checkbox';
import { GenericDropdownItemIcon } from './dropdown-provider/generic-dropdown-item';
import { Radio } from './radio';
import { Text } from './text';
import { TextInputAppearance, getAppearanceColors } from './text-input';

export interface SelectionListItem<V extends string | number = string> {
  readonly color?: TextInputAppearance;
  readonly title: string;
  readonly description?: string;
  readonly value: V;
  readonly icon?: JSX.Element;
  readonly isSelected?: boolean;
}
interface SelectionListProps<V extends string | number = string> {
  readonly items: SelectionListItem<V>[];
  readonly selectedItem?: SelectionListItem<V>;
  readonly onClick?: (item: SelectionListItem<V>, e: SyntheticEvent) => void;
  readonly inputType: 'radio' | 'checkbox';
  readonly inputAppearance?: TextInputAppearance;
}

const PlannerEventTypeSelectorLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectionListItemLayout = styled.button<{
  readonly appearance?: TextInputAppearance;
  readonly isSelected?: boolean;
  readonly noIcon?: boolean;
  readonly disableMouseMove?: boolean;
}>`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ theme, noIcon }) => (noIcon ? '' : theme.size.x2)} 1fr ${({
    theme,
  }) => theme.size.x2};
  column-gap: ${({ theme }) => theme.size.x1};
  align-items: center;
  justify-items: start;
  padding: ${({ theme }) => theme.size.x1} ${({ theme }) => theme.size.x2};
  border: 0;
  background: ${({ isSelected, 'aria-selected': ariaSelected, theme }) =>
    isSelected || ariaSelected
      ? isSelected && ariaSelected
        ? theme.color.black
        : theme.color.blackHover
      : 'transparent'};
  color: ${({ theme, appearance }) =>
    appearance
      ? getAppearanceColors(appearance, theme).text
      : theme.color.white};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'normal')};

  ${({ disableMouseMove, theme }) =>
    !disableMouseMove &&
    css`
      :hover {
        background-color: ${theme.color.blackHover};
      }
    `}

  :focus {
    outline: none;
  }
`;

const DescriptionWrapper = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  color: ${({ theme }) => theme.color.neutral};
  text-align: left;
`;

const SelectAllButtonLayout = styled.div`
  padding-bottom: ${({ theme }) => theme.size.x05};
  border-bottom: 1px solid ${({ theme }) => theme.color.blackHover};
`;

const StyledIcon = styled(Icon)<{ $appearance: TextInputAppearance }>`
  color: ${({ theme, $appearance }) =>
    getAppearanceColors($appearance, theme).base};
`;

interface SelectAllButtonProps {
  readonly isAllSelected: boolean;
  readonly selectAll: () => void;
  readonly unSelectAll: () => void;
  readonly selectAllLabel: string;
  readonly unSelectAllLabel: string;
}
export function SelectAllButton({
  isAllSelected,
  selectAll,
  unSelectAll,
  unSelectAllLabel,
  selectAllLabel,
}: SelectAllButtonProps): JSX.Element {
  return (
    <SelectAllButtonLayout>
      <SelectionListItemLayout
        onClick={isAllSelected ? unSelectAll : selectAll}
        type="button"
        noIcon
      >
        <Text
          appearance={isAllSelected ? 'text12strong' : 'text12'}
          inheritColor
          ellipsis
        >
          {isAllSelected ? unSelectAllLabel : selectAllLabel}
        </Text>

        {isAllSelected && (
          <StyledIcon size={IconSize.s16} $appearance="navy">
            <CheckboxMinus />
          </StyledIcon>
        )}
        {!isAllSelected && (
          <StyledIcon size={IconSize.s16} $appearance="navy">
            <CheckboxEmpty />
          </StyledIcon>
        )}
      </SelectionListItemLayout>
    </SelectAllButtonLayout>
  );
}

interface ListItemProps<V extends string | number = string> {
  readonly item: SelectionListItem<V>;
  readonly isSelected: boolean;
  readonly onClick?: (item: SelectionListItem<V>, e: SyntheticEvent) => void;
  readonly inputAppearance?: TextInputAppearance;
  readonly inputType: 'radio' | 'checkbox';
}
function ListItem<V extends string | number = string>({
  item,
  isSelected,
  onClick,
  inputAppearance,
  inputType,
  ...itemProps
}: ListItemProps<V>) {
  const itemRef = useRef<HTMLButtonElement>(null);
  const [ref, isActive, onMouseEnter, disableMouseMove] =
    useKeyboardListNavigationItem(
      inputType === 'radio' ? isSelected : undefined
    );
  const refs = useMergeRefs([itemRef, ref]);
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onClick?.(item, e);
    }
  };

  useEffect(() => {
    if ((isSelected || isActive) && inputType === 'radio') {
      itemRef.current?.focus();
    }
  }, [inputType, isSelected, isActive]);

  return (
    <SelectionListItemLayout
      key={item.value ?? item.title}
      onClick={onClick ? e => onClick(item, e) : undefined}
      onKeyDown={onKeyDown}
      appearance={item.color}
      type="button"
      isSelected={isSelected}
      aria-selected={isActive}
      ref={refs}
      onMouseOver={onMouseEnter}
      disableMouseMove={disableMouseMove}
      {...itemProps}
    >
      {item.icon ? (
        <Icon size={IconSize.s16}>{item.icon}</Icon>
      ) : (
        <GenericDropdownItemIcon color={item.color ?? 'white'} />
      )}
      <Text
        appearance={isSelected ? 'text12strong' : 'text12'}
        inheritColor
        ellipsis
      >
        {item.title}
      </Text>
      {onClick && (
        <>
          {inputType === 'radio' ? (
            <Radio
              isChecked={isSelected}
              disabled={!onClick}
              appearance={inputAppearance}
            />
          ) : (
            <Checkbox
              isChecked={isSelected}
              disabled={!onClick}
              appearance={inputAppearance}
            />
          )}
        </>
      )}
      {item.description && (
        <DescriptionWrapper>
          <Text inheritColor appearance="label10">
            {item.description}
          </Text>
        </DescriptionWrapper>
      )}
    </SelectionListItemLayout>
  );
}

export function SelectionList<V extends string | number = string>({
  items,
  selectedItem,
  inputType,
  inputAppearance = 'navy',
  onClick,
}: SelectionListProps<V>): JSX.Element {
  return (
    <PlannerEventTypeSelectorLayout>
      {items.map(item => (
        <ListItem
          item={item}
          isSelected={Boolean(
            selectedItem?.value === item.value || item.isSelected
          )}
          key={item.value}
          inputAppearance={inputAppearance}
          onClick={onClick}
          inputType={inputType}
        />
      ))}
    </PlannerEventTypeSelectorLayout>
  );
}
