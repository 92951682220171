import {
  ExternalIcon,
  ExternalIconName,
  NavigationLink,
} from '@yarmill/components';
import { useLocation } from '@yarmill/utils';
import { Location } from 'history';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { trackMainNavClick } from '../google-analytics/utils';
import { LinkCallback } from '../routes/types';
import { useNavigationStore } from './hooks';
import { NavLinkContent } from './nav-link-conent';
import { DropdownContext } from './utils';
import { isActive } from './utils/is-active';

export interface NavItemProps {
  to: LinkCallback;
  moduleKey: string;
  linkText: string;
  icon?: ExternalIconName;
  className?: string;
  onClick?(): void;
  isLinkActive?(pathname: string): boolean;
}

const StyledNavLink = styled(NavLink)<{ readonly $isActiveLink: boolean }>`
  ${NavigationLink}
`;

function InternalNavItem(props: NavItemProps): JSX.Element {
  const { to, icon, moduleKey, onClick, linkText, isLinkActive } = props;

  const navigationStore = useNavigationStore();
  const location = useLocation();
  const closeDropdown = useContext(DropdownContext);
  const handleClick = React.useCallback(() => {
    trackMainNavClick(moduleKey);
    navigationStore.closeNavigation();
    closeDropdown?.();
    onClick?.();
  }, [moduleKey, navigationStore, onClick, closeDropdown]);

  const isActiveLink = isLinkActive
    ? isLinkActive(location.pathname)
    : isActive(location.pathname, to(location as Location));

  const link = icon && <ExternalIcon name={icon} />;

  return (
    <StyledNavLink
      data-cy={moduleKey}
      to={to}
      isActive={(_match, location) =>
        isLinkActive
          ? isLinkActive(location.pathname)
          : isActive(location.pathname, to(location as Location))
      }
      onClick={handleClick}
      $isActiveLink={isActiveLink}
    >
      <NavLinkContent linkText={linkText} linkIcon={link} />
    </StyledNavLink>
  );
}

export const NavItem = React.memo(observer(InternalNavItem));
