import {
  ATHLETE_SEARCH_PARAM,
  GROUP_SEARCH_PARAM,
  ROUTE_DATE_FORMAT,
  WEEK_SEARCH_PARAM,
} from '@yarmill/const';
import {
  Command,
  ExternalIconName,
  GroupStore,
  ModuleCode,
} from '@yarmill/types';
import { History } from 'history';
import moment from 'moment';
import { IntlShape } from 'react-intl';

export function generateCommandPaletteModuleUserCommands(
  groups: GroupStore[],
  icon: ExternalIconName,
  moduleCode: ModuleCode | `${ModuleCode}.${string}`,
  path: string,
  history: History,
  intl: IntlShape
): Command[] {
  return groups
    .flatMap(group =>
      group.athletes.map<Command | null>(athlete => {
        if (!athlete) {
          return null;
        }

        return {
          icon,
          name: intl.formatMessage(
            { id: `commandPalette.goToUser.${moduleCode}` },
            { user: athlete.displayName, group: group.name }
          ),
          createCommand: defaultSearchParams => () => {
            const searchParams = new URLSearchParams(defaultSearchParams);
            searchParams.set(GROUP_SEARCH_PARAM, String(group.id));
            searchParams.set(ATHLETE_SEARCH_PARAM, String(athlete.id));

            if (!searchParams.has(WEEK_SEARCH_PARAM)) {
              searchParams.set(
                WEEK_SEARCH_PARAM,
                moment().format(ROUTE_DATE_FORMAT)
              );
            }

            const url = `${path}?${searchParams.toString()}`;
            history.push(url);
          },
        };
      })
    )
    .filter((c): c is Command => Boolean(c));
}
