import {
  ExternalIcon,
  HealthDataOverviewRow,
  Tippy,
} from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import { SleepData } from '../../training-day/types';

export interface SleepProps {
  value: SleepData;
}
export function Sleep(props: SleepProps): JSX.Element {
  const { value } = props;
  const validatedValue = {
    End: '-',
    Label: '-',
    Score: '-',
    Duration: '-',
    Start: '-',
    Efficiency: '-',
    ...value,
  };

  return (
    <HealthDataOverviewRow
      icon={<ExternalIcon name="Bed" />}
      label={
        value.Label ? <FormattedMessage id={value.Label} /> : 'Missing Label'
      }
      primaryValue={
        <FormattedMessage id="healthData.sleep.value" values={validatedValue} />
      }
      secondaryValues={[
        value.Start !== null && (
          <Tippy tooltipContent="healthData.sleep.start.tooltip">
            <FormattedMessage
              id="healthData.sleep.start"
              values={validatedValue}
            />
          </Tippy>
        ),
        value.End && (
          <Tippy tooltipContent="healthData.sleep.end.tooltip">
            <FormattedMessage
              id="healthData.sleep.end"
              values={validatedValue}
            />
          </Tippy>
        ),
        value.Score && (
          <Tippy tooltipContent="healthData.sleep.score.tooltip">
            <FormattedMessage
              id="healthData.sleep.score"
              values={validatedValue}
            />
          </Tippy>
        ),
        value.Efficiency && (
          <Tippy tooltipContent="healthData.sleep.efficiency.tooltip">
            <FormattedMessage
              id="healthData.sleep.efficiency"
              values={validatedValue}
            />
          </Tippy>
        ),
      ].filter(Boolean)}
    />
  );
}
