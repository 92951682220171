import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';
import { ApiFilters } from '../../../types';
import { SeasonTrend } from '../types';

export function getSeasonTrend(
  filters: ApiFilters
): AxiosPromise<SeasonTrend[]> {
  return axios.post('api/Analytics/General/SeasonTrend', filters);
}
