import styled from 'styled-components';

export const EvidenceContainer = styled.div<{
  readonly size: 'full-width' | 'default';
}>`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  row-gap: 30px;
  ${({ size }) => size === 'default' && 'max-width: 860px'};
  text-align: left;
  margin: 0 auto;
  position: relative;
  width: 100%;
`;
