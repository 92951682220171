import { AttachmentId, AttachmentValue } from '@yarmill/types';
import { AxiosPromise } from 'axios';
import { axios } from '../axios';

export interface RenameAttachmentRequestParams {
  AttachmentId: AttachmentId;
  FileName: string;
}

export function renameAttachment(
  params: RenameAttachmentRequestParams
): AxiosPromise<AttachmentValue> {
  return axios.post('api/TrainingDayAttributeAttachment/rename', params);
}
