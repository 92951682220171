import { createElement } from 'react';

export function BrandYoutube() {
  return createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 24 24',
      strokeWidth: '1.5',
      stroke: 'currentColor',
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      role: 'img'
    },
    createElement('path', { d: 'M0 0h24v24H0z', stroke: 'none' }),
    createElement('path', {
      d: 'M3 9a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v6a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4z'
    }),
    createElement('path', { d: 'm10 9 5 3-5 3z' })
  );
}
