import { ContentBox } from '../content-box';
import { Text } from '../text';
import styled from 'styled-components';

export const WeekGoalsLayout = styled.div<{
  readonly numberOfGoalsWeeks: number;
}>`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 20px;
  justify-content: center;
  padding-left: 12.5px;
  padding-right: 12.5px;
  padding-bottom: 24px;

  @media (min-width: ${1024}px) {
    justify-content: unset;
    & > div:not(:first-child) {
      display: block;
    }

    grid-auto-flow: column;
    grid-template-columns: 90px repeat(
        ${({ numberOfGoalsWeeks }) => numberOfGoalsWeeks},
        1fr
      );
    grid-template-rows: 40px repeat(9, auto);
    column-gap: 30px;
    padding: 20px unset;
    margin-bottom: 40px;
  }
`;
export const WeekGoalsContentBox = styled(ContentBox)<{
  readonly $column: number;
}>`
  place-self: center;
  grid-column: ${({ $column }) => `${$column + 1} / ${$column + 2}`};
  grid-row: 2 / -1;
  position: absolute;
  z-index: -1;
  pointer-events: none;
  width: calc(100% + 20px);
  height: calc(100% + 50px);

  @media (max-width: ${1024 - 1}px) {
    grid-column: 1 / 2;
    grid-row: ${({ $column }) =>
      `${($column - 1) * 10 + 2} / ${($column - 1) * 10 + 9 + 2}`};
  }
`;

export const WeekGoalRow = styled.div`
  display: grid;
  grid-template-columns: 100%;
  column-gap: 5px;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;

  @media (min-width: 560px) {
    grid-template-columns: 100px auto;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 20px auto;
  }

  &:focus-within {
    z-index: 1;
  }
`;

export const GoalLabelWrapper = styled(Text)<{ isWeekDay: boolean }>`
  padding-top: 4px;
  font-weight: 700;

  @media (min-width: 560px) {
    font-weight: normal;
  }

  @media (min-width: ${1024}px) {
    ${props => !props.isWeekDay && `font-size: 0;`}
  }
`;

const CapitalizedDate = styled(Text)`
  text-transform: capitalize;
`;

export const DailyGoalDesktopDate = styled(CapitalizedDate)`
  display: none;
  @media (min-width: ${1024}px) {
    display: inline;
  }
`;

export const DailyGoalMobileDate = styled(CapitalizedDate)`
  display: inline-flex;
  column-gap: 16px;
  @media (min-width: ${559}px) {
    display: none;
  }
`;

export const DailyGoalTabletDate = styled(CapitalizedDate)`
  display: none;

  @media (min-width: ${560}px) and (max-width: 1023px) {
    display: inline;
  }
`;

export const SeasonGoalCapitalizedDay = styled(Text)`
  text-transform: capitalize;
  line-height: 12px;
  margin-bottom: 4px;
`;

export const SeasonGoalTransparentDate = styled(Text)`
  opacity: 0.8;
  line-height: 10px;
`;
export const SeasonGoalLabelWrapper = styled.div`
  padding: 3.5px 0;
`;
