import { SeasonId } from '@yarmill/types';
import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { Objective } from '../types';

export interface PutObjectiveRequestParams {
  userId: number | undefined;
  seasonId: SeasonId;
  objective: Omit<Objective, 'id'> & { id?: string | number };
}

export function putObjective(
  params: PutObjectiveRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<Objective> {
  return axios.put(
    `${coreApiTarget}/api/okrs/userId/${params.userId}/seasonId/${params.seasonId}`,
    params.objective,
    {
      cancelToken: cancelToken?.token,
      params: {
        instanceCode: devMode ? instance : undefined,
      },
    }
  );
}
