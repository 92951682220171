import { User } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export interface UserConsents {
  NewsletterConsent: boolean;
  CommercialConsent: boolean;
}
export function changeEmailPreferences(
  userConsents: UserConsents
): AxiosPromise<User> {
  return axios.put('api/User/settings', userConsents);
}
