import { LineChart } from '../types';
import { Group } from '@visx/group';
import { LinePath } from './primitives/line-path';
import { LineMarker } from './primitives/line-marker';
import { useCallback } from 'react';
import { useXYChartContext } from '../xy-chart-context';
import { DataItem } from '../../reporting/types';

interface LineProps {
  readonly config: LineChart;
  readonly data: DataItem[];
}
export function Line({ config, data }: LineProps) {
  const { xScale, yScale } = useXYChartContext();

  const getX = useCallback(
    (item: DataItem) => {
      const value = xScale(config.getXValue(item) as any) ?? 0;
      const offset = 'bandwidth' in xScale ? xScale.bandwidth() / 2 : 0;

      return value !== undefined ? value + offset : 0;
    },
    [config, xScale]
  );

  const getY = useCallback(
    (item: DataItem) => yScale(config.getYValue(item) as any) ?? 0,
    [config, yScale]
  );

  return (
    <Group>
      <LinePath config={config} data={data} getX={getX} getY={getY} />
      {data.map((item, idx) => {
        const x = getX(item);
        const y = getY(item);

        return (
          <LineMarker
            config={config}
            item={item}
            x={x}
            y={y}
            key={`${x}-${y}-${idx}`}
          />
        );
      })}
    </Group>
  );
}
