import moment from 'moment';

export function formatSeconds(
  seconds: number,
  whiteSpace = `\u00A0\u00A0\u00A0`
): string {
  return `${moment.duration(seconds, 'seconds').format('h:mm', {
    trim: false,
  })}${whiteSpace}`;
}
