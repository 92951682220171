import {
  FloatingPortal,
  autoUpdate,
  flip,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { Icon, IconSize, ThemeProvider, styled } from '@yarmill/components';
import { ISO_DATE_FORMAT, ROUTE_DATE_FORMAT } from '@yarmill/const';
import { Cancel } from '@yarmill/icons-2';
import moment from 'moment';
import { MouseEvent, PropsWithChildren, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, ButtonAppearance } from './button';
import { Datepicker } from './datepicker';
import { DropdownButton } from './dropdown-provider/dropdown-provider';
import { Text } from './text';
import {
  StyledTextInput,
  TextInputProps,
  getAppearanceColors,
} from './text-input';

type DatepickerDropdownProps = PropsWithChildren<
  {
    readonly label: string;
    readonly setFieldValue?: (value: string) => void;
  } & TextInputProps
>;

const DatepickerLayer = styled.div`
  background-color: ${({ theme }) => theme.color.text};
  border-radius: ${({ theme }) => theme.borderRadius.x15};
`;

const LayerHeader = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  color: ${({ theme }) => theme.color.white};
  grid-template-columns: ${({ theme }) => theme.size.x4} auto ${({ theme }) =>
    theme.size.x4};
  padding: ${({ theme }) => theme.size.x05};
  background-color: ${({ theme }) => theme.color.blackHover};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.x15};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.x15};
`;

const LayerFooter = styled.div`
  padding: ${({ theme }) => theme.size.x1};
  background-color: ${({ theme }) => theme.color.blackHover};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.x15};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.x15};
`;

const DatepickerWrapper = styled.div`
  padding: ${({ theme }) => theme.size.x05};
`;

const DateInput = styled(StyledTextInput)`
  background-color: ${({ theme }) => theme.color.text};
  border: 0;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;

  ::-webkit-date-and-time-value {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  -webkit-text-fill-color: ${({ theme, appearance }) =>
    getAppearanceColors(appearance, theme).text};

  :focus {
    background-color: ${({ theme }) => theme.color.text};
  }
`;

const MobilePicker = styled(Button)`
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  @media (pointer: coarse) and (hover: none) {
    position: absolute;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  ::-webkit-date-and-time-value {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
`;

export function DatepickerDropdown({
  children,
  label,
  value: nullableValue,
  setFieldValue,
  ...dateInputProps
}: DatepickerDropdownProps): JSX.Element {
  const [isLayerOpened, setIsLayerOpened] = useState(false);
  const handleOpen = useCallback(() => setIsLayerOpened(o => !o), []);
  const intl = useIntl();
  const value = nullableValue || moment().format(ISO_DATE_FORMAT);

  const { refs, floatingStyles, context } = useFloating({
    open: isLayerOpened,
    onOpenChange: setIsLayerOpened,
    placement: 'bottom-start',
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
  });
  const dismiss = useDismiss(context, {
    bubbles: true,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const setDatepickerDate = useCallback(
    (value: string) => {
      setFieldValue?.(value);
      setIsLayerOpened(false);
    },
    [setFieldValue]
  );

  return (
    <>
      <ButtonWrapper>
        <DropdownButton
          ref={refs.setReference}
          onClick={!dateInputProps.disabled ? handleOpen : undefined}
          type="button"
          disabled={dateInputProps.disabled}
          {...getReferenceProps()}
        >
          {children}
        </DropdownButton>
        <MobilePicker
          {...dateInputProps}
          as="input"
          type="date"
          value={value}
          min={'1900-01-01'}
          max={moment().add(50, 'years').format(ROUTE_DATE_FORMAT)}
          autoComplete="off"
        />
      </ButtonWrapper>
      {isLayerOpened && (
        <FloatingPortal>
          <ThemeProvider theme="main" dark>
            <div
              style={{ ...floatingStyles, zIndex: 2 }}
              {...getFloatingProps()}
              ref={refs.setFloating}
            >
              <DatepickerLayer>
                <LayerHeader>
                  <div />
                  <Text appearance="task13strong" inheritColor align="center">
                    {label}
                  </Text>
                  <Button
                    $square
                    $appearance={ButtonAppearance.Secondary}
                    $appearanceStyle="white"
                    $iconOnly
                    onClick={handleOpen}
                    type="button"
                    aria-label={intl.formatMessage({
                      id: 'sidebar.close',
                    })}
                  >
                    <Icon size={IconSize.s24}>
                      <Cancel />
                    </Icon>
                  </Button>
                </LayerHeader>
                <DatepickerWrapper>
                  <Datepicker value={value} onChange={setDatepickerDate} />
                </DatepickerWrapper>
                <LayerFooter>
                  <DateInput
                    {...dateInputProps}
                    align="center"
                    appearance="tangerine"
                    type="date"
                    autoComplete="off"
                    value={value}
                    onClick={(e: MouseEvent) => {
                      e.preventDefault();
                    }}
                    variant="compact"
                    min={'1900-01-01'}
                    max={moment().add(50, 'years').format(ROUTE_DATE_FORMAT)}
                  />
                </LayerFooter>
              </DatepickerLayer>
            </div>
          </ThemeProvider>
        </FloatingPortal>
      )}
    </>
  );
}
