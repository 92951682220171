import {
  Breakpoints,
  Button,
  ButtonAppearance,
  ExternalIcon,
  Icon,
  IconSize,
  Text,
  TextSize,
  TextTag,
  styled,
  toast,
} from '@yarmill/components';
import { EvidenceObject as iEvidenceObject } from '@yarmill/types';
import { useCollapsible } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import {
  FormEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { EvidenceAttribute } from './evidence-attribute';
import { useEvidenceObjectStore } from './hooks/use-evidence-object-store';
import { handleFormSubmit } from './utils';

export interface EvidenceObjectProps {
  definition: iEvidenceObject;
}

interface StyledEvidenceRowProps {
  readonly numberOfAttributes: number;
  readonly isEditorVisible: boolean;
  readonly hasOnlyFileAttributes: boolean;
  readonly hideHeader?: boolean;
  readonly condensed?: boolean;
}

export const StyledEvidenceRow = styled.form<StyledEvidenceRowProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  min-height: ${({ condensed }) => (condensed ? 'unset' : '80px')};
  font-size: 14px;
  line-height: 1.5;

  @media (min-width: ${Breakpoints.tablet}px) {
    grid-template-columns:
      ${({ hideHeader }) => (hideHeader ? '' : '25%')} repeat(
        ${props => props.numberOfAttributes},
        1fr
      )
      ${props =>
        props.hasOnlyFileAttributes
          ? ''
          : props.isEditorVisible
            ? '120px'
            : '80px'};
  }

  :not(:last-child) {
    border-bottom: 1px solid #e7eaeeff;
  }
`;

const EvidenceObjectName = styled.div`
  padding-top: 15px;

  @media (min-width: ${Breakpoints.tablet}px) {
    padding-top: 29px;
  }
`;

const AttributeWrapper = styled.div<{
  isEditorVisible: boolean;
  condensed?: boolean;
}>`
  grid-column: 1 / 3;
  justify-self: center;
  width: 100%;
  padding-top: ${({ isEditorVisible, condensed }) =>
    isEditorVisible || condensed ? 0 : '10px'};
  padding-bottom: ${({ isEditorVisible, condensed }) =>
    isEditorVisible || condensed ? 0 : '10px'};

  user-select: none;

  span {
    user-select: text;
  }

  @media (min-width: ${Breakpoints.tablet}px) {
    padding-top: ${({ isEditorVisible, condensed }) =>
      isEditorVisible ? 0 : condensed ? '8px' : '29px'};
    padding-bottom: ${({ isEditorVisible, condensed }) =>
      isEditorVisible ? 0 : condensed ? '8px' : '29px'};
    padding-left: 5px;
    padding-right: 5px;
    grid-row: auto;
    grid-column: auto;
  }
`;
const EditButtonsWrapper = styled.div<{
  isEditorVisible: boolean;
  condensed?: boolean;
}>`
  display: flex;
  grid-row: 1 / 2;
  grid-column: 2/3;
  width: 100%;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 5px;

  @media (min-width: ${Breakpoints.tablet}px) {
    padding-top: ${({ condensed }) => (condensed ? 0 : '22px')};
    justify-content: ${({ isEditorVisible }) =>
      isEditorVisible ? 'space-between' : 'flex-end'};
    grid-row: auto;
    grid-column: auto;
  }
`;

export const EvidenceObject = observer(function EvidenceObject(
  props: EvidenceObjectProps
): JSX.Element {
  const { definition } = props;
  const [isEditorVisible, showEditor, hideEditor] = useCollapsible();
  const objectStore = useEvidenceObjectStore(definition.ObjectKey);
  const hasOnlyFileAttributes = !definition.Attributes?.some(
    a => a.AttributeTypeKey !== 'attachment'
  );
  const hideHeader = definition.Features?.HideHeader;
  const condensed = definition.Features?.Condensed;
  const data =
    objectStore?.data && objectStore.data.length
      ? objectStore.data[0]
      : undefined;

  const dataStore = useMemo(
    () => data || objectStore?.createNewEvidenceObjectDataStore(),
    [data, objectStore]
  );

  const isEditable =
    useMemo(() => {
      return definition.Attributes?.some(attr => attr.IsEditable);
    }, [definition]) && dataStore?.isEditable;

  const onEditClick = useCallback(() => {
    window.getSelection()?.empty();
    if (!isEditable) {
      return;
    }

    showEditor();
    dataStore?.setDefaultValues();
  }, [dataStore, showEditor, isEditable]);

  const onFormSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (!dataStore) {
        return;
      }

      const success = await handleFormSubmit(dataStore);

      if (success === null) {
        // FE validation failed, request to BE was not send
        return;
      } else if (success) {
        hideEditor();
        toast('evidence.form.saveSuccess', 'success');
      } else {
        toast('evidence.form.saveError', 'error');
      }
    },
    [hideEditor, dataStore]
  );

  const onFormReset = useCallback(
    (e?: FormEvent) => {
      e?.preventDefault();
      dataStore?.reset();
      hideEditor();
    },
    [dataStore, hideEditor]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onFormReset(e);
      } else if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
        onFormSubmit(e);
      }
    },
    [onFormReset, onFormSubmit]
  );

  useEffect(() => {
    return () => {
      onFormReset();
    };
  }, [onFormReset]);

  return (
    <StyledEvidenceRow
      as={hasOnlyFileAttributes ? 'div' : 'form'}
      numberOfAttributes={definition.Attributes?.length || 0}
      isEditorVisible={isEditorVisible}
      onSubmit={hasOnlyFileAttributes ? undefined : onFormSubmit}
      onReset={hasOnlyFileAttributes ? undefined : onFormReset}
      onKeyDown={hasOnlyFileAttributes ? undefined : handleKeyDown}
      hasOnlyFileAttributes={hasOnlyFileAttributes}
      hideHeader={hideHeader}
      condensed={condensed}
    >
      {!hideHeader && (
        <EvidenceObjectName>
          <Text size={TextSize.s14} medium ellipsis tag={TextTag.div}>
            <FormattedMessage
              id={`${definition.ModuleKey}.${definition.ObjectKey}`}
            />
          </Text>
        </EvidenceObjectName>
      )}
      {definition.Attributes?.map((attribute, idx) => (
        <AttributeWrapper
          key={attribute.AttributeId}
          isEditorVisible={isEditorVisible}
          condensed={condensed}
          onDoubleClick={
            isEditable &&
            !isEditorVisible &&
            dataStore?.isCurrentUserAllowedToWrite &&
            !hasOnlyFileAttributes
              ? onEditClick
              : undefined
          }
        >
          <EvidenceAttribute
            attributeStore={dataStore?.getAttribute(attribute.AttributeId)}
            showEditor={isEditorVisible}
            index={idx}
          />
        </AttributeWrapper>
      ))}
      {definition.Attributes?.length &&
        isEditable &&
        dataStore?.isCurrentUserAllowedToWrite &&
        // if there are files only, we do not want to show editor
        !hasOnlyFileAttributes && (
          <EditButtonsWrapper
            isEditorVisible={isEditorVisible}
            condensed={condensed}
          >
            {isEditorVisible ? (
              <>
                <Button
                  appearance={ButtonAppearance.Link}
                  type="reset"
                  key="reset"
                >
                  <FormattedMessage id="evidence.row.cancel" />
                </Button>
                <Button
                  appearance={ButtonAppearance.Primary}
                  inverted
                  noShadow
                  type="submit"
                  key="submit"
                >
                  <FormattedMessage id="evidence.row.save" />
                </Button>
              </>
            ) : (
              <Button
                appearance={ButtonAppearance.Link}
                onClick={onEditClick}
                key="edit"
                iconOnly
              >
                <Icon size={IconSize.s14}>
                  <ExternalIcon name="Pencil" />
                </Icon>
              </Button>
            )}
          </EditButtonsWrapper>
        )}
    </StyledEvidenceRow>
  );
});
