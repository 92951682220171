import {
  ConflictLayerContentWrapper,
  LayerPortal,
  PopoverItem,
  Tippy,
} from '@yarmill/components';
import {
  CopyConflictErrorResponse,
  useFocusedDayService,
  useLayer,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ConflictResolution } from '../../copy/types';
import { useDiaryStore } from '../../diary/diary-store-context';
import { TrainingDayStore } from '../mobx/training-day-store';

export interface CopyPlanToRealityProps {
  day: TrainingDayStore;
  hideTooltip?(e: React.MouseEvent): void;
}

function InternalCopyPlanToReality(
  props: CopyPlanToRealityProps
): ReactElement {
  const { day, hideTooltip } = props;
  const focusedDayService = useFocusedDayService();
  const diaryStore = useDiaryStore();
  const copyService = diaryStore.copyService;
  const validDate = copyService.isAllowedToCopyPlanToReality(day);
  const conflictLayer = useLayer('alert', { showShim: true });
  const [conflictResponse, setConflictResponse] = useState<
    CopyConflictErrorResponse | undefined
  >();
  async function submitCopy(resolution: ConflictResolution) {
    const result = await copyService.copyPlanToReality(day, resolution);
    if (
      result instanceof CopyConflictErrorResponse &&
      !conflictLayer.isOpened
    ) {
      setConflictResponse(result);
      conflictLayer.open();
    } else if (result) {
      await day.diaryStore.reloadDiaryData();
      focusedDayService.setFocusedDay(day.index);
    }
  }

  const onClick = async (e: React.MouseEvent): Promise<void> => {
    submitCopy('FailOnConflict');

    hideTooltip?.(e);
  };

  return (
    <>
      <PopoverItem
        onClick={validDate ? onClick : undefined}
        appearance={validDate ? 'default' : 'disabled'}
        dataTest="trainingDay.actions.importPlan"
      >
        <Tippy
          isEnabled={!validDate}
          placement="top"
          tooltipContent="trainingDay.actions.importPlan.due"
        >
          <span>
            <FormattedMessage id="trainingDay.actions.importPlan" />
          </span>
        </Tippy>
      </PopoverItem>
      <LayerPortal
        layerHandle={conflictLayer}
        getContent={layer => (
          <ConflictLayerContentWrapper
            layer={layer}
            submitCopy={submitCopy}
            buttonsConfig={conflictResponse?.resolutions ?? []}
            module={diaryStore.diaryType}
            viewType={diaryStore.viewType}
          >
            <FormattedMessage id="trainingDay.actions.importPlan.conflict.description" />
          </ConflictLayerContentWrapper>
        )}
      />
    </>
  );
}

export const CopyPlanToReality = observer(InternalCopyPlanToReality);
