import { Icon, IconSize, Tippy } from '@yarmill/components';
import { Button, ButtonAppearance } from '@yarmill/components-2';
import { Cancel } from '@yarmill/icons-2';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { KeyResultsSelectionContext } from '../key-results-selection-context';

export function CancelSelection(): JSX.Element {
  const intl = useIntl();
  const selectionStore = useContext(KeyResultsSelectionContext);
  async function handleClick() {
    selectionStore.clear();
  }

  return (
    <Tippy
      tooltipContent="okrs.detail.keyResults.cancelSelection"
      noWrapper
      touch={false}
    >
      <Button
        $appearance={ButtonAppearance.Tertiary}
        $appearanceStyle="neutral"
        onClick={handleClick}
        $iconOnly
        aria-label={intl.formatMessage({
          id: 'okrs.detail.keyResults.cancelSelection',
        })}
      >
        <Icon size={IconSize.s24}>
          <Cancel />
        </Icon>
      </Button>
    </Tippy>
  );
}
