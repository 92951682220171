import styled from 'styled-components';
import { Text, TextAlignment, TextTag } from '@yarmill/components';
import { ReactNode } from 'react';

export interface ChartTooltipProps {
  label: string | number;
  items: ChartTooltipItemProps[];
}

const StyledChartTooltip = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
  padding: 10px 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const StyledItemsWrapper = styled.div`
  display: table;
`;

export function ChartTooltip(props: ChartTooltipProps): JSX.Element {
  const { label, items } = props;

  return (
    <StyledChartTooltip>
      <Text bold>{label}</Text>
      <StyledItemsWrapper>
        {items.map(item => (
          <ChartTooltipItem {...item} key={item.id} />
        ))}
      </StyledItemsWrapper>
    </StyledChartTooltip>
  );
}

export interface ChartTooltipItemProps {
  color: string;
  id: string | number;
  label: ReactNode;
  value: ReactNode;
}

const StyledColorIndicator = styled.svg`
  width: 20px;
  height: 20px;
  flex-grow: 1;
`;

const StyledTooltipItem = styled.div`
  display: table-row;
`;
const StyledIndicatorWrapper = styled.div`
  display: table-cell;
`;

const StyledLabelWrapper = styled.div`
  display: table-cell;
  padding: 0 20px;
`;

const StyledValueWrapper = styled.div`
  text-align: right;
  display: table-cell;
`;

function ChartTooltipItem(props: ChartTooltipItemProps) {
  return (
    <StyledTooltipItem>
      <StyledIndicatorWrapper>
        <StyledColorIndicator viewBox="0 0 20 20">
          <circle
            r={4}
            cx={10}
            cy={10}
            fill="transparent"
            stroke={props.color}
            strokeWidth={2}
          />
        </StyledColorIndicator>
      </StyledIndicatorWrapper>
      <StyledLabelWrapper>
        <Text>{props.label}</Text>
      </StyledLabelWrapper>
      <StyledValueWrapper>
        <Text textAlign={TextAlignment.right} tag={TextTag.div}>
          {props.value}
        </Text>
      </StyledValueWrapper>
    </StyledTooltipItem>
  );
}
