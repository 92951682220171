import { TrainingDaysList } from '@yarmill/components';
import { AsyncStatus } from '@yarmill/types';
import { times, useConfig, useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { ReactElement, memo } from 'react';
import { useDiaryStore } from '../diary/diary-store-context';
import { TrainingDay } from './training-day';
import { TrainingDayLoadingPlaceholder } from './training-day-loading-placeholder';
import { WeekSummary } from './week-summary';

function InternalTrainingWeek(): ReactElement {
  const rootStore = useRootStore();
  const diaryStore = useDiaryStore();
  const trainingWeekStore = diaryStore.trainingWeekStore;
  const isGoalsViewEnabled = useConfig('goalsViewEnabled');
  const isInitialConfigLoaded = rootStore.status === AsyncStatus.resolved;
  const showLoadingPlaceholders = !trainingWeekStore || !isInitialConfigLoaded;

  const weekSummary =
    isInitialConfigLoaded && trainingWeekStore ? (
      <WeekSummary weekSummary={trainingWeekStore.weekSummary} />
    ) : (
      <TrainingDayLoadingPlaceholder weekPlaceholder />
    );

  return (
    <TrainingDaysList
      isWeekSummaryPinned={trainingWeekStore?.weekSummary.isPinned}
    >
      {isGoalsViewEnabled && weekSummary}
      {showLoadingPlaceholders &&
        times(7).map((_, idx) => <TrainingDayLoadingPlaceholder key={idx} />)}
      {!showLoadingPlaceholders &&
        trainingWeekStore?.trainingDays.map((store, idx) => (
          <TrainingDay key={store.index} day={store} />
        ))}
    </TrainingDaysList>
  );
}

export const TrainingWeek = memo(observer(InternalTrainingWeek));
