import { RequestStore, RootStore } from '@yarmill/types';
import {
  getIndexForDate,
  getWeekEndString,
  getWeekStartString,
  groupBy,
  times,
} from '@yarmill/utils';
import { CancelTokenSource } from 'axios';
import { action, computed, makeObservable, observable } from 'mobx';
import { getDataServiceOverview } from '../../diary/api/get-data-service-overview';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { DiaryDataUniqueId, DiaryEventData } from '../../diary/types';
import {
  TextAreaBasicData,
  TrainingDayAttribute,
  TrainingDayData,
  WorkoutData,
} from '../types';
import { TrainingDayStore } from './training-day-store';
import { WeekSummaryStore } from './week-summary-store';

export class TrainingWeekStore {
  private readonly rootStore: RootStore;
  private readonly diaryStore: DiaryStore;
  private readonly dataId: DiaryDataUniqueId;

  public readonly trainingDays: TrainingDayStore[];
  public readonly weekSummary: WeekSummaryStore;

  @observable
  private analyticsRequests: RequestStore<WorkoutData[]>[] | null = null;

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    dataId: DiaryDataUniqueId
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.diaryStore = diaryStore;
    this.dataId = dataId;
    this.trainingDays = this.initTrainingDays();
    this.weekSummary = new WeekSummaryStore(
      this.rootStore,
      this.diaryStore,
      dataId
    );
  }

  private initTrainingDays(): TrainingDayStore[] {
    return times(7).map(
      (_, idx) =>
        new TrainingDayStore(
          this.rootStore,
          this.diaryStore,
          idx,
          this.trainingDayAttributes,
          this.dataId
        )
    );
  }

  @computed
  private get trainingDayAttributes(): TrainingDayAttribute[] {
    if (this.dataId.diaryType === 'plan') {
      return this.diaryStore.trainingDayAttributesStore.planAttributes;
    } else {
      return this.diaryStore.trainingDayAttributesStore.realityAttributes;
    }
  }

  public getTrainingDay(index: number): TrainingDayStore {
    return this.trainingDays[index];
  }

  public clear(): void {
    this.weekSummary.clear();
    this.trainingDays.forEach(trainingDay => trainingDay.clear());
  }

  @action
  public setWeekData(data: TrainingDayData[]): void {
    const weekGoalAttributeId = this.diaryStore.goalsAttributes?.week;

    data.forEach(item => {
      const index = getIndexForDate(item.Date);
      if (item.AttributeItemId === weekGoalAttributeId && index === 0) {
        if (typeof item.Value === 'string') {
          this.weekSummary.setWeekGoalValue(item);
        }
      } else {
        this.trainingDays[index]?.setAttributeApiValue(
          item.AttributeItemId,
          item
        );
      }
    });
  }

  @action
  public setWeekEvents(events: DiaryEventData[]) {
    const eventsByDay = groupBy(events, 'EventDay');

    Array.from(eventsByDay.entries()).forEach(([date, dayEvents]) => {
      const index = getIndexForDate(date);
      this.trainingDays[index]?.setEvents(dayEvents);
    });
  }

  @action
  public loadAdditionalData(): void {
    this.analyticsRequests?.forEach(request => {
      request.cancel();
      this.rootStore.requestsStore.removeRequest(request);
    });
    const services = this.rootStore.configStore.dataServices;

    services.forEach(service => {
      void this.loadDataServiceData(service.Code);
    });
  }

  public disposeReactions(): void {
    this.trainingDays.forEach(trainingDay => trainingDay.disposeReactions());
  }

  public hasFilledDay(date: string): boolean {
    const index = getIndexForDate(date);

    return this.trainingDays[index]?.hasFilledAttributes;
  }

  private async loadDataServiceData(service: string): Promise<void> {
    const athleteId = this.dataId.athleteId;

    if (!athleteId) {
      return;
    }

    const request = this.rootStore.requestsStore.createRequest(
      (cancelToken: CancelTokenSource) =>
        getDataServiceOverview(
          {
            userId: athleteId,
            startDate: getWeekStartString(this.dataId.week),
            endDate: getWeekEndString(this.dataId.week),
          },
          service,
          cancelToken
        )
    );
    this.analyticsRequests?.push(request);

    const response = await request.getResponse();

    if (response) {
      this.setWeekData(response);
    }
  }

  public enableApiSynchronization(): void {
    this.weekSummary.enableApiSynchronization();
    this.trainingDays.forEach(day => day.enableApiSynchronization());
  }

  @action
  public setGoalsPlaceholders(data: TextAreaBasicData[]): void {
    const dailyGoal = this.diaryStore.goalsAttributes?.day;
    const weekGoal = this.rootStore.configStore.goalsAttributes?.reality.week;

    data.forEach(item => {
      const index = getIndexForDate(item.Date);
      if (item.AttributeItemId === weekGoal) {
        this.weekSummary.setWeekGoalPlaceholder(item);
      } else {
        if (dailyGoal) {
          this.trainingDays[index]?.setAttributePlaceholder(dailyGoal, item);
        }
      }
    });
  }
}
