import {
  ButtonAppearance,
  SegmentedButton,
  SegmentedControl,
  StyledViewSelector,
  styled,
} from '@yarmill/components';
import { useCurrentUserStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { useSeasonEvaluationStore } from './season-evaluation-store-context';

const ViewSelectorWrapper = styled(StyledViewSelector)`
  margin-right: 0;

  @media (min-width: 991px) {
    margin-right: 0;
  }
`;

export const ViewSelector = observer(function ViewSelector(): JSX.Element {
  const seasonEvaluationStore = useSeasonEvaluationStore();
  const currentUser = useCurrentUserStore();
  const activeViewType = seasonEvaluationStore.viewType;

  return (
    <ViewSelectorWrapper>
      <SegmentedControl>
        {currentUser.isAllowedTo('seasonEvaluation.singleSeasonEvaluation') && (
          <SegmentedButton
            appearance={ButtonAppearance.Navigation}
            active={activeViewType === 'single-season'}
            onClick={seasonEvaluationStore.setSingleSeasonViewType}
          >
            <FormattedMessage id="seasonEvaluation.viewType.singleSeason" />
          </SegmentedButton>
        )}
        {currentUser.isAllowedTo(
          'seasonEvaluation.seasonOverSeasonEvaluation'
        ) && (
          <SegmentedButton
            appearance={ButtonAppearance.Navigation}
            active={activeViewType === 'season-over-season'}
            onClick={seasonEvaluationStore.setSeasonOverSeasonViewType}
          >
            <FormattedMessage id="seasonEvaluation.viewType.seasonOverSeason" />
          </SegmentedButton>
        )}
      </SegmentedControl>
    </ViewSelectorWrapper>
  );
});
