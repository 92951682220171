import { axios } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { WeekAttendanceItem } from '../types';

export interface UpdateWeekAttendanceItemRequestParams
  extends WeekAttendanceItem {
  ActivityItemId: number | null;
}

export function updateAttendanceItem(
  params: UpdateWeekAttendanceItemRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<boolean> {
  return axios.post('api/attendance/save/user', params, {
    cancelToken: cancelToken?.token,
  });
}
