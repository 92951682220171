import styled from 'styled-components';

const SCROLLBAR_OFFSET = '15px';
export const StyledWeekViewRow = styled.div<{ readonly noNavbar?: boolean }>`
  @media (min-width: 1024px) {
    overflow: hidden;
    display: grid;
    grid-column-gap: calc(30px - ${SCROLLBAR_OFFSET});
    grid-template-columns: 1fr 50%;
    grid-template-rows: 100%;
    padding-bottom: unset;
    flex-grow: 1;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 5fr 7fr;
  }

  @media print {
    display: block;
    max-height: unset;
  }
`;

export const TrainingDaysListScrollWrapper = styled.div`
  @media (min-width: 1024px) {
    overflow: auto;
    max-height: 100%;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    padding-right: ${SCROLLBAR_OFFSET};
  }

  @media print {
    height: auto;
    width: 100% !important;
    max-width: 100% !important;
    flex: 1 !important;
    page-break-after: always;
  }
`;
