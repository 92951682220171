import {
  AthleteWithAvatar as AthleteWithAvatarLayout,
  Icon,
  IconSize,
  RawValue,
  Text,
  TextAlignment,
  TextSize,
  TextTag,
  WhiteSpace,
  YarmillIcon,
} from '@yarmill/components';
import { ExternalIconName } from '@yarmill/types';
import { observer } from 'mobx-react-lite';
import { ComponentType, Fragment, ReactNode } from 'react';
import { FormattedDate } from 'react-intl';
import { useReportingDataStore } from './hooks/use-reporting-data-store';
import {
  BusinessFormatterOptions,
  OverviewTableCellData,
  ReportingDataBusinessFormat,
  ReportingDataPredefinedFormat,
} from './types';
import { formatValueByBusinessFormat } from './utils';

export function getBusinessFormatFormatter(
  businessFormat: ReportingDataBusinessFormat,
  format: string | number | null,
  wrapper?: ComponentType<{
    value?: number | string;
    cellData?: OverviewTableCellData;
  }>,
  options?: BusinessFormatterOptions
): (value: number | string, cellData?: OverviewTableCellData) => ReactNode {
  const WrapperComponent = wrapper || Fragment;
  return (value, cellData?: OverviewTableCellData) => (
    <WrapperComponent
      {...(WrapperComponent === Fragment ? {} : { value, cellData })}
    >
      {formatValueByBusinessFormat(value, businessFormat, format, options)}
    </WrapperComponent>
  );
}

export function getDataFormatter(
  format: ReportingDataPredefinedFormat,
  options?: BusinessFormatterOptions
): (value: number | string) => JSX.Element {
  switch (format) {
    case 'athleteWithAvatar':
      return (value: number | string) => (
        <AthleteWithAvatar value={value} options={options} />
      );
    case 'shortDateDay':
      return (value: number | string) => <ShortDateDay value={value} />;
    case 'richtext':
      return (value: string | number) => (
        <RawValue dangerouslySetInnerHTML={{ __html: String(value) }} />
      );
    case 'weekday':
      return (value: number | string) => <WeekDay value={value} />;
    case 'icon':
      return (value: number | string) => <IconFormat value={value} />;
  }
}

interface DataFormatComponentProps {
  value: string | number;
  options?: BusinessFormatterOptions;
}

const AthleteWithAvatar = observer(function AthleteWithAvatar(
  props: DataFormatComponentProps
): JSX.Element {
  const { value, options } = props;
  const dataStore = useReportingDataStore();
  const user = dataStore?.getDataSource('Users')?.get(Number(value));

  return (
    <AthleteWithAvatarLayout
      name={user?.displayName ?? ''}
      avatar={user?.avatar}
      ellipsis={options?.ellipsis}
      monoSpace={options?.monoSpace}
    />
  );
});

function ShortDateDay(props: DataFormatComponentProps): JSX.Element {
  const { value, options } = props;

  return (
    <>
      <Text
        tag={TextTag.div}
        bold
        size={TextSize.s14}
        textAlign={TextAlignment.center}
        monoSpace={options?.monoSpace}
      >
        <FormattedDate value={value} weekday="short" />
      </Text>
      <Text
        tag={TextTag.div}
        size={TextSize.s14}
        whiteSpace={WhiteSpace.noWrap}
        textAlign={TextAlignment.center}
      >
        <FormattedDate value={value} day="numeric" month="numeric" />
      </Text>
    </>
  );
}

function WeekDay(props: DataFormatComponentProps): JSX.Element {
  const { value, options } = props;

  return (
    <>
      <Text
        tag={TextTag.div}
        bold
        size={TextSize.s14}
        textAlign={TextAlignment.center}
        monoSpace={options?.monoSpace}
      >
        <FormattedDate value={value} weekday="short" />
      </Text>
    </>
  );
}

function IconFormat(props: DataFormatComponentProps): JSX.Element {
  const { value } = props;

  return (
    <>
      <Icon size={IconSize.s18}>
        <YarmillIcon name={value as ExternalIconName} />
      </Icon>
    </>
  );
}
