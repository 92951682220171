import {
  StyledFullScreenLayerContent,
  Text,
  styled,
} from '@yarmill/components';

export const LayerContentContainer = styled(StyledFullScreenLayerContent)`
  @media (min-width: 768px) {
    max-width: 600px;
    margin: 0 auto;
  }
`;
export const FormFieldWrapper = styled.div`
  margin-top: 22px;
`;

export const LayerHeadline = styled(Text)`
  margin-bottom: 24px;
`;
