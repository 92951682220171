import { BaseWorkoutData } from './index';

const GAP_SIZE = 10;

export function isInGap(all: BaseWorkoutData[], idx: number, key: string) {
  const baseTime = all[idx].time;
  if (!baseTime) {
    return true;
  }

  let prevDiff = 0;
  let nextDiff = 0;

  let checkPrev = true;
  let checkNext = true;

  for (let i = 1; i < all.length / 2; i++) {
    const prev = all[idx - i];
    if (checkPrev) {
      if (!prev || !prev.time || (prev && prev[key] !== null)) {
        checkPrev = false;
        if (prev?.time) {
          prevDiff = baseTime - prev.time;
        }
      } else if (prev && prev.time) {
        prevDiff = baseTime - prev.time;
      }
    }

    if (prevDiff + nextDiff >= GAP_SIZE) {
      return true;
    }

    const next = all[idx + i];
    if (checkNext) {
      if (!next || !next.time || (next && next[key] !== null)) {
        checkNext = false;
        if (next?.time) {
          nextDiff = next.time - baseTime;
        }
      } else if (next && next.time) {
        nextDiff = next.time - baseTime;
      }
    }

    if (prevDiff + nextDiff >= GAP_SIZE) {
      return true;
    }

    if (!checkNext && !checkPrev) {
      return false;
    }
  }

  return false;
}
