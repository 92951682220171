import { groupCookies } from '@yarmill/const';
import { CurrentUserStore, GroupsStore } from '@yarmill/types';
import { loadPersistedCurrentUser } from './load-persisted-current-user';

export function loadPersistedGroupId(
  groupsStore: GroupsStore,
  currentUser: CurrentUserStore
): number | null {
  const groupId = window.localStorage.getItem(groupCookies.GROUP);
  const persistedUser = loadPersistedCurrentUser();
  const currentUserId = currentUser.id;

  if (
    !groupId ||
    !currentUserId ||
    (persistedUser && persistedUser !== currentUserId)
  ) {
    return null;
  }

  if (!groupsStore.getGroupById(Number(groupId))) {
    window.localStorage.removeItem(groupCookies.GROUP);

    return null;
  }

  if (!currentUser.hasPermissionToGroup(Number(groupId))) {
    return null;
  }

  return Number(groupId);
}
