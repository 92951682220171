import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from '@yarmill/const';
import {
  AsyncStatus,
  EvidenceModuleConfiguration,
  EvidenceObject,
  RootStore,
  UserGroupId,
  UserId,
  EvidenceStore as iEvidenceStore,
} from '@yarmill/types';
import {
  IReactionDisposer,
  computed,
  makeObservable,
  observable,
  reaction,
  when,
} from 'mobx';
import { DEFAULT_DATA_URL, DEFAULT_DEFINITION_URL } from '../const';
import { EvidenceDataStore } from './evidence-data-store';
import { EvidenceDefinitionStore } from './evidence-definition-store';
import { EvidenceSubmitHistoryStore } from './evidence-submit-history-store';

export class EvidenceStore implements iEvidenceStore {
  private readonly rootStore: RootStore;
  public readonly evidenceSubmitHistoryStore: EvidenceSubmitHistoryStore;

  @observable
  private _moduleKey: string | null = null;

  @observable
  private _moduleConfiguration: EvidenceModuleConfiguration | null = null;

  @observable
  private definitionStore: EvidenceDefinitionStore | null = null;

  @observable
  private _dataStore: EvidenceDataStore | null = null;

  @observable
  private _userId: UserId | null = null;

  @observable
  private _groupId: UserGroupId | null = null;

  @observable
  private _scrolledCategory: string | null = null;

  private reactions: IReactionDisposer[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.evidenceSubmitHistoryStore = new EvidenceSubmitHistoryStore();

    when(
      () => rootStore.status === AsyncStatus.resolved,
      async () => {
        if (!rootStore.modulesStore.evidence) {
          return;
        }
        this.registerReactions();
      }
    );
  }

  registerReactions() {
    const observeModuleConfiguration = reaction(
      () => this._moduleConfiguration,
      async definition => {
        if (definition) {
          this.definitionStore = new EvidenceDefinitionStore(
            this.rootStore,
            definition.definitionUrl || DEFAULT_DEFINITION_URL,
            definition.moduleKey
          );
          await this.definitionStore.loadDefinition();
        }
      }
    );

    const observePathname = reaction(
      () => this.rootStore.historyService.pathname,
      pathname => {
        if (!pathname.includes('evidence')) {
          this._moduleKey = null;
        }
        const match = pathname.match(/\/evidence\/(.+)$/);
        this._moduleKey = match?.[1] || null;
      },
      {
        fireImmediately: true,
      }
    );

    const observerAthleteId = reaction(
      () =>
        this.rootStore.historyService.searchParams.get(ATHLETE_SEARCH_PARAM),
      id => {
        this._userId = id !== undefined ? Number(id) : null;
      },
      {
        fireImmediately: true,
      }
    );
    const observeGroupId = reaction(
      () => this.rootStore.historyService.searchParams.get(GROUP_SEARCH_PARAM),
      id => {
        this._groupId = id !== undefined ? Number(id) : null;
      },
      {
        fireImmediately: true,
      }
    );

    const observeModuleKey = reaction(
      () => this._moduleKey,
      () => {
        this._moduleConfiguration =
          this.rootStore.configStore.evidenceModuleConfigurations.find(
            conf => conf.moduleKey === this._moduleKey
          ) || null;
        this._dataStore = null;
      },
      {
        fireImmediately: true,
      }
    );

    const dataStore = reaction(
      () => ({
        definition: this.definition,
        userId: this._userId,
        groupId: this._groupId,
      }),
      ({ definition }) => {
        if (definition && this._moduleKey) {
          this.createDataStore();
        }
      }
    );

    this.reactions.push(
      observePathname,
      observerAthleteId,
      observeGroupId,
      observeModuleConfiguration,
      dataStore,
      observeModuleKey
    );
  }

  createDataStore(): void {
    if (this.moduleKey && this.definition) {
      this._dataStore = new EvidenceDataStore(
        this.rootStore,
        this,
        this.definition,
        this.moduleKey,
        this._userId,
        this._groupId,
        this.dataUrl!,
        this.isValidParamsCombination
      );

      if (this.isValidParamsCombination) this._dataStore.load();
    }
  }

  setScrolledCategory(category: string): void {
    this._scrolledCategory = category;
  }

  get scrolledCategory(): string | null {
    return this._scrolledCategory;
  }

  @computed
  get dataUrl(): string {
    return this._moduleConfiguration?.dataUrl || DEFAULT_DATA_URL;
  }

  @computed
  get definition(): EvidenceObject[] | undefined {
    return this.definitionStore?.definition;
  }

  get userId(): number | null {
    return this._userId;
  }

  get groupId(): number | null {
    return this._groupId;
  }

  get moduleKey(): string | null {
    return this._moduleKey;
  }

  get moduleConfiguration(): EvidenceModuleConfiguration | null {
    return this._moduleConfiguration;
  }

  disposeReactions(): void {
    this.reactions.forEach(dispose => dispose());
  }

  @computed
  get isCurrentUserAllowedToWrite(): boolean {
    const currentUser = this.rootStore.currentUserStore;

    return (
      currentUser.isAllowedTo(`evidence.${this.moduleKey}.write`) &&
      currentUser.hasWritePermission(this._groupId, this._userId)
    );
  }

  @computed
  get isValidParamsCombination(): boolean {
    const cfg = this._moduleConfiguration;
    return Boolean(
      cfg &&
        ((cfg.athleteEnabled && this._userId) || // athlete is enabled so we require athlete id
          (cfg.groupEnabled && this._groupId) || // group is enabled so we require group id
          (!cfg.groupEnabled && !cfg.athleteEnabled))
    ); // athlete and group are disabled so we show data but they will not be editable
  }

  get dataStore() {
    return this._dataStore;
  }
}
