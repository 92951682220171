import { LabelPosition } from '../../reporting/types';
import { LABEL_FONT_SIZE, LABEL_OFFSET } from '../../reporting/const';

export function shouldShowBarStackLabel(
  showLabels: boolean | undefined,
  position: LabelPosition | undefined,
  barHeight: number
): boolean {
  if (!showLabels || !barHeight) {
    return false;
  }

  if (position === 'inside-center' || position === 'inside-top') {
    if (barHeight < LABEL_FONT_SIZE + LABEL_OFFSET * 2) {
      return false;
    }
  }

  return true;
}
