import { styled } from '@yarmill/components';
import { RightPanelSectionHeading } from '@yarmill/components-2';

export const EventSetEventsListHeading = styled(RightPanelSectionHeading)`
  padding: 0 ${({ theme }) => theme.size.x2};
  margin-top: ${({ theme }) => theme.size.x2};
  margin-bottom: ${({ theme }) => theme.size.x1};
`;

export const EventSetEventsForm = styled.form`
  display: contents;
`;

export const AttributeTagsList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.size.x05};
  padding: 0 ${({ theme }) => theme.size.x2};
`;
