import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  Icon,
  IconSize,
  Text,
  TextSize,
  styled,
} from '@yarmill/components';
import { EvidenceObject as iEvidenceObject } from '@yarmill/types';
import { useCollapsible } from '@yarmill/utils';
import { FormattedMessage } from 'react-intl';
import { EvidenceItem } from './evidence-item';

export interface EvidenceSubcategoryProps {
  definition: iEvidenceObject;
}

const StyledEvidenceSubcategory = styled.div``;

const SubcategoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SubcategoryChildren = styled.div`
  padding-left: 20px;
`;

export function EvidenceSubcategory(
  props: EvidenceSubcategoryProps
): JSX.Element {
  const { definition } = props;
  const [visible, show, hide] = useCollapsible();

  return (
    <StyledEvidenceSubcategory>
      <SubcategoryHeader>
        <Text size={TextSize.s14} bold>
          <FormattedMessage
            id={`${definition.ModuleKey}.${definition.ObjectKey}`}
          />
        </Text>
        <Button
          appearance={ButtonAppearance.Link}
          square
          onClick={visible ? hide : show}
        >
          <Icon size={IconSize.s22}>
            {visible ? (
              <ExternalIcon name="ChevronUp" />
            ) : (
              <ExternalIcon name="ChevronDown" />
            )}
          </Icon>
        </Button>
      </SubcategoryHeader>
      <SubcategoryChildren>
        {visible &&
          definition.Children.map(item => (
            <EvidenceItem
              definition={item}
              key={`${item.ModuleKey}.${item.ObjectKey}`}
            />
          ))}
      </SubcategoryChildren>
    </StyledEvidenceSubcategory>
  );
}
