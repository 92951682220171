import * as React from 'react';
import styled from 'styled-components';
import { Text, TextSize, WhiteSpace } from '../text';

export enum TagAppearance {
  grey,
  blue,
  darkYellow,
  lightYellow,
  red,
}

export type TagProps = React.PropsWithChildren<{
  appearance?: TagAppearance;
}>;

function getBackgroundColor(appearance?: TagAppearance): string {
  switch (appearance) {
    case TagAppearance.blue:
      return '#c4b1ec';
    case TagAppearance.darkYellow:
      return 'rgb(248,211,79)';
    case TagAppearance.lightYellow:
      return 'rgb(252,237,176)';
    case TagAppearance.red:
      return '#ecb1b1';
    case TagAppearance.grey:
    default:
      return '#b1ece4';
  }
}

const StyledTag = styled.span<TagProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  height: 30px;
  color: #4a4a4a;
  font-size: 12px;
  padding: 6px 20px 5px;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
  background-color: ${props => getBackgroundColor(props.appearance)};
  box-sizing: border-box;
`;

export function Tag(props: TagProps): JSX.Element {
  const { appearance = TagAppearance.grey, children } = props;

  return (
    <StyledTag appearance={appearance}>
      <Text size={TextSize.s12} whiteSpace={WhiteSpace.noWrap}>
        {children}
      </Text>
    </StyledTag>
  );
}
