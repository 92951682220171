import { Icon, IconSize, styled } from '@yarmill/components';
import { TextInputProps, getAppearanceColors } from '@yarmill/components-2';
import { CheckboxSmallChecked, CheckboxSmallEmpty } from '@yarmill/icons-2';

interface KeyResultCheckboxProps {
  readonly isChecked?: boolean;
  readonly appearance?: TextInputProps['appearance'];
  readonly onClick?: () => void;
  readonly disabled?: boolean;
}

const StyledInput = styled.input`
  position: absolute;
  left: -200vw;
`;

export const KeyResultCheckboxWrapper = styled.label`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.size.x1};
`;

const CheckboxIcon = styled(Icon)<{
  readonly $appearance: TextInputProps['appearance'];
  readonly disabled?: boolean;
}>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme, $appearance }) =>
    $appearance ? getAppearanceColors($appearance, theme).base : 'inherit'};
`;
export function KeyResultCheckbox({
  isChecked,
  appearance,
  onClick,
  disabled,
}: KeyResultCheckboxProps): JSX.Element {
  return (
    <CheckboxIcon
      size={IconSize.s16}
      $appearance={appearance}
      disabled={disabled}
    >
      {isChecked ? <CheckboxSmallChecked /> : <CheckboxSmallEmpty />}
      <StyledInput
        type="checkbox"
        checked={isChecked}
        onChange={onClick}
        readOnly={!onClick}
        disabled={disabled}
      />
    </CheckboxIcon>
  );
}
