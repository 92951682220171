import { PropsWithChildren } from 'react';
import { styled, css } from '../theme-provider';

const LinkContentLayout = styled.span<{
  readonly hasIcon?: boolean;
  readonly hasSecondary?: boolean;
  readonly disabled?: boolean;
}>`
  display: inline-grid;
  grid-template-rows: ${({ hasSecondary }) =>
    hasSecondary ? `auto auto` : '1fr'};
  grid-template-columns: ${({ hasIcon, theme }) =>
    hasIcon ? `${theme.name === 'main' ? '16px' : '25px'} 1fr` : '1fr'};
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: #6c757d;
    `}
  column-gap: 15px;
  align-items: center;

  &:hover {
    color: #4a90e2;
  }
`;

const IconWrapper = styled.span`
  grid-template-columns: 1 / 2;
  grid-template-rows: 1 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainContentWrapper = styled.span<{
  readonly hasIcon?: boolean;
  readonly isActive?: boolean;
}>`
  grid-template-columns: ${({ hasIcon }) => (hasIcon ? `2 / 3` : `1/2`)};
  grid-template-rows: 1 / 2;
  font-size: 16px;
  font-family: 'Ubuntu';
  text-decoration: none;
  font-weight: ${({ isActive }) => (isActive ? '700' : 'normal')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

const SecondaryContentWrapper = styled.span<{ readonly hasIcon?: boolean }>`
  grid-template-columns: ${({ hasIcon }) => (hasIcon ? `2 / 3` : `1/2`)};
  grid-template-rows: 2 / 3;
  font-size: 10px;
  font-family: 'Ubuntu';
  text-decoration: none;
`;

export const SidebarAvatar = styled.img`
  width: 100%;
  height: 100%;
`;

type SidebarLinkContentProps = PropsWithChildren<{
  readonly icon?: JSX.Element;
  readonly secondaryInfo?: JSX.Element;
  readonly disabled?: boolean;
  readonly isActive?: boolean;
}>;

export function SidebarLinkContent({
  children,
  disabled,
  icon,
  isActive,
  secondaryInfo,
}: SidebarLinkContentProps): JSX.Element {
  return (
    <LinkContentLayout
      disabled={disabled}
      hasIcon={Boolean(icon)}
      hasSecondary={Boolean(secondaryInfo)}
    >
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <MainContentWrapper hasIcon={Boolean(icon)} isActive={isActive}>
        {children}
      </MainContentWrapper>
      {secondaryInfo && (
        <SecondaryContentWrapper hasIcon={Boolean(icon)}>
          {secondaryInfo}
        </SecondaryContentWrapper>
      )}
    </LinkContentLayout>
  );
}
