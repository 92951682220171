import {
  toastErrorFileUpload,
  toastShowFileUpload,
  toastSuccessFileUpload,
} from '@yarmill/components';
import { FileUploadStore } from '@yarmill/types';
import { useFileUpload } from '@yarmill/utils';
import { useCallback } from 'react';
import { EvidenceAttachmentStore } from '../mobx/evidence-attachment-store';

export function useEvidenceFileUpload(
  attribute: EvidenceAttachmentStore,
  saveOnUpload = true
) {
  const onSuccess = useCallback(
    async (fileUpload: FileUploadStore, toastId: number | string) => {
      const file = fileUpload.uploadedFile;
      if (file) {
        attribute.addAttachment(fileUpload.uploadedFile);
        if (saveOnUpload) {
          await attribute.objectDataStore.syncValue();
        }
        toastSuccessFileUpload(toastId);
      } else {
        toastErrorFileUpload(toastId);
      }
      attribute.objectDataStore.pendingAttachments.remove(fileUpload);
    },
    [attribute, saveOnUpload]
  );

  const onStart = useCallback(
    (fileUpload: FileUploadStore) => {
      attribute.objectDataStore.pendingAttachments.push(fileUpload);
      return toastShowFileUpload(fileUpload);
    },
    [attribute]
  );

  const onError = useCallback(
    (fileUpload: FileUploadStore, toastId: string | number) => {
      attribute.objectDataStore.pendingAttachments.remove(fileUpload);
      toastErrorFileUpload(toastId);
    },
    [attribute]
  );

  return useFileUpload(onSuccess, onError, onStart);
}
