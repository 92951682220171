import { GlobalIntl } from '@yarmill/components';
import {
  Command,
  ExternalIconName,
  ModuleCode,
  ModuleDefinitionStore,
  NavigationLink,
  RootStore,
} from '@yarmill/types';
import {
  createModuleLink,
  generateCommandPaletteModuleUserCommands,
  getCurrentUserAllowedGroups,
} from '@yarmill/utils';

export class AttendanceModuleDefinitionStore implements ModuleDefinitionStore {
  private readonly _rootStore: RootStore;
  readonly moduleCode: ModuleCode = 'attendance';
  private readonly _icon: ExternalIconName = 'UserCheck';
  private readonly _baseUrl: string = '/attendance/week';

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
  }

  get navigationLinks(): NavigationLink[] {
    return [
      {
        icon: this._icon,
        moduleCode: this.moduleCode,
        label: 'header.navigation.attendance',
        isActive: path => path.startsWith('/attendance'),
        createClickHandler: defaultSearchParams => location =>
          createModuleLink(this._baseUrl, defaultSearchParams, location),
      },
    ];
  }

  get commandPaletteCommands(): Command[] {
    const history = this._rootStore.historyService.history;
    const currentUser = this._rootStore.currentUserStore;

    const groups = getCurrentUserAllowedGroups(
      this._rootStore.groupsStore,
      currentUser
    );

    const generalCommand: Command = {
      priority: 1,
      icon: this._icon,
      name: GlobalIntl.formatMessage({
        id: 'commandPalette.goToAttendance',
      }),
      createCommand: (defaultSearchParams: string) => () => {
        history.push(
          createModuleLink(this._baseUrl, defaultSearchParams, history.location)
        );
      },
    };

    const userCommands: Command[] = !currentUser.isAthlete
      ? generateCommandPaletteModuleUserCommands(
          groups,
          this._icon,
          this.moduleCode,
          this._baseUrl,
          history,
          GlobalIntl
        )
      : [];

    return [generalCommand, ...userCommands];
  }
}
