import { useIsElementInViewport } from '@yarmill/utils';
import { RefObject, useContext, useEffect } from 'react';
import { EvidenceStoreContext } from '../context/evidence-store-context';
import { useEvidenceDataStore } from './use-evidence-data-store';

function getRootMargin() {
  if (typeof window === 'undefined') {
    return undefined;
  }

  return `0px 0px -${(window.innerHeight * 2) / 3}px 0px`;
}

export function useSetVisibleCategory(
  objectKey: string,
  categoryRef: RefObject<HTMLDivElement>
): void {
  const evidenceStore = useContext(EvidenceStoreContext);
  const dataStore = useEvidenceDataStore();
  const isInViewport = useIsElementInViewport(categoryRef, {
    rootMargin: getRootMargin(),
  });

  useEffect(() => {
    if (isInViewport && dataStore?.hasLoadedData) {
      setTimeout(() => {
        evidenceStore?.setScrolledCategory(objectKey);
      }, 100);
    }
  }, [dataStore?.hasLoadedData, evidenceStore, isInViewport, objectKey]);
}
