import { observer } from 'mobx-react-lite';

import { useCurrentUserStore } from '@yarmill/utils';
import { Avatar } from '../avatar';
import {
  PrimarySidebarAvatarWrapper,
  PrimarySidebarHeaderContainer,
  PrimarySidebarHeaderLayout,
  PrimarySidebarHeaderSpacer,
} from '../primary-sidebar';
import { Logo } from './logo';

export const Header = observer(function Header(): JSX.Element {
  const currentUser = useCurrentUserStore();
  const avatar = currentUser.data?.AvatarFileName;

  return (
    <PrimarySidebarHeaderLayout>
      <PrimarySidebarHeaderContainer>
        <Logo />
        <PrimarySidebarHeaderSpacer />
        <PrimarySidebarAvatarWrapper>
          <Avatar avatar={avatar} />
        </PrimarySidebarAvatarWrapper>
      </PrimarySidebarHeaderContainer>
    </PrimarySidebarHeaderLayout>
  );
});
