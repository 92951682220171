import { AggregationFunction } from '@yarmill/types';
import { action, computed, makeObservable, observable } from 'mobx';
import { computeAggregationFunction } from '../utils';
import { AbstractActivityStore } from './abstract-activity-store';

// Store for summary values in last column of activities table
export class ActivityItemSummaryStore {
  private readonly _activities: AbstractActivityStore[];
  private readonly aggregationFunction: AggregationFunction | null;

  @observable
  private apiValue: number | null = null;

  constructor(
    aggregationFunction: AggregationFunction | null,
    activities: (AbstractActivityStore | ActivityItemSummaryStore)[]
  ) {
    makeObservable(this);
    this.aggregationFunction = aggregationFunction;
    this._activities = activities.filter(
      activity => activity instanceof AbstractActivityStore
    ) as AbstractActivityStore[];
  }

  @computed
  public get currentValue(): number {
    if (this.apiValue) {
      // If value comes from api, we do not want to sum it manually
      return this.apiValue;
    }

    const values = this._activities.map(activity => activity.summaryValue);
    return computeAggregationFunction(this.aggregationFunction, values);
  }

  @action
  public setApiValue(value: number): void {
    this.apiValue = value;
  }

  @action
  public clear(): void {
    this.apiValue = null;
  }

  @computed
  public get hasValue(): boolean {
    return Boolean(this.apiValue);
  }

  public disposeReactions(): void {}
}
