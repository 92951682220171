import {
  Button,
  ButtonAppearance,
  FormattedHTMLMessage,
  LayerPortal,
  Status,
  Text,
  TextSize,
  styled,
} from '@yarmill/components';
import { EvidenceObject } from '@yarmill/types';
import { useLayer } from '@yarmill/utils';
import { ReactElement, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyledEvidenceRow } from './evidence-object';
import { useEvidenceObjectStore } from './hooks/use-evidence-object-store';
import { useEvidenceStore } from './hooks/use-evidence-store';
import { EvidenceTable as EvidenceTableComponent } from './table/evidence-table';
import { EvidenceTableFormLayer } from './table/evidence-table-form-layer';
import { useEvidenceTableFormHandler } from './table/hooks';
import { getHeaderSize } from './utils';

export interface EvidenceTableProps {
  definition: EvidenceObject;
}

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const TableDescription = styled(Text)`
  color: #9a9a9a;
  margin-top: 4px;
`;

const EvidenceTableWrapper = styled.div`
  & + &,
  ${StyledEvidenceRow} + & {
    margin-top: 29px;

    ${TableHeader} {
      margin-bottom: 8px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export function EvidenceTable(props: EvidenceTableProps): ReactElement {
  const { definition } = props;
  const evidenceStore = useEvidenceStore();
  const objectStore = useEvidenceObjectStore(definition.ObjectKey);
  const { addRow, closeForm, editedItem, editRow, showForm } =
    useEvidenceTableFormHandler(objectStore || null);
  const formLayer = useLayer('full-screen', {
    onClose: closeForm,
  });
  const intl = useIntl();
  const showDescription = Boolean(
    intl.messages[`evidence.${objectStore?.definition.ObjectKey}.description`]
  );

  useEffect(() => {
    if (showForm && !formLayer.isOpened) {
      formLayer.open();
    }

    if (!showForm && formLayer.isOpened) {
      formLayer.layer.close();
    }
  }, [formLayer, showForm]);

  return (
    <EvidenceTableWrapper>
      <TableHeader>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text
            size={getHeaderSize(
              objectStore?.definition.Features?.HeaderSize || 'item'
            )}
            medium
          >
            <FormattedMessage
              id={`evidence.${objectStore?.definition.ObjectKey}.headline`}
            />
          </Text>
          {showDescription && (
            <TableDescription size={TextSize.s12}>
              <FormattedHTMLMessage
                id={`evidence.${objectStore?.definition.ObjectKey}.description`}
              />
            </TableDescription>
          )}
        </div>
        <ButtonContainer>
          <Status />
          <Button
            disabled={
              !objectStore || !evidenceStore.isCurrentUserAllowedToWrite
            }
            onClick={addRow}
            appearance={ButtonAppearance.Primary}
            noShadow
          >
            <FormattedMessage
              id={`evidence.${objectStore?.definition.ObjectKey}.createNew`}
            />
          </Button>
        </ButtonContainer>
      </TableHeader>
      <EvidenceTableComponent
        table={objectStore || null}
        showControls
        editItem={editRow}
        maxHeight="400px"
      />

      <LayerPortal
        layerHandle={formLayer}
        getContent={() =>
          editedItem ? (
            <EvidenceTableFormLayer item={editedItem} close={closeForm} />
          ) : (
            <></>
          )
        }
      />
    </EvidenceTableWrapper>
  );
}
