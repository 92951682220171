import { ContentBox } from '../content-box';
import { styled } from '../theme-provider';
import { PropsWithChildren } from 'react';
import { Text, TextSize } from '../text';

const ErrorContentBox = styled(ContentBox)`
  box-shadow: none;
  background-color: #d0021b;
  color: #fff;
  padding: 12px 20px;
  margin-bottom: 25px;

  p {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  a {
    color: #fff;
    font-weight: 500;
    :hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 10px;
    margin: 0;

    li {
      &:before {
        content: '- ';
      }
    }
  }
`;

export function ErrorBox({ children }: PropsWithChildren): JSX.Element {
  return (
    <ErrorContentBox>
      <Text size={TextSize.s14} inheritColor>
        {children}
      </Text>
    </ErrorContentBox>
  );
}
