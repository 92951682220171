import { ContentBoxHead } from '../content-box';
import styled from 'styled-components';

export * from './checkbox-list';
export const CopySourceDaysContentBoxHead = styled(ContentBoxHead)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 8px;

  @media (min-width: 560px) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;
