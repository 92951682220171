import { ReactElement, Ref, forwardRef } from 'react';
import { ButtonAppearance } from './button';
import { ExternalIcon } from './external-icon';
import { Icon, IconSize } from './icon';
import { PopoverToggleButton } from './popover';

export interface EllipsisButtonProps {
  'data-cy'?: string;
}

export const EllipsisButton = forwardRef(
  (props: EllipsisButtonProps, ref: Ref<HTMLButtonElement>): ReactElement => (
    <PopoverToggleButton
      ref={ref}
      type="button"
      data-cy={props['data-cy']}
      appearance={ButtonAppearance.Secondary}
      noShadow
    >
      <Icon size={IconSize.s20}>
        <ExternalIcon name="Dots" />
      </Icon>
    </PopoverToggleButton>
  )
);
