import { observer } from 'mobx-react-lite';
import {
  AttachmentFileInput,
  Button,
  ButtonAppearance,
  StyledLabel,
  StyledOptionalMessage,
  Text,
  TextSize,
} from '@yarmill/components';
import { getFormFieldAttributes } from '../utils';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AttachmentLabelWrapper,
  AttachmentsFormFieldWrapper,
} from '../components/attachments-form-field';
import { useEvidenceFileUpload } from '../hooks/use-evidence-file-upload';
import { EvidenceAttachmentStore } from '../mobx/evidence-attachment-store';
import { EvidenceFileAttribute } from '../attributes/evidence-file-attribute';

interface AttachmentsProps {
  readonly attributeStore: EvidenceAttachmentStore;
}
export const Attachments = observer(function Attachments(
  props: AttachmentsProps
) {
  const { attributeStore } = props;
  const intl = useIntl();
  const [inputRef, onChange] = useEvidenceFileUpload(attributeStore, false);
  const commonAttributes = getFormFieldAttributes(attributeStore, intl);
  const { variant, label, optional, optionalMessage } = commonAttributes;
  const inputId = `attr-${attributeStore.id}`;

  return (
    <>
      <AttachmentLabelWrapper>
        <StyledLabel variant={variant} as="div">
          {label}
          {optional && (
            <StyledOptionalMessage>{optionalMessage}</StyledOptionalMessage>
          )}
        </StyledLabel>
        <Button
          as="label"
          htmlFor={inputId}
          appearance={ButtonAppearance.Primary}
          inverted
          noShadow
        >
          <Text size={TextSize.s14} inheritColor>
            <FormattedMessage id="trainingDay.attributes.attachment.addFile" />
          </Text>
        </Button>
      </AttachmentLabelWrapper>
      <AttachmentFileInput
        ref={inputRef}
        id={inputId}
        type="file"
        name="fileData"
        onChange={onChange}
        multiple
      />
      <AttachmentsFormFieldWrapper>
        <EvidenceFileAttribute
          attribute={attributeStore}
          hideAdd
          variant="table-form"
          autoSave={false}
        />
      </AttachmentsFormFieldWrapper>
    </>
  );
});
