import { UserId } from '@yarmill/types';
import { createAuthToken, instance } from '@yarmill/utils';
import { getAuthToken } from '../../auth/utils';

export interface AskYollandaRequestParams {
  readonly userId: UserId;
  readonly query: string;
  readonly context: 'plan' | 'reality';
}

export function askYollanda(url: string, params: AskYollandaRequestParams) {
  const searchParams = new URLSearchParams(Object.entries(params));
  searchParams.set('instance', instance);
  return fetch(`${url}/api/yollanda-stream?${searchParams.toString()}`, {
    headers: {
      Authorization: createAuthToken(getAuthToken() as string),
    },
  });
}
