import { LayerPortal, Text, TextSize } from '@yarmill/components';
import { useLayer } from '@yarmill/utils';
import { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const StyledOfflineContainer = styled.div`
  padding: 40px;
`;

export function Offline(): ReactElement {
  const [isOffline, setIsOffline] = useState(!navigator?.onLine);
  const offlineLayer = useLayer('alert', {
    showShim: true,
    closeOnShimClick: false,
  });

  useEffect(() => {
    function onOnline() {
      setIsOffline(false);
    }

    function onOffline() {
      setIsOffline(true);
    }

    window.addEventListener('offline', onOffline);
    window.addEventListener('online', onOnline);

    return () => {
      window.removeEventListener('offline', onOffline);
      window.removeEventListener('online', onOnline);
    };
  }, []);

  useEffect(() => {
    if (isOffline && !offlineLayer.isOpened) {
      offlineLayer.open();
    } else if (!isOffline && offlineLayer.isOpened) {
      offlineLayer.layer.close();
    }
  }, [isOffline, offlineLayer]);

  return (
    <LayerPortal
      layerHandle={offlineLayer}
      getContent={() => (
        <StyledOfflineContainer>
          <Text size={TextSize.s14}>
            <FormattedMessage id="app.offline" />
          </Text>
        </StyledOfflineContainer>
      )}
    />
  );
}
