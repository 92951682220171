import { Logger } from '@yarmill/types';
import {
  ReportingTableColumnConfig,
  ReportingTableRowData,
} from '../components/reporting-table/types';
import { formatValueByBusinessFormat } from '../utils';

export function formatRowDataByBusinessFormat(
  row: ReportingTableRowData,
  def: ReportingTableColumnConfig[],
  logger: Logger
) {
  try {
    return Object.fromEntries(
      Object.entries(row).map(([key, value]) => {
        const column = def.find(c => c.columnName === key);
        if (!column || column.businessFormat === 'report') {
          return [key, value];
        } else {
          return [
            key,
            formatValueByBusinessFormat(
              value as string | number,
              column.businessFormat ?? undefined,
              column.format
            ),
          ];
        }
      })
    );
  } catch (e: unknown) {
    logger.error('Unable to format table row data', e);
    return row;
  }
}
