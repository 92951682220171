import {
  Button,
  ButtonAppearance,
  CloseButton,
  ExternalIcon,
  FieldSet,
  FormControlVariant,
  FullScreenLayerCloseButtonWrapper,
  Icon,
  IconSize,
  Text,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { AsyncStatus } from '@yarmill/types';
import { useRootStore } from '@yarmill/utils';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { GroupSelect } from '../groups/group-select';
import { inviteNewUsers } from './api/invite-new-users';
import { AutoAddEmptyRow } from './auto-add-empty-row';
import {
  StyledAddNewInvitationWrapper,
  StyledFormWrapper,
  StyledGroupSelectWrapper,
  StyledHeadline,
  StyledInviteButtonWrapper,
  StyledInviteUserRowLabels,
  StyledSelectGroupContainer,
} from './components/invite-users';
import { InviteUserRow } from './invite-user-row';
import {
  FormUserInvitation,
  InviteUsersFormState,
  UserInvitation,
} from './types';
import {
  getButtonAppearance,
  getSubmitButtonIcon,
  toastInviteUsers,
} from './utils';
import { createEmptyInvitation } from './utils/create-empty-invitation';
import { hasInvalidInvitation } from './utils/has-invalid-invitation';
import { hasValidInvitation } from './utils/has-valid-invitation';
import { isEmptyInvitation } from './utils/is-empty-invitation';

interface InviteUsersProps {
  readonly close: () => void;
}

export const InviteUsers = observer(function InviteUsers({
  close,
}: InviteUsersProps) {
  const rootStore = useRootStore();
  const intl = useIntl();
  const [formState, setFormState] = useState<AsyncStatus>(AsyncStatus.idle);

  const formik = useFormik<InviteUsersFormState>({
    initialValues: {
      invitations: [createEmptyInvitation()],
      groups: [],
    },
    onSubmit: async values => {
      setFormState(AsyncStatus.pending);
      const apiInvitations: UserInvitation[] = values.invitations
        .filter(inv => !isEmptyInvitation(inv))
        .map(inv => ({
          Email: inv.Email,
          FirstName: inv.FirstName,
          LastName: inv.LastName,
          Role: inv.Role,
        }));

      const request = rootStore.requestsStore.createRequest(() =>
        inviteNewUsers({
          UserRegistrationInfo: apiInvitations,
          groupIds: values.groups,
        })
      );
      const response = await request.getResponse();

      if (response) {
        void rootStore.currentUserStore.loadPermissions();
        rootStore.usersStore.setUsers(response);
        values.groups.forEach(groupId => {
          const group = rootStore.groupsStore.getGroupById(groupId);
          if (group) {
            response.forEach(user => {
              const userStore = rootStore.usersStore.getUserById(user.UserId);
              if (userStore) {
                group.addInvitedUser(userStore);
              }
              userStore?.groups.push(group);
            });
          }
        });

        toastInviteUsers(apiInvitations);
        setFormState(AsyncStatus.resolved);
        close();
      } else {
        setFormState(AsyncStatus.rejected);

        setTimeout(() => setFormState(AsyncStatus.idle), 5000);
      }
    },
    validateOnBlur: true,
  });
  const { values } = formik;

  const isSubmitDisabled =
    values.invitations.length === 0 ||
    hasInvalidInvitation(values.invitations) ||
    !hasValidInvitation(values.invitations) ||
    (!formik.isValid &&
      Boolean(
        (
          formik.errors.invitations as (
            | Partial<FormUserInvitation>
            | undefined
          )[]
        )?.find(inv => !inv?.Email?.includes('required'))
      ));

  const onGroupChange = useCallback(
    (options: { label: string; value: number }[]) => {
      const groupIds = options.map(({ value }) => value);
      formik.setFieldValue('groups', groupIds);
    },
    [formik]
  );

  return (
    <FormikProvider value={formik}>
      <StyledFormWrapper>
        <StyledHeadline size={TextSize.s24}>
          <span>
            <FormattedMessage
              id="settings.users.inviteUsersHeading"
              values={{
                instance: (
                  <strong>
                    {intl.formatMessage({ id: 'fe.instance.name' })}
                  </strong>
                ),
              }}
            />
          </span>
          <Icon size={IconSize.s24}>
            <ExternalIcon name="Send" />
          </Icon>
        </StyledHeadline>
        <form onSubmit={formik.handleSubmit}>
          <FieldSet disabled={formState === AsyncStatus.pending}>
            <StyledInviteUserRowLabels>
              <Text size={TextSize.s14} tag={TextTag.div}>
                <FormattedMessage id="settings.users.table.header.email" />
              </Text>
              <Text size={TextSize.s14} tag={TextTag.div}>
                <FormattedMessage id="settings.users.table.header.firstName" />
              </Text>
              <Text size={TextSize.s14} tag={TextTag.div}>
                <FormattedMessage id="settings.users.table.header.lastName" />
              </Text>
              <Text size={TextSize.s14} tag={TextTag.div}>
                <FormattedMessage id="settings.users.table.header.role" />
              </Text>
              <div />
            </StyledInviteUserRowLabels>

            <FieldArray name="invitations">
              {({ push, remove }) => (
                <>
                  <AutoAddEmptyRow
                    push={push}
                    invitations={values.invitations}
                  />
                  {values.invitations.map((invitation, idx) => (
                    <InviteUserRow
                      key={invitation.uid}
                      autoFocus={idx === 0}
                      index={idx}
                      handleRemove={remove}
                    />
                  ))}

                  <div>
                    <StyledAddNewInvitationWrapper>
                      <Button
                        type="button"
                        onClick={() => push(createEmptyInvitation())}
                        appearance={ButtonAppearance.Link}
                      >
                        <Icon>
                          <ExternalIcon name="CirclePlus" />
                        </Icon>
                        &nbsp;
                        <FormattedMessage id="settings.users.inviteNewRow" />
                      </Button>
                    </StyledAddNewInvitationWrapper>
                  </div>
                </>
              )}
            </FieldArray>

            <StyledSelectGroupContainer>
              <div>
                <StyledGroupSelectWrapper>
                  <Text size={TextSize.s14}>
                    <FormattedMessage id="settings.users.inviteUsersToGroups" />
                  </Text>
                  <GroupSelect onChange={onGroupChange} />
                </StyledGroupSelectWrapper>
                <StyledInviteButtonWrapper>
                  <Button
                    type="submit"
                    appearance={getButtonAppearance(formState)}
                    variant={FormControlVariant.big}
                    wide
                    disabled={isSubmitDisabled}
                    data-cy="invite-new-submit"
                  >
                    {getSubmitButtonIcon(formState)}
                    <FormattedMessage id="settings.users.inviteNewSubmit" />
                  </Button>
                </StyledInviteButtonWrapper>
              </div>
              <div />
            </StyledSelectGroupContainer>
          </FieldSet>
        </form>
        <FullScreenLayerCloseButtonWrapper>
          <CloseButton onClick={close} />
        </FullScreenLayerCloseButtonWrapper>
      </StyledFormWrapper>
    </FormikProvider>
  );
});
