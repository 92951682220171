import { Fragment } from 'react';
import { PATTERN_INPUT_EMPTY_VALUE } from './use-pattern-input';

export function replacePatternInputPattern(
  pattern: string,
  content: string
): (string | JSX.Element)[] {
  const patternChars = pattern.split('');
  const updated = patternChars.map((character, index) => {
    if (content[index] && content[index] !== PATTERN_INPUT_EMPTY_VALUE) {
      return <Fragment key={index}>&nbsp;</Fragment>;
    }
    return character;
  });

  return updated;
}
