import { InviteUsersFormState } from './types';
import { useIntl } from 'react-intl';
import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  FormControlVariant,
  Icon,
  IconSize,
  SelectBox,
  TextInput,
} from '@yarmill/components';
import {
  StyledDeleteButton,
  StyledInviteUserRow,
} from './components/invite-users';
import { useCallback, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { validateEmail } from './utils/validate-email';
import { validateTextField } from './utils/validate-text-field';
import { isEmptyInvitation } from './utils/is-empty-invitation';

interface InviteUserRowProps {
  readonly autoFocus: boolean;
  readonly index: number;
  readonly handleRemove: (index: number) => void;
}

interface FormikTextInputProps {
  readonly name: string;
  readonly placeholder: string;
  readonly index: number;
  readonly type?: 'text' | 'email';
  readonly autoFocus?: boolean;
}

function FormikTextInput({
  name,
  placeholder,
  type = 'text',
  autoFocus,
  index,
}: FormikTextInputProps): JSX.Element {
  const intl = useIntl();
  const fieldName = `invitations[${index}].${name}`;
  const formik = useFormikContext<InviteUsersFormState>();
  const [inputProps, meta] = useField({
    name: fieldName,
    validate: (value: string) => {
      const invitation = formik.values.invitations[index];
      if (isEmptyInvitation(invitation)) {
        return;
      }

      return type === 'email'
        ? validateEmail(
            value,
            formik.values.invitations[index],
            formik.values.invitations
          )
        : validateTextField(value);
    },
  });

  return (
    <div>
      <TextInput
        {...inputProps}
        noLabel
        autoFocus={autoFocus}
        variant={FormControlVariant.big}
        error={
          meta.error && meta.touched
            ? intl.formatMessage({ id: meta.error })
            : undefined
        }
        id={name}
        type={type}
        name={fieldName}
        placeholder={placeholder}
      />
    </div>
  );
}

export function InviteUserRow({
  autoFocus,
  index,
  handleRemove,
}: InviteUserRowProps) {
  const intl = useIntl();
  const formik = useFormikContext<InviteUsersFormState>();

  const onChangeRole = useCallback(
    ({ value }: { label: string; value: string }) => {
      formik.setFieldValue(`invitations[${index}].Role`, value);
    },
    [formik, index]
  );

  const onDeleteClick = useCallback(() => {
    handleRemove(index);
  }, [handleRemove, index]);

  const roles = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'settings.users.role.athlete' }),
        value: 'athlete',
      },
      {
        label: intl.formatMessage({ id: 'settings.users.role.coach' }),
        value: 'coach',
      },
    ],
    [intl]
  );

  return (
    <StyledInviteUserRow>
      <FormikTextInput
        name="Email"
        placeholder="homer.simpson@czech-ski.com"
        type="email"
        autoFocus={autoFocus}
        index={index}
      />
      <FormikTextInput name="FirstName" placeholder="Homer" index={index} />
      <FormikTextInput name="LastName" placeholder="Simpson" index={index} />
      <div>
        <SelectBox
          id="role"
          variant={FormControlVariant.big}
          label={intl.formatMessage({ id: `settings.users.table.header.role` })}
          defaultValue={{
            label: intl.formatMessage({ id: 'settings.users.role.athlete' }),
            value: 'athlete',
          }}
          options={roles}
          isSearchable={false}
          onChange={onChangeRole}
          noSeparator
          longList
          noExtraLabel
          disablePortal
          maxMenuHeight={350}
          data-cy="role"
        />
      </div>
      <StyledDeleteButton>
        <Button
          onClick={onDeleteClick}
          appearance={ButtonAppearance.Link}
          type="button"
        >
          <Icon size={IconSize.s22}>
            <ExternalIcon name="CircleX" />
          </Icon>
        </Button>
      </StyledDeleteButton>
    </StyledInviteUserRow>
  );
}
