import { createElement } from 'react';

export function Underline() {
  return createElement(
    'svg',
    {
      viewBox: '0 0 18 18',
      xmlns: 'http://www.w3.org/2000/svg',
      fill: 'currentColor',
      role: 'img',
    },
    createElement('path', {
      fill: 'none',
      stroke: 'currentColor',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.6',
      d: 'M5 3v6a4.012 4.012 0 0 0 4 4h0a4.012 4.012 0 0 0 4-4V3',
    }),
    createElement('rect', {
      fill: 'currentColor',
      height: '1',
      rx: '.5',
      ry: '.5',
      width: '12',
      x: '3',
      y: '15',
    })
  );
}
