import { ReportItemStore } from '../types';
import { useIntl } from 'react-intl';

export function useShowReportTitle(report: ReportItemStore): boolean {
  const intl = useIntl();
  const hasTitle = Boolean(report.title && intl.messages[report.title]);
  const hasDescription = Boolean(
    report.description && intl.messages[report.description]
  );

  return hasTitle || hasDescription;
}
