import { useXYChartContext } from './xy-chart-context';

interface BackgroundImageProps {
  readonly image?: string;
}
export function BackgroundImage({ image }: BackgroundImageProps) {
  const { chartRect } = useXYChartContext();

  if (!image) {
    return null;
  }

  return (
    <image
      href={image}
      x={chartRect.left}
      y={chartRect.top}
      width={chartRect.right - chartRect.left}
      height={chartRect.bottom - chartRect.top}
    />
  );
}
