import {
  ButtonAppearance,
  SegmentedButton,
  SegmentedControl,
  styled,
} from '@yarmill/components';
import { useHistory, useIsMobile } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAttendanceStore } from './attendance-store-context';
import { AttendancePhase, PHASE_SEARCH_PARAM } from './types';

const StyledPhaseSelector = styled.div``;

export interface PhaseButtonProps {
  active: boolean;
  phase: AttendancePhase;
}

function PhaseButton(props: PhaseButtonProps): JSX.Element {
  const { active, phase } = props;
  const isMobile = useIsMobile();
  const history = useHistory();

  const onChange = useCallback(() => {
    const link = new URL(window.location.href);
    link.searchParams.set(PHASE_SEARCH_PARAM, String(phase.activityItemId));

    history.push(`${link.pathname}${link.search}`);
  }, [history, phase]);

  return (
    <SegmentedButton
      appearance={ButtonAppearance.Navigation}
      active={active}
      onClick={onChange}
    >
      <FormattedMessage id={phase.name}>
        {msg => <>{isMobile && msg ? msg[0] : msg}</>}
      </FormattedMessage>
    </SegmentedButton>
  );
}

function InternalPhaseSelector(): JSX.Element {
  const attendanceStore = useAttendanceStore();

  return (
    <StyledPhaseSelector>
      <SegmentedControl>
        {attendanceStore.phases
          .slice()
          .sort((a, b) => a.sortCode - b.sortCode)
          .map(phase => (
            <PhaseButton
              active={
                attendanceStore.currentPhase?.activityItemId ===
                phase.activityItemId
              }
              phase={phase}
              key={phase.activityItemId || 0}
            />
          ))}
      </SegmentedControl>
    </StyledPhaseSelector>
  );
}

export const PhaseSelector = observer(InternalPhaseSelector);
