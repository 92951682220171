import {
  Video,
  VideoSelectorService as iVideoSelectorService,
} from '@yarmill/types';
import { action, makeObservable, observable } from 'mobx';

export class VideoSelectorService implements iVideoSelectorService {
  @observable
  private _callback: ((video: Video) => void) | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  readonly openVideoSelector = (callback: (video: Video) => void): void => {
    this._callback = callback;
  };

  @action
  readonly closeVideoSelector = (): void => {
    this._callback = null;
  };

  get isOpen(): boolean {
    return Boolean(this._callback);
  }

  get callback(): ((video: Video) => void) | null {
    return this._callback;
  }
}
