import {
  HealthDataOverview,
  Text,
  TextSize,
  TextTag,
  styled,
} from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { HealthDataAttributeStore } from '../mobx/health-data-attribute-store';
import { BodyBattery } from './body-battery';
import { HeartRateVariability } from './heart-rate-variability';
import { ReadinessScore } from './readiness-score';
import { RestingHeartRate } from './resting-heart-rate';
import { Sleep } from './sleep';

export interface HealthDataAttributeProps {
  readonly attribute: HealthDataAttributeStore;
}

const StyledAttributeWrapper = styled.div`
  width: 100%;
`;

const StyledLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UpdatedAt = styled(Text)`
  color: #9b9b9b;
`;

function InternalHealthDataAttribute(
  props: HealthDataAttributeProps
): JSX.Element | null {
  const { attribute } = props;
  const data = attribute.healthDataItems;
  const isUpdatedToday = moment(attribute.trainingDayStore.currentDate).isSame(
    moment(),
    'day'
  );

  if (data.length === 0) {
    return null;
  }

  return (
    <StyledAttributeWrapper>
      <StyledLabel>
        <Text tag={TextTag.span} size={TextSize.s14}>
          <FormattedMessage id={attribute.name} />
        </Text>
        {attribute.updatedAt && isUpdatedToday && (
          <UpdatedAt tag={TextTag.span} size={TextSize.s10}>
            <FormattedMessage
              id="attributes.health-data.updated-at"
              values={{
                at: (
                  <FormattedTime value={moment(attribute.updatedAt).toDate()} />
                ),
              }}
            />
          </UpdatedAt>
        )}
      </StyledLabel>
      <HealthDataOverview>
        {data.map(dataRow => {
          switch (dataRow.Type) {
            case 'sleep':
              return <Sleep value={dataRow} key="sleep" />;
            case 'resting-heart-rate':
              return (
                <RestingHeartRate value={dataRow} key="resting-heart-rate" />
              );
            case 'body-battery':
              return <BodyBattery value={dataRow} key="body-battery" />;
            case 'hrv':
              return <HeartRateVariability value={dataRow} key="hrv" />;
            case 'readiness-score':
              return <ReadinessScore value={dataRow} key="readiness-score" />;
            default:
              return null;
          }
        })}
      </HealthDataOverview>
    </StyledAttributeWrapper>
  );
}

export const HealthDataAttribute = observer(InternalHealthDataAttribute);
