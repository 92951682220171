import { SeasonStore, SeasonViewType } from '@yarmill/types';
import moment from 'moment';
import { FormattedDate } from 'react-intl';
import { Text, TextSize, TextTag } from './text';

interface SeasonHeaderCellContentProps {
  seasonViewType: SeasonViewType;
  season?: SeasonStore;
  columnIndex: number;
}

export function SeasonHeaderCellContent(
  props: SeasonHeaderCellContentProps
): JSX.Element {
  const { seasonViewType, season, columnIndex } = props;
  const date = season?.seasonSections[columnIndex];
  const dateObj = moment(date).toDate();

  return seasonViewType === 'month' ? (
    <Text
      size={TextSize.s12}
      tag={TextTag.div}
      className="activity-column-label-text"
    >
      <FormattedDate value={dateObj} month="long" />
    </Text>
  ) : (
    <Text
      size={TextSize.s12}
      tag={TextTag.div}
      className="activity-column-label-text"
    >
      {columnIndex + 1}
      <br />
      <FormattedDate value={dateObj} day="numeric" month="numeric" />
      &rarr;
    </Text>
  );
}
