import {
  ExternalIcon,
  Icon,
  IconSize,
  NoAthlete,
  ReportingSection,
  ReportsContainer,
} from '@yarmill/components';
import { useConfig, useCurrentSeasonByDay } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLegacyAnalyticsStore } from '../../hooks';
import { GeneralDashboardStore } from '../../mobx/general-dashboard-store';
import { Kpi } from './kpi';
import { MonthTrend } from './month-trend';
import { SeasonTrend } from './season-trend';
import { WeekTrend } from './week-trend';

export const GeneralAnalyticsDashboard = observer(
  function GeneralAnalyticsDashboard(): JSX.Element {
    const dashboard = useLegacyAnalyticsStore().getDashboard(
      'general.defaultDashboard'
    ) as GeneralDashboardStore | undefined;
    const seasonView = useConfig('seasonView');
    const intl = useIntl();
    const today = useMemo(() => moment().format(), []);
    const season = useCurrentSeasonByDay(today);

    if (!dashboard?.filters.length) {
      return <div />;
    }

    if (
      (dashboard?.filters.length && !dashboard.filterValues.has('UserId')) ||
      dashboard.filterValues.get('UserId') === '-1'
    ) {
      return <NoAthlete context="analytics" />;
    }

    return (
      <ReportsContainer>
        <ReportingSection
          layoutConfig={
            'days days days days days hours hours hours hours hours sickness sickness'
          }
          title={
            <>
              <FormattedMessage id="analytics.charts.kpi" />
              &nbsp;
              <Icon>
                <ExternalIcon name="Analyze" />
              </Icon>
            </>
          }
        >
          <Kpi data={dashboard.kpi} intl={intl} />
        </ReportingSection>
        <ReportingSection layoutConfig={'week month'}>
          <WeekTrend
            data={dashboard.weekTrend}
            kpi={dashboard.kpi}
            intl={intl}
          />

          <MonthTrend
            data={dashboard.cycleTrend}
            kpi={dashboard.kpi}
            intl={intl}
            useMonths={seasonView === 'month'}
          />
        </ReportingSection>
        <ReportingSection
          layoutConfig={'season'}
          title={
            <>
              <FormattedMessage id="analytics.charts.thisSeason" />
              &nbsp;
              <Icon size={IconSize.s16}>
                <ExternalIcon name="ChartBar" />
              </Icon>
            </>
          }
        >
          {season && (
            <SeasonTrend
              data={dashboard.seasonTrend}
              kpi={dashboard.kpi}
              season={season}
              intl={intl}
            />
          )}
        </ReportingSection>
      </ReportsContainer>
    );
  }
);
