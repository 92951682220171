import { AsyncStatus } from '@yarmill/types';
import {
  useCurrentUserStore,
  useIntlStore,
  useRootStore,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { AlertAnnouncement } from './alert-announcement';
import { ImageAnnouncement } from './image-announcement';
import { NewTermsAgreement } from './new-terms-agreement';
import { AnnouncementType } from './types';
import { showNotificationAnnouncement } from './utils';

export const Announcement = observer(
  function Announcement(): JSX.Element | null {
    const announcements = useRootStore().announcementsStore.announcements;
    const currentUser = useCurrentUserStore();
    const rootStore = useRootStore();
    const intlStore = useIntlStore();

    useEffect(() => {
      const notifications = announcements.filter(
        def =>
          def.type !== AnnouncementType.alert &&
          def.type !== AnnouncementType.image &&
          def.isVisible
      );

      notifications.forEach(notification =>
        showNotificationAnnouncement(notification)
      );
    }, [announcements]);
    const alerts = announcements.filter(
      def => def.type === AnnouncementType.alert && def.isVisible
    );
    const images = announcements.filter(
      def => def.type === AnnouncementType.image && def.isVisible
    );

    if (intlStore.version !== 'api') {
      return null;
    }

    return (
      <>
        {rootStore.status === AsyncStatus.resolved &&
          currentUser.status === AsyncStatus.resolved && (
            <>
              {alerts.map(alert => (
                <AlertAnnouncement announcement={alert} key={alert.code} />
              ))}
              {images.map(image => (
                <ImageAnnouncement announcement={image} key={image.code} />
              ))}
            </>
          )}

        {currentUser.status === AsyncStatus.resolved && <NewTermsAgreement />}
      </>
    );
  }
);
