import * as React from 'react';
import { TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

const StyledLayerWrapper = styled.div<LayerWrapperExtraProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  z-index: ${props => (props.showNavbar ? '1' : '3')};
  transform: translate3d(0, 0, 0);

  // The wrapper must always be mounted for animations to work, So we need to be able to click "through" it
  pointer-events: none;

  // All clicks go through the layer wrapper, so enable it for the children (= actual layers) again
  & > * {
    pointer-events: auto;
  }
`;
StyledLayerWrapper.displayName = 'StyledLayerWrapper';

interface LayerWrapperExtraProps {
  showNavbar?: boolean;
}

export type LayerWrapperProps = React.PropsWithChildren<{
  withoutWrapper?: boolean;
}>;

export function LayerWrapper(
  props: LayerWrapperProps & LayerWrapperExtraProps
): JSX.Element {
  const { withoutWrapper, showNavbar, ...otherProps } = props;

  return (
    <StyledLayerWrapper showNavbar={showNavbar}>
      <TransitionGroup
        {...otherProps}
        appear
        component={withoutWrapper ? null : undefined}
      />
    </StyledLayerWrapper>
  );
}
