import { ComponentType, createContext, forwardRef, useContext } from 'react';
import type * as Icons from '@yarmill/icons';

export type ExternalIconName = keyof typeof Icons;
interface ExternalIconProps {
  readonly name: ExternalIconName;
  readonly svgSelector?: string;
  readonly className?: string;
}

function getIconUrl(name: string) {
  if (typeof window !== 'undefined') {
    return `${window.location.origin}/static/icons/${name}.svg`;
  } else {
    return `https://test.yarmill.com/static/icons/${name}.svg`;
  }
}

const outlinedProps = {
  strokeWidth: '1.5',
  stroke: 'currentColor',
  fill: 'none',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
};

const filledProps = {
  fill: 'currentColor',
};

export const IconsContext = createContext<typeof Icons | undefined>(undefined);

export const ExternalIcon = forwardRef<SVGSVGElement, ExternalIconProps>(
  function ExternalIcon(props, ref) {
    const iconsContext = useContext(IconsContext);

    if (iconsContext && iconsContext[props.name]) {
      const SvgIcon = iconsContext[props.name] as ComponentType<{
        className?: string;
      }>;
      return <SvgIcon className={props.className} />;
    }

    const svgSelector =
      props.svgSelector === undefined ? '#main' : props.svgSelector;
    const url = getIconUrl(props.name);

    const iconProps = props.name.toLowerCase().includes('filled')
      ? filledProps
      : outlinedProps;

    return (
      <svg
        className={props.className}
        ref={ref}
        viewBox={`0 0 24 24`}
        data-cy="icon"
        data-name={props.name}
        {...iconProps}
      >
        <use href={`${url}${svgSelector}`} />
      </svg>
    );
  }
);
