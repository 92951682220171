import { Export } from '@yarmill/components';
import { ExportItem } from '@yarmill/types';
import { useGroupsStore, useLocale, useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDiaryStore } from './diary-store-context';

export const ExportSeasonButton = observer(
  function ExportSeason(): JSX.Element {
    const exportService = useRootStore().exportService;
    const diaryStore = useDiaryStore();
    const groups = useGroupsStore();
    const language = useLocale();
    const intl = useIntl();

    const exportToXlsx = useCallback(async () => {
      const season = diaryStore.currentSeason;
      const groupId = diaryStore.groupId;
      const athleteId = diaryStore.athleteId;
      const diaryType = diaryStore.diaryType;
      const selectedGroup = groups.getGroupById(groupId);
      const users = selectedGroup?.athletes || [];

      const seasonName = season?.name;
      const selectedUser = athleteId
        ? users.find(user => user.id === athleteId)
        : null;

      const exportFor = selectedUser
        ? selectedUser.displayName
        : selectedGroup?.name;
      const fileName = `${intl.formatMessage({
        id: `header.navigation.${diaryType}`,
      })} - ${exportFor} - ${seasonName}`;

      const usersParam = athleteId ? [athleteId] : users.map(user => user.id);

      await exportService.exportSeasonSummaryToXlsx(
        {
          seasonId: season?.id || null,
          users: usersParam,
          groups: selectedUser ? [] : [groupId as number],
          exportType: diaryType === 'plan' ? 'plan' : 'reality',
          language,
        },
        fileName
      );
    }, [diaryStore, groups, intl, exportService, language]);

    const exportItems: ExportItem[] = useMemo(
      () => [{ format: 'xlsx', doExport: exportToXlsx }],
      [exportToXlsx]
    );

    return <Export exportItems={exportItems} />;
  }
);
