import { ThemeProvider } from '@yarmill/components';
import {
  AppContainer,
  PageContentWrapper,
  PrimarySidebar,
} from '@yarmill/components-2';
import {
  useCurrentUserStore,
  useRootStore,
  useSidebarStore,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { memo } from 'react';
import { Redirect } from 'react-router-dom';
import { Planner } from './planner';
import {
  PlannerStoreContextProvider,
  usePlannerStore,
} from './planner-store-context';

function InternalPlannerRouteRoot(): JSX.Element | null {
  const rootStore = useRootStore();
  const sidebarStore = useSidebarStore();
  const plannerStore = usePlannerStore();
  const currentUser = useCurrentUserStore();

  if (
    rootStore.isReady &&
    !currentUser.isAthlete &&
    !plannerStore.group &&
    (!plannerStore.athleteId || plannerStore.athleteId === currentUser.id)
  ) {
    return <Redirect to="/noGroup" />;
  }

  return rootStore.isReady ? (
    <AppContainer>
      <PrimarySidebar />
      <PageContentWrapper isSidebarVisible={sidebarStore.isVisible}>
        <Planner />
      </PageContentWrapper>
    </AppContainer>
  ) : null;
}

const ObserverPlannerRouteRoot = observer(InternalPlannerRouteRoot);

export const PlannerModule = memo(() => (
  <PlannerStoreContextProvider>
    <ThemeProvider theme="main">
      <ObserverPlannerRouteRoot />
    </ThemeProvider>
  </PlannerStoreContextProvider>
));
