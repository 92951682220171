import { ErrorBoundary } from '@yarmill/components';
import { LoggerContext, RootStoreContext } from '@yarmill/contextlib';
import {
  Logger,
  debugValue,
  devMode,
  disabledInstances,
  initGlobalYtdNamespace,
  instance,
  setGlobalLogger,
} from '@yarmill/utils';
import { configure } from 'mobx';
import { ErrorInfo } from 'react';
import { createRoot } from 'react-dom/client';
import { showNewVersionAnnouncement } from './announcement/new-version';
import { App } from './app/app';
import { RootStore } from './app/mobx/root-store';
import { DisabledInstance } from './disabled-instance';
import { FatalError } from './fatal-error/fatal-error';
import { IntlProvider } from './intl/intl-provider';
import { registerVersionWorker } from './registerVersionWorker';

initGlobalYtdNamespace();
configure({
  enforceActions: 'never',
});

const target = document.getElementById('yarmill-diary-app');

// Unsupported browser
if (!target) {
  throw new Error('Unsupported browser');
}

const logger = new Logger();
setGlobalLogger(logger);
const root = createRoot(target);
let rootStore: RootStore;

const FatalErrorComponent = () => (
  <LoggerContext.Provider value={logger}>
    <RootStoreContext.Provider value={rootStore}>
      <IntlProvider>
        <FatalError />
      </IntlProvider>
    </RootStoreContext.Provider>
  </LoggerContext.Provider>
);
const renderFatalError = () => root.render(<FatalErrorComponent />);

function logError(e: Error, errorInfo?: ErrorInfo): void {
  logger.error(e.message, e.name);
}

const render = (AppComponent: typeof App) =>
  root.render(
    <LoggerContext.Provider value={logger}>
      <ErrorBoundary fallback={<FatalErrorComponent />} onCatch={logError}>
        <RootStoreContext.Provider value={rootStore}>
          <IntlProvider>
            <AppComponent history={rootStore.historyService.history} />
          </IntlProvider>
        </RootStoreContext.Provider>
      </ErrorBoundary>
    </LoggerContext.Provider>
  );

try {
  rootStore = new RootStore(logger);
  debugValue(rootStore, 'rootStore');

  registerVersionWorker(
    String(process.env.APP_VERSION),
    showNewVersionAnnouncement
  );

  const renderDisabledInstance = () => {
    root.render(
      <LoggerContext.Provider value={logger}>
        <RootStoreContext.Provider value={rootStore}>
          <DisabledInstance />
        </RootStoreContext.Provider>
      </LoggerContext.Provider>
    );
  };

  if (disabledInstances.includes(instance) && !devMode) {
    renderDisabledInstance();
  } else {
    render(App);
  }
} catch (e: any) {
  logError(e);
  renderFatalError();
}

if (module.hot) {
  module.hot.accept('./app/app', () => {
    const NextApp = require('./app/app').App;
    render(NextApp);
  });
}
