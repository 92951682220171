import { RootStoreContext } from '@yarmill/contextlib';
import { RootStore } from '@yarmill/types';
import { useContext } from 'react';

export function useRootStore(): RootStore {
  const rootStore = useContext(RootStoreContext);
  if (!rootStore) {
    throw new Error('Root Store not available');
  }

  return rootStore;
}
