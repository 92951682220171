import {
  AsyncButton,
  ConflictLayerContent,
  FormControlVariant,
  LayerPortal,
  styled,
  toast,
} from '@yarmill/components';
import { CopyMode, Layer } from '@yarmill/types';
import {
  AsyncState,
  CopyConflictErrorResponse,
  ServerErrorResponse,
  useAsyncState,
  useLayer,
  useRootStore,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { FormEvent, Fragment, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDiaryStore } from '../diary/diary-store-context';
import {
  Athletes,
  AthletesInGroup,
  Groups,
  SourceDay,
  SourceSeason,
  SourceSeasonSections,
  SourceSeasonSelector,
  SourceWeek,
  TargetSeason,
  TargetWeek,
} from './copy-elements';
import { Header } from './header';
import { useEscHandler } from './hooks';
import { useCopyService } from './hooks/use-copy-service';
import { ConflictResolution, CopyElement, CopyFormValues } from './types';
import {
  appendSourceId,
  appendTargetId,
  getElementsForCopyMode,
  isValidCopyModeParams,
  mapCopyModeElementsToApiParams,
  mapCopyTypeToApiParams,
  toastCopySuccess,
} from './utils';

const ElementsWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  row-gap: 16px;
  width: 100%;
  margin: 0 auto;
  justify-items: stretch;

  @media (min-width: 992px) {
    width: 655px;
  }
`;

const CopyForm = styled.form`
  max-width: 860px;
  display: grid;
  grid-template-columns: 100%;
  row-gap: 12px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const SubmitButtonWrapper = styled.div`
  margin: 15px auto;
`;

export interface CopyLayerContentProps {
  layer: Layer;
}

function getElement(
  elementType: CopyElement,
  onChange: (name: string, value: number[]) => void,
  copyMode: CopyMode
): JSX.Element {
  const commonProps = { key: elementType, elementType, onChange };

  switch (elementType) {
    case 'athletes':
      return <Athletes {...commonProps} />;
    case 'athletesInGroup':
      return <AthletesInGroup {...commonProps} />;
    case 'groups':
      return <Groups {...commonProps} copyMode={copyMode} />;
    case 'sourceDay':
      return <SourceDay {...commonProps} />;
    case 'targetWeek':
      return <TargetWeek {...commonProps} />;
    case 'targetSeason':
      return <TargetSeason {...commonProps} />;
    case 'seasonId':
    case 'sourceSeason':
    case 'currentSeason':
      return <SourceSeason {...commonProps} />;
    case 'sourceCycles':
    case 'sourceMonths':
      return <SourceSeasonSections {...commonProps} />;
    case 'sourceWeek':
      return <SourceWeek {...commonProps} />;
    case 'sourceSeasonSelector':
      return <SourceSeasonSelector {...commonProps} />;
    case 'includeWeekGoal':
      return <Fragment />;
  }
}

export const CopyLayerContent = observer(function CopyLayerContent(
  props: CopyLayerContentProps
): JSX.Element {
  const { layer } = props;
  const rootStore = useRootStore();
  const diaryStore = useDiaryStore();
  const copyService = useCopyService();
  const [state, setAsyncState, conflictResponse] =
    useAsyncState<CopyConflictErrorResponse>();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const conflictLayer = useLayer('alert', {
    showShim: true,
    returnFocus: false,
    onClose: () => {
      setAsyncState(AsyncState.idle);
    },
  });

  useEscHandler(layer.close);
  const copyModes = diaryStore.copyModes;
  const [copyMode, setCopyMode] = useState(copyModes[0]);
  const [formValues, setFormValues] = useState<CopyFormValues>({});
  const elements = getElementsForCopyMode(copyMode, diaryStore);

  const handleChange = useCallback((name: string, value: unknown) => {
    setFormValues(values => ({ ...values, [name]: value }));
  }, []);

  const submitCopy = async (resolution: ConflictResolution): Promise<void> => {
    setAsyncState(AsyncState.pending);
    const mappedValues = mapCopyModeElementsToApiParams(
      formValues,
      elements,
      diaryStore
    );
    const completeValues = appendTargetId(
      appendSourceId(mappedValues, diaryStore),
      diaryStore,
      copyMode
    );
    const { diaryType, type } = mapCopyTypeToApiParams(diaryStore);
    const sourceType = diaryStore.athleteId ? 'athlete' : 'group';

    const response = await copyService.copy(
      copyMode,
      sourceType,
      diaryType,
      type,
      completeValues,
      resolution
    );

    if (
      response instanceof CopyConflictErrorResponse &&
      !conflictLayer.isOpened
    ) {
      setAsyncState(AsyncState.rejected, response);
      conflictLayer.open();
      return;
    } else if (response instanceof ServerErrorResponse) {
      toast(response.id, 'error');
      setAsyncState(AsyncState.rejected);
    } else if (response) {
      setAsyncState(AsyncState.resolved);
      toastCopySuccess(
        copyMode,
        completeValues,
        rootStore,
        type === 'Goals' ? 'goals' : 'plan',
        resolution,
        conflictResponse?.conflictingIDs.length ?? 0
      );

      if (type === 'Goals' || copyMode === 'importFromReality') {
        void diaryStore.reloadDiaryData();
      }
      layer.close();
    } else {
      toast(`toast.error.${type === 'Goals' ? 'goals' : 'plan'}.copy`, 'error');
      setAsyncState(AsyncState.rejected);
    }
  };

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    await submitCopy('FailOnConflict');
  };

  const { type } = mapCopyTypeToApiParams(diaryStore);

  return (
    <>
      <CopyForm onSubmit={handleSubmit}>
        <Header onChange={setCopyMode} />
        <ElementsWrapper>
          {elements.map(el => getElement(el, handleChange, copyMode))}
        </ElementsWrapper>
        <SubmitButtonWrapper>
          <AsyncButton
            loading={state === AsyncState.pending}
            error={state === AsyncState.rejected}
            success={state === AsyncState.resolved}
            type="submit"
            variant={FormControlVariant.big}
            disabled={!isValidCopyModeParams(copyMode, formValues, diaryStore)}
            dataTest="do-copy-button"
            innerRef={submitButtonRef}
          >
            <FormattedMessage
              id={
                type === 'Goals' || type === 'SeasonGoals'
                  ? 'goals.copy.doCopy'
                  : 'plan.copy.doCopy'
              }
            />
          </AsyncButton>
        </SubmitButtonWrapper>
      </CopyForm>
      <LayerPortal
        layerHandle={conflictLayer}
        getContent={layer => (
          <ConflictLayerContent
            layer={layer}
            conflictingIds={conflictResponse?.conflictingIDs ?? []}
            conflictResolutions={conflictResponse?.resolutions ?? []}
            submitCopy={submitCopy}
            mode={copyMode}
            sourceType={diaryStore.athleteId ? 'athlete' : 'group'}
            module={diaryStore.diaryType}
            viewType={diaryStore.viewType}
          />
        )}
      />
    </>
  );
});
