import { File } from '@yarmill/types';
import { useFileLink } from '@yarmill/utils';
import { PropsWithChildren, forwardRef } from 'react';
import { Button, ButtonAppearance } from './button';
import { styled } from './theme-provider';

export type FileLinkProps = PropsWithChildren<{
  file: File;
  forceDownload?: boolean;
  iconOnly?: boolean;
  square?: boolean;
  className?: string;
}>;

const StyledLink = styled(Button)`
  overflow: hidden;
  text-align: left;
  padding: 0;
`;

export const FileLink = forwardRef<HTMLButtonElement, FileLinkProps>(
  function FileLink(props, ref): JSX.Element {
    const { file, children, forceDownload, iconOnly, square, className } =
      props;
    const onLinkClick = useFileLink(file, forceDownload);

    return (
      <>
        <StyledLink
          onClick={onLinkClick}
          type="button"
          appearance={ButtonAppearance.Link}
          iconOnly={iconOnly}
          square={square}
          className={className}
          ref={ref}
        >
          {children}
        </StyledLink>
      </>
    );
  }
);
