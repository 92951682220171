import { preformatTimeValue } from '@yarmill/components';
import { ISO_DATE_FORMAT } from '@yarmill/const';
import {
  EvidenceAttribute,
  EvidenceAttributeId,
  EvidenceAttributeType,
  EvidenceDataObjectAttribute,
  UserGroupId,
  UserId,
} from '@yarmill/types';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import moment from 'moment';
import { formatValueByDataType, timeToMS } from '../utils';
import { validateEvidenceAttribute } from '../validation';
import { EvidenceObjectDataStore } from './evidence-object-data-store';

export class EvidenceAttributeStore {
  private readonly _objectDataStore: EvidenceObjectDataStore;
  private readonly _definition: EvidenceAttribute;
  private readonly _objectKey: string;
  private readonly _userId: UserId | null;
  private readonly _groupId: UserGroupId | null;

  @observable
  private _apiValue: string | number | null = null;

  @observable
  private _userValue: string | null = null;

  @observable
  private _touched: boolean = false;

  @observable
  private _focused: boolean = false;

  @observable
  private _computedValue: string | null = null;

  constructor(
    objectDataStore: EvidenceObjectDataStore,
    objectKey: string,
    definition: EvidenceAttribute,
    userId: UserId | null,
    groupId: UserGroupId | null
  ) {
    makeObservable(this);
    this._objectDataStore = objectDataStore;
    this._objectKey = objectKey;
    this._definition = definition;
    this._userId = userId;
    this._groupId = groupId;
    reaction(
      () => this.value,
      () => objectDataStore.computeComputedAttributes(),
      {
        fireImmediately: true,
      }
    );
  }

  get definition(): EvidenceAttribute {
    return this._definition;
  }

  get objectKey(): string {
    return this._objectKey;
  }

  @action
  setApiValue(value: string | number | null): void {
    this._apiValue = value;
    if (
      (this.definition.AttributeTypeKey === 'number' ||
        this.definition.AttributeTypeKey === 'decimal-number') &&
      value
    ) {
      this._apiValue = String(Number(value));
    }
    if (this.definition.AttributeTypeKey === 'time' && value) {
      this._apiValue = preformatTimeValue(
        String(value),
        this.definition.Format
      );
    }
  }

  @computed
  get value(): string {
    return this._userValue ?? (this._apiValue ? String(this._apiValue) : '');
  }

  @computed
  get numericValue(): number {
    const value = this.value;
    switch (this.definition.AttributeTypeKey) {
      case 'decimal-number':
      case 'number':
        return typeof value === 'string'
          ? Number(value.replace(',', '.'))
          : Number(value);
      case 'time': {
        const fullValue = formatValueByDataType(
          this.definition.AttributeTypeKey,
          value,
          this.definition.Format
        ) as string;

        return timeToMS(fullValue);
      }
      default:
        return 0;
    }
  }

  @computed
  get formValue(): string {
    return this.definition.IsCalculated
      ? (this._computedValue ?? '')
      : this.value;
  }

  @action
  computeValue(): void {
    if (this.definition.IsCalculated && this.definition.Formula) {
      this._computedValue = this.objectDataStore.computeValue(
        this.definition.Formula
      );
      if (
        (this.definition.AttributeTypeKey === 'number' ||
          this.definition.AttributeTypeKey === 'decimal-number') &&
        typeof this._computedValue === 'number'
      ) {
        this._computedValue = (this._computedValue as number).toLocaleString(
          moment.locale(),
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: 10,
          }
        );
      }
      if (
        this.definition.AttributeTypeKey === 'time' &&
        this._computedValue !== ''
      ) {
        this._computedValue = preformatTimeValue(
          moment
            .duration(parseFloat(this._computedValue), 'milliseconds')
            .format('hh:mm:ss.SSS', { trim: false }),
          this.definition.Format
        );
      }
    }
  }

  @computed
  get hasValue(): boolean {
    return Boolean(this.value);
  }

  @computed
  get isRequired(): boolean {
    return !this._definition.IsOptional && !this._definition.IsCalculated;
  }

  @computed
  get attributeValueObject(): EvidenceDataObjectAttribute {
    return {
      AttributeId: this.definition.AttributeId,
      AttributeValue: formatValueByDataType(
        this.definition.AttributeTypeKey,
        this.value,
        this.definition.Format
      ),
    };
  }

  @computed
  get isValid(): boolean {
    if (this.definition.IsCalculated) {
      return true;
    }

    if (!this.definition.IsOptional && !this.hasValue) {
      return false;
    }

    if (this.definition.IsOptional && !this.hasValue) {
      return true;
    }

    return validateEvidenceAttribute(
      this.definition.AttributeTypeKey,
      this.value,
      this.definition.Format
    );
  }

  get id(): EvidenceAttributeId {
    return this.definition.AttributeId;
  }

  get attributeType(): EvidenceAttributeType {
    return this.definition.AttributeTypeKey;
  }

  get isTouched(): boolean {
    return this._touched;
  }

  get isFocused(): boolean {
    return this._focused;
  }

  get objectDataStore(): EvidenceObjectDataStore {
    return this._objectDataStore;
  }

  get userId(): UserId | null {
    return this._userId;
  }

  get groupId(): UserGroupId | null {
    return this._groupId;
  }

  @action
  clear(): void {
    this._apiValue = null;
    this._userValue = null;
    this._touched = false;
  }

  @action
  reset(): void {
    this._userValue = null;
    this._touched = false;
  }

  @action
  readonly onChange = (value: string): void => {
    this._userValue = value;
  };

  @action
  readonly prefill = (value: string | number): void => {
    if (
      (this.definition.AttributeTypeKey === 'number' ||
        this.definition.AttributeTypeKey === 'decimal-number') &&
      value
    ) {
      this._userValue = String(Number(value));
    } else if (this.definition.AttributeTypeKey === 'time' && value) {
      this._userValue = preformatTimeValue(
        String(value),
        this.definition.Format
      );
    } else {
      this._userValue = value as string;
    }
  };

  @action
  readonly onFocus = (): void => {
    this._focused = true;
    this._touched = true;
  };

  @action
  readonly onBlur = (): void => {
    this._focused = false;
  };

  @action
  setDefaultValue = (): void => {
    if (this.definition.SourceData) {
      this._userValue = this._apiValue
        ? String(this._apiValue)
        : this.definition.SourceData.find(item => item.IsDefault)?.Key || null;
    }
    if (
      this.definition.AttributeTypeKey === 'date' &&
      this.isRequired &&
      !this._apiValue
    ) {
      this._userValue = moment().format(ISO_DATE_FORMAT);
    }
  };
}
