import { GoogleAnalytics } from '../google-analytics/google-analytics';
import Helmet from 'react-helmet';

export function Meta(): JSX.Element {
  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#f8f9fa" />
      </Helmet>
      <GoogleAnalytics />
    </>
  );
}
