import {
  Avatar,
  CurrentUserDropdownButton,
  CurrentUserNavigation,
  ExternalIcon,
  LayerPortal,
  UserSectionDropdown,
} from '@yarmill/components';
import { Locale } from '@yarmill/types';
import {
  getUserName,
  useCollapsible,
  useConfig,
  useCurrentUserStore,
  useHistory,
  useLayer,
  useLocale,
  useLocation,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { clearBodyLocks, lock } from 'tua-body-scroll-lock';
import { useAuthStore } from '../auth/hooks';
import { trackDropdownClick, trackLogout } from '../google-analytics/utils';
import { useChangeLanguageHandler } from '../intl/hooks/use-change-language-handler';
import { ChangePassword } from '../profile/change-password';
import { AvatarLoader } from './avatar-loader';
import { UserSectionItem } from './user-section-item';

export const UserSection = observer(function UserSection(): JSX.Element {
  const currentLang = useLocale();
  const authStore = useAuthStore();
  const externalLinks = useConfig('externalLinks');
  const [opened, openDropdown, closeDropdown, dropdownRef] =
    useCollapsible<HTMLDivElement>();
  const currentUser = useCurrentUserStore();
  const history = useHistory();
  const location = useLocation();
  const changePasswordLayer = useLayer('full-screen');
  const scrollableListRef = useRef<HTMLUListElement>(null);
  const availableLanguages = useConfig('availableLanguages');
  const handleChangeLanguage = useChangeLanguageHandler();

  useEffect(() => {
    if (opened && scrollableListRef.current) {
      lock(scrollableListRef.current);
    } else {
      clearBodyLocks();
    }
    return () => clearBodyLocks();
  }, [opened]);

  const toggleDropdown = useCallback(() => {
    trackDropdownClick('user section', opened ? 'close' : 'open');
    opened ? closeDropdown() : openDropdown();
  }, [closeDropdown, openDropdown, opened]);

  const changeLocale = useCallback(
    (lang: Locale): void => {
      handleChangeLanguage(lang);

      closeDropdown();
    },
    [closeDropdown, handleChangeLanguage]
  );

  const changePassword = useCallback((): void => {
    if (!changePasswordLayer.isOpened) {
      changePasswordLayer.open();
    }
  }, [changePasswordLayer]);

  const handleLogOut = useCallback((): void => {
    trackLogout();
    authStore.logOut().finally(() => (window.location.href = '/'));
  }, [authStore]);

  const goToPersonal = useCallback((): void => {
    history.push('/settings/personal', {
      previousRoute: location,
    });
  }, [history, location]);

  return (
    <CurrentUserNavigation ref={dropdownRef}>
      <CurrentUserDropdownButton
        type="button"
        onClick={toggleDropdown}
        data-cy="user-dropdown-btn"
      >
        {currentUser.data ? (
          <Avatar id={currentUser.data.AvatarFileName} disableFlex />
        ) : (
          <AvatarLoader />
        )}
      </CurrentUserDropdownButton>
      {opened && (
        <UserSectionDropdown isOpened={opened} ref={scrollableListRef}>
          {currentUser?.data && (
            <UserSectionItem
              dataTest="personal"
              onClick={goToPersonal}
              isHeader
            >
              {getUserName(currentUser.data)}
            </UserSectionItem>
          )}
          {currentUser.data?.Instances.slice()
            .sort((a, b) =>
              a.Name.toLocaleLowerCase('cs').localeCompare(
                b.Name.toLocaleLowerCase('cs')
              )
            )
            .map(instance => (
              <UserSectionItem
                dataTest={`sing-in-to-${instance.Ident}`}
                icon={<ExternalIcon name="CircleArrowRight" />}
                linkHref={instance.FeUrl}
                key={instance.Ident}
              >
                <FormattedMessage
                  id="header.navigation.signInTo"
                  values={{ instance: instance.Name }}
                />
              </UserSectionItem>
            ))}
          <UserSectionItem
            icon={<ExternalIcon name="CirclePlus" />}
            linkHref={`https://yarmill.com/${
              currentLang === 'cs' ? 'cs/prihlasit' : 'en/sign-in'
            }`}
            dataTest="sing-in-to-other-team"
          >
            <FormattedMessage id="header.navigation.signInToOtherTeam" />
          </UserSectionItem>
          <UserSectionItem
            dataTest="change-password"
            icon={<ExternalIcon name="Lock" />}
            onClick={changePassword}
          >
            <FormattedMessage id="header.navigation.changePassword" />
          </UserSectionItem>
          <UserSectionItem
            dataTest="help"
            icon={<ExternalIcon name="Help" />}
            linkHref="https://www.yarmill.com/cs-navod/"
            linkTarget="_blank"
          >
            <FormattedMessage id="header.navigation.help" />
          </UserSectionItem>
          {externalLinks.map(externalLink => (
            <UserSectionItem
              key={`${externalLink.label}-${externalLink.url}`}
              dataTest="external-link"
              icon={
                <ExternalIcon name={externalLink.icon ?? 'FileCertificate'} />
              }
              linkHref={externalLink.url}
              linkTarget="_blank"
            >
              <FormattedMessage id={externalLink.label} />
            </UserSectionItem>
          ))}

          {availableLanguages
            .filter(lang => lang !== currentLang)
            .map(lang => (
              <UserSectionItem
                key={lang}
                dataTest={`set-language-${lang}`}
                icon={<ExternalIcon name="Language" />}
                onClick={() => changeLocale(lang)}
              >
                <FormattedMessage id={`header.navigation.language.${lang}`} />
              </UserSectionItem>
            ))}

          <UserSectionItem
            dataTest="log-out"
            danger
            onClick={handleLogOut}
            icon={<ExternalIcon name="Logout" />}
          >
            <FormattedMessage id="header.navigation.logout" />
          </UserSectionItem>
        </UserSectionDropdown>
      )}
      <LayerPortal
        layerHandle={changePasswordLayer}
        getContent={layer => <ChangePassword layer={layer} />}
      />
    </CurrentUserNavigation>
  );
});
