import { FactType, IndicatorType, VerticalDimension } from './types';

export const formatNumber = (value: number): string =>
  value.toLocaleString('cs', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  });

const REALITY_INDICATORS: IndicatorType[] = [
  'Dif. skut./RP',
  'Průměr kategorie (skut.)',
  'Dif. skut./kateg.',
];

const ANNUAL_PLAN_INDICATORS: IndicatorType[] = [
  'Dif. skut./RP',
  'Průměr kategorie (RP)',
  'Dif. RP/kateg.',
];

const PLAN_INDICATORS: IndicatorType[] = [
  'Dif. skut./plán',
  'Průměr kategorie (plán)',
  'Dif. plán/kateg.',
];

export const getIndicatorTypesByFactType = (
  factType?: FactType
): IndicatorType[] => {
  switch (factType) {
    case 'R':
      return REALITY_INDICATORS;
    case 'C':
      return ANNUAL_PLAN_INDICATORS;
    default:
      return PLAN_INDICATORS;
  }
};

export const sortBySortCode = (a: VerticalDimension, b: VerticalDimension) =>
  a.SortCode - b.SortCode;
