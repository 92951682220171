import { DataDefinitionV2, ReportItem } from '../types';
import { computed, makeObservable } from 'mobx';
import { BaseReportStore } from './base-report-store';

export class OverviewTableStore extends BaseReportStore {
  constructor(item: ReportItem) {
    super(item);
    makeObservable(this);
  }
  @computed
  public get columnKey(): DataDefinitionV2 | undefined {
    return this._item.DataDefinition.find(def => def.Purpose === 'ColumnKey');
  }

  @computed
  public get rowKey(): DataDefinitionV2 | undefined {
    return this._item.DataDefinition.find(def => def.Purpose === 'RowKey');
  }

  @computed
  public get flag(): DataDefinitionV2 | undefined {
    return this._item.DataDefinition.find(def => def.Purpose === 'Flag');
  }

  @computed
  public get category(): DataDefinitionV2 | undefined {
    return this._item.DataDefinition.find(def => def.Purpose === 'Category');
  }

  @computed
  public get value(): DataDefinitionV2 | undefined {
    return this._item.DataDefinition.find(def => def.Purpose === 'Value');
  }

  public get printWidth(): number | undefined {
    return this._item.Params?.PrintWidth;
  }

  public get hasRowLabel(): boolean {
    return Boolean(this._item.Params?.HasRowLabel);
  }

  public get hasHeader(): boolean {
    return Boolean(this._item.Params?.HasHeader);
  }

  public get dataTooltip(): string | undefined {
    return this.value?.Tooltip;
  }
}
