import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useAttendanceStore } from './attendance-store-context';
import { AttendanceValueButtons } from './attendance-value-buttons';
import { WeekAttendanceItemStore } from './mobx/week-attendance-item-store';
import { AttendanceValue } from './types';

export interface SelectAllButtonProps {
  day: string;
  disabled?: boolean;
}

export const SelectAllButtons = observer(function SelectAllButtons(
  props: SelectAllButtonProps
): JSX.Element {
  const { day, disabled } = props;
  const attendanceStore = useAttendanceStore();
  const values = Array.from(attendanceStore.weekData.values());

  const item = useMemo(() => {
    const value = values[0]?.get(day)?.value;
    const areAllSame = values.every(
      userData => userData.get(day)?.value === value
    );
    return areAllSame ? ({ value } as WeekAttendanceItemStore) : undefined;
  }, [values, day]);

  const onClick = useCallback(
    async (type: AttendanceValue) => {
      await attendanceStore.selectAll(day, type);
    },
    [day, attendanceStore]
  );

  return (
    <AttendanceValueButtons
      date={day}
      item={item}
      handleClick={onClick}
      disabled={disabled}
    />
  );
});
