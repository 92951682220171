import { useGeneratedUrl, useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import { REDIRECT_ACTION_PARAM } from '../app/utils';

export const RedirectActionHandler = observer(
  function RedirectActionHandler(): JSX.Element | null {
    const rootStore = useRootStore();
    const currentUrl = useGeneratedUrl({});
    const withoutRA = useGeneratedUrl({ [REDIRECT_ACTION_PARAM]: null });

    if (rootStore.isReady && currentUrl !== withoutRA) {
      return <Redirect to={withoutRA} />;
    }

    return null;
  }
);
