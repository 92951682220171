import * as React from 'react';
import styled, { css } from 'styled-components';

import { Text, TextSize, TextTag } from '../text';

export interface RadioButtonProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  readonly defaultChecked?: boolean;
  readonly checked?: boolean;
  readonly disabled?: boolean;
  readonly id?: string;
  readonly name?: string;
  readonly value: string;
  readonly label?: string | JSX.Element;
}

const StyledLabel = styled.label`
  position: relative;
  color: #4a4a4a;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;

const StyledRadioWrapper = styled.span`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledFocusWrapper = styled.span`
  position: relative;
  display: grid;
  grid-column-gap: 12px;
  grid-template-columns: max-content auto;
  align-items: center;
`;

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  outline: 0;
  user-select: none;
  pointer-events: none;

  /* we need a higher specificity here than :hover */
  &&&:disabled {
    + ${StyledFocusWrapper} {
      cursor: not-allowed;
      color: #9a9a9a;
    }
  }
`;

const StyledCircle = styled.circle<{ checked?: boolean }>`
  ${({ checked }) =>
    checked
      ? css`
          stroke: #4a90e2;
          stroke-width: 1;
          fill: #4a90e2;
        `
      : css`
          stroke: #ced4da;
          stroke-width: 1;
          fill: none;
        `}
`;

export function RadioButton(props: RadioButtonProps): JSX.Element {
  const {
    defaultChecked,
    checked,
    disabled,
    id,
    name,
    value,
    label = '',
    ...inputProps
  } = props;

  return (
    <StyledLabel>
      <StyledInput
        {...inputProps}
        defaultChecked={defaultChecked}
        checked={checked}
        disabled={disabled}
        id={id}
        name={name}
        type="radio"
        value={value}
      />
      <StyledFocusWrapper>
        <StyledRadioWrapper>
          <svg width={24} height={24} viewBox="0 0 24 24">
            <StyledCircle r={10} cx={12} cy={12} checked={checked} />
            <circle r={3.5} cx={12} cy={12} fill="#fff" />
          </svg>
        </StyledRadioWrapper>
        {label &&
          (typeof label === 'string' ? (
            <Text tag={TextTag.span} size={TextSize.s14}>
              {label}
            </Text>
          ) : (
            label
          ))}
      </StyledFocusWrapper>
    </StyledLabel>
  );
}
