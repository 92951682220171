import { Editor } from '@tiptap/react';
import { Video } from '@yarmill/types';
import { getFileNameWithoutExtension, useRootStore } from '@yarmill/utils';
import { ReactElement, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Tippy } from '../../tippy';
import { BrandYoutube } from '../icons/brand-youtube';
import { getSelectedText } from '../utils/get-selected-text';
import { Button } from './button';
import { OtherButtonsMenuContext } from './other-button';

interface VideoLinkButtonProps {
  readonly editor: Editor;
  readonly setKeepControlsVisible: (v: (prev: number) => number) => void;
  readonly tabIndex: number;
}

export function VideoLinkButton({
  editor,
  setKeepControlsVisible,
  tabIndex,
}: VideoLinkButtonProps): ReactElement {
  const videoSelector = useRootStore().videoSelectorService;
  const { closeMenu } = useContext(OtherButtonsMenuContext);
  const intl = useIntl();

  function formatLink(video: Video) {
    setKeepControlsVisible(v => Math.max(0, v - 1));
    editor.chain().focus().run();
    if (!video) {
      if (editor.isActive('link')) {
        editor.chain().focus().unsetLink().run();
      }
      return;
    }

    const selectedText = getSelectedText(editor);
    const href = `${window.location.origin}/video/${video.FileId}`;
    const text = selectedText || getFileNameWithoutExtension(video.FileName);

    editor.chain().focus().setVideoLink({ href }).insertContent(text).run();
    editor.commands.toggleMark('videoLink');
    editor.chain().focus().insertContent(' ').run();
    closeMenu();
  }

  const label = 'richtext.toolbar.video';

  return (
    <>
      <Tippy tooltipContent={label} noWrapper touch={false}>
        <Button
          type="button"
          tabIndex={tabIndex}
          isActive={editor.isActive('videoLink')}
          aria-label={intl.formatMessage({ id: label })}
          onMouseDown={() => {
            if (videoSelector.isOpen) {
              videoSelector.closeVideoSelector();
            } else {
              if (!getSelectedText(editor) && editor.isActive('videoLink')) {
                editor.commands.toggleMark('videoLink');
              } else {
                setKeepControlsVisible(v => v + 1);
                videoSelector.openVideoSelector(formatLink);
              }
            }
          }}
        >
          <BrandYoutube />
        </Button>
      </Tippy>
    </>
  );
}
