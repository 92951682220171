import { StyledTd, Text, TextSize, WhiteSpace } from '@yarmill/components';
import { UserStore } from '@yarmill/types';
import { observer } from 'mobx-react-lite';
import {
  useAttendanceSumColumnItemValue,
  useEnabledDaysForUser,
} from './hooks';
import { SumColumnType } from './types';
import { formatNumberValue } from './utils';

export interface SumItemProps {
  user: UserStore;
  type: SumColumnType;
}

const formatValueByType = (
  value: number,
  type: SumColumnType,
  enabledDays: number
): number | string => {
  switch (type) {
    case 'missed':
      return enabledDays - value;
    case 'percentage':
      return `${formatNumberValue((value / (enabledDays || 1)) * 100)}%`;
    default:
      return value;
  }
};

export const SumColumnItem = observer(function SumColumnItem(
  props: SumItemProps
): JSX.Element {
  const { type, user } = props;
  const enabledDays = useEnabledDaysForUser(user);
  const value = useAttendanceSumColumnItemValue(user);
  const formattedValue = formatValueByType(value, type, enabledDays);

  return (
    <StyledTd
      key={type}
      fixedWidth={type === 'percentage'}
      whiteSpace={WhiteSpace.noWrap}
    >
      <Text size={TextSize.s14}>
        {enabledDays !== 0 ? formattedValue : '-'}
      </Text>
    </StyledTd>
  );
});
