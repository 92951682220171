import { WORKOUT_URL_PARAM } from '@yarmill/const';

export function getWorkoutLink(workoutId: number | null): string {
  const url = new URL(window.location.href, window.location.origin);

  if (workoutId) {
    url.searchParams.set(WORKOUT_URL_PARAM, String(workoutId));
  } else {
    url.searchParams.delete(WORKOUT_URL_PARAM);
  }

  return `${url.pathname}${url.search}`;
}
