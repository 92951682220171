import { useScale } from '../hooks/use-scale';
import { getAxisPosition } from '../../reporting/utils/get-axis-position';
import { getBarSize } from './get-bar-size';

export function getHorizontalBarLabelX(
  xScale: ReturnType<typeof useScale>,
  barValue: number | null
): number {
  const domainX = xScale.domain();
  const axisPosition = getAxisPosition(domainX as number[]);

  if ('bandwidth' in xScale) {
    throw new Error('Bars are currently supported only in categorical scale');
  }

  if (barValue === null) {
    return 0;
  }

  const barWidth = getBarSize(xScale, barValue);

  return barValue < 0 ? xScale(axisPosition) - barWidth : xScale(axisPosition);
}
