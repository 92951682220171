import { UserId } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { CsvTableRowData } from '../components/csv-table/types';

export interface GetSeasonOverSeasonParams {
  userId?: UserId;
}

export type GetSeasonOverSeasonResponse = {
  Data: CsvTableRowData[];
}[];

export function getSeasonOverSeasonData(
  params: GetSeasonOverSeasonParams,
  cancelToken: CancelTokenSource
): AxiosPromise<GetSeasonOverSeasonResponse> {
  return axios.get('/api/rtc', {
    params,
    cancelToken: cancelToken.token,
  });
}
