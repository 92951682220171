import { GridColumns, GridRows } from '@visx/grid';
import { useXYChartContext } from './xy-chart-context';
import {
  AxisValue,
  GridLinesConfig,
  XAxisConfig,
  YAxisConfig,
} from '../reporting/types';
import { getAxisPosition } from '../reporting/utils/get-axis-position';

interface GridLineProps {
  readonly config?: GridLinesConfig;
  readonly columnTicks?: AxisValue[];
  readonly rowTicks?: AxisValue[];
  readonly xAxisConfig?: XAxisConfig<AxisValue>;
  readonly yAxisConfig?: YAxisConfig<AxisValue>;
}

export function GridLines({
  config,
  columnTicks,
  rowTicks,
  xAxisConfig,
  yAxisConfig,
}: GridLineProps): JSX.Element {
  const { xScale, yScale, chartRect } = useXYChartContext();
  const domainY = yScale.domain();
  const domainX = xScale.domain();
  const showAxisXLine = xAxisConfig?.hideAxisLine
    ? false
    : !('bandwidth' in yScale) && Number(domainY?.[0] || 0) < 0;
  const showAxisYLine = yAxisConfig?.hideAxisLine
    ? false
    : !('bandwidth' in xScale) && Number(domainX?.[0] || 0) < 0;

  return (
    <>
      {config?.showRows && (
        <GridRows
          left={chartRect.left}
          width={chartRect.right - chartRect.left}
          height={chartRect.bottom - chartRect.top}
          scale={yScale}
          lineStyle={config.rowLineStyle ?? config.lineStyle}
          tickValues={rowTicks}
          numTicks={config.numberOfRows}
        />
      )}
      {config?.showColumns && (
        <GridColumns
          top={chartRect.top}
          width={chartRect.right - chartRect.left}
          height={chartRect.bottom - chartRect.top}
          scale={xScale}
          lineStyle={config.columnLineStyle ?? config.lineStyle}
          tickValues={columnTicks}
          numTicks={config.numberOfColumns}
        />
      )}
      {showAxisXLine && (
        <GridRows
          scale={yScale}
          left={chartRect.left}
          width={chartRect.right - chartRect.left}
          height={chartRect.bottom - chartRect.top}
          numTicks={1}
          tickValues={[getAxisPosition(domainY as number[])]}
          stroke="#a9a9a9"
        />
      )}
      {showAxisYLine && (
        <GridColumns
          scale={xScale}
          left={chartRect.left}
          width={chartRect.right - chartRect.left}
          height={chartRect.bottom - chartRect.top}
          numTicks={1}
          tickValues={[getAxisPosition(domainX as number[])]}
          stroke="#a9a9a9"
        />
      )}
    </>
  );
}
