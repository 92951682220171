import { useRef, useContext } from 'react';
import styled from 'styled-components';

import { useDay } from '@datepicker-react/hooks';
import { Text, TextSize } from '../text';
import { DatepickerContext } from './index';

interface DayProps {
  readonly dayLabel: string;
  readonly date: Date;
}

interface ColorProp {
  selectedStartEndColor: string;
  selectedColor: string;
  withinHoverRange: string;
  disableColor: string;
  normalColor: string;
}

interface StyledDayComponentProps {
  color?: string;
  backgroundColor?: string;
}

const StyledDayComponent = styled.div<StyledDayComponentProps>`
  outline: none;
  padding: 7px 0;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  border: 1px solid #e4e7e7;
  border-radius: 3px;
  color: ${props => props.color};
  background: ${props => props.backgroundColor};
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:active {
    border: 1px solid #e4e7e7;
    filter: brightness(95%);
  }

  @media (min-width: 768px) {
    padding: 9px 0;
  }
`;

export function Day(props: DayProps): JSX.Element {
  const { dayLabel, date } = props;
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DatepickerContext);

  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  if (!dayLabel) {
    return <div />;
  }

  const getColor = (color: ColorProp) => {
    if (isSelectedStartOrEnd) {
      return color.selectedStartEndColor;
    }
    if (isSelected) {
      return color.selectedColor;
    }
    if (isWithinHoverRange) {
      return color.withinHoverRange;
    }
    if (disabledDate) {
      return color.disableColor;
    }

    return color.normalColor;
  };

  const classNames = [
    isSelectedStartOrEnd && 'selected',
    disabledDate && 'disabled',
  ].filter(Boolean);

  return (
    <StyledDayComponent
      data-cy="datepicker-day"
      onClick={onClick}
      tabIndex={tabIndex}
      ref={dayRef}
      className={classNames.length ? classNames.join(' ') : undefined}
      color={getColor({
        selectedStartEndColor: '#fff',
        selectedColor: '#fff',
        withinHoverRange: '#fff',
        disableColor: '#cacccd',
        normalColor: '#484848',
      })}
      backgroundColor={getColor({
        selectedStartEndColor: '#4a90e2',
        selectedColor: '#4a90e2',
        withinHoverRange: '#71c9ed',
        disableColor: '#eee',
        normalColor: '#fff',
      })}
    >
      <Text size={TextSize.s14} inheritColor>
        {parseInt(dayLabel, 10)}
      </Text>
    </StyledDayComponent>
  );
}
