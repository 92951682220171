import {
  ActivitiesTable,
  ActivitiesTableContainer,
  ActivityRowWrapper,
  ActivityTitle,
  ContentCell,
  HeadCell,
  HoverWrapper,
  SummaryCell,
  Text,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { ActivityItem } from '@yarmill/types';
import { times } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { MouseEvent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDiaryStore } from '../diary/diary-store-context';
import { HeaderCellProps } from '../diary/week-header-cell';
import { ActivitySummary } from './activity-summary';
import { ActivityValue } from './activity-value';
import { getTabIndex } from './utils';

export interface ActivitiesListProps {
  columnsCount: number;
  HeaderCellContent: React.ComponentType<HeaderCellProps>;
}

function InternalActivitiesList(props: ActivitiesListProps): JSX.Element {
  const { columnsCount, HeaderCellContent } = props;
  const diaryStore = useDiaryStore();
  const activityItems =
    diaryStore.activityListStore?.visibleActivityItems || [];
  const columns = times(columnsCount);

  const onActivityLabelClick = useCallback(
    (e: MouseEvent, activityItem: ActivityItem) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        e.stopPropagation();
        diaryStore.activityListStore?.selectWholeActivityItemRow(activityItem);
      }
    },
    [diaryStore]
  );

  function onColumnLabelClick(e: MouseEvent, columnIndex: number) {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      e.stopPropagation();
      diaryStore.activityListStore?.selectWholeColumn(columnIndex);
    }
  }

  function onEmptyHeadCellClick(e: MouseEvent) {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      e.stopPropagation();
      diaryStore.activityListStore?.selectAll();
    }
  }

  return (
    <ActivitiesTableContainer>
      <ActivitiesTable columnsCount={columnsCount + 2}>
        <HeadCell
          className="activity-column-label"
          onClick={onEmptyHeadCellClick}
        />
        {columns.map((_, columnIndex) => (
          <HeadCell
            className="activity-column-label"
            key={columnIndex}
            onClick={e => onColumnLabelClick(e, columnIndex)}
          >
            <HeaderCellContent columnIndex={columnIndex} />
          </HeadCell>
        ))}
        <HeadCell>
          <FormattedMessage id="activityItem.columns.total">
            {msg => (
              <Text bold size={TextSize.s12} tag={TextTag.div}>
                {msg}
              </Text>
            )}
          </FormattedMessage>
        </HeadCell>
        {activityItems.map((activityItem, rowIndex) => (
          <React.Fragment key={activityItem.ActivityItemId}>
            <ActivityTitle
              activityItem={activityItem}
              onClick={onActivityLabelClick}
            />
            <ActivityRowWrapper>
              {columns.map((_, columnIndex) => (
                <ContentCell key={columnIndex}>
                  <HoverWrapper>
                    <ActivityValue
                      activityItem={activityItem}
                      columnIndex={columnIndex}
                      tabIndex={getTabIndex(rowIndex + 1, columnIndex + 1)}
                    />
                  </HoverWrapper>
                </ContentCell>
              ))}
              <SummaryCell>
                <HoverWrapper>
                  <ActivitySummary activityItem={activityItem} />
                </HoverWrapper>
              </SummaryCell>
            </ActivityRowWrapper>
          </React.Fragment>
        ))}
      </ActivitiesTable>
    </ActivitiesTableContainer>
  );
}

export const ActivitiesList = React.memo(observer(InternalActivitiesList));
