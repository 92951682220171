import { FormUserInvitation } from '../types';
import { isValidInvitation } from '../utils/is-valid-invitation';
import { isEmptyInvitation } from './is-empty-invitation';

export function hasInvalidInvitation(
  invitations: FormUserInvitation[]
): boolean {
  return invitations.some(invitation => {
    if (isValidInvitation(invitation)) {
      return false;
    }

    return !isEmptyInvitation(invitation);
  });
}
