import { Icon, IconSize, Tippy } from '@yarmill/components';
import { Button, ButtonAppearance } from '@yarmill/components-2';
import { Chat } from '@yarmill/icons-2';
import { useIntl } from 'react-intl';
import { useOkrsStore } from '../okrs-store-context';

export function Comments(): JSX.Element {
  const intl = useIntl();
  const okrsStore = useOkrsStore();

  return (
    <Tippy
      tooltipContent="okrs.activityLog.showActivityLog"
      noWrapper
      touch={false}
    >
      <Button
        $appearance={ButtonAppearance.Secondary}
        onClick={() => {
          const currentPanel = okrsStore.visibleSidePanel;
          okrsStore.setVisibleRightPanel(
            currentPanel === 'activity-log' ? null : 'activity-log'
          );
        }}
        $appearanceStyle="neutral"
        $iconOnly
        aria-label={intl.formatMessage({
          id: 'okrs.activityLog.showActivityLog',
        })}
      >
        <Icon size={IconSize.s24}>
          <Chat />
        </Icon>
      </Button>
    </Tippy>
  );
}
