import * as React from 'react';
import { PropsWithChildren } from 'react';

export class ErrorBoundary extends React.PureComponent<
  PropsWithChildren<{
    readonly fallback: JSX.Element;
    onCatch?(error: Error, errorInfo: React.ErrorInfo): void;
  }>,
  { error?: boolean }
> {
  public readonly state = { error: false };

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error: true });
    this.props.onCatch?.(error, errorInfo);
  }

  render() {
    const { error } = this.state;
    const { children, fallback } = this.props;
    return error ? fallback : children;
  }
}
