import { StackedBarChart } from '../../types';
import { groupStackedBarsByCategories } from '../../utils/group-stacked-bars-by-categories';
import { StackedBarRectLabel } from './stacked-bar-rect-label';
import { ArrayElement, DataItem } from '../../../reporting/types';
import { getBarRadius } from '../../../reporting/utils/get-bar-radius';

interface StackedBarRectProps {
  readonly bar: ArrayElement<
    ArrayElement<ReturnType<typeof groupStackedBarsByCategories>>
  >;
  readonly config: StackedBarChart;
  readonly item: DataItem;
}
export function StackedBarRect({ bar, config, item }: StackedBarRectProps) {
  const showCategoryGaps = config.showCategoryGaps;
  const barHeight = showCategoryGaps ? Math.max(bar.height - 2, 1) : bar.height;
  const barY = showCategoryGaps ? bar.y + 1 : bar.y;

  if (Number.isNaN(barHeight) || barHeight <= 0) {
    return null;
  }

  return (
    <>
      <rect
        x={bar.x}
        y={barY}
        height={barHeight}
        width={bar.width}
        fill={config.getColor(item, bar.key)}
        opacity={config.getOpacity(item, bar.key)}
        pointerEvents="none"
        rx={showCategoryGaps ? getBarRadius(bar.width) : undefined}
      />
      <StackedBarRectLabel
        bar={bar}
        barHeight={barHeight}
        config={config}
        item={item}
        barY={barY}
      />
    </>
  );
}
