import { styled, Text, TextSize } from '@yarmill/components';
import { PropsWithChildren } from 'react';

const KeyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.neutral};
  border-radius: ${({ theme }) => theme.borderRadius.x05};
  min-width: ${({ theme }) => theme.size.x2};
  height: ${({ theme }) => theme.size.x2};
  visibility: hidden;
`;

export function Key({ children }: PropsWithChildren): JSX.Element {
  return (
    <KeyWrapper>
      <Text size={TextSize.s10} inheritColor>
        {children}
      </Text>
    </KeyWrapper>
  );
}
