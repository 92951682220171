import {
  appendDefaultSearchParams,
  useDefaultCommonSearchParams,
} from '@yarmill/utils';
import { Location } from 'history';
import { useCallback } from 'react';
import { LinkCallback } from '../../routes/types';

export function useAnalyticsLink(dashboard?: string): LinkCallback {
  const defaultSearchParams = useDefaultCommonSearchParams();

  return useCallback(
    (location: Location) => {
      const searchParams = appendDefaultSearchParams(
        location.search,
        defaultSearchParams
      );

      return `/analytics/${
        dashboard || 'general.defaultDashboard'
      }?${searchParams}`;
    },
    [defaultSearchParams, dashboard]
  );
}
