import { UserGroupId, UserId } from '@yarmill/types';
import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { PlannerEvent } from '../types';

export interface DeleteEventRequestParams {
  readonly eventId: number;
  readonly userId: UserId | null;
  readonly userGroupId: UserGroupId | null;
}

export function deleteEvent(
  { eventId, userId, userGroupId }: DeleteEventRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<PlannerEvent[]> {
  return axios.delete(`${coreApiTarget}/api/planner`, {
    params: {
      eventId,
      userId: userId || undefined,
      userGroupId: userId ? undefined : userGroupId || undefined,
      instanceCode: devMode ? instance : undefined,
    },
    cancelToken: cancelToken?.token,
  });
}
