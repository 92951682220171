import { Icon, IconSize, Tippy } from '@yarmill/components';
import { Button, ButtonAppearance } from '@yarmill/components-2';
import { Pastebox } from '@yarmill/icons-2';
import { useIntl } from 'react-intl';

interface ShowImporterProps {
  readonly show: () => void;
}
export function ShowImporter({ show }: ShowImporterProps): JSX.Element {
  const intl = useIntl();
  return (
    <Tippy tooltipContent="planner.showImporter" noWrapper touch={false}>
      <Button
        $appearance={ButtonAppearance.Secondary}
        $appearanceStyle="neutral"
        $iconOnly
        onClick={show}
        aria-label={intl.formatMessage({
          id: 'planner.showImporter',
        })}
        type="button"
      >
        <Icon size={IconSize.s24}>
          <Pastebox />
        </Icon>
      </Button>
    </Tippy>
  );
}
