import {
  ErrorBox,
  ExpiredTokenLayout,
  FormattedHTMLMessage,
} from '@yarmill/components';
import { useIntl } from 'react-intl';

export function ExpiredToken(): JSX.Element {
  const intl = useIntl();

  return (
    <ExpiredTokenLayout>
      <ErrorBox>
        <FormattedHTMLMessage
          id="app.tokenExpired"
          values={{
            adminEmail: intl.formatMessage({ id: 'ytd.instance.adminEmail' }),
            adminName: intl.formatMessage({ id: 'ytd.instance.adminName' }),
          }}
        />
      </ErrorBox>
    </ExpiredTokenLayout>
  );
}
