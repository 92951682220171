import {
  StyledTr,
  Table,
  Text,
  TextSize,
  WhiteSpace,
  styled,
} from '@yarmill/components';
import { times } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { CsvTableCell } from './csv-table-cell';
import { CsvTableFirstColumnCell } from './csv-table-first-column-cell';
import { CsvTableHeaderCell } from './csv-table-header-cell';
import { ExpandableTree } from './mobx/expandable-tree';
import {
  CsvTableConfiguration,
  CsvTableFirstColumnItem,
  CsvTableHeader,
  CsvTableRowData,
  CsvTable as ICsvTable,
} from './types';

export interface CsvTableProps {
  table: ICsvTable;
  data: CsvTableRowData[] | undefined;
  expandable?: ExpandableTree<CsvTableFirstColumnItem> | undefined;
}

const CsvTableTr = styled(StyledTr)<{ isExpandable?: boolean }>`
  height: calc(24px + 2 * 4px);
  ${props => props.isExpandable && 'cursor: pointer;'};

  @media print {
    height: auto;
  }
`;

function getColWidth(
  config: CsvTableConfiguration,
  colIdx: number,
  header: CsvTableHeader[]
): string | number {
  const predefinedWidth = header[colIdx]?.Width;

  if (predefinedWidth !== undefined && predefinedWidth !== null) {
    return predefinedWidth;
  }

  const baseWidth = config.HasFirstColumn ? 80 : 100;

  return `${baseWidth / config.ColumnsCount}%`;
}

export const CsvTable = observer<CsvTableProps, HTMLTableElement>(
  forwardRef<HTMLTableElement, CsvTableProps>(
    function CsvTable(props, ref): JSX.Element {
      const { table, data, expandable } = props;
      const config = table.Configuration;
      const firstColumn = table.FirstColumn;
      const rowsCount = table.Configuration.RowsCount || data?.length || 0;
      const hasExpandableControls = Boolean(expandable);

      return (
        <Table
          ref={ref}
          colgroup={
            <>
              {config.HasFirstColumn && <col />}
              {times(config.ColumnsCount).map((_, idx) => (
                <col key={idx} width={getColWidth(config, idx, table.Header)} />
              ))}
            </>
          }
          head={
            config.HasHeader ? (
              <>
                {config.HasFirstColumn && (
                  <CsvTableHeaderCell
                    config={config}
                    alignment="left"
                    hasExpandableControls={hasExpandableControls}
                  >
                    <Text
                      size={TextSize.s12}
                      bold
                      whiteSpace={WhiteSpace.preWrap}
                    >
                      {table.Configuration.Title && (
                        <FormattedMessage id={table.Configuration.Title} />
                      )}
                    </Text>
                  </CsvTableHeaderCell>
                )}
                {table.Header.map((column, idx) => (
                  <CsvTableHeaderCell
                    key={idx}
                    config={config}
                    column={column}
                    alignment={column.HeaderAlignment}
                    hasExpandableControls={hasExpandableControls}
                  />
                ))}
              </>
            ) : undefined
          }
        >
          {times(rowsCount).map(
            (_, rowIdx) =>
              (!expandable || expandable.isNodeVisible(rowIdx)) && (
                <CsvTableTr
                  key={rowIdx}
                  isExpandable={expandable?.isNodeExpandable(rowIdx)}
                  onClick={
                    expandable?.isNodeExpandable(rowIdx)
                      ? () =>
                          expandable.isNodeExpanded(rowIdx)
                            ? expandable?.collapseNode(rowIdx)
                            : expandable?.expandNode(rowIdx)
                      : undefined
                  }
                >
                  {config.HasFirstColumn && (
                    <CsvTableFirstColumnCell
                      item={firstColumn[rowIdx]}
                      labelIsTranslateString={config.ColumnLabels}
                      expandable={expandable}
                      rowIndex={rowIdx}
                      alignment={
                        table.FirstColumn[rowIdx]?.ValueAlignment ||
                        table.Header[rowIdx]?.ValueAlignment
                      }
                    />
                  )}
                  {times(config.ColumnsCount).map((_, columnIdx) => (
                    <CsvTableCell
                      key={columnIdx}
                      unit={
                        config.HasFirstColumn
                          ? table.FirstColumn[rowIdx]?.DataType
                          : table.Header[columnIdx]?.DataType
                      }
                      format={
                        config.HasFirstColumn
                          ? table.FirstColumn[rowIdx]?.Format
                          : table.Header[columnIdx]?.Format
                      }
                      data={
                        data?.[rowIdx]?.[
                          table.Header[columnIdx]?.ColumnName || ''
                        ]
                      }
                      alignment={
                        config.HasFirstColumn
                          ? table.FirstColumn[rowIdx]?.ValueAlignment
                          : table.Header[columnIdx]?.ValueAlignment
                      }
                      translateValue={
                        config.HasFirstColumn
                          ? table.FirstColumn[rowIdx]?.TranslateValue
                          : table.Header[columnIdx]?.TranslateValue
                      }
                    />
                  ))}
                </CsvTableTr>
              )
          )}
        </Table>
      );
    }
  )
);
