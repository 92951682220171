import {
  Button,
  ButtonAppearance,
  FormattedHTMLMessage,
  SimplePageLayout,
  Text,
  TextAlignment,
  TextSize,
  TextTag,
  toast,
} from '@yarmill/components';
import { useConfig, useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useLocation } from 'react-router-dom';
import { connectApp } from './api/connect-app';
import { ButtonsWrapper, ConnectPageLayout } from './components/connect';

export const Connect = observer(function Connect() {
  const rootStore = useRootStore();
  const availableApps = useConfig('externalApps');
  const intl = useIntl();
  const { search } = useLocation();

  if (!rootStore.isReady) {
    return <SimplePageLayout />;
  }

  const searchParams = new URLSearchParams(search);
  const params = Object.fromEntries(searchParams.entries()) as {
    readonly appCode: string;
    readonly origin: string;
    readonly originUserId: string;
    readonly originToken: string;
  };

  const app = availableApps.find(app => app.code === params.appCode);

  if (!app) {
    return <Redirect to="/not-found" />;
  }

  async function onClick(isAccepted: boolean) {
    const request = rootStore.requestsStore.createRequest(cancelToken =>
      connectApp(
        {
          ...params,
          isAccepted,
        },
        cancelToken
      )
    );

    const response = await request.getResponse();

    if (request.statusCode === 200 && response) {
      window.location.href = response;
    } else {
      toast('toast.error.externalServices.connectApp', 'error', {
        name: app?.name ? intl.formatMessage({ id: app.name }) : '',
      });
    }
  }

  function onAllowClick() {
    onClick(true);
  }

  function onDiscardClick() {
    onClick(false);
  }

  return (
    <SimplePageLayout>
      <ConnectPageLayout>
        <Text
          tag={TextTag.h1}
          size={TextSize.s24}
          textAlign={TextAlignment.center}
        >
          <FormattedMessage id={app.name} />
        </Text>
        <Text size={TextSize.s16} textAlign={TextAlignment.left}>
          <FormattedHTMLMessage id={app.description} />
        </Text>
        <ButtonsWrapper>
          <Button
            onClick={onDiscardClick}
            appearance={ButtonAppearance.Secondary}
          >
            <FormattedMessage id="externalServices.connectApp.discard" />
          </Button>
          <Button onClick={onAllowClick} appearance={ButtonAppearance.Primary}>
            <FormattedMessage id="externalServices.connectApp.allow" />
          </Button>
        </ButtonsWrapper>
      </ConnectPageLayout>
    </SimplePageLayout>
  );
});
