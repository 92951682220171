import {
  LayerOptions,
  LayerRenderer,
  LayerType,
  LayerManagerService as iLayerManagerService,
} from '@yarmill/types';
import { IObservableArray, action, makeObservable, observable } from 'mobx';
import { Layer } from './layer';

export class LayerManagerService implements iLayerManagerService {
  @observable
  private _layers: IObservableArray<Layer> = observable.array();

  constructor() {
    makeObservable(this);
  }

  get layers(): Layer[] {
    return this._layers;
  }

  @action
  private readonly removeLayer = (layer: Layer): void => {
    this._layers.remove(layer);
  };

  @action
  openLayer(
    type: LayerType,
    render: LayerRenderer,
    options: LayerOptions
  ): Layer {
    const layer = new Layer(type, render, this.removeLayer, options);
    this._layers.push(layer);

    return layer;
  }
}
