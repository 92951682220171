import {
  forwardRef,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
} from 'react';
import styled from 'styled-components';

type AthletesSidebarDropdownToggleProps = PropsWithChildren<{
  readonly label: ReactNode;
  readonly primaryRow: ReactNode;
  readonly secondaryRow?: ReactNode;
  readonly 'data-cy'?: string;
  readonly onClick: MouseEventHandler<HTMLButtonElement>;
}>;

const DropdownToggle = styled.button`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 768px) {
    text-align: center;
    align-items: center;
    row-gap: unset;
  }
`;

const DropdownToggleLabel = styled.span`
  font-family: 'Ubuntu';
  font-size: 10px;
  color: #9a9a9a;
  text-transform: uppercase;

  @media (min-width: 768px) {
    display: none;
  }
`;

const PrimaryRow = styled.span`
  font-family: 'Ubuntu';
  font-size: 14px;
  color: #4a90e2;
  text-decoration: underline;
  height: 20px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 768px) {
    color: #4a4a4a;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    height: unset;
  }
`;

const SecondaryRow = styled.span`
  display: none;
  font-family: Ubuntu;

  @media (min-width: 768px) {
    display: inline;
    font-size: 12px;
  }
`;

export const GroupsToggleWrapper = styled.span`
  display: flex;
  align-items: center;
  column-gap: 12px;

  & > :not(:first-child) {
    display: none;

    @media (min-width: 768px) {
      display: inline-flex;
    }
  }
`;

export const GroupNameWrapper = styled.span`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SelectedAthleteWrapper = styled.span`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  column-gap: 8px;
`;

export const SelectAthleteAvatar = styled.img`
  width: auto;
  height: 100%;
`;

export const AthletesSidebarDropdownToggle = forwardRef<
  HTMLButtonElement,
  AthletesSidebarDropdownToggleProps
>(function AthletesSidebarDropdownToggle(
  { label, primaryRow, secondaryRow, onClick, 'data-cy': dataCy },
  ref
): JSX.Element {
  return (
    <DropdownToggle onClick={onClick} ref={ref} data-cy={dataCy}>
      <DropdownToggleLabel>{label}</DropdownToggleLabel>
      <PrimaryRow>{primaryRow}</PrimaryRow>
      <SecondaryRow>{secondaryRow}</SecondaryRow>
    </DropdownToggle>
  );
});
