import moment from 'moment';

import { useMergeRefs } from '@floating-ui/react';
import { Color, css, styled } from '@yarmill/components';
import { PropsWithChildren, forwardRef, useRef } from 'react';
import { Calendar } from './calendar';
import { MonthIndexContextProvider } from './month-index-context';
import { PlannerContext } from './planner-context';
import {
  OnEventCreatedCallback,
  useCreateEventHandlers,
} from './use-create-event-handlers';
import {
  PlannerZoomLevel,
  getColumnsDefinition,
  getNumberOfColumns,
  getZoomLevelHeight,
} from './utils';
import { ZoomLevelContext } from './zoom-level-context';

const NUMBER_OF_DAYS = 31;
export const PlannerContainer = styled.div`
  display: grid;
  grid-template-columns: 1px auto 1px;
  background-color: ${({ theme }) => theme.color.white};
  padding: 0 ${({ theme }) => theme.size.x3} ${({ theme }) => theme.size.x025};
  margin-right: ${({ theme }) => theme.size.x1};
  overflow: auto;
  scroll-padding-left: ${({ theme }) => theme.size.x2};
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  transition: all 250ms ease;
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
`;

interface StyledPlannerProps {
  readonly columns: number;
  readonly allowInteraction: boolean;
  readonly zoomLevel: PlannerZoomLevel;
  readonly conflictsCountPerMonth: number[];
}

const StyledPlanner = styled.div<StyledPlannerProps>`
  box-sizing: border-box;
  display: grid;
  width: 100%;
  min-height: 100%;
  grid-template-rows:
    minmax(${({ zoomLevel }) => getZoomLevelHeight(zoomLevel)}px, 1fr)
    repeat(
      ${NUMBER_OF_DAYS},
      minmax(${({ zoomLevel }) => getZoomLevelHeight(zoomLevel)}px, 1fr)
    );
  user-select: none;

  transition: all 250ms ease;
  grid-template-columns: ${({ columns, zoomLevel, conflictsCountPerMonth }) =>
    getColumnsDefinition(columns, zoomLevel, conflictsCountPerMonth)};

  ${({ zoomLevel, columns }) =>
    zoomLevel === 2 &&
    css`
      @media (max-width: 767px) {
        grid-template-columns: repeat(${columns}, 100vw);
      }
    `}

  ${props =>
    !props.allowInteraction &&
    css`
      .planner-event {
        pointer-events: none;
      }
    `}

  &[data-isEdited] {
    .planner-event {
      pointer-events: none;
    }
  }
`;

type PlannerProps = PropsWithChildren<{
  readonly startDate: moment.Moment;
  readonly endDate: moment.Moment;
  readonly onCreate: OnEventCreatedCallback;
  readonly newEventLabel: string;
  readonly zoomLevel: PlannerZoomLevel;
  readonly disableAdd?: boolean;
  readonly lastColor?: Color;
  readonly conflictsCountPerMonth: number[];
  readonly onCreateStart?: () => void;
  readonly language: string;
}>;

export const Planner = forwardRef<HTMLDivElement, PlannerProps>(
  function Planner(props, passedRef): JSX.Element {
    const {
      startDate,
      endDate,
      onCreate,
      children,
      newEventLabel,
      zoomLevel,
      disableAdd,
      lastColor,
      conflictsCountPerMonth,
      onCreateStart,
      language,
    } = props;
    const numberOfColumns = getNumberOfColumns(startDate, endDate);
    const { newEvent, onMouseDown, onMouseOver, onDoubleClick } =
      useCreateEventHandlers(
        onCreate,
        newEventLabel,
        disableAdd,
        lastColor,
        onCreateStart
      );
    const plannerRef = useRef<HTMLDivElement>(null);
    const ref = useMergeRefs([plannerRef, passedRef]);

    return (
      <MonthIndexContextProvider startDate={startDate} endDate={endDate}>
        <PlannerContext.Provider value={plannerRef}>
          <ZoomLevelContext.Provider value={zoomLevel}>
            <StyledPlanner
              ref={ref}
              allowInteraction={!newEvent}
              columns={numberOfColumns}
              onMouseDown={onMouseDown}
              onMouseOver={onMouseOver}
              onDoubleClick={onDoubleClick}
              zoomLevel={zoomLevel}
              conflictsCountPerMonth={conflictsCountPerMonth}
            >
              <Calendar
                language={language}
                startDate={startDate}
                endDate={endDate}
                numberOfDays={NUMBER_OF_DAYS}
                headerHeight={getZoomLevelHeight(zoomLevel)}
                showLongMonthLabel={zoomLevel !== 0}
              />
              {children}
              {newEvent}
            </StyledPlanner>
          </ZoomLevelContext.Provider>
        </PlannerContext.Provider>
      </MonthIndexContextProvider>
    );
  }
);
