import {
  ExternalIcon,
  HealthDataOverviewRow,
  Tippy,
} from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import { HeartRateVariabilityData } from '../../training-day/types';

export interface HeartRateVariabilityProps {
  value: HeartRateVariabilityData;
}
export function HeartRateVariability(
  props: HeartRateVariabilityProps
): JSX.Element {
  const { value } = props;
  const validatedValue = {
    AvgHRV: '-',
    MaxHRV: '-',
    Label: '-',
    ...value,
  };

  return (
    <HealthDataOverviewRow
      icon={<ExternalIcon name="ActivityHeartbeat" />}
      label={
        value.Label ? <FormattedMessage id={value.Label} /> : 'Missing Label'
      }
      primaryValue={
        <FormattedMessage id="healthData.hrv.value" values={validatedValue} />
      }
      secondaryValues={[
        value.MaxHRV && (
          <Tippy tooltipContent="healthData.hrv.max.tooltip">
            <FormattedMessage id="healthData.hrv.max" values={validatedValue} />
          </Tippy>
        ),
      ].filter(Boolean)}
    />
  );
}
