import { DATE_FORMAT } from '@yarmill/const';
import { Season } from '@yarmill/types';
import moment from 'moment';

export function getSeasonMonths(season: Season): string[] {
  const start = moment(season.StartDate).date(1);
  const end = moment(season.EndDate);
  const current = start;

  const months: string[] = [];
  while (current.diff(end) <= 0) {
    months.push(current.format(DATE_FORMAT));
    current.add(1, 'months');
  }

  return months;
}
