import {
  CurrentUserStore,
  MemoryStore,
  TrainingDiaryViewType,
} from '@yarmill/types';

const viewTypes: TrainingDiaryViewType[] = [
  'week',
  'goals',
  'seasonGoals',
  'season',
];

export function getAllowedViewType(
  diaryType: 'plan' | 'reality',
  currentUser: CurrentUserStore,
  memoryStore: MemoryStore
): TrainingDiaryViewType | undefined {
  const lastViewType = memoryStore.getItem<TrainingDiaryViewType>(diaryType);

  if (lastViewType && currentUser.isAllowedTo(`${diaryType}.${lastViewType}`)) {
    return lastViewType;
  }

  return viewTypes.find(viewType =>
    currentUser.isAllowedTo(`${diaryType}.${viewType}`)
  );
}
