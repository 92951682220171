import { ExternalIconName } from '@yarmill/types';
import { ExternalIcon } from './external-icon';

interface YarmillIconProps {
  readonly name?: ExternalIconName | null;
}
export function YarmillIcon({ name }: YarmillIconProps) {
  if (name) {
    return <ExternalIcon name={name} />;
  }

  return null;
}
