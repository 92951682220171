import ReactFocusLock, { ReactFocusLockProps } from 'react-focus-lock';
import { forwardRef, useEffect, useState } from 'react';

export const FocusLock = forwardRef<HTMLDivElement, ReactFocusLockProps>(
  (props, ref): JSX.Element => {
    const [focusLockEnabled, setFocusLockEnabled] = useState(true);

    useEffect(() => {
      const onBlur = () => {
        setFocusLockEnabled(false);
      };
      const onFocus = () => {
        setFocusLockEnabled(true);
      };
      window.addEventListener('blur', onBlur);
      window.addEventListener('focus', onFocus);

      return () => {
        window.removeEventListener('blur', onBlur);
        window.removeEventListener('focus', onFocus);
      };
    }, []);

    return <ReactFocusLock disabled={!focusLockEnabled} {...props} ref={ref} />;
  }
);
