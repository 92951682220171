import { UserId } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';
import { HeartRateZonesSet } from '../types';

export function saveHeartRateZones(
  value: HeartRateZonesSet[],
  userId: UserId
): AxiosPromise<boolean> {
  return axios.post(
    `${process.env.REACT_APP_API_ENV_URL}/api/application`,
    value,
    {
      params: {
        appName: 'hrZonesApp',
        userId,
      },
    }
  );
}
