import { Color, Icon, IconSize, styled } from '@yarmill/components';
import { Right } from '@yarmill/icons-2';
import { GenericDropdownItemIcon } from './dropdown-provider/generic-dropdown-item';
import { Text } from './text';

export interface NavigationListItem {
  readonly title: string;
  readonly description?: string;
  readonly icon?: JSX.Element;
  readonly color?: Color;
  readonly onClick?: () => void;
}
interface NavigationListProps {
  readonly items: NavigationListItem[];
}

const NavigationListLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavigationItemLayout = styled.button`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.size.x2} 1fr ${({ theme }) =>
    theme.size.x2};
  column-gap: ${({ theme }) => theme.size.x05};
  align-items: center;
  padding: ${({ theme }) => theme.size.x1} ${({ theme }) => theme.size.x2};
  border: 0;
  background: transparent;
  color: ${({ theme }) => theme.color.tangerine};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'normal')};

  :hover {
    background-color: ${({ theme }) => theme.color.blackHover};
  }
`;

const RightIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.neutral};
`;
const DescriptionWrapper = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  color: ${({ theme }) => theme.color.neutralDark};
`;

export function NavigationList({ items }: NavigationListProps): JSX.Element {
  return (
    <NavigationListLayout>
      {items.map(item => (
        <NavigationItemLayout
          key={item.title}
          onClick={item.onClick}
          type="button"
        >
          {item.icon ? (
            <Icon size={IconSize.s16}>{item.icon}</Icon>
          ) : (
            <GenericDropdownItemIcon color={item.color ?? 'white'} />
          )}
          <Text appearance="text12strong" inheritColor ellipsis>
            {item.title}
          </Text>
          <RightIcon size={IconSize.s16}>
            <Right />
          </RightIcon>
          {item.description && (
            <DescriptionWrapper>
              <Text appearance="label10" inheritColor>
                {item.description}
              </Text>
            </DescriptionWrapper>
          )}
        </NavigationItemLayout>
      ))}
    </NavigationListLayout>
  );
}
