import { FormattedMessage } from 'react-intl';
import { Controls } from './controls';
import { PageHeader, Text, TextSize, TextTag } from '@yarmill/components';

export function SingleSeasonEvaluationHeader(): JSX.Element {
  return (
    <PageHeader>
      <Text tag={TextTag.h1} size={TextSize.s24}>
        <FormattedMessage id="seasonEvaluation.heading" />
      </Text>
      <Controls />
    </PageHeader>
  );
}
