import { useRootStore } from '@yarmill/utils';
import {
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { FilesOverviewStore } from './mobx/files-overview-store';

export const FilesOverviewStoreContext = createContext<
  FilesOverviewStore | undefined
>(undefined);

export function useFilesOverviewStore(): FilesOverviewStore {
  const store = useContext(FilesOverviewStoreContext);
  if (!store) {
    throw new Error('FilesOverview Store not available');
  }

  return store;
}

export function InternalFilesOverviewStoreContextProvider(
  props: PropsWithChildren
): JSX.Element {
  const rootStore = useRootStore();
  const store = useRef(new FilesOverviewStore(rootStore)).current;

  useEffect(() => {
    return () => {
      store.disposeReactions();
    };
  }, [store]);

  return (
    <FilesOverviewStoreContext.Provider value={store}>
      {props.children}
    </FilesOverviewStoreContext.Provider>
  );
}

export const FilesOverviewStoreContextProvider = memo(
  InternalFilesOverviewStoreContextProvider
);
