import { Editor } from '@tiptap/react';
import { Layer } from '@yarmill/types';
import { FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AlertLayerCloseButtonWrapper,
  AlertLayerContent,
} from '../../alert-layer';
import { Button, ButtonAppearance } from '../../button';
import { CloseButton } from '../../close-button';
import { Text, TextSize, TextTag } from '../../text';
import { TextInput } from '../../text-input';
import { getSelectedText } from './get-selected-text';

interface LinkAlertProps {
  layer: Layer;
  editor: Editor;
  onSave: (text: string, link: string) => void;
}

function getLink(editor: Editor): string {
  return editor.getAttributes('link').href ?? '';
}

export function LinkAlert(props: LinkAlertProps): JSX.Element {
  const { layer, editor, onSave } = props;
  const intl = useIntl();
  const [text, setText] = useState(getSelectedText(editor));
  const [link, setLink] = useState(getLink(editor));

  function handleSave(e: FormEvent): void {
    e.preventDefault();
    e.stopPropagation();
    onSave(text, link);
    layer.close();
  }

  return (
    <AlertLayerContent.AlertLayerContainer>
      <AlertLayerContent.StickyWrapper>
        <AlertLayerCloseButtonWrapper>
          <CloseButton onClick={layer.close} hideText />
        </AlertLayerCloseButtonWrapper>
      </AlertLayerContent.StickyWrapper>
      <AlertLayerContent.ContentWrapper onSubmit={handleSave}>
        <AlertLayerContent.HeadlineWrapper>
          <Text tag={TextTag.h2} size={TextSize.s16}>
            <FormattedMessage id="richtext.link.headline" />
          </Text>
        </AlertLayerContent.HeadlineWrapper>
        <div>
          <TextInput
            id="text"
            label={intl.formatMessage({ id: 'richtext.link.text' })}
            value={text}
            onChange={e => setText(e.target.value)}
            noError
          />
        </div>
        <div>
          <TextInput
            id="link"
            label={intl.formatMessage({ id: 'richtext.link.link' })}
            value={link}
            onChange={e => setLink(e.target.value)}
            noError
          />
        </div>
        <AlertLayerContent.ButtonsWrapper>
          <Button
            type="button"
            appearance={ButtonAppearance.Link}
            noShadow
            data-cy="cancel"
            onClick={layer.close}
          >
            <FormattedMessage id="richtext.link.button.cancel" />
          </Button>
          <Button
            type="submit"
            appearance={ButtonAppearance.Primary}
            noShadow
            data-cy="save"
          >
            <FormattedMessage id="richtext.link.button.save" />
          </Button>
        </AlertLayerContent.ButtonsWrapper>
      </AlertLayerContent.ContentWrapper>
    </AlertLayerContent.AlertLayerContainer>
  );
}
