import styled from 'styled-components';
import { FieldSet } from '../form';
import { StyledTh } from '../table';
import { StyledFullScreenLayerContent } from '../fullscreen-layer';

export const GroupsHeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const GroupsHeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 4px;
`;

export const AddUsersToGroupsFieldset = styled(FieldSet)`
  display: grid;
  grid-template-columns: 100%;
  gap: 24px;

  @media (min-width: 768px) {
    align-items: center;
    grid-template-columns: 2fr 1fr;
  }
`;

export const UsersInGroupLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
`;

export const UsersInGroupTh = styled(StyledTh)`
  vertical-align: middle;
`;

export const AddGroupLayout = styled(StyledFullScreenLayerContent)`
  @media (min-width: 768px) {
    max-width: 1000px;
    margin: 0 auto;
  }
`;

export const AddGroupFieldSet = styled(FieldSet)`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-top: 20px;
`;

export const AddGroupButtonsContainer = styled.div`
  display: flex;
  column-gap: 12px;
  justify-content: flex-end;
  padding-top: 16px;
`;

export const AddGroupButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(237, 240, 245);

  @media (min-width: 768px) {
    position: sticky;
    bottom: -1px;
    padding: 15px 0 25px;
  }
`;
