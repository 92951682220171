import {
  AsyncStatus,
  RequestStore,
  TrainingDayAttributesStore as iTrainingDayAttributesStore,
} from '@yarmill/types';
import { RootStore } from '@yarmill/types';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  TrainingDayAttribute,
  TrainingDayAttributeId,
} from '../../training-day/types';
import { getTrainingDayAttributes } from '../api/get-training-day-attributes';

export class TrainingDayAttributesStore implements iTrainingDayAttributesStore {
  private readonly rootStore: RootStore;
  private readonly memoryStoreKey = 'training-day-attributes';

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  private request: RequestStore<TrainingDayAttribute[]> | null = null;

  @observable
  private _planAttributes = new Map<
    TrainingDayAttributeId,
    TrainingDayAttribute
  >();

  @observable
  private _realityAttributes = new Map<
    TrainingDayAttributeId,
    TrainingDayAttribute
  >();

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.loadTrainingDayAttributes();
  }

  async loadTrainingDayAttributes(): Promise<void> {
    const cachedResponse = this.rootStore.memoryStore.getItem<
      TrainingDayAttribute[]
    >(this.memoryStoreKey);

    if (cachedResponse) {
      this.setTrainingDayAttributes(cachedResponse);
      this._status = AsyncStatus.resolved;
      return;
    }

    this.request = this.rootStore.requestsStore.createRequest(() =>
      getTrainingDayAttributes()
    );

    const response = await this.request.getResponse();
    if (response) {
      runInAction(() => {
        this.rootStore.memoryStore.setItem<TrainingDayAttribute[]>(
          this.memoryStoreKey,
          response
        );
        this.setTrainingDayAttributes(response);
        this._status = AsyncStatus.resolved;
      });
    } else {
      this._status = AsyncStatus.rejected;
      throw this.request.error;
    }
  }

  @action
  setTrainingDayAttributes(
    trainingDayAttributes: TrainingDayAttribute[]
  ): void {
    trainingDayAttributes.forEach(item => {
      if (item.Type === 'P') {
        this._planAttributes.set(item.AttributeItemId, item);
      } else {
        this._realityAttributes.set(item.AttributeItemId, item);
      }
    });
  }
  @computed
  get planAttributes(): TrainingDayAttribute[] {
    return Array.from(this._planAttributes.values()).sort(
      (a, b) => a.SortCode - b.SortCode
    );
  }

  @computed
  get realityAttributes(): TrainingDayAttribute[] {
    return Array.from(this._realityAttributes.values()).sort(
      (a, b) => a.SortCode - b.SortCode
    );
  }

  get status(): AsyncStatus {
    return this._status;
  }
}
