import { RightPanelContent } from '@yarmill/components-2';
import { useFormikContext } from 'formik';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { AttributesSelector } from '../attributes-selector';
import { PlannerEvent, PlannerEventFormStep } from '../types';
import { FormStepHeader } from './form-step-header';

interface AttributesStepProps {
  readonly setCurrentStep: Dispatch<SetStateAction<PlannerEventFormStep>>;
}
export function AttributesStep({
  setCurrentStep,
}: AttributesStepProps): JSX.Element {
  const formik = useFormikContext<PlannerEvent>();
  const selectedAttributes = formik.values.attributes;

  const onChange = useCallback(
    (selectedAttributes: (number | string)[]) => {
      formik.setFieldValue('attributes', selectedAttributes);
    },
    [formik]
  );

  const onEsc = useCallback(() => {
    setCurrentStep('details');
  }, [setCurrentStep]);

  return (
    <>
      <FormStepHeader />
      <RightPanelContent noPadding>
        <AttributesSelector
          selectedAttributes={selectedAttributes}
          onChange={onChange}
          onEsc={onEsc}
        />
      </RightPanelContent>
    </>
  );
}
