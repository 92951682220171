import {
  FormattedHTMLMessage,
  Text,
  TextSize,
  styled,
} from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { SummaryAttendanceItem } from './types';

export interface PopoverProps {
  attendanceItem?: SummaryAttendanceItem;
}

const StyledPopoverTableWrapper = styled.div`
  padding: 8px;
`;

const StyledPopoverTable = styled.table`
  width: 100%;
`;

const StyledTh = styled.th`
  text-align: left;
  padding: 4px;
`;

const StyledTd = styled.th`
  text-align: right;
  padding: 4px;
  padding-left: 16px;
`;

export const Popover = observer(function Popover(
  props: PopoverProps
): JSX.Element {
  const { attendanceItem } = props;

  return (
    <StyledPopoverTableWrapper>
      <StyledPopoverTable>
        <tbody>
          <tr>
            <StyledTh>
              <Text inheritColor size={TextSize.s14}>
                <FormattedHTMLMessage id="attendance.tooltip.present" />
              </Text>
            </StyledTh>
            <StyledTd>
              <Text inheritColor size={TextSize.s14}>
                {attendanceItem ? attendanceItem.Present : 0}
              </Text>
            </StyledTd>
          </tr>
          <tr>
            <StyledTh>
              <Text inheritColor size={TextSize.s14}>
                <FormattedHTMLMessage id="attendance.tooltip.missed" />
              </Text>
            </StyledTh>
            <StyledTd>
              <Text inheritColor size={TextSize.s14}>
                {attendanceItem ? attendanceItem.Missed : 0}
              </Text>
            </StyledTd>
          </tr>
        </tbody>
      </StyledPopoverTable>
    </StyledPopoverTableWrapper>
  );
});
