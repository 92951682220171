import { styled } from '@yarmill/components';

export const ObjectiveValidationButtonWrapper = styled.div`
  padding: ${({ theme }) => theme.size.x1};
  border-top: 1px solid ${({ theme }) => theme.color.blackHover};
  border-bottom: 1px solid ${({ theme }) => theme.color.blackHover};
  margin-bottom: ${({ theme }) => theme.size.x2};
`;

export const ObjectiveValidationWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.size.x1};
`;
