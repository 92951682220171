import { Icon, IconSize, Tippy } from '@yarmill/components';
import { Button, ButtonAppearance, Text } from '@yarmill/components-2';
import { Validation } from '@yarmill/icons-2';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { getScoreAppearance } from '../components/detail/rating-score';
import { ObjectiveStore } from '../mobx/objective-store';
import { useOkrsStore } from '../okrs-store-context';
import { calculateObjectiveValidationScore } from '../utils';

interface RatingProps {
  readonly objective?: ObjectiveStore;
}
export const Rating = observer(function Rating({
  objective,
}: RatingProps): JSX.Element {
  const intl = useIntl();
  const score = calculateObjectiveValidationScore(objective);
  const okrsStore = useOkrsStore();
  async function handleClick() {
    const currentPanel = okrsStore.visibleSidePanel;
    okrsStore.setVisibleRightPanel(
      currentPanel === 'validation' ? null : 'validation'
    );
  }

  return (
    <Tippy
      tooltipContent="okrs.detail.keyResults.ratingButtonLabel"
      noWrapper
      touch={false}
    >
      <Button
        $appearance={ButtonAppearance.Primary}
        $appearanceStyle={getScoreAppearance(score)}
        onClick={objective ? handleClick : undefined}
        $iconOnly
        disabled={!objective}
        aria-label={intl.formatMessage({
          id: 'okrs.detail.keyResults.ratingButtonLabel',
        })}
      >
        <Icon size={IconSize.s24}>
          <Validation />
        </Icon>
        <Text appearance="text15strong" inheritColor>
          {score}%
        </Text>
      </Button>
    </Tippy>
  );
});
