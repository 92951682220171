import { EvidenceModuleKey, EvidenceObject } from '@yarmill/types';
import { makeObservable } from 'mobx';
import { EvidenceReportItem } from '../types';
import { BaseReportStore } from './base-report-store';

export class EvidenceReportStore extends BaseReportStore {
  private readonly _definition: EvidenceObject[];
  private readonly _moduleKey: EvidenceModuleKey;
  private readonly _dataUrl: string | undefined;

  constructor(item: EvidenceReportItem) {
    super(item);
    makeObservable(this);
    this._definition = item.EvidenceDefinition.filter(Boolean);
    this._moduleKey = item.Params?.ModuleKey ?? '';
    this._dataUrl = item.Params?.EvidenceDataUrl;
  }

  public get definition() {
    return this._definition;
  }

  public get moduleKey() {
    return this._moduleKey;
  }

  public get dataUrl() {
    return this._dataUrl;
  }
}
