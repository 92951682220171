import { AsyncStatus, RequestStore as iRequestStore } from '@yarmill/types';
import axios, { AxiosPromise, CancelTokenSource } from 'axios';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { GlobalLogger } from '../logger';

export class RequestStore<T> implements iRequestStore<T> {
  @observable
  status: AsyncStatus = AsyncStatus.idle;

  private readonly request: AxiosPromise<T>;

  private readonly cancelToken: CancelTokenSource;

  private _error: Error | undefined;

  private _canceled: boolean = false;

  private _statusCode: number | undefined = undefined;

  constructor(request: (cancelToken: CancelTokenSource) => AxiosPromise<T>) {
    makeObservable(this);
    this.cancelToken = axios.CancelToken.source();
    this.request = request(this.cancelToken);
    this.status = AsyncStatus.pending;
  }

  @action
  cancel(): void {
    this.status = AsyncStatus.idle;
    this._canceled = true;
    this.cancelToken.cancel();
  }

  get error(): Error | undefined {
    return this._error;
  }

  get statusCode(): number | undefined {
    return this._statusCode;
  }

  async getResponse(): Promise<T | undefined> {
    try {
      const response = await this.request;
      const { data, status } = response;

      this._statusCode = status;
      runInAction(() => {
        this.status = AsyncStatus.resolved;
      });

      return data;
    } catch (e: unknown) {
      runInAction(() => {
        this._error = e as Error;
        if (axios.isAxiosError(e)) {
          this._statusCode = e.response?.status;
        }

        if (this.status !== AsyncStatus.idle) {
          this.status = AsyncStatus.rejected;

          if (axios.isAxiosError(e)) {
            const status = e.response?.status ?? 0;

            if (
              (status >= 500 || status <= 100) &&
              status !== 0 &&
              status !== 401
            ) {
              if (!this._canceled) {
                GlobalLogger.error(
                  `Request failed: ${e.code}`,
                  e.message,
                  `${e.config?.method?.toUpperCase()} ${e.config?.baseURL}${
                    e.config?.url
                  }`
                );
              }
            }
          }
        }
      });
    }
  }

  get wasCanceled(): boolean {
    return this._canceled;
  }
}
