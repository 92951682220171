import * as React from 'react';
import styled from 'styled-components';
import { Icon } from '../icon';
import { TextSize, Text } from '../text';
import { ExternalIcon } from '../external-icon';

export type HandleCheckboxEvent = (
  e: React.SyntheticEvent<HTMLElement>,
  props: CheckboxProps
) => void;

export enum CheckboxAppearance {
  Error,
}

export interface CheckboxProps {
  readonly appearance?: CheckboxAppearance;
  readonly errorText?: string;
  readonly checked?: boolean;
  readonly disabled?: boolean;
  readonly label?: string | JSX.Element;
  readonly name?: string;
  readonly onBlur?: HandleCheckboxEvent;
  readonly onChange?: HandleCheckboxEvent;
  readonly onClick?: HandleCheckboxEvent;
  readonly onFocus?: HandleCheckboxEvent;
  readonly required?: boolean;
  readonly value: string;
  readonly id?: string;
  readonly noError?: boolean;
  readonly narrow?: boolean;
}

const noop = () => undefined;

const borderSize = '1px';

const StyledErrorText = styled.span`
  display: block;
  color: #d0021b;
  font-size: 16px;
  padding-left: calc(24px * 2);
`;

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  opacity: 0;
  border: 0;
  border-radius: 8px;
  padding: 2px;
`;

const StyledIconWrapper = styled.div`
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  overflow: hidden;
  position: relative;
  border: ${borderSize} solid #d5d6da;
  border-radius: 8px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledWrapper = styled.span<{ readonly narrow?: boolean }>`
  display: inline-flex;
  align-items: center;
  color: #4a4a4a;
  cursor: pointer;
  column-gap: ${({ narrow }) => (narrow ? '4px' : '20px')};
`;

const StyledInput = styled.input`
  position: absolute;
  left: -200vw;

  :checked {
    + ${StyledWrapper} {
      ${StyledIcon} {
        opacity: 1;
      }
    }
  }

  :checked:not(:disabled) {
    + ${StyledWrapper} {
      ${StyledIconWrapper} {
        border-color: #4a90e2;
        background-color: #4a90e2;
      }
      ${StyledIcon} {
        color: #ffffff;
      }
    }
  }

  :disabled {
    + ${StyledWrapper} {
      color: #e9ecef;
      cursor: not-allowed;

      ${StyledIconWrapper} {
        border-color: #e9ecef;
      }
      ${StyledIcon} {
        fill: #e9ecef;
      }
    }
  }
`;

export const StyledLabelText = styled.span`
  padding-left: 10px;
`;

export class Checkbox extends React.Component<CheckboxProps> {
  public render(): JSX.Element {
    const {
      checked,
      disabled,
      label = '',
      name,
      value,
      required,
      appearance,
      errorText,
      id,
      noError,
      narrow,
    } = this.props;
    const errorShouldBeVisible = Boolean(
      appearance === CheckboxAppearance.Error && errorText
    );

    return (
      <label>
        <StyledInput
          id={id}
          checked={checked}
          disabled={disabled}
          name={name}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onClick={this.handleClick}
          onFocus={this.handleFocus}
          type="checkbox"
          value={value}
          required={required}
        />
        <StyledWrapper narrow={narrow}>
          <StyledIconWrapper>
            <StyledIcon>
              <Icon>
                <ExternalIcon name="Check" />
              </Icon>
            </StyledIcon>
          </StyledIconWrapper>
          {label &&
            (typeof label === 'string' ? (
              <StyledLabelText>
                <Text size={TextSize.s14}>{label}</Text>
              </StyledLabelText>
            ) : (
              label
            ))}
        </StyledWrapper>
        {!noError && (
          <StyledErrorText aria-live="assertive" aria-relevant="all">
            {errorShouldBeVisible && errorText}
          </StyledErrorText>
        )}
      </label>
    );
  }

  protected readonly handleChange = (
    e: React.SyntheticEvent<HTMLElement>
  ): void => {
    const { onChange = noop } = this.props;
    onChange(e, this.props);
  };

  protected readonly handleBlur = (
    e: React.SyntheticEvent<HTMLElement>
  ): void => {
    const { onBlur = noop } = this.props;
    onBlur(e, this.props);
  };

  protected readonly handleFocus = (
    e: React.SyntheticEvent<HTMLElement>
  ): void => {
    const { onFocus = noop } = this.props;
    onFocus(e, this.props);
  };

  protected readonly handleClick = (
    e: React.SyntheticEvent<HTMLInputElement>
  ): void => {
    const { onClick = noop } = this.props;
    e.currentTarget.focus();
    onClick(e, this.props);
  };
}
