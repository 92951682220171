import {
  ChangeEvent,
  ComponentType,
  FormEvent,
  forwardRef,
  useCallback,
  useRef,
} from 'react';
import styled from 'styled-components';

import {
  DateFilter,
  NumberFilter,
  Sort,
  TableFilterType,
  TableFilterValue,
  TimeFilter,
} from '@yarmill/types';
import { Button, ButtonAppearance } from '../button';
import { ContentBox } from '../content-box';
import { ExternalIcon } from '../external-icon';
import { Breakpoints } from '../helpers';
import { useEnhancedState } from '../helpers/use-enhanced-state';
import { Icon, IconSize } from '../icon';
import { Text, TextSize, TextTag } from '../text';
import { TextInput } from '../text-input';
import { DateFilters } from './date-filters';
import { NumberFilters } from './number-filters';
import { OptionsFilter } from './options-filter';
import { TimeFilters } from './time-filters';
import { formatFilterValue } from './utils';

export * from './utils';

export interface TableFilterOption {
  label: string;
  value: string;
}

export interface TableFiltersProps {
  ascLabel: string;
  descLabel: string;
  filtersLabel: string;
  clearLabel: string;
  applyLabel: string;
  sort?: Sort;
  type?: TableFilterType;
  options?: TableFilterOption[];
  value?: TableFilterValue;
  hideFilters?: boolean;
  onApply?(value: TableFilterValue, sort: Sort): void;
  onClear?(): void;
  onClose?(): void;
  inputComponent?: ComponentType<any>;
  translate?(key: string): string;
  format?: string;
}

const StyledTableFilters = styled(ContentBox)`
  text-align: left;
  border: 1px solid #e9eff4;
  width: 75vw;
  max-height: calc(var(--100vh, 100vh) - 120px);
  overflow-y: auto;

  @media (min-width: ${Breakpoints.tablet - 1}px) {
    width: 230px;
  }
`;

const SortButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  border-bottom: 1px solid #e5e5e5;
`;

const FiltersWrapper = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const StyledCloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 14px;
`;

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  padding-top: 12px;
  flex-direction: row-reverse;
`;

const StyledSortButton = styled(Button)<{ readonly isActive?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  ${props => props.isActive && 'color: #4a90e2;'}
`;

const StyledSortSymbol = styled.span`
  display: flex;
  align-items: center;
`;

const StyledSortIcon = styled(Icon)`
  display: inline-flex;
  align-items: center;
`;

export const FiltersFloatingContainer = styled.div`
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    top: calc(50% + 25px) !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
`;

function getSortSymbol(
  filterType: TableFilterType,
  symbolType: 'start' | 'end'
): string | number {
  switch (filterType) {
    case 'number':
    case 'time':
    case 'date': {
      if (symbolType === 'start') {
        return 0;
      } else {
        return 1;
      }
    }
    default: {
      if (symbolType === 'start') {
        return 'A';
      } else {
        return 'Z';
      }
    }
  }
}

export const TableFiltersLayout = forwardRef<HTMLDivElement, TableFiltersProps>(
  function InternalTableFilters(
    props: TableFiltersProps,
    passedRef
  ): JSX.Element {
    const {
      ascLabel,
      descLabel,
      filtersLabel,
      options,
      clearLabel,
      applyLabel,
      onClose,
      value,
      onClear,
      onApply,
      sort,
      hideFilters,
      type = 'text',
      inputComponent,
      translate,
      format,
    } = props;

    const internalRef = useRef<HTMLDivElement>(null);
    const ref = passedRef || internalRef;
    const [currentValue, setCurrentValue, getCurrentValue] = useEnhancedState<
      string | string[] | DateFilter | TimeFilter
    >(value ? value : options ? [] : '');

    function onTextFilterChange(e: ChangeEvent): void {
      const { target } = e;
      if (target instanceof HTMLInputElement) {
        const { value } = target;
        setCurrentValue(value);
      }
    }

    function onSortASC(): void {
      if (onApply) {
        onApply(currentValue, 'ASC');
      }
    }

    function onSortDESC(): void {
      if (onApply) {
        onApply(currentValue, 'DESC');
      }
    }

    const handleApply = useCallback(
      function handleApply(): void {
        if (document.activeElement) {
          if (document.activeElement instanceof HTMLInputElement) {
            document.activeElement.blur();
          }
        }

        if (onApply) {
          // timeout is necessary to fire blur events on time filters
          setTimeout(
            () =>
              onApply(formatFilterValue(getCurrentValue(), type), sort || null),
            0
          );
        }
      },
      [getCurrentValue, onApply, sort, type]
    );

    function handleSubmit(e: FormEvent) {
      e.preventDefault();
    }

    return (
      <StyledTableFilters ref={ref}>
        <StyledCloseButtonWrapper>
          <Button
            appearance={ButtonAppearance.Link}
            onClick={onClose}
            autoFocus
          >
            <Icon>
              <ExternalIcon name="X" />
            </Icon>
          </Button>
        </StyledCloseButtonWrapper>
        <SortButtonsWrapper>
          <StyledSortButton
            isActive={sort === 'ASC'}
            appearance={ButtonAppearance.Link}
            onClick={onSortASC}
          >
            {ascLabel}
            <StyledSortSymbol>
              {getSortSymbol(type, 'start')}&nbsp;
              <StyledSortIcon size={IconSize.s10}>
                <ExternalIcon name="ArrowNarrowRight" />
              </StyledSortIcon>
              &nbsp;{getSortSymbol(type, 'end')}
            </StyledSortSymbol>
          </StyledSortButton>
          <StyledSortButton
            isActive={sort === 'DESC'}
            appearance={ButtonAppearance.Link}
            onClick={onSortDESC}
          >
            {descLabel}
            <StyledSortSymbol>
              {getSortSymbol(type, 'end')}&nbsp;
              <StyledSortIcon size={IconSize.s10}>
                <ExternalIcon name="ArrowNarrowRight" />
              </StyledSortIcon>
              &nbsp;{getSortSymbol(type, 'start')}
            </StyledSortSymbol>
          </StyledSortButton>
        </SortButtonsWrapper>
        <form onSubmit={handleSubmit}>
          {!hideFilters && (
            <FiltersWrapper>
              <Text tag={TextTag.div} size={TextSize.s12} bold>
                {filtersLabel}
              </Text>
              {type === 'options' && options && (
                <OptionsFilter
                  options={options}
                  currentValue={currentValue as string[]}
                  setCurrentValue={setCurrentValue}
                />
              )}
              {type === 'text' && (
                <TextInput
                  id="filter-value"
                  value={String(currentValue)}
                  onChange={onTextFilterChange}
                  autoFocus
                />
              )}
              {type === 'date' && inputComponent && (
                <DateFilters
                  PatternInputDate={inputComponent}
                  value={(currentValue as DateFilter) || undefined}
                  setCurrentValue={setCurrentValue}
                  dateFormat={format}
                  translate={translate}
                />
              )}
              {type === 'time' && inputComponent && (
                <TimeFilters
                  PatternInput={inputComponent}
                  value={(currentValue as TimeFilter) || undefined}
                  setCurrentValue={setCurrentValue}
                  timeFormat={format}
                  translate={translate}
                />
              )}
              {type === 'number' && inputComponent && (
                <NumberFilters
                  PatternInput={inputComponent}
                  value={(currentValue as NumberFilter) || undefined}
                  setCurrentValue={setCurrentValue}
                  timeFormat={format}
                  translate={translate}
                />
              )}
            </FiltersWrapper>
          )}
          <ControlsWrapper>
            <Button
              appearance={ButtonAppearance.Primary}
              noShadow
              type="submit"
              onClick={handleApply}
            >
              {applyLabel}
            </Button>
            <Button
              onClick={onClear}
              appearance={ButtonAppearance.Link}
              type="button"
            >
              {clearLabel}
            </Button>
          </ControlsWrapper>
        </form>
      </StyledTableFilters>
    );
  }
);
