import { useXYChartContext } from './xy-chart-context';

interface RangeMaskProps {
  readonly code: string;
  readonly markerPadding: number;
}

export function RangeMask({ code, markerPadding }: RangeMaskProps) {
  const { chartRect } = useXYChartContext();

  return (
    <mask id={code}>
      <rect
        x={chartRect.left - markerPadding}
        y={chartRect.top - markerPadding}
        width={chartRect.right - chartRect.left + 2 * markerPadding}
        height={chartRect.bottom - chartRect.top + 2 * markerPadding}
        fill="white"
      />
    </mask>
  );
}
