import { Locale, UserId } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';
import { CsvTable } from '../components/csv-table/types';

export interface GetSeasonOverSeasonDefinitionParams {
  language: Locale;
  userId: UserId;
}

export function getSeasonOverSeasonDefinition(
  params: GetSeasonOverSeasonDefinitionParams
): AxiosPromise<CsvTable[]> {
  return axios.get('/api/rtc/def', { params });
}
