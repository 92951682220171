import { AxisBottom, AxisLeft } from '@visx/axis';
import { curveBasis } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { NumberLike, scaleLinear } from '@visx/scale';
import { AreaClosed, LinePath } from '@visx/shape';
import { Text, TextProps } from '@visx/text';
import { useTooltipInPortal } from '@visx/tooltip';
import { ReactNode, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { isInGap } from './is-in-gap';
import { SyncTooltipHandlers, Tooltip } from './tooltip';

export interface BaseWorkoutData {
  time: number | null;
  distance?: number | null;
  zone?: number;
  [key: string]: number | null | undefined;
}

export interface WorkoutChartZone {
  top: number;
  bottom: number;
  name: string | null;
}

export interface BaseWorkoutChartProps {
  data: BaseWorkoutData[];
  domain?: [number, number];
  height: number;
  total: number;
  showTooltip?: boolean;
  showZones?: boolean;
  width: number;
  zones?: WorkoutChartZone[];
  zoneLabel?: string;
  id?: string;
  xAxisKey?: 'time' | 'distance';
  showGridRows?: boolean;
  showLeftAxis?: boolean;
  showBottomAxis?: boolean;
  showArea?: boolean;
  yAxisKey: string;
  marginConfig?: {
    left: number;
    bottom: number;
    right: number;
    top: number;
  };
  xAxisTicks?: number[];
  yAxisTicks?: number[];
  formatXAxisTicks?(value: NumberLike): string;
  formatTooltipCursor?(value: number): string;
  formatTooltip?(item: BaseWorkoutData): ReactNode;
  syncTooltipHandlers?: SyncTooltipHandlers;
  setSyncTooltipHandlers?: (handlers: SyncTooltipHandlers) => () => void;
  strokeDasharray?: string;
  strokeWidth?: number;
}

const ZONE_LABEL = 'zone';

export type { SyncTooltipHandlers } from './tooltip';

export const TICK_FORMAT: TextProps = {
  fill: '#D4D6D9',
  fontSize: 12,
  fontFamily: 'Roboto Mono',
};

export const BOTTOM_TICK_FORMAT: TextProps = {
  ...TICK_FORMAT,
  textAnchor: 'middle',
};

export const LEFT_TICK_FORMAT: TextProps = {
  ...TICK_FORMAT,
  textAnchor: 'end',
  verticalAnchor: 'middle',
  transform: 'translate(-15, 0)',
};

const DROP_SHADOW = (
  <feDropShadow
    dx="0"
    dy="1"
    stdDeviation="1"
    floodColor="#000000"
    floodOpacity="0.3"
  />
);

const ChartWrapper = styled.div`
  position: relative;
`;

const CHART_MARGIN = {
  left: 60,
  right: 35,
  bottom: 20,
  top: 5,
};

export function BaseWorkoutChart(props: BaseWorkoutChartProps): JSX.Element {
  const {
    width,
    height,
    data: dataWithNulls,
    showTooltip,
    showZones,
    formatXAxisTicks,
    formatTooltip,
    total,
    id = '',
    formatTooltipCursor,
    zones = [],
    zoneLabel = ZONE_LABEL,
    domain,
    marginConfig = CHART_MARGIN,
    xAxisKey = 'time',
    yAxisKey,
    showGridRows,
    showLeftAxis,
    showBottomAxis,
    showArea,
    yAxisTicks,
    syncTooltipHandlers,
    setSyncTooltipHandlers,
    strokeDasharray,
    strokeWidth,
  } = props;

  const data = useMemo(
    () =>
      dataWithNulls.filter(
        (d, idx, all) => d[yAxisKey] !== null || isInGap(all, idx, yAxisKey)
      ),
    [dataWithNulls, yAxisKey]
  );

  const leftMargin = showLeftAxis ? marginConfig.left : 5;
  const bottomMargin = showBottomAxis ? marginConfig.bottom : 0;
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
    detectBounds: true,
    debounce: 25,
  });

  const xMax = width - leftMargin - marginConfig.right;
  const yMax = height - bottomMargin - marginConfig.top;

  const getZoneForValue = useCallback(
    (hr: number): string => {
      let zoneIndex = zones.findIndex(z => hr >= z.bottom && hr <= z.top);

      if (zoneIndex === -1) {
        zoneIndex = hr < zones[0].bottom ? 0 : zones.length;
      }

      return zones[zoneIndex].name ?? `${zoneLabel} ${zoneIndex}`;
    },
    [zones, zoneLabel]
  );

  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax],
        round: true,
        domain: [0, total],
      }),
    [total, xMax]
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain,
      }),
    [yMax, domain]
  );

  const zoneLabels = useMemo(() => {
    const usedZones = zones.filter(
      zone =>
        (zone.bottom <= yScale.domain()[0] && zone.top > yScale.domain()[0]) ||
        (zone.bottom > yScale.domain()[0] &&
          zone.top > yScale.domain()[0] &&
          zone.bottom < yScale.domain()[1] &&
          zone.top < yScale.domain()[1]) ||
        (zone.bottom < yScale.domain()[1] && zone.top > yScale.domain()[1])
    );

    const labels = usedZones.map((zone, idx) => {
      const bottom =
        idx === 0 ? Math.max(yScale.domain()[0], zone.bottom) : zone.bottom;
      const top =
        idx === usedZones.length - 1
          ? Math.min(yScale.domain()[1], zone.top)
          : zone.top;

      return (top - bottom) / 2 + bottom;
    });

    return labels.filter(
      label => label < yScale.domain()[1] && label > yScale.domain()[0]
    );
  }, [zones, yScale]);

  return (
    <ChartWrapper>
      <svg width={width} height={height} ref={containerRef}>
        <defs>
          <filter id={`dropshadow-${id}`}>{DROP_SHADOW}</filter>
        </defs>
        <LinearGradient
          id={`area-gradient-${id}`}
          from="#4a4a4a"
          fromOffset="5%"
          fromOpacity={0.2}
          to="#4a4a4a"
          toOffset="70%"
          toOpacity={0}
        />
        <Group left={leftMargin} top={marginConfig.top}>
          {showGridRows && (
            <GridRows
              width={xMax}
              height={yMax}
              scale={yScale}
              stroke="#ccc"
              strokeDasharray="1 5"
              strokeWidth={1}
              tickValues={yAxisTicks}
              numTicks={4}
            />
          )}
          {showZones &&
            zoneLabels.map(value => (
              <Text
                key={value}
                textAnchor="end"
                verticalAnchor="middle"
                x={xMax}
                y={yScale(value)}
                fill="#d4d6d9"
                fontSize={12}
                fontFamily="Roboto Mono"
              >
                {getZoneForValue(value)}
              </Text>
            ))}
          {showLeftAxis && (
            <AxisLeft
              scale={yScale}
              hideTicks
              hideAxisLine
              tickLabelProps={() => LEFT_TICK_FORMAT}
              numTicks={4}
              tickValues={yAxisTicks}
              labelOffset={10}
              hideZero
            />
          )}
          {showBottomAxis && (
            <AxisBottom
              scale={xScale}
              top={yMax}
              hideAxisLine
              hideTicks
              tickLabelProps={() => BOTTOM_TICK_FORMAT}
              tickFormat={formatXAxisTicks}
              numTicks={5}
            />
          )}
          {showArea && (
            <AreaClosed
              data={data}
              yScale={yScale}
              curve={curveBasis}
              x={item => {
                return item[xAxisKey] ? xScale(item[xAxisKey]) : 0;
              }}
              y={item => yScale(item[yAxisKey] || 0)}
              strokeWidth={0}
              fill={`url(#area-gradient-${id})`}
              shapeRendering="geometricPrecision"
              defined={item =>
                item[yAxisKey] !== null && item[xAxisKey] !== null
              }
            />
          )}
          <g style={{ filter: `url('#dropshadow-${id}')` }}>
            <circle r="4" cx="0" cy="0" visibility="hidden"></circle>
            <LinePath
              data={data}
              curve={curveBasis}
              x={item => {
                return item[xAxisKey] ? xScale(item[xAxisKey]) : 0;
              }}
              y={item => yScale(item[yAxisKey] || 0)}
              strokeWidth={strokeWidth ?? 1}
              stroke="#4A4A4A"
              shapeRendering="geometricPrecision"
              strokeDasharray={strokeDasharray}
              strokeLinecap="round"
              strokeLinejoin="round"
              defined={item =>
                item[yAxisKey] !== null && item[xAxisKey] !== null
              }
            />
          </g>
          {showTooltip && (
            <Tooltip
              data={data}
              xScale={xScale}
              yScale={yScale}
              TooltipInPortal={TooltipInPortal}
              width={width}
              height={height}
              innerHeight={yMax}
              leftMargin={leftMargin}
              formatTooltip={formatTooltip}
              formatTooltipCursor={formatTooltipCursor}
              yMax={yMax}
              xAxisKey={xAxisKey}
              yAxisKey={yAxisKey}
              syncTooltipHandlers={syncTooltipHandlers}
              setSyncTooltipHandlers={setSyncTooltipHandlers}
            />
          )}
        </Group>
      </svg>
    </ChartWrapper>
  );
}
