import { toast } from '@yarmill/components';
import { ConflictResolution, UserStore } from '@yarmill/types';
import { getDaysInWeek, getFinalCountAfterResolution } from '@yarmill/utils';
import { AttendanceStore } from './mobx/attendance-store';
import { SumItemType } from './sum-row-item';
import { AttendanceValue, SumColumnType, isSumColumnType } from './types';

export const ENABLE_TABLE_ANIMATION = false;

export function getSumRowItemValue(
  attendanceStore: AttendanceStore,
  column: string | SumColumnType
): number {
  if (isSumColumnType(column)) {
    return attendanceStore.athletes.reduce(
      (total, user) => total + getSumColumnItemValue(attendanceStore, user),
      0
    );
  }

  return attendanceStore.athletes.reduce((total: number, user: UserStore) => {
    const item = attendanceStore.weekData.get(user.id)?.get(column);

    return item && item.value === AttendanceValue.Present ? total + 1 : total;
  }, 0);
}

export function getSumColumnItemValue(
  attendanceStore: AttendanceStore,
  user: UserStore
): number {
  const days = getDaysInWeek(attendanceStore.week || '');

  return days.reduce((total: number, date: string) => {
    const item = attendanceStore.weekData.get(user.id)?.get(date);

    return item && item.value === AttendanceValue.Present ? total + 1 : total;
  }, 0);
}

export function formatNumberValue(
  value: number | null = null
): number | string {
  if (value === null) {
    return '-';
  }

  return Math.round(value);
}

export function getEnabledDaysForUser(
  attendanceStore: AttendanceStore,
  user: UserStore
): number {
  const days = getDaysInWeek(attendanceStore.week || '');

  return days.reduce((total: number, date: string) => {
    const item = attendanceStore.weekData.get(user.id)?.get(date);

    return item && item.value !== AttendanceValue.Free ? total + 1 : total;
  }, 0);
}

export function getEnabledDaysForGroup(
  attendanceStore: AttendanceStore,
  column: string | SumItemType
): number {
  return attendanceStore.athletes.reduce((total: number, user: UserStore) => {
    if (isSumColumnType(column)) {
      return total + getEnabledDaysForUser(attendanceStore, user);
    }

    const item = attendanceStore.weekData.get(user.id)?.get(column);

    return item && item.value !== AttendanceValue.Free ? total + 1 : total;
  }, 0);
}

export const monthsMock = [
  '2000-04-01T00:00:00',
  '2000-05-01T00:00:00',
  '2000-06-01T00:00:00',
  '2000-07-01T00:00:00',
  '2000-08-01T00:00:00',
  '2000-09-01T00:00:00',
  '2000-10-01T00:00:00',
  '2000-11-01T00:00:00',
  '2000-12-01T00:00:00',
  '2000-01-01T00:00:00',
  '2000-02-01T00:00:00',
  '2000-03-01T00:00:00',
];

export function toastCopyAttendanceSuccess(
  athletesCount: number,
  resolution: ConflictResolution,
  numberOfConflicts: number
): void {
  toast(`toast.success.attendance.copy`, 'success', {
    count: String(
      getFinalCountAfterResolution(athletesCount, resolution, numberOfConflicts)
    ),
  });
}
