import { LayerComponentProps } from './types';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import { useBodyScrollLock } from './hooks';

const StyledLayerContentWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const FocusLockContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  &:focus {
    outline: none;
  }
`;

const allowedClassNames = [
  'richtext-highlight-picker',
  'richtext-others-picker',
];

function focusLockWhitelist(activeElement: HTMLElement): boolean {
  if (
    allowedClassNames.some(
      className =>
        activeElement?.className?.includes(className) ||
        activeElement?.parentElement?.className.includes(
          'richtext-highlight-picker'
        )
    )
  ) {
    return false;
  }

  return true;
}

export function LayerContent(props: LayerComponentProps): JSX.Element {
  const { layer, active } = props;
  const layerContentWrapperRef = useRef<HTMLDivElement>(null);
  const [isInitialRender, setIsInitialRender] = useState(true);
  useBodyScrollLock(layerContentWrapperRef);

  useEffect(() => {
    const element = layerContentWrapperRef.current;
    layer.setScrollContainerRef(layerContentWrapperRef);

    function onKeyDown(e: KeyboardEvent): void {
      if (e.key === 'Escape') {
        const activeElement = document.activeElement;
        const shouldCloseLayer =
          (!activeElement?.className.includes('react_select') &&
            !activeElement?.className.includes('multi-select') &&
            !activeElement?.className.includes('users-select') &&
            !activeElement?.className.includes('groups-select')) ||
          (!activeElement
            ?.closest('.react_select__control')
            ?.className.includes('menu-is-open') &&
            !(
              activeElement
                ?.closest('.multi-select')
                ?.getAttribute('aria-expanded') === 'true'
            ));

        if (shouldCloseLayer) {
          e.preventDefault();
          layer.close();
        }
      }
    }

    if (element) {
      layer.render(layerContentWrapperRef.current, layer);
      element.addEventListener('keydown', onKeyDown);
      layer.render(element, layer);
    }

    return () => {
      element?.removeEventListener('keydown', onKeyDown);
    };
  }, [layer]);

  useEffect(() => {
    // we need to wait until layer animation is finished (300ms)
    setTimeout(() => setIsInitialRender(false), 300);
  }, []);

  return (
    <ReactFocusLock
      returnFocus={layer.options.returnFocus ?? true}
      as={FocusLockContainer}
      disabled={isInitialRender || !active}
      whiteList={focusLockWhitelist}
      focusOptions={
        layer.options.disableFocusScroll ? { preventScroll: true } : undefined
      }
    >
      <StyledLayerContentWrapper
        className="layer-content-wrapper"
        ref={layerContentWrapperRef}
      />
    </ReactFocusLock>
  );
}
