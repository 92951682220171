import { AsyncStatus, RootStore } from '@yarmill/types';
import {
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { getDashboards } from '../api/get-dashboards';
import { DashboardStore, DashboardType } from '../types';
import { GeneralDashboardStore } from './general-dashboard-store';
import { SlcrDashboardStore } from './slcr-dashboard-store';

export class LegacyAnalyticsStore {
  private readonly _rootStore: RootStore;
  private readonly memoryStoreKey = 'legacy-analytics-store';
  @observable
  private readonly _dashboards: DashboardStore[] = [];

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeObservable(this);
    when(
      () =>
        rootStore.currentUserStore.status === AsyncStatus.resolved &&
        rootStore.configStore.status === AsyncStatus.resolved,
      () => this.loadDashboards()
    );
  }

  async loadDashboards(): Promise<void> {
    const cachedDashboards = this._rootStore.memoryStore.getItem<
      DashboardStore[]
    >(this.memoryStoreKey);
    if (cachedDashboards) {
      (this._dashboards as IObservableArray<DashboardStore>).replace(
        cachedDashboards
      );
      return;
    }

    const request = this._rootStore.requestsStore.createRequest(() =>
      getDashboards()
    );
    const response = await request.getResponse();

    runInAction(() => {
      const stores =
        response?.map(dashboard => {
          switch (dashboard.Ident) {
            case 'general.defaultDashboard':
              return new GeneralDashboardStore(this._rootStore, dashboard);
            default:
              return new SlcrDashboardStore(this._rootStore, dashboard);
          }
        }) ?? [];

      (this._dashboards as IObservableArray<DashboardStore>).replace(stores);
      this._rootStore.memoryStore.setItem(
        this.memoryStoreKey,
        this._dashboards
      );
    });
  }

  get dashboards(): DashboardStore[] {
    return this._dashboards;
  }

  getDashboard(id: DashboardType): DashboardStore | undefined {
    return this._dashboards.find(dashboard => dashboard.id === id);
  }
}
