import { Locale, SeasonId, UserId } from '@yarmill/types';
import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';

export interface ExportOkrsToPdfRequestParams {
  language: Locale;
  userId: UserId;
  seasonId: SeasonId;
  templateCode?: string;
}

export function exportOkrsToPdf(
  params: ExportOkrsToPdfRequestParams,
  cancelToken: CancelTokenSource
): AxiosPromise<BlobPart> {
  return axios.post(`${coreApiTarget}/api/print/okr`, params, {
    responseType: 'blob',
    cancelToken: cancelToken.token,
    params: {
      instanceCode: devMode ? instance : undefined,
    },
  });
}
