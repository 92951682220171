import { useScale } from '../hooks/use-scale';

export function getScaleSize(scale: ReturnType<typeof useScale>) {
  const range = scale.range();
  if (typeof range[0] !== 'number' || typeof range[1] !== 'number') {
    return 0;
  }

  return range[1] - range[0];
}
