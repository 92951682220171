import { LayerPortal } from '@yarmill/components';
import { useLayer, useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { ReactElement, useEffect } from 'react';
import { VideoSelectorLayerContent } from './video-selector-layer-content';

function InternalVideoSelector(): ReactElement {
  const videoSelectorService = useRootStore().videoSelectorService;
  const videoSelectorLayer = useLayer('interaction');
  const isOpen = videoSelectorService.isOpen;

  useEffect(() => {
    if (isOpen && !videoSelectorLayer.isOpened) {
      videoSelectorLayer.open({
        closeOnShimClick: true,
        showShim: true,
        returnFocus: false,
        onClose: () => videoSelectorService.closeVideoSelector(),
      });
    }
  }, [videoSelectorLayer, isOpen, videoSelectorService]);

  return (
    <LayerPortal
      layerHandle={videoSelectorLayer}
      getContent={layer => <VideoSelectorLayerContent layer={layer} />}
    />
  );
}

export const VideoSelector = observer(InternalVideoSelector);
