import {
  ContentBox,
  FormattedHTMLMessage,
  LoginPageLayout,
  SimplePageLayout,
  Text,
  TextAlignment,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { useIntlStore } from '@yarmill/utils';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getIsInstanceActive } from './api/get-is-instance-active';
import { InactiveInstance } from './inactive-instance';
import { LoginForm } from './login-form';

export function Login(): JSX.Element | null {
  const intlStore = useIntlStore();
  const showMessageBox = Boolean(intlStore.messages['auth.login.messageBox']);

  const [isInstanceActive, setIsInstanceActive] = useState<boolean | null>(
    true
  );

  useEffect(() => {
    getIsInstanceActive()
      .then(response => setIsInstanceActive(Boolean(response.data)))
      .catch(() => setIsInstanceActive(false));
  }, []);

  if (isInstanceActive === null) {
    return null;
  }

  return (
    <SimplePageLayout>
      <LoginPageLayout>
        {isInstanceActive && (
          <Text
            tag={TextTag.h1}
            size={TextSize.s24}
            textAlign={TextAlignment.center}
          >
            <FormattedMessage id="auth.loginPageHeading" />
            &nbsp;
            <FormattedMessage id="fe.instance.name" defaultMessage={' '} />
          </Text>
        )}
        {showMessageBox && (
          <ContentBox>
            <Text size={TextSize.s14}>
              <FormattedHTMLMessage id="auth.login.messageBox" />
            </Text>
          </ContentBox>
        )}

        {isInstanceActive && <LoginForm />}
      </LoginPageLayout>
      {!isInstanceActive && <InactiveInstance />}
    </SimplePageLayout>
  );
}
