import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export function getIsInstanceActive(): AxiosPromise<boolean> {
  return axios.get(`${coreApiTarget}/api/instance/active`, {
    params: {
      instanceCode: devMode ? instance : undefined,
    },
  });
}
