import styled from 'styled-components';
import { StyledPropsExtractor } from '../helpers/styled-props-extractor';

export const CommandPaletteModal = styled(StyledPropsExtractor)`
  width: 640px;
  position: absolute;
  top: 100px;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 0;
  background-image: linear-gradient(
    136.61deg,
    rgb(49, 49, 54) 13.72%,
    rgb(43, 43, 50) 74.3%
  );
  overflow: hidden;
  border-radius: 8px;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.5) 0 16px 70px;
  margin-right: -50%;
  transform: translate(-50%, 0px);
  box-sizing: border-box;
  & * {
    box-sizing: border-box;
  }
`;

export const CommandPaletteOverlay = styled(StyledPropsExtractor)`
  position: fixed;
  inset: 0;
  background-color: unset;
`;
export const CommandPaletteContainer = styled(StyledPropsExtractor)``;
export const CommandPaletteInput = styled(StyledPropsExtractor)`
  padding: 20px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  color: rgb(214, 214, 214);
  font-size: 18px;
  line-height: 1.5;
  font-family: Ubuntu;
`;
export const CommandPaletteSuggestionContainer = styled(StyledPropsExtractor)`
  border-top: 1px solid rgb(60, 60, 64);
`;
export const CommandPaletteSuggestionList = styled(StyledPropsExtractor)`
  padding: 0;
  margin: 0;
  max-height: calc(7 * 46px);
  overflow-y: auto;
`;

export const HIGHLIGHTED_CLASS_NAME = 'highlighted';
export const CommandPaletteSuggestion = styled(StyledPropsExtractor)`
  list-style-type: none;
  height: 46px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  color: rgb(214, 214, 214);
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: transparent;
  font-weight: 300;

  &.${HIGHLIGHTED_CLASS_NAME} {
    border-left-color: rgb(94, 106, 210);
    background-color: rgb(55, 55, 60);
    color: #ffffff;
    cursor: pointer;
  }

  & > div {
    width: 100%;
  }
`;

export const CommandPalettePortalTarget = styled.div`
  position: relative;
  z-index: 4;
`;
