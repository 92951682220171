import { LabelPosition } from '../../reporting';
import { LABEL_FONT_SIZE, LABEL_OFFSET } from '../../reporting/const';
import { getRotatedWidth } from '../../reporting/utils/get-rotated-width';

export function getHorizontalBarLabelOffset(
  barWidth: number,
  xValue: number | null,
  position?: LabelPosition,
  labelAngle: number = 0,
  labelWidth: number = 0
): number {
  let offset;

  if (xValue === null) {
    return 0;
  }

  const rotatedWidth = getRotatedWidth(labelWidth, LABEL_FONT_SIZE, labelAngle);

  switch (position) {
    case 'inside-center': {
      offset = barWidth / 2;
      break;
    }
    case 'inside-top':
      if (barWidth < rotatedWidth + 2 * LABEL_OFFSET) {
        offset = barWidth / 2;
      } else {
        offset = barWidth - LABEL_OFFSET - rotatedWidth / 2;
      }
      break;
    case 'outside':
    default:
      offset = barWidth + LABEL_OFFSET + rotatedWidth / 2;
      break;
  }

  return xValue < 0 ? -1 * offset : offset;
}
