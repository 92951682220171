import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  Icon,
  IconSize,
  Popover,
  PopoverItem,
  SingleLinePopoverItem,
} from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { MouseEvent, MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DesktopButtonsWrapper,
  MobileButtonsWrapper,
} from '../components/table-row-edit-buttons';

interface RowEditButtonsProps {
  readonly editRow: MouseEventHandler;
  readonly removeRow: MouseEventHandler;
}
export const RowEditButtons = observer(function RowEditButtons({
  editRow,
  removeRow,
}: RowEditButtonsProps) {
  return (
    <>
      <DesktopButtonsWrapper>
        <Button onClick={editRow} appearance={ButtonAppearance.Link}>
          <Icon>
            <ExternalIcon name="Pencil" />
          </Icon>
        </Button>
        <Button onClick={removeRow} appearance={ButtonAppearance.Link}>
          <Icon>
            <ExternalIcon name="Trash" />
          </Icon>
        </Button>
      </DesktopButtonsWrapper>
      <MobileButtonsWrapper>
        <Popover
          autoWidth
          content={tippy => (
            <>
              <PopoverItem
                dataTest="edit-button"
                onClick={(e: MouseEvent) => {
                  editRow(e);
                  tippy.hide();
                }}
                appearance="default"
              >
                <SingleLinePopoverItem>
                  <FormattedMessage id="evidence.table.buttons.edit" />
                </SingleLinePopoverItem>
              </PopoverItem>
              <PopoverItem
                dataTest="remove-button"
                onClick={(e: MouseEvent) => {
                  removeRow(e);
                  tippy.hide();
                }}
                appearance="danger"
              >
                <SingleLinePopoverItem>
                  <FormattedMessage id="evidence.table.buttons.remove" />
                </SingleLinePopoverItem>
              </PopoverItem>
            </>
          )}
        >
          <Button
            as="button"
            appearance={ButtonAppearance.Link}
            square
            data-cy="edit-buttons-menu"
          >
            <Icon size={IconSize.s24}>
              <ExternalIcon name="DotsCircleHorizontal" />
            </Icon>
          </Button>
        </Popover>
      </MobileButtonsWrapper>
    </>
  );
});
