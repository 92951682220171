import { ActivityData } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { DiaryType } from '../types';

export interface GetSeasonPlanForGroupRequestParams {
  userGroupId: number;
  seasonId: number;
  referenceTime?: string;
}

export function getSeasonDataForGroup(
  diaryType: DiaryType,
  params: GetSeasonPlanForGroupRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<ActivityData[]> {
  return axios.post(`api/${diaryType}V2/Season/Group`, params, {
    cancelToken: cancelToken?.token,
  });
}
