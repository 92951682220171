import styled from 'styled-components';
import { StyledProgress, StyledSvgWrapper } from './toast-progress';
import { CrossIcon, StyledSvg } from './svg';

export const ToastCancelOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(249, 57, 67, 0.6);
  border-radius: 8px;
  cursor: pointer;
  display: none;
`;

const SvgWrapper = styled(StyledSvgWrapper)`
  background-color: #d04535;
  cursor: pointer;
`;

export function ToastCancel(): JSX.Element {
  return (
    <StyledProgress className="toast-cancel">
      <SvgWrapper>
        <StyledSvg viewBox="0 0 26 26">
          <CrossIcon />
        </StyledSvg>
      </SvgWrapper>
    </StyledProgress>
  );
}
