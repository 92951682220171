import { ConflictResolution } from '@yarmill/types';

export function getFinalCountAfterResolution(
  count: number,
  resolution: ConflictResolution,
  numberOfConflicts: number
): number {
  if (resolution === 'SkipConflicts') {
    return count - numberOfConflicts;
  }
  return count;
}
