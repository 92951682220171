import { IconSize, Tippy } from '@yarmill/components';
import {
  Avatar,
  CoachSymbol,
  RoleAvatarIcon,
  SelectedUsers,
} from '@yarmill/components-2';
import { Coach } from '@yarmill/icons-2';
import { UserId, UserStore } from '@yarmill/types';
import { useUsersStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

interface SupervisorsProps {
  readonly userIds: (UserId | UserStore)[];
  readonly hideText?: boolean;
}

export const Supervisors = observer(function Supervisors({
  userIds,
  hideText,
}: SupervisorsProps): JSX.Element {
  const intl = useIntl();
  const usersStore = useUsersStore();
  const users = userIds
    .map(id => (typeof id === 'number' ? usersStore.getUserById(id) : id))
    .filter((u): u is UserStore => Boolean(u));

  const avatars = users.map(u => (
    <Tippy
      key={u.id}
      tooltipContent={u.displayName}
      noWrapper
      translateValue={false}
    >
      <RoleAvatarIcon isCoach={!u.isAthlete} size={IconSize.s24}>
        <>
          <Avatar key={u.id} avatar={u.avatar} />
          {!u.isAthlete && (
            <CoachSymbol size={IconSize.s10}>
              <Coach />
            </CoachSymbol>
          )}
        </>
      </RoleAvatarIcon>
    </Tippy>
  ));

  return (
    <SelectedUsers
      avatars={avatars}
      name={users.length === 1 ? users[0].displayName : undefined}
      noUserText={intl.formatMessage({
        id: 'okrs.supervisors.noSupervisor',
      })}
      hideText={hideText}
    />
  );
});
