import {
  CurrentUserStore,
  ItemWithPermissionScope,
  PermissionFilter,
  PermissionType,
  UserRole,
} from '@yarmill/types';

export const getDefaultPermission = (role?: UserRole | null): PermissionType =>
  role === 'coach' || role === 'admin' ? 'write' : 'read';
export function createPermissionScopeFilter<
  I,
  T extends ItemWithPermissionScope<I>,
>(currentUser?: CurrentUserStore): PermissionFilter<T> {
  return (item: T) => {
    if (!currentUser) {
      return item.permissionScope === null;
    }

    if (item.permissionScope === null) {
      return true;
    }

    if (Array.isArray(item.permissionScope)) {
      return item.permissionScope.some(scope => currentUser.isAllowedTo(scope));
    }

    return currentUser.isAllowedTo(item.permissionScope);
  };
}
