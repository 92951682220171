import { useCurrentUserStore } from '@yarmill/utils';
import { usePlannerStore } from '../planner-store-context';

export function useCurrentUserIsAllowedToWrite() {
  const plannerStore = usePlannerStore();
  const currentUser = useCurrentUserStore();
  return currentUser.hasWritePermission(
    plannerStore.groupId,
    plannerStore.athleteId
  );
}
