import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

export function ChartLoader(props: IContentLoaderProps): JSX.Element | null {
  if (!props.width || !props.height) {
    return null;
  }

  return (
    <ContentLoader
      {...props}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      viewBox={`0 0 ${props.width} ${props.height}`}
    >
      <rect
        x={0}
        y={0}
        rx={4}
        ry={4}
        width={props.width}
        height={props.height}
      />
    </ContentLoader>
  );
}
