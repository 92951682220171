import { createElement } from 'react';

export function Strikethrough() {
  return createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'icon icon-tabler icon-tabler-strikethrough',
      viewBox: '0 0 24 24',
      strokeWidth: '1.5',
      stroke: 'currentColor',
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      role: 'img',
    },
    createElement('path', { d: 'M0 0h24v24H0z', stroke: 'none' }),
    createElement('path', {
      d: 'M5 12h14M16 6.5A4 2 0 0 0 12 5h-1a3.5 3.5 0 0 0 0 7h2a3.5 3.5 0 0 1 0 7h-1.5a4 2 0 0 1-4-1.5',
    })
  );
}
