import { Icon, IconSize, Tippy } from '@yarmill/components';
import { Button, ButtonAppearance } from '@yarmill/components-2';
import { ISO_DATE_FORMAT } from '@yarmill/const';
import { Plus } from '@yarmill/icons-2';
import moment from 'moment';
import { ReactElement, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSubmitOpenedForm } from '../hooks/use-submit-opened-form';
import { PlannerEventStore } from '../mobx/planner-event-store';
import { usePlannerStore } from '../planner-store-context';

export function CreateNewEvent(): ReactElement {
  const intl = useIntl();
  const plannerStore = usePlannerStore();
  const submitOpenedForm = useSubmitOpenedForm();

  const createNewEvent = useCallback(() => {
    const openedEventType = plannerStore.formEvent?.eventTypeId;
    submitOpenedForm();

    const currentSeason = plannerStore.currentSeason;
    if (!currentSeason) {
      return;
    }

    const store = new PlannerEventStore();

    const eventTypeId =
      openedEventType ??
      plannerStore.lastEventType ??
      plannerStore.eventTypes[0]?.eventTypeId;

    const today = moment();
    const seasonStart = currentSeason.start.clone();
    const seasonEnd = currentSeason.end.clone();

    const correctedDay =
      today.isSameOrAfter(seasonStart) && today.isSameOrBefore(seasonEnd)
        ? today
        : moment(
            (seasonStart.toDate().getTime() + seasonEnd.toDate().getTime()) / 2
          ).startOf('day');
    store
      .setStartDate(correctedDay.format(ISO_DATE_FORMAT))
      .setEndDate(correctedDay.format(ISO_DATE_FORMAT))
      .setEventTypeId(eventTypeId);
    store.titlePlaceholder = intl.formatMessage(
      { id: 'planner.newEvent' },
      { eventType: eventTypeId }
    );
    plannerStore.formEvent = store;
    plannerStore.calendarFormEvent = store;
    plannerStore.scrollToEvent = 'form-event';
  }, [plannerStore, submitOpenedForm, intl]);

  return (
    <Tippy tooltipContent="planner.createNewEvent" noWrapper touch={false}>
      <Button
        $appearance={ButtonAppearance.Secondary}
        $appearanceStyle="neutral"
        $iconOnly
        onClick={createNewEvent}
        aria-label={intl.formatMessage({
          id: 'planner.createNewEvent',
        })}
        type="button"
      >
        <Icon size={IconSize.s24}>
          <Plus />
        </Icon>
      </Button>
    </Tippy>
  );
}
