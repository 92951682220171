import { Avatar, ProfileHeader, RoleTag } from '@yarmill/components';
import { useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useEvidenceStore } from '../hooks/use-evidence-store';

export const EvidenceProfileHeader = observer(function EvidenceProfileHeader() {
  const rootStore = useRootStore();
  const evidenceStore = useEvidenceStore();
  const user = rootStore.usersStore.getUserById(evidenceStore.userId);

  return user ? (
    <ProfileHeader
      avatar={<Avatar id={user.avatar} alt={user.displayName} />}
      name={user.displayName}
      email={user.internalUser.Email}
      role={<RoleTag role={user.internalUser.Role} />}
    />
  ) : null;
});
