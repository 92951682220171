import { action, makeObservable, observable } from 'mobx';

interface iMemoryStore {
  setItem<T>(key: string, value: T): void;

  getItem<T>(key: string): T | undefined;
}

export class MemoryStore implements iMemoryStore {
  @observable
  private readonly _persistedItems: Record<string, any> = {};

  constructor() {
    makeObservable(this);
  }

  @action
  setItem<T>(key: string, value: T): void {
    this._persistedItems[key] = value;
  }

  getItem<T>(key: string): T | undefined {
    return this._persistedItems[key];
  }
}
