import { Locale } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';

export interface ExportReportingPageToPdfRequestParams {
  language: Locale;
  reportPageCode: string;
  filters: Record<string, string>;
}

export function exportReportingPageToPdf(
  params: ExportReportingPageToPdfRequestParams,
  cancelToken: CancelTokenSource
): AxiosPromise<BlobPart> {
  return axios.post(`api/print/reporting`, params, {
    responseType: 'blob',
    cancelToken: cancelToken.token,
  });
}
