import { DATE_FORMAT } from '@yarmill/const';
import { getWeekEnd } from './get-week-end';
import { getWeekStart } from './get-week-start';

export function getDaysInWeek(
  day: string,
  format: string = DATE_FORMAT
): string[] {
  const weekStart = getWeekStart(day);
  const weekEnd = getWeekEnd(day);
  const currentDay = weekStart;
  const daysInWeek = [];

  while (currentDay <= weekEnd) {
    daysInWeek.push(currentDay.format(DATE_FORMAT));
    currentDay.add(1, 'days');
  }

  return daysInWeek;
}
