import { athleteCookies } from '@yarmill/const';
import { UserStore } from '@yarmill/types';

export function persistSelectedAthlete(athlete: UserStore | undefined): void {
  if (athlete) {
    window.localStorage.setItem(athleteCookies.ATHLETE, String(athlete.id));
  } else {
    window.localStorage.removeItem(athleteCookies.ATHLETE);
  }
}
