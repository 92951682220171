import { AuthStore } from '@yarmill/types';
import { useRootStore } from '@yarmill/utils';

export function useToken(): string | null {
  return useAuthStore().token;
}

export function useIsLoggedIn(): boolean {
  return useAuthStore().isLoggedIn;
}

export function useAuthStore(): AuthStore {
  return useRootStore().authStore;
}
