import { useScale } from '../hooks/use-scale';
import { groupStackedBarsByCategories } from './group-stacked-bars-by-categories';
import { ArrayElement } from '../../reporting/types';

export function sortHorizontalStackedBarsByValue(
  categoryBars: ArrayElement<ReturnType<typeof groupStackedBarsByCategories>>,
  xScale: ReturnType<typeof useScale>
) {
  const sortedBars = categoryBars.sort((a, b) => b.width - a.width);

  return sortedBars.map((bar, barIdx) => ({
    ...bar,
    x:
      // sum all previous bars
      sortedBars.reduce((sum, item, idx) => {
        if (idx < barIdx) {
          sum = sum + item.width;
        }
        return sum;
      }, 0) + xScale.range()[0],
  }));
}
