import { Locale } from '@yarmill/types';
import { useConfig } from './use-config';
import { useIntlStore } from './use-intl-store';

export function useLocale(): Locale {
  const defaultLanguage = useConfig('defaultLanguage');
  const intlStore = useIntlStore();

  return intlStore.locale || defaultLanguage;
}
