import { WORKOUT_URL_PARAM } from '@yarmill/const';
import {
  RequestStore,
  RootStore,
  WorkoutDetailStore as iWorkoutDetailStore,
} from '@yarmill/types';
import {
  IReactionDisposer,
  action,
  makeObservable,
  observable,
  reaction,
} from 'mobx';
import { getWorkoutDetail } from '../api/getWorkoutDetail';
import { Workout } from '../types';

export class WorkoutDetailStore implements iWorkoutDetailStore {
  private readonly rootStore: RootStore;
  private readonly reactions: IReactionDisposer[] = [];

  @observable
  private workoutId: number | null = null;

  @observable
  private workoutName: string | null = null;

  @observable
  private workoutDate: string | null = null;

  @observable
  private _workout: Workout | null = null;

  @observable
  private request: RequestStore<Workout> | null = null;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;

    this.registerReactions();
  }

  @action
  clear(): void {
    this.workoutDate = null;
    this.workoutName = null;
    this.workoutId = null;
    this._workout = null;
  }

  disposeReactions(): void {
    this.reactions.forEach(dispose => dispose());
  }

  get workout(): Workout | null {
    return this._workout;
  }

  get name(): string | null {
    return this.workoutName;
  }

  get date(): string | null {
    return this.workoutDate;
  }

  get id(): number | null {
    return this.workoutId;
  }

  registerReactions(): void {
    const workoutId = reaction(
      () => this.rootStore.historyService.searchParams.get(WORKOUT_URL_PARAM),
      workoutId => {
        this.workoutId = workoutId ? Number(workoutId) : null;
      },
      {
        fireImmediately: true,
      }
    );

    const loadData = reaction(
      () => this.workoutId,
      () => {
        if (this.workoutId) {
          this.loadWorkoutData();
        }
      },
      {
        fireImmediately: true,
      }
    );

    this.reactions.push(workoutId, loadData);
  }

  async loadWorkoutData(): Promise<void> {
    const workoutId = this.workoutId;
    if (!workoutId) {
      return;
    }

    if (this.request) {
      this.request.cancel();
      this.rootStore.requestsStore.removeRequest(this.request);
    }

    this.request = this.rootStore.requestsStore.createRequest(cancelToken =>
      getWorkoutDetail(workoutId, cancelToken)
    );
    const response = await this.request.getResponse();

    if (response) {
      this._workout = response;
    }
  }
}
