import { AlertLayer as AlertLayerComponent } from '@yarmill/components';
import { AlertLayerOptions } from '@yarmill/types';
import { LayerContent } from './layer-content';
import { LayerComponentProps } from './types';

export function AlertLayer(props: LayerComponentProps): JSX.Element {
  const { layer, active, ...ownProps } = props;
  const options = layer.options as AlertLayerOptions;

  return (
    <AlertLayerComponent
      {
        ...ownProps /* Used to pass through the animation props */
      }
      active={Boolean(active)}
      transparent={options.transparent}
      autoSize={options.autoSize}
      wide={options.wide}
      center={options.center}
    >
      <LayerContent layer={layer} active={active} />
    </AlertLayerComponent>
  );
}
