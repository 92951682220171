import { Icon, IconSize, styled, ThemeDefinition } from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import { Text } from './text';
import { TextInputProps } from './text-input';
import { Stop } from '@yarmill/icons-2';

interface StatusProps {
  readonly status?:
    | 'not-started'
    | 'on-track'
    | 'off-track'
    | 'completed'
    | 'canceled'
    | 'failed';
  readonly hideText?: boolean;
  readonly variant?: 'default' | 'large';
}

export function getStatusAppearance(
  status?: StatusProps['status']
): TextInputProps['appearance'] {
  switch (status) {
    case 'failed':
      return 'red';
    case 'on-track':
      return 'navy';
    case 'off-track':
      return 'orangine';
    case 'completed':
      return 'green';
    case 'canceled':
      return 'neutral';
    case 'not-started':
    default:
      return 'neutral';
  }
}
export function getBackgroundColorForStatus(
  status: StatusProps['status'],
  colors: ThemeDefinition['color']
): string {
  switch (status) {
    case 'failed':
      return colors.red;
    case 'on-track':
      return colors.navy;
    case 'off-track':
      return colors.orangine;
    case 'completed':
      return colors.green;
    case 'canceled':
      return colors.neutral;
    case 'not-started':
    default:
      return colors.neutral;
  }
}

const StatusLayout = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.size.x05};
  color: ${({ theme, status }) =>
    getBackgroundColorForStatus(status, theme.color)};
  line-height: normal;
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.borderRadius.x05};
`;

export function Status({
  status = 'not-started',
  hideText,
  variant,
}: StatusProps): JSX.Element {
  return (
    <StatusLayout status={status}>
      <StatusIcon status={status} variant={variant} />
      {!hideText && (
        <Text appearance="button10" whiteSpace="noWrap" inheritColor bold>
          <FormattedMessage id={`okrs.status.${status}`} />
        </Text>
      )}
    </StatusLayout>
  );
}

export const StyledStatusIcon = styled(Icon)<{
  readonly status: StatusProps['status'];
  readonly variant?: 'compact' | 'default';
}>`
  color: ${({ theme, status }) =>
    getBackgroundColorForStatus(status, theme.color)};
`;
interface StatusIconProps {
  readonly status: StatusProps['status'];
  readonly variant?: 'default' | 'large' | 'x-large';
}
export function StatusIcon({
  status = 'not-started',
  variant = 'default',
}: StatusIconProps): JSX.Element {
  return (
    <StyledStatusIcon
      status={status}
      size={
        variant === 'default'
          ? IconSize.s16
          : variant === 'large'
            ? IconSize.s24
            : IconSize.s32
      }
    >
      <Stop />
    </StyledStatusIcon>
  );
}
