import { AsyncStatus } from '@yarmill/types';

export function getProgressText(
  progress: number | undefined,
  status: AsyncStatus
): string | undefined {
  if (progress !== undefined && status === AsyncStatus.pending) {
    return `${Math.round(progress * 100)}%`;
  }
  return;
}
