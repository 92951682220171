import {
  ServerError,
  ServerErrorResponse as iServerErrorResponse,
} from '@yarmill/types';

export class ServerErrorResponse extends Error implements iServerErrorResponse {
  private _error: ServerError;
  constructor(msg: string, error: ServerError) {
    super(msg);
    this._error = error;
    Object.setPrototypeOf(this, ServerErrorResponse.prototype);
  }

  get id(): string {
    return this._error.Id;
  }

  get field(): string | undefined {
    return this._error.Field;
  }

  get values(): Record<string, string | number> | undefined {
    return this._error.Values;
  }
}
