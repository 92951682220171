export function sortBySortCode<
  T extends
    | { SortCode: Readonly<number | null> }
    | { sortCode: Readonly<number | null> },
>(a: T, b: T): number {
  const left = 'sortCode' in a ? a.sortCode : a.SortCode;
  const right = 'sortCode' in b ? b.sortCode : b.SortCode;

  return (left ?? 0) - (right ?? 0);
}
