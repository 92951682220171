import styled from 'styled-components';
import { ContentBox } from '../content-box';

export const LoginPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin: 80px 20px;

  @media (min-width: 768px) {
    width: 50%;
    margin: 80px auto;
  }

  @media (min-width: 1200px) {
    max-width: 30%;
  }
`;

export const LoginFormButtonsWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
`;

export const InactiveInstanceContentBox = styled(ContentBox)`
  display: flex;
  width: fit-content;
`;

export const InactiveInstanceWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px;
`;
