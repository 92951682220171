import { useEffect } from 'react';
import { usePlannerStore } from '../planner-store-context';
import { useCloseEventFormPanelHandler } from './use-close-event-form-panel-handler';
import { useIntl } from 'react-intl';
import { useCurrentUserIsAllowedToWrite } from './use-current-user-is-allowed-to-write';

export function useKeyboardControls() {
  const plannerStore = usePlannerStore();
  const closeEventFormPanel = useCloseEventFormPanelHandler();
  const intl = useIntl();
  const currentUserIsAllowedToWrite = useCurrentUserIsAllowedToWrite();

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (
        plannerStore.formEvent &&
        plannerStore.formEvent.id &&
        plannerStore.formEvent.isRemovable &&
        currentUserIsAllowedToWrite &&
        (e.key === 'Delete' || e.key === 'Backspace') &&
        (!document.activeElement || document.activeElement === document.body)
      ) {
        const message = intl.formatMessage(
          { id: 'planner.removeEvent.confirm' },
          { title: plannerStore.formEvent.title }
        );
        if (window.confirm(message)) {
          plannerStore.removeEvent(plannerStore.formEvent);
          closeEventFormPanel();
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeEventFormPanel, intl, plannerStore, currentUserIsAllowedToWrite]);
}
