import { createContext, useContext } from 'react';
import { useScale } from './hooks/use-scale';

interface XYChartContextValue {
  readonly xScale: ReturnType<typeof useScale>;
  readonly yScale: ReturnType<typeof useScale>;
  readonly chartRect: {
    readonly top: number;
    readonly left: number;
    readonly right: number;
    readonly bottom: number;
  };
}
export const XYChartContext = createContext<XYChartContextValue | undefined>(
  undefined
);

export function useXYChartContext() {
  const value = useContext(XYChartContext);

  if (!value) {
    throw new Error('XYChartContext not defined');
  }

  return value;
}
