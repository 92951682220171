const degToRad = (deg: number) => (deg * Math.PI) / 180.0; // Convert degrees to radians using the formula: radians = (degrees * Math.PI) / 180

export function getRotatedHeight(
  width: number,
  height: number,
  rotationInDeg: number
) {
  return (
    width * Math.sin(degToRad(Math.abs(rotationInDeg))) +
    height * Math.cos(degToRad(Math.abs(rotationInDeg)))
  );
}
