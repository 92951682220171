import {
  AlertLayerOptions,
  ContextLayerOptions,
  InteractionLayerOptions,
  LayerHandle,
  LayerOptions,
  LayerRenderer,
  LayerType,
} from '@yarmill/types';
import { useState } from 'react';
import { useLayerManagerService } from './use-layer-manager-service';

export function useLayer(type: LayerType, options?: LayerOptions): LayerHandle;
export function useLayer(
  type: 'context',
  options?: ContextLayerOptions
): LayerHandle;
export function useLayer(
  type: 'interaction',
  options?: InteractionLayerOptions
): LayerHandle;
export function useLayer(
  type: 'alert',
  options?: AlertLayerOptions
): LayerHandle;
export function useLayer(
  type: LayerType,
  options?: Partial<LayerOptions>
): LayerHandle {
  const layersService = useLayerManagerService();

  const [layerResult, setLayerResult] = useState<LayerHandle>({
    isOpened: false,
    open(openOptions?: Partial<LayerOptions>): void {
      const render: LayerRenderer = (mountPoint, layer) => {
        setLayerResult({ isOpened: true, mountPoint, layer });
      };

      const layerOptions: LayerOptions = {
        ...options,
        ...openOptions,
        onClose: () => {
          // On close revert back to the initial layer result,
          // i.e. `this` (the object literal defined as the initial state).
          setLayerResult(this);
          options?.onClose?.();
          openOptions?.onClose?.();
        },
      };

      layersService.openLayer(type, render, layerOptions);
    },
  });

  return layerResult;
}
