import { axios, devMode, instance } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export function removeEvidenceObject(
  dataUrl: string,
  moduleKey: string,
  objectKey: string,
  ArrayIndex: number,
  userId: number | null,
  groupId: number | null
): AxiosPromise<boolean> {
  return axios.delete(dataUrl, {
    params: {
      objectCategoryKey: objectKey,
      groupId: userId === null ? groupId : undefined,
      userId: userId !== null ? userId : undefined,
      moduleKey,
      arrayIndex: ArrayIndex,
      instanceCode: devMode ? instance : undefined,
    },
  });
}
