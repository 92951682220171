import { AxisConfig, AxisValue } from '@yarmill/components';
import { useMemo } from 'react';
import { ContinuousChartStore } from '../mobx/continuous-chart-store';
import { CategoricalChartReportStore } from '../types';

export function useTranslatedAxisConfigs(
  axisConfigs: AxisConfig<AxisValue, AxisValue>[],
  report: CategoricalChartReportStore | ContinuousChartStore,
  getItem: (key: string, tickValue: AxisValue) => any
) {
  return useMemo(
    () =>
      axisConfigs.map(axisConfig =>
        axisConfig.translateValues ||
        report.xy.axisXLabel ||
        report.xy.axisYLabel
          ? {
              ...axisConfig,
              formatTick: (tickValue: AxisValue) =>
                report.xy.formatAxisValue(
                  tickValue,
                  axisConfig.axis,
                  {
                    forceString: true,
                  },
                  getItem
                ),
            }
          : axisConfig
      ),
    [axisConfigs, getItem, report.xy]
  );
}
