import { DATE_FORMAT, ROUTE_DATE_FORMAT } from '@yarmill/const';
import moment from 'moment';

export function getWeekEnd(day: Date): Date;
export function getWeekEnd(day: string | moment.Moment): moment.Moment;
export function getWeekEnd(
  day: string | moment.Moment | Date
): moment.Moment | Date {
  const date = moment(day, ROUTE_DATE_FORMAT);

  const weekEnd = date.endOf('isoWeek');
  if (day instanceof Date) {
    return weekEnd.toDate();
  }
  return weekEnd;
}

export function getWeekEndString(day: string): string {
  return getWeekEnd(day).format(DATE_FORMAT);
}
