import { Icon, IconSize, Tippy, useTheme } from '@yarmill/components';
import {
  Button,
  FakeButton,
  Header,
  HeaderPageTitle,
  SeasonSelector,
  SideMenuToggle,
} from '@yarmill/components-2';
import { Download, Plus } from '@yarmill/icons-2';
import {
  useCurrentUserStore,
  useSidebarStore,
  useWindowSize,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Link,
  matchPath,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { ExportButton } from './buttons/export';
import {
  MobileListHeaderWrapper,
  ObjectivesList as ObjectivesListLayout,
  ObjectivesListSidebar,
} from './components/objectives-list';
import { NoObjectives } from './no-objectives';
import { ObjectiveListItem } from './objective-list-item';
import { useOkrsStore } from './okrs-store-context';

export const ObjectivesList = observer(function ObjectivesList(): JSX.Element {
  const { url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const id = matchPath<{ readonly id?: string }>(location.pathname, {
    path: '/okr/:id',
  })?.params?.id;
  const intl = useIntl();
  const okrsStore = useOkrsStore();
  const sidebarStore = useSidebarStore();
  const currentUser = useCurrentUserStore();
  const theme = useTheme();
  const { width: windowWidth = 0 } = useWindowSize();

  const firstObjectiveId = okrsStore.objectives[0]?.id;

  const isAllowedToAdd = okrsStore.isCurrentUserAllowedToWrite();

  useEffect(() => {
    if (
      !id &&
      window.innerWidth >= 768 &&
      okrsStore.isReady &&
      firstObjectiveId
    ) {
      const { location } = history;
      const search = new URLSearchParams(location.search);
      history.replace({
        pathname: `/okr/${firstObjectiveId}`,
        search: search.toString(),
      });
    } else if (id && window.innerWidth < 768 && sidebarStore.isVisible) {
      sidebarStore.hide();
    }
  }, [id, firstObjectiveId, history, okrsStore.isReady, sidebarStore]);

  useEffect(() => {
    if (
      okrsStore.athleteId &&
      okrsStore.isReady &&
      id &&
      id !== 'add' &&
      !okrsStore.objectives.find(o => String(o.id) === String(id))
    ) {
      const { location } = history;
      const search = new URLSearchParams(location.search);
      history.replace({
        pathname: `/okr`,
        search: search.toString(),
      });
    }
  }, [
    okrsStore.athleteId,
    okrsStore.isReady,
    id,
    history,
    okrsStore.objectives,
  ]);

  return (
    <ObjectivesListSidebar $isRoot={!id}>
      {windowWidth < 768 && !sidebarStore.isVisible && (
        <Helmet>
          <meta name="theme-color" content={theme.color.white} />
        </Helmet>
      )}
      <Header
        left={<SideMenuToggle />}
        center={
          <MobileListHeaderWrapper>
            <HeaderPageTitle appearance="text15strong">
              {okrsStore.athleteId === currentUser.id ? (
                <FormattedMessage id="okrs.navigation.myGoals" />
              ) : (
                (okrsStore.athlete?.displayName ?? okrsStore.group?.name)
              )}
            </HeaderPageTitle>
          </MobileListHeaderWrapper>
        }
        right={
          <>
            {isAllowedToAdd && okrsStore.isReady && id !== 'add' ? (
              <Tippy tooltipContent="okrs.addObjective" noWrapper touch={false}>
                <Button
                  as={Link}
                  to={l => ({ ...l, pathname: `${url}/add` })}
                  aria-label={intl.formatMessage({ id: 'okrs.addObjective' })}
                  $iconOnly
                  $appearanceStyle="green"
                >
                  <Icon size={IconSize.s24}>
                    <Plus />
                  </Icon>
                </Button>
              </Tippy>
            ) : (
              <Button
                aria-label={intl.formatMessage({ id: 'okrs.addObjective' })}
                $iconOnly
                disabled
              >
                <Icon size={IconSize.s24}>
                  <Plus />
                </Icon>
              </Button>
            )}
          </>
        }
      />
      <ObjectivesListLayout>
        {okrsStore.objectives.map(objective => (
          <ObjectiveListItem
            key={objective.id}
            objective={objective}
            isActive={String(objective.id) === String(id)}
          />
        ))}
        {id === 'add' && (
          <ObjectiveListItem isActive isNewObjectivePlaceholder />
        )}
        {!id && okrsStore.objectives.length === 0 && okrsStore.isReady && (
          <NoObjectives />
        )}
      </ObjectivesListLayout>
      <Header
        position="footer"
        left={
          <FakeButton>
            <Icon size={IconSize.s24}>
              <Download />
            </Icon>
          </FakeButton>
        }
        center={<SeasonSelector />}
        right={
          currentUser.isAllowedTo('okr.export') ? (
            <ExportButton />
          ) : (
            <FakeButton>
              <Icon size={IconSize.s24}>
                <Download />
              </Icon>
            </FakeButton>
          )
        }
      />
    </ObjectivesListSidebar>
  );
});
