import {
  AthletesSidebar,
  PageMainContent,
  PageScrollContainer,
} from '@yarmill/components';
import { useCurrentUserStore, useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { ReactElement, memo } from 'react';
import { Redirect } from 'react-router-dom';
import { EvidenceStoreContextProvider } from './evidence-store-context-provider';
import { useEvidenceStore } from './hooks/use-evidence-store';
import { InvalidUser } from './invalid-user';
import { PageContent } from './page-content';

function InternalEvidenceRouteRoot(): ReactElement {
  const currentUser = useCurrentUserStore();
  const initialConfigLoaded = useRootStore().isReady;
  const evidenceStore = useEvidenceStore();

  if (!evidenceStore.moduleKey && initialConfigLoaded) {
    // we are going to /evidence without module key
    return <Redirect to="/" />;
  }

  const moduleConfiguration = evidenceStore.moduleConfiguration;
  const athleteEnabled = moduleConfiguration?.athleteEnabled;
  const groupEnabled = moduleConfiguration?.groupEnabled;
  const hideSidebar = !athleteEnabled && !groupEnabled;

  // if (
  //   initialConfigLoaded &&
  //   !currentUser.isAllowedTo(`evidence.${evidenceStore.moduleKey}`)
  // ) {
  //   return <Redirect to="/" />;
  // }

  if (
    !athleteEnabled &&
    !groupEnabled &&
    (evidenceStore.userId || evidenceStore.groupId)
  ) {
    return <Redirect to={`/evidence/${evidenceStore.moduleKey}`} />;
  } else if (evidenceStore.userId && !athleteEnabled && initialConfigLoaded) {
    return (
      <Redirect
        to={`/evidence/${evidenceStore.moduleKey}?group=${evidenceStore.groupId}`}
      />
    );
  }

  if (
    initialConfigLoaded &&
    !currentUser.isAthlete &&
    (athleteEnabled || groupEnabled) &&
    !evidenceStore.userId &&
    !evidenceStore.groupId
  ) {
    return <Redirect to="/noGroup" />;
  }

  return (
    <>
      {!hideSidebar && currentUser?.role !== 'athlete' ? (
        <AthletesSidebar
          disableWholeGroup={!groupEnabled}
          disableWholeGroupTooltip={`evidence.${evidenceStore.moduleKey}.sidebar.disableAllAthletes`}
          hideAthletesInGroup={!athleteEnabled}
        />
      ) : undefined}

      <PageMainContent>
        <PageScrollContainer>
          {!initialConfigLoaded || evidenceStore.isValidParamsCombination ? (
            <PageContent />
          ) : (
            <InvalidUser moduleKey={evidenceStore.moduleKey} />
          )}
        </PageScrollContainer>
      </PageMainContent>
    </>
  );
}

const ObserverEvidenceRouteRoot = observer(InternalEvidenceRouteRoot);

export const EvidenceModule = memo(() => (
  <EvidenceStoreContextProvider>
    <ObserverEvidenceRouteRoot />
  </EvidenceStoreContextProvider>
));
