import {
  RootStore,
  Season,
  SeasonId,
  SeasonViewType,
  SeasonStore as iSeasonStore,
} from '@yarmill/types';
import {
  getSeasonCycles,
  getSeasonMonths,
  getSeasonWeeks,
} from '@yarmill/utils';
import { computed, makeObservable } from 'mobx';
import moment from 'moment';

export class SeasonStore implements iSeasonStore {
  private readonly rootStore: RootStore;
  private readonly _season: Season;
  private readonly _seasonMonths: string[];
  private readonly _seasonCycles: string[];
  private readonly _seasonWeeks: string[];

  constructor(rootStore: RootStore, season: Season) {
    makeObservable(this);
    this.rootStore = rootStore;
    this._season = season;
    this._seasonMonths = getSeasonMonths(season);
    this._seasonCycles = getSeasonCycles(season);
    this._seasonWeeks = getSeasonWeeks(season);
  }

  get id(): SeasonId {
    return this._season.SeasonId;
  }

  get label(): string {
    return this._season.Name;
  }

  get name(): string {
    return this._season.Name;
  }

  get seasonMonths(): string[] {
    return this._seasonMonths;
  }

  get seasonCycles(): string[] {
    return this._seasonCycles;
  }

  get seasonWeeks(): string[] {
    return this._seasonWeeks;
  }

  get startDate(): string {
    return this._season.StartDate;
  }

  @computed
  get start(): moment.Moment {
    return moment(this.startDate);
  }

  get endDate(): string {
    return this._season.EndDate;
  }

  @computed
  get end(): moment.Moment {
    return moment(this.endDate);
  }

  get startYear(): number {
    return this._season.StartYear;
  }

  @computed
  get seasonSections(): string[] {
    return this.seasonViewType === 'month'
      ? this.seasonMonths
      : this.seasonCycles;
  }

  @computed
  get seasonViewType(): SeasonViewType {
    return this.rootStore.configStore.seasonView;
  }

  isInSeason(dateString: string): boolean {
    const date = moment(dateString);
    const start = moment(this._season.StartDate);
    const end = moment(this._season.EndDate);

    return start <= date && end >= date;
  }
}
