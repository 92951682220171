import { ButtonAppearance, Spinner } from '@yarmill/components';
import { FormState } from '@yarmill/types';
import { Fragment, ReactElement } from 'react';

export function getSubmitButtonIcon(state: FormState): ReactElement | null {
  switch (state) {
    case 'submitting':
      return (
        <Fragment>
          <Spinner />
          &nbsp;
        </Fragment>
      );

    case 'error':
      return (
        <Fragment>
          <span>×</span>
          &nbsp;
        </Fragment>
      );

    case 'success':
      return (
        <Fragment>
          <span>✓</span>
          &nbsp;
        </Fragment>
      );

    default:
      return null;
  }
}

export function getSubmitButtonAppearance(state: FormState): ButtonAppearance {
  switch (state) {
    case 'error':
      return ButtonAppearance.Error;

    case 'success':
      return ButtonAppearance.Success;

    default:
      return ButtonAppearance.Primary;
  }
}
