import { UserStore } from '@yarmill/types';
import { AttendanceValueButton } from './attendance-value-button';
import { WeekAttendanceItemStore } from './mobx/week-attendance-item-store';
import { AttendanceValue, attendanceValues } from './types';

interface AttendanceValueButtonsProps {
  date: string;
  item: WeekAttendanceItemStore | undefined;
  user?: UserStore;
  handleClick?: (type: AttendanceValue) => void;
  disabled?: boolean;
}

export function AttendanceValueButtons(
  props: AttendanceValueButtonsProps
): JSX.Element {
  return (
    <>
      {attendanceValues.map(type => (
        <AttendanceValueButton key={type} type={type} {...props} />
      ))}
    </>
  );
}
