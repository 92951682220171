import { AsyncStatus } from '@yarmill/types';
import { useIntl } from 'react-intl';
import { Id as ToastId, ToastOptions, toast } from 'react-toastify';
import { AsyncToastMessage } from '../async-toast-message';

interface GenericAsyncToastMessageProps {
  message: string;
  status: AsyncStatus;
}

function GenericAsyncToastMessage(
  props: GenericAsyncToastMessageProps
): JSX.Element {
  const { message, status } = props;
  const intl = useIntl();

  return (
    <AsyncToastMessage
      title={intl.formatMessage({ id: message })}
      status={status}
    />
  );
}

const showOptions: ToastOptions = {
  autoClose: false,
  closeOnClick: true,
  position: 'bottom-right',
  className: 'ytd-toast',
  icon: false,
  hideProgressBar: true,
  closeButton: false,
};

const successGenericAsyncToastOptions: ToastOptions = {
  ...showOptions,
  autoClose: 5000,
  className: 'ytd-toast success',
};

const errorGenericAsyncToastOptions: ToastOptions = {
  ...showOptions,
  autoClose: 5000,
  className: 'ytd-toast error',
};

export const showGenericAsyncToastMessage = (
  message: string,
  cancelGenericAsyncToast: () => void
): ToastId =>
  toast(
    <GenericAsyncToastMessage message={message} status={AsyncStatus.pending} />,
    {
      ...showOptions,
      onClose: cancelGenericAsyncToast,
    }
  );

export const showGenericAsyncSuccessToastMessage = (
  id: ToastId,
  message: string
) =>
  toast.update(id, {
    ...successGenericAsyncToastOptions,
    render: (
      <GenericAsyncToastMessage
        message={message}
        status={AsyncStatus.resolved}
      />
    ),
  });

export const showGenericAsyncErrorToastMessage = (
  id: ToastId,
  message: string
) => {
  const toastContent = (
    <GenericAsyncToastMessage message={message} status={AsyncStatus.rejected} />
  );

  if (!id) {
    toast(toastContent, errorGenericAsyncToastOptions);
  } else {
    toast.update(id, {
      ...errorGenericAsyncToastOptions,
      render: toastContent,
    });
  }
};
