import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export interface SetResetedPasswordRequestParams {
  NewPassword: string;
}

export interface SetResetedPasswordResponse {
  Message: string;
}

export function setResetedPassword(
  params: SetResetedPasswordRequestParams
): AxiosPromise<SetResetedPasswordResponse> {
  return axios.put('api/Account/ResetPassword', params);
}
