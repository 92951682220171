import { RefObject, useEffect, useState } from 'react';

export function useIsElementInViewport(
  element: RefObject<HTMLElement>,
  options?: IntersectionObserverInit
): boolean {
  const [isInViewPort, setIsInViewPort] = useState(false);

  useEffect(() => {
    if (typeof IntersectionObserver === 'undefined') {
      return;
    }
    const target = element.current;
    const observer = new IntersectionObserver(([entry]) => {
      setIsInViewPort(entry.isIntersecting);
    }, options);

    target && observer.observe(target);

    return () => {
      target && observer.unobserve(target);
    };
  }, [element, options]);

  return isInViewPort;
}
