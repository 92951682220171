import { TextProps } from '@visx/text';
import { AXIS_TICK_FONT_STYLES } from '../../reporting/const';

export function getAxisXTickLabelFormatter(
  angle?: number,
  width?: number,
  offset?: number
): () => Partial<TextProps> {
  return () => ({
    ...AXIS_TICK_FONT_STYLES,
    textAnchor: angle ? (angle < 0 ? 'end' : 'start') : 'middle',
    verticalAnchor: 'middle',
    width,
    angle,
    dy: offset ? -1 * (offset / 2) : 0,
  });
}
