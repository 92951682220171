import styled, { css } from 'styled-components';
import { Button } from '../button';

export const PopoverContainer = css<{ $autoWidth?: boolean }>`
  padding: 12px;
  box-shadow: 0 2px 18px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  text-align: left;
  font-family: Ubuntu;

  background-clip: padding-box;
  border: 1px solid rgba(0, 8, 16, 0.15);
  box-sizing: border-box;
  transition: all 250ms ease;

  &[data-animation='fade'][data-state='hidden'] {
    opacity: 0;
  }

  color: #4a4a4a;
  & > .tippy-content {
    padding: 5px 9px;
  }
  ${({ $autoWidth }) => !$autoWidth && `width: 300px;`};

  ${({ $autoWidth }) =>
    !$autoWidth &&
    css`
      @media (max-width: 767px) {
        width: 250px;
      }
      @media (max-width: 575px) {
        width: 200px;
      }
    `}

  & > .tippy-arrow {
    color: #333;
    height: 16px;
    width: 16px;

    &:before {
      border-color: transparent;
      border-style: solid;
      content: '';
      position: absolute;
    }

    :after {
      border-color: transparent;
      border-style: solid;
      content: '';
      position: absolute;
      z-index: -1;
    }
  }

  &[data-placement^='bottom'] > .tippy-arrow {
    top: 1px;

    :before {
      border-bottom-color: #fff;
      bottom: 16px;
      border-width: 0 8px 8px;
      left: 0;
      top: -7px;
      transform-origin: center bottom;
    }

    :after {
      border-bottom-color: rgba(0, 8, 16, 0.2);
      border-width: 0 7px 7px;
      bottom: 17px;
      left: 1px;
    }
  }
  &[data-placement^='top'] > .tippy-arrow {
    bottom: 1px;

    :before {
      border-top-color: #fff;
      border-width: 8px 8px 0;
      bottom: -7px;
      left: 0;
      transform-origin: center top;
    }

    :after {
      border-top-color: rgba(0, 8, 16, 0.2);
      border-width: 7px 7px 0;
      left: 1px;
      top: 17px;
    }
  }
`;

export type PopoverItemAppearance = 'default' | 'danger' | 'disabled';
export const PopoverItemStyles = css<{
  readonly appearance?: PopoverItemAppearance;
}>`
  padding: 12px;
  border: 0;
  background-color: #fff;
  box-sizing: border-box;
  font-family: Ubuntu;
  text-align: left;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  color: #4a4a4a;

  @media (max-width: 767px) {
    padding: 12px 6px;
  }

  &:hover {
    color: #4a90e2;
  }

  ${({ appearance }) =>
    appearance === 'disabled' &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      :hover {
        color: #4a4a4a;
      }
    `}

  ${({ appearance }) =>
    appearance === 'danger' &&
    css`
      color: #d0021b;
      &:hover {
        color: #ff0000;
      }
    `}

  font-size: 12px;
`;

export const StyledPopoverItem = styled.button<{
  readonly appearance?: PopoverItemAppearance;
}>`
  ${PopoverItemStyles};
`;

export const PopoverIconWrapper = styled.div`
  width: 20px;
  margin-right: 12.5px;
`;

export const PopoverContentWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 5px;
`;

export const PopoverToggleButton = styled(Button)`
  border-color: transparent;
  display: flex;
  align-items: center;
  :hover {
    border-color: #4a4a4a;
  }
  :focus-visible {
    color: #4a90e2;
    border-color: #4a90e2;
  }
`;

export const SingleLinePopoverItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  @media (min-width: 768px) {
    column-gap: 12px;
  }
`;
