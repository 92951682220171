import { BarGroup as VisxBarGroup } from '@visx/shape/lib/types/barGroup';
import { BarGroupChart } from '../../types';
import { Group } from '@visx/group';
import { Bar } from './bar';
import { DataItem } from '../../../reporting/types';
import { useXYChartContext } from '../../xy-chart-context';
import { getAxisPosition } from '../../../reporting/utils/get-axis-position';

interface BarGroupProps {
  readonly group: VisxBarGroup<string>;
  readonly config: BarGroupChart;
  readonly item: DataItem;
}

export function BarGroup({ group, config, item }: BarGroupProps) {
  const { yScale } = useXYChartContext();
  const domainY = yScale.domain();
  // Bar Chart works only for categorical scale
  if ('bandwidth' in yScale) {
    return null;
  }

  return (
    <Group left={group.x0}>
      {group.bars.map(bar => {
        const yValue = bar.value;
        const axisPosition = getAxisPosition(domainY as number[]);
        const y = yValue < 0 ? yScale(axisPosition) : yScale(yValue);
        const height = Math.abs(yScale(yValue) - yScale(axisPosition));

        return (
          <Bar
            key={bar.key}
            x={
              bar.x + ((1 - config.getBarWidth(item, bar.key)) * bar.width) / 2
            }
            y={y}
            width={bar.width * config.getBarWidth(item, bar.key)}
            height={height}
            opacity={config.getOpacity(item, bar.key) ?? 1}
            color={config.getColor(item, bar.key)}
          />
        );
      })}
    </Group>
  );
}
