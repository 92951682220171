import styled from 'styled-components';
import { Text } from '../text';

type StatusAppearance = 'success' | 'error' | 'info';
export const NavbarStatus = styled.span<{
  readonly appearance: StatusAppearance;
}>`
  display: none;

  @media (min-width: 768px) {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 4px;

    color: ${({ appearance }) =>
      appearance === 'success'
        ? '#22a061'
        : appearance === 'error'
          ? '#dc3545'
          : '#4a4a4a'};
  }
`;

export const NavbarStatusText = styled(Text)`
  @media (max-width: 1300px) {
    display: none;
  }
`;
