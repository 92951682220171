import { StyledTd, styled } from '@yarmill/components';
import ContentLoader from 'react-content-loader';

const StyledCheckboxLoader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 32px;
    height: 32px;
  }
`;

const Td = styled(StyledTd)`
  position: relative;
`;

export function LoadingCell(): JSX.Element {
  return (
    <Td disabled>
      <StyledCheckboxLoader>
        <ContentLoader
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          viewBox="0 0 32 32"
          width={32}
          height={32}
        >
          <circle cx="16" cy="16" r="16" />
        </ContentLoader>
      </StyledCheckboxLoader>
    </Td>
  );
}
