import { HorizontalBarGroupChart } from '../types';
import { useXYChartContext } from '../xy-chart-context';
import { useMemo } from 'react';
import { BarGroupHorizontal as VisxBarGroupHorizontal } from '@visx/shape';
import { DataItem } from '../../reporting/types';
import { createKeysScale } from '../utils/create-keys-scale';
import { HorizontalBarGroup } from './primitives/horizontal-bar-group';

interface ClusteredBarsProps {
  readonly config: HorizontalBarGroupChart;
  readonly data: DataItem[];
}

const noop = () => '';

export function HorizontalClusteredBars({ config, data }: ClusteredBarsProps) {
  const { xScale, yScale, chartRect } = useXYChartContext();

  if (!('bandwidth' in yScale)) {
    throw new Error('Clustered bars are available only for categorical scale');
  }

  const yBandwidth = yScale.bandwidth();
  const keysScale = useMemo(
    () => createKeysScale(config.keys, yBandwidth),
    [config.keys, yBandwidth]
  );

  return (
    <VisxBarGroupHorizontal
      data={data}
      keys={config.keys}
      width={chartRect.right - chartRect.left}
      y0={config.getYValue as () => string}
      y0Scale={yScale}
      y1Scale={keysScale}
      xScale={xScale}
      color={noop}
    >
      {barGroups =>
        barGroups.map((barGroup, idx) => (
          <HorizontalBarGroup
            key={`bar-group-${barGroup.index}-${barGroup.y0}`}
            group={barGroup}
            config={config}
            item={data[idx]}
          />
        ))
      }
    </VisxBarGroupHorizontal>
  );
}
