import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { TransitionGroupProps } from '../helpers';

const animationClassName = 'animation';
const animationTimeout = 300;
const animationTimingFunction = 'ease';

export interface ContextLayerPositionProps {
  readonly top: number | string;
  readonly left: number | string;
  readonly verticalAlignment?: 'top' | 'bottom';
  readonly horizontalAlignment?: 'left' | 'right';
}

const StyledContextLayer = styled.div<ContextLayerPositionProps>`
  position: absolute;
  top: ${({ top }) => (typeof top === 'string' ? top : `${top}px`)};
  left: ${({ left }) => (typeof left === 'string' ? left : `${left}px`)};
  transition: opacity ${animationTimeout}ms ${animationTimingFunction};

  transform: ${({ horizontalAlignment, verticalAlignment }) =>
    `translate(${horizontalAlignment === 'left' ? '-100%' : 0},${
      verticalAlignment === 'top' ? '-100%' : 0
    });`};

  &.${animationClassName}-exit, &.${animationClassName}-enter-done {
    opacity: 1;
  }

  &.${animationClassName}-enter,
    &.${animationClassName}-exit-active,
    &.${animationClassName}-exit-done {
    opacity: 0;
  }

  &.${animationClassName}-enter-active {
    opacity: 1;
  }
`;

export type ContextLayerProps = React.PropsWithChildren<
  ContextLayerPositionProps & TransitionGroupProps
>;

export function ContextLayer(props: ContextLayerProps): JSX.Element {
  const {
    active,
    children,
    in: visible,
    onExited,
    top,
    left,
    verticalAlignment,
    horizontalAlignment,
  } = props;

  return (
    <CSSTransition
      classNames={animationClassName}
      onExited={onExited}
      in={visible}
      timeout={animationTimeout}
    >
      <StyledContextLayer
        aria-modal={active}
        top={top}
        left={left}
        verticalAlignment={verticalAlignment}
        horizontalAlignment={horizontalAlignment}
      >
        {children}
      </StyledContextLayer>
    </CSSTransition>
  );
}
