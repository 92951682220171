import styled from 'styled-components';

export enum IconSize {
  s6 = '6px',
  s8 = '8px',
  s10 = '10px',
  s12 = '12px',
  s14 = '14px',
  s15 = '15px',
  s16 = '16px',
  s17 = '17px',
  s18 = '18px',
  s20 = '20px',
  s22 = '22px',
  s24 = '24px',
  s28 = '28px',
  s32 = '32px',
  s48 = '48px',
  s56 = '56px',
  s80 = '80px',
}

export interface IconProps {
  size?: IconSize;
}

export const Icon = styled.span<IconProps>`
  height: ${props => props.size || IconSize.s16};
  width: ${props => props.size || IconSize.s16};

  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg,
  img {
    width: ${props => props.size || IconSize.s16};
    height: ${props => props.size || IconSize.s16};
  }
`;
