import { Icon, IconSize } from '@yarmill/components';
import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from '@yarmill/const';
import {
  Account,
  Cal,
  Checklist,
  Down,
  Goal,
  Numbers,
  Settings,
  Team,
} from '@yarmill/icons-2';
import { UserGroupId } from '@yarmill/types';
import {
  useCommonUrlParams,
  useCurrentUserAllowedGroups,
  useCurrentUserStore,
  useDefaultCommonSearchParams,
  useGroup,
  useRootStore,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DropdownOption, DropdownProvider } from '../dropdown-provider';
import { VerticalFlexSpacer } from '../flex-utils';
import {
  GroupsToggle,
  GroupsToggleTextWrapper,
  NavigationDropdownToggleWrapper,
  NavigationModulesContainer,
  NavigationTeamContainer,
  SidebarNavigationContainer,
  SidebarNavigationSectionLabel,
} from '../sidebar-navigation';
import { Text } from '../text';
import { AthletesList } from './athletes-list';
import { NavigationItem } from './navigation-item';

export const Navigation = observer(function Navigation(): JSX.Element {
  const intl = useIntl();
  const modulesRegistryService = useRootStore().modulesRegistryService;
  const plan = modulesRegistryService.enabledModules.find(
    module => module.moduleCode === 'plan'
  );
  const reality = modulesRegistryService.enabledModules.find(
    module => module.moduleCode === 'reality'
  );
  const okrs = modulesRegistryService.enabledModules.find(
    module => module.moduleCode === 'okr'
  );
  const planner = modulesRegistryService.enabledModules.find(
    module => module.moduleCode === 'planner'
  );
  const settingsLink = '/settings/personal';
  const rootStore = useRootStore();
  const historyService = rootStore.historyService;
  const { group } = useCommonUrlParams();
  const currentUser = useCurrentUserStore();
  const defaultCommonSearchParams = useDefaultCommonSearchParams();
  const selectedGroup = useGroup(group);
  const groups = useCurrentUserAllowedGroups();
  const pathname = historyService.pathname;
  const history = historyService.history;

  const groupDropdownOptions: DropdownOption<UserGroupId>[] = useMemo(
    () =>
      groups.map(group => ({
        onClick: () => {
          const { location } = history;
          const searchParams = new URLSearchParams(location.search);
          searchParams.set(GROUP_SEARCH_PARAM, String(group.id));
          searchParams.delete(ATHLETE_SEARCH_PARAM);
          history.push({ ...location, search: searchParams.toString() });
        },
        label: group.name,
        value: group.id,
      })),
    [groups, history]
  );

  return (
    <SidebarNavigationContainer>
      <SidebarNavigationSectionLabel>
        <FormattedMessage id="sidebar.navigation.menu" />
      </SidebarNavigationSectionLabel>
      <NavigationModulesContainer>
        {reality && currentUser.isAllowedTo('reality') && (
          <NavigationItem
            icon={<Numbers />}
            text={intl.formatMessage({ id: 'header.navigation.reality' })}
            to={reality.navigationLinks[0].createClickHandler!(
              defaultCommonSearchParams
            )}
            isActive={pathname.includes('reality')}
          />
        )}
        {plan && currentUser.isAllowedTo('plan') && (
          <NavigationItem
            icon={<Checklist />}
            text={intl.formatMessage({ id: 'header.navigation.plan' })}
            to={plan.navigationLinks[0].createClickHandler!(
              defaultCommonSearchParams
            )}
            isActive={
              pathname.includes('plan') && !pathname.includes('planner')
            }
          />
        )}
        {okrs && currentUser.isAllowedTo('okr') && (
          <NavigationItem
            icon={<Goal />}
            text={intl.formatMessage({ id: 'header.navigation.okrs' })}
            to={okrs.navigationLinks[0].createClickHandler!(
              defaultCommonSearchParams
            )}
            isActive={pathname.includes('okr')}
          />
        )}
        {planner && currentUser.isAllowedTo('planner') && (
          <NavigationItem
            icon={<Cal />}
            text={intl.formatMessage({ id: 'header.navigation.planner' })}
            to={planner.navigationLinks[0].createClickHandler!(
              defaultCommonSearchParams
            )}
            isActive={pathname.includes('planner')}
          />
        )}
        <NavigationItem
          icon={<Settings />}
          text={intl.formatMessage({ id: 'header.navigation.settings' })}
          to={settingsLink}
        />
      </NavigationModulesContainer>
      {!currentUser.isAthlete &&
        (groupDropdownOptions.length > 0 || pathname.includes('okr')) && (
          <>
            <SidebarNavigationSectionLabel>
              <FormattedMessage id="sidebar.navigation.team" />
            </SidebarNavigationSectionLabel>

            {(groupDropdownOptions.length > 0 || pathname.includes('okr')) && (
              <NavigationTeamContainer>
                {pathname.includes('okr') && (
                  <NavigationItem
                    icon={<Account />}
                    isActive={
                      historyService.searchParams.get(ATHLETE_SEARCH_PARAM) ===
                      String(currentUser.id)
                    }
                    text={intl.formatMessage({ id: 'okrs.navigation.myGoals' })}
                    to={l => {
                      const search = new URLSearchParams(l.search);
                      search.set(ATHLETE_SEARCH_PARAM, String(currentUser.id));
                      return {
                        pathname: '/okr',
                        search: search.toString(),
                      };
                    }}
                  />
                )}
                {groupDropdownOptions.length > 0 && (
                  <>
                    <NavigationDropdownToggleWrapper>
                      <DropdownProvider
                        options={groupDropdownOptions}
                        placement="bottom-start"
                        label={intl.formatMessage({
                          id: 'sidebar.navigation.team',
                        })}
                        selectedValue={selectedGroup?.id}
                      >
                        <GroupsToggle as="div">
                          <GroupsToggleTextWrapper>
                            <Icon size={IconSize.s16}>
                              <Team />
                            </Icon>

                            <Text
                              lineClamp={2}
                              appearance="task13strong"
                              inheritColor
                            >
                              {selectedGroup?.name}
                            </Text>
                          </GroupsToggleTextWrapper>

                          <Icon>
                            <Down />
                          </Icon>
                        </GroupsToggle>
                      </DropdownProvider>
                    </NavigationDropdownToggleWrapper>

                    {selectedGroup && (
                      <AthletesList
                        group={selectedGroup}
                        isVisible
                        toggleGroups={() => {}}
                        includeCoaches={pathname.includes('okr')}
                        hideCurrentUser={pathname.includes('okr')}
                        showWholeGroupItem
                      />
                    )}
                  </>
                )}
              </NavigationTeamContainer>
            )}
          </>
        )}
      <VerticalFlexSpacer />
    </SidebarNavigationContainer>
  );
});
