export function generateUrl(
  searchParams: Record<string, string | number | null>,
  href: string = window.location.href
): string {
  const url = new URL(href);

  Object.entries(searchParams).forEach(([param, value]) => {
    if (value !== null) {
      url.searchParams.set(param, String(value));
    } else {
      url.searchParams.delete(param);
    }
  });

  return `${url.pathname}${url.search}`;
}
