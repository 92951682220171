import { BarStack as VisxBarStack } from '@visx/shape/lib/types';
import { HorizontalStackedBarChart } from '../../types';
import { useMemo } from 'react';
import { groupStackedBarsByCategories } from '../../utils/group-stacked-bars-by-categories';
import { useXYChartContext } from '../../xy-chart-context';
import { HorizontalBarStack } from './horizontal-bar-stack';
import { DataItem } from '../../../reporting/types';
import { sortHorizontalStackedBarsByValue } from '../../utils/sort-horizontal-stacked-bars-by-value';

interface HorizontalBarsStacksProps {
  readonly config: HorizontalStackedBarChart;
  readonly stacks: VisxBarStack<DataItem, string>[];
  readonly data: DataItem[];
}

export function HorizontalBarsStacks({
  stacks,
  config,
  data,
}: HorizontalBarsStacksProps) {
  const { xScale } = useXYChartContext();
  const groupedStacks = useMemo(
    () => groupStackedBarsByCategories(stacks, 'y'),
    [stacks]
  );
  const sortedStacks = useMemo(
    () =>
      groupedStacks.map(group =>
        config.sort.toLowerCase() === 'value'
          ? sortHorizontalStackedBarsByValue(group, xScale)
          : group
      ),
    [config.sort, groupedStacks, xScale]
  );

  const filteredStacks = useMemo(
    () => sortedStacks.map(stack => stack.filter(bar => bar.width)),
    [sortedStacks]
  );

  return (
    <>
      {filteredStacks.map((stack, idx) => (
        <HorizontalBarStack
          key={idx}
          stack={stack}
          stackIndex={idx}
          config={config}
          item={data[stack[0]?.index]}
        />
      ))}
    </>
  );
}
