import {
  ConflictResolution,
  CopyConflictResponse,
  UserGroupId,
  UserId,
  CopyConflictErrorResponse as iCopyConflictErrorResponse,
} from '@yarmill/types';

export class CopyConflictErrorResponse
  extends Error
  implements iCopyConflictErrorResponse
{
  private _conflictingIds: UserId[] | UserGroupId[];
  private _resolutions: ConflictResolution[];
  constructor(msg: string, conflictResponse: CopyConflictResponse) {
    super(msg);
    this._conflictingIds = conflictResponse.ConflictingIds;
    this._resolutions = conflictResponse.Resolutions;
    Object.setPrototypeOf(this, CopyConflictErrorResponse.prototype);
  }

  get conflictingIDs(): UserId[] | UserGroupId[] {
    return this._conflictingIds;
  }

  get resolutions(): ConflictResolution[] {
    return this._resolutions;
  }
}
