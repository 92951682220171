import { useHistory } from '@yarmill/utils';
import { Action, Location as HLocation } from 'history';
import { useEffect } from 'react';

export function useSameLocationTransitionBlocker(): void {
  const history = useHistory();

  useEffect(() => {
    let prevPath = window.location.pathname;
    let prevSearch = window.location.search;

    function blockSameUrlTransition(location: HLocation<any>, action: Action) {
      const nextLocation = location.pathname + location.search;

      if (action === 'PUSH') {
        if (prevPath + prevSearch === nextLocation) {
          return false;
        }
      }

      prevPath = location.pathname;
      prevSearch = location.search;
    }

    return history.block(blockSameUrlTransition);
  }, [history]);
}
