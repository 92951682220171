import { ModuleDefinitionStore, RootStore } from '@yarmill/types';
import { LegacyAnalyticsStore } from './legacy-analytics-store';

export class LegacyAnalyticsModuleDefinitionStore
  implements ModuleDefinitionStore
{
  public readonly moduleCode = 'analytics';
  private readonly _legacyAnalyticsStore: LegacyAnalyticsStore;

  constructor(rootStore: RootStore) {
    this._legacyAnalyticsStore = new LegacyAnalyticsStore(rootStore);
  }

  get legacyAnalyticsStore() {
    return this._legacyAnalyticsStore;
  }

  navigationLinks = [];
  commandPaletteCommands = [];
}
