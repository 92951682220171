import { DataItem } from '../../reporting';
import { LABEL_FONT_SIZE } from '../../reporting/const';
import { getRotatedHeight } from '../../reporting/utils/get-rotated-height';
import { getSSRStringWidth } from '../../reporting/utils/get-ssr-string-width';
import { useScale } from '../hooks/use-scale';
import { DataLabelDefinition, FloatingBarChart } from '../types';
import { getBarLabelOffset } from './get-bar-label-offset';
import { getBarLabelY } from './get-bar-label-y';
import { getFloatingBarDimensions } from './get-floating-bar-dimensions';

export function getFloatingBarLabel(
  item: DataItem,
  xScale: ReturnType<typeof useScale>,
  yScale: ReturnType<typeof useScale>,
  chartConfig: FloatingBarChart,
  priority: number
): DataLabelDefinition | undefined {
  const dimensions = getFloatingBarDimensions(
    chartConfig,
    item,
    xScale,
    yScale
  );
  const showLabel = chartConfig.getShowLabels(item);
  if (!dimensions || !showLabel) {
    return undefined;
  }

  const { x, height: barHeight, width } = dimensions;
  const yValue = chartConfig.getYValue(item);
  const formattedValue =
    yValue !== null ? (chartConfig.formatLabelValue?.(yValue) ?? yValue) : null;
  const color = chartConfig.getLabelColor(item);
  const position = chartConfig.labelPosition;
  const y = getBarLabelY(yScale, yValue);
  const angle = chartConfig.getLabelAngle(item);
  const labelWidth = getSSRStringWidth(formattedValue ?? '');
  const labelOffset = getBarLabelOffset(
    barHeight,
    yValue,
    position,
    angle,
    labelWidth
  );
  const labelHeight = getRotatedHeight(labelWidth, LABEL_FONT_SIZE, angle);

  if (
    labelHeight + Math.abs(labelOffset) > barHeight &&
    position !== 'outside'
  ) {
    return undefined;
  }

  return {
    x: x + width / 2,
    y: y - labelOffset,
    originalValue: yValue,
    text: String(formattedValue),
    angle,
    color,
    priority,
    maxWidth: position !== 'outside' ? width : undefined,
    maxHeight: undefined,
  };
}
