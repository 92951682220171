import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from '@yarmill/const';
import { GROUP_URL_FILTER, USER_URL_FILTER } from './types';

export function getMappedFilterId(filterKey: string): string {
  let filterId = filterKey;

  if (filterKey === USER_URL_FILTER) {
    filterId = ATHLETE_SEARCH_PARAM;
  } else if (filterKey === GROUP_URL_FILTER) {
    filterId = GROUP_SEARCH_PARAM;
  }

  return filterId;
}

export function setFilterValue(
  searchParams: URLSearchParams,
  filterKey: string,
  value: string | number | undefined
): void {
  const filterId = getMappedFilterId(filterKey);

  if (value === undefined || Number(value) === -1) {
    searchParams.delete(filterId);
  } else {
    searchParams.set(filterId, String(value));
  }
}
