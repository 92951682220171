import { AxisLeft, AxisRight, TickRendererProps } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { BarGroupHorizontal, Bar as VisxBar } from '@visx/shape';
import { TextProps } from '@visx/text';
import { Fragment, useCallback, useMemo } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { HeartRateZone } from '../heart-rate';

import { isBrowser } from '@yarmill/utils';
import { ANIMATION_DURATION } from '../reporting/const';
import { getSSRStringWidth } from '../reporting/utils/get-ssr-string-width';

export interface ZoneData extends HeartRateZone {
  time: number;
  name: string | null;
}

export type { TickRendererProps } from '@visx/axis';
export interface ZonesProps {
  data: ZoneData[];
  height: number;
  width: number;
  formatZoneLabel(zone: number): string;
  zoneLabelComponent?: (props: TickRendererProps) => JSX.Element;
  formatDuration?(value: number): string;
}

const AnimatedBar = animated(VisxBar);

const ChartWrapper = styled.div`
  position: relative;
`;

const labelFont = {
  fontSize: 14,
  fontFamily: 'Ubuntu',
};

const AXIS_GAP = 20;
const NATIVE_GAP = 10;

const TICK_FORMAT: TextProps = {
  ...labelFont,
  fill: '#4A4A4A',
  fontSize: 14,
  textAnchor: 'end',
  verticalAnchor: 'middle',
};

const TICK_FORMAT_RIGHT: TextProps = {
  ...TICK_FORMAT,
  fontFamily: 'Roboto Mono',
  transform: undefined,
  textAnchor: 'start',
  width: 70,
};

const TICK_FORMAT_PERCENTS: TextProps = {
  ...TICK_FORMAT_RIGHT,
  fill: '#d4d6d9',
  width: 35,
};

export function Zones(props: ZonesProps): JSX.Element {
  const {
    width,
    height,
    data,
    formatZoneLabel,
    formatDuration,
    zoneLabelComponent,
  } = props;

  const total = useMemo(
    () => data.reduce((sum, item) => sum + item.time, 0),
    [data]
  );

  const categories = useMemo(
    () => data.map(d => d.id).sort((a, b) => b - a),
    [data]
  );

  const longestLabelWidth = useMemo(
    () =>
      Math.ceil(
        Math.max(...categories.map(c => getSSRStringWidth(formatZoneLabel(c))))
      ),
    [categories, formatZoneLabel]
  );

  const timeInZoneLabel = useCallback(
    (zone: number): string => {
      const zoneData = data.find(d => d.id === zone);
      if (zoneData) {
        return formatDuration
          ? `${formatDuration(zoneData.time)}`
          : `${zoneData.time}`;
      }

      return '';
    },
    [data, formatDuration]
  );

  const percentsInZoneLabel = useCallback(
    (zone: number): string => {
      const zoneData = data.find(d => d.id === zone);
      const allInOne = data.find(d => d.time === total);

      if (zoneData) {
        const percents = Math.round(zoneData.time / (total / 100));

        return `${percents < 10 ? '\u00A0' : ''}${
          percents < 100 && allInOne ? '\u00A0' : ''
        }${percents}%`;
      }

      return '';
    },
    [data, total]
  );

  const leftLabelWidth = Math.min(longestLabelWidth, 85);

  const xMin = leftLabelWidth + AXIS_GAP + NATIVE_GAP;
  const xMax =
    width -
    NATIVE_GAP -
    Number(TICK_FORMAT_RIGHT.width) -
    Number(TICK_FORMAT_PERCENTS.width) -
    2 * AXIS_GAP;
  const yMax = height;
  const barWidth = xMax - leftLabelWidth - AXIS_GAP - NATIVE_GAP;

  const yScale = useMemo(
    () =>
      scaleBand<number>({
        range: [0, yMax],
        round: false,
        domain: categories,
        padding: 0.2,
      }),
    [categories, yMax]
  );
  const keysScale = useMemo(
    () =>
      scaleBand<string>({
        domain: ['zone'],
        padding: 0,
      }).rangeRound([0, yScale.bandwidth()]),
    [yScale]
  );

  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [xMin, xMax],
        round: true,
        domain: [0, total],
      }),
    [xMax, total, leftLabelWidth]
  );

  const { scale: animation } = useSpring({
    from: { scale: isBrowser ? 0 : 1 },
    to: { scale: 1 },
    config: {
      duration: ANIMATION_DURATION,
    },
  });

  return (
    <ChartWrapper>
      <svg width={width} height={height}>
        <AxisLeft
          left={leftLabelWidth + NATIVE_GAP}
          hideAxisLine
          hideTicks
          scale={yScale}
          tickLabelProps={id => ({
            ...TICK_FORMAT,
            width: leftLabelWidth,
            zoneId: id,
          })}
          tickFormat={formatZoneLabel}
          tickComponent={zoneLabelComponent}
          numTicks={categories.length}
        />
        <AxisRight
          hideAxisLine
          hideTicks
          scale={yScale}
          tickLabelProps={() => TICK_FORMAT_PERCENTS}
          labelOffset={0}
          tickFormat={percentsInZoneLabel}
          numTicks={categories.length}
          left={xMax + AXIS_GAP}
          rangePadding={0}
        />
        <AxisRight
          hideAxisLine
          hideTicks
          scale={yScale}
          labelOffset={0}
          rangePadding={0}
          tickLabelProps={() => TICK_FORMAT_RIGHT}
          tickFormat={timeInZoneLabel}
          numTicks={categories.length}
          left={xMax + Number(TICK_FORMAT_PERCENTS.width) + 2 * AXIS_GAP}
        />
        <BarGroupHorizontal
          data={data}
          color={() => '#f5f6f8'}
          keys={['time']}
          y0={item => item.id}
          xScale={xScale}
          y0Scale={yScale}
          y1Scale={keysScale}
          width={barWidth}
          left={xMin}
        >
          {barGroups =>
            barGroups.map(group => (
              <Group key={group.index} top={group.y0}>
                {group.bars.map(bar => {
                  return (
                    <Fragment key={bar.index}>
                      <rect
                        x={xScale(0)}
                        y={bar.y}
                        fill="#f5f6f8"
                        height={bar.height}
                        width={barWidth}
                        rx={4}
                      />
                      {bar.value && (
                        <AnimatedBar
                          key={bar.key}
                          x={xScale(0)}
                          y={bar.y}
                          width={animation.to(
                            s => s * Math.abs(xScale(bar.value) - xScale(0))
                          )}
                          height={bar.height}
                          fill="#000"
                          rx={4}
                          pointerEvents="none"
                        />
                      )}
                    </Fragment>
                  );
                })}
              </Group>
            ))
          }
        </BarGroupHorizontal>
      </svg>
    </ChartWrapper>
  );
}
