import moment from 'moment';
import * as React from 'react';
import { FormattedDate } from 'react-intl';
import styled from 'styled-components';
import { Button, ButtonAppearance } from './button';
import { ExternalIcon } from './external-icon';
import { Icon, IconSize } from './icon';
import { Text, TextAlignment, TextSize } from './text';

const StyledDaySelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 35%;
`;

const StyledDateWrapper = styled(Text)`
  text-transform: capitalize;
`;

export interface DaySelectorProps {
  onPrevClick: React.MouseEventHandler;
  onNextClick: React.MouseEventHandler;
  date: string;
}

export function DaySelector(props: DaySelectorProps) {
  const date = moment(props.date).toDate();

  return (
    <StyledDaySelector>
      <Button
        square
        noShadow
        onClick={props.onPrevClick}
        appearance={ButtonAppearance.Navigation}
        data-cy="prev-day"
      >
        <Icon size={IconSize.s20}>
          <ExternalIcon name="ChevronLeft" />
        </Icon>
      </Button>

      <StyledDateWrapper textAlign={TextAlignment.center} size={TextSize.s12}>
        <FormattedDate value={date} weekday="short">
          {(msg: string) => <Text bold>{msg}</Text>}
        </FormattedDate>
        <br />
        <FormattedDate value={date} month="numeric" day="numeric" />
      </StyledDateWrapper>
      <Button
        noShadow
        square
        onClick={props.onNextClick}
        appearance={ButtonAppearance.Navigation}
        data-cy="next-week"
      >
        <Icon size={IconSize.s20}>
          <ExternalIcon name="ChevronRight" />
        </Icon>
      </Button>
    </StyledDaySelector>
  );
}
