import {
  ATHLETE_SEARCH_PARAM,
  GROUP_SEARCH_PARAM,
  ROUTE_DATE_FORMAT,
  WEEK_SEARCH_PARAM,
} from '@yarmill/const';
import moment from 'moment';
import { useMemo } from 'react';
import { loadPersistedGroupId, loadPersistedSelectedAthlete } from '../utils';
import { useCurrentUserStore } from './use-current-user-store';
import { useDefaultGroup } from './use-default-group';
import { useGroupsStore } from './use-group-store';

export function useDefaultCommonSearchParams(
  customDefaultParams?: Record<string, string | undefined>
): string {
  const defaultGroup = useDefaultGroup();
  const currentUser = useCurrentUserStore();
  const groupsStore = useGroupsStore();
  const persistedAthlete = loadPersistedSelectedAthlete();
  const persistedGroup = loadPersistedGroupId(groupsStore, currentUser);

  return useMemo(() => {
    const params = new URLSearchParams();

    if (!params.has(GROUP_SEARCH_PARAM) && defaultGroup) {
      params.set(
        GROUP_SEARCH_PARAM,
        String(persistedGroup ? persistedGroup : defaultGroup)
      );
    }
    if (currentUser.isAthlete && !params.has(ATHLETE_SEARCH_PARAM)) {
      params.set(ATHLETE_SEARCH_PARAM, String(currentUser.id));
    }

    if (
      currentUser.isAthlete &&
      !params.has(ATHLETE_SEARCH_PARAM) &&
      persistedAthlete
    ) {
      params.set(ATHLETE_SEARCH_PARAM, String(persistedAthlete));
    }

    if (!params.has(WEEK_SEARCH_PARAM)) {
      params.set(WEEK_SEARCH_PARAM, moment().format(ROUTE_DATE_FORMAT));
    }

    Object.entries(customDefaultParams || {}).forEach(([param, value]) => {
      if (value && !params.has(param)) {
        params.set(param, value);
      }
    });

    return params.toString();
  }, [
    customDefaultParams,
    defaultGroup,
    currentUser,
    persistedAthlete,
    persistedGroup,
  ]);
}
