import { Icon, IconSize, styled } from '@yarmill/components';
import { Radio, Text } from '@yarmill/components-2';
import { Location as LocationIcon, NoLocation } from '@yarmill/icons-2';

const LocationLayout = styled.button<{ readonly isSelected?: boolean }>`
  display: grid;
  width: 100%;
  border: 0;
  cursor: pointer;
  grid-template-columns: ${({ theme }) => theme.size.x2} 1fr ${({ theme }) =>
    theme.size.x2};
  column-gap: ${({ theme }) => theme.size.x1};
  justify-content: start;
  align-items: center;
  padding: ${({ theme }) => theme.size.x1} ${({ theme }) => theme.size.x2};
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.color.blackHover : 'transparent'};
  color: ${({ theme }) => theme.color.white};
  text-align: left;

  :hover {
    background-color: ${({ theme }) => theme.color.blackHover};
  }
`;

const DescriptionWrapper = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
`;

interface LocationProps {
  readonly noLocation?: boolean;
  readonly title: string;
  readonly description?: string;
  readonly onClick: () => void;
  readonly isSelected?: boolean;
}
export function Location({
  noLocation,
  title,
  onClick,
  isSelected,
  description,
}: LocationProps): JSX.Element {
  return (
    <LocationLayout isSelected={isSelected} type="button" onClick={onClick}>
      <Icon size={IconSize.s16}>
        {noLocation ? <NoLocation /> : <LocationIcon />}
      </Icon>
      <Text appearance="label10strong" inheritColor>
        {title}
      </Text>
      <Radio isChecked={isSelected} hideInput />
      {description && (
        <DescriptionWrapper>
          <Text appearance="label10" inheritColor>
            {description}
          </Text>
        </DescriptionWrapper>
      )}
    </LocationLayout>
  );
}
