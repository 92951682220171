import { DATE_FORMAT } from '@yarmill/const';
import moment from 'moment';

export function getWeeksInMonth(
  month: string,
  format: string = DATE_FORMAT
): string[] {
  const monthStart = moment(month, format).startOf('month');
  const monthEnd = moment(month, format).endOf('month');

  const currentWeek = monthStart;
  const weeksInMonth = [];

  while (currentWeek <= monthEnd) {
    weeksInMonth.push(currentWeek.format(DATE_FORMAT));
    currentWeek.add(1, 'weeks');
  }

  return weeksInMonth;
}
