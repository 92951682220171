import { StackedBarChart } from '../types';
import { BarStack as VisxBarStack } from '@visx/shape';
import { useXYChartContext } from '../xy-chart-context';
import { BarsStacks } from './primitives/bars-stacks';
import { DataItem } from '../../reporting/types';

interface StackedBarsProps {
  readonly config: StackedBarChart;
  readonly data: DataItem[];
}

const noop = () => '';

export function StackedBars({ config, data }: StackedBarsProps) {
  const { xScale, yScale } = useXYChartContext();

  return (
    <VisxBarStack<DataItem, string>
      data={data}
      keys={config.keys}
      x={config.getXValue}
      xScale={xScale}
      yScale={yScale}
      color={noop}
      order="reverse"
      offset="diverging"
    >
      {stacks => <BarsStacks config={config} stacks={stacks} data={data} />}
    </VisxBarStack>
  );
}
