import { RootStore } from '@yarmill/types';
import { ComponentType } from 'react';
import { useRootStore } from '../hooks';

export interface WithRootStoreProps {
  rootStore: RootStore;
}

export const withRootStore = <P extends {}>(
  Component: ComponentType<P & WithRootStoreProps>
) =>
  function WithRootStore(props: P): JSX.Element {
    const rootStore = useRootStore();
    return <Component {...props} rootStore={rootStore} />;
  };
