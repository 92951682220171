import { Group } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export interface CreateGroupRequestParams {
  name: string;
  userIds: number[];
}

export function createGroup(
  params: CreateGroupRequestParams
): AxiosPromise<Group> {
  return axios.post('api/UserGroup', params);
}
