import {
  AthleteWithAvatar,
  AthleteWithAvatarWrapper,
  BreakpointWrapper,
  Breakpoints,
  ExternalIcon,
  FormattedHTMLMessage,
  Icon,
  StyledTd,
  StyledTh,
  StyledTr,
  Table,
  Text,
  TextSize,
  WhiteSpace,
  css,
  styled,
} from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
  AttendanceItemValue,
  ButtonsWrapper,
  Td,
} from './attendance-item-value';
import { useAttendanceStore } from './attendance-store-context';
import { EditButton } from './edit-button';
import { useAttendanceWeekViewColumns } from './hooks';
import { LoadingCell } from './loading-cell';
import { SelectAllButtons } from './select-all-buttons';
import { SumColumnItem } from './sum-column-item';
import { SumRowItem } from './sum-row-item';
import { ENABLE_TABLE_ANIMATION } from './utils';

import { AsyncStatus } from '@yarmill/types';
import { useCurrentUserStore } from '@yarmill/utils';
import { StyledAvatarWrapper } from './attendance';

const Th = styled(StyledTh)<{ isEdited?: boolean }>`
  ${
    ENABLE_TABLE_ANIMATION &&
    css<{ isEdited?: boolean }>`
    min-width: 70px;
    transition: all 250ms ease;
    transition-duration: 250ms;
    will-change: contents;
  `
  }

  ${({ isEdited }) =>
    isEdited &&
    css`
      min-width: 128px;
    `};
`;

const STICKY = { top: '0' };

export const WeekView = observer(function WeekView(): JSX.Element {
  const attendanceStore = useAttendanceStore();
  const columns = useAttendanceWeekViewColumns();
  const currentUser = useCurrentUserStore();

  const isNotAthlete = currentUser && !currentUser.isAthlete;

  return (
    <Table
      head={
        <>
          <Th sticky={STICKY} />
          {columns.map((column, index) => {
            const date = moment(column).toDate();

            return (
              <Th
                key={column}
                isEdited={attendanceStore.editedColumn === index}
                sticky={STICKY}
              >
                <BreakpointWrapper min={Breakpoints.tablet}>
                  <FormattedDate value={date} weekday="short">
                    {(msg: string) => (
                      <Text bold size={TextSize.s14}>
                        {msg}
                      </Text>
                    )}
                  </FormattedDate>
                  <br />
                  <FormattedDate value={date} month="numeric" day="numeric">
                    {(msg: string) => (
                      <Text bold size={TextSize.s14}>
                        {msg}
                      </Text>
                    )}
                  </FormattedDate>
                  <br />
                </BreakpointWrapper>
                {isNotAthlete && <EditButton dayIndex={index} />}
              </Th>
            );
          })}
          <Th sticky={STICKY}>
            <Text size={TextSize.s14}>
              <FormattedHTMLMessage id="attendance.header.present" />
            </Text>
          </Th>
          <Th sticky={STICKY}>
            <Text size={TextSize.s14}>
              <FormattedHTMLMessage id="attendance.header.missed" />
            </Text>
          </Th>
          <Th sticky={STICKY}>
            <Text size={TextSize.s14}>
              <FormattedHTMLMessage id="attendance.header.percentage" />
            </Text>
          </Th>
        </>
      }
    >
      {isNotAthlete && (
        <StyledTr>
          <StyledTd>
            <AthleteWithAvatarWrapper>
              <StyledAvatarWrapper>
                <Icon>
                  <ExternalIcon name="Layout2" />
                </Icon>
              </StyledAvatarWrapper>
              <Text size={TextSize.s14}>
                <FormattedMessage id="attendance.entireGroup" />
              </Text>
            </AthleteWithAvatarWrapper>
          </StyledTd>
          {columns.map((column, index) => (
            <Td key={column} whiteSpace={WhiteSpace.noWrap}>
              {isNotAthlete && (
                <ButtonsWrapper
                  isVisible={attendanceStore.editedColumn === index}
                >
                  <SelectAllButtons
                    day={column}
                    disabled={attendanceStore.editedColumn !== index}
                  />
                </ButtonsWrapper>
              )}
              <ButtonsWrapper
                isVisible={attendanceStore.editedColumn !== index}
              >
                <SumRowItem column={column} />
              </ButtonsWrapper>
            </Td>
          ))}
          <StyledTd whiteSpace={WhiteSpace.noWrap}>
            <SumRowItem column="present" />
          </StyledTd>
          <StyledTd whiteSpace={WhiteSpace.noWrap}>
            <SumRowItem column="missed" />
          </StyledTd>
          <StyledTd whiteSpace={WhiteSpace.noWrap}>
            <SumRowItem column="percentage" />
          </StyledTd>
        </StyledTr>
      )}
      {attendanceStore.athletes.map(user => (
        <StyledTr key={user.id}>
          <StyledTd whiteSpace={WhiteSpace.noWrap}>
            <Text size={TextSize.s14}>
              <AthleteWithAvatar name={user.displayName} avatar={user.avatar} />
            </Text>
          </StyledTd>
          {columns.map((column, index) =>
            attendanceStore.status === AsyncStatus.pending ? (
              <LoadingCell key={column} />
            ) : (
              <AttendanceItemValue
                dayIndex={index}
                key={column}
                user={user}
                date={column}
                readonly={!isNotAthlete}
              />
            )
          )}
          <SumColumnItem user={user} type="present" />
          <SumColumnItem user={user} type="missed" />
          <SumColumnItem user={user} type="percentage" />
        </StyledTr>
      ))}
    </Table>
  );
});
