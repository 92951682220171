import styled from 'styled-components';

export const TermsWrapper = styled.div`
  width: 100%;

  @media (min-width: 556px) {
    margin: auto;
    width: 80%;
  }

  @media (min-width: 767px) {
    margin: 50px auto;
    width: 50%;
  }
`;
