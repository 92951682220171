import {
  AnalyticsReportTitleWrapper,
  ContentBox,
  ExternalIcon,
  Icon,
  IconSize,
  ReportContainer,
  Text,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { DATE_FORMAT } from '@yarmill/const';
import { getWeeksInMonth, useConfig } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';
import { ChartHeader } from './chart-header';
import { ReBarChart } from './rebarchart';
import { CycleTrend as CycleTrendType, KPI } from './types';
import { getKPIValue, prepareChartData } from './utils';

export interface CycleTrendProps extends WrappedComponentProps {
  data: CycleTrendType[];
  kpi: KPI[];
  useMonths: boolean;
}

const monthCycles = ['1', '2', '3', '4'];

export const MonthTrend = observer(function MonthTrend(
  props: CycleTrendProps
): JSX.Element {
  const { data, intl, kpi, useMonths } = props;
  const labels = useMonths
    ? getWeeksInMonth(moment().format(DATE_FORMAT)).map((week, idx) =>
        String(idx + 1)
      )
    : monthCycles;
  const chartData = prepareChartData(labels, data, labels);
  const xAxisLabel = intl.formatMessage({
    id: 'analytics.cycleTrend.xLabel',
  });
  const seasonView = useConfig('seasonView');

  return (
    <ReportContainer sectionArea="month">
      <AnalyticsReportTitleWrapper>
        <Text size={TextSize.s16} tag={TextTag.div} medium>
          <FormattedMessage
            id={
              seasonView === 'cycle'
                ? 'analytics.charts.thisCycle'
                : 'analytics.charts.thisMonth'
            }
          />
        </Text>
        <Icon size={IconSize.s16}>
          <ExternalIcon name="ChartBar" />
        </Icon>
      </AnalyticsReportTitleWrapper>
      <ContentBox>
        <ChartHeader
          plan={getKPIValue(kpi, 'HZ Cycle Plan')}
          reality={getKPIValue(kpi, 'HZ Cycle Reality')}
          absDiff={getKPIValue(kpi, 'HZ Cycle AbsDiff')}
          relDiff={getKPIValue(kpi, 'HZ Cycle RelDiff')}
          intl={intl}
        />
        <ReBarChart
          data={chartData}
          xAxisLabel={xAxisLabel}
          tooltipTitlePrefix={xAxisLabel}
          stepSize={60}
        />
      </ContentBox>
    </ReportContainer>
  );
});
