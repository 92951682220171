import { Group } from '@yarmill/types';
import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export function getGroups(): AxiosPromise<Group[]> {
  return axios.get(`${coreApiTarget}/api/user-groups`, {
    params: {
      instanceCode: devMode ? instance : undefined,
    },
  });
}
