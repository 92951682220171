import { action, makeObservable, observable } from 'mobx';

export class NavigationStore {
  @observable
  private isNavigationOpened = false;
  constructor() {
    makeObservable(this);
  }

  @action
  public readonly toggleNavigation = (): void => {
    this.isNavigationOpened = !this.isNavigationOpened;
  };

  @action
  public closeNavigation(): void {
    this.isNavigationOpened = false;
  }

  public get isOpened(): boolean {
    return this.isNavigationOpened;
  }
}
