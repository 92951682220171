import { useMemo } from 'react';
import { useLocation as useRouterLocation } from 'react-router-dom';

export interface Location<S = unknown> {
  pathname: string;
  search: string;
  state?: S;
}

export function useLocation<T = unknown>(): Location<T> {
  const { search, pathname, state } = useRouterLocation<T>();

  return useMemo(
    () => ({
      search,
      pathname,
      state,
    }),
    [search, pathname, state]
  );
}
