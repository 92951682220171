import { Text, TextSize } from '@yarmill/components';
import { useConfig } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Fragment, useCallback } from 'react';
import { FormattedDate } from 'react-intl';
import { useAttendanceSeasonViewColumns } from './hooks';
import { SummaryView } from './summary-view';

export const SeasonView = observer(function SeasonView(): JSX.Element {
  const columns = useAttendanceSeasonViewColumns();
  const seasonView = useConfig('seasonView');

  const formatHeader = useCallback(
    (column: string, columnIndex: number) => {
      const date = moment(column).toDate();

      return seasonView === 'month' ? (
        <FormattedDate value={date} month="short">
          {(msg: string) => (
            <Text bold size={TextSize.s12}>
              {msg}
            </Text>
          )}
        </FormattedDate>
      ) : (
        <Fragment>
          <Text bold size={TextSize.s12}>
            {columnIndex + 1}
          </Text>
          <br />
          <Text size={TextSize.s10}>
            <FormattedDate value={date} day="numeric" month="numeric" />
            &rarr;
          </Text>
        </Fragment>
      );
    },
    [seasonView]
  );

  return <SummaryView columns={columns} formatHeader={formatHeader} />;
});
