import { css, styled } from '../theme-provider';

export const FilesLayout = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr auto;
  row-gap: 8px;
  column-gap: 8px;
  margin: 12px 0;

  @media (min-width: 768px) {
    margin: 0;
  }
`;

export const FileAttributeLayout = styled.div<{ readonly hideAdd?: boolean }>`
  position: relative;
  display: grid;
  align-items: start;
  padding-right: 4px;
  column-gap: 40px;
  grid-template-columns: 100%;
  margin: -8px 0 -7px;

  @media (min-width: 768px) {
    grid-template-columns: ${({ hideAdd }) => (hideAdd ? '100%' : '1fr auto')};
  }
`;

export const NoValueWrapper = styled.div`
  padding: 7px 0;
`;

export const AddFileButton = styled.label<{ readonly disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f61c8e;
  color: #ffffff;
  border-color: #f61c8e;
  padding: 8px;
  border-radius: 8px;
  transition:
    background-color,
    color 250ms ease;
  position: absolute;
  right: 6px;
  top: 0;
  transform: translateY(-100%);
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  :disabled {
    cursor: not-allowed;
  }

  @media (min-width: 768px) {
    position: absolute;
    right: 0;
    top: 0;
    transform: unset;
  }

  :hover,
  :focus {
    color: #fff;
    background: #e3097b;
    border-color: #d60974;
    outline: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: #9a9a9a;
      border-color: #9a9a9a;
      background: transparent;
    `}
`;

export const StyledDownloadLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 36px;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;

  tr:hover & {
    background-color: #f7f9fb;
  }
`;

export const FileLayout = styled.div<{
  readonly variant: 'table-form' | 'default';
}>`
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
  grid-template-rows: auto;

  ${({ variant }) =>
    variant === 'table-form' &&
    css`
      padding: 8px;
      border-radius: 8px;
      background-color: #ffffff;

      & > :nth-child(2) {
        padding-left: 8px;
      }
    `}
`;
