import { AthletesSidebar, PageMainContent } from '@yarmill/components';
import { useCurrentUserStore, useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { memo } from 'react';
import { Redirect } from 'react-router-dom';
import { PageContent } from './page-content';
import {
  SeasonEvaluationStoreContextProvider,
  useSeasonEvaluationStore,
} from './season-evaluation-store-context';

function InternalSeasonEvaluationRouteRoot(): JSX.Element {
  const rootStore = useRootStore();
  const currentUser = useCurrentUserStore();
  const seasonEvaluationStore = useSeasonEvaluationStore();

  if (
    rootStore.isReady &&
    !currentUser.isAthlete &&
    !seasonEvaluationStore.groupId &&
    !seasonEvaluationStore.athleteId
  ) {
    return <Redirect to="/noGroup" />;
  }

  return (
    <>
      {currentUser.role !== 'athlete' ? (
        <AthletesSidebar
          disableWholeGroup={
            seasonEvaluationStore.viewType === 'season-over-season'
          }
          disableWholeGroupTooltip="sidebar.seasonEvaluation.disableWholeGroupForSos"
        />
      ) : undefined}
      <PageMainContent>
        <PageContent />
      </PageMainContent>
    </>
  );
}

const ObserverSeasonEvaluationRouteRoot = observer(
  InternalSeasonEvaluationRouteRoot
);

export const SeasonEvaluationModule = memo(() => (
  <SeasonEvaluationStoreContextProvider>
    <ObserverSeasonEvaluationRouteRoot />
  </SeasonEvaluationStoreContextProvider>
));
