import { FactType, IndicatorType } from './types';

export const formatNumber = (value: number): string =>
  value.toLocaleString('cs', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  });

const ANNUAL_PLAN_INDICATORS: IndicatorType[] = [
  'Dif. plán/RP',
  'Průměr kategorie (RP)',
  'Dif. RP/kateg.',
];

const PLAN_INDICATORS: IndicatorType[] = [
  'Dif. plán/RP',
  'Průměr kategorie (plán)',
  'Dif. plán/kateg.',
];

export const getIndicatorTypesByFactType = (
  factType?: FactType
): IndicatorType[] => {
  switch (factType) {
    case 'C':
      return ANNUAL_PLAN_INDICATORS;
    default:
      return PLAN_INDICATORS;
  }
};
