import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { EvidenceAttributeStore } from '../mobx/evidence-attribute-store';
import { getErrorMessage } from '../validation';

export function useErrorMessage(
  attributeStore: EvidenceAttributeStore
): string | undefined {
  const intl = useIntl();
  const submitClicked = attributeStore.objectDataStore.submitButtonClicked;
  const type = attributeStore.attributeType;
  const hasValue = attributeStore.hasValue;
  const isRequired = attributeStore.isRequired;
  const isValid = attributeStore.isValid;

  return useMemo(
    () =>
      submitClicked
        ? getErrorMessage(
            type,
            hasValue,
            isRequired,
            isValid,
            intl,
            attributeStore.definition.Format
          )
        : undefined,
    [
      intl,
      type,
      hasValue,
      isRequired,
      isValid,
      submitClicked,
      attributeStore.definition.Format,
    ]
  );
}
