import {
  AthleteWithAvatar,
  AthleteWithAvatarWrapper,
  ExternalIcon,
  FormattedHTMLMessage,
  Icon,
  StyledTd,
  StyledTh,
  StyledTr,
  Table,
  Text,
  TextSize,
  Tippy,
  WhiteSpace,
} from '@yarmill/components';
import { AsyncStatus } from '@yarmill/types';
import { useCurrentUserStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { StyledAvatarWrapper } from './attendance';
import { useAttendanceStore } from './attendance-store-context';
import { LoadingCell } from './loading-cell';
import { Popover } from './popover';
import { SummaryAttendanceItem } from './types';
import { formatNumberValue } from './utils';

function getUserAttendanceMapByDate(
  attendances: SummaryAttendanceItem[]
): Record<string, SummaryAttendanceItem> {
  return attendances.reduce(
    (
      map: Record<string, SummaryAttendanceItem>,
      item: SummaryAttendanceItem
    ) => {
      map[item.Date] = item;

      return map;
    },
    {}
  );
}

const userNameTdMinWidth = '100px';
const attendanceTdMinWidth = '50px';

export interface SummaryViewProps {
  columns: string[];
  formatHeader(column: string, columnIndex: number): JSX.Element;
}
const STICKY = { top: '0' };

export const SummaryView = observer(function SummaryView(
  props: SummaryViewProps
): JSX.Element {
  const { columns, formatHeader } = props;
  const attendanceStore = useAttendanceStore();
  const group = attendanceStore.group;
  const currentUser = useCurrentUserStore();
  const athletes = group?.athletes || [];
  const status = attendanceStore.status;
  const data = attendanceStore.summaryData;

  const sumRowData = data.get(null);
  const sumAttendancesByDate = sumRowData
    ? getUserAttendanceMapByDate(sumRowData.Attendances || [])
    : {};

  return (
    <Table
      head={
        <>
          <StyledTh sticky={STICKY} />
          {columns.map((column, columnIndex) => {
            return (
              <StyledTh key={column} sticky={STICKY}>
                {formatHeader(column, columnIndex)}
              </StyledTh>
            );
          })}
          <StyledTh sticky={STICKY}>
            <Text size={TextSize.s12}>
              <FormattedHTMLMessage id="attendance.header.present" />
            </Text>
          </StyledTh>
          <StyledTh sticky={STICKY}>
            <Text size={TextSize.s12}>
              <FormattedHTMLMessage id="attendance.header.missed" />
            </Text>
          </StyledTh>
          <StyledTh sticky={STICKY}>
            <Text size={TextSize.s12}>
              <FormattedHTMLMessage id="attendance.header.percentage" />
            </Text>
          </StyledTh>
        </>
      }
    >
      {currentUser && !currentUser.isAthlete && (
        <StyledTr>
          <StyledTd minWidth={userNameTdMinWidth}>
            <AthleteWithAvatarWrapper>
              <StyledAvatarWrapper>
                <Icon>
                  <ExternalIcon name="Layout2" />
                </Icon>
              </StyledAvatarWrapper>
              <Text size={TextSize.s14}>
                <FormattedMessage id="attendance.entireGroup" />
              </Text>
            </AthleteWithAvatarWrapper>
          </StyledTd>
          {columns.map(column => (
            <StyledTd key={column} minWidth={attendanceTdMinWidth}>
              <Tippy
                isEnabled={
                  sumAttendancesByDate[column] &&
                  sumAttendancesByDate[column].Percentage !== null
                }
                tooltipContent={
                  <Popover attendanceItem={sumAttendancesByDate[column]} />
                }
              >
                <Text size={TextSize.s14} whiteSpace={WhiteSpace.noWrap}>
                  {!sumAttendancesByDate[column] ||
                  sumAttendancesByDate[column].Percentage === null
                    ? '-'
                    : `${formatNumberValue(
                        sumAttendancesByDate[column].Percentage * 100
                      )}
                    %`}
                </Text>
              </Tippy>
            </StyledTd>
          ))}
          <StyledTd minWidth={attendanceTdMinWidth}>
            <Text size={TextSize.s14} whiteSpace={WhiteSpace.noWrap}>
              {!sumRowData || sumRowData.TotalPresent === null
                ? '-'
                : formatNumberValue(sumRowData.TotalPresent)}
            </Text>
          </StyledTd>
          <StyledTd minWidth={attendanceTdMinWidth}>
            <Text size={TextSize.s14} whiteSpace={WhiteSpace.noWrap}>
              {!sumRowData || sumRowData.TotalMissed === null
                ? '-'
                : formatNumberValue(sumRowData.TotalMissed)}
            </Text>
          </StyledTd>
          <StyledTd minWidth={attendanceTdMinWidth}>
            <Text size={TextSize.s14} whiteSpace={WhiteSpace.noWrap}>
              {!sumRowData || sumRowData.TotalPercentage === null
                ? '-'
                : `${formatNumberValue(sumRowData.TotalPercentage * 100)}
                %`}
            </Text>
          </StyledTd>
        </StyledTr>
      )}
      {athletes.map(user => {
        const userData = data.get(user.id);
        const userAttendancesByDate = userData
          ? getUserAttendanceMapByDate(userData.Attendances || [])
          : {};

        return (
          <StyledTr key={user.id}>
            <StyledTd minWidth={attendanceTdMinWidth}>
              <Text size={TextSize.s14} whiteSpace={WhiteSpace.noWrap}>
                <AthleteWithAvatar
                  name={user.displayName}
                  avatar={user.avatar}
                />
              </Text>
            </StyledTd>
            {columns.map(column =>
              status === AsyncStatus.pending ? (
                <LoadingCell key={column} />
              ) : (
                <StyledTd key={column} minWidth={attendanceTdMinWidth}>
                  <Tippy
                    isEnabled={
                      userAttendancesByDate[column] &&
                      userAttendancesByDate[column].Percentage !== null
                    }
                    tooltipContent={
                      <Popover attendanceItem={userAttendancesByDate[column]} />
                    }
                  >
                    <Text size={TextSize.s14} whiteSpace={WhiteSpace.noWrap}>
                      {!userAttendancesByDate[column] ||
                      userAttendancesByDate[column].Percentage === null
                        ? '-'
                        : `${formatNumberValue(
                            userAttendancesByDate[column].Percentage * 100
                          )}
                        %`}
                    </Text>
                  </Tippy>
                </StyledTd>
              )
            )}
            <StyledTd minWidth={attendanceTdMinWidth}>
              <Text size={TextSize.s14} whiteSpace={WhiteSpace.noWrap}>
                {!userData || userData.TotalPresent === null
                  ? '-'
                  : formatNumberValue(userData.TotalPresent)}
              </Text>
            </StyledTd>
            <StyledTd minWidth={attendanceTdMinWidth}>
              <Text size={TextSize.s14} whiteSpace={WhiteSpace.noWrap}>
                {!userData || userData.TotalMissed === null
                  ? '-'
                  : formatNumberValue(userData.TotalMissed)}
              </Text>
            </StyledTd>
            <StyledTd minWidth={attendanceTdMinWidth}>
              <Text size={TextSize.s14} whiteSpace={WhiteSpace.noWrap}>
                {!userData || userData.TotalPercentage === null
                  ? '-'
                  : `${formatNumberValue(userData.TotalPercentage * 100)}
                        %`}
              </Text>
            </StyledTd>
          </StyledTr>
        );
      })}
    </Table>
  );
});
