import { ConflictResolution, RootStore, UserGroupId } from '@yarmill/types';
import {
  CopyConflictErrorResponse,
  ServerErrorResponse,
  getWeekEndString,
  getWeekStartString,
} from '@yarmill/utils';
import {
  CopyAttendanceRequestParams,
  copyAttendance as copyAttendanceRequest,
} from '../api/copy-attendance';

export async function copyAttendance(
  rootStore: RootStore,
  groupId: UserGroupId,
  week: string,
  resolution: ConflictResolution = 'FailOnConflict'
): Promise<boolean | CopyConflictErrorResponse | ServerErrorResponse> {
  const params: CopyAttendanceRequestParams = {
    UserGroupId: groupId,
    StartDate: getWeekStartString(week),
    EndDate: getWeekEndString(week),
    ConflictResolution: resolution,
  };

  const transaction = rootStore.navbarStore.createTransaction(
    'loading',
    'diary'
  );
  try {
    await copyAttendanceRequest(params);
    transaction.finished();
    return true;
  } catch (e: any) {
    transaction.finished();
    if (e?.response?.status === 406) {
      return new CopyConflictErrorResponse(
        'Copy Conflict Error',
        e.response.data
      );
    } else if (e?.response?.status === 400 && e.response.data?.Id) {
      return new ServerErrorResponse('copy server error', e.response.data);
    }
    return false;
  }
}
