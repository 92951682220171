import { Icon, IconSize, styled } from '@yarmill/components';
import { Folder } from '@yarmill/icons-2';
import { Text } from './text';
import { getAppearanceColors, TextInputProps } from './text-input';

interface CategoryProps {
  readonly category: string | string[];
  readonly appearance: TextInputProps['appearance'];
  readonly limitWidth?: boolean;
}

const CategoryLayout = styled.div<{
  readonly $appearance: TextInputProps['appearance'];
}>`
  display: flex;
  column-gap: ${({ theme }) => theme.size.x05};
  align-items: center;
  color: ${({ theme, $appearance }) =>
    getAppearanceColors($appearance, theme).text};
  max-width: 100%;
  overflow: hidden;
`;

function limitCategoryWidth(category: string): string {
  if (category.length <= 16) {
    return category;
  }
  return `${category.substring(0, 15)}...`;
}
export function Category({
  category,
  appearance,
  limitWidth,
}: CategoryProps): JSX.Element {
  return (
    <CategoryLayout $appearance={appearance}>
      <Icon size={IconSize.s16}>
        <Folder />
      </Icon>
      <Text
        inheritColor
        ellipsis
        upperCase
        appearance="button10"
        whiteSpace="noWrap"
      >
        {Array.isArray(category)
          ? category
              .map(c => (limitWidth ? limitCategoryWidth(c) : c))
              .join(' / ')
          : category}
      </Text>
    </CategoryLayout>
  );
}
