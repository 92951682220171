import { observer } from 'mobx-react-lite';
import {
  ErrorBoundary,
  ReportContainer,
  Text,
  TextSize,
  TextTag,
} from '@yarmill/components';
import {
  CategoricalChartReportData,
  CategoricalChartReportStore,
  ContinuousChartReportData,
  EvidenceReportData,
  HamburgerReportData,
  KpiReportData,
  OverviewTableReportData,
  ReportData,
  ReportItemStore,
  RichtextReportData,
  TableReportData,
  VideoReportData,
} from './types';
import { CategoricalChartReport } from './categorical-chart-report';
import { TableReport } from './table-report';
import { OverviewTableReport } from './overview-table-report';
import { KpiReport } from './kpi-report';
import { ContinuousChartReport } from './continuous-chart-report';
import { TableChartStore } from './mobx/table-chart-store';
import { OverviewTableStore } from './mobx/overview-table-store';
import { KpiStore } from './mobx/kpi-store';
import { ContinuousChartStore } from './mobx/continuous-chart-store';
import { VideoReport } from './video-report';
import { RichtextReport } from './richtext-report';
import { RichtextReportStore } from './mobx/richtext-report-store';
import { HamburgerReport } from './hamburger-report';
import { HamburgerReportStore } from './mobx/hamburger-report-store';
import { ReportTitle } from './report-title';
import { useShowReportTitle } from './hooks/use-show-report-title';
import { EvidenceReport } from './evidence-report';
import { EvidenceReportStore } from './mobx/evidence-report-store';
import { ReportContentBox } from './report-content-box';
import { FormattedMessage } from 'react-intl';
import { ErrorInfo, useMemo } from 'react';
import { ReportContext } from './context/report-context';

export interface ReportProps {
  readonly report: ReportItemStore;
  readonly sectionArea?: string;
  readonly sectionRatio?: number;
  readonly useSubgrid?: boolean;
  readonly data?: ReportData;
  readonly noReportWrapper?: boolean;
}

function renderReport(
  report: ReportItemStore,
  sectionRatio?: number,
  data?: ReportData,
  noReportWrapper?: boolean
): JSX.Element {
  switch (report.kind) {
    case 'Table':
      return (
        <TableReport
          report={report as TableChartStore}
          data={data as TableReportData | undefined}
        />
      );
    case 'OverviewTable':
      return (
        <OverviewTableReport
          report={report as OverviewTableStore}
          sectionRatio={sectionRatio}
          data={data as OverviewTableReportData}
        />
      );
    case 'Kpi':
      return (
        <KpiReport
          report={report as KpiStore}
          data={data as KpiReportData}
          noReportWrapper={noReportWrapper}
        />
      );
    case 'HorizontalClusteredColumnChart':
    case 'HorizontalStackedColumnChart':
      return (
        <CategoricalChartReport
          report={report as CategoricalChartReportStore}
          sectionRatio={sectionRatio}
          data={data as CategoricalChartReportData}
          noReportWrapper={noReportWrapper}
          isHorizontal
        />
      );
    case 'ContinuousChart':
    case 'TimeChart':
      return (
        <ContinuousChartReport
          report={report as ContinuousChartStore}
          sectionRatio={sectionRatio}
          data={data as ContinuousChartReportData}
          noReportWrapper={noReportWrapper}
        />
      );
    case 'Video':
      return (
        <VideoReport
          report={report}
          data={data as VideoReportData}
          noReportWrapper={noReportWrapper}
        />
      );
    case 'Richtext':
      return (
        <RichtextReport
          report={report as RichtextReportStore}
          data={data as RichtextReportData}
        />
      );
    case 'Hamburger':
      return (
        <HamburgerReport
          report={report as HamburgerReportStore}
          data={data as HamburgerReportData}
          noReportWrapper={noReportWrapper}
        />
      );
    case 'Evidence':
      return (
        <EvidenceReport
          report={report as EvidenceReportStore}
          data={data as EvidenceReportData}
        />
      );
    default:
      return (
        <CategoricalChartReport
          report={report as CategoricalChartReportStore}
          sectionRatio={sectionRatio}
          data={data as CategoricalChartReportData}
          noReportWrapper={noReportWrapper}
        />
      );
  }
}

function onError(error: Error, info: ErrorInfo) {
  console.error(error, info);
}

export const Report = observer(function Report(
  props: ReportProps
): JSX.Element {
  const {
    report,
    sectionArea,
    sectionRatio,
    useSubgrid,
    data,
    noReportWrapper,
  } = props;
  const showTitle = useShowReportTitle(report);

  const reportContextValue = useMemo(() => ({ sectionRatio }), [sectionRatio]);

  if (noReportWrapper) {
    return renderReport(report, sectionRatio, data, noReportWrapper);
  }

  return (
    <ErrorBoundary
      onCatch={onError}
      fallback={
        <ReportContentBox>
          <Text tag={TextTag.div} size={TextSize.s14}>
            <FormattedMessage id="reporting.genericReportError" />
          </Text>
        </ReportContentBox>
      }
    >
      <ReportContext.Provider value={reportContextValue}>
        <ReportContainer
          sectionArea={sectionArea}
          hasTwoRows={showTitle && report.titlePosition === 'outside'}
          sectionVerticalAlignment={report.sectionVerticalAlignment}
          useSubgrid={useSubgrid}
          data-cy={`report-${report.code}`}
        >
          {!showTitle && useSubgrid && <div />}
          {report.titlePosition === 'outside' && showTitle && (
            <ReportTitle report={report} />
          )}
          {renderReport(report, sectionRatio, data)}
        </ReportContainer>
      </ReportContext.Provider>
    </ErrorBoundary>
  );
});
