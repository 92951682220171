import { Header } from '../header/header';
import { PageLayout } from '@yarmill/components';
import { PropsWithChildren } from 'react';

export function Page(
  props: PropsWithChildren<{ readonly doubleSidebar?: boolean }>
): JSX.Element {
  const { children, doubleSidebar } = props;

  return (
    <>
      <Header />
      <PageLayout doubleSidebar={doubleSidebar}>{children}</PageLayout>
    </>
  );
}
