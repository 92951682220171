import {
  AsyncStatus,
  AttachmentData,
  AttachmentId,
  AttachmentListStore,
  AttachmentValue,
  FileUploadStore,
  RootStore,
  TrainingDayAttribute,
  TrainingDayData,
  UserGroupId,
  UserId,
} from '@yarmill/types';
import { AttachmentStore, waitFor } from '@yarmill/utils';
import { action, computed, makeObservable, observable, override } from 'mobx';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { DiaryDataUniqueId } from '../../diary/types';
import { TrainingDayStore } from '../../training-day/mobx/training-day-store';
import { assignAttachment } from '../api/assign-attachment';
import { AbstractTrainingDayAttributeStore } from './abstract-training-day-attribute-store';

export class AttachmentAttributeStore
  extends AbstractTrainingDayAttributeStore
  implements AttachmentListStore
{
  protected userValue: AttachmentValue[] | null = null;

  @observable
  private _attachments: Map<AttachmentId, AttachmentStore> = new Map();
  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    trainingDayStore: TrainingDayStore,
    attribute: TrainingDayAttribute,
    dataId: DiaryDataUniqueId
  ) {
    super(rootStore, diaryStore, trainingDayStore, attribute, dataId);
    makeObservable(this);
  }

  @override
  setApiValue(value: TrainingDayData) {
    super.setApiValue(value);
    const attachments = (value as AttachmentData).Value;

    attachments.forEach(attachment => {
      const store = new AttachmentStore(this.rootStore, this, attachment);
      this._attachments.set(store.id, store);
    });
  }

  @override
  clear() {
    super.clear();
    this._attachments.clear();
  }

  @computed
  public get attachments(): AttachmentStore[] {
    return this.diaryStore.status !== AsyncStatus.resolved
      ? []
      : Array.from(this._attachments.values());
  }

  @action
  public removeAttachment(attachment: AttachmentStore): void {
    this._attachments.delete(attachment.id);
  }

  @override
  get isHidden(): boolean {
    return (
      (this.attribute.IsExtra &&
        !this.trainingDayStore.visibleExtraAttributes.has(
          this.attribute.AttributeItemId
        ) &&
        this._attachments.size === 0) ||
      this.diaryStore.status !== AsyncStatus.resolved
    );
  }

  public async assignAttachment(
    fileUpload: FileUploadStore,
    userGroupId: UserGroupId | null,
    userId: UserId | null,
    uploadDate: string
  ): Promise<boolean> {
    const uploadedFile = fileUpload.uploadedFile;
    if (!uploadedFile) {
      return false;
    }

    const AttributeId = this.id;
    const FileId = uploadedFile.FileId;
    const State = this.getState();
    const UserGroupId = userId ? undefined : userGroupId!;
    const UserId = userId || undefined;

    const createAssignRequest = () =>
      assignAttachment({
        AttributeId,
        Date: uploadDate,
        FileId,
        State,
        UserGroupId,
        UserId,
      });

    let numberOfAttempts = 0;
    let success = false;
    while (numberOfAttempts <= 3) {
      const request =
        this.rootStore.requestsStore.createRequest(createAssignRequest);

      const response = await request.getResponse();

      if (response) {
        if (
          (userGroupId ? this.dataId.groupId === userGroupId : true) &&
          (userId ? this.dataId.athleteId === userId : true) &&
          this.trainingDayStore.currentDate === uploadDate
        ) {
          const store = new AttachmentStore(this.rootStore, this, response);
          this._attachments.set(store.id, store);
          success = true;
          break;
        }
      } else {
        numberOfAttempts++;
        await waitFor(numberOfAttempts * 1000 * 3);
      }
    }

    return success;
  }

  public isAllowedToDeleteFile(): boolean {
    const role = this.rootStore.currentUserStore.role;
    return (
      this.rootStore.currentUserStore.hasWritePermission(
        this.dataId.groupId,
        this.dataId.athleteId
      ) &&
      ((role === 'athlete' && this.dataId.diaryType === 'reality') ||
        role === 'admin' ||
        role === 'coach')
    );
  }

  protected get currentValue(): string | string[] {
    return '';
  }
}
