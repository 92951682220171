import styled from 'styled-components';
import { Dashboard } from './dashboard';

const StyledDashboardSelector = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  row-gap: 16px;
  list-style: none;
  padding: 0;
  margin: 0;

  margin-bottom: 20px;
`;

export interface DashboardSelectorProps {
  activeDashboard: string | number | null;
  dashboards: { id: string | number; label: string; description: string }[];
  onDashboardClick(dashboardId: string | number): void;
}

export function DashboardSelector(props: DashboardSelectorProps): JSX.Element {
  const { dashboards, onDashboardClick, activeDashboard } = props;

  return (
    <StyledDashboardSelector>
      {dashboards.map(dashboard => (
        <Dashboard
          key={dashboard.id}
          id={dashboard.id}
          isActive={activeDashboard === dashboard.id}
          label={dashboard.label}
          description={dashboard.description}
          onClick={onDashboardClick}
        />
      ))}
    </StyledDashboardSelector>
  );
}
