import { Attachment } from './extra-attribute-types/attachment';
import { Basic } from './extra-attribute-types/basic';
import { AbstractTrainingDayAttributeStore } from '../attributes/mobx/abstract-training-day-attribute-store';
import { AttachmentAttributeStore } from '../attributes/mobx/attachment-attribute-store';

export interface TrainingDayExtraAttributeProps {
  attribute: AbstractTrainingDayAttributeStore;
  hideTooltip(): void;
}

export function TrainingDayExtraAttribute(
  props: TrainingDayExtraAttributeProps
): JSX.Element | null {
  const { attribute, hideTooltip } = props;

  if (attribute instanceof AttachmentAttributeStore) {
    return <Attachment attribute={attribute} hideTooltip={hideTooltip} />;
  }

  return <Basic attribute={attribute} hideTooltip={hideTooltip} />;
}
