import { SeasonEvaluationModule } from '@yarmill/season-evaluation';
import { Page } from '../page/page';

export function SeasonEvaluation() {
  return (
    <Page>
      <SeasonEvaluationModule />
    </Page>
  );
}
