import {
  AsyncButton,
  Avatar,
  Button,
  ButtonAppearance,
  FieldSet,
  FormControlVariant,
  PasswordRequirementsList,
  PatternInputDate,
  SelectBox,
  SelectValueProps,
  Text,
  TextInput,
  TextSize,
  toast,
} from '@yarmill/components';
import { DATE_FORMAT } from '@yarmill/const';
import { CurrentUserStore, Layer, ServerError, User } from '@yarmill/types';
import {
  DEFAULT_AVATAR,
  createDateFormatValidator,
  extractDateFormat,
  extractDelimiters,
} from '@yarmill/utils';
import moment from 'moment';
import * as React from 'react';
import { useEffect } from 'react';
import {
  FormattedMessage,
  IntlShape,
  WrappedComponentProps,
  injectIntl,
} from 'react-intl';
import styled from 'styled-components';
import {
  trackProfileBackButtonClick,
  trackProfileFormFieldBlur,
  trackProfileFormFieldFocus,
  trackProfileNextButtonClick,
} from '../google-analytics/utils';

export interface UpdateProfileFormProps {
  isRegistration?: boolean;
  commercialConsent?: boolean;
  user: CurrentUserStore;
  layer?: Layer;
  showTerms?: React.MouseEventHandler;
  avatars: Map<string, string>;
  backToTermsState?(e: React.MouseEvent<Element, MouseEvent>): void;
}
interface AsyncState {
  loading?: boolean;
  loaded?: boolean;
  error?: boolean;
  errorMessages?: ServerError[];
}
export interface UpdateProfileFormState extends AsyncState {
  fields: { [key: string]: string | undefined };
  avatar: string | null;
  showAvatarSelector: boolean;
  isBirthdayValid: boolean;
  acceptedConditions: boolean;
  showBirthdayError: boolean;
}

const StyledForm = styled.form`
  width: 100%;

  @media (min-width: 556px) {
    margin: 0 auto;
    width: 80%;
  }

  @media (min-width: 767px) {
    width: 50%;
  }
`;

// this component used inside the form which has affect for all child inputs elements
// .big-form
const SelectBoxWrapper = styled.div`
  .react_select__single-value {
    color: #495057;
    font-weight: 400;
  }

  .react_select__menu {
    color: #495057;
    font-weight: 400;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button:first-child {
    margin-right: 10px;
  }
`;

const StyledInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 556px) {
    flex-direction: row;
  }
`;

const StyledFirstNameWrapper = styled.div`
  width: 100%;

  @media (min-width: 556px) {
    margin-right: 15px;
  }
`;

const StyledSecondNameWrapper = styled.div`
  width: 100%;
`;

const StyledBirthWrapper = styled.div`
  width: 100%;

  @media (min-width: 556px) {
    margin-right: 15px;
    width: 40%;
  }
`;
const StyledGenderWrapper = styled.div`
  width: 100%;

  @media (min-width: 556px) {
    width: 30%;
  }
`;

const StyledPasswordWrapper = styled.div`
  width: 100%;
`;

const StyledAvatarsContainer = styled.div`
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > * {
    width: 80px;
    height: 80px;
    padding: 15px;
    border: 1px solid transparent;
    cursor: pointer;
    img {
      width: 100%;
      height: auto;
    }

    &.active {
      background: #ffffff;
      border: 1px solid #4a90e2;
      box-shadow: 0 2px 18px 6px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      position: relative;

      &:after {
        font-family: "TrainingDiary" !important;
        position: absolute;
        font-size: 22px;
        color: #4a90e2;
        display: block;
        content: "\\e91b";
        bottom: -10px;
        right: -5px;
        text-align: center;
      }
    }
  }
`;

const StyledAsyncButtonWrapper = styled.div`
  button {
    padding: 20px 50px;
    height: 100%;
    font-size: 18px;
  }
`;

function DateFormatUpdater(props: {
  intl: IntlShape;
  setDateFormat: (format: string, delimiters: string[]) => void;
}): null {
  const { intl, setDateFormat } = props;
  const locale = intl.locale;
  useEffect(() => {
    if (locale) {
      const format = extractDateFormat(intl);
      const delimiters = extractDelimiters(format);
      setDateFormat(format, delimiters);
    }
  }, [intl, locale, setDateFormat]);

  return null;
}

class UpdateProfileForm extends React.PureComponent<
  UpdateProfileFormProps & WrappedComponentProps,
  UpdateProfileFormState
> {
  private readonly submitRef = React.createRef<HTMLButtonElement>();
  private birthdayFormat: string;
  private dateFormatDelimiter: string[];
  private birthdayValidator: (val: string) => boolean;

  public constructor(props: UpdateProfileFormProps & WrappedComponentProps) {
    super(props);
    this.birthdayFormat = extractDateFormat(props.intl);
    this.dateFormatDelimiter = extractDelimiters(this.birthdayFormat);
    this.birthdayValidator = createDateFormatValidator(this.birthdayFormat);

    this.state = {
      fields: {
        Gender:
          props.user && props.user.data?.Gender
            ? props.user.data?.Gender
            : 'male',
      },
      avatar: null,
      showAvatarSelector: false,
      isBirthdayValid: Boolean(props.user && props.user.data?.Birthday),
      acceptedConditions: false,
      showBirthdayError: false,
    };
  }

  public render(): React.ReactNode {
    const { showAvatarSelector } = this.state;
    const { user } = this.props;

    if (!user) {
      return null;
    }

    return (
      <StyledForm onSubmit={this.saveProfile}>
        <FieldSet
          disabled={this.state.loading}
          style={{ display: showAvatarSelector ? 'none' : 'block' }}
        >
          {this.renderForm()}
        </FieldSet>
        <FieldSet
          disabled={this.state.loading}
          style={{ display: showAvatarSelector ? 'block' : 'none' }}
        >
          {this.renderAvatarsSelector()}
        </FieldSet>
      </StyledForm>
    );
  }

  private readonly renderForm = (): JSX.Element => {
    const { fields } = this.state;
    const { user, intl } = this.props;
    const selectOptions = [
      {
        label: intl.formatMessage({ id: 'settings.profile.gender.male' }),
        value: 1,
      },
      {
        label: intl.formatMessage({ id: 'settings.profile.gender.female' }),
        value: 2,
      },
    ];

    const stateGender =
      fields.Gender === 'male' ? selectOptions[0] : selectOptions[1];
    const userGender = user
      ? user.data?.Gender === 'male'
        ? selectOptions[0]
        : selectOptions[1]
      : selectOptions[0];

    const isValidBirthday =
      (fields.Birthday
        ? moment(fields.Birthday, this.birthdayFormat)
        : moment(user.data?.Birthday)
      ).isValid() &&
      (fields.Birthday ? fields.Birthday?.length === 10 : user.data?.Birthday);

    return (
      <div>
        <DateFormatUpdater intl={intl} setDateFormat={this.setDateFormat} />
        <StyledInputsWrapper>
          <StyledFirstNameWrapper>
            <TextInput
              autoFocus
              label={intl.formatMessage({ id: 'settings.profile.firstName' })}
              id="FirstName"
              variant={FormControlVariant.big}
              value={
                fields.FirstName !== undefined
                  ? fields.FirstName
                  : user
                    ? user.data?.FirstName || ''
                    : ''
              }
              autoComplete="given-name"
              name="given-name"
              onChange={this.onFormFieldChange}
              onFocus={this.onFormFieldFocus}
              onBlur={this.onFormFieldBlur}
              type="text"
            />
          </StyledFirstNameWrapper>
          <StyledSecondNameWrapper>
            <TextInput
              label={intl.formatMessage({ id: 'settings.profile.lastName' })}
              variant={FormControlVariant.big}
              id="LastName"
              value={
                fields.LastName !== undefined
                  ? fields.LastName
                  : user
                    ? user.data?.LastName || ''
                    : ''
              }
              type="text"
              autoComplete="family-name"
              name="family-name"
              onChange={this.onFormFieldChange}
              onFocus={this.onFormFieldFocus}
              onBlur={this.onFormFieldBlur}
            />
          </StyledSecondNameWrapper>
        </StyledInputsWrapper>
        <StyledInputsWrapper>
          <StyledBirthWrapper>
            <PatternInputDate
              label={intl.formatMessage({ id: 'settings.profile.birthday' })}
              variant={FormControlVariant.big}
              id="Birthday"
              placeholder={this.birthdayFormat}
              value={
                fields.Birthday !== undefined
                  ? fields.Birthday
                  : user && user.data?.Birthday
                    ? moment(user.data?.Birthday).format(this.birthdayFormat)
                    : ''
              }
              validateValue={this.birthdayValidator}
              name="bday"
              error={
                this.state.showBirthdayError && !isValidBirthday
                  ? intl.formatMessage({
                      id: 'settings.profile.birthday.invalid',
                    })
                  : undefined
              }
              pattern={this.birthdayFormat}
              delimiter={this.dateFormatDelimiter}
              autoComplete="bday"
              onChange={value =>
                this.setState(s => ({
                  ...s,
                  fields: { ...s.fields, Birthday: value },
                }))
              }
              onFocus={this.onFormFieldFocus}
              onBlur={() => {
                if (!this.state.showBirthdayError) {
                  this.setState(s => ({ ...s, showBirthdayError: true }));
                }
              }}
              todayButtonLabel={
                <FormattedMessage id="navbar.calendar.todayButton" />
              }
            />
          </StyledBirthWrapper>
          <StyledGenderWrapper>
            <SelectBoxWrapper>
              <SelectBox
                id="Gender"
                variant={FormControlVariant.big}
                label={intl.formatMessage({ id: 'settings.profile.gender' })}
                options={selectOptions}
                isSearchable={false}
                value={
                  fields.Gender !== undefined
                    ? stateGender
                    : user
                      ? userGender
                      : selectOptions[0]
                }
                defaultValue={selectOptions[0]}
                onChange={this.onSelectChange}
                noSeparator
                longList
                disablePortal
                maxMenuHeight={350}
              />
            </SelectBoxWrapper>
          </StyledGenderWrapper>
        </StyledInputsWrapper>
        {this.props.isRegistration && (
          <StyledInputsWrapper>
            <StyledPasswordWrapper>
              <input
                type="hidden"
                name="username"
                autoComplete="username"
                value={user ? user.data?.Email : ''}
              />
              <input
                type="hidden"
                name="email"
                autoComplete="email"
                value={user ? user.data?.Email : ''}
              />
              <TextInput
                type="Password"
                label={intl.formatMessage({ id: 'settings.profile.password' })}
                variant={FormControlVariant.big}
                id="Password"
                value={fields.Password ? fields.Password : ''}
                autoComplete="new-password"
                name="new-password"
                onChange={this.onFormFieldChange}
                noError
              />
              <PasswordRequirementsList>
                {!this.validateMinChars() && (
                  <li>
                    <Text size={TextSize.s14} inheritColor>
                      <FormattedMessage id="settings.profile.minChars" />
                    </Text>
                  </li>
                )}
                {!this.validateNumber() && (
                  <li>
                    <Text size={TextSize.s14} inheritColor>
                      <FormattedMessage id="settings.profile.hasNumber" />
                    </Text>
                  </li>
                )}
                {!this.validateCharacters() && (
                  <li>
                    <Text size={TextSize.s14} inheritColor>
                      <FormattedMessage id="settings.profile.hasCharacters" />
                    </Text>
                  </li>
                )}
              </PasswordRequirementsList>
            </StyledPasswordWrapper>
          </StyledInputsWrapper>
        )}
        <StyledButtonsWrapper>
          {this.props.isRegistration && (
            <Button
              appearance={ButtonAppearance.Secondary}
              onClick={this.props.backToTermsState}
              variant={FormControlVariant.big}
              inverted
              type="button"
            >
              <FormattedMessage id="settings.profile.form.back" />
            </Button>
          )}
          <Button
            type="submit"
            variant={FormControlVariant.big}
            disabled={
              !isValidBirthday ||
              (this.props.isRegistration &&
                (!this.validateMinChars() ||
                  !this.validateNumber() ||
                  !this.validateCharacters())) ||
              this.state.loading
            }
            appearance={ButtonAppearance.Primary}
          >
            <FormattedMessage id="settings.profile.form.next" />
          </Button>
        </StyledButtonsWrapper>
      </div>
    );
  };

  private readonly renderAvatarsSelector = (): JSX.Element => {
    const { user, avatars } = this.props;
    const { avatar, loading } = this.state;

    return (
      <>
        <StyledAvatarsContainer>
          {Array.from(avatars.entries()).map(
            ([id]) =>
              id !== DEFAULT_AVATAR && (
                <Avatar
                  key={id}
                  active={
                    (avatar === null &&
                      user &&
                      user.data?.AvatarFileName === id) ||
                    (avatar !== null && avatar === id)
                  }
                  id={id}
                  onClick={!loading ? this.onChangeAvatar : undefined}
                />
              )
          )}
        </StyledAvatarsContainer>
        <StyledButtonsWrapper>
          <Button
            variant={FormControlVariant.big}
            type="button"
            disabled={this.state.loading}
            appearance={ButtonAppearance.Secondary}
            onClick={this.hideAvatarSelector}
            inverted
          >
            <FormattedMessage id="settings.profile.form.back" />
          </Button>
          <StyledAsyncButtonWrapper>
            <AsyncButton
              innerRef={this.submitRef}
              type="submit"
              disabled={Boolean(
                avatar === null && user && user.data?.AvatarFileName === null
              )}
              loading={this.state.loading}
              success={this.state.loaded && !this.state.error}
              error={this.state.loaded && this.state.error}
            >
              <FormattedMessage id="settings.profile.form.save" />
            </AsyncButton>
          </StyledAsyncButtonWrapper>
        </StyledButtonsWrapper>
      </>
    );
  };

  private readonly onChangeAvatar = (avatar: string): void => {
    this.setState(s => ({ ...s, avatar }));
  };

  private readonly onFormFieldChange = (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { target } = e;
    if (
      target instanceof HTMLInputElement ||
      target instanceof HTMLSelectElement
    ) {
      const { value, id } = target;

      this.setState(s => ({
        ...s,
        fields: { ...s.fields, [id]: value },
      }));
    }
  };

  private readonly onSelectChange = (value: SelectValueProps): void => {
    this.setState(s => ({
      ...s,
      fields: { ...s.fields, Gender: value.value === 1 ? 'male' : 'female' },
    }));
  };

  private readonly showAvatarSelector = (): void => {
    trackProfileNextButtonClick(this.props.isRegistration);
    this.setState(s => ({ ...s, showAvatarSelector: true }));
    this.props.layer?.scrollContainerRef?.current?.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  private readonly hideAvatarSelector = (): void => {
    trackProfileBackButtonClick(this.props.isRegistration);
    this.setState(s => ({ ...s, showAvatarSelector: false }));
    this.props.layer?.scrollContainerRef?.current?.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  private readonly saveProfile = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (!this.state.showAvatarSelector) {
      this.showAvatarSelector();
      setTimeout(() => {
        if (this.submitRef.current !== null) {
          this.submitRef.current.focus();
        }
      }, 0);

      return;
    }

    const { fields, avatar } = this.state;
    const { user, isRegistration, commercialConsent = false } = this.props;
    if (user) {
      const newAvatar = avatar || user.data?.AvatarFileName;
      const birthDay = fields.Birthday;
      const formattedBirthday = birthDay
        ? moment(birthDay, this.birthdayFormat).format(DATE_FORMAT)
        : user.data?.Birthday;

      const updatedUser: User = {
        ...user.data!,
        ...fields,
        CommercialConsent: commercialConsent,
        AvatarFileName: newAvatar || null,
        Birthday: formattedBirthday!,
      };

      this.setState(s => ({ ...s, loading: true }));

      const success = isRegistration
        ? await user.register(updatedUser)
        : await user.update(updatedUser);

      if (success) {
        if (isRegistration) {
          window.location.href = '/';
        } else {
          toast('toast.success.changeProfile', 'success');
          this.props.layer?.close();
        }
      } else {
        if (!isRegistration) {
          toast('toast.error.changeProfile', 'error');
        }
        this.setState(s => ({
          ...s,
          loaded: true,
          loading: false,
          error: true,
        }));
      }
    }
  };

  private readonly validateMinChars = (): boolean => {
    const { Password } = this.state.fields;

    return Boolean(Password !== undefined && Password.length >= 6);
  };

  private readonly validateNumber = (): boolean => {
    const { Password } = this.state.fields;

    return Boolean(Password && /\d/.test(Password));
  };

  private readonly validateCharacters = (): boolean => {
    const { Password } = this.state.fields;

    return Boolean(Password && /(?=.*[a-z])(?=.*[A-Z])/.test(Password));
  };

  private readonly onFormFieldFocus = (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { target } = e;
    if (
      target instanceof HTMLInputElement ||
      target instanceof HTMLSelectElement
    ) {
      const { id } = target;
      trackProfileFormFieldFocus(this.props.isRegistration, id);
    }
  };

  private readonly onFormFieldBlur = (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { target } = e;
    if (
      target instanceof HTMLInputElement ||
      target instanceof HTMLSelectElement
    ) {
      const { id } = target;
      trackProfileFormFieldBlur(this.props.isRegistration, id);
    }
  };

  private readonly setDateFormat = (
    format: string,
    delimiter: string[]
  ): void => {
    this.birthdayFormat = format;
    this.dateFormatDelimiter = delimiter;
    this.birthdayValidator = createDateFormatValidator(format);
  };
}

const withIntl = injectIntl(UpdateProfileForm);
export { withIntl as UpdateProfileForm };
