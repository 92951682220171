import * as React from 'react';
import styled, { css } from 'styled-components';

enum FileType {
  video = 'video',
  picture = 'picture',
  general = 'general',
}

export const Attachments = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr 36px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

const StyledAttachmentName = styled.div`
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 36px;
`;

export interface StyledAttachmentIconProps {
  fileType?: FileType;
}

function getAttachmentIconColor(fileType?: FileType): string {
  switch (fileType) {
    case FileType.picture:
      return '#2273D3';
    case FileType.video:
      return '#D0021B';
    default:
      return '#000000';
  }
}

export const StyledAttachmentIcon = styled.div<StyledAttachmentIconProps>`
  background-color: rgb(230, 240, 251);
  border-radius: 4px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => getAttachmentIconColor(props.fileType)};
`;

const StyledDownloadIcon = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AttachmentFileInput = styled.input`
  display: none;
`;

export const AttachmentAttributeLabelContainer = styled.div<{
  readonly isHidden?: boolean;
  readonly filesUploaded?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => !props.isHidden && !props.filesUploaded && 'margin-bottom:  10px'};
  width: 100%;
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

export const AttachmentItemWrapper = styled.div`
  display: contents;
`;

export const AddAttachmentButton = styled.label<{
  readonly disabled?: boolean;
}>`
  color: #4a90e2;
  cursor: pointer;
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: #d4d6d9;
    `};
`;

export function AttachmentName(
  props: React.PropsWithChildren<{ title?: string }>
): JSX.Element {
  return (
    <StyledAttachmentName title={props.title} data-cy="attachment-name">
      {props.children}
    </StyledAttachmentName>
  );
}

export function AttachmentIcon(
  props: React.PropsWithChildren<StyledAttachmentIconProps>
): JSX.Element {
  return (
    <StyledAttachmentIcon fileType={props.fileType}>
      {props.children}
    </StyledAttachmentIcon>
  );
}

export function ActionIcon(props: React.PropsWithChildren): JSX.Element {
  return <StyledDownloadIcon>{props.children}</StyledDownloadIcon>;
}
