import { PopoverItem } from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { WeekSummaryStore } from '../mobx/week-summary-store';

export interface InternalPinToTop {
  day: WeekSummaryStore;
  hideTooltip?(e: MouseEvent): void;
}

function InternalPintoTop(props: InternalPinToTop): JSX.Element {
  const { day, hideTooltip } = props;
  const isPinned = day.isPinned;

  const onClick = useCallback(
    (e: MouseEvent) => {
      day.isPinned ? day.unpinFromTop() : day.pinToTop();
      hideTooltip?.(e);
    },
    [day, hideTooltip]
  );

  return (
    <PopoverItem onClick={onClick}>
      {!isPinned ? (
        <FormattedMessage id="trainingDay.attributes.weekGoal.pin" />
      ) : (
        <FormattedMessage id="trainingDay.attributes.weekGoal.unPin" />
      )}
    </PopoverItem>
  );
}

export const PinToTop = observer(InternalPintoTop);
