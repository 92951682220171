import {
  Mark,
  markInputRule,
  markPasteRule,
  mergeAttributes,
} from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { VideoService } from '@yarmill/types';
import { inputRegex, pasteRegex } from './highlight';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    videoLink: {
      /**
       * Set a highlight mark
       */
      setVideoLink: (attributes?: { href: string }) => ReturnType;
      /**
       * Toggle a highlight mark
       */
      toggleVideoLink: (attributes?: { href: string }) => ReturnType;
      /**
       * Unset a highlight mark
       */
      unsetVideoLink: () => ReturnType;
    };
  }
}

interface VideoLinkOptions {
  readonly videoService: VideoService | null;
  readonly HTMLAttributes: Record<string, any>;
}

interface VideoLinkClickHandlerOptions {
  readonly videoService: VideoService | null;
}

export const VideoLink = Mark.create<VideoLinkOptions>({
  name: 'videoLink',
  keepOnSplit: false,
  priority: 1000000,

  addOptions() {
    return {
      videoService: null,
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      href: {
        default: null,
        parseHTML: (element: HTMLElement) =>
          element.className.split(' ')?.find(c => c.includes('ytd-video')) &&
          element.getAttribute('href'),
        renderHTML: attributes => {
          if (!attributes.href) {
            return {};
          }

          return {
            href: attributes.href,
          };
        },
      },
      class: {
        default: 'ytd-video',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'a',
        getAttrs: element =>
          element instanceof HTMLElement
            ? {
                class: element.className.includes('ytd-video'),
                href: element.getAttribute('href'),
              }
            : null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'a',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setVideoLink:
        attributes =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes);
        },
      toggleVideoLink:
        attributes =>
        ({ commands }) => {
          return commands.toggleMark(this.name, attributes);
        },
      unsetVideoLink:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },

  addInputRules() {
    return [
      markInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: pasteRegex,
        type: this.type,
      }),
    ];
  },

  addProseMirrorPlugins() {
    return [clickHandler({ videoService: this.options.videoService })];
  },
});

function clickHandler({ videoService }: VideoLinkClickHandlerOptions): Plugin {
  return new Plugin({
    key: new PluginKey('handleClickVideoLink'),
    props: {
      handleClick: (_view, _pos, event) => {
        if (event.button !== 0) {
          return false;
        }

        const link = (event.target as HTMLElement).closest('a');

        const className = link?.className;
        const href = link?.href;
        const videoLinkParts = href?.split('/');
        const videoId = videoLinkParts?.[videoLinkParts.length - 1];

        if (
          link &&
          className?.includes('ytd-video') &&
          videoId &&
          videoService
        ) {
          videoService.openVideoPlayer(videoId);

          return true;
        }

        return false;
      },
    },
  });
}
