import { RootStore } from '@yarmill/types';
import { action, computed, makeObservable } from 'mobx';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { DiaryDataUniqueId } from '../../diary/types';
import { getDateForDayIndexString } from '../../diary/utils';
import { TrainingDayStore } from '../../training-day/mobx/training-day-store';
import {
  TrainingDayAttribute,
  TrainingDayData,
} from '../../training-day/types';
import { getDayIndexFromGoalIndex } from '../utils';
import { GoalAttributeStore } from './goal-attribute-store';

export class GoalStore extends TrainingDayStore {
  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    index: number,
    attributes: TrainingDayAttribute[],
    dataId: DiaryDataUniqueId
  ) {
    super(rootStore, diaryStore, index, attributes, dataId);
    makeObservable(this);
  }

  @computed
  public get attribute(): GoalAttributeStore {
    return this.attributes[0] as GoalAttributeStore;
  }

  @action
  public setPlaceholder(item: TrainingDayData): void {
    this.attribute.setPlaceholderText(item);
  }

  get currentDate(): string {
    const dayIndex = getDayIndexFromGoalIndex(this.index);
    return getDateForDayIndexString(this.dataId.week, dayIndex);
  }

  protected initAttributes(attributes: TrainingDayAttribute[]) {
    attributes.forEach(item => {
      const store = new GoalAttributeStore(
        this.rootStore,
        this.diaryStore,
        this,
        item,
        this.dataId
      );
      this._attributes.set(item.AttributeItemId, store);
    });
  }
}
