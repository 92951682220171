import { styled, Text } from '@yarmill/components';
import { getTextAppearanceStyles } from './text';

export const PrimarySidebarLayout = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  transition: opacity 0.05s ease-in-out 0s;
`;

export const PrimarySidebarHeaderLayout = styled.div`
  display: flex;
  flex: initial;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  gap: ${({ theme }) => theme.size.x25};
  padding-bottom: ${({ theme }) => theme.size.x15};
  flex-shrink: 0;
  height: ${({ theme }) => theme.size.x7};

  @media (min-width: 768px) {
    padding-right: 0;
  }
`;

export const PrimarySidebarHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  max-width: 100%;
  background-color: ${({ theme }) => theme.color.neutral_8};
  padding: ${({ theme }) => theme.size.x1};
  border-radius: ${({ theme }) => theme.borderRadius.x2};
`;

export const PrimarySidebarHeaderLogo = styled.div`
  background: transparent;
  border: none;

  display: flex;
  flex: initial;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  overflow: hidden;
`;
export const PrimarySidebarHeaderSpacer = styled.div`
  min-width: ${({ theme }) => theme.size.x1};
  display: flex;
  flex-shrink: initial;
  flex-basis: initial;
  flex-direction: row;
  -webkit-box-flex: 1;
  flex-grow: 1;
`;
export const PrimarySidebarHeaderCurrentUser = styled.button`
  width: ${({ theme }) => theme.size.x35};
  height: ${({ theme }) => theme.size.x35};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.size.x05};
  border-radius: ${({ theme }) => theme.size.x05};
  background: transparent;
  border: none;
  transition:
    background-color,
    border 250ms ease;
  cursor: pointer;
  color: ${({ theme }) => theme.color.neutral};

  :hover {
    background-color: ${({ theme }) => theme.color.neutralPlate};
  }
`;
export const PrimarySidebarHeaderLogoImage = styled.img`
  width: auto;
  height: ${({ theme }) => theme.size.x4};
  pointer-events: none;
`;

export const PrimarySidebarHeaderLogoInstanceName = styled(Text)`
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.color.neutralDark};
  ${getTextAppearanceStyles('task13strong')};
`;

export const PrimarySidebarAvatarWrapper = styled.div`
  height: ${({ theme }) => theme.size.x4};
  width: ${({ theme }) => theme.size.x4};
`;
