import { RootStore } from '@yarmill/types';
import { makeObservable, observable, override } from 'mobx';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { DiaryDataUniqueId } from '../../diary/types';
import { TrainingDayStore } from '../../training-day/mobx/training-day-store';
import {
  HealthDataValue,
  TrainingDayAttribute,
  TrainingDayData,
} from '../../training-day/types';
import { AbstractTrainingDayAttributeStore } from './abstract-training-day-attribute-store';

export class HealthDataAttributeStore extends AbstractTrainingDayAttributeStore {
  @observable
  protected _healthDataItems: HealthDataValue[] = [];
  @observable
  private _updatedAt: string | null = null;

  protected userValue: null = null;

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    trainingDayStore: TrainingDayStore,
    attribute: TrainingDayAttribute,
    dataId: DiaryDataUniqueId
  ) {
    super(rootStore, diaryStore, trainingDayStore, attribute, dataId);
    makeObservable(this);
  }

  protected get currentValue(): string | string[] {
    return '';
  }

  @override
  setApiValue(newValue: TrainingDayData) {
    super.setApiValue(newValue);
    this._healthDataItems = newValue.Value as HealthDataValue[];
    this._updatedAt = newValue.LastUpdated;
  }

  @override
  clear(): void {
    super.clear();
    this._healthDataItems = [];
  }

  public get healthDataItems(): HealthDataValue[] {
    return this._healthDataItems;
  }

  public get updatedAt(): string | null {
    return this._updatedAt;
  }
}
