import { AttachmentAttribute } from '../attributes/attachment/attachment-attribute';
import { InputArrayAttribute } from '../attributes/input-array/input-array-attribute';
import { AbstractTrainingDayAttributeStore } from '../attributes/mobx/abstract-training-day-attribute-store';
import { RichtextAttributeStore } from '../attributes/mobx/richtext-attribute-store';
import { AttachmentAttributeStore } from '../attributes/mobx/attachment-attribute-store';
import { InputArrayAttributeStore } from '../attributes/mobx/input-array-attribute-store';
import { WorkoutAttributeStore } from '../attributes/mobx/workout-attribute-store';
import { CgmAttributeStore } from '../attributes/mobx/cgm-attribute-store';
import { WorkoutAttribute } from '../attributes/workout-attribute';
import { CgmAttribute } from '../attributes/cgm-attribute';
import { HealthDataAttribute } from '../attributes/health/health-data-attribute';
import { HealthDataAttributeStore } from '../attributes/mobx/health-data-attribute-store';
import { RichtextAttribute } from '../attributes/richtext/richtext-attribute';

export interface TrainingDayAttributeProps {
  attribute: AbstractTrainingDayAttributeStore;
}

export function TrainingDayAttribute(
  props: TrainingDayAttributeProps
): JSX.Element | null {
  const { attribute } = props;

  if (attribute instanceof AttachmentAttributeStore) {
    return <AttachmentAttribute attribute={attribute} />;
  } else if (attribute instanceof InputArrayAttributeStore) {
    return <InputArrayAttribute attribute={attribute} />;
  } else if (attribute instanceof RichtextAttributeStore) {
    return <RichtextAttribute attribute={attribute} />;
  } else if (attribute instanceof WorkoutAttributeStore) {
    return <WorkoutAttribute attribute={attribute} />;
  } else if (attribute instanceof CgmAttributeStore) {
    return <CgmAttribute attribute={attribute} />;
  } else if (attribute instanceof HealthDataAttributeStore) {
    return <HealthDataAttribute attribute={attribute} />;
  }

  return null;
}
