import { ExternalIcon } from '@yarmill/components';
import { NavigationLink as iNavigationLink } from '@yarmill/types';
import { useDefaultCommonSearchParams } from '@yarmill/utils';
import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DropdownLinks } from './dropdown-links';
import { NavItem } from './nav-item';

interface NavigationLinkProps {
  readonly item: iNavigationLink;
}
export function NavigationLink({
  item,
}: NavigationLinkProps): ReactElement | null {
  const intl = useIntl();
  const defaultSearchParams = useDefaultCommonSearchParams();
  if (item.children?.length) {
    return (
      <DropdownLinks
        label={<FormattedMessage id={item.label} />}
        icon={<ExternalIcon name={item.icon} />}
        links={item.children}
        isActive={item.isActive}
        id="reportingItems"
      />
    );
  }

  if (!item.createClickHandler) {
    return null;
  }

  return (
    <li>
      <NavItem
        moduleKey={item.moduleCode}
        to={item.createClickHandler(defaultSearchParams)}
        linkText={intl.formatMessage({ id: item.label })}
        icon={item.icon}
        isLinkActive={item.isActive}
      />
    </li>
  );
}
