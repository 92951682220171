import { ISO_DATE_FORMAT } from '@yarmill/const';
import { DateFilter } from '@yarmill/types';
import moment from 'moment';
import { ComponentType, useState } from 'react';
import styled from 'styled-components';
import { DatepickerProps } from '../datepicker';
import { PatternInputProps } from '../pattern-input';
import { RadioButton } from '../radio-button';

const DateFiltersWrapper = styled.div`
  display: grid;
  padding-bottom: 10px;
  row-gap: 8px;
`;
const CustomDatesWrapper = styled.div`
  display: grid;
  row-gap: 5px;
  padding: 5px 0 5px 36px;
`;

const noop = () => null;

export interface DateFiltersProps {
  PatternInputDate: ComponentType<PatternInputProps & DatepickerProps>;
  dateFormat?: string;
  value: DateFilter | undefined;
  setCurrentValue(value: DateFilter): void;
  translate?(key: string): string;
}

export function DateFilters(props: DateFiltersProps): JSX.Element {
  const {
    value,
    setCurrentValue,
    PatternInputDate,
    dateFormat = 'DD.MM.YYYY',
    translate,
  } = props;
  const [inputValues, setInputValue] = useState({
    from:
      value?.type === 'range' && value.from
        ? moment(value.from, ISO_DATE_FORMAT).format(dateFormat)
        : '',
    to:
      value?.type === 'range' && value.to
        ? moment(value.to, ISO_DATE_FORMAT).format(dateFormat)
        : '',
    'match-date':
      value?.type === 'match' && value.values
        ? moment(value.values[0], ISO_DATE_FORMAT).format(dateFormat)
        : '',
  });

  function onInputChange(input: string, inputValue: string): void {
    if (input === 'from-date') {
      setInputValue(v => ({ ...v, from: inputValue }));
    } else if (input === 'to-date') {
      setInputValue(v => ({ ...v, to: inputValue }));
    } else if (input === 'match-date') {
      setInputValue(v => ({ ...v, 'match-date': inputValue }));
    }
  }

  function onRangeBlur(): void {
    const fromValue =
      inputValues['from'].length === dateFormat.length ? inputValues.from : '';
    const toValue =
      inputValues['to'].length === dateFormat.length ? inputValues.to : '';
    const from = fromValue ? moment(fromValue, dateFormat) : '';
    const to = toValue ? moment(toValue, dateFormat) : '';

    setCurrentValue({
      type: 'range',
      from: from && from.isValid() ? from.format(ISO_DATE_FORMAT) : '',
      to: to && to.isValid() ? to.format(ISO_DATE_FORMAT) : '',
    });
  }

  function onMatchBlur(): void {
    if (inputValues['match-date'].length !== dateFormat.length) {
      return;
    }
    const formattedValue = moment(inputValues['match-date'], dateFormat).format(
      ISO_DATE_FORMAT
    );
    setCurrentValue({ type: 'match', values: [formattedValue] });
  }

  return (
    <DateFiltersWrapper>
      <RadioButton
        value="this-week"
        label={translate?.('table.filters.thisWeek')}
        checked={value?.type === 'thisweek'}
        onClick={() => setCurrentValue({ type: 'thisweek' })}
        onChange={noop}
      />
      <RadioButton
        value="last-week"
        label={translate?.('table.filters.lastWeek')}
        checked={value?.type === 'lastweek'}
        onClick={() => setCurrentValue({ type: 'lastweek' })}
        onChange={noop}
      />
      <RadioButton
        value="this-month"
        label={translate?.('table.filters.thisMonth')}
        checked={value?.type === 'thismonth'}
        onClick={() => setCurrentValue({ type: 'thismonth' })}
        onChange={noop}
      />
      <RadioButton
        value="last-month"
        label={translate?.('table.filters.lastMonth')}
        checked={value?.type === 'lastmonth'}
        onClick={() => setCurrentValue({ type: 'lastmonth' })}
        onChange={noop}
      />
      <RadioButton
        value="match"
        label={translate?.('table.filters.match')}
        checked={value?.type === 'match'}
        onChange={noop}
        onClick={() => setCurrentValue({ type: 'match', values: [''] })}
      />
      {value?.type === 'match' && (
        <CustomDatesWrapper>
          <PatternInputDate
            autoFocus
            pattern={dateFormat}
            id="match-date"
            noError
            noLabel
            value={inputValues['match-date']}
            onChange={val => onInputChange('match-date', val)}
            onBlur={onMatchBlur}
          />
        </CustomDatesWrapper>
      )}
      <RadioButton
        value="range"
        label={translate?.('table.filters.range')}
        checked={value?.type === 'range'}
        onChange={noop}
        onClick={() => setCurrentValue({ type: 'range', from: '', to: '' })}
      />
      {value?.type === 'range' && (
        <CustomDatesWrapper>
          <PatternInputDate
            autoFocus
            pattern={dateFormat}
            id="from-date"
            noError
            label={translate?.('table.filters.range.from')}
            value={inputValues.from}
            onChange={val => onInputChange('from-date', val)}
            onBlur={onRangeBlur}
            maxBookingDate={value.to ? moment(value.to).toDate() : undefined}
          />
          <PatternInputDate
            pattern={dateFormat}
            id="to-date"
            noError
            label={translate?.('table.filters.range.to')}
            value={inputValues.to}
            onChange={val => onInputChange('to-date', val)}
            onBlur={onRangeBlur}
            minBookingDate={
              value.from ? moment(value.from).toDate() : undefined
            }
          />
        </CustomDatesWrapper>
      )}
    </DateFiltersWrapper>
  );
}
