import { GlobalIntl } from '@yarmill/components';
import {
  Command,
  ExternalIconName,
  ModuleCode,
  ModuleDefinitionStore,
  NavigationLink,
  RootStore,
} from '@yarmill/types';
import {
  createModuleLink,
  generateCommandPaletteModuleUserCommands,
  getCurrentUserAllowedGroups,
} from '@yarmill/utils';
import { getAllowedViewType } from '../utils/get-allowed-view-type';

export class PlanModuleDefinitionStore implements ModuleDefinitionStore {
  private readonly _rootStore: RootStore;
  private readonly _icon: ExternalIconName = 'ClipboardList';
  private readonly _baseUrl: string = '/plan';
  readonly moduleCode: ModuleCode = 'plan';

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
  }

  get navigationLinks(): NavigationLink[] {
    const currentUser = this._rootStore.currentUserStore;
    const memoryStore = this._rootStore.memoryStore;
    const viewType = getAllowedViewType(
      this.moduleCode as 'plan',
      currentUser,
      memoryStore
    );

    return [
      {
        icon: this._icon,
        moduleCode: this.moduleCode,
        label: 'header.navigation.plan',
        isActive: path => path.startsWith(this._baseUrl),
        createClickHandler: defaultSearchParams => location =>
          createModuleLink(
            `${this._baseUrl}/${viewType}`,
            defaultSearchParams,
            location
          ),
      },
    ];
  }

  get commandPaletteCommands(): Command[] {
    const history = this._rootStore.historyService.history;
    const currentUser = this._rootStore.currentUserStore;

    const groups = getCurrentUserAllowedGroups(
      this._rootStore.groupsStore,
      currentUser
    );
    const memoryStore = this._rootStore.memoryStore;
    const viewType = getAllowedViewType(
      this.moduleCode as 'plan',
      currentUser,
      memoryStore
    );

    const generalCommand: Command = {
      priority: 1,
      icon: this._icon,
      name: GlobalIntl.formatMessage({
        id: 'commandPalette.goToPlan',
      }),
      createCommand: (defaultSearchParams: string) => () => {
        history.push(
          createModuleLink(
            `${this._baseUrl}/${viewType}`,
            defaultSearchParams,
            history.location
          )
        );
      },
    };

    const userCommands: Command[] = !currentUser.isAthlete
      ? generateCommandPaletteModuleUserCommands(
          groups,
          this._icon,
          this.moduleCode,
          `${this._baseUrl}/${viewType}`,
          history,
          GlobalIntl
        )
      : [];

    return [generalCommand, ...userCommands];
  }
}
