import { AnalyticsChartHeader } from '@yarmill/components';
import { Units } from '@yarmill/types';
import { WrappedComponentProps } from 'react-intl';
import { formatValueByUnitForAnalytics } from './utils';

export interface ChartHeaderProps extends WrappedComponentProps {
  reality: number;
  plan: number;
  absDiff: number;
  relDiff: number;
}

export function ChartHeader(props: ChartHeaderProps): JSX.Element {
  return (
    <AnalyticsChartHeader>
      <svg viewBox="0 0 466 105">
        <defs>
          <circle id="path-1" cx="9" cy="9" r="8" />
          <mask
            id="mask-2"
            maskContentUnits="userSpaceOnUse"
            maskUnits="objectBoundingBox"
            x="0"
            y="0"
            width="16"
            height="16"
            fill="white"
          >
            <use xlinkHref="#path-1" />
          </mask>
        </defs>
        <g
          id="TD-novy-design"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="BasicStatistics-Copy"
            transform="translate(-356.000000, -521.000000)"
          >
            <g id="This-week" transform="translate(298.000000, 417.000000)">
              <g id="Group-2" transform="translate(8.000000, 62.000000)">
                <g id="Group-42" transform="translate(50.000000, 40.000000)">
                  <g id="Group-oval">
                    <g transform="translate(155, 4) scale(0.75)">
                      <path
                        d="M9.5,19 C14.7467051,19 19,14.7467051 19,9.5 C19,4.25329488 14.7467051,0 9.5,0 C4.25329488,0 0,4.25329488 0,9.5 C0,14.7467051 4.25329488,19 9.5,19 Z"
                        id="sedy-krouzek"
                        stroke="#EDF0F5"
                        strokeWidth={4}
                        strokeDasharray="60,60"
                        transform="translate(9.500000, 9.500000) scale(-1, 1) rotate(180.000000) translate(-9.500000, -9.500000) "
                      />
                      <path
                        d="M9.5,19 C14.7467051,19 19,14.7467051 19,9.5 C19,4.25329488 14.7467051,0 9.5,0 C4.25329488,0 0,4.25329488 0,9.5 C0,14.7467051 4.25329488,19 9.5,19 Z"
                        id="zluty-krouzek"
                        stroke="#F2CA18"
                        strokeWidth={4}
                        strokeLinecap="round"
                        strokeLinejoin="bevel"
                        strokeDasharray={`${
                          props.relDiff > 100
                            ? (60 / 100) * 100
                            : props.relDiff < 0
                              ? 0
                              : props.relDiff * (60 / 100)
                        },60`}
                        transform="translate(9.500000, 9.500000) scale(-1, 1) rotate(180.000000) translate(-9.500000, -9.500000) "
                      />
                    </g>
                  </g>
                  <text
                    id="62%"
                    fontFamily="Ubuntu-Medium, Ubuntu"
                    fontSize="14"
                    fontWeight="400"
                    fill="#4A4A4A"
                  >
                    <tspan x="182.269" y="16">
                      {props.relDiff}%
                    </tspan>
                  </text>
                  <g
                    id="sipky"
                    transform={
                      props.plan > 60 * 99
                        ? 'translate(20.000000, 0.000000)'
                        : undefined
                    }
                  >
                    <path
                      d="M254.468387,90.3147059 C254.468568,90.525248 254.550253,90.7270993 254.695484,90.8758824 C254.827559,91.025261 255.015011,91.1104553 255.211613,91.1104553 C255.408215,91.1104553 255.595667,91.025261 255.727742,90.8758824 L257.694194,88.79 L257.694194,100.902941 C257.694194,101.34152 258.040812,101.697059 258.468387,101.697059 C258.895962,101.697059 259.242581,101.34152 259.242581,100.902941 L259.242581,88.6152941 L261.307097,90.8494118 C261.610952,91.16945 262.106351,91.1880977 262.432258,90.8917647 C262.58575,90.7514677 262.678535,90.554309 262.690154,90.3437638 C262.701773,90.1332187 262.63127,89.9265793 262.494194,89.7694118 L259.397419,86.3282353 C259.224404,86.1391711 258.970492,86.0516927 258.72129,86.0952941 C258.470728,86.0426676 258.211115,86.1221583 258.029677,86.3070588 L254.674839,89.7482353 C254.535386,89.9014274 254.461104,90.1052476 254.468387,90.3147059 L254.468387,90.3147059 Z"
                      id="Shape"
                      fill={props.absDiff > 0 ? '#97C66B' : '#D4D6D9'}
                    />
                    <path
                      d="M251.242581,103.793529 C251.489719,103.846077 251.746263,103.770892 251.929032,103.592353 L255.283871,100.283529 C255.585758,99.9734873 255.585758,99.4712186 255.283871,99.1611765 C254.955814,98.8510438 254.450638,98.8510438 254.122581,99.1611765 L252.011613,101.178235 L252.011613,89.2558824 C252.011613,88.8173033 251.664995,88.4617647 251.237419,88.4617647 C250.809844,88.4617647 250.463226,88.8173033 250.463226,89.2558824 L250.463226,101.278824 L248.517419,99.0447059 C248.383988,98.8908407 248.195207,98.7993691 247.994596,98.7913778 C247.793985,98.7833866 247.598857,98.8595655 247.454194,99.0023529 C247.300312,99.1542843 247.215991,99.3657085 247.221935,99.5847059 C247.226378,99.7819127 247.301748,99.9704714 247.433548,100.114118 L250.530323,103.555294 C250.711432,103.755976 250.980953,103.846125 251.242581,103.793529 Z"
                      id="Shape"
                      fill={props.absDiff < 0 ? '#F97272' : '#D4D6D9'}
                    />
                  </g>
                  <text
                    id="8:10"
                    fontFamily="Ubuntu-Medium, Ubuntu"
                    fontSize="14"
                    fontWeight="400"
                    transform={
                      props.plan > 60 * 99
                        ? 'translate(20.000000, 0.000000)'
                        : undefined
                    }
                    fill={
                      props.absDiff > 0
                        ? '#97C66B'
                        : props.absDiff < 0
                          ? '#F97272'
                          : '#D4D6D9'
                    }
                  >
                    <tspan x="270.42" y="101">
                      {formatValueByUnitForAnalytics(props.absDiff, Units.min)}
                    </tspan>
                  </text>
                  <text
                    id="13:40"
                    fontFamily="Ubuntu-Medium, Ubuntu"
                    fontSize="36"
                    fontWeight="400"
                    fill="#4A4A4A"
                  >
                    <tspan x="0" y="106">
                      {formatValueByUnitForAnalytics(props.reality, Units.min)}
                    </tspan>
                  </text>
                  <text
                    id="Hodiny-zatížení"
                    fontFamily="Ubuntu-Bold, Ubuntu"
                    fontSize="14"
                    fontWeight="bold"
                    letterSpacing="0.6"
                    fill="#4A4A4A"
                  >
                    <tspan x="0" y="16">
                      {props.intl.formatMessage({
                        id: 'analytics.charts.header',
                      })}
                    </tspan>
                  </text>
                  <text
                    id="21:50"
                    fontFamily="Ubuntu-Medium, Ubuntu"
                    fontSize="36"
                    fontWeight="400"
                    fill="#4A4A4A"
                  >
                    <tspan x="144" y="106">
                      {formatValueByUnitForAnalytics(props.plan, Units.min)}
                    </tspan>
                  </text>
                  <text
                    id="plan"
                    fontFamily="Ubuntu"
                    fontSize="14"
                    fontWeight="normal"
                    fill="#B7BECC"
                  >
                    <tspan x="159" y="70">
                      {props.intl.formatMessage({
                        id: 'analytics.charts.header.plan',
                      })}
                    </tspan>
                  </text>
                  <text
                    id="reality"
                    fontFamily="Ubuntu"
                    fontSize="14"
                    fontWeight="normal"
                    fill="#B7BECC"
                  >
                    <tspan x="15" y="70">
                      {props.intl.formatMessage({
                        id: 'analytics.charts.header.reality',
                      })}
                    </tspan>
                  </text>
                  <circle
                    id="Oval-2"
                    stroke="#F2C80F"
                    strokeWidth="2"
                    fillRule="nonzero"
                    cx="4"
                    cy="66"
                    r="3"
                  />
                  <circle
                    id="Oval-2-Copy"
                    stroke="#D8D8D8"
                    strokeWidth="2"
                    fillRule="nonzero"
                    cx="148"
                    cy="66"
                    r="3"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </AnalyticsChartHeader>
  );
}
