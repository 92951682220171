import {
  ElementProps,
  ExtendedRefs,
  FloatingPortal,
  autoUpdate,
  flip,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import {
  DropdownIcon,
  DropdownNavigationList,
  ExternalIcon,
  IconSize,
  MainNavigationDropdownButton,
} from '@yarmill/components';
import { NavigationLink as iNavigationLink } from '@yarmill/types';
import { useDefaultCommonSearchParams, useWindowSize } from '@yarmill/utils';
import { CSSProperties, ReactElement, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { NavItem } from './nav-item';
import { NavLinkContent } from './nav-link-conent';
import { NavigationLink } from './navigation-link';
import { DropdownContext } from './utils';
import { isActive } from './utils/is-active';

export interface DropdownLinksProps {
  readonly label: ReactElement;
  readonly icon: ReactElement;
  readonly links: iNavigationLink[];
  readonly id?: string;
  readonly isActive?: (pathname: string) => boolean;
}

interface DropdownPortalProps {
  readonly children: ReactElement;
  readonly refs: ExtendedRefs<Element>;
  readonly floatingStyles: CSSProperties;
  readonly dismiss: ElementProps;
}
function DropdownPortal({
  children,
  refs,
  floatingStyles,
  dismiss,
}: DropdownPortalProps): ReactElement | null {
  const { width = 0 } = useWindowSize();
  const { getFloatingProps } = useInteractions([dismiss]);

  if (width < 768) {
    return children;
  }

  return (
    <FloatingPortal>
      <div
        style={{ ...floatingStyles, zIndex: 4 }}
        {...getFloatingProps()}
        ref={refs.setFloating}
      >
        {children}
      </div>
    </FloatingPortal>
  );
}

export function DropdownLinks(props: DropdownLinksProps): ReactElement | null {
  const {
    label,
    icon,
    links,
    id = 'otherNavigationItems',
    isActive: isDropdownActive,
  } = props;
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isDropdownOpened,
    onOpenChange: setIsDropdownOpened,
    placement: 'bottom-start',
    middleware: [flip()],
    whileElementsMounted: autoUpdate,
  });
  const defaultSearchParams = useDefaultCommonSearchParams();
  const intl = useIntl();
  const location = useLocation();

  const closeDropdown = useCallback(() => {
    setIsDropdownOpened(false);
  }, []);

  const dismiss = useDismiss(context, {
    bubbles: true,
  });
  const { getReferenceProps } = useInteractions([dismiss]);

  if (links.length === 0) {
    return null;
  }

  if (links.length === 1) {
    const module = links[0];

    if (!module.createClickHandler) {
      return null;
    }
    return (
      <NavItem
        moduleKey={module.moduleCode}
        to={module.createClickHandler(defaultSearchParams)}
        linkText={intl.formatMessage({ id: module.label })}
        icon={module.icon}
        isLinkActive={module.isActive}
      />
    );
  }

  const hasActiveLink = isDropdownActive
    ? isDropdownActive(location.pathname)
    : links.some(link =>
        isActive(
          location.pathname,
          link.createClickHandler?.(defaultSearchParams)(location) ?? '#@#'
        )
      );

  return (
    <li>
      <MainNavigationDropdownButton
        id={id}
        aria-haspopup="true"
        aria-expanded={isDropdownOpened}
        onClick={() => setIsDropdownOpened(o => !o)}
        $isActiveLink={hasActiveLink || isDropdownOpened}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <NavLinkContent linkText={label} linkIcon={icon} />
        <DropdownIcon size={IconSize.s16}>
          {isDropdownOpened ? (
            <ExternalIcon name="ChevronUp" />
          ) : (
            <ExternalIcon name="ChevronDown" />
          )}
        </DropdownIcon>
      </MainNavigationDropdownButton>
      <DropdownPortal
        refs={refs}
        floatingStyles={floatingStyles}
        dismiss={dismiss}
      >
        <DropdownContext.Provider value={closeDropdown}>
          <DropdownNavigationList
            aria-labelledby={id}
            isOpened={isDropdownOpened}
          >
            {links.map((module, idx) => (
              <NavigationLink item={module} key={idx} />
            ))}
          </DropdownNavigationList>
        </DropdownContext.Provider>
      </DropdownPortal>
    </li>
  );
}
