import styled from 'styled-components';

export interface ContextBoxProps {
  noShadow?: boolean;
}

export const ContentBox = styled.div<ContextBoxProps>`
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #ffffff;
  box-shadow: ${props =>
    `0 6px 8px 3px  rgba(0, 0, 0,  ${props.noShadow ? 0 : 0.06})`};
  padding: 12px;
  max-width: 100%;

  @media print {
    border-color: #4a4a4a;
  }

  @media (min-width: 768px) {
    padding: 26px;
  }
`;

export const ContentBoxHead = styled.h2`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  margin: 0;
  margin-bottom: 24px;
`;

export const FixedContentBox = styled(ContentBox)`
  display: flex;
  max-height: calc(100vh - 50px - var(--vh-offset, 0px));
  overflow: hidden;

  padding-right: 6px;

  & > div {
    padding-right: 6px;
  }

  @media (min-width: 768px) {
    padding-right: 13px;

    & > div {
      padding-right: 13px;
    }
  }
`;
