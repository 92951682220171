import {
  GroupStore,
  RequestStore,
  RootStore,
  User,
  GoogleCalendarService as iGoogleCalendarService,
} from '@yarmill/types';

import { getUserName } from '@yarmill/utils';
import { subscribeToCalendar } from '../api/subscribe-to-calendar';

export class GoogleCalendarService implements iGoogleCalendarService {
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  subscribeToUserCalendar(user: User): RequestStore<void> {
    const request = this.rootStore.requestsStore.createRequest(cancelToken =>
      subscribeToCalendar(
        {
          userId: user.UserId,
          userGroupId: undefined,
          calendarName: getUserName(user),
        },
        cancelToken
      )
    );

    return request;
  }

  subscribeToGroupCalendar(group: GroupStore): RequestStore<void> {
    const request = this.rootStore.requestsStore.createRequest(cancelToken =>
      subscribeToCalendar(
        {
          userId: undefined,
          userGroupId: group.id,
          calendarName: group.name,
        },
        cancelToken
      )
    );

    return request;
  }
}
