import {
  CloseButton,
  FullScreenLayerCloseButtonWrapper,
  StyledFullScreenLayerContent,
} from '@yarmill/components';
import { Layer } from '@yarmill/types';
import { Fragment } from 'react';
import { CopyLayerContent } from './copy-layer-content';

export interface CopyLayerProps {
  layer: Layer;
}

export function Copy(props: CopyLayerProps): JSX.Element {
  const { layer } = props;
  return (
    <Fragment>
      <StyledFullScreenLayerContent>
        <CopyLayerContent layer={layer} />
      </StyledFullScreenLayerContent>
      <FullScreenLayerCloseButtonWrapper>
        <CloseButton onClick={layer.close} />
      </FullScreenLayerCloseButtonWrapper>
    </Fragment>
  );
}
