import { AxisBottom, AxisLeft } from '@visx/axis';

import { useXYChartContext } from './xy-chart-context';
import { AxisValue, XAxisConfig, YAxisConfig } from '../reporting/types';
import { getAxisXTickLabelFormatter } from './utils/get-axis-x-tick-label-formatter';
import { getAxisYTickLabelFormatter } from './utils/get-axis-y-tick-label-formatter';
import { getAxisPosition } from '../reporting/utils/get-axis-position';

interface AxesProps {
  readonly xAxisConfig?: XAxisConfig<AxisValue>;
  readonly yAxisConfig?: YAxisConfig<AxisValue>;
  readonly xAxisTicks?: AxisValue[];
  readonly yAxisTicks?: AxisValue[];
}

export function Axes({
  xAxisConfig,
  yAxisConfig,
  xAxisTicks,
  yAxisTicks,
}: AxesProps) {
  const { xScale, yScale, chartRect } = useXYChartContext();
  const bottomAxisPosition =
    'bandwidth' in yScale ? 0 : getAxisPosition(yScale.domain() as number[]);
  const leftAxisPosition =
    'bandwidth' in xScale ? 0 : getAxisPosition(xScale.domain() as number[]);

  const hideBottomAxisLine =
    yAxisConfig?.hideAxisLine === undefined
      ? true
      : yAxisConfig?.hideAxisLine
        ? true
        : bottomAxisPosition !== 0;

  const hideLeftAxisLine =
    xAxisConfig?.hideAxisLine === undefined
      ? true
      : xAxisConfig?.hideAxisLine
        ? true
        : leftAxisPosition !== 0;

  return (
    <>
      {xAxisConfig?.showTickLabels && (
        <AxisBottom
          hideAxisLine={hideBottomAxisLine}
          top={chartRect.bottom}
          scale={xScale}
          hideTicks
          tickLabelProps={getAxisXTickLabelFormatter(xAxisConfig.tickAngle)}
          tickValues={xAxisTicks}
          tickFormat={xAxisConfig.formatTick as any}
          numTicks={xAxisConfig.numberOfTicks}
        />
      )}
      {yAxisConfig?.showTickLabels && (
        <AxisLeft
          left={chartRect.left}
          hideAxisLine={hideLeftAxisLine}
          scale={yScale}
          hideTicks
          tickLabelProps={getAxisYTickLabelFormatter(
            yAxisConfig.tickAngle || 360
          )}
          tickValues={yAxisTicks}
          tickFormat={yAxisConfig.formatTick as any}
          numTicks={yAxisConfig.numberOfTicks}
        />
      )}
    </>
  );
}
