import { ExportFormat } from '@yarmill/types';
import { ReactElement, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { ExternalIcon } from '../external-icon';
import { Icon } from '../icon';
import { PopoverItem } from '../popover';
import {
  PopoverContentWrapper,
  PopoverIconWrapper,
} from '../popover/components';

export interface ExportDropdownItemProps {
  label?: string;
  description?: string;
  format: ExportFormat;
  doExport(): Promise<void>;
  closeDropdown(): void;
}

function getIconForFormat(format: ExportFormat): ReactElement {
  switch (format) {
    case 'pdf':
      return <ExternalIcon name="Printer" />;
    case 'xlsx':
      return <ExternalIcon name="TableDown" />;
  }
}

export function ExportDropdownItem(
  props: ExportDropdownItemProps
): JSX.Element {
  const { format, doExport, closeDropdown, label, description } = props;
  const handleClick = useCallback(async () => {
    closeDropdown();
    await doExport();
  }, [doExport, closeDropdown]);

  return (
    <PopoverItem onClick={handleClick} dataTest={`export-to-${format}-button`}>
      <PopoverIconWrapper>
        <Icon>{getIconForFormat(format)}</Icon>
      </PopoverIconWrapper>
      <PopoverContentWrapper>
        <strong>
          {label || <FormattedMessage id={`export.title.${format}`} />}
        </strong>
        <div>
          {description || (
            <FormattedMessage id={`export.description.${format}`} />
          )}
        </div>
      </PopoverContentWrapper>
    </PopoverItem>
  );
}
