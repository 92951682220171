import { FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  AsyncButton,
  ErrorBox,
  FieldSet,
  FormControlVariant,
  FormattedHTMLMessage,
  Link,
  LoginFormButtonsWrapper,
  Text,
  TextAlignment,
  TextInput,
  TextSize,
} from '@yarmill/components';
import { AsyncStatus, ServerError } from '@yarmill/types';
import { isValidEmail } from '@yarmill/utils';
import { useAuthStore } from './hooks';

export function ForgottenPasswordForm(): JSX.Element {
  const authStore = useAuthStore();
  const [email, setValue] = useState('');
  const [asyncState, setAsyncState] = useState<AsyncStatus>(AsyncStatus.idle);
  const [lastSubmittedValue, setLastSubmittedValue] = useState<
    string | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<ServerError | undefined>();
  const intl = useIntl();
  const submit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setErrorMessage({
        Field: 'email',
        Id: 'error.auth.forgotPassword.invalidEmail',
        Values: {},
      });
      setAsyncState(AsyncStatus.rejected);
    } else if (email) {
      try {
        await authStore.requestForgottenPassword(email);
        setAsyncState(AsyncStatus.resolved);
      } catch (e: any) {
        setAsyncState(AsyncStatus.rejected);
        setErrorMessage(e?.response?.data);
        setLastSubmittedValue(email);
      }
    }
  };

  if (asyncState === AsyncStatus.resolved) {
    return (
      <Text size={TextSize.s14} textAlign={TextAlignment.center}>
        <FormattedMessage id="auth.forgottenPasswordSuccess" />
      </Text>
    );
  }

  return (
    <form id="login-form" onSubmit={submit}>
      <FieldSet disabled={asyncState === AsyncStatus.pending}>
        {errorMessage && (
          <ErrorBox>
            <FormattedHTMLMessage
              id={errorMessage.Id}
              values={errorMessage.Values}
            />
          </ErrorBox>
        )}

        <TextInput
          variant={FormControlVariant.big}
          id="email"
          autoFocus
          type="email"
          name="email"
          onChange={e => setValue(e.target.value)}
          placeholder={intl.formatMessage({ id: 'auth.email' })}
          required
          data-cy="email"
          noLabel
        />

        <LoginFormButtonsWrapper>
          <Link to="/login" data-cy="back-btn">
            <Text size={TextSize.s14} medium inheritColor>
              <FormattedMessage id="auth.forgottenPasswordBack" />
            </Text>
          </Link>

          <AsyncButton
            dataTest="submit"
            type="submit"
            disabled={Boolean(errorMessage) && lastSubmittedValue === email}
            loading={asyncState === AsyncStatus.pending}
            variant={FormControlVariant.big}
          >
            <FormattedMessage id="auth.submitForgottenPassword" />
          </AsyncButton>
        </LoginFormButtonsWrapper>
      </FieldSet>
    </form>
  );
}
