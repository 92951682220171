import { Icon, IconSize, Tippy, useTheme } from '@yarmill/components';
import { Sidemenu } from '@yarmill/icons-2';
import { useSidebarStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { Button, ButtonAppearance } from './button';

export const SideMenuToggle = observer(function SideMenuToggle(): JSX.Element {
  const sidebarStore = useSidebarStore();
  const intl = useIntl();
  const theme = useTheme();

  return (
    <Tippy
      tooltipContent={
        sidebarStore.isVisible
          ? 'sidebar.button.hideSidebar'
          : 'sidebar.button.showSidebar'
      }
      noWrapper
      touch={false}
    >
      <Button
        $appearance={ButtonAppearance.Secondary}
        onClick={sidebarStore.toggle}
        $appearanceStyle={theme.dark ? 'white' : 'neutral'}
        $iconOnly
        aria-label={intl.formatMessage({
          id: sidebarStore.isVisible
            ? 'sidebar.button.hideSidebar'
            : 'sidebar.button.showSidebar',
        })}
      >
        <Icon size={IconSize.s24}>
          <Sidemenu />
        </Icon>
      </Button>
    </Tippy>
  );
});
