import { ActivitiesTableContainer } from '@yarmill/components';
import { PureComponent } from 'react';
import ContentLoader from 'react-content-loader';

export class WeekViewLoadingPlaceholder extends PureComponent {
  public render(): JSX.Element {
    return (
      <ActivitiesTableContainer>
        <ContentLoader
          uniqueKey="activityLoader"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          viewBox="0 0 563 628"
          height="100%"
        >
          <rect x="0" y="48" rx="8" ry="8" width="43" height="580" />
          <rect x="50" y="0" rx="8" ry="8" width="493" height="35" />
          <rect x="50" y="48" rx="8" ry="8" width="493" height="580" />
        </ContentLoader>
      </ActivitiesTableContainer>
    );
  }
}
