import { Line } from '@visx/shape';
import { useContext } from 'react';
import { useTooltipContext } from '../hooks/use-tooltip';
import { useXYChartContext } from '../xy-chart-context';
import { SharedTooltipContext } from './shared-tooltip-context';

interface ContinuousTooltipProps {
  readonly mainAxisKey?: string;
  readonly syncTooltip?: boolean;
}
export function ContinuousTooltip({
  mainAxisKey,
  syncTooltip,
}: ContinuousTooltipProps) {
  const { chartRect, xScale } = useXYChartContext();
  const sharedTooltip = useContext(SharedTooltipContext);
  let { tooltipLeft, tooltipOpen } = useTooltipContext();

  if (
    syncTooltip &&
    sharedTooltip &&
    !tooltipOpen &&
    mainAxisKey === sharedTooltip.axisKey &&
    sharedTooltip.axisValue &&
    !('bandwidth' in xScale)
  ) {
    tooltipLeft = xScale(sharedTooltip.axisValue as number);
    tooltipOpen = true;
  }

  return (
    <>
      {tooltipOpen && (
        <Line
          from={{ x: tooltipLeft, y: chartRect.top }}
          to={{ x: tooltipLeft, y: chartRect.bottom }}
          stroke="#c2c2c2"
          strokeWidth={2}
          pointerEvents="none"
          strokeDasharray="2,2"
        />
      )}
    </>
  );
}
