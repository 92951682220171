import { Group } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export interface RemoveUserFromGroupRequestParams {
  groupId: number;
  userId: number;
}

export function removeUserFromGroup(
  params: RemoveUserFromGroupRequestParams
): AxiosPromise<Group> {
  return axios.delete(`api/UserGroupUsers`, {
    params,
  });
}
