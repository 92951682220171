import { DataItem } from '../../reporting';
import { useScale } from '../hooks/use-scale';
import { BarChart, DataLabelDefinition } from '../types';
import { getBarDimensions } from './get-bar-dimensions';
import { getBarLabelAttributes } from './get-bar-label-attributes';
import { getBarLabelY } from './get-bar-label-y';

export function getBarLabel(
  item: DataItem,
  xScale: ReturnType<typeof useScale>,
  yScale: ReturnType<typeof useScale>,
  chartConfig: BarChart,
  priority: number
): DataLabelDefinition | undefined {
  const dimensions = getBarDimensions(chartConfig, item, xScale, yScale);
  const showLabel = chartConfig.getShowLabels(item);
  if (!dimensions || !showLabel || !('bandwidth' in xScale)) {
    return undefined;
  }

  const { x, height: barHeight, width: barWidth } = dimensions;
  const yValue = chartConfig.getYValue(item);
  const formattedValue =
    (yValue !== null
      ? (chartConfig.formatLabelValue?.(yValue) ?? yValue)
      : null) ?? '';
  const color = chartConfig.getLabelColor(item);
  const position = chartConfig.labelPosition;
  const y = getBarLabelY(yScale, yValue);
  const maxWidth = position !== 'outside' ? barWidth : xScale.step();
  const customAngle = chartConfig.getLabelAngle(item);

  const attributes = getBarLabelAttributes({
    formattedValue,
    customAngle,
    maxWidth,
    barHeight,
    yValue,
    position,
  });

  if (!attributes) {
    return undefined;
  }

  const { labelOffset, maxWidth: adjustedMaxWidth, angle } = attributes;

  return {
    x: x + barWidth / 2,
    y: y - labelOffset,
    originalValue: yValue,
    text: String(formattedValue),
    angle,
    color,
    priority,
    maxWidth: adjustedMaxWidth,
    maxHeight: xScale.step(),
  };
}
