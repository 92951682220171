import './setup-moment';
import { GlobalIntlProvider } from '@yarmill/components';
import { Locale } from '@yarmill/types';
import { useIntlStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, useCallback } from 'react';
import { IntlConfig, IntlProvider as ReactIntlProvider } from 'react-intl';
import { setMomentLocale } from './utils';

interface IntlError {
  readonly code: string;
  readonly descriptor?: {
    readonly id: string;
  };
  readonly message?: string;
}

function getLocaleForLanguage(language: Locale): string {
  switch (language) {
    case 'cs':
      return 'cs-CZ';
    case 'en':
      return 'en-GB';
    case 'sk':
      return 'sk-SK';
    case 'de':
      return 'de-DE';
    case 'it':
      return 'it-IT';
    case 'fr':
      return 'fr-FR';
  }
}
export const IntlProvider = observer(function IntlProvider(
  props: PropsWithChildren
): JSX.Element {
  const intlStore = useIntlStore();

  setMomentLocale(intlStore.locale);

  const reportMissingTranslations = useCallback(
    (err: IntlError) => {
      if (
        intlStore.shouldReportMissingTranslations &&
        (err.code.includes('MISSING_TRANSLATION') ||
          (err.code.includes('FORMAT_ERROR') &&
            err.message?.includes('intl string context variable')))
      ) {
        if ('descriptor' in err && err.descriptor?.id) {
          intlStore.reportMissingTranslation(
            err.descriptor.id,
            window.location.href,
            err.code
          );
        }
      }
    },
    [intlStore]
  ) as IntlConfig['onError'];

  return (
    <ReactIntlProvider
      locale={getLocaleForLanguage(intlStore.locale)}
      messages={intlStore.messages}
      onError={reportMissingTranslations}
    >
      <GlobalIntlProvider />
      {props.children}
    </ReactIntlProvider>
  );
});
