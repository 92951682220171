import {
  LayerWrapper,
  ShimBackgroundType,
  ShimLayer,
} from '@yarmill/components';
import { Layer } from '@yarmill/types';
import { useLayerManagerService } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { AlertLayer } from './alert-layer';
import { ContextLayer } from './context-layer';
import { FullScreenLayer } from './full-screen-layer';
import { InteractionLayer } from './interaction-layer';

function renderLayer(
  layer: Layer,
  index: number,
  active: boolean
): ReactElement {
  switch (layer.type) {
    case 'full-screen':
      return (
        <FullScreenLayer
          layer={layer}
          key={`${index}-${layer.type}`}
          active={active}
        />
      );
    case 'alert':
      return (
        <AlertLayer
          layer={layer}
          key={`${index}-${layer.type}`}
          active={active}
        />
      );
    case 'context':
      return (
        <ContextLayer
          layer={layer}
          key={`${index}-${layer.type}`}
          active={active}
        />
      );
    case 'interaction':
      return (
        <InteractionLayer
          layer={layer}
          key={`${index}-${layer.type}`}
          active={active}
        />
      );
  }
}

function shouldShowShim(
  layers: Layer[],
  _layer: Layer,
  index: number
): boolean {
  return (
    // We are top layer
    index === layers.length - 1 ||
    // Or second from top and layer above is context layer
    (index === layers.length - 2 &&
      layers[layers.length - 1].type === 'context')
  );
}

export const LayerManager = observer(
  function LayerManager(): JSX.Element | null {
    const service = useLayerManagerService();
    const layers = service.layers;

    return (
      <LayerWrapper>
        {layers.map((layer, index) => [
          shouldShowShim(layers, layer, index) && (
            <ShimLayer
              key="shim"
              backgroundType={
                layer.showShim
                  ? ShimBackgroundType.OPAQUE
                  : ShimBackgroundType.TRANSPARENT
              }
              onClick={
                layer.options.closeOnShimClick ? () => layer.close() : undefined
              }
            />
          ),
          renderLayer(layer, index, index === layers.length - 1),
        ])}
      </LayerWrapper>
    );
  }
);
