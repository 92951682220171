export function isValidFileName(fileName: string): boolean {
  // Define a regular expression pattern to match valid file names
  /**
   *
   */
  const pattern =
    /^[+@$~^*{}°=%.;§()[\]!, a-zA-Z0-9_\-à-ýčďěňřšťůžÁÉÍÓÚÝáéíóúý]+\.[a-zA-Z0-9]+$/i;

  // Use the test() method to check if the fileName matches the pattern
  return pattern.test(fileName);
}
