import { DATE_FORMAT, ROUTE_DATE_FORMAT } from '@yarmill/const';
import { RootStore, Season } from '@yarmill/types';
import {
  getSeasonCycles,
  getSeasonWeeks,
  getWeekEnd,
  getWeekStart,
  getWeekStartString,
  groupBy,
} from '@yarmill/utils';
import {
  IObservableArray,
  ObservableMap,
  action,
  computed,
  makeObservable,
  observable,
} from 'mobx';
import moment from 'moment';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { DiaryDataUniqueId, DiaryEventData } from '../../diary/types';
import { TextAreaBasicData } from '../../training-day/types';
import { getCyclesEndDate, getSeasonStart } from '../utils';
import { GoalStore } from './goal-store';
import { BASE_GOAL_ATTRIBUTE } from './goals-store';
import { SeasonGoalStore } from './season-goal-store';

export class SeasonGoalsStore {
  private readonly rootStore: RootStore;
  private readonly diaryStore: DiaryStore;
  private readonly loadData: (
    start: moment.Moment,
    end: moment.Moment
  ) => Promise<void>;
  private readonly dataId: DiaryDataUniqueId;

  @observable
  private _cycleGoals: GoalStore[] = [];
  @observable
  private _cycleGoalsMap: ObservableMap<string, GoalStore> =
    new ObservableMap();
  @observable
  private _weekGoals: GoalStore[] = [];
  @observable
  private _weekGoalsMap: ObservableMap<string, GoalStore> = new ObservableMap();
  @observable
  private _dailyGoals: GoalStore[] = [];
  @observable
  private _dailyGoalsMap: ObservableMap<string, GoalStore> =
    new ObservableMap();

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    loadData: (start: moment.Moment, end: moment.Moment) => Promise<void>,
    dataId: DiaryDataUniqueId
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.diaryStore = diaryStore;
    this.dataId = dataId;
    this.loadData = loadData;
    void this.initGoals();
  }

  public get cycleGoals(): GoalStore[] {
    return this._cycleGoals;
  }

  public get weekGoals(): GoalStore[] {
    return this._weekGoals;
  }

  public get dailyGoals(): GoalStore[] {
    return this._dailyGoals;
  }

  @action
  public setGoalsData(
    data: TextAreaBasicData[],
    events: DiaryEventData[]
  ): void {
    this._dailyGoals.forEach(goal => goal.clear());
    this._weekGoals.forEach(goal => goal.clear());
    this._cycleGoals.forEach(goal => goal.clear());
    this.setData(data, 'data');
    this.setEvents(events);
  }

  @action
  public setPlaceholders(data: TextAreaBasicData[]): void {
    this.setData(data, 'placeholders');
  }

  @computed
  public get seasonStartDate(): string | null {
    return this.getSeasonStartDate()?.format(ROUTE_DATE_FORMAT) || null;
  }

  private async initGoals(): Promise<void> {
    this.clear();

    const attributes = this.diaryStore.goalsAttributes;
    const seasonStartDate = this.getSeasonStartDate();

    if (!attributes || !seasonStartDate) {
      return;
    }

    const start = getWeekStart(seasonStartDate.clone());
    const end = getWeekEnd(getCyclesEndDate(start));
    const tempSeason = {
      StartDate: start.format(DATE_FORMAT),
      EndDate: end.format(DATE_FORMAT),
    } as Season;

    getSeasonCycles(tempSeason).forEach(cycle => {
      const store = new SeasonGoalStore(
        this.rootStore,
        this.diaryStore,
        0,
        [
          {
            ...BASE_GOAL_ATTRIBUTE,
            AttributeItemId: attributes.cycle,
          },
        ],
        cycle,
        this.dataId
      );
      this._cycleGoalsMap.set(cycle, store);
      this._cycleGoals.push(store);
    });

    getSeasonWeeks(tempSeason).forEach(week => {
      const store = new SeasonGoalStore(
        this.rootStore,
        this.diaryStore,
        1,
        [
          {
            ...BASE_GOAL_ATTRIBUTE,
            AttributeItemId: attributes.week,
          },
        ],
        week,
        this.dataId
      );
      if (getWeekStartString(this.dataId.week) === store.currentDate) {
        store.attribute.setInitialFocus(true);
      }

      this._weekGoalsMap.set(week, store);
      this._weekGoals.push(store);
    });

    const current = start.clone();

    while (current.diff(end) <= 0) {
      const date = current.format(DATE_FORMAT);
      const store = new SeasonGoalStore(
        this.rootStore,
        this.diaryStore,
        1,
        [
          {
            ...BASE_GOAL_ATTRIBUTE,
            AttributeItemId: attributes.day,
          },
        ],
        date,
        this.dataId
      );
      this._dailyGoalsMap.set(date, store);
      this._dailyGoals.push(store);
      current.add(1, 'day');
    }
    await this.loadData(start, end);
  }

  @action
  private setData(
    data: TextAreaBasicData[],
    type: 'data' | 'placeholders'
  ): void {
    const attributes =
      type === 'placeholders'
        ? this.rootStore.configStore.goalsAttributes?.reality!
        : this.diaryStore.goalsAttributes;

    if (!attributes) {
      return;
    }

    data.forEach(item => {
      let goalStore: GoalStore | undefined;
      switch (item.AttributeItemId) {
        case attributes.cycle: {
          goalStore = this._cycleGoalsMap.get(item.Date);
          break;
        }
        case attributes.week: {
          goalStore = this._weekGoalsMap.get(item.Date);
          break;
        }
        case attributes.day: {
          goalStore = this._dailyGoalsMap.get(item.Date);
          break;
        }
      }

      if (type === 'placeholders') {
        goalStore?.attribute.setPlaceholderText(item);
      } else {
        goalStore?.attribute.setApiValue(item);
      }
    });
  }

  @action
  private setEvents(events: DiaryEventData[]): void {
    const eventsByDay = groupBy(events, 'EventDay');

    Array.from(eventsByDay.entries()).forEach(([day, dayEvents]) => {
      const goalStore = this._dailyGoalsMap.get(day);
      goalStore?.setEvents(dayEvents);
    });
  }

  @action
  private clear(): void {
    (this._cycleGoals as IObservableArray).clear();
    (this._weekGoals as IObservableArray).clear();
    (this._dailyGoals as IObservableArray).clear();
    this._cycleGoalsMap.clear();
    this._weekGoalsMap.clear();
    this._dailyGoalsMap.clear();
  }

  private getSeasonStartDate(): moment.Moment | undefined {
    const athleteId = this.dataId.athleteId;
    const groupId = this.dataId.groupId;
    const season = this.rootStore.seasonsStore.getSeasonByWeek(
      this.dataId.week
    );

    const athlete = athleteId
      ? this.rootStore.usersStore.getUserById(athleteId)
      : undefined;

    const group = groupId
      ? this.rootStore.groupsStore.getGroupById(groupId)
      : undefined;

    if (!season) {
      return;
    }

    return getSeasonStart(season, group, athlete);
  }

  public enableApiSynchronization(): void {
    this._cycleGoals.forEach(goal => goal.enableApiSynchronization());
    this._weekGoals.forEach(goal => goal.enableApiSynchronization());
    this._dailyGoals.forEach(goal => goal.enableApiSynchronization());
  }
}
