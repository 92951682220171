import { WhiteSpace } from '@yarmill/components';
import { apiTarget, instance, useConfig } from '@yarmill/utils';
import { useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import {
  PrimarySidebarHeaderLogo,
  PrimarySidebarHeaderLogoImage,
  PrimarySidebarHeaderLogoInstanceName,
} from '../primary-sidebar';

const defaultLogo = `https://${instance}.api.yarmill.com/Assets/fe/instance/logo/logo.png`;

export const Logo = observer(function Logo(): JSX.Element {
  const logo = useConfig('logo');
  const smallLogo = useConfig('logoSmall');
  const rootStore = useRootStore();
  const logoUrl = smallLogo
    ? `${apiTarget}/${smallLogo}`
    : logo
      ? `${apiTarget}/${logo}`
      : defaultLogo;
  const instanceName = rootStore.configStore.appName;

  return (
    <PrimarySidebarHeaderLogo>
      <PrimarySidebarHeaderLogoImage src={logoUrl} />
      <PrimarySidebarHeaderLogoInstanceName
        ellipsis
        whiteSpace={WhiteSpace.noWrap}
      >
        {instanceName}
      </PrimarySidebarHeaderLogoInstanceName>
    </PrimarySidebarHeaderLogo>
  );
});
