import styled from 'styled-components';
import { Text } from '@yarmill/components';

export const StyledInviteUserRowLabels = styled.div`
  display: none;
  @media (min-width: 576px) {
    display: grid;
    grid-template-columns: 4fr 3fr 3fr 2fr 1fr;
    position: relative;
  }
`;

export const StyledHeadline = styled(Text)`
  display: flex;
  align-items: center;
  column-gap: 16px;
  text-align: left;
`;

export const StyledAddNewInvitationWrapper = styled.div`
  flex: 0 0 100%;
  max-width: 100%;

  button {
    min-height: 26px;
    color: #4a90e2;
    font-size: 16px;
    padding: 0;

    &:hover {
      color: #396fad;
      text-decoration: none;
    }
  }
`;

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 0 50px 50px;
  position: relative;

  @media (min-width: 576px) {
    padding: 50px calc(10% + 50px);
  }
`;

export const StyledGroupSelectWrapper = styled.div`
  margin: 20px 0 10px;
`;

export const StyledSelectGroupContainer = styled.div`
  display: grid;
  column-gap: 10px;
  @media (min-width: 576px) {
    grid-template-columns: 10fr 1fr;
  }

  @media (min-width: 766px) {
    grid-template-columns: 12fr 1fr;
  }
`;

export const StyledInviteButtonWrapper = styled.div`
  margin: 30px 0 20px;

  text-align: right;
`;

export const StyledInviteUserRow = styled.div`
  display: grid;
  grid-row-gap: 10px;
  @media (min-width: 576px) {
    position: relative;
    grid-template-columns: 4fr 3fr 3fr 2fr 1fr;
    column-gap: 10px;
  }
`;

export const StyledDeleteButton = styled.div`
  svg {
    color: #d0021b;
  }
  justify-content: start;
  @media (min-width: 576px) {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      color: #4a90e2;
      margin-bottom: 18px;
    }
  }
`;

export const StyledLabel = styled.label`
  display: block;

  @media (min-width: 576px) {
    display: none;
  }
`;
