import { SeasonHeaderCellContent } from '@yarmill/components';
import { useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useDiaryStore } from './diary-store-context';
import { HeaderCellProps } from './week-header-cell';

function InternalSeasonHeaderCell(props: HeaderCellProps): JSX.Element | null {
  const { columnIndex } = props;
  const rootStore = useRootStore();
  const diaryStore = useDiaryStore();
  const season = rootStore.seasonsStore.getSeasonByDate(diaryStore.week);
  const seasonViewType = rootStore.configStore.seasonView;

  if (!season) {
    return null;
  }

  return (
    <SeasonHeaderCellContent
      columnIndex={columnIndex}
      season={season}
      seasonViewType={seasonViewType}
    />
  );
}

export const SeasonHeaderCell = observer(InternalSeasonHeaderCell);
