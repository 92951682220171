import { useCurrentUserStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { Route } from 'react-router-dom';
import { useIsLoggedIn } from '../auth/hooks';
import { createPermissionScopeFilter } from '../permissions/utils';
import { PrivateRoute } from './private-route';
import { routes } from './routes';

export const RoutesList = observer(function RoutesList(): ReactElement {
  const currentUser = useCurrentUserStore();
  const isLoggedIn = useIsLoggedIn();

  const allowedRoutes = isLoggedIn
    ? routes.filter(createPermissionScopeFilter(currentUser))
    : routes;

  return (
    <>
      {allowedRoutes.map(route =>
        route.public ? (
          <Route key={route.path} exact={route.exact} path={route.path}>
            <route.component />
          </Route>
        ) : (
          <PrivateRoute
            key={route.path}
            path={route.path}
            component={route.component}
            exact={route.exact}
          />
        )
      )}
    </>
  );
});
