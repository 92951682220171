import { Permission } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';

export function getCurrentUserRightsToUsers(
  userId: number
): AxiosPromise<Permission[]> {
  return axios.get(`api/UserGroupUserPermissions/AllUsers`, {
    params: {
      userId,
    },
  });
}
