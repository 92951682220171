import { FormattedMessage } from 'react-intl';
import { ForgottenPasswordForm } from './forgotten-password-form';
import {
  LoginPageLayout,
  SimplePageLayout,
  Text,
  TextAlignment,
  TextSize,
  TextTag,
} from '@yarmill/components';

export const ForgottenPassword = (): JSX.Element => (
  <SimplePageLayout>
    <LoginPageLayout>
      <Text
        tag={TextTag.h1}
        size={TextSize.s24}
        textAlign={TextAlignment.center}
      >
        <FormattedMessage id="auth.forgottenPassword" />
      </Text>
      <ForgottenPasswordForm />
    </LoginPageLayout>
  </SimplePageLayout>
);
