import {
  ActivityMask,
  AsyncStatus,
  AttendancePhase,
  DataService,
  EvidenceModuleConfiguration,
  ExternalApp,
  ExternalLink,
  ExternalService,
  GlobalConfigOption,
  GoalsAttributes,
  HeaderNavigationConfigItem,
  Locale,
  OkrExportTemplate,
  RequestStore,
  SeasonViewType,
  UserRole,
  ConfigStore as iConfigStore,
} from '@yarmill/types';
import { getDefaultAvailableLanguages } from '@yarmill/utils';
import {
  IObservableArray,
  ObservableMap,
  action,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { RootStore } from '../app/mobx/root-store';
import { InitialConfigError } from '../error/initial-config-error';
import { AdditionalRegistrationRequirement } from '../register/types';
import { getGlobalConfig } from './api/get-global-config';

const MODULE_SELECTOR = 'fe.module.';
const MODULE_REGEX = new RegExp(MODULE_SELECTOR);

export class ConfigStore implements iConfigStore {
  private readonly rootStore: RootStore;
  private request: RequestStore<GlobalConfigOption[]> | null = null;

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  @observable
  appName: string | null = null;
  @observable
  logo: string | null = null;
  @observable
  logoSmall: string | null = null;
  @observable
  seasonView: SeasonViewType = 'month';
  @observable
  weekViewEnabled: boolean = true;
  @observable
  goalsViewEnabled: boolean = false;
  @observable
  seasonEditable: boolean = true;
  @observable
  adminEmail: string = '';
  @observable
  adminName: string = '';
  @observable
  copyPlanToReality: boolean = false;
  @observable
  copyPlanToRealityValidDays: number = 0;
  @observable
  defaultLanguage: Locale = 'cs';
  @observable
  attendanceCopyPlanToReality: boolean = false;
  @observable
  availableExternalServices: ExternalService[] = [];
  @observable
  instructions: boolean = false;
  @observable
  tipsAndTricks: boolean = false;
  @observable
  tipsAndTricksUrl: string = '';
  @observable
  showGoalPlaceholders: boolean = false;
  @observable
  goalsAttributes: {
    reality: GoalsAttributes;
    plan: GoalsAttributes;
  } | null = null;
  @observable
  avatars: ObservableMap<string, string> = new ObservableMap();
  @observable
  diaryBackfill: number = -1;
  @observable
  diaryBackfillScope: UserRole[] = [];
  @observable
  idleTimeout: number = 8; // hours
  @observable
  attendancePhases: AttendancePhase[] = [];
  @observable
  fileUploadUrl: string = 'https://filestorage.ms.yarmill.com/api/upload';
  @observable
  evidenceModuleConfigurations: EvidenceModuleConfiguration[] = [];
  @observable
  reportingDefUrl: string = '/api/reporting/def-v2';
  @observable
  reportingDataUrl: string = '/api/reporting';
  @observable
  activityMasks: ActivityMask[] = [];
  @observable
  seasonGoalsViewEnabled: boolean = false;
  @observable
  headerNavigationConfig: HeaderNavigationConfigItem[] | null = null;
  @observable
  yollandaUrl: string | null = null;
  @observable
  availableLanguages: Locale[] = [];
  @observable
  additionalRegistrationRequirements: AdditionalRegistrationRequirement[] = [];
  @observable
  yollandaCopilotEnabled: boolean = false;

  @observable
  dataServices: DataService[] = [];

  @observable
  okrExportTemplates: OkrExportTemplate[] = [];

  @observable
  okrInstructionsUrl: string | null = null;

  @observable
  externalLinks: ExternalLink[] = [];

  @observable
  externalApps: ExternalApp[] = [];

  @observable
  modules = new Map();

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  async loadConfig(): Promise<void> {
    this._status = AsyncStatus.pending;
    this.request = this.rootStore.requestsStore.createRequest(() =>
      getGlobalConfig()
    );
    const response = await this.request.getResponse();
    if (response) {
      await runInAction(async () => {
        this.setConfig(response);

        if (this.modules.get('announcement')?.length) {
          const intlPromise = new Promise<void>(resolve => {
            when(
              () => this.rootStore.intlStore.version === 'api',
              () => {
                this._status = AsyncStatus.resolved;
                resolve();
              }
            );
          });
          await intlPromise;
        } else {
          this._status = AsyncStatus.resolved;
        }
      });
    } else {
      throw new InitialConfigError(
        'Unable to load global config',
        this.request.error
      );
    }
  }

  @action
  setConfig(options: GlobalConfigOption[]): void {
    options.forEach(option => {
      switch (option.Code) {
        case 'fe.instance.name':
          this.appName = option.Value;
          break;
        case 'fe.instance.logo':
          this.logo = option.Value;
          break;
        case 'fe.instance.logo.small':
          this.logoSmall = option.Value;
          break;
        case 'ytd.seasonView':
          this.seasonView = option.Value;
          break;
        case 'ytd.plan.weekViewEnabled':
          this.weekViewEnabled = option.Value;
          break;
        case 'ytd.plan.goalsViewEnabled':
          this.goalsViewEnabled = option.Value;
          break;
        case 'ytd.reality.seasonEditable':
          this.seasonEditable = option.Value;
          break;
        case 'ytd.instance.adminEmail':
          this.adminEmail = option.Value;
          break;
        case 'ytd.instance.adminName':
          this.adminName = option.Value;
          break;
        case 'ytd.module.copyPlanToReality':
          this.copyPlanToReality = option.Value;
          break;
        case 'ytd.module.copyPlanToReality.daysValid':
          this.copyPlanToRealityValidDays = option.Value;
          break;
        case 'fe.defaultLanguage':
          this.defaultLanguage = option.Value;
          break;
        case 'ytd.module.attendance.copyPlanToReality':
          this.attendanceCopyPlanToReality = option.Value;
          break;
        case 'ytd.module.externalServices.enabledV2':
          this.availableExternalServices = option.Value;
          break;
        case 'ytd.goals.attributeIds':
          this.goalsAttributes = option.Value;
          break;
        case 'ytd.goals.showGoalPlaceholders':
          this.showGoalPlaceholders = option.Value;
          break;
        case 'fe.avatars.list': {
          const obj = option.Value;
          this.avatars.replace(obj);
          break;
        }
        case 'ytd.diary.backfill':
          this.diaryBackfill = option.Value;
          break;
        case 'ytd.diary.backfillScope':
          this.diaryBackfillScope = option.Value;
          break;
        case 'fe.general.idleUserTimeout':
          this.idleTimeout = option.Value;
          break;
        case 'ytd.module.attendance.phases':
          this.attendancePhases = option.Value;
          break;
        case 'ytd.services.filestorage.upload.url':
          this.fileUploadUrl = option.Value;
          break;
        case 'ytd.evidence.moduleDefinitions':
          this.evidenceModuleConfigurations = option.Value;
          break;
        case 'ytd.reporting.definitionUrl':
          this.reportingDefUrl = option.Value;
          break;
        case 'ytd.reporting.dataUrl':
          this.reportingDataUrl = option.Value;
          break;
        case 'ytd.mask.availableMasks':
          this.activityMasks = option.Value;
          break;
        case 'ytd.plan.seasonGoalsViewEnabled':
          this.seasonGoalsViewEnabled = option.Value;
          break;
        case 'ytd.header.navigationConfig':
          this.headerNavigationConfig = option.Value;
          break;
        case 'ytd.services.yollanda.url':
          this.yollandaUrl = option.Value;
          break;
        case 'fe.availableLanguages':
          (this.availableLanguages as IObservableArray).replace(option.Value);
          break;
        case 'ytd.registration.additionalRequirements':
          this.additionalRegistrationRequirements = option.Value;
          break;
        case 'ytd.services.yollanda.copilot':
          this.yollandaCopilotEnabled = option.Value;
          break;
        case 'ytd.dataServices':
          this.dataServices = option.Value;
          break;
        case 'ytd.module.okr.exportTemplates':
          this.okrExportTemplates = option.Value;
          break;
        case 'ytd.module.okr.instructionsUrl':
          this.okrInstructionsUrl = option.Value;
          break;
        case 'fe.userSection.externalLinks':
          this.externalLinks = option.Value;
          break;
        case 'ytd.module.externalServices.apps':
          this.externalApps = option.Value;
          break;
      }
    });

    if (this.availableLanguages.length === 0) {
      this.availableLanguages = getDefaultAvailableLanguages(
        this.defaultLanguage ?? 'cs'
      );
    }

    options
      .filter(option => MODULE_REGEX.test(option.Code))
      .forEach(option => {
        const module = option.Code.replace(MODULE_SELECTOR, '');
        const value = option.Value;
        this.modules.set(module, value);
      });
  }

  public get status(): AsyncStatus {
    return this._status;
  }
}
