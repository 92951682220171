import { ListItem } from '@yarmill/components';
import { useIntl } from 'react-intl';
import { trackSettingsMenuClick } from '../google-analytics/utils';

export interface SidebarItemProps {
  active: boolean;
  link: string;
  title: string;
}

export function SidebarItem(props: SidebarItemProps): JSX.Element {
  const { active, link, title } = props;
  const intl = useIntl();
  return (
    <ListItem
      link={() => link}
      isActive={active}
      onClick={() => trackSettingsMenuClick(title)}
      name={intl.formatMessage({ id: `settings.sidebar.${title}` })}
      data-cy={title}
    />
  );
}
