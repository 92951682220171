import {
  BaseWorkoutData,
  ChartWidthProvider,
  HeartRate,
  SampleChartListLayout,
  Text,
  TextSize,
  TrainingDayAttributeLabel,
  TrainingDayAttributeLayout,
  WorkoutSample,
} from '@yarmill/components';
import { DATE_FORMAT } from '@yarmill/const';
import {
  formatDistanceWithUnit,
  formatSeconds,
  getWorkoutLink,
  useHistory,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { ReactElement, useCallback, useMemo } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { WorkoutValue } from '../training-day/types';
import { WorkoutAttributeStore } from './mobx/workout-attribute-store';

interface WorkoutSampleChartProps {
  readonly id: string;
  readonly data: number[];
  readonly hrMax: number | null;
  readonly hrMin: number | null;
  readonly width: number;
}

function WorkoutSampleChart(
  props: WorkoutSampleChartProps
): ReactElement | null {
  const { data, hrMax, hrMin, id, width } = props;

  const heartRateData: BaseWorkoutData[] = useMemo(() => {
    if (hrMin === null || hrMax === 0) {
      return [];
    }
    const fullData = data.map((item: number, idx: number) => ({
      hr: item || null,
      time: idx,
      x: idx,
      y: item || 0,
    }));

    return fullData;
  }, [data, hrMin, hrMax]);

  return heartRateData.length !== 0 && hrMax !== null && hrMin !== null ? (
    <HeartRate
      id={id}
      width={width}
      height={45}
      data={heartRateData}
      domain={[hrMin - 10, hrMax + 10]}
      total={heartRateData[heartRateData.length - 1]?.time || 0}
    />
  ) : null;
}

interface SingleWorkoutSampleProps {
  workout: WorkoutValue;
}

function SingleWorkoutSample(props: SingleWorkoutSampleProps): ReactElement {
  const history = useHistory();
  const { workout } = props;

  const showDetail = useCallback(() => {
    history.push(getWorkoutLink(workout.WorkoutId));
  }, [history, workout]);

  const startTime = useMemo(
    () => moment(workout.StartDateTime, DATE_FORMAT).toDate(),
    [workout]
  );

  return (
    <WorkoutSample
      name={
        <Text size={TextSize.s14}>
          <FormattedMessage id={workout.Label} />
        </Text>
      }
      time={
        <Text size={TextSize.s10} inheritColor monoSpace>
          <FormattedMessage id="workout.sample.at" />
          &nbsp;
          <FormattedTime value={startTime} />
        </Text>
      }
      distance={
        <Text size={TextSize.s10} inheritColor monoSpace>
          {formatDistanceWithUnit(workout.TotalDistance)}
        </Text>
      }
      duration={
        <Text size={TextSize.s10} inheritColor monoSpace>
          {formatSeconds(workout.TotalTime, '')}
        </Text>
      }
      hr={
        <Text size={TextSize.s10} inheritColor monoSpace>
          <FormattedMessage
            id="attributes.workout.hr"
            values={{ hr: workout.HeartRateAvg }}
          />
        </Text>
      }
      chart={
        <ChartWidthProvider>
          {width => (
            <WorkoutSampleChart
              id={String(workout.WorkoutId)}
              data={workout.ChartData || []}
              hrMax={workout.HeartRateMax}
              hrMin={workout.HeartRateMin}
              width={width}
            />
          )}
        </ChartWidthProvider>
      }
      onClick={showDetail}
    />
  );
}

export interface WorkoutAttributeProps {
  readonly attribute: WorkoutAttributeStore;
}

function InternalWorkoutAttribute(
  props: WorkoutAttributeProps
): ReactElement | null {
  const { attribute } = props;
  const workouts = attribute.workouts;

  if (workouts.length === 0) {
    return null;
  }

  return (
    <TrainingDayAttributeLayout>
      <TrainingDayAttributeLabel>
        <FormattedMessage id={attribute.name} />
      </TrainingDayAttributeLabel>
      <SampleChartListLayout>
        {workouts.map(workout => (
          <SingleWorkoutSample workout={workout} key={workout.WorkoutId} />
        ))}
      </SampleChartListLayout>
    </TrainingDayAttributeLayout>
  );
}

export const WorkoutAttribute = observer(InternalWorkoutAttribute);
