import { Icon, IconSize, Tippy } from '@yarmill/components';
import { Button, ButtonAppearance } from '@yarmill/components-2';
import { Left } from '@yarmill/icons-2';
import { useIsElementInViewport } from '@yarmill/utils';
import { ReactElement, RefObject, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { usePlannerStore } from '../planner-store-context';

interface ScrollLeftProps {
  readonly plannerContainerRef: RefObject<HTMLDivElement>;
  readonly leftScrollIndicatorRef: RefObject<HTMLDivElement>;
}
export function ScrollLeft({
  plannerContainerRef,
  leftScrollIndicatorRef,
}: ScrollLeftProps): ReactElement {
  const intl = useIntl();
  const plannerStore = usePlannerStore();
  const seasonMonthsCount =
    plannerStore.currentSeason?.seasonMonths.length ?? 12;
  const isDisabled = useIsElementInViewport(leftScrollIndicatorRef);

  const handleScrollLeft = useCallback(() => {
    const element = plannerContainerRef.current;
    if (element) {
      element.scrollBy({
        left: -1 * (element.scrollWidth / seasonMonthsCount),
        behavior: 'smooth',
      });
    }
  }, [plannerContainerRef, seasonMonthsCount]);

  return (
    <Tippy tooltipContent="planner.scrollLeft" noWrapper touch={false}>
      <Button
        $appearance={ButtonAppearance.Secondary}
        $appearanceStyle="neutral"
        $iconOnly
        onClick={handleScrollLeft}
        aria-label={intl.formatMessage({
          id: 'planner.scrollLeft',
        })}
        type="button"
        disabled={isDisabled}
      >
        <Icon size={IconSize.s24}>
          <Left />
        </Icon>
      </Button>
    </Tippy>
  );
}
