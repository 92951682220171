import {
  Button,
  ButtonAppearance,
  ConflictLayerContent,
  LayerPortal,
  Tippy,
  styled,
  toast,
} from '@yarmill/components';
import { FormattedMessage, useIntl } from 'react-intl';

import { ConflictResolution } from '@yarmill/types';
import {
  AsyncState,
  CopyConflictErrorResponse,
  ServerErrorResponse,
  useAsyncState,
  useConfig,
  useCurrentUserStore,
  useLayer,
  useRootStore,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useAttendanceStore } from './attendance-store-context';
import { toastCopyAttendanceSuccess } from './utils';
import { copyAttendance } from './utils/copy-attendace';

const StyledCopyAttendanceButton = styled.span`
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 7px;

    span,
    button {
      width: 100%;
    }
  }
`;

export const CopyAttendanceButton = observer(
  function CopyAttendanceButton(): JSX.Element | null {
    const attendanceStore = useAttendanceStore();
    const intl = useIntl();
    const rootStore = useRootStore();
    const currentUser = useCurrentUserStore();
    const isAllowedToCopy = currentUser.isAllowedTo('attendance.copy');
    const isCopyEnabled = useConfig('attendanceCopyPlanToReality');
    const conflictLayer = useLayer('alert', { showShim: true });
    const [state, setAsyncState, conflictResponse] =
      useAsyncState<CopyConflictErrorResponse>();
    const currentUserHasWritePermissions = currentUser.hasWritePermission(
      attendanceStore.group?.id ?? null,
      null
    );
    const disabled =
      attendanceStore.viewType === 'season' ||
      state === AsyncState.pending ||
      !currentUserHasWritePermissions;

    const submitCopy = async (
      resolution: ConflictResolution
    ): Promise<void> => {
      setAsyncState(AsyncState.pending);
      if (!attendanceStore.group || !attendanceStore.week) {
        return;
      }
      const response = await copyAttendance(
        rootStore,
        attendanceStore.group.id,
        attendanceStore.week,
        resolution
      );
      if (
        response instanceof CopyConflictErrorResponse &&
        !conflictLayer.isOpened
      ) {
        setAsyncState(AsyncState.rejected, response);
        conflictLayer.open();
        return;
      } else if (response instanceof ServerErrorResponse) {
        toast(response.id, 'error');
        setAsyncState(AsyncState.rejected);
      } else if (response) {
        setAsyncState(AsyncState.resolved);
        toastCopyAttendanceSuccess(
          attendanceStore.group.athletes.length,
          resolution,
          conflictResponse?.conflictingIDs.length ?? 0
        );
      } else {
        toast(`toast.error.attendance.copy`, 'error');
        setAsyncState(AsyncState.rejected);
      }
    };

    const onClick = async () => {
      if (
        window.confirm(intl.formatMessage({ id: 'attendance.copy.confirm' }))
      ) {
        await submitCopy('FailOnConflict');
      }
    };

    if (!isCopyEnabled || !isAllowedToCopy) {
      return null;
    }

    return (
      <>
        <StyledCopyAttendanceButton>
          <Tippy
            tooltipContent={
              !currentUserHasWritePermissions
                ? `attendance.copyButton.disabledTooltip.noPermissions`
                : `attendance.copyButton.disabledTooltip`
            }
            isEnabled={disabled}
            noWrapper={!disabled}
          >
            <Button
              appearance={ButtonAppearance.Primary}
              onClick={onClick}
              disabled={disabled}
              wide
            >
              <FormattedMessage id="navbar.attendance.copyButton" />
            </Button>
          </Tippy>
        </StyledCopyAttendanceButton>
        <LayerPortal
          layerHandle={conflictLayer}
          getContent={layer => (
            <ConflictLayerContent
              layer={layer}
              conflictingIds={conflictResponse?.conflictingIDs ?? []}
              conflictResolutions={conflictResponse?.resolutions ?? []}
              submitCopy={submitCopy}
              mode="attendance"
              sourceType="group"
              module="attendance"
              viewType="week"
            />
          )}
        />
      </>
    );
  }
);
