import { UserGroupId, UserId } from '@yarmill/types';
import { axios, coreApiTarget, devMode, instance } from '@yarmill/utils';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { Objective } from '../types';

export interface DeleteObjectiveRequestParams {
  readonly objectiveId: number;
  readonly userId: UserId | null;
  readonly userGroupId: UserGroupId | null;
}

export function deleteObjective(
  { objectiveId, userId, userGroupId }: DeleteObjectiveRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<Objective[]> {
  return axios.delete(`${coreApiTarget}/api/okrs/objectiveId/${objectiveId}`, {
    cancelToken: cancelToken?.token,
    params: {
      instanceCode: devMode ? instance : undefined,
      userId: userId || undefined,
      userGroupId: userId ? undefined : userGroupId || undefined,
    },
  });
}
