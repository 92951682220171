import { useContext } from 'react';
import { ReportingStoreContext } from '../context/reporting-store-context';
import { ReportingStore } from '../mobx/reporting-store';

export function useReportingStore(): ReportingStore {
  const reportingStore = useContext(ReportingStoreContext);
  if (!reportingStore) {
    throw new Error('Reporting Store not provided in context');
  }
  return reportingStore;
}
