import { TrainingDiaryModule } from '@yarmill/training-diary';
import { Page } from '../page/page';

export function TrainingDiary() {
  return (
    <Page>
      <TrainingDiaryModule />
    </Page>
  );
}
