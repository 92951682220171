import { TextSize, WhiteSpace, YollandaLoadingText } from '@yarmill/components';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

interface LoadingTextProps {
  readonly forceLastMessage?: boolean;
}
export function LoadingText({
  forceLastMessage,
}: LoadingTextProps): JSX.Element {
  const intl = useIntl();
  const [message, setMessage] = useState(0);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
    let interval: ReturnType<typeof setTimeout>;
    let timeout: ReturnType<typeof setTimeout>;

    function tick() {
      interval = setTimeout(() => {
        setVisible(false);
        timeout = setTimeout(
          () =>
            setMessage(m => {
              interval = setTimeout(() => setVisible(true), 750);
              if (m < 3) {
                tick();
              }
              return Math.min(4, m + 1);
            }),
          750
        );
      }, 3000);
    }

    if (!forceLastMessage) {
      tick();
    }
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [forceLastMessage]);

  useEffect(() => {
    if (forceLastMessage) {
      setVisible(true);
      setMessage(4);
    }
  }, [forceLastMessage]);

  return (
    <YollandaLoadingText
      size={TextSize.s14}
      whiteSpace={WhiteSpace.preWrap}
      className={visible ? 'visible' : undefined}
    >
      {intl.formatMessage({
        id: `yollanda.loadingMessage.${message}`,
      })}
    </YollandaLoadingText>
  );
}
