import { getWeekEnd, getWeekStart } from '@yarmill/utils';
import { FormatDateOptions, IntlShape } from 'react-intl';
import { ReportingPageFilterStore } from '../mobx/reporting-page-filter-store';

function formatFilterValue(
  filter: ReportingPageFilterStore,
  intl: IntlShape
): string {
  if (filter.type.toLowerCase() === 'dropdown') {
    const filterOption = filter.options.find(
      option => option.Key === filter.value
    );
    if (!filterOption) {
      return '-';
    }

    return filter.translateLabels &&
      filterOption.Label &&
      intl.messages[filterOption.Label]
      ? intl.formatMessage({ id: filterOption.Label })
      : filterOption.Label;
  } else if (
    filter.type.toLowerCase() === 'week' ||
    filter.type === 'datepicker-week'
  ) {
    const dateFormat: FormatDateOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    };
    const filterValue = filter.value;

    if (!filterValue) {
      return '-';
    }

    const start = intl.formatDate(
      getWeekStart(filterValue).toDate(),
      dateFormat
    );
    const end = intl.formatDate(getWeekEnd(filterValue).toDate(), dateFormat);

    return `${start} - ${end}`;
  } else if (filter.type.toLowerCase() === 'datepicker-day') {
    const dateFormat: FormatDateOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    };

    const filterValue = filter.value;

    if (!filterValue) {
      return '-';
    }

    return intl.formatDate(filterValue, dateFormat);
  }

  return '';
}

export function getFilterValues(
  filters: ReportingPageFilterStore[],
  intl: IntlShape
) {
  const entries = filters.map(filter => [
    filter.code,
    formatFilterValue(filter, intl),
  ]);

  return Object.fromEntries(entries);
}
