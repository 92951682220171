import { AttendanceModuleDefinitionStore } from '@yarmill/attendance';
import { EvidenceModuleDefinitionStore } from '@yarmill/evidence';
import { FilesOverviewModuleDefinitionStore } from '@yarmill/files-overview';
import { OkrsModuleDefinitionStore } from '@yarmill/okrs';
import { PlannerModuleDefinitionStore } from '@yarmill/planner';
import { ReportingModuleDefinitionStore } from '@yarmill/reporting';
import { SeasonEvaluationModuleDefinitionStore } from '@yarmill/season-evaluation';
import {
  PlanModuleDefinitionStore,
  RealityModuleDefinitionStore,
} from '@yarmill/training-diary';
import {
  AsyncStatus,
  ModuleDefinitionStore,
  RootStore,
  ModulesRegistryService as iModulesRegistryService,
} from '@yarmill/types';
import { IObservableArray, makeObservable, observable, when } from 'mobx';
import { LegacyAnalyticsModuleDefinitionStore } from '../../analytics/mobx/legacy-analytics-module-definition-store';

export class ModulesRegistryService implements iModulesRegistryService {
  private readonly _rootStore: RootStore;

  @observable
  private _modules: IObservableArray<ModuleDefinitionStore> =
    observable.array();

  @observable
  private _isReady = false;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeObservable(this);
    when(
      () =>
        rootStore.configStore.status === AsyncStatus.resolved &&
        rootStore.currentUserStore.status === AsyncStatus.resolved,
      () => this.registerModules()
    );
  }

  get enabledModules(): ModuleDefinitionStore[] {
    return this._modules;
  }

  get isReady() {
    return this._isReady;
  }

  private registerModules(): void {
    const modulesStore = this._rootStore.modulesStore;
    const currentUser = this._rootStore.currentUserStore;

    if (modulesStore.reality && currentUser.isAllowedTo('reality')) {
      this._modules.push(new RealityModuleDefinitionStore(this._rootStore));
    }
    if (modulesStore.plan && currentUser.isAllowedTo('plan')) {
      this._modules.push(new PlanModuleDefinitionStore(this._rootStore));
    }
    if (modulesStore.analytics && currentUser.isAllowedTo('analytics')) {
      this._modules.push(
        new LegacyAnalyticsModuleDefinitionStore(this._rootStore)
      );
    }
    if (modulesStore.reporting.length && currentUser.isAllowedTo('reporting')) {
      this._modules.push(new ReportingModuleDefinitionStore(this._rootStore));
    }
    if (modulesStore.attendance && currentUser.isAllowedTo('attendance')) {
      this._modules.push(new AttendanceModuleDefinitionStore(this._rootStore));
    }
    if (
      modulesStore.filesOverview &&
      currentUser.isAllowedTo('filesOverview')
    ) {
      this._modules.push(
        new FilesOverviewModuleDefinitionStore(this._rootStore)
      );
    }
    if (
      modulesStore.seasonEvaluation &&
      currentUser.isAllowedTo('seasonEvaluation')
    ) {
      this._modules.push(
        new SeasonEvaluationModuleDefinitionStore(this._rootStore)
      );
    }
    if (modulesStore.okr && currentUser.isAllowedTo('okr')) {
      this._modules.push(new OkrsModuleDefinitionStore(this._rootStore));
    }
    if (modulesStore.planner && currentUser.isAllowedTo('plan')) {
      this._modules.push(new PlannerModuleDefinitionStore(this._rootStore));
    }
    if (modulesStore.evidence && currentUser.isAllowedTo('evidence')) {
      this._modules.push(new EvidenceModuleDefinitionStore(this._rootStore));
    }

    this._isReady = true;
  }
}
