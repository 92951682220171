import { getSeasonAttendanceItems } from './get-season-attendace-items';
import { getWeekAttendanceItems } from './get-week-attendace-items';
import { updateAttendanceItem } from './update-attendace-item';
import { updateAttendanceItemForGroup } from './update-attendace-item-for-group';

export const api = {
  getSeasonAttendanceItems,
  getWeekAttendanceItems,
  updateAttendanceItem,
  updateAttendanceItemForGroup,
};
