import styled from 'styled-components';

import { Text, TextAlignment, TextSize } from '../text';
import { chunkArray } from '../helpers';
import { Fragment, ReactNode } from 'react';

export interface ChartTooltipProps {
  readonly label: ReactNode;
  readonly tableLayout?: string;
  readonly showTableColumnsLabels?: boolean;
  readonly tableColumnsLabels?: Record<string, string>;
  readonly items: ChartTooltipItemProps[];
}

const StyledChartTooltip = styled.div<{ readonly tableColumnsCount?: number }>`
  font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
  display: grid;
  grid-template-columns: ${({ tableColumnsCount }) =>
    tableColumnsCount ? `20px repeat(${tableColumnsCount + 1}, auto)` : '100%'};
  grid-template-rows: auto;
  row-gap: 5px;
  column-gap: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
  padding: 10px 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const StyledItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px auto auto;
  align-items: center;
  column-gap: 5px;
  row-gap: 5px;

  &:not(:last-child) {
    margin-right: 5px;
    padding-right: 10px;
    border-right: 1px solid #e4e7e7;
  }
`;

const ColumnsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const TableLabel = styled(Text)<{ readonly useTable: boolean }>`
  ${({ useTable }) => useTable && `grid-column: 1/3;`};
`;

function getNumberOfChunks(): number {
  return window.innerHeight > window.innerWidth ? 2 : 3;
}

export function ChartTooltip(props: ChartTooltipProps): JSX.Element {
  const {
    label,
    items,
    showTableColumnsLabels,
    tableLayout,
    tableColumnsLabels,
  } = props;
  const columns = tableLayout
    ? [items]
    : chunkArray(
        items,
        Math.max(Math.ceil(items.length / getNumberOfChunks()), 6)
      );

  const tableColumns = tableLayout?.split(' ');
  const Wrapper = tableLayout ? Fragment : ColumnsWrapper;
  const ItemsWrapper = tableLayout ? Fragment : StyledItemsWrapper;

  return (
    <StyledChartTooltip tableColumnsCount={tableColumns?.length}>
      {label && (
        <TableLabel
          bold
          size={TextSize.s14}
          useTable={Boolean(tableColumns?.length)}
        >
          {label}
        </TableLabel>
      )}
      {tableColumns?.map(col => (
        <Text size={TextSize.s14} key={col} textAlign={TextAlignment.right}>
          {showTableColumnsLabels ? tableColumnsLabels?.[col] : null}
        </Text>
      ))}
      <Wrapper>
        {columns.map((columnItems, idx) => (
          <ItemsWrapper key={idx}>
            {columnItems.map((item, idx) => (
              <ChartTooltipItem
                {...item}
                key={item.id || idx}
                tableColumns={tableColumns}
                tableValues={item.tableValues}
              />
            ))}
          </ItemsWrapper>
        ))}
      </Wrapper>
    </StyledChartTooltip>
  );
}

export interface ChartTooltipItemProps {
  readonly color: string;
  readonly id?: string | number;
  readonly label: React.ReactNode;
  readonly value: React.ReactNode;
  readonly tableValues?: Record<string, ReactNode>;
  readonly tableColumns?: string[] | undefined;
}

const StyledColorIndicator = styled.svg`
  width: 20px;
  height: 20px;
  flex-grow: 1;
`;

const StyledValue = styled(Text)`
  padding-left: 15px;
`;

function ChartTooltipItem(props: ChartTooltipItemProps): JSX.Element {
  const { tableColumns, tableValues, color, label, value } = props;
  return (
    <>
      <StyledColorIndicator viewBox="0 0 20 20">
        <circle
          r={4}
          cx={10}
          cy={10}
          fill={color}
          stroke={color}
          strokeWidth={2}
        />
      </StyledColorIndicator>
      <Text size={TextSize.s14}>{label}</Text>
      {tableColumns?.length ? (
        tableColumns.map(columnKey => (
          <StyledValue
            textAlign={TextAlignment.right}
            size={TextSize.s14}
            key={columnKey}
          >
            {tableValues?.[columnKey]}
          </StyledValue>
        ))
      ) : (
        <StyledValue textAlign={TextAlignment.right} size={TextSize.s14}>
          {value}
        </StyledValue>
      )}
    </>
  );
}
