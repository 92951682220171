import { User } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';
import { UserInvitation } from '../types';

export interface InviteNewUsersParams {
  UserRegistrationInfo: UserInvitation[];
  groupIds: number[];
}

export function inviteNewUsers(
  params: InviteNewUsersParams
): AxiosPromise<User[]> {
  return axios.post('api/Account/Register/Invite', params);
}
