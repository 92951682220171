import { createElement } from 'react';

export function Link() {
  return createElement(
    'svg',
    {
      viewBox: '0 0 18 18',
      xmlns: 'http://www.w3.org/2000/svg',
      fill: 'currentColor',
      role: 'img'
    },
    createElement(
      'g',
      { fill: 'currentColor' },
      createElement('path', {
        d: 'm15.347 11.393-1.848-1.849a.659.659 0 0 0-.931.931l1.848 1.848a2.297 2.297 0 0 1-3.249 3.25l-3.486-3.487a2.296 2.296 0 0 1 1.274-3.894.666.666 0 1 0-.202-1.317 3.613 3.613 0 0 0-2.002 6.146l3.486 3.486a3.613 3.613 0 1 0 5.11-5.11v-.004Z'
      }),
      createElement('path', {
        d: 'M3.585 5.633a2.297 2.297 0 0 1 3.25-3.25L10.32 5.87a2.296 2.296 0 0 1-1.273 3.894.66.66 0 0 0 .1 1.317h.102a3.613 3.613 0 0 0 2.002-6.146L7.765 1.449a3.613 3.613 0 1 0-5.11 5.11l1.848 1.848a.659.659 0 1 0 .93-.93L3.586 5.633Z'
      })
    )
  );
}
