import { css } from 'styled-components';

export const ToastContainerStyles = css`
  padding: 4px;
  position: fixed;
  transform: translate3d(0, 0, 9999px);
  width: 320px;
  z-index: 9999;

  @media (max-width: 767px) {
    padding: 12px;
  }

  &.Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em;
  }

  &.Toastify__toast-container--top-left {
    top: 1em;
    left: 1em;
  }
  &.Toastify__toast-container--bottom-center {
    max-width: 50vw;
    width: auto;
    margin: auto;
  }
  &.Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
    max-width: 50vw;
    width: auto;
    margin: auto;
  }
  &.Toastify__toast-container--top-right {
    top: 1em;
    right: 1em;
  }
  &.Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em;
  }
  &.Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
  &.Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em;
  }

  @media only screen and (max-width: 480px) {
    width: auto;
    padding: 0 12px;
    right: 0 !important;
    margin: 0;

    &.Toastify__toast-container--top-left,
    &.Toastify__toast-container--top-center,
    &.Toastify__toast-container--top-right {
      top: 0;
      transform: translateX(0);
    }
    &.Toastify__toast-container--bottom-left,
    &.Toastify__toast-container--bottom-center,
    &.Toastify__toast-container--bottom-right {
      bottom: 0;
      transform: translateX(0);
    }
    &.Toastify__toast-container--rtl {
      right: 0;
      left: initial;
    }

    &.Toastify__toast-container--top-center,
    &.Toastify__toast-container--bottom-center {
      transform: unset;
      max-width: unset;
    }
  }

  .Toastify__toast-body {
    padding: unset;

    & > div {
      max-width: 100%;
    }
  }

  .ytd-toast {
    border-radius: 8px;
    background-color: #4a4a4a;
    color: #ffffff;
    padding: 8px 12px;
    margin-bottom: 1rem;
    min-height: unset;
    transition: background-color 0.25s ease-in;
    box-shadow:
      0 1px 10px 0 rgba(0, 0, 0, 0.1),
      0 2px 15px 0 rgba(0, 0, 0, 0.05);
    position: relative;

    &.Toastify__toast--close-on-click {
      cursor: pointer;
    }

    &.success {
      background-color: #22a061;
    }

    &.error {
      background-color: #d04535;
    }

    & > .Toastify__toast-body {
      max-width: 100%;
    }
  }

  .Toastify--animate {
    animation-fill-mode: both;
    animation-duration: 0.7s;
  }

  @keyframes Toastify__slideInRight {
    from {
      transform: translate3d(110%, 0, 0);
      visibility: visible;
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes Toastify__slideInLeft {
    from {
      transform: translate3d(-110%, 0, 0);
      visibility: visible;
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes Toastify__slideInUp {
    from {
      transform: translate3d(0, 110%, 0);
      visibility: visible;
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes Toastify__slideInDown {
    from {
      transform: translate3d(0, -110%, 0);
      visibility: visible;
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes Toastify__slideOutRight {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      visibility: hidden;
      transform: translate3d(110%, 0, 0);
    }
  }
  @keyframes Toastify__slideOutLeft {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      visibility: hidden;
      transform: translate3d(-110%, 0, 0);
    }
  }
  @keyframes Toastify__slideOutDown {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      visibility: hidden;
      transform: translate3d(0, 500px, 0);
    }
  }
  @keyframes Toastify__slideOutUp {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      visibility: hidden;
      transform: translate3d(0, -500px, 0);
    }
  }
  .Toastify__slide-enter--top-left,
  .Toastify__slide-enter--bottom-left {
    animation-name: Toastify__slideInLeft;
  }
  .Toastify__slide-enter--top-right,
  .Toastify__slide-enter--bottom-right {
    animation-name: Toastify__slideInRight;
  }
  .Toastify__slide-enter--top-center {
    animation-name: Toastify__slideInDown;
  }
  .Toastify__slide-enter--bottom-center {
    animation-name: Toastify__slideInUp;
  }

  .Toastify__slide-exit--top-left,
  .Toastify__slide-exit--bottom-left {
    animation-name: Toastify__slideOutLeft;
  }
  .Toastify__slide-exit--top-right,
  .Toastify__slide-exit--bottom-right {
    animation-name: Toastify__slideOutRight;
  }
  .Toastify__slide-exit--top-center {
    animation-name: Toastify__slideOutUp;
  }
  .Toastify__slide-exit--bottom-center {
    animation-name: Toastify__slideOutDown;
  }

  @keyframes Toastify__trackProgress {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }
  .Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: var(--toastify-z-index);
    opacity: 0.7;
    transform-origin: left;
  }
  .Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards;
  }
  .Toastify__progress-bar--controlled {
    transition: transform 0.2s;
  }
`;
