import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import styled from 'styled-components';
import { css } from '../theme-provider';
import { Breakpoints } from '../helpers';

export enum ShimBackgroundType {
  OPAQUE = 'OPAQUE',
  TRANSPARENT = 'TRANSPARENT',
}

export interface ShimLayerProps {
  readonly onClick?: React.ReactEventHandler<HTMLDivElement>;
  readonly backgroundType?: ShimBackgroundType;
  readonly mobileOnly?: boolean;
}

const animationClassName = 'animation';
const animationTimeout = 300;
const animationTimingFunction = 'ease';

const StyledShimLayer = styled.div<{
  readonly backgroundType: ShimBackgroundType;
  readonly mobileOnly?: boolean;
}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: ${props =>
    props.backgroundType === ShimBackgroundType.TRANSPARENT
      ? 'transparent'
      : 'rgba(0,0,0,.2)'};
  opacity: 1;

  transition: opacity ${animationTimeout}ms ${animationTimingFunction};

  &.${animationClassName}-exit {
    opacity: 1;
  }

  &.${animationClassName}-enter,
    &.${animationClassName}-exit-active,
    &.${animationClassName}-exit-done {
    opacity: 0;
  }

  // this must be separate from the animation-exit definition because the order in the CSS must be in a specific order
  // for the animation to work and some of the values for the animation are breakpoint specific.
  // * <name>-<state> => breakpoint specific for enter
  // * <name>-<state>-active => breakpoint specific for exit
  // * <name>-<state>-done (if needed) => breakpoint specific for exit
  &.${animationClassName}-enter-active {
    opacity: 1;
  }

  ${({ mobileOnly }) =>
    mobileOnly &&
    css`
      @media (min-width: ${Breakpoints.tablet}px) {
        display: none;
      }
    `}
`;
StyledShimLayer.displayName = 'StyledShimLayer';

export interface ShimLayerPropsWithAnimation extends ShimLayerProps {
  /**
   * This is provided by the react-transition-group. No need to use it when instantiating the component.
   * Note: If you write a wrapper for this component, take care to pass these props through to ensure that the
   * animations still work
   */
  readonly in?: boolean;
  /**
   * This is provided by the react-transition-group. No need to use it when instantiating the component.
   * Note: If you write a wrapper for this component, take care to pass these props through to ensure that the
   * animations still work
   */
  onExited?(): void;
}

export function ShimLayer(props: ShimLayerPropsWithAnimation): JSX.Element {
  const {
    backgroundType = ShimBackgroundType.OPAQUE,
    in: visible,
    onExited,
    mobileOnly,
  } = props;

  const handleClick = (event: React.SyntheticEvent<HTMLDivElement>): void => {
    const { onClick = () => undefined } = props;
    onClick(event);
  };

  return (
    <CSSTransition
      classNames={animationClassName}
      onExited={onExited}
      in={visible}
      timeout={animationTimeout}
    >
      <StyledShimLayer
        onClick={handleClick}
        backgroundType={backgroundType}
        mobileOnly={mobileOnly}
      />
    </CSSTransition>
  );
}
