import { Button, ButtonAppearance, Tippy } from '@yarmill/components';
import { useCurrentUserStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { useAttendanceStore } from './attendance-store-context';

export interface EditButtonProps {
  dayIndex: number;
}

export const EditButton = observer(function EditButton(
  props: EditButtonProps
): JSX.Element {
  const { dayIndex } = props;
  const attendanceStore = useAttendanceStore();
  const currentUser = useCurrentUserStore();
  const isCurrentlyEdited = dayIndex === attendanceStore.editedColumn;
  const hasReadPermissionToGroup =
    currentUser.getPermissionToGroup(attendanceStore.group?.id || -1) ===
    'read';

  const disabled =
    !currentUser.isAllowedTo('attendance.write') || hasReadPermissionToGroup;

  const onClick = () => {
    attendanceStore.setEditedColumn(isCurrentlyEdited ? null : dayIndex);
  };

  return (
    <Tippy
      Wrapper="div"
      tooltipContent={`attendance.noRights.${
        currentUser.role === 'athlete' ? 'athlete' : 'coach'
      }`}
      isEnabled={disabled}
    >
      <Button
        appearance={ButtonAppearance.Link}
        onClick={disabled ? undefined : onClick}
        small
        disabled={disabled}
      >
        {isCurrentlyEdited ? (
          <FormattedMessage id="attendance.closeEdit" />
        ) : (
          <FormattedMessage id="attendance.edit" />
        )}
      </Button>
    </Tippy>
  );
});
