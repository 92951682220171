import { PropsWithChildren, createContext, useMemo, useState } from 'react';
import { AxisValue } from '../../reporting';

export interface SharedTooltipContextValue {
  readonly axisValue: AxisValue | null;
  readonly axisKey: string | null;
  readonly axisType: 'x' | 'y' | null;
  readonly setValue: (
    value: AxisValue | null,
    axisType: 'x' | 'y' | null,
    axisKey: string | null
  ) => void;
}

export const SharedTooltipContext = createContext<
  SharedTooltipContextValue | undefined
>(undefined);

export function SharedTooltipContextProvider({ children }: PropsWithChildren) {
  const [axisValue, setAxisValue] = useState<AxisValue | null>(null);
  const [axisType, setAxisType] = useState<'x' | 'y' | null>(null);
  const [axisKey, setAxisKey] = useState<string | null>(null);
  const value: SharedTooltipContextValue = useMemo(
    () => ({
      axisKey,
      axisType,
      axisValue,
      setValue: (value, axisType, axisKey) => {
        setAxisKey(axisKey);
        setAxisType(axisType);
        setAxisValue(value);
      },
    }),
    [axisKey, axisType, axisValue]
  );

  return (
    <SharedTooltipContext.Provider value={value}>
      {children}
    </SharedTooltipContext.Provider>
  );
}
