import {
  Button,
  ButtonAppearance,
  Checkbox,
  FormControlVariant,
  FormattedHTMLMessage,
  TermsWrapper,
  Text,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { TermsAndConditionsStore } from './mobx/terms-and-conditions-store';
import { TermsCheckBox } from './types';

const StyledContentWrapper = styled.div``;
StyledContentWrapper.displayName = 'StyledContentWrapper';

export const StyledTermsPart = styled(Text)`
  width: 100%;
  margin: 40px 0;

  a {
    color: #4da1ff;
    text-decoration: underline;
  }
`;

const StyledCheckBoxWrapper = styled.div`
  margin-bottom: 40px;
`;

const StyledTermsCheckBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  a {
    color: #4da1ff;
    text-decoration: underline;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLabel = styled.p`
  margin: 0;
`;

interface RegistrationTermsAndConditionsPage {
  checkBox: TermsAndConditionsStore;
  handleNextStep(): void;
}

export interface TermsAndConditionsCheckboxesProps {
  checkBox: TermsAndConditionsStore;
}

export const TermsAndConditionsCheckboxes = observer(
  function TermsAndConditionsCheckboxes(
    props: TermsAndConditionsCheckboxesProps
  ): JSX.Element {
    const { checkBox } = props;

    const handleCheckbox = React.useCallback(
      (e: React.SyntheticEvent<HTMLElement>) => {
        const target = e.target as HTMLInputElement;
        switch (target.name) {
          case TermsCheckBox.CONDITIONS:
            checkBox.isConditionsAccepted
              ? checkBox.rejectConditions()
              : checkBox.acceptConditions();
            break;
          case TermsCheckBox.PERSONAL_DATA:
            checkBox.isPersonalDataAccepted
              ? checkBox.rejectPersonalData()
              : checkBox.acceptPersonalData();
            break;
          case TermsCheckBox.NEWS:
            checkBox.isNewsAccepted
              ? checkBox.rejectNews()
              : checkBox.acceptNews();
            break;
          default:
            checkBox.isCustomRequirementAccepted(target.name)
              ? checkBox.rejectCustomRequirement(target.name)
              : checkBox.acceptCustomRequirement(target.name);
            break;
        }
      },
      [checkBox]
    );
    return (
      <>
        <StyledCheckBoxWrapper>
          <StyledTermsCheckBox>
            <Checkbox
              name={TermsCheckBox.CONDITIONS}
              value={String(checkBox.isConditionsAccepted)}
              onClick={handleCheckbox}
              checked={checkBox.isConditionsAccepted}
              label={
                <Text size={TextSize.s14}>
                  <FormattedMessage id="registration.terms.checkbox.termsAndPrivacyPolicy" />
                </Text>
              }
            />
            <StyledLabel></StyledLabel>
          </StyledTermsCheckBox>
          <StyledTermsCheckBox>
            <Checkbox
              name={TermsCheckBox.PERSONAL_DATA}
              value={String(checkBox.isPersonalDataAccepted)}
              onClick={handleCheckbox}
              checked={checkBox.isPersonalDataAccepted}
              label={
                <Text size={TextSize.s14}>
                  <FormattedMessage id="registration.terms.checkbox.sensitiveDataConsent" />
                </Text>
              }
            />
          </StyledTermsCheckBox>
          <StyledTermsCheckBox>
            <Checkbox
              name={TermsCheckBox.NEWS}
              value={String(checkBox.isNewsAccepted)}
              onClick={handleCheckbox}
              checked={checkBox.isNewsAccepted}
              label={
                <Text size={TextSize.s14}>
                  <FormattedMessage id="registration.terms.checkbox.commercialConsent" />
                </Text>
              }
            />
          </StyledTermsCheckBox>
          {checkBox.customRequirements.map(customRequirement => (
            <StyledTermsCheckBox key={customRequirement.code}>
              <Checkbox
                name={customRequirement.code}
                value={String(
                  checkBox.isCustomRequirementAccepted(customRequirement.code)
                )}
                onClick={handleCheckbox}
                checked={checkBox.isCustomRequirementAccepted(
                  customRequirement.code
                )}
                label={
                  <Text size={TextSize.s14}>
                    <FormattedHTMLMessage id={customRequirement.text} />
                  </Text>
                }
              />
            </StyledTermsCheckBox>
          ))}
        </StyledCheckBoxWrapper>
      </>
    );
  }
);

function InternalTermsAndConditions(
  props: RegistrationTermsAndConditionsPage
): JSX.Element {
  const { checkBox, handleNextStep } = props;

  return (
    <TermsWrapper>
      <Text size={TextSize.s24} tag={TextTag.h1}>
        <FormattedMessage id="registration.terms.heading" />
      </Text>
      <StyledContentWrapper>
        <StyledTermsPart>
          <Text size={TextSize.s14}>
            <FormattedHTMLMessage id="registration.terms.description" />
          </Text>
        </StyledTermsPart>
        <TermsAndConditionsCheckboxes checkBox={checkBox} />
        <StyledButtonWrapper>
          <Button
            disabled={!checkBox.areRequiredCheckBoxesAccepted}
            appearance={ButtonAppearance.Primary}
            onClick={handleNextStep}
            variant={FormControlVariant.big}
            data-cy="export-button"
          >
            <FormattedMessage id="settings.profile.form.next" />
          </Button>
        </StyledButtonWrapper>
      </StyledContentWrapper>
    </TermsWrapper>
  );
}

export const TermsAndConditions = observer(InternalTermsAndConditions);
