import {
  ActivityInput,
  PatternInputProps,
  replacePatternInputPattern,
  styled,
  usePatternInput,
} from '@yarmill/components';
import { forwardRef } from 'react';

const StyledInputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const StyledValue = styled(ActivityInput)`
  position: absolute;
  color: transparent;
  pointer-events: none;
  top: 0;
  left: 0;
`;

const StyledPattern = styled(StyledValue)`
  color: #9a9a9a;
  pointer-events: none;
`;

const StyledTextInput = styled(ActivityInput).attrs<{
  contentLength: number;
  patternLength: number;
}>(({ contentLength, patternLength }) => ({
  style: {
    paddingRight: `calc(${patternLength - contentLength}ch + 5px)`,
  },
}))<{
  contentLength: number;
  patternLength: number;
}>`
  z-index: 1;
  &:not(:focus) + ${StyledPattern} {
    color: transparent;
  }
`;

export const PatternActivityInput = forwardRef<
  HTMLInputElement,
  PatternInputProps & { isSelected?: boolean }
>(function PatternActivityInput(props, ref): JSX.Element {
  const {
    defaultValue,
    pattern,
    validateValue,
    delimiter,
    variant,
    value,
    readOnly,
    noError,
    noLabel,
    ...textInputProps
  } = props;
  const { onChange, onKeyDown, persistedValue } = usePatternInput(props);
  const content = value !== undefined ? value : persistedValue;

  return (
    <StyledInputWrapper>
      <StyledTextInput
        {...textInputProps}
        value={content}
        readOnly={readOnly}
        autoComplete="off"
        onChange={onChange}
        onKeyDown={onKeyDown}
        ref={ref}
        patternLength={pattern.length}
        contentLength={content.length}
      />
      <StyledPattern
        readOnly
        tabIndex={-1}
        value={replacePatternInputPattern(pattern, content)
          .map(r => (typeof r === 'object' ? ' ' : r))
          .join('')}
      />
    </StyledInputWrapper>
  );
});
