import { times } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { KPIProps } from '../kpi';
import { getKPIProgressColor, getKPIValue } from '../utils';

export const Days = observer((props: KPIProps) => (
  <svg viewBox="0 0 460 152">
    <g
      id="TD-novy-design"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="BasicStatistics-Copy"
        transform="translate(-298.000000, -186.000000)"
      >
        <g id="Group-25" transform="translate(298.000000, 186.000000)">
          <g id="Group-39">
            <rect
              id="Rectangle-15-Copy"
              fill="#FFFFFF"
              x="0"
              y="0"
              width="460"
              height="152"
              rx="8"
            />
            <text
              id="zbyva-97-do-konce-se"
              opacity="0.801172"
              fontFamily="Ubuntu-Light, Ubuntu"
              fontSize="12"
              fontWeight="300"
              fill="#4A4A4A"
            >
              <tspan x="51" y="107">
                {props.intl.formatMessage({ id: 'analytics.kpi.remaining' })}
                <tspan fontWeight="bold">
                  {getKPIValue(props.data, 'DZ AbsDiff')}
                </tspan>
                {props.intl.formatMessage({
                  id: 'analytics.kpi.toEndOfSeason',
                })}
              </tspan>
            </text>
            <text
              id="131/233"
              fontFamily="Ubuntu"
              fontSize="32"
              fontWeight="normal"
              fill="#4A4A4A"
            >
              <tspan x="420.504" y="84" textAnchor="end">
                {getKPIValue(props.data, 'DZ Reality')}/
                <tspan fontSize="26">
                  {getKPIValue(props.data, 'DZ Plan')}
                </tspan>
              </tspan>
            </text>
            <text
              id="dni"
              opacity="0.801172"
              fontFamily="Ubuntu-Light, Ubuntu"
              fontSize="12"
              fontWeight="300"
              fill="#4A4A4A"
            >
              <tspan x="420.504" y="110" textAnchor="end">
                {props.intl.formatMessage({
                  id: 'analytics.kpi.dzDaysRemaining',
                })}
              </tspan>
            </text>
            <text
              id="Dny-zatížení"
              fontFamily="Ubuntu-Bold, Ubuntu"
              fontSize="14"
              fontWeight="bold"
              letterSpacing="0.6"
              fill="#4A4A4A"
            >
              <tspan x="51" y="47">
                {props.intl.formatMessage({ id: 'analytics.kpi.dz' })}
              </tspan>
            </text>
            <g
              id="Group-4"
              transform="translate(51.000000, 72.000000)"
              fillRule="nonzero"
            >
              {times(19, idx => (
                <rect
                  key={idx}
                  id="Rectangle-4-Copy-9"
                  fill={getKPIProgressColor(
                    getKPIValue(props.data, 'DZ RelDiff'),
                    idx
                  )}
                  x={idx * 5}
                  y="0"
                  width="2"
                  height="7"
                />
              ))}
            </g>
            <text
              id="54%"
              fontFamily="Ubuntu-Medium, Ubuntu"
              fontSize="14"
              fontWeight="400"
              fill="#4A4A4A"
            >
              <tspan x="160" y="80">
                {getKPIValue(props.data, 'DZ RelDiff')}%
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </g>
  </svg>
));
