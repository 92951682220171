import styled, { css } from 'styled-components';
import { SelectRange } from './index';

import { useMonth } from '@datepicker-react/hooks';
import { getWeekEnd, getWeekStart } from '@yarmill/utils';
import { capitalizeFirstLetter } from '../helpers';
import { Text, TextAlignment, TextSize } from '../text';
import { Day } from './day';
import { groupByWeeks } from './utils';

type FirstDayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;

interface MonthProps {
  year: number;
  month: number;
  selectedDay: Date;
  selectMode: SelectRange;
  firstDayOfWeek: FirstDayOfWeek;
  rangeStart?: Date;
  rangeEnd?: Date;
  weekdayLabelFormat(date: Date): string;
}

const StyledMonthLabel = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 40px);
  justify-content: center;
  margin: 10px 0;
  color: #757575;
`;

const StyledWeekWrapper = styled.div<{
  isSelected?: boolean;
  selectWeek?: boolean;
}>`
  display: contents;

  ${({ selectWeek, isSelected }) =>
    selectWeek &&
    css`
      &:hover > div:not(.selected):not(:empty) {
        ${!isSelected && `background-color: #f4f5f5`};
      }
    `};

  ${({ isSelected }) =>
    isSelected &&
    css`
      & > div:not(:empty) {
        background-color: #4a90e2;
        color: #ffffff;
      }
    `};
`;

const StyledDayNumberComponent = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 40px);
  grid-auto-rows: 40px;
  justify-content: center;
`;

function isWeekSelected(
  week: (number | { dayLabel: string; date: Date })[],
  selectedWeekStart: Date,
  selectedWeekEnd: Date
): boolean {
  const firstDayOfWeek = week[0];
  const lastDayOfWeek = week[week.length - 1];

  if (typeof firstDayOfWeek !== 'number') {
    return (
      firstDayOfWeek.date.getDate() === selectedWeekStart.getDate() &&
      firstDayOfWeek.date.getMonth() === selectedWeekStart.getMonth()
    );
  } else if (typeof lastDayOfWeek !== 'number') {
    return (
      lastDayOfWeek.date.getDate() === selectedWeekEnd.getDate() &&
      lastDayOfWeek.date.getMonth() === selectedWeekEnd.getMonth()
    );
  }

  return false;
}

export function Month(props: MonthProps): JSX.Element {
  const {
    year,
    weekdayLabelFormat,
    month,
    firstDayOfWeek,
    selectMode,
    selectedDay,
  } = props;

  const { days, weekdayLabels } = useMonth({
    year,
    month,
    weekdayLabelFormat,
    firstDayOfWeek,
  });

  const weeks = groupByWeeks(days);
  const selectedWeekEnd = getWeekEnd(selectedDay);
  const selectedWeekStart = getWeekStart(selectedDay);

  return (
    <>
      <StyledMonthLabel>
        {weekdayLabels.map(dayLabel => (
          <Text
            size={TextSize.s10}
            inheritColor
            textAlign={TextAlignment.center}
            key={dayLabel}
          >
            {capitalizeFirstLetter(dayLabel)}
          </Text>
        ))}
      </StyledMonthLabel>
      <StyledDayNumberComponent>
        {weeks.map((week, idx) => (
          <StyledWeekWrapper
            key={idx}
            isSelected={
              selectMode === 'week' &&
              isWeekSelected(week, selectedWeekStart, selectedWeekEnd)
            }
            selectWeek={selectMode === 'week'}
          >
            {week.map((day, idx) =>
              typeof day === 'number' ? (
                <div key={idx} />
              ) : (
                <Day
                  date={day.date}
                  key={day.date.toString()}
                  dayLabel={day.dayLabel}
                />
              )
            )}
          </StyledWeekWrapper>
        ))}
      </StyledDayNumberComponent>
    </>
  );
}
