import {
  ActivityItem,
  ActivityItemId,
  ActivityItemsResponse,
  ActivityMask,
  AsyncStatus,
  MaskCode,
  RootStore,
  ActivityItemsStore as iActivityItemsStore,
} from '@yarmill/types';
import { sortBySortCode } from '@yarmill/utils';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { getActivityItems } from '../api/get-activity-items';

export class ActivityItemsStore implements iActivityItemsStore {
  private readonly rootStore: RootStore;

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  @observable
  private _activityItems: Map<MaskCode, Map<ActivityItemId, ActivityItem>> =
    new Map();

  @observable
  private _masks: Map<MaskCode, ActivityMask> = new Map();

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;

    when(
      () => rootStore.status === AsyncStatus.resolved,
      () => {
        this.setActivityMasks();
      }
    );
  }

  async loadActivityItems(): Promise<void> {
    if (this._status === AsyncStatus.resolved) {
      return;
    }

    this._status = AsyncStatus.pending;

    const request = this.rootStore.requestsStore.createRequest(() =>
      getActivityItems()
    );

    const response = await request.getResponse();
    if (response) {
      runInAction(() => {
        this.setActivityItems(response);
        this._status = AsyncStatus.resolved;
      });
    } else {
      this._status = AsyncStatus.rejected;
      throw request.error;
    }
  }

  getActivityItems(maskCode: MaskCode): ActivityItem[] {
    const activityItems = this._activityItems.get(maskCode);

    if (!activityItems) {
      return [];
    }

    return Array.from(activityItems.values()).sort(
      (a, b) => a.SortCode - b.SortCode
    );
  }

  getVisibleActivityItems(maskCode: MaskCode): ActivityItem[] {
    return this.getActivityItems(maskCode).filter(item => item.IsShown);
  }

  get status(): AsyncStatus {
    return this._status;
  }

  @computed
  get masks(): ActivityMask[] {
    return Array.from(this._masks.values()).sort(sortBySortCode);
  }

  getActivityItem(
    maskCode: MaskCode,
    id: ActivityItemId
  ): ActivityItem | undefined {
    return this._activityItems.get(maskCode)?.get(id);
  }

  @action
  setActivityItems(items: ActivityItemsResponse): void {
    items.forEach(item => {
      this._activityItems.set(item.MaskCode, new Map());
      item.ActivityItems.forEach(activityItem =>
        this._activityItems
          .get(item.MaskCode)
          ?.set(activityItem.ActivityItemId, activityItem)
      );
    });
  }

  @action
  private setActivityMasks(): void {
    this.rootStore.configStore.activityMasks.forEach(mask => {
      this._masks.set(mask.MaskCode, mask);
    });
  }
}
