import { FakeButton, Header, RightPanelTitle } from '@yarmill/components-2';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { BackToEventTypePanel } from '../buttons/back-to-event-type';
import { CloseEventPanel } from '../buttons/close-event-panel';
import { RemoveEvent } from '../buttons/remove-event';
import { useCurrentUserIsAllowedToWrite } from '../hooks/use-current-user-is-allowed-to-write';
import { usePlannerStore } from '../planner-store-context';
import { getFormTitle } from '../utils';
import { PlannerFormContext } from './planner-form-context';

export const FormStepHeader = observer(function FormStepHeader(): JSX.Element {
  const plannerStore = usePlannerStore();
  const formEvent = plannerStore.formEvent;
  const { currentStep, closeForm, setCurrentStep } =
    useContext(PlannerFormContext);
  const currentUserIsAllowedToWrite = useCurrentUserIsAllowedToWrite();

  return (
    <Header
      dark
      left={
        <>
          {currentStep === 'details' && <CloseEventPanel close={closeForm} />}
          {currentStep !== 'details' && (
            <BackToEventTypePanel onClick={() => setCurrentStep('details')} />
          )}
        </>
      }
      center={
        <RightPanelTitle appearance="text15strong">
          {getFormTitle(currentStep, formEvent)}
        </RightPanelTitle>
      }
      right={
        formEvent?.id &&
        currentUserIsAllowedToWrite &&
        formEvent.isRemovable &&
        currentStep === 'details' ? (
          <RemoveEvent event={formEvent} close={closeForm} />
        ) : (
          <FakeButton />
        )
      }
    />
  );
});
