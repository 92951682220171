import styled, { css } from 'styled-components';

export const DropdownList = styled.ul<{ readonly isOpened: boolean }>`
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: #ffffff;
  color: #212529;
  min-width: 10rem;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    display: ${({ isOpened }) => (isOpened ? 'block' : 'none')};
  }
`;

export const DropdownItem = styled.a<{
  readonly isHeader?: boolean;
  readonly danger?: boolean;
}>`
  font-family: 'Ubuntu';
  text-decoration: none;
  color: #4a4a4a;
  line-height: 24px;

  :hover {
    color: #4a90e2;
  }

  padding: 10px 20px;
  width: 100%;
  display: inline-flex;
  gap: 12px;
  align-items: center;
  border: 0;
  background: none;
  font-size: 16px;
  cursor: pointer;

  ${({ isHeader }) =>
    isHeader &&
    css`
      background: none;
      border: 0;
      border-bottom: 1px solid #edf0f5;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      text-align: center;
      font-weight: 700;
      justify-content: center;
      font-size: 16px;
    `}

  ${({ danger }) => danger && `color: #d0021b;`};
`;
