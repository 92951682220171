import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { TransitionGroupProps } from '../helpers';
import { forwardRef } from 'react';

const animationClassName = 'animation';
const animationTimeout = 300;
const animationTimingFunction = 'ease';

const StyledFullscreenLayer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity ${animationTimeout}ms ${animationTimingFunction};
  background-color: #edf0f5;
  display: flex;
  flex-direction: column;

  &.${animationClassName}-exit, &.${animationClassName}-enter-done {
    opacity: 1;
  }

  &.${animationClassName}-enter,
    &.${animationClassName}-exit-active,
    &.${animationClassName}-exit-done {
    opacity: 0;
  }

  &.${animationClassName}-enter-active {
    opacity: 1;
  }
`;

export const FullScreenLayerCloseButtonWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  @media (min-width: 576px) {
    right: 50px;
    top: 50px;
  }
`;

export const StyledFullScreenLayerContent = styled.div`
  position: relative;
  padding: 50px 12px;

  @media (min-width: 768px) {
    padding: 80px 50px;
  }
`;

export type FullscreenLayerProps =
  React.PropsWithChildren<TransitionGroupProps>;

export const FullscreenLayer = forwardRef<HTMLDivElement, FullscreenLayerProps>(
  function FullscreenLayer(props, ref): JSX.Element {
    const { active, children, in: visible, onExited } = props;

    return (
      <CSSTransition
        classNames={animationClassName}
        onExited={onExited}
        in={visible}
        timeout={animationTimeout}
      >
        <StyledFullscreenLayer aria-modal={active} ref={ref}>
          {children}
        </StyledFullscreenLayer>
      </CSSTransition>
    );
  }
);
