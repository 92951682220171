import {
  CurrentUserStore,
  ReportingModuleNavigationConfig,
} from '@yarmill/types';
import { getReportingPagePermissionScope } from '../utils';

export function createDashboardsByPermissionFilter(
  currentUser: CurrentUserStore,
  isLegacyAnalytics: boolean
) {
  return (dashboard: ReportingModuleNavigationConfig) =>
    (dashboard.Permissions.Roles.includes(currentUser.role) ||
      dashboard.Permissions.Users.includes(currentUser.id)) &&
    currentUser.isAllowedTo(
      isLegacyAnalytics
        ? `analytics.${dashboard.ReportPageCode}`
        : getReportingPagePermissionScope(dashboard!.ReportPageCode)
    );
}
