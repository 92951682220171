import { GoalAttributeStore } from './mobx/goal-attribute-store';
import { GoalLabel } from './goal-label';
import { GoalEditor } from './goal-editor';
import { WeekGoalRow } from '@yarmill/components';

export interface GoalRowProps {
  attribute: GoalAttributeStore;
  week?: boolean;
  cycle?: boolean;
}

export function GoalRow(props: GoalRowProps): JSX.Element {
  const { attribute, week, cycle } = props;

  return (
    <div>
      <WeekGoalRow>
        <GoalLabel attribute={attribute} week={week} cycle={cycle} />
        <GoalEditor attribute={attribute} />
      </WeekGoalRow>
    </div>
  );
}
