import { FocusedDayService as iFocusedDayService } from '@yarmill/types';
import { action, makeObservable, observable } from 'mobx';

export class FocusedDayService implements iFocusedDayService {
  @observable
  private _focusedDay: number = -1;

  constructor() {
    makeObservable(this);
  }

  get focusedDay(): number {
    return this._focusedDay;
  }

  @action
  setFocusedDay(focusedDay: number): void {
    this._focusedDay = focusedDay;
  }
}
