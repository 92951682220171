import { DATE_FORMAT } from '@yarmill/const';
import { uniqId } from '@yarmill/utils';
import moment from 'moment';
import { HeartRateZonesSetStore } from './mobx/heart-rate-zones-set-store';
import { HeartRateZone } from './types';

export function getAgeInYearsFromBirthday(
  birthday: string | moment.Moment
): number {
  return moment().diff(moment(birthday, DATE_FORMAT), 'years');
}

function getZoneMax(maxHr: number, coefficient: number): number {
  return Math.round((maxHr / 100) * coefficient);
}
export function getDefaultHeartRateZones(age: number): HeartRateZone[] {
  const maxHr = 220 - age;
  const zoneCoefficients = [50, 60, 70, 80, 90, 100];

  const zones: HeartRateZone[] = zoneCoefficients.map((coefficient, idx) => ({
    id: uniqId(),
    max: getZoneMax(maxHr, coefficient),
    name: `Z${idx}`,
  }));

  return zones;
}

export function hasOverlap(
  zoneSet1: HeartRateZonesSetStore,
  zoneSet2: HeartRateZonesSetStore
) {
  if (zoneSet1.isDefault || zoneSet2.isDefault) {
    return false;
  }

  const start1 = moment(zoneSet1.validFrom || undefined);
  const end1 = moment(zoneSet1.validTo || undefined);
  const start2 = moment(zoneSet2.validFrom || undefined);
  const end2 = moment(zoneSet2.validTo || undefined);

  // check range1 is between range2
  const startFirst = start1.isBetween(start2, end2);
  const endFirst = end1.isBetween(start2, end2);

  // check range2 is between range1
  const startLast = start2.isBetween(start1, end1);
  const endLast = end2.isBetween(start1, end1);

  return startFirst || endFirst || startLast || endLast;
}
