import { styled } from '@yarmill/components';

export const ConnectPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding: 80px 20px;

  @media (min-width: 768px) {
    max-width: 800px;
    margin: 80px auto;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;
