import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  MouseEvent,
  forwardRef,
  useContext,
} from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { Tippy } from '../../tippy';
import { OtherButtonsMenuContext } from './other-button';

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isActive?: boolean;
  isHidden?: boolean;
  noHoverBg?: boolean;
}
export const Button = styled.button<ButtonProps>`
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  cursor: pointer;
  padding: 4px 6px;
  background-color: transparent;
  color: rgb(117, 116, 116);
  border: 0;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${({ isHidden }) => (isHidden ? 'none' : 'all')};
  border-radius: 4px;
  z-index: 1;

  & > svg {
    width: 28px;
    height: 28px;
  }

  :hover {
    color: #4a4a4a;
    ${({ noHoverBg }) => !noHoverBg && 'background: #eeeeee;'};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      transition: background 0.3s ease;
      background: #dcdcdc;

      @media (min-width: 420px) {
        &:hover {
          background: #cccccc;
        }
      }
      svg,
      svg * {
        &[stroke="currentColor"] {
          stroke: #3c3b3b !important;
        }
        &[fill="currentColor"] {
          fill: #3c3b3b !important;
        }
      }
    `}
`;

export const BaseButton = forwardRef<HTMLButtonElement, ButtonProps>(
  function BaseButton(
    { children, onMouseDown, isActive, isHidden, name, tabIndex },
    ref
  ): JSX.Element {
    const { closeMenu } = useContext(OtherButtonsMenuContext);
    const intl = useIntl();
    const label = `richtext.toolbar.${name}`;

    function handleMouseDown(e: MouseEvent<HTMLButtonElement>) {
      e.preventDefault();
      closeMenu();
      onMouseDown?.(e);
    }

    return (
      <Tippy
        tooltipContent={`richtext.toolbar.${name}`}
        noWrapper
        touch={false}
      >
        <Button
          ref={ref}
          onMouseDown={handleMouseDown}
          type="button"
          isActive={isActive}
          isHidden={isHidden}
          tabIndex={tabIndex}
          aria-label={intl.formatMessage({ id: label })}
        >
          {children}
        </Button>
      </Tippy>
    );
  }
);
