import { TrainingDayContentBox } from '@yarmill/components';

import { getTrainingDayElementId } from '../diary/utils';
import { TrainingDayAttribute } from './training-day-attribute';
import { TrainingDayStore } from './mobx/training-day-store';
import { TrainingDayHeader } from './training-day-header';

export interface TrainingDayProps {
  day: TrainingDayStore;
}

export function TrainingDay(props: TrainingDayProps): JSX.Element {
  const { day } = props;
  const attributes = day.attributes;

  return (
    <TrainingDayContentBox
      id={getTrainingDayElementId(day.index)}
      data-cy="training-day"
      noShadow
    >
      <TrainingDayHeader day={day} />
      {attributes.map(attribute => (
        <TrainingDayAttribute attribute={attribute} key={attribute.id} />
      ))}
    </TrainingDayContentBox>
  );
}
