import { css, styled } from '@yarmill/components';
import { Text } from '@yarmill/components-2';
import { ISO_DATE_FORMAT } from '@yarmill/const';
import moment from 'moment';
import {
  ReactElement,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PlannerZoomLevel } from './utils';
import { ZoomLevelContext } from './zoom-level-context';

export interface CalendarProps {
  readonly startDate: moment.Moment;
  readonly endDate: moment.Moment;
  readonly numberOfDays: number;
  readonly headerHeight: number;
  readonly showLongMonthLabel: boolean;
  readonly language: string;
}

const StyledMonthLabel = styled.div.attrs<StyledDayContainerProps>(props => ({
  style: {
    gridColumn: `${props.column} / ${props.column + 1}`,
  },
}))<StyledDayContainerProps>`
  grid-row: 1 / 2;
  user-select: none;
  text-align: center;
  color: ${({ theme }) => theme.color.neutral};
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.color.white};
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: grid 250ms ease;

  :after {
    content: " ";
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    height: ${({ theme }) => theme.size.x2};
    background: transparent;
    opacity: 0;
    pointer-events: none;
  }
`;

export interface StyledDayContainerProps {
  readonly column: number;
  readonly row: number;
  readonly isValidDate?: boolean;
  readonly isWeekend?: boolean;
  readonly isSaturday?: boolean;
  readonly isSunday?: boolean;
  readonly isLastColumn?: boolean;
  readonly zoomLevel: PlannerZoomLevel;
}

const StyledDayContainer = styled.div.attrs<StyledDayContainerProps>(props => ({
  style: {
    gridColumn: `${props.column} / ${props.column + 1}`,
    gridRow: `${props.row} / ${props.row + 1}`,
  },
}))<StyledDayContainerProps>`
  ${({ theme, column }) =>
    column !== 1 &&
    css`
      border-left: 1px solid ${theme.color.neutralPlateDark};
    `};

  ${({ isLastColumn, theme }) =>
    isLastColumn &&
    css`
      border-right: 1px solid ${theme.color.neutralPlateDark};
    `};
  padding: 0
    ${({ theme, zoomLevel }) =>
      zoomLevel === 2
        ? theme.size.x1
        : zoomLevel === 1
          ? theme.size.x05
          : theme.size.x025};
  user-select: none;
  align-self: stretch;
  color: ${({ theme, isWeekend }) =>
    isWeekend ? theme.color.neutral_40 : theme.color.neutral_24};
  display: flex;
  align-items: center;
  transition:
    padding,
    color 250ms ease;
`;

const DayLabelText = styled(Text)<{
  readonly isSaturday: boolean;
  readonly isSunday: boolean;
  readonly zoomLevel: PlannerZoomLevel;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme, zoomLevel }) =>
    zoomLevel === 2 ? `${theme.size.x1} ${theme.size.x15}` : theme.size.x075};
  width: ${({ theme, zoomLevel }) =>
    zoomLevel === 2
      ? theme.size.x4
      : zoomLevel === 1
        ? theme.size.x3
        : theme.size.x25};
  height: ${({ zoomLevel, theme }) =>
    zoomLevel === 2 ? `calc(100% - ${theme.size.x05})` : '100%'};
  margin-top: ${({ zoomLevel, isSaturday, theme }) =>
    zoomLevel === 2 && isSaturday ? theme.size.x05 : 0};
  margin-bottom: ${({ zoomLevel, isSunday, theme }) =>
    zoomLevel === 2 && isSunday ? theme.size.x05 : 0};
  transition:
    padding,
    margin 250ms ease;

  ${({ theme, isSaturday, zoomLevel }) =>
    isSaturday &&
    css`
      border-top-left-radius: ${
        zoomLevel === 2 ? theme.size.x2 : theme.size.x15
      };
      border-top-right-radius: ${
        zoomLevel === 2 ? theme.size.x2 : theme.size.x15
      };
      background-color: ${theme.color.neutral_8};
    `};

  ${({ theme, isSunday, zoomLevel }) =>
    isSunday &&
    css`
      border-bottom-left-radius: ${
        zoomLevel === 2 ? theme.size.x2 : theme.size.x15
      };
      border-bottom-right-radius: ${
        zoomLevel === 2 ? theme.size.x2 : theme.size.x15
      };
      background-color: ${theme.color.neutral_8};
    `};
`;

const IsScrolledIndicator = styled.div`
  display: contents;

  & ~ ${StyledMonthLabel} {
    background-color: ${({ theme }) => theme.color.neutralPlate};

    :after {
      opacity: 1;
      background: linear-gradient(
        180deg,
        rgba(27, 28, 33, 0.08) 0%,
        rgba(27, 28, 33, 0) 100%
      );
    }
  }
`;

function InternalCalendar(props: CalendarProps): ReactElement {
  const {
    startDate,
    endDate,
    numberOfDays,
    headerHeight,
    showLongMonthLabel,
    language,
  } = props;

  const dayRefs = useRef<HTMLDivElement[]>([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const zoomLevel = useContext(ZoomLevelContext);
  const [days, monthLabels] = useMemo(() => {
    const current = startDate.clone().locale(language).startOf('month');
    const days = [];
    const monthLabels = [];
    let monthColumn = 1;
    const monthFormat = showLongMonthLabel ? 'MMMM' : 'MMM';

    const setRef = (el: HTMLDivElement | null) => {
      if (el && !dayRefs.current.includes(el)) {
        dayRefs.current.push(el);
      }
    };
    // Iterate through months
    while (current.isSameOrBefore(endDate)) {
      // Iterate throught days
      for (let day = 1; day <= numberOfDays; day++) {
        const date = moment(`${current.format('YYYY-MM')}-${day}`, 'YYYY-MM-D');
        const weekDay = date.isoWeekday();
        const row = day + 1;
        // Create cell for day
        days.push(
          <StyledDayContainer
            key={`${current.format('YYYY-M')}-${day}`}
            column={monthColumn}
            row={row}
            isValidDate={date.isValid()}
            isWeekend={weekDay === 6 || weekDay === 7}
            isSunday={weekDay === 7}
            isSaturday={weekDay === 6}
            isLastColumn={current.isSame(endDate, 'month')}
            data-type="day"
            data-date={date.format(ISO_DATE_FORMAT)}
            ref={day === 1 ? setRef : undefined}
            zoomLevel={zoomLevel}
          >
            <DayLabelText
              appearance={zoomLevel === 2 ? 'text12strong' : 'button10'}
              inheritColor
              upperCase
              isSunday={weekDay === 7}
              isSaturday={weekDay === 6}
              as="div"
              zoomLevel={zoomLevel}
            >
              {date.isValid() ? day : ''}
            </DayLabelText>
          </StyledDayContainer>
        );
      }

      // Create cells in first row for month labels
      monthLabels.push(
        <StyledMonthLabel
          key={`monthLabel-${current.format('YYYY-MM')}`}
          column={monthColumn}
          row={1}
          zoomLevel={zoomLevel}
        >
          <Text
            align="center"
            appearance={zoomLevel === 2 ? 'text12strong' : 'button10'}
            inheritColor
            upperCase
          >
            {current.format(
              current.month() === 0 ? `${monthFormat} YY` : monthFormat
            )}
          </Text>
        </StyledMonthLabel>
      );
      current.add(1, 'month').startOf('month');
      monthColumn++;
    }

    return [days, monthLabels];
  }, [
    startDate,
    endDate,
    numberOfDays,
    showLongMonthLabel,
    zoomLevel,
    language,
  ]);

  useEffect(() => {
    const elements = dayRefs.current;
    const observer = new IntersectionObserver(
      entries => {
        if (entries.some(e => e.boundingClientRect.y < e.intersectionRect.y)) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      },
      {
        root: document.querySelector('#planner-container'),
        rootMargin: `-${headerHeight}px`,
        threshold: [0.99, 1],
      }
    );

    elements.forEach(element => observer.observe(element));

    return () => {
      elements.forEach(element => observer.unobserve(element));
    };
  }, [headerHeight]);

  return (
    <>
      {isScrolled && <IsScrolledIndicator />}
      {monthLabels}
      {days}
    </>
  );
}

export const Calendar = memo(InternalCalendar);
