import {
  AsyncStatus,
  Group,
  MaskCode,
  Permission,
  PermissionType,
  PersonalizedSettingsConfiguration,
  RootStore,
  SeasonId,
  ServerError,
  SettingsKey,
  UserGroupId,
  UserId,
  UserStore,
  GroupStore as iGroupStore,
} from '@yarmill/types';
import { sortUserStores } from '@yarmill/utils';
import { AxiosError } from 'axios';
import {
  IObservableArray,
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { getDefaultPermission } from '../../permissions/utils';
import { addUserToGroup } from '../api/add-user-to-group';
import { deleteGroup } from '../api/delete-group';
import { getHistoricalAthletesForGroup } from '../api/get-historical-athletes-for-group';
import { getUserGroupUserPermission } from '../api/get-user-group-user-permission';
import { removeUserFromGroup } from '../api/remove-user-from-group';
import { updateGroup } from '../api/update-group';
import { updateUserGroupUserPermission } from '../api/update-user-group-user-permission';
import { UserInGroupValidity } from '../types';

export class GroupStore implements iGroupStore {
  private readonly _rootStore: RootStore;

  @observable
  private _group: Group;

  @observable
  private settings: Group['Settings'];

  @observable
  private readonly _athletes: UserStore[] = [];

  @observable
  private readonly _historicalMembers: UserStore[] = [];

  @observable
  private readonly _membersValidityDates: Map<UserId, UserInGroupValidity> =
    new Map();

  @observable
  private readonly _membersPermissions: Map<UserId, Permission> = new Map();

  @observable
  private readonly _coaches: UserStore[] = [];

  constructor(rootStore: RootStore, group: Group) {
    this._rootStore = rootStore;
    this._group = group;
    this.settings = this._group.Settings;

    group.ValidityDates.forEach(validityDate => {
      this._membersValidityDates.set(validityDate.UserId, validityDate);
    });

    makeObservable(this);

    when(
      () => rootStore.usersStore.status === AsyncStatus.resolved,
      () => {
        this._group.Athletes.forEach(userId => {
          const user = this._rootStore.usersStore.getUserById(userId);
          if (user) {
            this._athletes.push(user);
          }
        });

        this._group.Coaches.forEach(userId => {
          const user = this._rootStore.usersStore.getUserById(userId);
          if (user) {
            this._coaches.push(user);
          }
        });
      }
    );
  }

  get group(): Group {
    return this._group;
  }

  @computed
  get athletes(): Readonly<UserStore[]> {
    return this._athletes.slice().sort(sortUserStores);
  }

  @computed
  get coaches(): Readonly<UserStore[]> {
    return this._coaches.slice().sort(sortUserStores);
  }

  get name(): string {
    return this._group.Name;
  }

  get id(): UserGroupId {
    return this._group.UserGroupId;
  }

  @computed
  get allUsers(): UserStore[] {
    return [...this._coaches, ...this._athletes].sort(sortUserStores);
  }

  async delete(): Promise<boolean> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      deleteGroup(this.id)
    );

    const response = await request.getResponse();

    return runInAction(() => {
      if (response) {
        this.athletes.forEach(user => user.removeGroup(this));
        this.coaches.forEach(user => user.removeGroup(this));
        this._rootStore.groupsStore.removeGroup(this.id);

        return true;
      } else {
        return false;
      }
    });
  }

  async update(group: Partial<Group>): Promise<true | ServerError[]> {
    const updatedGroup = { ...this._group, ...group };
    const request = this._rootStore.requestsStore.createRequest(() =>
      updateGroup(updatedGroup)
    );

    const response = await request.getResponse();

    return runInAction(() => {
      if (response) {
        this._group = updatedGroup;
        return true;
      } else {
        return (request.error as AxiosError)?.response?.data as ServerError[];
      }
    });
  }

  async addUsers(users: UserStore[]): Promise<boolean> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      addUserToGroup({ groupId: this.id, userIds: users.map(u => u.id) })
    );
    const response = await request.getResponse();

    return runInAction(() => {
      if (response) {
        users.forEach(user => {
          if (user.isAthlete) {
            this._athletes.push(user);
          } else if (user.isCoach || user.isAdmin) {
            this._coaches.push(user);
          }
          user.groups.push(this);
        });
        return true;
      } else {
        return false;
      }
    });
  }

  addInvitedUser(user: UserStore): void {
    if (user.isAthlete) {
      this._athletes.push(user);
    } else if (user?.isCoach) {
      this._coaches.push(user);
    }
  }

  async removeUser(user: UserStore): Promise<boolean> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      removeUserFromGroup({ groupId: this.id, userId: user.id })
    );
    const response = await request.getResponse();

    return runInAction(() => {
      if (response) {
        (this._athletes as IObservableArray).remove(user);
        (this._coaches as IObservableArray).remove(user);
        user.removeGroup(this);

        return true;
      } else {
        return false;
      }
    });
  }

  @action
  removeDeactivatedUser(user: UserStore): void {
    (this._athletes as IObservableArray).remove(user);
    (this._coaches as IObservableArray).remove(user);
  }

  async loadHistoricalAthletes(): Promise<boolean> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      getHistoricalAthletesForGroup({ userGroupId: this.id })
    );
    const response = await request.getResponse();

    return runInAction(() => {
      if (response) {
        const newUsers = response.Users.filter(
          user => !this._rootStore.usersStore.getUserById(user.UserId)
        );
        this._rootStore.usersStore.setUsers(newUsers);

        const users = response.Users.map(u =>
          this._rootStore.usersStore.getUserById(u.UserId)
        ).filter(Boolean) as UserStore[];

        (this._historicalMembers as IObservableArray).clear();
        this._historicalMembers.push(...users);
        this._membersValidityDates.clear();

        response.ValidityDates.forEach(validityDate => {
          this._membersValidityDates.set(validityDate.UserId, validityDate);
        });
        return true;
      } else {
        return false;
      }
    });
  }

  async loadMembersPermissions(): Promise<boolean> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      getUserGroupUserPermission(this.id)
    );
    const response = await request.getResponse();

    return runInAction(() => {
      if (response) {
        response.forEach(permission => {
          this._membersPermissions.set(permission.UserId, permission);
        });
        return true;
      } else {
        return false;
      }
    });
  }

  async updateMemberPermissions(
    userId: UserId,
    permission: PermissionType
  ): Promise<boolean> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      updateUserGroupUserPermission({
        permission,
        userId,
        userGroupId: this.id,
      })
    );
    const response = await request.getResponse();

    return runInAction(() => {
      if (response) {
        response.forEach(permission => {
          this._membersPermissions.set(permission.UserId, permission);
        });
        return true;
      } else {
        return false;
      }
    });
  }

  getUserValidityDate(userId: UserId): UserInGroupValidity | undefined {
    return this._membersValidityDates.get(userId);
  }

  getUserPermissions(userId: UserId): Permission {
    const role =
      this._rootStore.usersStore.getUserById(userId)?.internalUser.Role;

    return (
      this._membersPermissions.get(userId) ?? {
        UserId: userId,
        Permission: getDefaultPermission(role),
        UserGroupId: this.id,
      }
    );
  }

  @computed
  get loadedPermissionsCount(): number {
    return this._membersPermissions.size;
  }

  @computed
  get historicalMembers(): Readonly<UserStore[]> {
    return this._historicalMembers.slice().sort(sortUserStores);
  }

  @computed
  get historicalMembersCount(): number {
    return this._historicalMembers.length || this.group.HistoricalMembersCount;
  }

  @computed
  get historicalAthletes(): UserStore[] {
    return this.historicalMembers.filter(u => u.isAthlete);
  }

  @action
  updateSetting(key: SettingsKey | string, value: string | boolean) {
    this.settings[key] = value;
    this._group.Settings[key] = value;
  }

  getSetting<K extends SettingsKey>(
    id: K
  ): PersonalizedSettingsConfiguration[K] {
    return this.settings[id];
  }

  getSeasonStart(seasonId: SeasonId): string | undefined {
    return this.settings[`season.startDate.${seasonId}`] as string | undefined;
  }

  get activityMask(): MaskCode | undefined {
    return this.settings['mask.selectedMask'] || this._group.DefaultMaskCode;
  }

  get availableMasks(): MaskCode[] {
    return this._group.AvailableMasks;
  }
}
