import { RootStore } from '@yarmill/types';
import { useRootStore } from '@yarmill/utils';
import { PropsWithChildren, useRef } from 'react';
import { ReportingStoreContext } from './context/reporting-store-context';
import { ReportingStore } from './mobx/reporting-store';

function createReportingStore(rootStore: RootStore): ReportingStore {
  const modulesRegistry = rootStore.modulesRegistryService;
  const reportingDefinitionStore = modulesRegistry.enabledModules.find(
    module => module.moduleCode === 'reporting'
  );
  const reportingStore =
    reportingDefinitionStore && 'reportingStore' in reportingDefinitionStore
      ? (reportingDefinitionStore.reportingStore as ReportingStore)
      : undefined;

  return reportingStore ?? new ReportingStore(rootStore);
}

export function ReportingStoreContextProvider({ children }: PropsWithChildren) {
  const rootStore = useRootStore();
  const store = useRef(createReportingStore(rootStore));

  return (
    <ReportingStoreContext.Provider value={store.current}>
      {children}
    </ReportingStoreContext.Provider>
  );
}
