import { EvidenceObject as IEvidenceObject } from '@yarmill/types';
import { observer } from 'mobx-react-lite';
import { EvidenceCategory } from './evidence-category';
import { EvidenceObject } from './evidence-object';
import { EvidenceSubcategory } from './evidence-subcategory';
import { EvidenceTable } from './evidence-table';
import { EvidenceProfileHeader } from './mobx/evidence-profile-header';

export interface EvidenceItemProps {
  definition: IEvidenceObject;
}

export const EvidenceItem = observer(function EvidenceItem(
  props: EvidenceItemProps
): JSX.Element | null {
  const { definition } = props;

  switch (definition.VisualizationKey) {
    case 'category':
      return <EvidenceCategory definition={definition} />;
    case 'subcategory':
      return <EvidenceSubcategory definition={definition} />;
    case 'item':
      return <EvidenceObject definition={definition} />;
    case 'table':
      return <EvidenceTable definition={definition} />;
    case 'profileHeader':
      return <EvidenceProfileHeader />;
    default:
      return null;
  }
});
