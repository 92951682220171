import {
  NavbarStatus,
  NavbarStatusText,
  Spinner,
  StyledTd,
  StyledTh,
  StyledTr,
  Table,
  Text,
  TextSize,
} from '@yarmill/components';
import { AsyncStatus } from '@yarmill/types';
import { useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { HeartRateZonesTableRow } from './heart-rate-zones-table-row';
import { HeartRateZonesSetStore } from './mobx/heart-rate-zones-set-store';

interface HeartRateZonesTableProps {
  setFormItem(heartRateZonesSet: HeartRateZonesSetStore): void;
}
export const HeartRateZonesTable = observer(function HeartRateZonesTable({
  setFormItem,
}: HeartRateZonesTableProps): JSX.Element {
  const rootStore = useRootStore();
  const heartRateZonesManagerStore = rootStore.heartRateZonesManagerStore;

  return (
    <Table
      colgroup={
        <>
          <col />
          <col />
          <col width="85" />
          <col width="85" />
          <col />
          <col width="108" />
        </>
      }
      head={
        <>
          <StyledTh>
            <Text size={TextSize.s12} bold>
              <FormattedMessage id="settings.heartRateZones.sport" />
            </Text>
          </StyledTh>
          <th />
          <StyledTh>
            <Text size={TextSize.s12} bold>
              <FormattedMessage id="settings.heartRateZones.validFrom" />
            </Text>
          </StyledTh>
          <StyledTh>
            <Text size={TextSize.s12} bold>
              <FormattedMessage id="settings.heartRateZones.validTo" />
            </Text>
          </StyledTh>
          <StyledTh>
            <Text size={TextSize.s12} bold>
              <FormattedMessage id="settings.heartRateZones.zones" />
            </Text>
          </StyledTh>
          <StyledTh />
        </>
      }
    >
      {heartRateZonesManagerStore.availableSports.length !== 0 &&
        heartRateZonesManagerStore.sortedHeartRateZonesSets.map(
          heartRateZonesSet => (
            <HeartRateZonesTableRow
              heartRateZonesSet={heartRateZonesSet}
              key={heartRateZonesSet.id}
              setFormItem={setFormItem}
            />
          )
        )}
      {((heartRateZonesManagerStore.status === AsyncStatus.pending &&
        heartRateZonesManagerStore.sortedHeartRateZonesSets.length === 0) ||
        heartRateZonesManagerStore.availableSports.length === 0) && (
        <StyledTr>
          <StyledTd colSpan={6}>
            <NavbarStatus appearance="info">
              <Spinner />
              <NavbarStatusText size={TextSize.s14} inheritColor>
                <FormattedMessage id="navbar.status.loadingData" />
              </NavbarStatusText>
            </NavbarStatus>
          </StyledTd>
        </StyledTr>
      )}
    </Table>
  );
});
