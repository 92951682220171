import { getFileExtension } from './get-file-extension';
import { getFileNameWithoutExtension } from './get-file-name-without-extension';

export function limitFileName(fileName: string, length: number) {
  if (fileName.length <= length) {
    return fileName;
  }

  const extension = getFileExtension(fileName);
  const name = getFileNameWithoutExtension(fileName);
  const lengthForName = length - (extension.length + 1 + 1); // +1 for dot, +1 for …
  const firstPartLength = Math.ceil(lengthForName / 2);
  const secondPartLength = Math.floor(lengthForName / 2);
  const firstPart = name.substring(0, firstPartLength);
  const secondPart = name.substring(name.length - secondPartLength);

  return `${firstPart}…${secondPart}.${extension}`;
}
