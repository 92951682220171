import { DATE_FORMAT, ROUTE_DATE_FORMAT } from '@yarmill/const';
import moment from 'moment';

export function getWeekStart(day: Date): Date;
export function getWeekStart(day: string | moment.Moment): moment.Moment;
export function getWeekStart(
  day: string | moment.Moment | Date
): moment.Moment | Date {
  const date = moment(day, ROUTE_DATE_FORMAT);

  const weekStart = date.startOf('isoWeek');
  if (day instanceof Date) {
    return weekStart.toDate();
  }
  return weekStart;
}

export function getWeekStartString(day: string): string {
  return getWeekStart(day).format(DATE_FORMAT);
}
