import { AsyncStatus, RequestsStore as iRequestsStore } from '@yarmill/types';
import { AxiosPromise, CancelTokenSource } from 'axios';
import {
  IObservableArray,
  action,
  makeObservable,
  observable,
  when,
} from 'mobx';
import { RequestStore } from './request-store';

export class RequestsStore implements iRequestsStore {
  @observable
  private _requests: RequestStore<unknown>[] = [];

  constructor() {
    makeObservable(this);
  }

  @action createRequest<T>(
    req: (cancelToken: CancelTokenSource) => AxiosPromise<T>
  ): RequestStore<T> {
    const request = new RequestStore(req);
    this._requests.push(request);

    when(
      () => request.status === AsyncStatus.resolved,
      () => {
        this.removeRequest(request);
      }
    );
    return request;
  }

  @action removeRequest<T>(req: RequestStore<T>): void {
    (this._requests as IObservableArray<RequestStore<T>>).remove(req);
  }

  get requests(): RequestStore<unknown>[] {
    return this._requests;
  }
}
