import { FilesTable } from './files-table';
import { Header } from './header';

export function PageContent(): JSX.Element {
  return (
    <>
      <Header />
      <FilesTable />
    </>
  );
}
