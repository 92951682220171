import { ComponentProps, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDefaultValues } from './use-default-values';

export function FormattedMessageWithDefaultValues({
  id,
  defaultMessage,
  values,
}: ComponentProps<typeof FormattedMessage>): JSX.Element {
  const defaultValues = useDefaultValues(id);

  const combinedValues = useMemo(
    () =>
      (defaultValues
        ? { ...defaultValues, ...values }
        : values) as ComponentProps<typeof FormattedMessage>['values'],
    [defaultValues, values]
  );

  return (
    <FormattedMessage
      id={id}
      defaultMessage={defaultMessage}
      values={combinedValues}
    />
  );
}
