import { useMemo } from 'react';
import { generateUrl } from '../utils';
import { useLocation } from './use-location';

export function useGeneratedUrl(
  overrides: Record<string, string | number | null>
): string {
  const { pathname, search } = useLocation();
  return useMemo(
    () => generateUrl(overrides, `${window.origin}${pathname}${search}`),
    [pathname, search, overrides]
  );
}
