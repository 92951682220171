import { Status, styled } from '@yarmill/components';
import { Evidence } from './evidence';

const StickyStatus = styled.div`
  position: sticky;
  top: 0;
  @media (min-width: 768px) {
  }
`;
const StatusWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export function PageContent(): JSX.Element {
  return (
    <>
      <StickyStatus>
        <StatusWrapper>
          <Status />
        </StatusWrapper>
      </StickyStatus>
      <Evidence />
    </>
  );
}
