import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  CommandPaletteContainer,
  CommandPaletteInput,
  CommandPaletteModal,
  CommandPaletteOverlay,
  CommandPaletteSuggestion,
  CommandPaletteSuggestionContainer,
  CommandPaletteSuggestionList,
} from '@yarmill/components';
import ReactCommandPalette from 'react-command-palette';
import { FocusLock } from '../utils/focus-lock';
import { Command } from './command';
import { useCommands } from './hooks';

export const HIGHLIGHTED_CLASS_NAME = 'highlighted';

export const fuzzySortOptions = {
  threshold: -Infinity, // Don't return matches worse than this (higher is faster)
  limit: 7, // Don't return more results than this (lower is faster)
  allowTypo: true, // Allows a single transposes (false is faster)
  key: 'name', // For when targets are objects (see its example usage)
  keys: ['name', 'plainText'], // For when targets are objects (see its example usage)
  scoreFn: null, // For use with `keys` (see its example usage)
};
export function CommandPalette(): JSX.Element | null {
  const commands = useCommands();
  const intl = useIntl();
  const ref = useRef<any | null>(null);
  const [isOpened, setIsOpened] = useState(false);

  const handleModalClose = useCallback(() => {
    const onKeyDown = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        e.preventDefault();
      }
      document.removeEventListener('keydown', onKeyDown);
    };
    document.addEventListener('keydown', onKeyDown);
    setIsOpened(false);
  }, []);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent): void => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setIsOpened(isOpened => !isOpened);
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  return isOpened ? (
    <FocusLock>
      <CommandPaletteOverlay>
        {({ className: overlayClassName }) => (
          <CommandPaletteModal>
            {({ className: modalClassName }) => (
              <CommandPaletteContainer>
                {({ className: containerClassName }) => (
                  <CommandPaletteInput>
                    {({ className: inputClassName }) => (
                      <CommandPaletteSuggestionContainer>
                        {({ className: suggestionsContainerClassName }) => (
                          <CommandPaletteSuggestionList>
                            {({ className: suggestionsListClassName }) => (
                              <CommandPaletteSuggestion>
                                {({ className: suggestionClassName }) => (
                                  <ReactCommandPalette
                                    commands={commands}
                                    ref={ref}
                                    onRequestClose={handleModalClose}
                                    closeOnSelect
                                    resetInputOnClose
                                    resetInputOnOpen
                                    hotKeys="command+shift+option+k+p+a+l+t+e"
                                    trigger={Fragment}
                                    placeholder={intl.formatMessage({
                                      id: 'commandPalette.placeholder',
                                    })}
                                    maxDisplayed={7}
                                    open
                                    options={fuzzySortOptions}
                                    reactModalParentSelector="#ytd-cmd-palette-portal-target"
                                    renderCommand={Command}
                                    theme={{
                                      modal: modalClassName,
                                      container: containerClassName,
                                      input: inputClassName,
                                      suggestionsContainer:
                                        suggestionsContainerClassName,
                                      suggestionsList: suggestionsListClassName,
                                      suggestion: suggestionClassName,
                                      suggestionHighlighted:
                                        HIGHLIGHTED_CLASS_NAME,
                                      overlay: overlayClassName,
                                    }}
                                  />
                                )}
                              </CommandPaletteSuggestion>
                            )}
                          </CommandPaletteSuggestionList>
                        )}
                      </CommandPaletteSuggestionContainer>
                    )}
                  </CommandPaletteInput>
                )}
              </CommandPaletteContainer>
            )}
          </CommandPaletteModal>
        )}
      </CommandPaletteOverlay>
    </FocusLock>
  ) : null;
}
