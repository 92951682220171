import { FakeButton, Header, RightPanelTitle } from '@yarmill/components-2';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

interface ImporterHeaderProps {
  readonly backButton: ReactElement;
  readonly filtersButton?: ReactElement;
}
export function ImporterHeader({
  backButton,
  filtersButton,
}: ImporterHeaderProps): ReactElement {
  return (
    <Header
      dark
      left={backButton}
      center={
        <RightPanelTitle appearance="text15strong">
          <FormattedMessage id="planner.importer.headline" />
        </RightPanelTitle>
      }
      right={filtersButton ?? <FakeButton />}
    />
  );
}
